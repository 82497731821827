<div class="form">
    <form action="" [formGroup]="admissionForm">
        <div class="row">
            <div class="col-md-6">
                <label for="">First Name<sup>*</sup></label>
                <input type="text" class="form-control mt-2" formControlName="fname"
                    [ngClass]="{ 'is-invalid': submitted && f.fname.errors }">
                    <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                        <span class="text-danger msg_txt" *ngIf="(f.fname.touched || submitted) && f.fname.errors?.required">
                            First name is required
                        </span>
                    </div>
            </div>
           
            <div class="col-md-6">
                <label for="">Last Name<sup>*</sup></label>
                <input type="text" class="form-control mt-2" formControlName="lname"
                    [ngClass]="{ 'is-invalid': submitted && f.lname.errors }">
                <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                    <span class="text-danger msg_txt"
                        *ngIf="(f.lname.touched || submitted) && f.lname.errors?.required">
                        Last name is required
                    </span>
                </div>
            </div>
            <div class="col-md-6 mt-3">
                <label for="">Email Id<sup>*</sup></label>
                <input type="text" class="form-control mt-2" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <span class="text-danger msg_txt"
                        *ngIf="(f.email.touched || submitted) && f.email.errors?.required">
                        Email is required
                    </span>
                    <span class="text-danger msg_txt" *ngIf="f.email.touched && f.email.errors?.email">
                        Enter a valid email address
                    </span>
                </div>
            </div>
            <div class="col-md-6 mt-3">
                <label for="">Mobile No<sup>*</sup></label>
                <input type="text" class="form-control mt-2" formControlName="mobile" (keyup)="getMobileNO();"
                    [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                    <span *ngIf="f.mobile.errors?.required">Mobile is required.</span>
                    <span *ngIf="f.mobile.errors?.pattern">Please, Enter 10 digit Mobile.</span>
                </div>
                <div *ngIf="otpInput" class="validMsg">OTP sent successfully</div>
            </div>
            <div class="col-md-6 mt-3" *ngIf="otpInput">
                <div class="form-group">
                    <label for="">OTP<sup>*</sup></label>
                    <input type="text" class="form-control" (keyup)="verifyOTP();" formControlName="otp"
                        [ngClass]="{ 'is-invalid': submitted && f.otp.errors }">
                    <div *ngIf="submitted && f.otp.errors" class="invalid-feedback">
                        <span *ngIf="f.otp.errors?.required">OTP is required.</span>
                    </div>
                    <div *ngIf="otp_ValidMsg" class="validMsg">otp verified successfully</div>
                    <div *ngIf="otp_inValidMsg" class="inValidMsg">please enter valid otp</div>
                </div>
            </div>
            <div class="col-md-6 mt-3">
                <label for="">Pin Code<sup>*</sup></label>
                <input type="text" class="form-control" formControlName="pinCode"
                    [ngClass]="{ 'is-invalid': submitted && f.pinCode.errors }">
                <div *ngIf="submitted && f.pinCode.errors" class="invalid-feedback">
                    <span *ngIf="f.pinCode.errors?.required">Pin code is required.</span>
                    <span *ngIf="f.pinCode.errors?.pattern">Please, Enter 6 digit pincode.</span>
                </div>
            </div>
            <div class="col-md-6 mt-3">
                <label for="">State<sup>*</sup></label>
                <select name="" id="" class="form-control" formControlName="state" (change)="selectState($event)"
                    [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                    <option value="">State/District</option>
                    <option value="{{state.State_Name}}" *ngFor="let state of stateList">{{state.State_Name}}</option>
                </select>
                <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                    <span class="text-danger msg_txt"
                        *ngIf="(f.state.touched || submitted) && f.state.errors?.required">
                        State/District is required
                    </span>
                </div>
            </div>
            <div class="col-md-6 mt-3">
                <label for="">City<sup>*</sup></label>
                <select name="" id="" class="form-control" formControlName="city" (change)="selectCity($event)"
                    [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                    <option value="">City</option>
                    <option value="{{city.City_Name}}" *ngFor="let city of cityList">{{city.City_Name}}</option>
                </select>
                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                    <span class="text-danger msg_txt" *ngIf="(f.city.touched || submitted) && f.city.errors?.required">
                        City is required
                    </span>
                </div>
            </div>
        </div>
    </form>
    <div class="sub_btn d-flex justify-content-center mt-5">
        <button class="btn submit_btn" (click)="validationForm();">Submit</button>
    </div>
</div>