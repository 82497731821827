import { Component } from '@angular/core';

@Component({
  selector: 'app-motion-graphics',
  templateUrl: './motion-graphics.component.html',
  styleUrls: ['./motion-graphics.component.css', '../../../../../src/app/components/commonCss/program.css']
})
export class MotionGraphicsComponent {

}
