import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/common/header/header.component';
import { LegancyComponent } from './components/legancy/legancy.component';
import { AdmissionsComponent } from './components/admissions/admissions.component';
import { FranchiseOpportunityComponent } from './components/franchise-opportunity/franchise-opportunity.component';
import { BlogComponent } from './components/blog/blog/blog.component';
import { PentemindComponent } from './components/pentemind/pentemind.component';
import { PlayGroupComponent } from './components/play-group/play-group.component';
import { NurseryComponent } from './components/nursery/nursery.component';
import { KindergartenComponent } from './components/kindergarten/kindergarten.component';
import { TeacherTrainingComponent } from './components/teacher-training/teacher-training.component';
import { DayCareComponent } from './components/day-care/day-care.component';
import { OurApproachComponent } from './components/our-approach/our-approach.component';
import { AwardsAndRecognitionComponent } from './components/awards-and-recognition/awards-and-recognition.component';
import { LocateUsComponent } from './components/locate-us/locate-us.component';
import { OurPresenceComponent } from './components/our-presence/our-presence.component';
import { PreschoolInAgartalaComponent } from './components/preschool-in-agartala/preschool-in-agartala.component';
import { PartnerLandingComponent } from './components/partner-landing/partner-landing.component';
import { AdmissionThankyouComponent } from './components/thank-you/admission-thankyou/admission-thankyou.component';
import { FranchiseThankyouComponent } from './components/thank-you/franchise-thankyou/franchise-thankyou.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { BlogDetailsComponent } from './components/blog/blog-details/blog-details.component';
import { InnewsComponent } from './components/innews/innews.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { FranchiseComponent } from './franchise/franchise.component';
import { ProgrammesComponent } from './components/programmes/programmes.component';
import { ComingsoonComponent } from './components/comingsoon/comingsoon.component';
import { StudentsZoneComponent } from './components/students-zone/students-zone.component';
import { CareersPlacementComponent } from './components/careers-placement/careers-placement.component';
import { CareersWorkWithUsComponent } from './components/careers-work-with-us/careers-work-with-us.component';
import { AllBlogComponent } from './components/blog/all-blog/all-blog.component';
import { CentersComponent } from './components/centers/centers.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { AnimationComponent } from './components/course/animation/animation.component';
import { VisualEffectsComponent } from './components/course/visual-effects/visual-effects.component';
import { GameComponent } from './components/course/game/game.component';
import { InteriorDesignComponent } from './components/course/interior-design/interior-design.component';
import { FashionDesignComponent } from './components/course/fashion-design/fashion-design.component';
import { GraphicDesignComponent } from './components/course/graphic-design/graphic-design.component';
import { WebDesignComponent } from './components/course/web-design/web-design.component';
import { DigitalMarketingComponent } from './components/course/digital-marketing/digital-marketing.component';
import { DigitalPhotographyComponent } from './components/course/digital-photography/digital-photography.component';
import { EventsComponent } from './components/events/events.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ThreeDanimationComponent } from './components/course/three-danimation/three-danimation.component';
import { EnquiryComponent } from './components/enquiry/enquiry.component';
import { ThankyouEnquiryComponent } from './components/thankyou-enquiry/thankyou-enquiry.component';
import { FaqsPageComponent } from './components/faqs-page/faqs-page.component';
import { PartnerPageComponent } from './components/partner-page/partner-page.component';
import { ThankyouPartnerComponent } from './components/thankyou-partner/thankyou-partner.component';
import { AdmissionLPComponent } from './components/AdmissionLP/admission-lp/admission-lp.component';
import { BVocAnimationVisualEffectsComponent } from './components/course/bvoc-animation-visual-effects/bvoc-animation-visual-effects.component';
import { UiUxComponent } from './components/course/ui-ux/ui-ux.component';
import { MotionGraphicsComponent } from './components/course/motion-graphics/motion-graphics.component';
import { BlenderComponent } from './components/course/blender/blender.component';
import { UnrelComponent } from './components/course/unrel/unrel.component';
import { DigitalAudioVideoEditingComponent } from './components/course/digital-audio-video-editing/digital-audio-video-editing.component';

const routes: Routes = [
   // enquiry landing page
  {path:'', component:EnquiryComponent},
    // {path:'', component:PartnerPageComponent},
  {path:'enquiry', component:EnquiryComponent},
  {path:'enquiryThankyou',component:ThankyouEnquiryComponent},
  // partner_page landing page
  // {path:'partner_page', component:PartnerPageComponent},
  // {path:'partnerThankyou', component:ThankyouPartnerComponent},

  // AdmissionLP landing page
  // {path:'admissionLP', component:AdmissionLPComponent},
  {
    path: '',
    component: HeaderComponent,
    children: [
      // { path: '', component: HomeComponent }, 
      // { path: 'franchise', component: FranchiseComponent },
      // { path: 'admissions', component: AdmissionsComponent },
      // { path: 'thankyou', component: AdmissionThankyouComponent },
      // { path: 'programs', component: ProgrammesComponent },
      // {path:'comingsoon',component:ComingsoonComponent},
      // {path:"studentsZone",component:StudentsZoneComponent},
      // {path:"careersPlacement", component:CareersPlacementComponent},
      // {path:"careersWorkWithUs",component:CareersWorkWithUsComponent},
      // {path:"ourBlog", component:AllBlogComponent},
      // { path: 'blog/:name', component: BlogComponent },
      // {path:'center/:centerPath',component:CentersComponent},
      // {path:'termsOfUse', component:TermsOfUseComponent},
      //  {path:'PrivacyPolicy', component:PrivacyPolicyComponent},
      //  {path:'animation', component:AnimationComponent},
      //  {path:'visualEffect',component:VisualEffectsComponent},
      //  {path:'BVocAnimationVisualEffects', component: BVocAnimationVisualEffectsComponent},
      //  {path:'UiUx', component:UiUxComponent},
      //  {path:'motionGraphics', component:MotionGraphicsComponent},
      //  {path:'blender', component:BlenderComponent},
      //  {path:'unrel', component:UnrelComponent},
      //  {path:'digitalAudioVideoEditing', component:DigitalAudioVideoEditingComponent},
      //  {path:'game',component:GameComponent},
      //  {path:'interiorDesign', component:InteriorDesignComponent},
      //  {path :'fashionDesign', component: FashionDesignComponent},
      //  {path:'graphicDesign', component:GraphicDesignComponent},
      //  {path:'webDesign', component:WebDesignComponent},
      //  {path:'digitalMarketing', component:DigitalMarketingComponent},
      //  {path:'digitalPhotography', component:DigitalPhotographyComponent},
      //  {path:'2D_Animation',component:ThreeDanimationComponent},
      //  { path: 'awards-and-recognition',component: AwardsAndRecognitionComponent},
      //  {path:'event', component:EventsComponent},
      //  {path:'aboutUs', component:AboutUsComponent},
      //  {path:'locateUs/:zoon', component:LocateUsComponent},
      //  {path:'enquiry', component:EnquiryComponent},
      //  {path:'faqs', component:FaqsPageComponent},
      //  {path:'partner_page', component:PartnerPageComponent}
    ],
  },
  { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
