
<!-- <nav class="navbar navbar-expand-lg bg-body-tertiary navbar1">
  <div class="">
    <div class="collapse navbar-collapse nav_body" id="navbarNavDropdown">
      <ul class="navbar-nav ul_content">
        <li class="nav-item">
          <a class="nav-link active menu1" aria-current="page" routerLink="">Home</a>
        </li>
        <li class="nav-sub-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            About Us
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" routerLink="/legacy">Legacy</a>
            <a class="dropdown-item" routerLink="/our-approach">Our Approach</a>
            <a class="dropdown-item" routerLink="/vision">Vision & Mission</a>
            <a class="dropdown-item" routerLink="/awards-and-recognition">Awards & Recognized</a>
          </div>
        </li>
        <li class="nav-sub-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Programmes
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" id="target">
            <a class="dropdown-item" routerLink="/playgroup">Playgroup</a>
            <a class="dropdown-item" routerLink="/nursery">Nursery</a>
            <a class="dropdown-item" routerLink="/kindergarten">Kindergarten</a>
            <a class="dropdown-item" routerLink="/teacher-training-programme">Teacher Training Programme</a>
            <a class="dropdown-item" routerLink="/Daycare">Daycare</a>
          </div>
        
        </li>
        <li class="nav-item">
          <a class="nav-link active menu1" aria-current="page" routerLink="/pentemind">Péntemind</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active menu5" aria-current="page" routerLink="/admissions">Admissions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active menu" aria-current="page" routerLink="/partner-with-kidzee">Franchise Opportunity</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" routerLink="/locateUs">Locate Us</a>
        </li>

      </ul>
    </div>
  </div>
</nav> -->
<!-- <div class="whiteHeader" id="whiteHeader">
    <nav class="nav navbar navbar-expand-lg">
        <div class="container-fluid">
          <div class="navbar-brand">
            <div class="header_logo" routerLink=""></div>
          </div>
          <div class="menu_icon" data-bs-toggle="collapse" data-bs-target="#navbarNav"></div>
          <div class="collapse navbar-collapse " id="navbarNav">
            <ul class="navbar-nav ul_content">
              <li class="nav-item">
                <a class="nav-link active menu1" aria-current="page" routerLink="">Home</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  About Us
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a class="dropdown-item" routerLink="/legacy">Legacy</a>
                  <a class="dropdown-item" routerLink="/our-approach">Our Approach</a>
                  <a class="dropdown-item" routerLink="/vision">Vision & Mission</a>
                  <a class="dropdown-item" routerLink="/awards-and-recognition">Awards & Recognized</a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Programmes
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a class="dropdown-item" routerLink="/playgroup">Playgroup</a>
                  <a class="dropdown-item" routerLink="/nursery">Nursery</a>
                  <a class="dropdown-item" routerLink="/kindergarten">Kindergarten</a>
                  <a class="dropdown-item" routerLink="/teacher-training-programme">Teacher Training Programme</a>
                  <a class="dropdown-item" routerLink="/Daycare">Daycare</a>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link active menu1" aria-current="page" routerLink="/pentemind">Péntemind</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active menu5" aria-current="page" routerLink="/admissions">Admissions</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active menu" aria-current="page" routerLink="/partner-with-kidzee">Franchise Opportunity</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" routerLink="/locateUs">Locate Us</a>
              </li>
    
            </ul>
          </div>
        </div>
      </nav>      
</div> -->


<div class="whiteHeader_dark" id="whiteHeader_dark">
  <nav class="nav navbar navbar-expand-lg">
    <div class="container-fluid">
      <div class="navbar-brand d-flex">
        <div class="">
          <img src="../../../../assets/img/zica/ZICA_Logo.png" class="zica_logo" alt="ZICA_Logo">
        </div>
        <div class="">
          <img src="../../../../assets/img/zica/year_logo.png" class="header_logo2" alt="year_logo">
        </div>
        <!-- <div class="header_logo_whiteText" routerLink=""></div> -->
      </div>
      <div class="menu_icon" data-bs-toggle="collapse" data-bs-target="#navbarNav"></div>
      <div class="collapse navbar-collapse " id="navbarNav">
        <ul class="navbar-nav ul_content">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="">Our Programs</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="">Student Zone</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="">Our Centres</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Careers
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="">Placements</a>
              <a class="dropdown-item"routerLink="">Work With Us</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Resources
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="">About ZICA</a>
              <a class="dropdown-item" routerLink="">Awards and Recognition</a>
              <a class="dropdown-item" routerLink="">Our Events</a>
              <a class="dropdown-item" routerLink="">Our Blogs</a>

            </div>
          </li>
          <li class="nav-item">
            <button class="btn startFr_btn">Start a Franchise</button>
            </li>
            <li class="nav-item">
              <button class="btn admission_enq_btn">Admissions Enquiry</button>
              </li>
        </ul>
      </div>
    </div>
    <div class="header_btn d-flex justify-content-center"><img src="../../../../assets/img/zica/icon/cap_icon.png" width="25px" height="25px" alt="cap_icon">Admission Open Now 2023-2024</div>
  </nav>
    
</div>
<router-outlet></router-outlet>
<!-- <app-footer></app-footer> -->