import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
import { CommonService } from '../components/service/common.service';

@Component({
  selector: 'app-franchise-form',
  templateUrl: './franchise-form.component.html',
  styleUrls: ['./franchise-form.component.css']
})
export class FranchiseFormComponent {
  admissionForm: FormGroup;
  otp_ValidMsg: boolean = false
  otp_inValidMsg: boolean = false
  submitted: boolean = false
  otpInput: boolean = false
  randomOtp: any;
  countryList: any;
  stateList: any;
  cityList: any;
  franchiseeList: any;
  constructor(private fb: FormBuilder, private _servie: CommonService, private _activeRoute: ActivatedRoute, private router: Router,
    private projectService: ProjectSeoService,
    private apiService: ApicallService) {
    this.admissionForm = fb.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      pinCode: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]],
      state: ['', Validators.required],
      city: ['', Validators.required],
      otp: ['', Validators.required],

    })
  }

  ngOnInit(): void {
    this.selectCountry_State_cityList();
    
  }


  validationForm() {
    this.submitted = true;
    if (this.admissionForm.invalid) {
      return;
    } else {
      this.submitForm()
    }
  }

  get f() {
    return this.admissionForm.controls;
  }

  submitForm() {

    if ((this.admissionForm.get('otp')?.value).length == 4) {
      if (this.randomOtp == this.admissionForm.get('otp')?.value) {
        let obj = {
          "City": this.admissionForm.get('city')?.value,
          "Country": "India",
          "Email": this.admissionForm.get('email')?.value,
          "FirstName": this.admissionForm.get('fname')?.value,
          "HaveSpace": "",
          "LastName": this.admissionForm.get('lname')?.value,
          "Location": "",
          "Mobile": this.admissionForm.get('mobile')?.value,
          "PinCode": this.admissionForm.get('pinCode')?.value,
          "Product": "259262000015975052",
          "ProjectId": "3",
          "SoonStartsIn": "",
          "Source": "gclid",
          "gclid": "gclid",
          "State": this.admissionForm.get('state')?.value,
          "Type": "F",
          "WillingToInvest": "",
          "utm_compaign": "Website",
          "utm_medium": "Website",
          "utm_source": "Website",
          "utm_ad": "Website",
          "utm_Content": "Website",
          "utm_Term": "Website",
        }
        this._servie.saveDataPartner(obj).subscribe(
          res => {
            this.otp_ValidMsg = false;
            this.otp_inValidMsg = false;
            this.router.navigate(['/thankyou'])
            this.admissionForm.reset();
            this.submitted = false
          }
        )
        this.otp_ValidMsg = true;
        this.otp_inValidMsg = false;

      } else {
        alert('Please Enter Valid OTP')
        this.otp_inValidMsg = true;
        this.otp_ValidMsg = false;
      }
    }
  }
  getMobileNO() {
    if ((this.admissionForm.get('mobile')?.value).length == 10) {
      this.sendMobNO();
    }
  }

  sendMobNO() {
    this.randomOtp = Math.floor(1000 + Math.random() * 9000);
    let mobNo = {
      "MobileNo": this.admissionForm.get('mobile')?.value,
      "smsText": `To validate your ZICA interest use One Time Password ${this.randomOtp}. Think Education. Think Zee Learn.`,
      "sResponse": ""
    }
    this._servie.getOtp(mobNo).subscribe(
      res => {
        this.otpInput = true;
      }
    )
  }

  verifyOTP() {
    if ((this.admissionForm.get('otp')?.value).length == 4) {
      if (this.randomOtp == this.admissionForm.get('otp')?.value) {
        this.otp_ValidMsg = true;
        this.otp_inValidMsg = false;
      } else {
        this.otp_inValidMsg = true;
        this.otp_ValidMsg = false;
      }
    }
  }

  selectCountry_State_cityList() {    
    this._servie.getState_countryList().subscribe(
      res => {
        this.countryList = res.root.subroot
     this.stateList = this.countryList.State
      })
  }

  selectState(selectVal: any) {
    let state = selectVal.target.value
    let filterCity = this.stateList.filter((x: any) => {
      return x.State_Name == state
    })
    // this.cityList = filterCity[0].City
    if(!Array.isArray(filterCity[0].City))
    {

      this.cityList=[filterCity[0].City];
    }
    else
    {
      this.cityList = filterCity[0].City;

    }
  }

  selectCity(selectVal: any) {
    let city = selectVal.target.value
    let filterFranchisee = this.cityList.filter((x: any) => {
      return x.City_Name == city
    })
    this.franchiseeList = filterFranchisee[0].Franchisee
  }

}
