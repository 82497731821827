<section>
    <div class="testimonial">
    <div class="row">
        <div class="col-md-7">
            <div class="text2">Let’s Hear It From<br><span class="color_text"> Our Students</span>
            </div>
            <!-- <div class="text_para">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua</p>
            </div> -->
        </div>
        <div class="col-md-5">
            <div class="right_goalsImg_box d-flex justify-content-center">
                <img src="../../../assets/img/zica/d_comma.png" class="d_comma img-fluid" alt="">
            </div>
        </div>
    </div>

    <div class="multi_box_content mt-3">
        <div class="row">
        <div class="col-lg-4 col-md-4 col-6 mt-3" *ngFor="let a of testimonialList">
            <div class="video_content" *ngIf="a.slug" (click)="playvideo(a.slug)">
               
                <div *ngFor="let b of a.logofiles">
                    <a data-fancybox href="{{videoUrl}}" class="video_main_content"> 
                    <i class='fa fa-play'></i> 
                    <div class="playText_content"> 
                    <div class="text-white titlePLayList text-center">{{a.Title}}</div>
                    <div class="text-white text-center">{{a.short}}</div>
                    </div>
                        <img src="{{b.value}}" width="100%" height="368px" alt="{{b.filename}}">
                    </a>       
                </div>
              
            </div>
            <div *ngIf="!a.slug" >
            <div class="sub_box profile_box" *ngFor="let b of a.logofiles" >
                <div class="left_icon">
                    <img  src="{{b.value}}" width="75px" height="75px" alt="{{b.filename}}">
                </div>
                <div class="profile_name">{{a.Title}}</div>
                <div class="details">{{a.short}}</div>
               
                <div class="para">
                <p [innerHTML]="a.Long1"></p>
                </div>
            </div>
            </div>
    </div>
    </div>
    </div>
</div>
</section>