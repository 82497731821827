import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../components/service/common.service';

@Component({
  selector: 'app-franchise',
  templateUrl: './franchise.component.html',
  styleUrls: ['./franchise.component.css']
})
export class FranchiseComponent {
  constructor(private _service: CommonService, private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxSpinner: NgxSpinnerService, public sanitizer: DomSanitizer,
    private projectService: ProjectSeoService,
    private apiService: ApicallService) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
      }
    }

ngOnInit(): void {
  let tbody = {
    slug: 'franchise',
    Projectid: environment.projectid,
  };
  this.apiService.getGetseo(tbody).subscribe((data: any) => {
   this.projectService.sendMessagebread(data?.data?.breadcrumb);
  });
 
}
}
