import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css'],
})
export class TestimonialComponent implements OnInit {
  careersPlacementData: any;
  testimonialList: any;
  videoUrl: any;
  videoUrlList:any;
  constructor(private _service: CommonService, private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxSpinner: NgxSpinnerService, public sanitizer: DomSanitizer,
    private projectService: ProjectSeoService,
    private apiService: ApicallService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
  }

  ngOnInit(): void {
   //this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/`);
    setTimeout(() => {
      this.getCMSDataList();
      }, 1000);
  }

  getCMSDataList() {
    let obj = {
      "Type": "student testimonial",
      "Project_Id": 3
    }
    this._service.getCMSContent(obj).subscribe(
      res => {
        this.testimonialList = JSON.parse(res.data[0].contentData);    
      // this.videoUrl = this.testimonialList.map((x:any)=>{
      //    return  this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+x.slug)
      // })
      }
    )
  }
  playvideo(url:string){
    if(url){
      this.videoUrl =this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+url)
    }
   
  }
}
