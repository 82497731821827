<section>
    <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="box1">
        <div class="row">
            <div class="col-md-6">
                <div class="text2"><span class="color_text">Admission</span><br>
                    Form</div>
                <!-- <div class="text_para">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua</p>
                </div> -->
            </div>
            <div class="col-md-6">
                <div class="bg_form">
                      <app-admissions-form></app-admissions-form>
                </div>
              
            </div>
        </div>
        
    </div>
    <div class="box2">
        <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
        <div class="text_para text-center">
            <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                Visual FX Labs And Imaging Technologies.</p>
        </div>
        <app-blogs></app-blogs>
    </div>
</section>