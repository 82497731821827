<footer class="desktopResponsive">
    <div class="footerContent">
        <div class="row">
            <div class="footerTextContnetn">
                <div class="subListContent">
                    <div class="row">
                        <div class="col-md-6">
                    <div class="top_logo_list">
                        <img src="../../../../assets/img/zica/course/mesc.png" width="90px" height="90px" alt="">
                        <img src="../../../../assets/img/zica/course/skill.png" class="mx-3" width="90px" height="90px" alt="">
                    </div>
                    <div class="para mt-2">
                        <h6 class="text-white">ZICA Training Centre Affiliated with MESC.</h6>
                        <p class="text-white">Welcome to the Enhanced Experience of Certification from The Government of India.</p>
                    </div>
                </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <div class="zeeLogo">
                                <img src="../../../../assets/img/zica/ZLLLogo.png" alt="">
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="logoList">
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/ZICA_Logo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/zima.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/KidzeeFooterLogo.png" alt="">
                                </div>
                                <!-- <div class="logo">
                                    <img src="../../../../assets/img/zica/mlwp.png" alt="">
                                </div> -->
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlzs.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mls.png" alt="">
                                </div>
                              
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-4">
                            <ul>
                                <li routerLink="/programs">
                                    <h5>Our Programs</h5>
                                </li>
                                <li class="dropdown-item" routerLink="/animation">3D Animation</li>
                                <li routerLink="/visualEffect">Visual Effects</li>
                                <li routerLink="/graphicDesign">Graphic Design</li>
                                <li routerLink="/webDesign">Web Design</li>
                                <li routerLink="/interiorDesign">Interior Design</li>
                                <li routerLink="/fashionDesign">Fashion Design</li>
                                <li routerLink="/digitalMarketing">Digital Marketing</li>
                                <li routerLink="/digitalPhotography">Digital Photography</li>
                                <li routerLink="/game">Game Design</li>
                            </ul>
                        </div>
                        <div class="col-md-2">
                            <ul>
                                <li routerLink="/studentsZone">
                                    <h5>Student Zone</h5>
                                </li>
                                <li routerLink="/faqs">FAQ's</li>
                            </ul>
                        </div>
                        <div class="col-md-2">
                            <ul>
                                <li>
                                    <a href="/center/jaipur-zee-studios-sitapura">
                                    <h5>Our Centres</h5>
                                </a>
                                </li>
                                <li routerLink="/locateUs/North">North</li>
                                <li routerLink="/locateUs/East">East</li>
                                <li routerLink="/locateUs/West">West</li>
                                <li routerLink="/locateUs/South">South</li>
                            </ul>
                        </div>
                        <div class="col-md-2">
                            <ul>
                                <li>
                                    <h5>Careers</h5>
                                </li>
                                <li routerLink="/careersPlacement">Placements</li>
                                <li routerLink="/careersWorkWithUs">Work With Us</li>
                            </ul>
                        </div>
                        <div class="col-md-2">
                            <ul>
                                <li>
                                    <h5>Resources</h5>
                                </li>
                                <li routerLink="/aboutUs">About Us</li>
                                <li routerLink="/awards-and-recognition">Awards & Recognition</li>
                                <li routerLink="/event">Our Events</li>
                                <li routerLink="/blog/all">Our Blogs</li>
                                <li routerLink="/locateUs/all">Locate Us</li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-4">
                <div class="iconList">
                    <a href="https://www.facebook.com/zicaworld" target="_blank">
                    <img src="../../../../assets/img/zica/icon/icon1.png" alt="">
                </a>
                <a href="https://www.instagram.com/zeeinstituteofcreativeart/" target="_blank">
                    <img src="../../../../assets/img/zica/icon/icon2.png" alt="">
                </a>
                <!-- <a href="" target="_blank">
                    <img src="../../../../assets/img/zica/icon/icon3.png" alt="">
                </a> -->
                <a href="https://www.linkedin.com/school/zicaworld/" target="_blank">
                    <img src="../../../../assets/img/zica/icon/icon4.png" alt="">
                </a>
                <a href="https://www.youtube.com/user/ZICAWorld" target="_blank">
                    <img src="../../../../assets/img/zica/icon/icon5.png" alt="">
                </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId">
                    copyright ® 2024 Zee Learn Ltd. All rights reserved.
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId">
                    <span class="footerRout" routerLink="./PrivacyPolicy">Privacy & Policy</span> <span
                        class="footerRout" routerLink="./termsOfUse">Terms of Use</span>
                </div>
            </div>
        </div>
    </div>

</footer>

<footer class="mobileResponsive">
    <div class="footerContent">
        <div class="mobile_footerLogoList">
        <div class="row">
            <div class="col-md-6">
        <div class="top_logo_list">
            <img src="../../../../assets/img/zica/course/mesc.png" width="90px" height="90px" alt="">
            <img src="../../../../assets/img/zica/course/skill.png" class="mx-3" width="90px" height="90px" alt="">
        </div>
        <div class="para mt-2">
            <h6 class="text-white">ZICA Training Centre Affiliated with MESC.</h6>
            <p class="text-white">Welcome to the Enhanced Experience of Certification from The Government of India.</p>
        </div>
    </div>
</div>
        </div>
        <div class="row">
            <div class="footerTextContnetn">
                <div class="subListContent">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="zeeLogo">
                                <img src="../../../../assets/img/zica/ZLLLogo.png" alt="">
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="logoList">
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/ZICA_Logo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/zima.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/KidzeeFooterLogo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlwp.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlzs.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mls.png" alt="">
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <ul>
                                <li routerLink="/">
                                    <h5>Our Programs</h5>
                                </li>
                                <li class="dropdown-item" routerLink="/animation">3D Animation</li>
                                <li routerLink="/visualEffect">Visual Effects</li>
                                <li routerLink="/graphicDesign">Graphic Design</li>
                                <li routerLink="/webDesign">Web Design</li>
                                <li routerLink="/interiorDesign">Interior Design</li>
                                <li routerLink="/fashionDesign">Fashion Design</li>
                                <li routerLink="/digitalMarketing">Digital Marketing</li>
                                <li routerLink="/digitalPhotography">Digital Photography</li>
                                <li routerLink="/game">Game Design</li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <ul>
                                <li routerLink="/studentsZone">
                                    <h5>Student Zone</h5>
                                </li>
                                <li routerLink="/faqs">Faq's</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <ul>
                                <li routerLink="/center">
                                    <h5>Our Centres</h5>
                                </li>
                                <li routerLink="/locateUs/North">North</li>
                                <li routerLink="/locateUs/East">East</li>
                                <li routerLink="/locateUs/West">West</li>
                                <li routerLink="/locateUs/South">South</li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <ul>
                                <li>
                                    <h5>Careers</h5>
                                </li>
                                <li routerLink="/careersPlacement">Placements</li>
                                <li routerLink="/careersWorkWithUs">Work With Us</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6">
                            <ul>
                                <li routerLink="/">
                                    <h5>Resources</h5>
                                </li>
                                <li routerLink="/aboutUs">About Us</li>
                                <li routerLink="/awards-and-recognition">Awards & Recognition</li>
                                <li routerLink="/event">Our Events</li>
                                <li routerLink="/blog/all">Our Blogs</li>
                                <li routerLink="/locateUs/all">Locate Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-6">
                <div class="iconList">
                    <a href="https://www.facebook.com/zicaworld">
                    <img src="../../../../assets/img/zica/icon/icon1.png" alt="">
                </a>
                <a href="https://www.instagram.com/zeeinstituteofcreativeart/">
                    <img src="../../../../assets/img/zica/icon/icon2.png" alt="">
                </a>
                <!-- <a href="">
                    <img src="../../../../assets/img/zica/icon/icon3.png" alt="">
                </a> -->
                <!-- <a href="">
                    <img src="https://www.linkedin.com/school/zicaworld/" alt="">
                </a> -->
                <a href="https://www.youtube.com/user/ZICAWorld">
                    <img src="../../../../assets/img/zica/icon/icon5.png" alt="">
                </a>
                </div>
            </div>
            <div class="col-6">
                <div class="text-white text-center footerTextEmailId">
                    <span class="footerRout" routerLink="./PrivacyPolicy">Privacy & Policy</span><br><br> <span
                        class="footerRout" routerLink="./termsOfUse">Terms of Use</span>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId bottom_text">
                    copyright ® 2024 Zee Learn Ltd.<br> All rights reserved.
                </div>
            </div>

        </div>
        <div class="btn_content">
            <button class="btn admission_enq_btn" routerLink="/franchise">Start a Franchise</button>
            <button class="btn admission_enq_btn" routerLink="/admissions">Admissions Enquiry</button>
        </div>
    </div>

</footer>