<section>
    <div class="whiteHeader_dark desktopNav" id="whiteHeader_dark">
        <div class="side_con">
          <a href="https://wa.me/9152010855" target="_blank"><div class="watsapp_icon"></div></a>
          <div class="download_icon"></div>
      </div>
      </div>
</section>
<section>
    <div class=content>
        <div class="wrapper-1">
          <div class="wrapper-2">
            <h1>Thank you !</h1>
            <p>Thanks for submitting your enquiry to our Website.  </p>
            <p>You should receive a Email/Call soon to discusse further  </p>
            <button class="btn back_btn" routerLink="">
            Go home
            </button>
          </div>
      </div>
      </div>
        </section>
<section>
    <footer class="desktopResponsive">
    <div class="footerContent">
        <div class="row">
            <div class="footerTextContnetn">
                <div class="subListContent">
                    <div class="row">
                        <div class="col-5">
                            <div class="zeeLogo">
                                <img src="../../../../assets/img/zica/ZLLLogo.png" alt="">
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="logoList">
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/ZICA_Logo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/KidzeeFooterLogo.png" alt="">
                                </div>
                                <!-- <div class="logo">
                                    <img src="../../../../assets/img/zica/mlwp.png" alt="">
                                </div> -->
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlzs.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mls.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/zima.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-4">
                <div class="iconList">
                    <a href="https://www.facebook.com/zicaworld" target="_blank">
                    <img src="../../../../assets/img/zica/icon/icon1.png" alt="">
                </a>
                <a href="https://www.instagram.com/zeeinstituteofcreativeart/" target="_blank">
                    <img src="../../../../assets/img/zica/icon/icon2.png" alt="">
                </a>
                <a href="https://www.linkedin.com/school/zicaworld/" target="_blank">
                    <img src="../../../../assets/img/zica/icon/icon4.png" alt="">
                </a>
                <a href="https://www.youtube.com/user/ZICAWorld" target="_blank">
                    <img src="../../../../assets/img/zica/icon/icon5.png" alt="">
                </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId">
                    copyright ® 2023 Zee Learn Ltd. All rights reserved.
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId">
                    <span class="footerRout" routerLink="./PrivacyPolicy">Privacy & Policy</span> <span
                        class="footerRout" routerLink="./termsOfUse">Terms of Use</span>
                </div>
            </div>
        </div>
    </div>

</footer>

<footer class="mobileResponsive">
    <div class="footerContent">
        <div class="row">
            <div class="footerTextContnetn">
                <div class="subListContent">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="zeeLogo">
                                <img src="../../../../assets/img/zica/ZLLLogo.png" alt="">
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="logoList">
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/ZICA_Logo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/KidzeeFooterLogo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlwp.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlzs.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mls.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/zima.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12">
                <div class="text-white text-center footerTextEmailId">
                    <span class="footerRout" routerLink="./PrivacyPolicy">Privacy & Policy</span><br><br> <span
                        class="footerRout" routerLink="./termsOfUse">Terms of Use</span>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId bottom_text">
                    copyright ® 2023 Zee Learn Ltd.<br> All rights reserved.
                </div>
            </div>

        </div>
        <div class="btn_content">
            <button class="btn admission_enq_btn" routerLink="/franchise">Start a Franchise</button>
            <button class="btn admission_enq_btn" routerLink="/admissions">Admissions Enquiry</button>
        </div>
    </div>

</footer>
</section>