<section>
    <div class="mainDiv">
        <div class="banner_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
        </div>

        <div class="sub_content">
            <div class="row">
                <div class="col-md-4">
                    <div class="img_content">
                        <img srcset="../../../assets/img/Kindergarten.png" loading="lazy" class="faqImg_style" alt="">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="text_content">
                        <div class="title mt-3">Kindergarten</div>
                        <div class="infoContent mt-3">
                            <div class="text1">
                                <div class="sub_text">Junior K.G.</div>
                                <div class="sub_text">3.5 - 4.5 Years</div>
                            </div>
                            <div class="text2 rightBorder">
                                <div class="sub_text">Senior K.G.</div>
                                <div class="sub_text">4.5 - 5.5 Years</div>
                            </div>
                            <div class="text2">
                                <div class="sub_text">Duration</div>
                                <div class="sub_text">3 hours 45 mins/day</div>
                            </div>
                        </div>
                        <div class="paraText mt-4">
                            <p>*As per RTE- Right to Education and NEP- National Education Policy from state to state.
                            </p>
                            <p>At Kidzee, the Kindergarten (KG) curriculum ensures a smooth transition to formal schooling. Children learn age-appropriate activities in various areas like English language, numbers, general knowledge, music, physical development, and art. The Kidzee Proprietary programmes for Phonics and school readiness makes it one of the best preschools in India. Child development is observed and assessed thrice in a year using a well-designed progressive matrix. Our pedagogy Péntemind - The Learning Minds is aligned to the guidelines of NEP - National Education Policy 2020 and NCF - National Curriculum Framework of India.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contentList">
            <div class="row">
                <div class="col-md-4">
                    <div class="listTitle">Key Areas in Playgroup Curriculum</div>
                    <div class="text_list">
                        <ul>
                            <li> <span class="doth">.</span><span class="liText">Theme-based Concept</span></li>
                            <li> <span class="doth">.</span><span class="liText">Phonics and Language</span></li>
                            <li> <span class="doth">.</span><span class="liText">Personal, Social, and Emotional Growth</span></li>
                            <li> <span class="doth">.</span><span class="liText">Communication skills</span></li>
                            <li> <span class="doth">.</span><span class="liText">Performance Art</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="listTitle">Playgroup learning Activities Included</div>
<div class="row">
    <div class="col-4">
        <div class="text_list">
            <ul>
                <li> <span class="doth">.</span><span class="liText">Circle Time</span></li>
                <li> <span class="doth">.</span><span class="liText">Free Play</span></li>
                <li> <span class="doth">.</span><span class="liText">Knowledge Time</span></li>
                <li> <span class="doth">.</span><span class="liText">Language Time</span></li>
                <li> <span class="doth">.</span><span class="liText">Hindi (Optional)</span></li>
                <li> <span class="doth">.</span><span class="liText">English Readiness Programme</span></li>
                <li> <span class="doth">.</span><span class="liText">Artsy</span></li>
                <li> <span class="doth">.</span><span class="liText">Personality Development</span></li>
                <li> <span class="doth">.</span><span class="liText">Showstopper</span></li>
                <li> <span class="doth">.</span><span class="liText">Mental Might</span></li>
                <li> <span class="doth">.</span><span class="liText">Eco-conscious</span></li>
                <li> <span class="doth">.</span><span class="liText">Numeracy Time</span></li>
            </ul>
        </div>
    </div>
    <div class="col-8">
        <div class="text_list">
            <ul>
                <li> <span class="doth">.</span><span class="liText">Tell-a-Tale</span></li>
                <li> <span class="doth">.</span><span class="liText">Library</span></li>
                <li> <span class="doth">.</span><span class="liText">Talk Time</span></li>
                <li> <span class="doth">.</span><span class="liText">Sciencify</span></li>
                <li> <span class="doth">.</span><span class="liText">Outdoor</span></li>
                <li> <span class="doth">.</span><span class="liText">Indoor</span></li>
                <li> <span class="doth">.</span><span class="liText">Whirl & Twirl</span></li>
                <li> <span class="doth">.</span><span class="liText">Sensorium</span></li>
                <li> <span class="doth">.</span><span class="liText">Critical Thinking</span></li>
                <li> <span class="doth">.</span><span class="liText">Celebration of festivals and special days</span></li>
                <li> <span class="doth">.</span><span class="liText">Field Trips</span></li>
                <li> <span class="doth">.</span><span class="liText">Puppet shows and skits</span></li>
            </ul>
        </div>
    </div>
</div>
                </div>
            </div>
        </div>
        <div class="faqs_content">
            <app-faqs></app-faqs>
        </div>
        <div class="blogs_content">
            <app-blogs></app-blogs>
        </div>
       
    </div>
    <div class="bottomMonkey">
        <img srcset="../../../assets/img/monkey2.png" loading="lazy" class="monkeyImgList" alt="">
    </div>
</section>