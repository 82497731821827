import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-programmes',
  templateUrl: './programmes.component.html',
  styleUrls: ['./programmes.component.css']
})
export class ProgrammesComponent implements OnInit {
  tab1:boolean=true
  showBox:string="All";
  showContentBox:any;
  ngOnInit(): void {
  this.showContentBox = true
}
  showContent(val:any){
this.showBox = val
  }

}
