<section>
    <div class="main_body">
      <div class="box1">
        <div class="innerBlogCOntent">
            <div class="row">
                <div class="col-md-6">
                  <div class="left_box">
                    <div class="text2">Terms of  <br>Use
                    </div>
                    <div class="text_para">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="img_box">
                    <img src="../../.../../../assets/img/zica/terms.png" width="100%" alt="">
                  </div>
                </div>
              </div>
              <div class="blog_img_content mt-4">
                <!-- <img src={{blogdetails.files[0].url}} width="100%" alt={{blogdetails.files[0].filename}}> -->
              </div>
            
              <div class="blog_text_content text-white mt-5">
                <div class="blog_heading">Power Of Technology</div>
                <p class="mt-2">In the year 1995, the Chairman Dr. Subash Candra started Zee Institute of Creative Art (ZICA), the nation's first full-fledged Classical and Digital Animation Training Academy that trains youth in classical 2D and modern 3D animation. The past 21 years ZICA has nurtured some of the brightest talents in the world of animation. ZICA adopted a novel training style and is focused entirely on creating a stimulating environment for its students.</p>
                <p>ZICA, with its state-of-the-art infrastructure, gives practical training in 2D Animation, 3D Animation, Visual Effects & Gaming, covering the stages of visualization, Pre-production, production and post-production. At ZICA, a lot of emphasis is laid on the principles of art and design and fundamentals of classical animation, while extracting the same knowledge to computer based Digital animation.
                  Today ZICA, with over 15 centres in cities including Mumbai, Pune, Bangalore, Noida, Lucknow, Bhubaneswar, Indore etc. emerges as young India's chosen partner to turn passion into profession.</p>
  
                  <div class="blog_heading">Why Animation & VFX business?</div>
                   <li class="mt-2">According to a report released by Ernst & Young, the higher education sector in India is expected to grow by 18% (CAGR) till 2020</li>
                   <li>Animation & VFX industry itself is on an upswing: KPMG expects this sector to grow at 18.5% to reach 55.9 billion rupees by 2015. The growing demand for television content continues to be a key driver for growth of the animation & VFX industry</li>
                   <p>With the entry of global players such as Disney & Cartoon Network in India, and major Hollywood production houses constantly outsourcing their work to Indian studios, there is a huge gap between demand & supply of trained people to work in this industry.</p>
                   <p>DELIVERABLES OF ZEE INSTITUTE OF CREATIVE ART</p>
                   <p>Content developmen</p>
                   <p>Our content development team comprises SMEs who work closely with industry experts to build up any content that needs to be developed even If it means customizing content to cater to the varying and unique demands of our Institutional business partners or internal customers. Significantly, we also provide you with the added advantage of tailoring the training to meet the current market trends and needs.</p>
                   <p>Structured Training Programme</p>
                   <p>The ZICA programs’ curriculum is designed as per university standards and is in the vanguard of these industries by way of exhaustive research and meticulous planning and design.
                    Advertising & National campaigns</p>
                    <p>ZICA handles the brand building activities. Local level promotions will be funded and executed by the franchisee</p>
                    <p>Art works and Designs</p>
                    <p>ZICA will provide all the designs for the marketing activities that are required to promote the programs.</p>
                    <p>Placement Services</p>
                    <p>The Placement cell provides 100 % placement assistance for all students undertaking the ZICA programs. Senior Industry professionals are invited to the institute on a regular basis to offer up-to-date industry insights to the students</p>
                    <p>Quality Assessors</p>
                    <p>Visit the center to ensure quality standards are achieved in all aspects and to gauge on the levels of competencies in processes and procedures to enhance productivity.</p>
                    <p>Training of Institute Staff</p>
                    <p>At ZICA we are highly committed to enhancing skills and knowledge of the Administrative and Sales staff to empower our franchisees to maximize on sales and revenue resulting from the deployment of the contemporary solutions we offer.</p>
                    <p>Experts from our team will conduct seminars and specialized training programs. These training sessions are tailored to fit specific regional requirements. Every member of the institute will be equipped with a clear understanding of product knowledge software usage development </p>
                    <p>methodologies Hands on training to all staff members are conducted by the ZICA team on product, operations, accounts and sales techniques. The training is held at the regional Office of ZICA and within the premises of the institute. The cost of lodging and boarding, traveling, etc. would be borne by Franchisee.</p>
              </div>
        </div>
      </div>
      <div class="box3 mt-5">
        <div class="row ">
          <div class="col-lg-6 col-6 text-white mobileRes">
             
           <div class="text2"><span class="color_text"> Get In Touch</span><br>
                With Us </div>
            <div class="text_para">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua</p>
            </div>
          </div>
          <div class="col-lg-6 col-6 text-white mobileRes">
         
            <div class="girl_img">
                <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="">
            </div>
          </div>
      </div>
        <div class="row">
          <div class="col-md-6 desktopRes">
              <div class="text2"><span class="color_text"> Get In Touch</span><br>
                  With Us </div>
              <div class="text_para">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua</p>
              </div>
              <div class="girl_img">
                  <img src="../../../assets/img/zica/Enquiry_pic.png" alt="">
              </div>
          </div>
          <div class="col-md-6">
              <div class="franchise_form_content">
                  <app-franchise-form></app-franchise-form>
              </div>
          </div>
      </div>
      </div>
    </div>
  </section>