<section>
    <div class="mainDiv">
        <div class="banner_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
        </div>

        <div class="sub_content">
            <div class="row">
                <div class="col-md-4">
                    <div class="img_content">
                        <img srcset="../../../assets/img/Nursery.png" loading="lazy" class="faqImg_style" alt="">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="text_content">
                        <div class="title mt-3">Nursery</div>
                        <div class="infoContent mt-3">
                            <div class="text1">
                                <div class="sub_text">Age Group</div>
                                <div class="sub_text">2.5 Years - 3.5 Years*</div>
                            </div>
                            <div class="text2">
                                <div class="sub_text">Duration</div>
                                <div class="sub_text">2 hours 45 mins/day</div>
                            </div>
                        </div>
                        <div class="paraText mt-4">
                            <p>*As per RTE- Right to Education and NEP- National Education Policy from state to state.
                            </p>
                            <p>To set a firm foundation in the early years of preschool, quality early childhood education is vital for the overall development of a child. The Nursery syllabus has the right array of activities and learning environment that help a child move towards achieving their learning goals. Quality education in the early years of preschool, lays a strong foundation to develop socially and emotionally.</p>
                            <p>The nursery education engages children in school readiness activities such as reading, writing, counting, number recognition, and problem solving. Children learn in a fun-filled but purposeful manner. Children are encouraged to display their learning experiences through collaborative activities in the areas of dramatics, science, and art. Our pedagogy Péntemind - The Learning Minds is aligned to the guidelines of NEP - National Education Policy 2020 and NCF - National Curriculum Framework of India.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contentList">
            <div class="row">
                <div class="col-md-4">
                    <div class="listTitle">Key Areas in Playgroup Curriculum</div>
                    <div class="text_list">
                        <ul>
                            <li> <span class="doth">.</span><span class="liText">Developing communication skills</span></li>
                            <li> <span class="doth">.</span><span class="liText">Expression through art and drama</span></li>
                            <li> <span class="doth">.</span><span class="liText">Math and Language Skill Development</span></li>
                            <li> <span class="doth">.</span><span class="liText">Opportunities for fine and gross motor development</span></li>
                            <li> <span class="doth">.</span><span class="liText">Promoting cognitive development</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="listTitle">Playgroup learning Activities Included</div>
<div class="row">
    <div class="col-4">
        <div class="text_list">
            <ul>
                <li> <span class="doth">.</span><span class="liText">Circle Time</span></li>
                <li> <span class="doth">.</span><span class="liText">Free Play</span></li>
                <li> <span class="doth">.</span><span class="liText">Knowledge Time</span></li>
                <li> <span class="doth">.</span><span class="liText">Language Time</span></li>
                <li> <span class="doth">.</span><span class="liText">Artsy</span></li>
                <li> <span class="doth">.</span><span class="liText">Personality Development</span></li>
                <li> <span class="doth">.</span><span class="liText">Showstopper</span></li>
                <li> <span class="doth">.</span><span class="liText">Mental Might</span></li>
                <li> <span class="doth">.</span><span class="liText">Eco-conscious</span></li>
                <li> <span class="doth">.</span><span class="liText">Numeracy Time</span></li>
                <li> <span class="doth">.</span><span class="liText">Tell-a-Tale</span></li>
            </ul>
        </div>
    </div>
    <div class="col-8">
        <div class="text_list">
            <ul>
                <li> <span class="doth">.</span><span class="liText">Library</span></li>
                <li> <span class="doth">.</span><span class="liText">Talk Time</span></li>
                <li> <span class="doth">.</span><span class="liText">Sciencify</span></li>
                <li> <span class="doth">.</span><span class="liText">Outdoor</span></li>
                <li> <span class="doth">.</span><span class="liText">Indoor</span></li>
                <li> <span class="doth">.</span><span class="liText">Whirl & Twirl</span></li>
                <li> <span class="doth">.</span><span class="liText">Sensorium</span></li>
                <li> <span class="doth">.</span><span class="liText">Critical Thinking</span></li>
                <li> <span class="doth">.</span><span class="liText">Celebration of festivals and special days</span></li>
                <li> <span class="doth">.</span><span class="liText">Field Trips</span></li>
                <li> <span class="doth">.</span><span class="liText">Puppet shows and skits</span></li>
            </ul>
        </div>
    </div>
</div>
                </div>
            </div>
        </div>
        <div class="faqs_content">
            <app-faqs></app-faqs>
        </div>
        <div class="blogs_content">
            <app-blogs></app-blogs>
        </div>
       
    </div>
    <div class="bottomMonkey">
        <img srcset="../../../assets/img/monkey1.png" loading="lazy" class="monkeyImgList" alt="">
    </div>
</section>