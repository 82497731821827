import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './components/home/home.component';
import { BlogsComponent } from './components/blogs/blogs.component';
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { LegancyComponent } from './components/legancy/legancy.component';
import { NewsComponent } from './components/news/news.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { KidzeePreeSchoolComponent } from './components/kidzee-pree-school/kidzee-pree-school.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { AdmissionsComponent } from './components/admissions/admissions.component';
import { FranchiseOpportunityComponent } from './components/franchise-opportunity/franchise-opportunity.component';
import { BlogComponent } from './components/blog/blog/blog.component';
import { AllBlogComponent } from './components/blog/all-blog/all-blog.component';
import { BlogDetailsComponent } from './components/blog/blog-details/blog-details.component';
import { StaticHeaderComponent } from './components/common/static-header/static-header.component';
import { SubMenuComponent } from './components/common/sub-menu/sub-menu.component';
import { PentemindComponent } from './components/pentemind/pentemind.component';
import { PlayGroupComponent } from './components/play-group/play-group.component';
import { NurseryComponent } from './components/nursery/nursery.component';
import { KindergartenComponent } from './components/kindergarten/kindergarten.component';
import { TeacherTrainingComponent } from './components/teacher-training/teacher-training.component';
import { DayCareComponent } from './components/day-care/day-care.component';
import { LocateUsComponent } from './components/locate-us/locate-us.component';
import { OurApproachComponent } from './components/our-approach/our-approach.component';
import { VisionComponent } from './components/vision/vision.component';
import { AwardsAndRecognitionComponent } from './components/awards-and-recognition/awards-and-recognition.component';
import { ProgrammesComponent } from './components/programmes/programmes.component';
import { AdmissionsFormComponent } from './components/admissions-form/admissions-form.component';
import { OurPresenceComponent } from './components/our-presence/our-presence.component';
import { PreschoolInAgartalaComponent } from './components/preschool-in-agartala/preschool-in-agartala.component';
import { CentersInAgartalaComponent } from './components/centers-in-agartala/centers-in-agartala.component';
import { PartnerLandingComponent } from './components/partner-landing/partner-landing.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonHeaderComponent } from './components/common/common-header/common-header.component';
import { AdmissionThankyouComponent } from './components/thank-you/admission-thankyou/admission-thankyou.component';
import { FranchiseThankyouComponent } from './components/thank-you/franchise-thankyou/franchise-thankyou.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { InnewsComponent } from './components/innews/innews.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FranchiseFormComponent } from './franchise-form/franchise-form.component';
import { FranchiseComponent } from './franchise/franchise.component';
import { ComingsoonComponent } from './components/comingsoon/comingsoon.component';
import { StudentsZoneComponent } from './components/students-zone/students-zone.component';
import { CareersPlacementComponent } from './components/careers-placement/careers-placement.component';
import { CareersWorkWithUsComponent } from './components/careers-work-with-us/careers-work-with-us.component';
import { CentersComponent } from './components/centers/centers.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { AnimationComponent } from './components/course/animation/animation.component';
import { VisualEffectsComponent } from './components/course/visual-effects/visual-effects.component';
import { GameComponent } from './components/course/game/game.component';
import { InteriorDesignComponent } from './components/course/interior-design/interior-design.component';
import { FashionDesignComponent } from './components/course/fashion-design/fashion-design.component';
import { GraphicDesignComponent } from './components/course/graphic-design/graphic-design.component';
import { WebDesignComponent } from './components/course/web-design/web-design.component';
import { DigitalMarketingComponent } from './components/course/digital-marketing/digital-marketing.component';
import { DigitalPhotographyComponent } from './components/course/digital-photography/digital-photography.component';
import { EventsComponent } from './components/events/events.component';
import { register } from 'swiper/element/bundle';
import { ThreeDanimationComponent } from './components/course/three-danimation/three-danimation.component';
import { EnquiryComponent } from './components/enquiry/enquiry.component';
import { ThankyouEnquiryComponent } from './components/thankyou-enquiry/thankyou-enquiry.component';
import { AmissionCourseComponent } from './components/amission-course/amission-course.component';
import { FaqsPageComponent } from './components/faqs-page/faqs-page.component';
import { PartnerPageComponent } from './components/partner-page/partner-page.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { AdmissionLPComponent } from './components/AdmissionLP/admission-lp/admission-lp.component';
import { ThankyouPartnerComponent } from './components/thankyou-partner/thankyou-partner.component';
import { BVocAnimationVisualEffectsComponent } from './components/course/bvoc-animation-visual-effects/bvoc-animation-visual-effects.component';
import { UiUxComponent } from './components/course/ui-ux/ui-ux.component';
import { MotionGraphicsComponent } from './components/course/motion-graphics/motion-graphics.component';
import { BlenderComponent } from './components/course/blender/blender.component';
import { UnrelComponent } from './components/course/unrel/unrel.component';
import { DigitalAudioVideoEditingComponent } from './components/course/digital-audio-video-editing/digital-audio-video-editing.component';
register();
interface NgxSpinnerConfig {
  type?: string;
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BlogsComponent,
    HeaderComponent,
    FooterComponent,
    LegancyComponent,
    NewsComponent,
    FaqsComponent,
    AboutUsComponent,
    KidzeePreeSchoolComponent,
    TestimonialComponent,
    AdmissionsComponent,
    FranchiseOpportunityComponent,
    BlogComponent,
    AllBlogComponent,
    BlogDetailsComponent,
    StaticHeaderComponent,
    SubMenuComponent,
    PentemindComponent,
    PlayGroupComponent,
    NurseryComponent,
    KindergartenComponent,
    TeacherTrainingComponent,
    DayCareComponent,
    LocateUsComponent,
    OurApproachComponent,
    VisionComponent,
    AwardsAndRecognitionComponent,
    ProgrammesComponent,
    AdmissionsFormComponent,
    OurPresenceComponent,
    PreschoolInAgartalaComponent,
    CentersInAgartalaComponent,
    PartnerLandingComponent,
    CommonHeaderComponent,
    FranchiseThankyouComponent,
    AdmissionThankyouComponent,
    PagenotfoundComponent,
    InnewsComponent,
    PrivacyPolicyComponent,
    FranchiseFormComponent,
    FranchiseComponent,
    ComingsoonComponent,
    StudentsZoneComponent,
    CareersPlacementComponent,
    CareersWorkWithUsComponent,
    CentersComponent,
    TermsOfUseComponent,
    AnimationComponent,
    VisualEffectsComponent,
    GameComponent,
    InteriorDesignComponent,
    FashionDesignComponent,
    GraphicDesignComponent,
    WebDesignComponent,
    DigitalMarketingComponent,
    DigitalPhotographyComponent,
    EventsComponent,
    ThreeDanimationComponent,
    EnquiryComponent,
    ThankyouEnquiryComponent,
    AmissionCourseComponent,
    FaqsPageComponent,
    PartnerPageComponent,
    AdmissionLPComponent,
    ThankyouPartnerComponent,
    BVocAnimationVisualEffectsComponent,
    UiUxComponent,
    MotionGraphicsComponent,
    BlenderComponent,
    UnrelComponent,
    DigitalAudioVideoEditingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatExpansionModule,
    AutocompleteLibModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }

