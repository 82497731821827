<section>
    <div class="main_body">
        <div class="bitCard_content">
            <app-sub-menu></app-sub-menu>
        </div>
        <div class="box1">
            <div class="row">
                <div class="col-md-6">
                    <div class="left_box">
                        <!-- <div class="text1">Resources / Awards & Recognition</div> -->
                        <div class="text2">Frequently Asked<br><span class="color_text">Questions</span></div>
                        <div class="text_para">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Impedit nesciunt, quasi sapiente, eum sequi eligendi facilis accusantium eius esse laboriosam, cumque debitis adipisci provident atque quo molestiae iure quod possimus.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="right_box">
                        <div class="img_box">
                            <img src="../../../assets/img/zica/faqs.png" width="100%" alt="Awards And Recognition">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box2">
            <div class="row">
                <div class="col-md-6">
                    <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                    </div>
                    <div class="text_para">
                     <!-- <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Home"> -->
                    </div>
                </div>
                <div class="col-md-6">
                    <app-faqs></app-faqs>
                </div>
            </div>
        </div>

        <div class="box3">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">

                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <!-- <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p>
                    </div> -->
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">

                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Awards And Recognition">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <!-- <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p>
                    </div> -->
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Awards And Recognition">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <!-- <app-franchise-form></app-franchise-form> -->
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>