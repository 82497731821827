<section>
    <div class="main_body">
        <div class="bitCard_content">
            <app-sub-menu></app-sub-menu>
             </div>
        <div class="pinGroup"></div>
        <div class="box1">
            <div class="row">
                <div class="col-md-6">
                    <div class="text1 ">Careers / Work With Us</div>
                    <div class="text2">Find<span class="color_text"> ZICA Career</span> Info Here
                    </div>
                    <!-- <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                    </div> -->
                    <div class="emailArrow"></div>
                </div>
                <div class="col-md-6">
                    <div class="img_box">
                        <img src="../../../assets/img/zica/roundImg.png" class="placeImg" alt="Work With Us">
                    </div>
                </div>
            </div>
        </div>

        <div class="box2">
            <div class="row">
                
                <div class="col-md-6">
                    <div class="text2 mobileHeading">Working<span class="color_text"> With Us</span>
                    </div>
                    <div class="trangle_img_content">
                        <img src="../../../assets/img/zica/trangle.png"  width="300px" height="300px" alt="Work With Us">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text2 desktopHeading">Working<span class="color_text"> With Us</span>
                    </div>
                    <div class="text_para">
                        <p>There's nothing more satisfying than knowing you've made a difference in the life of another. When you work at ZICA, that's exactly what you'll do. For more than 16 years, our purpose has been to help students build a career into creative industry… and you can help us make that happen with your drive, commitment and passion. For all your hard work, you'll be rewarded with unlimited opportunities to grow professionally and a culture that pays for performance and promotes from within.</p>
                        <p>We want you to get off to a flying start. Our success is based on brilliant people coming together to collaborate, share ideas and inspire each other's creativity.</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="box3">
            <div class="row">
                <div class="col-7">
                    <div class="text2">Job<br><span class="color_text">Recruitments</span>
                    </div>
                    <!-- <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </div> -->
                </div>
                <div class="col-5">
                    <div class="right_goalsImg_box d-flex justify-content-center">
                        <img src="../../../assets/img/zica/jobReq.png" class="reqImg" alt="Work With Us">
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12">
                    <div class="accordion_box">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item" *ngFor="let a of careersPlacementList;let i = index">
                              <div>
                              <h2 class="accordion-header" [attr.id]="'headingOne'+i">
                                <button class="accordion-button1" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+i" aria-expanded="true" [attr.aria-controls]="'collapseOne'+i">
                                  <div class="row w-100">
                                    <div class="col-6"><span> {{a.Title}}</span></div>
                                    <div class="col-6"><span>{{a.short}}</span></div>
                                  </div>
                                    
                                   <!-- <span>{{a.short}}</span> -->
                                </button>
                              </h2>
                              <div [attr.id]="'collapseOne'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne'+i" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                  <p [innerHTML]="a.Long1"></p>
                                </div>
                              </div>
                            </div>
                            </div>
                          </div>
                          <!-- <div class="d-flex justify-content-end mt-3">
                          <div class="view_btn" *ngIf="showBtn" (click)="showMore()">View More</div>
                          <div class="view_btn" style="margin-left: 7px;" *ngIf="closeBtn" (click)="closeMore()">close</div>
                        </div> -->
                        <!-- <div class="numOfFaqs">Showing {{fqindex}} records of {{getFaqCount}}</div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="box5">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                   
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <!-- <div class="text_para">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p>
                  </div> -->
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
               
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Work With Us">
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-6 desktopRes">
                  <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <!-- <div class="text_para">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p>
                  </div> -->
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Work With Us">
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="franchise_form_content">
                      <!-- <app-franchise-form></app-franchise-form> -->
                      <app-amission-course></app-amission-course>
                  </div>
              </div>
          </div>
          </div>

    </div>
</section>