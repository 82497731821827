        <div class="blogs_content">
            <div class="row">
                <div class="col-md-4 mt-4" *ngFor="let item of filteredblogList ;let i=index" >
                    <div class="blog_img_content" routerLink="/blog/{{item.slug}}">
                        <div class="book" *ngIf="item.files">
                            <img src={{item.files[0].url}} height="230px" class="inner-img"  alt={{item.files[0].filename}}>
                        </div>
                        <div class="book" *ngIf="!item.files">
                            <img src="../../../../assets/img/zica/ZICA_Logo.png" height="230px" width="370px"  class="inner-img">
                        </div>
                        <div class="text_info_content">
                            <button class="three_d_btn" routerLink="/blog/{{item.slug}}">3D Animation</button>
                        <div class="profile_name">{{item.Title}}</div>
                        <div class="para">
                        <p>{{item?.Short}}</p>
                    </div>
                   <div class="bottom_text">by Akshit Daksh •{{item.datecreated}}</div>
                </div>
                    </div>
                </div>
            </div>
            <div class="viwe_btn_content mt-5 d-flex justify-content-center">
                <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
            </div>
        </div>      