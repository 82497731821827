<section>
    <div class="box1">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
         </div>
        <div class="row">
            <div class="col-md-6">
                <div class="text1">Course Detail</div>
                <div class="text2">Digital <br>Marketing
                </div>
                <div class="text_para">
                  <ul>
                  <li>Along with ZICA’s program completion certificate, the student can apply for multiple certificates from Google, Facebook, Hubspot, LinkedIn etc.</li>
                  <li>Increase technical and visualization skills.</li>
                  <li>Learn from Google certified professionals.</li>
                </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img_box">
                    <div class="imgSubBox d-flex">
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/digitalD1.png" class="aniImg1" alt="Digital Marketing">
                        </div>
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/digitalD2.png" class="aniImg2" alt="Digital Marketing">
                        </div>
                    </div>
                    <div class="imgContent2">
                        <img src="../../../../assets/img/zica/course/digitalD3.png" class="aniImg3" alt="Digital Marketing">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box2">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-12">
              <div class="left_box">
                <div class="text2">Program In Digital Marketing<span class="color_text"> (6 Months)</span>
                </div>
                <div class="sub_text mt-4 text-white">Learn from <span
                        class="sub_text_color">The Professionals</span></div>
                <div class="text_para">
                    <p>
                      Digital marketing refers to advertising delivered through digital channels such as search engines, websites, social media, email, and mobile apps.  Learn from Google certified professionals. Enroll in a digital marketing course led by industry experts. Acquire comprehensive knowledge of digital marketing strategies, including SEO, social media, email marketing. Gain practical skills through real-world projects and campaigns. Stay updated with the latest trends and tools. Receive personalized guidance and mentorship to develop a strong understanding of digital marketing analytics and optimization. Be industry-ready to excel in today's competitive digital landscape.
                    </p>
                    <div class="sub_text mt-3 text-white">Become a professional artist</div>
                    <div class="text_para">
                        <p>While the term Digital marketing covers a wide range of marketing activities, this program deals with types of digital marketing & important terminologies, web advertising and fundamental components, Google Adwords & account setup, search engines and marketing, SEM in modern business, display advertising, e-mail marketing, mobile marketing, Social media strategy, Google Analytics etc.</p>
                    </div>
                    <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                    <div class="text_para">
                        <p>At the end of the program, the student can be part of any existing strong digital marketing team or individually work comfortably with a deep domain understanding of digital marketing, social media and brand management.</p>
                    </div>
                    <div class="sub_text mt-4 text-white">Program Highlights</div>
                        <div class="text_para">
                        <ul>
                               <li>Increase technical and visualization skills</li>
                               <li>Learn a complete digital marketing pipeline</li>
                               <li>Placement support</li>
                               <li>Get four certificates (ZICA, Google, Facebook and Hubspot)</li>
                        </ul>  
                        </div>
                        <div class="accordion mt-2" id="accordionExample">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Module 01: Module Outline

                                </button>
                              </h2>
                              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                <div class="row">
                                  <div class="col-md-6">
                                    <ul>
                                        <li>Introduction to Digital Marketing.</li>
                                      <li>Search Marketing</li>
                                      <li>SEO (Search Engine Optimization)</li>
                                      <li>SEM (Search Engine Marketing)</li>
                                    </ul>
                                  </div>
                                  <div class="col-md-6">
                                    <li>Other Types of Online Marketing</li>
                                    <li>Social Media Marketing</li>
                                    <li>Inbound Marketing</li>
                                    <li>Web Analytics</li>
                                  </div>
                                </div> 
                                </div>
                              </div>
                            </div>
                            
                          </div>

                          <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                          <div class="row mt-3">
                            <div class="col-md-6">
                                <ul>
                                  <li>Search Engine Optimizer</li> 
                                  <li>SEM manager</li>
                                  <li>Social Media Marketing Manager</li>
                                  </ul>
                            </div>
                            <div class="col-md-6">
                                <ul>
                                  <li>Content Marketing Manager</li>
                                  <li>Inbound Marketing Manager</li>
                                  <li>Web Analyst</li>
                                  </ul>
                            </div>
                          </div>
                </div>
            </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="list_box">
                    <div class="list">
                    <div class="letf_text sub_text_color">Level</div>
                    <div class="right_text">Intermediate</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Duration</div>
                    <div class="right_text">6 Months</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Eligibility</div>
                    <div class="right_text">10th Pass</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Avg. Package</div>
                    <div class="right_text">12LPA</div>
                </div>
                </div>
            </div> -->
        </div>
    </div>

    <div class="box3">
        <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
        <div class="text_para text-center">
            <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                Visual FX Labs And Imaging Technologies.</p>
        </div>
        <app-blogs></app-blogs>
        </div>
        <div class="box4 mt-5">
            <div class="row">
                <div class="col-md-6">
                    <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                    </div>
                    <div class="text_para">
                     <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Digital Marketing">
                    </div>
                </div>
                <div class="col-md-6">
                    <app-faqs></app-faqs>
                </div>
            </div>
        </div>
        
        <div class="box5">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                   
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <div class="text_para">
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p> -->
                  </div>
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
               
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Digital Marketing">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <div class="text_para">
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p> -->
                    </div>
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Digital Marketing">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <!-- <app-franchise-form></app-franchise-form> -->
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
</section>