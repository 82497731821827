import { Component } from '@angular/core';

@Component({
  selector: 'app-bvoc-animation-visual-effects',
  templateUrl: './bvoc-animation-visual-effects.component.html',
  styleUrls: ['./bvoc-animation-visual-effects.component.css','../../../../../src/app/components/commonCss/program.css']
})
export class BVocAnimationVisualEffectsComponent {

}
