<section>
    <div class="box1">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
         </div>
        <div class="row">
            <div class="col-md-6">
                <div class="text1">Course Detail</div>
                <div class="text2">Fashion Design
                </div>
                <div class="text_para">
                  <ul>
                      <li>The program covers a broad spectrum of topics, including drawing, design principles, garment construction, textiles, CAD software, and fashion marketing.</li> 
                      <li>Students have the opportunity to apply their knowledge through hands-on design projects, draping, pattern making, and garment construction, allowing them to develop practical design skills.</li>
                      <li>The curriculum includes modules on fashion forecasting, merchandising, export-import business, and quality control, preparing students to understand and navigate the real-world challenges of the fashion industry.</li>
                      <li>Students are equipped with essential digital design skills through training through industry standard software, which are vital for contemporary fashion design and presentation.</li>
                      <li>The program emphasizes portfolio development, enabling students to showcase their work and secure opportunities in the fashion industry, whether in design, styling, photography, or other related fields.</li>
                  </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img_box">
                    <div class="imgSubBox d-flex">
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/faction1.png" class="aniImg1" alt="Fashion Design">
                        </div>
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/faction2.png" class="aniImg2" alt="Fashion Design">
                        </div>
                    </div>
                    <div class="imgContent2">
                        <img src="../../../../assets/img/zica/course/faction3.png" class="aniImg3" alt="Fashion Design">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box2">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-12">
                <div class="left_box">
                    <div class="text2">PDP In Fashion Design<span class="color_text"> (24 Months)</span>
                    </div>
                    <div class="sub_text mt-4 text-white">Learn from<span
                            class="sub_text_color"> The Professionals</span></div>
                    <div class="text_para">
                        <p>
                          Fashion design is the art of applying design, aesthetics and natural beauty to clothing and its accessories. It is influenced by cultural and social attitudes and has diversified over time and place. Fashion designers work in several ways in designing clothing and accessories. Because of the time required to bring a garment onto the market, designers must at times anticipate changes to consumer tastes. Designers research fashion trends and interpret them for their audience and they work within a wide range of materials, colours, patterns and styles. Though most clothing worn for everyday wear falls within a narrow range of conventional styles, unusual garments are usually sought for special occasions such as evening wear or party dresses.
                        </p>
                        <div class="sub_text mt-3 text-white">Become a Professional Artist</div>
                        <div class="text_para">
                            <p>To become a professional fashion designer, one must cultivate creativity, develop technical skills, and stay attuned to industry trends. Start by mastering drawing, design principles, and garment construction. Utilize industry standard software for digital design. Gain hands-on experience through projects and internships. Build a strong portfolio showcasing your unique style and versatility. Network within the fashion community and stay updated on emerging trends. With dedication and persistence, a career in fashion design can be achieved, allowing one to express their artistic vision through clothing and make a mark in the ever-evolving world of fashion.</p>
                        </div>
                        <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                        <div class="text_para">
                            <p>Fashion designers have a myriad of opportunities in today's dynamic industry. They can work for established fashion houses, create their own brands, or freelance. Designers also find roles in costume design, fashion journalism, and styling for movies, TV, and advertising. The rise of e-commerce and social media offers avenues for online retail and personal branding. Moreover, sustainable fashion and tech-driven wearables open exciting prospects. The fashion world continually evolves, providing diverse and rewarding career paths for creative minds.</p>
                        </div>
                        <div class="sub_text mt-4 text-white">Program Highlights</div>
                            <div class="text_para">
                              <ul>
                                <li>Comprehensive Curriculum</li>
                                <li>Practical Experience</li>
                                <li>Industry Relevance program</li>
                                <li>Learning Digital Skills</li>
                                <li>Emphasizes portfolio development</li>
                              </ul>
                            </div>
                            <div class="accordion mt-2" id="accordionExample">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Module 01: 1 Duration: 6 Months
                                    </button>
                                  </h2>
                                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <ul>
                                          <li>Drawing + geometry</li>
                                        <li>Basic design - elements and principals of design + colour theory</li>
                                        <li>Fashion model drawing + illustration</li> 
                                        <li>Design project – theme concept mood board</li> 
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <ul>
                                          <li>Basic pattern making</li> 
                                        <li>Basic garment construction</li>
                                        <li>History of art & design -Indian and Western</li> 
                                        <li>Introduction to embroideries - Basic Embroidery and Indian Embroidery</li>
                                        <li>Textile Science  1 – Fiber to Fabric</li>
                                        </ul>
                                      </div>
                                    </div> 
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Module 02:Duration: 6 Months
                                    </button>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <ul>
                                          <li>Introduction to CAD -PHOTOSHOP and ILLUSTRATOR  (CORELDRAW)</li>
                                        <li>Basic Draping -Draping skirt and Bodice </li>
                                        <li>DESIGN PROJECT -I–(SKIRT) Research - Design development -Theme Concept Mood board - Pattern Making -Garment Construction</li>
                                        <li>DESIGN PROJECT -II–(DRESS) Research -Design development -Theme Concept Mood   board -Pattern Making -Garment Construction</li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <ul>
                                          <li>Histroy Of Textiles -Indian and Western</li> 
                                        <li>Fashion Marketing</li>
                                        <li>Fashion Forecasting - Market Research</li>
                                        <li>Textile Science  2 – Dyeing and Printing</li> 
                                        </ul>
                                      </div>
                                    </div> 
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Module 03: Duration: 6 Months
                                    </button>
                                  </h2>
                                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <ul>
                                           <li>Design project – draping –draped dress</li>
                                         <li>Design project -iii– (evening wear) research -design development -theme concept mood board -pattern making -garment construction</li>
                                         <li>Design project -iv– (ethnic wear) research -design development -theme concept mood board -pattern making -garment construction</li>
                                         <li>CAD for fashion and textiles</li>
                                         
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <ul>
                                        <li>Fashion Merchandising </li>
                                         <li>Managing export-import business</li>
                                         <li>Manufacturing methods & processes and quality control</li>
                                         <li>Portfolio development </li>
                                        </ul>
                                      </div>
                                    </div> 
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                          Module 04: Duration: 6 Months
                                      </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                      <div class="accordion-body">
                                      <div class="row">
                                        <div class="col-md-6">
                                          <ul>
                                            <li>Project styling - for films - advertisement</li> 
                                        <li>Textile- product development</li>
                                        <li>Fashion photography</li>
                                        <li>Fashion accessories</li> 
                                        <li>Design project -v–(final collection ) research -design development -theme concept mood board -pattern     making -garment construction</li>
                                          </ul>
                                        </div>
                                        <div class="col-md-6">
                                          <ul>
                                            <li>Portfolio</li>
                                            <li>Resource management</li>  
                                            <li>Execution skills</li>  
                                            <li>Result orientation</li>
                                          </ul>
                                        </div>
                                      </div>  
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                              <div class="row mt-3">
                                <div class="col-6">
                                    <ul>
                                      <li>Fashion Designer</li>
                                      <li>Assistant Designer</li>
                                      <li>Creative Director</li>
                                      <li>Patternmaker</li>
                                      <li>Fashion Illustrator</li>
                                      <li>Textile Designer</li>
                                      <li>Costume Designer</li>
                                      </ul>
                                </div>
                                <div class="col-6">
                                    <ul>
                                      <li>Fashion Stylist</li>
                                      <li>Fashion Merchandiser</li>
                                      <li>Fashion Buyer</li>
                                      <li>Fashion Consultant</li>
                                      <li>Fashion Blogger/Influencer</li>
                                      <li>Fashion Editor</li>
                                      <li>Fashion Marketing Manager</li>
                                      <li>Fashion Researcher</li>
                                      </ul>
                                </div>
                              </div>
                             
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="list_box">
                    <div class="list">
                    <div class="letf_text sub_text_color">Level</div>
                    <div class="right_text">Intermediate</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Duration</div>
                    <div class="right_text">24 Months</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Eligibility</div>
                    <div class="right_text">10th Pass</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Avg. Package</div>
                    <div class="right_text">12LPA</div>
                </div>
                </div>
            </div> -->
        </div>

        <div class="row flex-column-reverse flex-lg-row mt-5">
          <div class="col-md-12">
              <div class="left_box">
                  <div class="text2">Professional Program In Fashion Design<span class="color_text"> (12 Months)</span>
                  </div>
                  <div class="sub_text mt-4 text-white">Learn from<span
                          class="sub_text_color"> The Professionals</span></div>
                  <div class="text_para">
                      <p>
                        Learning from a professional fashion designer provides invaluable insights. Gain firsthand knowledge of design processes, industry trends, and practical skills. Understand the business side, from marketing to production. Mentorship fosters creativity and helps build a network crucial for a successful career in the fashion world.
                      </p>
                      <div class="sub_text mt-3 text-white">Become a Professional Artist</div>
                      <div class="text_para">
                          <p>To become a professional fashion designer, one must master design principles, color theory, and garment construction. Developing strong drawing skills and utilizing digital design tools is crucial. Building a unique design style and staying updated with industry trends are vital. Practical experience and a robust portfolio are key to success in this creative and competitive field.</p>
                      </div>
                      <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                      <div class="text_para">
                          <p>To be an industry-ready fashion designer, hone your design skills, grasp the latest trends, and master digital tools. Gain hands-on experience through internships or projects. Learn about production, marketing, and sustainability in fashion. A dynamic portfolio and a passion for innovation will set you up for success in the competitive fashion industry.</p>
                      </div>
                      <div class="sub_text mt-4 text-white">Program Highlights</div>
                          <div class="text_para">
                            <ul>
                                <li>Increase design and visualization skills</li>
                                <li>Learn complete fashion design production pipeline</li>
                                <li>Continuous evaluation through examination, competitions and activities</li>
                                <li>Regular onsite visit for site and meeting with professionals on site.</li>
                            </ul>
                          </div>
                          <div class="accordion mt-2" id="accordionExample1">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne1">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                      Module 01: 1 (6 Months) 
                                  </button>
                                </h2>
                                <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                                  <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-md-6">
                                       <ul>
                                        <li>Drawing + geometry</li>
                                      <li>Basic design - elements and principals of design + colour theory</li>
                                      <li>Fashion model drawing + illustration</li> 
                                      <li>Design project – theme concept mood board 
                                      <li>Basic pattern making</li> 
                                       </ul> 
                                    </div>
                                    <div class="col-md-6">
                                      <ul>
                                        <li>Basic garment construction</li>
                                      <li>History of art & design -Indian and Western</li> 
                                      <li>Introduction to embroideries - Basic Embroidery and Indian Embroidery</li>
                                      <li>Textile Science  1 – Fiber to Fabric </li>
                                      </ul>
                                    </div>
                                  </div> 
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo1">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1">
                                      Module 02: (6 Months) 
                                  </button>
                                </h2>
                                <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample1">
                                  <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <ul>
                                        <li>Introduction to CAD -PHOTOSHOP and ILLUSTRATOR  (CORELDRAW)</li>
                                        <li>Basic Draping -Draping skirt and Bodice</li> 
                                        <li>DESIGN PROJECT -I–(SKIRT) Research - Design development -Theme Concept Mood board - Pattern Making -Garment Construction</li>
                                        <li>DESIGN PROJECT -II–(DRESS) Research -Design development -Theme Concept Mood   board -Pattern Making -Garment Construction</li>
                                         
                                      </ul>
                                    </div>
                                    <div class="col-md-6">
                                      <ul>
                                        <li>History Of Textiles Indian And Western</li> 
                                        <li>Fashion Marketing</li>
                                        <li>Fashion Forecasting - Market Research</li>
                                        <li>Textile Science  2 – Dyeing and Printing</li>
                                      </ul>
                                    </div>
                                  </div> 
                                  </div>
                                </div>
                              </div>
                            
                            </div>

                            <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                            <div class="row mt-3">
                              <div class="col-6">
                                  <ul>
                                    <li>Fashion Designer</li>
                                    <li>Assistant Designer</li>
                                    <li>Patternmaker</li>
                                    <li>Fashion Illustrator</li>
                                    </ul>
                              </div>
                              <div class="col-6">
                                  <ul>
                                    <li>Textile Designer</li>
                                    <li>Costume Designer</li>
                                    <li>Fashion Researcher</li>
                                    </ul>
                              </div>
                            </div>
                           
                  </div>
              </div>
          </div>
          <!-- <div class="col-md-4">
              <div class="list_box">
                  <div class="list">
                  <div class="letf_text sub_text_color">Level</div>
                  <div class="right_text">Intermediate</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Duration</div>
                  <div class="right_text">12 Months</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Eligibility</div>
                  <div class="right_text">10th Pass</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Avg. Package</div>
                  <div class="right_text">12LPA</div>
              </div>
              </div>
          </div> -->
      </div>
    </div>

    <div class="box3">
        <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
        <div class="text_para text-center">
            <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                Visual FX Labs And Imaging Technologies.</p>
        </div>
        <app-blogs></app-blogs>
        </div>
        <div class="box4 mt-5">
            <div class="row">
                <div class="col-md-6">
                  <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                  </div>
                  <div class="text_para">
                   <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Fashion Design">
                  </div>
                </div>
                <div class="col-md-6">
                    <app-faqs></app-faqs>
                </div>
            </div>
        </div>
        
        <div class="box5">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                   
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <div class="text_para">
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p> -->
                  </div>
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
               
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Fashion Design">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <div class="text_para">
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p> -->
                    </div>
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Fashion Design">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <!-- <app-franchise-form></app-franchise-form> -->
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
</section>