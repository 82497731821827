<section>
    <div class="whiteHeader_dark desktopNav" id="whiteHeader_dark">
        <div class="side_con">
            <a href="https://wa.me/9152010855" target="_blank">
                <div class="watsapp_icon"></div>
            </a>
            <div class="download_icon"></div>
        </div>
        <nav class="nav navbar navbar-expand-lg">
            <div class="container-fluid">
                <div class="navbar-brand d-flex">
                    <div class="">
                        <a href="">
                            <img src="../../../../assets/img/zica/ZICA_Logo_finle.png" class="zica_logo" alt=""
                                routerLink="">
                        </a>
                    </div>
                    <div class="">
                        <img src="../../../../assets/img/zica/year_logo.png" class="header_logo2 zica_logo"
                            routerLink="" alt="">
                    </div>
                </div>
                <div class="menu_Img" data-bs-toggle="collapse" data-bs-target="#navbarNav" id="menuClose">
                    <div class="menu_icon"></div>
                </div>
                <div class="collapse navbar-collapse " id="navbarNav">
                    <ul class="navbar-nav ul_content">

                        <li class="nav-item">
                            <a class="nav-link" href="enquiry#About">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="enquiry#OurPrograms">Our Programs</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="enquiry#Placement">Placement</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="enquiry#Testimonial">Testimonial</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="enquiry#FAQs">FAQs</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>

    <div class="whiteHeader_dark mobile_nav" id="whiteHeader_dark">
        <div class="side_con">
            <a href="https://wa.me/9152010855" target="_blank">
                <div class="watsapp_icon"></div>
            </a>
            <div class="download_icon"></div>
        </div>
        <nav class="nav navbar navbar-expand-lg">
            <div class="container-fluid">
                <div class="navbar-brand d-flex">
                    <div class="">
                        <img src="../../../../assets/img/zica/ZICA_Logo_finle.png" class="zica_logo" alt=""
                            routerLink="">
                    </div>
                    <div class="">
                        <img src="../../../../assets/img/zica/year_logo.png" class="header_logo2" alt="">
                    </div>
                </div>
                <div class="menu_Img" data-bs-toggle="collapse" data-bs-target="#navbarNav" id="menuClose">
                    <div class="menu_icon"></div>
                </div>
                <div class="collapse navbar-collapse " id="navbarNav">
                    <ul class="navbar-nav ul_content">

                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#About" (click)="clickMenu('About')">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#OurPrograms"
                                (click)="clickMenu('Our Programs')">Our Programs</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#Placement"
                                (click)="clickMenu('Placement')">Placement</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#Testimonial"
                                (click)="clickMenu('Testimonial')">Testimonial</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#FAQs" (click)="clickMenu('FAQs')">FAQs</a>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    </div>
</section>
<section>
    <div class="box1" id="admissionform">
        <div class="row">
            <div class="col-md-8 paddin_zero">
                <!-- <img src="../../../assets/img/zica/banner/game_banner.png" class="img-fluid" width="100%" alt=""> -->
                <img src="../../../assets/img/zica/banner/ZICA Partner_01-03.jpg" class="img-fluid" width="100%" alt="">
            </div>
            <div class="col-md-4 paddin_zero">
                <div class="bg_form">
                    <div class="admissionForm">
                        <form action="" [formGroup]="admissionForm">
                            <div class="row">
                                <div class="col-12">
                                    <label for="">Name<sup>*</sup></label>
                                    <input type="text" class="form-control" formControlName="fname"
                                        [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" />
                                    <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                        <span class="text-danger msg_txt"
                                            *ngIf="(f.fname.touched || submitted) && f.fname.errors?.required">
                                            First Name is required
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 mt-1">
                                    <label for="">Mobile<sup>*</sup></label>
                                    <input type="text" class="form-control" (keyup)="getMobileNO();"
                                        formControlName="mobile"
                                        [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                        <span *ngIf="f.mobile.errors?.required">Mobile is required.</span>
                                        <span *ngIf="f.mobile.errors?.pattern">Please, Enter 10 digit Mobile.</span>
                                    </div>
                                    <div *ngIf="otpInput" class="validMsg">OTP sent successfully</div>
                                </div>
                                <div class="col-12 mt-1" *ngIf="otpInput">
                                    <label for="">OTP<sup>*</sup></label>
                                    <input type="text" class="form-control" (keyup)="verifyOTP();" formControlName="otp"
                                        [ngClass]="{ 'is-invalid': submitted && f.otp.errors }">
                                    <div *ngIf="submitted && f.otp.errors" class="invalid-feedback">
                                        <span *ngIf="f.otp.errors?.required">OTP is required.</span>
                                    </div>
                                    <div *ngIf="otp_ValidMsg" class="validMsg">OTP verified successfully</div>
                                    <div *ngIf="otp_inValidMsg" class="inValidMsg">please enter valid otp</div>
                                </div>
                                <div class="col-12 mt-1">
                                    <label for="">Select Course<sup>*</sup></label>
                                    <select name="" id="" class="form-control" formControlName="course"
                                        [ngClass]="{ 'is-invalid': submitted && f.course.errors }">
                                        <option value="">Select Course</option>
                                        <option value="{{a.course}}" *ngFor="let a of getCourseList">{{a.course}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && f.course.errors" class="invalid-feedback">
                                        <span class="text-danger msg_txt"
                                            *ngIf="(f.course.touched || submitted) && f.course.errors?.required">
                                            Course is required
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 mt-1">
                                    <label for="">Email Id<sup>*</sup></label>
                                    <input type="text" class="form-control" formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <span class="text-danger msg_txt"
                                            *ngIf="(f.email.touched || submitted) && f.email.errors?.required">
                                            Email is required
                                        </span>
                                        <span class="text-danger msg_txt"
                                            *ngIf="f.email.touched && f.email.errors?.email">
                                            Enter a valid email address
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 mt-1" *ngIf="fromcenterpage!='true'">
                                    <label for="">State/District<sup>*</sup></label>
                                    <select name="" id="" class="form-control" formControlName="state"
                                        (change)="selectState($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                                        <option value="">State/District</option>
                                        <option value="{{state.State_Name}}" *ngFor="let state of stateList">
                                            {{state.State_Name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                        <span class="text-danger msg_txt"
                                            *ngIf="(f.state.touched || submitted) && f.state.errors?.required">
                                            State/District is required
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 mt-1" *ngIf="fromcenterpage!='true'">
                                    <label for="">City<sup>*</sup></label>
                                    <select name="" id="" class="form-control" formControlName="city"
                                        (change)="selectCity($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                                        <option value="">City*</option>
                                        <option value="{{city.City_Name}}" *ngFor="let city of cityList">{{city.City_Name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                        <span class="text-danger msg_txt"
                                            *ngIf="(f.city.touched || submitted) && f.city.errors?.required">
                                            City is required
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 mt-1" *ngIf="fromcenterpage!='true'">
                                    <label for="">Select Center<sup>*</sup></label>
                                    <select name="" id="" class="form-control" formControlName="center"
                                        (change)="selectCentet(selectedDevice)" [(ngModel)]="selectedDevice"
                                        [ngClass]="{ 'is-invalid': submitted && f.center.errors }">
                                        <option value="">center*</option>
                                        <option value="{{franchise.Franchisee_Code}}"
                                            *ngFor="let franchise of franchiseeList" #center>{{franchise.Franchisee_Name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.center.errors" class="invalid-feedback">
                                        <span class="text-danger msg_txt"
                                            *ngIf="(f.center.touched || submitted) && f.center.errors?.required">
                                            Center is required
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="sub_btn d-flex justify-content-center mt-2">
                            <button class="btn submit_btn" (click)="validationForm()">Submit</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="box3 mt-5" id="About">
        <div class="row">
            <div class="col-md-6">
                <div class="lagacyImg_content scrollPadding">
                    <div class="text2 text-left d-lg-none d-md-block">About <span class="color_text">ZICA</span></div>
               
                    <div class="firstImgCOntent d-flex">
                        <div class="firstImgBox"><img src="../../../assets/img/zica/placement_logo/pl_1.jpg"
                                class="aniImg1" alt="About Us"></div>
                        <div class="firstImgBox"><img src="../../../assets/img/zica/placement_logo/pl_2.jpg"
                                class="secondImg aniImg2" alt="About Us"></div>
                    </div>
                    <div class="secontImgCOntent">
                        <div class="firstImgBox">
                            <img src="../../../assets/img/zica/placement_logo/pl_3.jpg" alt="About Us" class="aniImg3">
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-6">
                <div class="text2 text-left d-none d-lg-block">About <span class="color_text">ZICA</span></div>
                <div class="text_para mt-4">
                    <p>One Of The Top Institutes in Animation ZICA is the part of Zee Group, India’s Largest TV Network
                        which comprises of Channels like Zee Tv, Zee Cinema, Zee News, & Tv, & Pictures etc. ZICA as a
                        brand started its journey 20 yrs back in Mumbai. This institute is the brainchild of the
                        Chairman of Zee Group Shri Subhash Chandra ji who is also known as the Media Baron of India</p>
                </div>
            </div>
        </div>
    </div>
    <div class="box4 mt-5" id="OurPrograms">
        <nav>
			<div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                <div class="buttonList">
				<button class="nav-link active" id="nav-animation-tab" data-bs-toggle="tab" data-bs-target="#nav-animation" type="button" role="tab" aria-controls="nav-animation" aria-selected="true">3D Animation</button>
				<button class="nav-link" id="nav-Effects-tab" data-bs-toggle="tab" data-bs-target="#nav-Effects" type="button" role="tab" aria-controls="nav-Effects" aria-selected="false">Visual Effects</button>
				<button class="nav-link" id="nav-Design-tab" data-bs-toggle="tab" data-bs-target="#nav-Design" type="button" role="tab" aria-controls="nav-Design" aria-selected="false">Graphic Design</button>

				<button class="nav-link" id="nav-WebDesign-tab" data-bs-toggle="tab" data-bs-target="#nav-WebDesign" type="button" role="tab" aria-controls="nav-WebDesign" aria-selected="false">Web Design</button>
				<button class="nav-link" id="nav-Interior-tab" data-bs-toggle="tab" data-bs-target="#nav-Interior" type="button" role="tab" aria-controls="nav-Interior" aria-selected="false">Interior Design</button>

                <button class="nav-link" id="nav-Marketing-tab" data-bs-toggle="tab" data-bs-target="#nav-Marketing" type="button" role="tab" aria-controls="nav-Marketing" aria-selected="true">Digital Marketing</button>
				<button class="nav-link" id="nav-Photography-tab" data-bs-toggle="tab" data-bs-target="#nav-Photography" type="button" role="tab" aria-controls="nav-Photography" aria-selected="false">Digital Photography</button>
				<!-- <button class="nav-link" id="nav-Multimedia-tab" data-bs-toggle="tab" data-bs-target="#nav-Multimedia" type="button" role="tab" aria-controls="nav-Multimedia" aria-selected="false">Multimedia</button> -->

                <button class="nav-link" id="nav-2Danimation-tab" data-bs-toggle="tab" data-bs-target="#nav-2Danimation" type="button" role="tab" aria-controls="nav-2Danimation" aria-selected="true">2D Animation</button>
				<button class="nav-link" id="nav-Gaming-tab" data-bs-toggle="tab" data-bs-target="#nav-Gaming" type="button" role="tab" aria-controls="nav-Gaming" aria-selected="false">Gaming</button>
				<button class="nav-link" id="nav-VisualEffect-tab" data-bs-toggle="tab" data-bs-target="#nav-VisualEffect" type="button" role="tab" aria-controls="nav-VisualEffect" aria-selected="false">B.Voc in Animation & Visual Effect </button>
            </div>
            </div>
		</nav>
		<div class="tab-content p-3" id="nav-tabContent">
			<div class="tab-pane fade active show" id="nav-animation" role="tabpanel" aria-labelledby="nav-animation-tab">
				<div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-none d-lg-block">3D Animation</div>
                        <div class="text_para mt-4">
                            <p>This is a comprehensive certificate program that deals with all the important aspects
                                of 2D, 3D & Visual Effects. The student begins with learning all the creative
                                development exercises with manual and digital 2D animation processes. The second
                                module starts with all the basic elements of 3D digital art which in the later
                                module progresses into advanced 3D Digital art and character animation. The
                                remaining modules of the program deal with different aspects of Dynamics, tracking,
                                and advanced Visual effects which are generally used in production studios.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-lg-none d-md-block">3D Animation</div>
                      <div class=" d-flex justify-content-center">
                        <div class="animationImg_content">
                            <img src="../../../assets/img/zica/palette.png" class="animationImg img-fluid"
                                alt="enquiry">
                        </div>
                      </div>
                    </div>
                </div>
			</div>
			<div class="tab-pane fade" id="nav-Effects" role="tabpanel" aria-labelledby="nav-Effects-tab">
                <div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-none d-lg-block">Visual Effects</div>
                        <div class="text_para mt-4">
                            <p>Comprehensive Animation and Visual Effects (VFX) program to cater to exceedingly growing demand in the industry Produce Short film by using Live action, 3D and Visual Effects.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-lg-none d-md-block">Visual Effects</div>
                        <div class="d-flex justify-content-center">
                        <div class="animationImg_content">
                            <img src="../../../assets/img/zica/vs_design.png" class="animationImg img-fluid"
                                alt="enquiry">
                        </div>
                    </div>
                    </div>
                </div>
			</div>
			<div class="tab-pane fade" id="nav-Design" role="tabpanel" aria-labelledby="nav-Design-tab">
				<div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-none d-lg-block">Graphic Design</div>
                        <div class="text_para mt-4">
                            <p>Learn to create concept Game Art, Storyboard, Digital Paint, Matte paint, Crate
                                props, environment and character.
                                To provide students with a foundational understanding of the principles and concepts
                                specific to the field of game art.
                                To teach the fundamental principles of computer graphics, including pixel
                                manipulation, rendering, and image processing.
                                To train students in the art of creating organic and character models with a focus
                                on topology, realism, and aesthetics.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-lg-none d-md-block">Graphic Design</div>
                        <div class="d-flex justify-content-center">
                        <div class="animationImg_content">
                            <img src="../../../assets/img/zica/gd.png" class="animationImg img-fluid" alt="enquiry">
                        </div>
                    </div>
                    </div>
                </div>
			</div>


            <div class="tab-pane fade" id="nav-WebDesign" role="tabpanel" aria-labelledby="nav-WebDesign-tab">
				<div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-none d-lg-block">Web Design</div>
                        <div class="text_para mt-4">
                            <ul>
                                <li>A complete web design program as per current market trend</li>
                                <li>Incredible placement record.</li>
                                <li>Create an outstanding web design portfolio.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-lg-none d-md-block">Web Design</div>
                        <div class="d-flex justify-content-center">
                        <div class="animationImg_content">
                            <img src="../../../assets/img/zica/course/web1.png" class="animationImg img-fluid" alt="enquiry">
                        </div>
                    </div>
                    </div>
                </div>
			</div>
			<div class="tab-pane fade" id="nav-Interior" role="tabpanel" aria-labelledby="nav-Interior-tab">
				<div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-none d-lg-block">Interior Design</div>
                        <div class="text_para mt-4">
                            <ul>
                                <li>To introduce students to the fundamental principles, concepts, and theories of interior design as a discipline.</li>
                                <li>To specialize in designing residential interiors, considering the unique requirements and preferences of homeowners.</li>
                                <li>To learn advanced computer rendering techniques for creating realistic 3D walkthroughs of interior spaces.</li>
                                <li>To gain expertise in designing restaurant and hospitality spaces, considering customer experience and functional requirements.</li>
                                <li>To develop research and communication skills essential for academic and professional advancement.</li>
                                <li>To expand and update the professional portfolio with advanced and specialized projects.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center">
                        <div class="text2 text-left mt-5 d-lg-none d-md-block">Interior Design</div>
                        <div class="animationImg_content">
                            <img src="../../../assets/img/zica/course/Interior1.png" class="animationImg img-fluid"
                                alt="enquiry">
                        </div>
                    </div>
                </div>
			</div>
			<div class="tab-pane fade" id="nav-animation" role="tabpanel" aria-labelledby="nav-animation-tab">
				<p><strong>3This is some placeholder content the Contact tab's associated content.</strong>
					Clicking another tab will toggle the visibility of this one for the next.
					The tab JavaScript swaps classes to control the content visibility and styling. You can use it with
					tabs, pills, and any other <code>.nav</code>-powered navigation.</p>
			</div>


            <div class="tab-pane fade" id="nav-Photography" role="tabpanel" aria-labelledby="nav-Photography-tab">
				<div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-none d-lg-block">Digital Photography</div>
                        <div class="text_para mt-4">
                            <ul>
                                <li>Learn about camera types, accessories, care for equipment, and mastering exposure.</li>
                                <li>Understand various lens types, characteristics, and filters.</li>
                                <li>Dive into features, styles, composition, lighting, and motion photography.</li>
                                <li>Master photo editing tools, tones, contrast, and advanced techniques.</li>
                                <li>Recreate images, blend art with photography, and delve into color management and printing.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-lg-none d-md-block">Digital Photography</div>
                        <div class="d-flex justify-content-center">
                        <div class="animationImg_content">
                            <img src="../../../assets/img/zica/course/digitalPhoto1.png" class="animationImg img-fluid"
                                alt="enquiry">
                        </div>
                    </div>
                    </div>
                </div>
			</div>
			<!-- <div class="tab-pane fade" id="nav-Multimedia" role="tabpanel" aria-labelledby="nav-Multimedia-tab">
				<p><strong>2This is some placeholder content the Profile tab's associated content.</strong>
					Clicking another tab will toggle the visibility of this one for the next.
					The tab JavaScript swaps classes to control the content visibility and styling. You can use it with
					tabs, pills, and any other <code>.nav</code>-powered navigation.</p>
			</div> -->
			<div class="tab-pane fade" id="nav-animation" role="tabpanel" aria-labelledby="nav-animation-tab">
				<p><strong>3This is some placeholder content the Contact tab's associated content.</strong>
					Clicking another tab will toggle the visibility of this one for the next.
					The tab JavaScript swaps classes to control the content visibility and styling. You can use it with
					tabs, pills, and any other <code>.nav</code>-powered navigation.</p>
			</div>

            <div class="tab-pane fade" id="nav-Gaming" role="tabpanel" aria-labelledby="nav-Gaming-tab">
                <div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-none d-lg-block">Game Design</div>
                        <div class="text_para mt-4">
                            <p>Comprehensive Graphic design program to cater extremely growing demand in the
                                industry.</p>
                            <p>A wide range of graphic design job opportunities.
                                Introduction of Augmented and Virtual Reality.
                                Create an outstanding portfolio</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-lg-none d-md-block">Game Design</div>
                        <div class="d-flex justify-content-center">
                        <div class="animationImg_content">
                            <img src="../../../assets/img/zica/gd.png" class="animationImg img-fluid" alt="enquiry">
                        </div>
                    </div>
                    </div>
                </div>
			</div>
			
            <div class="tab-pane fade" id="nav-2Danimation" role="tabpanel" aria-labelledby="nav-2Danimation-tab">
				<div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-none d-lg-block">2D Animation</div>
                        <div class="text_para mt-4">
                            <p>This is a comprehensive certificate program that deals with all the important aspects
                                of 2D, 3D & Visual Effects. The student begins with learning all the creative
                                development exercises with manual and digital 2D animation processes. The second
                                module starts with all the basic elements of 3D digital art which in the later
                                module progresses into advanced 3D Digital art and character animation. The
                                remaining modules of the program deal with different aspects of Dynamics, tracking,
                                and advanced Visual effects which are generally used in production studios.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-lg-none d-md-block">2D Animation</div>
                        <div class="d-flex justify-content-center">
                        <div class="animationImg_content">
                            <img src="../../../assets/img/zica/palette.png" class="animationImg img-fluid"
                                alt="enquiry">
                        </div>
                    </div>
                    </div>
                </div>
			</div>

            <div class="tab-pane fade" id="nav-VisualEffect" role="tabpanel" aria-labelledby="nav-profile-tab">
				<div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-none d-lg-block">B.Voc. in Animation Visual Effects</div>
                        <div class="text_para mt-4">
                            <p>This B.Voc. in Animation & Visual Effects Program has been meticulously crafted to empower students with the essential skills and knowledge required by a professional in the field to tackle projects of varying complexities. The course is structured to be project-centric and hands-on, complemented by lectures, workshops, industry visits, and live presentations, all aimed at acquainting students with the dynamic realm of Animation and Visual Effects.
                                The B.Voc. in Animation & Visual Effects program is powered by Medhavi Skills University,  the course is designed to herald a transformative learning experience, tailored to meet the demands of the future global workforce.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-lg-none d-md-block">B.Voc. in Animation Visual Effects</div>
                     <div class="d-flex justify-content-center">
                        <div class="animationImg_content">
                            <img src="../../../assets/img/zica/voc_animation.png" class="animationImg img-fluid"
                                alt="enquiry">
                        </div>
                    </div>
                    </div>
                </div>
			</div>

            <div class="tab-pane fade" id="nav-Marketing" role="tabpanel" aria-labelledby="nav-Marketing-tab">
				<div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-none d-lg-block">Digital Marketing</div>
                        <div class="text_para mt-4">
                            <ul>
                                <li>Along with ZICA’s program completion certificate, the student can apply for multiple certificates from Google, Facebook, Hubspot, LinkedIn etc.</li>
                                <li>Increase technical and visualization skills.</li>
                                <li>Learn from Google certified professionals.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text2 text-left mt-5 d-lg-none d-md-block">Digital Marketing</div>
                        <div class="d-flex justify-content-center">
                        <div class="animationImg_content">
                            <img src="../../../assets/img/zica/course/digitalD1.png" class="animationImg img-fluid"
                                alt="enquiry">
                        </div>
                    </div>
                    </div>
                </div>
			</div>
		</div>
  
  
        <!-- <div id="carouselExampleControls" class="carousel slide scrollPadding" data-bs-ride="carousel">
            <div class="carousel-inner">

                <div class="carousel-item active">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-md-6">
                            <div class="text2 text-left mt-5">B.Voc. in Animation Visual Effects</div>
                            <div class="text_para mt-4">
                                <p>This B.Voc. in Animation & Visual Effects Program has been meticulously crafted to empower students with the essential skills and knowledge required by a professional in the field to tackle projects of varying complexities. The course is structured to be project-centric and hands-on, complemented by lectures, workshops, industry visits, and live presentations, all aimed at acquainting students with the dynamic realm of Animation and Visual Effects.
                                    The B.Voc. in Animation & Visual Effects program is powered by Medhavi Skills University,  the course is designed to herald a transformative learning experience, tailored to meet the demands of the future global workforce.</p>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex justify-content-center">
                            <div class="animationImg_content">
                                <img src="../../../assets/img/zica/voc_animation.png" class="animationImg img-fluid"
                                    alt="enquiry">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-md-6">
                            <div class="text2 text-left mt-5">2D & 3D Animation</div>
                            <div class="text_para mt-4">
                                <p>This is a comprehensive certificate program that deals with all the important aspects
                                    of 2D, 3D & Visual Effects. The student begins with learning all the creative
                                    development exercises with manual and digital 2D animation processes. The second
                                    module starts with all the basic elements of 3D digital art which in the later
                                    module progresses into advanced 3D Digital art and character animation. The
                                    remaining modules of the program deal with different aspects of Dynamics, tracking,
                                    and advanced Visual effects which are generally used in production studios.</p>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex justify-content-center">
                            <div class="animationImg_content">
                                <img src="../../../assets/img/zica/palette.png" class="animationImg img-fluid"
                                    alt="enquiry">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-md-6">
                            <div class="text2 text-left mt-5">Visual Design</div>
                            <div class="text_para mt-4">
                                <p>Comprehensive Animation and Visual Effects (VFX) program to cater to exceedingly
                                    growing demand in the industry
                                    Produce Short film by using Live action, 3D and Visual Effects.</p>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex justify-content-center">
                            <div class="animationImg_content">
                                <img src="../../../assets/img/zica/vs_design.png" class="animationImg img-fluid"
                                    alt="enquiry">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-md-6">
                            <div class="text2 text-left mt-5">Game Design</div>
                            <div class="text_para mt-4">
                                <p>Comprehensive Graphic design program to cater extremely growing demand in the
                                    industry.</p>
                                <p>A wide range of graphic design job opportunities.
                                    Introduction of Augmented and Virtual Reality.
                                    Create an outstanding portfolio</p>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex justify-content-center">
                            <div class="animationImg_content">
                                <img src="../../../assets/img/zica/gd.png" class="animationImg img-fluid" alt="enquiry">
                            </div>
                        </div>
                    </div>
                </div>
             
                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-md-6">
                            <div class="text2 text-left mt-5">Graphic Design</div>
                            <div class="text_para mt-4">
                                <p>Learn to create concept Game Art, Storyboard, Digital Paint, Matte paint, Crate
                                    props, environment and character.
                                    To provide students with a foundational understanding of the principles and concepts
                                    specific to the field of game art.
                                    To teach the fundamental principles of computer graphics, including pixel
                                    manipulation, rendering, and image processing.
                                    To train students in the art of creating organic and character models with a focus
                                    on topology, realism, and aesthetics.</p>
                            </div>
                        </div>
                        <div class="col-md-6 d-flex justify-content-center">
                            <div class="animationImg_content">
                                <img src="../../../assets/img/zica/gd.png" class="animationImg img-fluid" alt="enquiry">
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div> -->

    </div>

    <div class="box5" id="Placement">
        <!-- <div class="scrollPadding">
            <div class="text2 text-center">ZICA’s<span class="color_text"> Placement Cell</span>
            </div>
            <div class="text_para text-center">
                <p class="text-center">ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across
                    The Country. ZICA Training Academy Is Equipped With High-End Animation Tools, 2D Animation
                    Facilities, 3D Software, Visual FX Labs And Imaging Technologies.</p>
            </div>
        </div>
        <div class="imggallery mt-4 d-none d-xl-block">
            <div class="row">
                <div class="col-md-4 d-flex justify-content-center mt-3">
                    <div class="imgList">
                        <div class='photo'>
                            <div>
                                <img src="../../../assets/img/zica/Chahak.png" class="inner_img" alt="enquiry">
                            </div>
                            <div class='info'>
                                <div class="heading">Chahak Dalal</div>
                                <div class="sub_text">Dneg (Double Negative), Matchmover</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d-flex justify-content-center mt-3">
                    <div class="imgList">
                        <div class='photo'>
                            <div>
                                <img src="../../../assets/img/zica/Drishti.png" class="inner_img" alt="enquiry">
                            </div>
                            <div class='info'>
                                <div class="heading">Drishti Gupta</div>
                                <div class="sub_text">Red Chillies VFX Studio,Matchmover</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d-flex justify-content-center mt-3">
                    <div class="imgList">
                        <div class='photo'>
                            <div>
                                <img src="../../../assets/img/zica/Shubham.png" class="inner_img" alt="enquiry">
                            </div>
                            <div class='info'>
                                <div class="heading">Shubham Tijare</div>
                                <div class="sub_text">Final Layout Artist, Tata Elxsi</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="carouselExampleIndicators11" class="carousel slide d-xl-none d-md-block" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators11" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators11" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators11" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="imgList">
                        <div class='photo'>
                            <div>
                                <img src="../../../assets/img/zica/Chahak.png" class="inner_img" alt="enquiry">
                            </div>
                            <div class='info'>
                                <div class="heading">Chahak Dalal</div>
                                <div class="sub_text">Dneg (Double Negative), Matchmover</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="imgList">
                        <div class='photo'>
                            <div>
                                <img src="../../../assets/img/zica/Drishti.png" class="inner_img" alt="enquiry">
                            </div>
                            <div class='info'>
                                <div class="heading">Drishti Gupta</div>
                                <div class="sub_text">Red Chillies VFX Studio,Matchmover</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="imgList">
                        <div class='photo'>
                            <div>
                                <img src="../../../assets/img/zica/Shubham.png" class="inner_img" alt="enquiry">
                            </div>
                            <div class='info'>
                                <div class="heading">Shubham Tijare</div>
                                <div class="sub_text">Final Layout Artist, Tata Elxsi</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="text2 text-center">Placements
        </div>
        <div class="placement_content">
            <div class="placement_list">
            <div class="row mt-2">
                <div class="col md-3 mt-4">
                    <div class="cardList">
                        <div class="placement_title">Akshay Joshi</div>
                        <div class="mt-3">
                        <label for="">Company Joined :</label><span> MPC</span>
                    </div>
                    <div>
                        <label for="">Designation :</label><span> 3D Artist</span>
                    </div>
                    </div>
                    </div>
                  
                <div class="col-md-3 mt-4">
                    <div class="cardList">
                        <div class="placement_title">Aniket Goel </div>
                        <div class="mt-3">
                        <label for="">Company Joined :</label><span>ARES STUDIOS </span>
                    </div>
                    <div>
                        <label for="">Designation :</label><span> Lighting Artist</span>
                    </div>
                    </div>
                </div>
                <div class="col-md-3 mt-4">
                    <div class="cardList">
                        <div class="placement_title">Manish Bhatnagar</div>
                        <div class="mt-3">
                        <label for="">Company Joined :</label><span>ATMACHINES PVT LTD</span>
                    </div>
                    <div>
                        <label for="">Designation :</label><span>3D Game Artist</span>
                    </div>
                    </div>
                </div>
                <div class="col-md-3 mt-4">
                    <div class="cardList">
                        <div class="placement_title">Animika Mathur</div>
                        <div class="mt-3">
                        <label for="">Company Joined :</label><span>TECHQART PVT.LTD </span>
                    </div>
                    <div>
                        <label for="">Designation :</label><span>Motion Graphic Artist</span>
                    </div>
                    </div>
                </div>

                <div class="col-md-3 mt-4">
                    <div class="cardList">
                        <div class="placement_title">Shubham Lanjewar</div>
                        <div class="mt-3">
                        <label for="">Company Joined :</label><span>MINDYOURLOGIC PVT . LTD</span>
                    </div>
                    <div>
                        <label for="">Designation :</label><span>Video Editing & Sound Designing</span>
                    </div>
                    </div>
                </div>

                <div class="col-md-3 mt-4">
                    <div class="cardList">
                        <div class="placement_title">Albin Antony</div>
                        <div class="mt-3">
                        <label for="">Company Joined :</label><span>RAYS 3D</span>
                    </div>
                    <div>
                        <label for="">Designation :</label><span>VFX Artist</span>
                    </div>
                    </div>
                </div>

                <div class="col-md-3 mt-4">
                    <div class="cardList">
                        <div class="placement_title">Ashique Manuel</div>
                        <div class="mt-3">
                        <label for="">Company Joined :</label><span>WE BROS MEDIA PVT.LTD.</span>
                    </div>
                    <div>
                        <label for="">Designation :</label><span>Game Designer</span>
                    </div>
                    </div>
                </div>

                <div class="col-md-3 mt-4">
                    <div class="cardList">
                        <div class="placement_title">Prakhar Agrawal</div>
                        <div class="mt-3">
                        <label for="">Company Joined :</label><span>REDCHILLIES VFX</span>
                    </div>
                    <div>
                        <label for="">Designation :</label><span>Composite Artist</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
<div class="placement_slider">
            <div id="carouselExampleControls" class="carousel slide scrollPadding" data-bs-ride="carousel">
            <div class="carousel-inner">

                <div class="carousel-item active">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-12 md-3 mt-4">
                            <div class="cardList">
                                <div class="placement_title">Akshay Joshi</div>
                                <div class="mt-3">
                                <label for="">Company Joined :</label><span> MPC</span>
                            </div>
                            <div>
                                <label for="">Designation :</label><span> 3D Artist</span>
                            </div>
                            </div>
                            </div>
                    </div>
                </div>

                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-12 mt-4">
                            <div class="cardList">
                                <div class="placement_title">Aniket Goel </div>
                                <div class="mt-3">
                                <label for="">Company Joined :</label><span>ARES STUDIOS </span>
                            </div>
                            <div>
                                <label for="">Designation :</label><span> Lighting Artist</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-12 mt-4">
                            <div class="cardList">
                                <div class="placement_title">Manish Bhatnagar</div>
                                <div class="mt-3">
                                <label for="">Company Joined :</label><span>ATMACHINES PVT LTD</span>
                            </div>
                            <div>
                                <label for="">Designation :</label><span>3D Game Artist</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-12 mt-4">
                            <div class="cardList">
                                <div class="placement_title">Animika Mathur</div>
                                <div class="mt-3">
                                <label for="">Company Joined :</label><span>TECHQART PVT.LTD </span>
                            </div>
                            <div>
                                <label for="">Designation :</label><span>Motion Graphic Artist</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
               

                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-12 mt-4">
                            <div class="cardList">
                                <div class="placement_title">Shubham Lanjewar</div>
                                <div class="mt-3">
                                <label for="">Company Joined :</label><span>MINDYOURLOGIC PVT . LTD</span>
                            </div>
                            <div>
                                <label for="">Designation :</label><span>Video Editing & Sound Designing</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
              

                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-12 mt-4">
                            <div class="cardList">
                                <div class="placement_title">Albin Antony</div>
                                <div class="mt-3">
                                <label for="">Company Joined :</label><span>RAYS 3D</span>
                            </div>
                            <div>
                                <label for="">Designation :</label><span>VFX Artist</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-12 mt-4">
                            <div class="cardList">
                                <div class="placement_title">Ashique Manuel</div>
                                <div class="mt-3">
                                <label for="">Company Joined :</label><span>WE BROS MEDIA PVT.LTD.</span>
                            </div>
                            <div>
                                <label for="">Designation :</label><span>Game Designer</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carousel-item">
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-12 mt-4">
                            <div class="cardList">
                                <div class="placement_title">Prakhar Agrawal</div>
                                <div class="mt-3">
                                <label for="">Company Joined :</label><span>REDCHILLIES VFX</span>
                            </div>
                            <div>
                                <label for="">Designation :</label><span>Composite Artist</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
        </div>
       
    
    </div>

    <div class="box6 mt-5" id="Testimonial">
        <div class="logo_list">
            <div class="text_para text-center">
                <p class="text-center">Few companies where ZICA students have been hired:</p>
            </div>
            <div class="logo_content">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-6">
                        <div>
                            <div class="logo">
                                <img src="../../../assets/img/zica/placement_logo/placement1.png" alt="placement logo"
                                    width="100%">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div>
                            <div class="logo">
                                <img src="../../../assets/img/zica/placement_logo/placement2.png" alt="placement logo"
                                    width="100%">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div>
                            <div class="logo">
                                <img src="../../../assets/img/zica/placement_logo/placement3.png" alt="placement logo"
                                    width="100%">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div>
                            <div class="logo">
                                <img src="../../../assets/img/zica/placement_logo/placement4.png" alt="placement logo"
                                    width="100%">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div>
                            <div class="logo">
                                <img src="../../../assets/img/zica/placement_logo/placement5.png" alt="placement logo"
                                    width="100%">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div>
                            <div class="logo">
                                <img src="../../../assets/img/zica/placement_logo/placement6.png" alt="placement logo"
                                    width="100%">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="box7 mt-5">
        <div class="row">
            <div class="col-md-7">
                <div class="text2">Let’s Hear It From<br><span class="color_text"> Our Students</span>
                </div>
            </div>
            <div class="col-md-5">
                <div class="right_goalsImg_box d-flex justify-content-center">
                    <img src="../../../assets/img/zica/d_comma.png" width="280px" height="280px" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="box8">
        <div class="multi_box_content mt-3">
            <div class="row">
                <div class="col-md-4 mt-3">
                    <div class="sub_box profile_box">
                        <div class="left_icon">
                            <img src="../../../assets/img/zica/placement_logo/Aishwarya.png" width="75px" height="75px"
                                alt="enquiry">
                        </div>
                        <div class="profile_name">Aishwarya Pillay</div>
                        <div class="details">NY VFXWAALA as 3D Artist</div>

                        <div class="para">
                            <p>ZICA has been a great place to learn; the faculty, as well as the staff has been very
                                helpful since the beginning. Everyone at ZICA has been very supportive to me. Throughout
                                the years I spent at ZICA, I've have grown abundantly in knowledge and learnt various
                                techniques used in the field.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="sub_box profile_box">
                        <div class="left_icon">
                            <img src="../../../assets/img/zica/placement_logo/Bhargava A J.png" width="75px"
                                height="75px" alt="enquiry">
                        </div>
                        <div class="profile_name">Bhargava A J</div>
                        <div class="details">MPC as Modeling & Texturing Artist</div>

                        <div class="para">
                            <p>I had a very good experience at ZICA where I got to learn about the industry which has
                                helped me reaching my goals.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="sub_box profile_box">
                        <div class="left_icon">
                            <img src="../../../assets/img/zica/placement_logo/Chinmaya.png" width="75px" height="75px"
                                alt="enquiry">
                        </div>
                        <div class="profile_name">Chinmaya Pradhan</div>
                        <div class="details">Pixalot Labs as Modeling & Texturing Artist</div>
                        <div class="para">
                            <p>I would like to thank ZICA BBSR and all the faculties for their hard work to us. Thank
                                you.</p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mt-3">
                    <div>
                        <div class="sub_box profile_box">
                            <div class="left_icon">
                                <img src="../../../assets/img/zica/placement_logo/Dhruvil.png" width="75px"
                                    height="75px" alt="enquiry">
                            </div>
                            <div class="profile_name">Dhruvil kantharia</div>
                            <div class="details">DAP Advertising as Sketching Artist</div>

                            <div class="para">
                                <p>It was a great experience studying in ZICA. The whole course was indeed a wonderful
                                    learning for me. The faculties are very helpful, providing with a bucket full of
                                    creativity and knowledge.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div>
                        <div class="sub_box profile_box">
                            <div class="left_icon">
                                <img src="../../../assets/img/zica/placement_logo/Shanel.png" width="75px" height="75px"
                                    alt="enquiry">
                            </div>
                            <div class="profile_name">Shanel Moraes</div>
                            <div class="details">YFX Studio as Animator</div>

                            <div class="para">
                                <p>ZICA has been an amazing place to be at; the teachers, as well as the staff have been
                                    very helpful since the beginning. Throughout the years I spent here, I have grown
                                    abundantly in knowledge and learnt various techniques used in the actual Animation
                                    field.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div>
                        <div class="sub_box profile_box">
                            <div class="left_icon">
                                <img src="../../../assets/img/zica/placement_logo/Sumit.png" width="75px" height="75px"
                                    alt="enquiry">
                            </div>
                            <div class="profile_name">Sumit Badoniya</div>
                            <div class="details">Drishyam VFX as Matchmove artist</div>
                            <div class="para">
                                <p>I would like to thank ZICA for catering quality education through specialized
                                    faculties to each student to nourish and expand in their respective chosen fields.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box9 mt-5" id="FAQs">
        <div class="row scrollPadding">
            <div class="col-md-6">
                <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                </div>
                <div class="text_para">
                    <!-- <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Home"> -->
                </div>
            </div>
            <div class="col-md-6">
                <div class="accordion_box">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <div>
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button1" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Are drawing skills compulsory for doing the Animation program?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>For 2D animation drawing skills is a must. If you are more interested in the
                                            3D animation, then you will suffice without drawing.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div>
                                <h2 class="accordion-header" id="headingOne1">
                                    <button class="accordion-button1" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne1" aria-expanded="true"
                                        aria-controls="collapseOne1">
                                        Which course I should prefer after Grade 12th?
                                    </button>
                                </h2>
                                <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>After 12th you should opt for a career-oriented program. The duration of the
                                            program should be 2 years or more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div>
                                <h2 class="accordion-header" id="headingOne2">
                                    <button class="accordion-button1" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne2" aria-expanded="true"
                                        aria-controls="collapseOne2">
                                        Are any internship opportunities available? Paid or unpaid?
                                    </button>
                                </h2>
                                <div id="collapseOne2" class="accordion-collapse collapse" aria-labelledby="headingOne2"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>The internship opportunity is optional and provided from time to time.
                                            Internships are with a basic stipend. Most of the students do not opt for an
                                            internship as production studios hire them on a full-time basis after the
                                            course is completed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div>
                                <h2 class="accordion-header" id="headingOne3">
                                    <button class="accordion-button1" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne3" aria-expanded="true"
                                        aria-controls="collapseOne3">
                                        Any hidden charges or extra charges apart from fees?
                                    </button>
                                </h2>
                                <div id="collapseOne3" class="accordion-collapse collapse" aria-labelledby="headingOne3"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>There are no hidden charges from the institute apart from the fees. You might
                                            have to purchase basic stationaries (Pencil, drawing notebook etc) for your
                                            personal practice purpose. </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <div>
                                <h2 class="accordion-header" id="headingOne4">
                                    <button class="accordion-button1" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne4" aria-expanded="true"
                                        aria-controls="collapseOne4">
                                        Can we get the Demo class session?
                                    </button>
                                </h2>
                                <div id="collapseOne4" class="accordion-collapse collapse" aria-labelledby="headingOne4"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Yes. ZICA center arranges Demo classes for minimum 5 enquiries at a time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<footer class="desktopResponsive">
    <div class="top_logo_list">
        <img src="../../../../assets/img/zica/course/mesc.png" width="90px" height="90px" alt="">
        <img src="../../../../assets/img/zica/course/skill.png" class="mx-3" width="90px" height="90px" alt="">
        <div class="para mt-2 py-3">
            <h6 class="text-white">ZICA Training Centre Affiliated with MESC.</h6>
            <p class="text-white">Welcome to the Enhanced Experience of Certification from The Government of India.</p>
        </div>
    </div>
   
    <div class="footerContent">
        <div class="row">
            <div class="footerTextContnetn">
                <div class="subListContent">
                   
                    <div class="row">
                        <div class="col-5">
                            <div class="zeeLogo">
                                <img src="../../../../assets/img/zica/ZLLLogo.png" alt="">
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="logoList">
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/ZICA_Logo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/KidzeeFooterLogo.png" alt="">
                                </div>
                                <!-- <div class="logo">
                                <img src="../../../../assets/img/zica/mlwp.png" alt="">
                            </div> -->
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlzs.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mls.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/zima.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-4">
                <div class="iconList">
                    <a href="https://www.facebook.com/zicaworld" target="_blank">
                        <img src="../../../../assets/img/zica/icon/icon1.png" alt="">
                    </a>
                    <a href="https://www.instagram.com/zeeinstituteofcreativeart/" target="_blank">
                        <img src="../../../../assets/img/zica/icon/icon2.png" alt="">
                    </a>
                    <a href="https://www.linkedin.com/school/zicaworld/" target="_blank">
                        <img src="../../../../assets/img/zica/icon/icon4.png" alt="">
                    </a>
                    <a href="https://www.youtube.com/user/ZICAWorld" target="_blank">
                        <img src="../../../../assets/img/zica/icon/icon5.png" alt="">
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId">
                    copyright ® 2023 Zee Learn Ltd. All rights reserved.
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId">
                    <span class="footerRout" routerLink="./PrivacyPolicy">Privacy & Policy</span> <span
                        class="footerRout" routerLink="./termsOfUse">Terms of Use</span>
                </div>
            </div>
        </div>
    </div>

</footer>

<footer class="mobileResponsive">
    <div class="footerContent">
        <div class="top_logo_list">
            <img src="../../../../assets/img/zica/course/mesc.png" width="90px" height="90px" alt="">
            <img src="../../../../assets/img/zica/course/skill.png" class="mx-3" width="90px" height="90px" alt="">
        </div>
        <div class="para mt-2">
            <h6 class="text-white">ZICA Training Centre Affiliated with MESC.</h6>
            <p class="text-white">Welcome to the Enhanced Experience of Certification from The Government of India.</p>
        </div>
        <div class="row">
            <div class="footerTextContnetn">
                <div class="">
                    <!-- <div class="row">
                        <div class="col-md-5">
                            <div class="zeeLogo">
                                <img src="../../../../assets/img/zica/ZLLLogo.png" alt="">
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="logoList">
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/ZICA_Logo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/KidzeeFooterLogo.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlwp.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mlzs.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/mls.png" alt="">
                                </div>
                                <div class="logo">
                                    <img src="../../../../assets/img/zica/zima.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div> -->


                    <div class="row">
                        <div class="footerTextContnetn">
                            <div class="">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="zeeLogo">
                                            <img src="../../../../assets/img/zica/ZLLLogo.png" alt="">
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="logoList">
                                            <div class="logo">
                                                <img src="../../../../assets/img/zica/ZICA_Logo.png" alt="">
                                            </div>
                                            <div class="logo">
                                                <img src="../../../../assets/img/zica/zima.png" alt="">
                                            </div>
                                            <div class="logo">
                                                <img src="../../../../assets/img/zica/KidzeeFooterLogo.png" alt="">
                                            </div>
                                            <div class="logo">
                                                <img src="../../../../assets/img/zica/mlwp.png" alt="">
                                            </div>
                                            <div class="logo">
                                                <img src="../../../../assets/img/zica/mlzs.png" alt="">
                                            </div>
                                            <div class="logo">
                                                <img src="../../../../assets/img/zica/mls.png" alt="">
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12">
                <div class="text-white text-center footerTextEmailId">
                    <span class="footerRout" routerLink="./PrivacyPolicy">Privacy & Policy</span><br><br> <span
                        class="footerRout" routerLink="./termsOfUse">Terms of Use</span>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
                <div class="text-white text-center footerTextEmailId bottom_text">
                    copyright ® 2023 Zee Learn Ltd.<br> All rights reserved.
                </div>
            </div>

        </div>
        <div class="btn_content">
         
            <a href="#admissionform"><button class="btn admission_enq_btn">Admissions Enquiry</button></a>
            <a href="#admissionform"><button class="btn admission_enq_btn">Download Browser</button></a>
        </div>
    </div>

</footer>