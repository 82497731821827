import { Component } from '@angular/core';

@Component({
  selector: 'app-thankyou-enquiry',
  templateUrl: './thankyou-enquiry.component.html',
  styleUrls: ['./thankyou-enquiry.component.css']
})
export class ThankyouEnquiryComponent {

}
