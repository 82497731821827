<section>
    <div class="box1">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
         </div>
        <div class="row">
            <div class="col-md-6">
                <div class="text1">Course Detail</div>
                <div class="text2">Interior Design
                </div>
                <div class="text_para">
                    <ul>
                       <li>To introduce students to the fundamental principles, concepts, and theories of interior design as a discipline.</li>
                     <li>To specialize in designing residential interiors, considering the unique requirements and preferences of homeowners.</li>
                     <li>To learn advanced computer rendering techniques for creating realistic 3D walkthroughs of interior spaces.</li>
                     <li>To gain expertise in designing restaurant and hospitality spaces, considering customer experience and functional requirements.</li>
                     <li>To develop research and communication skills essential for academic and professional advancement.</li>
                     <li>To expand and update the professional portfolio with advanced and specialized projects.</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img_box">
                    <div class="imgSubBox d-flex">
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/Interior1.png" class="aniImg1" alt="Interior Design">
                        </div>
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/Interior2.png" class="aniImg2" alt="Interior Design">
                        </div>
                    </div>
                    <div class="imgContent2">
                        <img src="../../../../assets/img/zica/course/Interior3.png" class="aniImg3" alt="Interior Design">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box2">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-12">
                <div class="left_box">
                    <div class="text2">PDP In Interior Design <span class="color_text"> (24 Months)</span>
                    </div>
                    <div class="sub_text mt-4 text-white">Learn from<span
                            class="sub_text_color">The Professionals</span></div>
                    <div class="text_para">
                        <p>
                          Interior design program deals with the art and science of enhancing the interior of a building inner space to achieve a healthier, more aesthetically pleasing and functional environment for the people using the space.
                        </p>
                        <div class="sub_text mt-3 text-white">Become a Professional Artist</div>
                        <div class="text_para">
                            <p>In this program we develop design, art, fundamentals of drawing, furniture design and design source and materials, interior graphics, Introduction to CAD, construction, services, case study, building material etc. In order to became a Professional Interior Designer. This program offers exposure to new technologies like AR/ VR to help the students be future ready
                            </p>
                        </div>
                        <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                        <div class="text_para">
                            <p>Students walk away with a rich portfolio comprising various interior design 3D layouts such as a living room, bedroom, office, restaurant, commercial space etc. This portfolio will be a gateway to join the interior design firm.</p>
                        </div>
                        <div class="sub_text mt-4 text-white">Program Highlights</div>
                            <div class="text_para">
                             <ul>
                              <li>Increase design and visualization skills.</li>
                              <li>Learn complete interior design production pipeline.</li>
                              <li>Continuous evaluation through examination, competitions and activities.</li>
                              <li>Regular onsite visit for site and meeting with professionals on site.</li>
                             </ul>
                            </div>
                            <div class="accordion mt-2" id="accordionExample">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Module 01
                                    </button>
                                  </h2>
                                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <ul>
                                        <li>History of Interior furniture Design (Indian & Western)</li>
                                        <li>Concept of Interior Design</li>
                                        <li>Fundamental of Art</li> 
                                      </ul>
                                    </div>
                                    <div class="col-md-6">
                                      <li>Free-Hand Drawing</li>
                                        <li>Design development</li>
                                        <li>Space planning</li> 
                                        <li>Services, Techniques, Theory & Practical</li> 
                                    </div>
                                  </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Module 02
                                    </button>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <ul>
                                          <li>Architectural Drafting</li>
                                          <li>Interior Design – Residential</li>
                                          <li>2D, 3D Computer Aided Drawing</li> 
                                          <li>Computer Graphic Skills</li> 
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <ul>
                                          <li>Creativity and Concept Development</li>
                                          <li>BOQ, Budget and Costing</li> 
                                          <li>Drawing Presentation</li> 
                                          <li>Application and Execution</li>
                                          <li>Portfolio</li> 
                                        </ul>
                                      </div>
                                    </div> 
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Module 03
                                    </button>
                                  </h2>
                                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <li>Furniture History and Application</li>
                                        <li>Design Studio workshop 2 - Retail</li>
                                      </div>
                                      <div class="col-md-6">
                                        <li>Construction Studies</li> 
                                        <li>Services- Plumbing, Lighting, AC etc.</li>
                                        <li>Computer Rendering Walkthrough</li> 
                                      </div>
                                    </div> 
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                          Module 04
                                      </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                      <div class="accordion-body">
                                      <div class="row">
                                        <div class="col-md-6">
                                          <ul>
                                            <li>Design Studio workshop 3 - Office</li>
                                            <li>Design Studio workshop 4 - Restaurant & Hospitality</li>
                                            <li>Academic Research and Communication Skills</li>
                                          </ul>
                                        </div>
                                        <div class="col-md-6">
                                          <li>Industrial experience, Internship</li>
                                          <li>Application and Execution</li>
                                          <li>Portfolio Development</li>
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>

                              <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                              <div class="row mt-3">
                                <div class="col-6">
                                    <ul>
                                      <li>Interior Designer</li>
                                      <li>Furniture Designer</li>
                                      <li>Colour Consultant</li>
                                    <li>Residential, Hospitality, Commercial Space Designer</li>
                                     
                                      </ul>
                                </div>
                                <div class="col-6">
                                    <ul>
                                      <li>Visualizer</li>
                                      <li>Merchandising Designer</li>
                                      <li>Art Director</li>
                                      </ul>
                                </div>
                              </div>
                            
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="list_box">
                    <div class="list">
                    <div class="letf_text sub_text_color">Level</div>
                    <div class="right_text">Intermediate</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Duration</div>
                    <div class="right_text">24 Months</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Eligibility</div>
                    <div class="right_text">10th Pass</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Avg. Package</div>
                    <div class="right_text">12LPA</div>
                </div>
                </div>
            </div> -->
        </div>


        <div class="row flex-column-reverse flex-lg-row mt-5">
          <div class="col-md-12">
              <div class="left_box">
                  <div class="text2">Professional Program In Interior Design <span class="color_text"> (12 Months)</span>
                  </div>
                  <div class="sub_text mt-4 text-white">Learn from<span
                          class="sub_text_color">The Professionals</span></div>
                  <div class="text_para">
                      <p>
                        Interior design is all about how we experience spaces. It’s a powerful, essential part of our daily lives and affects how we live, work, play, and even heal. Comfortable homes, functional workplaces, beautiful public spaces—that’s interior design at work. 
                      </p>
                      <div class="sub_text mt-3 text-white">Become a professional artist</div>
                      <div class="text_para">
                          <p>This program deals with design source and materials, fundamentals of drawing, furniture design and design source and materials, interior graphics, Introduction to CAD, construction, services, case-study, building material etc.
                          </p>
                      </div>
                      <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                      <div class="text_para">
                          <p>Students walk away with a rich portfolio comprising various interior design 3D layouts such as a living room, bedroom, kitchen etc. This portfolio will be a gateway to join the interior design firm.</p>
                      </div>
                      <div class="sub_text mt-4 text-white">Program Highlights</div>
                          <div class="text_para">
                           <ul>
                            <li>Increase design and visualization skills.</li>
                            <li>Learn complete interior design production pipeline.</li>
                            <li>Continuous evaluation through examination, competitions and activities.</li>
                            <li>Regular onsite visit for site and meeting with professionals on site.</li>
                           </ul>
                          </div>
                          <div class="accordion mt-2" id="accordionExample1">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne1">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                      Module 01
                                  </button>
                                </h2>
                                <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                                  <div class="accordion-body">
                                <div class="row">
                                  <div class="col-md-6">
                                    <ul>
                                      <li>History of Interior furniture Design (Indian & Western)</li>
                                      <li>Concept of Interior Design</li>
                                      <li>Fundamental of Art</li> 
                                       
                                    </ul>
                                  </div>
                                  <div class="col-md-6">
                                    <li>Free-Hand Drawing</li>
                                    <li>Design development</li>
                                    <li>Space planning</li> 
                                    <li>Services, Techniques, Theory & Practical</li>
                                  </div>
                                </div>
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo1">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1">
                                      Module 02
                                  </button>
                                </h2>
                                <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample1">
                                  <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <ul>
                                        <li>Architectural Drafting</li>
                                        <li>Interior Design – Residential</li>
                                        <li>2D, 3D Computer Aided Drawing</li> 
                                        <li>Computer Graphic Skills</li>
                                       
                                      </ul>
                                    </div>
                                    <div class="col-md-6">
                                      <ul>
                                        <li>Creativity and Concept Development</li>                                       
                                         <li>	BOQ, Budget and Costing</li> 
                                        <li>Drawing Presentation</li> 
                                        <li>Application and Execution</li>
                                        <li>Portfolio</li>
                                      </ul>
                                    </div>
                                  </div> 
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                            <div class="row mt-3">
                              <div class="col-6">
                                  <ul>
                                    <li>Interior Designer</li>
                                    <li>Furniture Designer</li>
                                    <li>Colour Consultant</li>
                                    <li>Residential, Hospitality, Commercial Space Designer</li>
                                    
                                    </ul>
                              </div>
                              <div class="col-6">
                                  <ul>
                                    <li>Visualizer</li>
                                    <li>Merchandising Designer</li>
                                    <li>Art Director</li>
                                    </ul>
                              </div>
                            </div>
                          
                  </div>
              </div>
          </div>
          <!-- <div class="col-md-4">
              <div class="list_box">
                  <div class="list">
                  <div class="letf_text sub_text_color">Level</div>
                  <div class="right_text">Intermediate</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Duration</div>
                  <div class="right_text">12 Months</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Eligibility</div>
                  <div class="right_text">10th Pass</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Avg. Package</div>
                  <div class="right_text">12LPA</div>
              </div>
              </div>
          </div> -->
      </div>
    </div>

    <div class="box3">
        <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
        <div class="text_para text-center">
            <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                Visual FX Labs And Imaging Technologies.</p>
        </div>
        <app-blogs></app-blogs>
        </div>
        <div class="box4 mt-5">
            <div class="row">
                <div class="col-md-6">
                  <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                  </div>
                  <div class="text_para">
                   <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Interior Design">
                  </div>
                </div>
                <div class="col-md-6">
                    <app-faqs></app-faqs>
                </div>
            </div>
        </div>
        
        <div class="box5">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                   
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <div class="text_para">
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p> -->
                  </div>
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
               
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/course/Enquiry_pic.png" width="100%" alt="Interior Design">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p>
                    </div>
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Interior Design">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <!-- <app-franchise-form></app-franchise-form> -->
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
</section>