
<section>
    <div class="main_body">
      
        <div class="box1">
            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                        class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="right_box">
                                    <img src="../../../assets/img/zica/banner/banner3.png" width="100%" class="Responsive image" alt="Home">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="right_box">
                                    <img src="../../../assets/img/zica/banner/banner1.png" width="100%" class="Responsive image" alt="Home">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="down_arrow">
            <div class="down_icon"></div>
        </div> -->
        <div class="box2 mt-3">
            <div class="text2 text-center">Discover<span class="color_text"> Our Programs</span></div>
            <div class="text_para">
                <p>ZICA Unveils the Palette of Possibilities!-Discover Our Programs – Animation, VFX, Gaming, Interior
                    Design, Fashion Design, Digital Marketing and Photography. Your Canvas Awaits, Your Masterpiece
                    Begins Now!</p>
            </div>
            <div class="swiper-container two">
                <div class="swiper-wrapper" data-swiper-autoplay="1000">
                    <div class="swiper-slide">
                        <div class="slider-image" routerLink="/animation">
                            <img src="../../../assets/img/zica/slider/5.png" width="270px" height="480px" alt="slide 5">
                            <div class="bottom_label">Animation</div>
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image" routerLink="/visualEffect">
                            <img src="../../../assets/img/zica/slider/1.png" width="270px" height="480px" alt="slide 1">
                            <div class="bottom_label">Visual Effects</div>
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image" routerLink="/game">
                            <img src="../../../assets/img/zica/slider/2.png" width="270px" height="480px" alt="slide 2">
                            <div class="bottom_label">Game Arts And Design</div>
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image" routerLink="/digitalPhotography">
                            <img src="../../../assets/img/zica/slider/3.png" width="270px" height="480px" alt="slide 3">
                            <div class="bottom_label">Digital Photography</div>
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image" routerLink="/digitalMarketing">
                            <img src="../../../assets/img/zica/slider/7.png" width="270px" height="480px" alt="slide 4">
                            <div class="bottom_label" style="color: #000;">Digital Marketing</div>
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image" routerLink="/webDesign">
                            <img src="../../../assets/img/zica/slider/6.png" width="270px" height="480px" alt="slide 5">
                            <div class="bottom_label">Web Design</div>
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image" routerLink="/fashionDesign">
                            <img src="../../../assets/img/zica/slider/8.png" width="270px" height="480px" alt="slide 6">
                            <div class="bottom_label">Fashion Design</div>
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image" routerLink="/interiorDesign">
                            <img src="../../../assets/img/zica/slider/9.png" width="270px" height="480px" alt="slide 7">
                            <div class="bottom_label">Interior Design</div>
                        </div>
                    </div>
                    <!-- Add Pagination -->
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
        <div class="box4">
            <div class="text2 text-center"><span class="color_text"> Student’s Work</span></div>
            <div class="text_para">
                <p class="text-center">CHECK OUT OUTSTANDING WORK OF OUR ZICA STUDENTS</p>
            </div>

            <div class="swiper-container two">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image">
                            <!-- <img src="https://theme-land.com/sapp/demo/assets/img/screenshots/3.jpg" alt="slide 1"> -->
                            <img src="../../../assets/img/zica/slider/studentWork/Img1.jpg" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image" >
                            <img src="../../../assets/img/zica/slider/studentWork/Img2.jpg" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image">
                            <!-- <img src="https://theme-land.com/sapp/demo/assets/img/screenshots/5.jpg" alt="slide 3"> -->
                            <img src="../../../assets/img/zica/slider/studentWork/Img3.jpg" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image">
                            <!-- <img src="https://theme-land.com/sapp/demo/assets/img/screenshots/4.jpg" alt="slide 4"> -->
                            <img src="../../../assets/img/zica/slider/studentWork/Img4.jpg" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image">
                            <!-- <img src="https://theme-land.com/sapp/demo/assets/img/screenshots/2.jpg" alt="slide 5"> -->
                            <img src="../../../assets/img/zica/slider/studentWork/Img5.jpg" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image">
                            <!-- <img src="https://theme-land.com/sapp/demo/assets/img/screenshots/2.jpg" alt="slide 5"> -->
                            <img src="../../../assets/img/zica/slider/studentWork/Img6.jpg" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image">
                            <!-- <img src="https://theme-land.com/sapp/demo/assets/img/screenshots/2.jpg" alt="slide 5"> -->
                            <img src="../../../assets/img/zica/slider/studentWork/Img7.jpg" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image">
                            <!-- <img src="https://theme-land.com/sapp/demo/assets/img/screenshots/2.jpg" alt="slide 5"> -->
                            <img src="../../../assets/img/zica/slider/studentWork/Img8.jpg" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image">
                            <!-- <img src="https://theme-land.com/sapp/demo/assets/img/screenshots/2.jpg" alt="slide 5"> -->
                            <img src="../../../assets/img/zica/slider/studentWork/Img9.jpg" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide" data-swiper-autoplay="1000">
                        <div class="slider-image">
                            <!-- <img src="https://theme-land.com/sapp/demo/assets/img/screenshots/2.jpg" alt="slide 5"> -->
                            <img src="../../../assets/img/zica/slider/studentWork/Img10.jpg" alt="slide 2">
                        </div>
                    </div>

                    <!-- Add Pagination -->
                </div>
                <div class="swiper-pagination"></div>
                <div class="next_btn">
                    <button class="btn nextbtn" routerLink="/studentsZone">View all</button>
                </div>
            </div>

        </div>

        <div class="box5">
            <div class="text2 text-center">ZICA’s <span class="color_text">Placement Cell</span></div>
            <div class="text_para text-center">
                <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                    Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                    Visual FX Labs And Imaging Technologies.</p>
            </div>
            <div class="cascade-slider_container" id="cascade-slider">
                <div class="cascade-slider_slides" >
                    <div class="cascade-slider_item" *ngFor="let data of careersPlacementList">
                        <div class='card align'>
                            <div class='photo' >
                                <div *ngFor="let dt of data.logofiles">
                                    <img src="{{dt.value}}" width="300px" class="inner_img"
                                    height="275px">
                                    
                                </div>
                                <div *ngIf="!data.logofiles">
                                    <img src="../../../assets/img/zica/slider/man.png" width="300px" class="inner_img"
                                    height="275px">
                                </div>                                
                                <div class='info'>
                                    <div class="heading">{{data.Title}}</div>
                                    <div class="sub_text">{{data.short}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span class="cascade-slider_arrow cascade-slider_arrow-left" data-action="prev"><img
                        src="../../../assets/img/zica/icon/prev.png" type="image/svg+xml"></span>
                <span class="cascade-slider_arrow cascade-slider_arrow-right" data-action="next"><img
                        src="../../../assets/img/zica/icon/next.png" type="image/svg+xml"></span>
            </div>
            <div class="logo_list">
                <div class="text_para text-center">
                    <p class="text-center">Few companies where ZICA students have been hired:</p>
                </div>
                <div class="logo_content">
                    <div class="row">
                        <div class="col-2" *ngFor="let a of placementLogoList; let i = index">
                            <div *ngIf="i <= 6">
                            <div class="logo" *ngFor="let b of a.logofiles">
                                <img src="{{b.value}}" alt="{{b.key}}" width="100%">
                            </div>
                        </div>
                        <div *ngIf="showAllLogo">
                        <div *ngIf="i >= 7">
                            <div class="logo" *ngFor="let b of a.logofiles">
                                <img src="{{b.value}}" alt="{{b.key}}" width="100%">
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
                <div class="next_btn">
                    <button class="btn nextbtn" (click)="showAllLosgoList();">View all</button>
                </div>
            </div>
        </div>


    
        <div class="box6">
           <app-testimonial></app-testimonial>
        </div>

        <div class="box7">
            <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
            <div class="text_para text-center">
                <!-- <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                    Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                    Visual FX Labs And Imaging Technologies.</p> -->
            </div>

            <!-- <div class="blogs_content">
                <div class="row">
                    <div class="col-md-4">
                        <div class="blog_img_content">
                            <div class="book">
                                <img src="../../../assets/img/zica/book.png" height="230px" class="inner-img" alt="">
                            </div>
                            <div class="text_info_content">
                                <button class="three_d_btn">3D Animation</button>
                            <div class="profile_name">The power of VR technology</div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore...</p>
                       <div class="bottom_text">by Akshit Daksh • Mar 14, 2022</div>
                    </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="right_content_list">
                            <div class="teq_content mt">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="sub_img_content">
                                            <img src="../../../assets/img/zica/imgList2.png" class="inner-img" alt="">
                                        </div>
                                       
                                    </div>
                                    <div class="col-9">
                                        <div class="right_info_content">
                                            <button class="three_d_btn mt-3">3D Animation</button>
                                        <div class="profile_name">The power of VR technology</div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore...</p>
                                   <div class="bottom_text">by Akshit Daksh • Mar 14, 2022</div>
                                </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="teq_content mt-4">
                                
                                <div class="row">
                                    <div class="col-3">
                                        <div class="sub_img_content">
                                            <img src="../../../assets/img/zica/imgList1.png" class="inner-img" alt="">
                                        </div>
                                       
                                    </div>
                                    <div class="col-9">
                                        <div class="right_info_content">
                                            <button class="three_d_btn mt-3">3D Animation</button>
                                        <div class="profile_name">The power of VR technology</div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore...</p>
                                   <div class="bottom_text">by Akshit Daksh • Mar 14, 2022</div>
                                </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <app-blogs></app-blogs>

        </div>
        
        <div class="box3">
            <div class="row">
                <div class="col-md-7">
                    <div class="">
                        <div class="text2 text-center">The Right Place to<br> Achieve<span class="color_text"> Your
                                Goals</span></div>
                        <div class="text_para">
                            <p>Zee Institute Of Creative Art (ZICA) Is India's First Full-Fledged Classical And Digital
                                Animation Training Academy Established In 1995, That Trains Youth In 2D Animation, 3D
                                Animation, Visual Effects (VFX), Gaming, Graphic Design, Web Design, Digital Marketing
                                And Digital Photography.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="right_goalsImg_box d-flex justify-content-center">
                        <img src="../../../assets/img/zica/Goals_img.png" class="goals_img img-fluid" alt="">
                    </div>
                </div>
            </div>
            <div class="multi_box_content1 mt-3">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>India’s premiere traditional and digital animation institute</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons2.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>Emphasize on pre-production which is very important</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons3.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>Current industry standard and career focused course curriculum</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                    <div class="sub_box">
                        <div class="left_icon">
                            <img src="../../../assets/img/zica/icon/USPIcons15.png" width="45px" height="45px" alt="Home">
                        </div>
                        <p>Student loan facility</p>
                    </div>
                    </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons5.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>Collaboration with well-known university to provide animation VFX degree</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons6.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>Dedicated online and in-class training platform for Students</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons7.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>Access to world-class infrastructure & latest software</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons8.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>Monthly & yearly activities to enhance competitive spirit among students</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons9.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>E-Studio an exclusive online app for you to access e-books, on the got</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons10.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>Fun-filled studio study environment</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons11.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>Guidance to develop your own portfolio & show reel</p>
                </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 mt-3">
                <div class="sub_box">
                    <div class="left_icon">
                        <img src="../../../assets/img/zica/icon/USPIcons14.png" width="45px" height="45px" alt="Home">
                    </div>
                    <p>Opportunities for placements with top animation & VFX studios</p>
                </div>
                </div>
             
            </div>
            </div>
        </div>
        <div class="box8 mt-5">
            <div class="row">
                <div class="col-md-6">
                    <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                    </div>
                    <div class="text_para">
                     <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Home">
                    </div>
                </div>
                <div class="col-md-6">
                    <app-faqs></app-faqs>
                </div>
            </div>
        </div>

        <div class="box9">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                   
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <div class="text_para">
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p> -->
                  </div>
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
               
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Home">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <div class="text_para">
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p> -->
                    </div>
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Home">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <app-footer></app-footer> -->