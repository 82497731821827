import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseUrl = environment.api_url
  cmsBaseURL = environment.cmsapi_url
  course = [{course:"3D Animation",id:80},
  {course:"Visual Effects",id:81},
  {course:"Graphic Design",id:87},
  {course:"Web Design",id:88},
  {course:"Interior Design",id:97},
  {course:"Digital Marketing",id:98},
  {course:"Digital Photography",id:99},
  {course:"Multimedia",id:86},
  {course:"2D Animation",id:100},
  {course:"Gaming",id:101},
  {course:"B.Voc in Animation & Visual Effect",id:138}]

  private centerdt = new Subject<any>();

  constructor(private http: HttpClient) { }

  setcenterdt(data:any){
    this.centerdt.next(data);
  }

  getcenterdt(){
    return this.centerdt.asObservable();
  }

  saveData(obj:any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'V1/ZeeparentEnquiry',obj);
  }

  saveDataPartner(obj:any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'V1/ZeeEnquiry',obj);
  }

  getOtp(mobNo:any):Observable<any>{
    return this.http.post<any>(this.baseUrl + 'V1/SendSms_Clientbcbc',mobNo);
  }
  getState_countryList():Observable<any>{
    return this.http.post<any>(this.baseUrl + 'V1/ZicaCentreList',{});
  }
  getCenterList(obj:any):Observable<any>{
    return this.http.post<any>(this.baseUrl + 'V1/ZicaList',obj);
  }

  getCMSContent(obj:any):Observable<any>{
    return this.http.post<any>(this.cmsBaseURL + 'GetContentbytype',obj);
  }

  getZicaWebdata(obj:any){   
    return this.http.post<any>(this.cmsBaseURL + 'GetZicaWebdata',obj);
  }

  partner_stateList():Observable<any>{
    return this.http.get<any>('https://partner.zica.org/json/GetFranchiseeDetailsCity.json');
  }
}
