<section>
    <div class="main_body">
        <div class="bitCard_content">
            <app-sub-menu></app-sub-menu>
        </div>
        <div class="side_con">
            <div class="watsapp_icon"></div>
            <div class="download_icon"></div>
        </div>
        <div class="box1">
            <div class="row">
                <div class="col-md-6">
                    <div class="text2"><span class="color_text">Admission</span><br>
                        Form</div>
                    <!-- <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p>
                    </div> -->
                </div>
                <div class="col-md-6">
                    <div class="bg_form">
                        <app-admissions-form></app-admissions-form>
                    </div>

                </div>
            </div>
        </div>
        <div class="box10">
            <div class="row mt-5" *ngFor="let data of centerInfodata">
                <div class="col-md-6">
                    <div class="img_box_bgNon" *ngFor="let dt of data.files">
                        <img src="{{dt.url}}" width="100%" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text2">Welcome TO {{centerdata?.Franchisee_Name}}</div>
                    <div class="text_para">
                        <div [innerHTML]="data.AboutUS"></div>
                        <!-- <p class="para_color">Central India’s leading Institute offering graphic designing & web
                            designing courses</p> -->
                        <!-- <p>Zee Institute of Creative Art Jaipur is a hub for innovation and creativity.
                             ZICA is unique in its teaching methodology and training standards. 
                             ZICA is completely dedicated to higher levels of interdisciplinary training in the fields of Design,
                              Animation and VFX. We are located in the Heart of City that is easily accessible from every part of city as well as for outstation students Our teaching methodology emphasizes on a practical learning approach inculcate the production practices. 
                              This approach is meant to help students face all industry-related challenges. 
                              At ZICA, students and faculties communicate and collaborate with one another to enhance their skills and knowledge.
                               Our course curriculum is focused on increasing exposure and promoting experimentation. 
                               Students are encouraged to attend seminars, events - to interact with industry experts all for exposure and a better understanding of the creative field that they are about to enter in the future.</p> -->
                    </div>
                    <!-- <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right"
                            aria-hidden="true"></i></button> -->
                </div>

            </div>
        </div>
        <div class="box2 mt-3">
            <div class="text2 text-center">Discover<span class="color_text"> Our Programs</span></div>
            <div class="text_para">
                <p>ZICA Unveils the Palette of Possibilities!-Discover Our Programs – Animation, VFX, Gaming, Interior
                    Design, Fashion Design, Digital Marketing and Photography. Your Canvas Awaits, Your Masterpiece
                    Begins Now!</p>
            </div>
            <div class="swiper-container two">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="slider-image" routerLink="/animation">
                            <img src="../../../assets/img/zica/slider/5.png" width="270px" height="480px" alt="slide 5">
                            <div class="bottom_label">Animation</div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image" routerLink="/visualEffect">
                            <img src="../../../assets/img/zica/slider/1.png" width="270px" height="480px" alt="slide 1">
                            <div class="bottom_label">Visual Effects</div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image" routerLink="/graphicDesign">
                            <img src="../../../assets/img/zica/slider/2.png" width="270px" height="480px" alt="slide 2">
                            <div class="bottom_label">Game Design</div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image" routerLink="/digitalPhotography">
                            <img src="../../../assets/img/zica/slider/3.png" width="270px" height="480px" alt="slide 3">
                            <div class="bottom_label">Degital Photography</div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image" routerLink="/digitalMarketing">
                            <img src="../../../assets/img/zica/slider/4.png" width="270px" height="480px" alt="slide 4">
                            <div class="bottom_label">Marketing</div>
                        </div>
                    </div>

                    <div class="swiper-slide">
                        <div class="slider-image" routerLink="/game">
                            <img src="../../../assets/img/zica/slider/6.png" width="270px" height="480px" alt="slide 5">
                            <div class="bottom_label">Game Design</div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image" routerLink="/fashionDesign">
                            <img src="../../../assets/img/zica/slider/7.png" width="270px" height="480px" alt="slide 5">
                            <div class="bottom_label">Dashion Design</div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image" routerLink="/interiorDesign">
                            <img src="../../../assets/img/zica/slider/8.png" width="270px" height="480px" alt="slide 5">
                            <div class="bottom_label">Interior Design</div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image" routerLink="/webDesign">
                            <img src="../../../assets/img/zica/slider/9.png" width="270px" height="480px" alt="slide 5">
                            <div class="bottom_label">Web Design</div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>

        <!-- <div class="box4">
            <div class="text2 text-center">Best of<span class="color_text"> Student’s work</span> 2023</div>
            <div class="text_para">
                <p class="text-center">CHECK OUT OUTSTANDING WORK OF OUR ZICA STUDENTS</p>
            </div>
            <div class="swiper-container two">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="slider-image">
                            <img src="../../../assets/img/zica/slider/Img1.png" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image">
                            <img src="../../../assets/img/zica/slider/Img2.png" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image">
                            <img src="../../../assets/img/zica/slider/Img3.png" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image">
                            <img src="../../../assets/img/zica/slider/Img4.png" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image">
                            <img src="../../../assets/img/zica/slider/Img5.png" alt="slide 2">
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="slider-image">
                            <img src="../../../assets/img/zica/slider/Img6.png" alt="slide 2">
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div> -->

        <div class="box11">
            <div class="">
                <div class="text2 text-center">ZEE STUDIO BENEFITS
                </div>
                <div class="text_para">
                    <p>Exclusive Internship Opportunity at ZEE Studios Jaipur, Gain experience with Industry professionals, Meet Celebrities, Live Shoot experience, Professional Shooting sets access, Seminars by Industry Experts.</p>
                </div>
            </div>
            <div class="body_content d-flex justify-content-center align-items-center">
                <div class="card p-3">
                  <nav>
                    <div class="nav nav-tabs mb-3 buttonList d-flex justify-content-between" id="nav-tab" role="tablist">
                      <div class="button_content" *ngFor="let a of careersAssignCategory">
                        <button class="nav-link" id="nav-Manual-tab" data-bs-toggle="tab" data-bs-target="#nav-Manual"
                          type="button" role="tab" aria-controls="nav-Manual" aria-selected="true"
                          (click)="getCatagory(a.categoryId)">{{a.categoryname}}</button>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div class="imggallery">
                <div class="row">
                  <div class="col-md-4 d-flex justify-content-center mt-4" *ngFor="let a of StudentWorkdata">
                    <div class="imgList" *ngFor="let b of a.files">
                      <a data-fancybox [attr.data-caption]="b.filename" href="{{b.url}}">
                        <img src="{{b.url}}" class="stdImg">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
        </div>

        <div class="box5">
            <div class="text2 text-center">ZICA’s <span class="color_text">Placement Cell</span></div>
            <div class="text_para text-center">
                <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                    Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                    Visual FX Labs And Imaging Technologies.</p>
            </div>
            <div class="cascade-slider_container" id="cascade-slider">
           
                <div class="cascade-slider_slides" >
                    <div class="cascade-slider_item" *ngFor="let data of careerplacementdata">
                        <div class='card align'>
                            <div class='photo' >
                                <div *ngFor="let dt of data.files">
                                    <img src="{{dt.url}}" width="300px" class="inner_img"
                                    height="275px">
                                    
                                </div>
                                <div *ngIf="!data.files">
                                    <img src="../../../assets/img/zica/slider/man.png" width="300px" class="inner_img"
                                    height="275px">
                                </div>                                
                                <div class='info'>
                                    <div class="heading">{{data.Title}}</div>
                                    <div class="sub_text">{{data.short}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span class="cascade-slider_arrow cascade-slider_arrow-left" data-action="prev"><img
                        src="../../../assets/img/zica/icon/prev.png" type="image/svg+xml"></span>
                <span class="cascade-slider_arrow cascade-slider_arrow-right" data-action="next"><img
                        src="../../../assets/img/zica/icon/next.png" type="image/svg+xml"></span>
            </div>

            <div class="logo_list">
                <div class="text_para text-center">
                    <p class="text-center">Few companies where ZICA students have been hired:</p>
                </div>
                <div class="logo_content">
                    <!-- <div class="row">
                        <div class="col-2">
                            <div class="logo">
                                <img src="../../../assets/img/zica/logo1.png" alt="">
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="logo">
                                <img src="../../../assets/img/zica/logo2.png" alt="">
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="logo">
                                <img src="../../../assets/img/zica/logo3.png" alt="">
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="logo">
                                <img src="../../../assets/img/zica/logo4.png" alt="">
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="logo">
                                <img src="../../../assets/img/zica/logo5.png" alt="">
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="logo">
                                <img src="../../../assets/img/zica/logo6.png" alt="">
                            </div>
                        </div>

                    </div> -->
                    <div class="row">
                        <div class="col-2" *ngFor="let a of PlacementCompanydata; let i = index">
                            <div *ngIf="i <= 6">
                            <div class="logo" *ngFor="let b of a.files">
                                <img src="{{b.url}}" alt="{{b.filename}}" width="100%">
                            </div>
                        </div>
                        <div *ngIf="showAllLogo">
                        <div *ngIf="i >= 7">
                            <div class="logo" *ngFor="let b of a.files">
                                <img src="{{b.url}}" alt="{{b.filename}}" width="100%">
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
               
                </div>
                <div class="next_btn">
                    <button class="btn nextbtn" (click)="showAllLosgoList();">View all</button>
                </div>
            </div>
        </div>

        <div class="box6">
            <div class="row">
                <div class="col-md-7">
                    <div class="text2">Let’s Hear It From<br><span class="color_text"> Our Students</span>
                    </div>
                    <!-- <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p>
                    </div> -->
                </div>
                <div class="col-md-5">
                    <div class="right_goalsImg_box d-flex justify-content-center">
                        <img src="../../../assets/img/zica/d_comma.png" width="280px" height="280px" alt="">
                    </div>
                </div>
            </div>

            <div class="multi_box_content mt-3">
                <div class="row" style="width:100%">
                    <div class="col-lg-3 col-md-3 col-6 mt-3" *ngFor="let dt of StudentTestimonialdata">
                        <div *ngIf="dt.slug" class="video_content" (click)="playvideo(dt.slug)">
                            <div *ngFor="let b of dt.files">
                                <a class="img_content" data-fancybox href="{{videoUrl}}"> 
                                <i class='fa fa-play'></i> 
                                <div class="playText_content"> 
                                    <div class="text-white titlePLayList text-center">{{dt.Title}}</div>
                                    <div class="text-white text-center">{{dt.short}}</div>
                                </div> 
                                    <img src="{{b.url}}" width="100%" height="368px" alt="{{b.filename}}">
                                </a>       
                            </div>
                        </div>
                        <div *ngIf="!dt.slug" class="sub_box profile_box">
                            <div class="left_icon" *ngFor="let a of dt.files">
                                <img src="{{a.url}}" width="75px" height="75px" alt="">
                            </div>
                            <div class="profile_name">{{dt.Title}}</div>
                            <div class="details">
                                {{dt.Short}}
                            </div>
                            <div class="para">
                                <p [innerHTML]="dt.Long1"></p>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>


        <div class="box8 mt-5" *ngIf="Faqdata">
            <div class="row">
                <div class="col-md-6">
                    <div class="text2">Frequently Asked<br><span class="color_text"> Questions</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <app-faqs [FAQsdata]="Faqdata"></app-faqs>
                </div>
            </div>
        </div>

        <div class="box9">
            <div class="topArow" onclick="window.scrollTo(0, 0);"></div>
            <div class="midea_list">   
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-6">
                        <div class="midea_box">
                            <div class="midea_img">
                                <img src="../../../assets/img/zica/icon/phone_img.png" width="50px" height="50px"
                                    alt="">
                            </div>
                            <div class="midea_phone">Phone</div>
                            <div class="midea_text" data-toggle="tooltip" data-placement="bottom" title="{{centerdata?.Phone_No1}}">+91 {{centerdata?.Phone_No1 | slice:0:11}}</div>
                            <div class="midea_text" data-toggle="tooltip" data-placement="bottom" title="{{centerdata?.Mobile_No}}">+91 {{centerdata?.Mobile_No | slice:0:11}}</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-6">
                        <div class="midea_box">
                            <div class="midea_img">
                                <img src="../../../assets/img/zica/icon/email_img.png" width="50px" height="50px"
                                    alt="">
                            </div>
                            <div class="midea_phone">Email</div>
                            <div class="midea_text">{{centerdata?.Email_Id}}</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-6">
                        <div class="midea_box">
                            <div class="midea_img">
                                <img src="../../../assets/img/zica/icon/watch_img.png" width="50px" height="50px"
                                    alt="">
                            </div>
                            <div class="midea_phone">Time</div>
                            <div class="midea_text">10:00 a.m. to 07:00 p.m.</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-6">
                        <div class="midea_box">
                            <div class="midea_img">
                                <img src="../../../assets/img/zica/icon/location_img.png" width="50px" height="50px"
                                    alt="">
                            </div>
                            <div class="midea_phone">Address</div>
                            <div class="midea_text">{{centerdata?.Address1}}
                                {{centerdata?.Address2}} {{centerdata?.City_Name}}
                                {{centerdata?.State_Name}}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box12 mt-5">
            <div class="map_content">
                <div class="map">
                    <iframe [src]='mapurl' frameborder="0" allowfullscreen webkitallowfullscreen mozallowfullscreen>
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</section>