<section>
    <div class="main_body">
        <div class="bitCard_content d-flex">
            <app-sub-menu></app-sub-menu><span class="text-white select_item">> {{zoonTitle}}</span> 
             </div>
        <div class="box1">
            <div class="row">
                <div class="col-md-6">
                    <div class="left_box">
                        <div class="text2">Locate Us
                        </div>
                        <div class="text_para">
                            <p>Locate our {{zoonTitle}} ZICA centre</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="right_box">
                        <div class="img_box">
                        <img src="../../../assets/img/zica/locatUsBgImg.png" width="100%" alt="locatUsBgImg">
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box2">
            <div class="search_box">
                <form action="" [formGroup]="searchForm">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group search_sub_box">
                            <select name="" id="" class="form-control" formControlName="state"
                            (change)="selectState($any($event.target).value)">
                            <option value="">State/District</option>
                            <option value="{{state.State_Id}}" *ngFor="let state of stateList">
                                {{state.State_Name}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group search_sub_box">
                            <select name="" id="" class="form-control  dropdown-toggle" formControlName="city"
                            (change)="selectCity($any($event.target).value)">
                            <option value="">Select City</option>
                            <option value="{{city.City_Id}}" *ngFor="let city of cityList">{{city.City_Name}}
                            </option>
                        </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group search_sub_box">
                            <button class="search_btn" (click)="filterData()">Search</button>
                        </div>
                    </div>
                </div>
            </form>
            </div>
            <div class="map_box">
                <div class="row" *ngFor="let a of centerList">
                    <div class="col-md-6">
                        <div class="left_box">
                            <div class="text2">{{a.Franchisee_Name}}
                            </div>
                            <div class="text_para">
                               <img src="../../../assets/img/zica/icon/mob_icon.png"alt="mob_icon" width="20px" height="20px"> <p>{{a.Mobile_No}}</p>
                            </div>
                            <div class="text_para">
                                <img src="../../../assets/img/zica/icon/email_icon.png"alt="email_icon" width="20px" height="20px"> <p>{{a.Email_Id}}</p>
                             </div>
                             <div class="text_para">
                                <img src="../../../assets/img/zica/icon/location_icon.png"alt="location_icon" width="20px" height="20px"> <p>{{a.Address1}},{{a.Address2}},{{a.Place}} - {{a.Pin_Code}}</p>
                             </div>
                             <div class="text_para">
                                <img src="../../../assets/img/zica/icon/url_icon.png"alt="url_icon" width="20px" height="20px"><p><a href="/center/{{a.slug}}">https://www.zica.org/centre/{{a.slug}}</a></p>
                             </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="right_box">
                            <div class="map">
                            <iframe  [src]='a.mapurl'  width="100%" height="315"   frameborder="0"   allowfullscreen webkitallowfullscreen mozallowfullscreen>
                            </iframe>
                              </div>
                        </div>
                    </div>
                    <hr class="mt-5">
                </div>
            </div>
        </div>
        </div>
</section>