<section>
    <div class="box1">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
         </div>
        <div class="row">
            <div class="col-md-6">
                <div class="text1">Course Detail</div>
                <div class="text2">Web Design
                </div>
                <div class="text_para">
                    <div class="row">
                      <div class="col-12">
                        <ul>
                          <li>A complete web design program as per current market trend</li>
                          <li>Incredible placement record.</li>
                          <li>Create an outstanding web design portfolio.</li>
                        </ul>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img_box">
                    <div class="imgSubBox d-flex">
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/web1.png" class="aniImg1" alt="Web Design">
                        </div>
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/web2.png" class="aniImg2" alt="Web Design">
                        </div>
                    </div>
                    <div class="imgContent2">
                        <img src="../../../../assets/img/zica/course/web3.png" class="aniImg3" alt="Web Design">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box2">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-12">
                <div class="left_box">
                    <div class="text2">Program in Web Design<span class="color_text"> (6 Months)</span>
                    </div>
                    <div class="sub_text mt-4 text-white">Learn from<span
                            class="sub_text_color"> Professionals</span></div>
                    <div class="text_para">
                        <p>
                          Web Design typically includes a combination of text, images, animation, audio-video & other interactive forms of media, embedded in the presentable form for web/internet. Learn website design from seasoned professionals. Gain insights into HTML, CSS, and JavaScript, and master design tools. Understand user experience (UX) principles and responsive design. Get hands-on experience and feedback. Build a portfolio showcasing your skills. Develop problem-solving abilities and industry-relevant knowledge to create stunning and functional websites.
                        </p>
                        <div class="sub_text mt-3 text-white">Become a professional artist</div>
                        <div class="text_para">
                            <p>By mastering website design, students can transform into professional artists of the digital realm. They cultivate expertise in combining technical skills, creativity, and user-centered design principles. Through proficiency in HTML, CSS, and design software, they craft visually captivating and functional web experiences. This expertise empowers them to communicate ideas, evoke emotions, and tell stories through web design, making them capable of creating captivating digital artistry that engages and resonates with online audiences.</p>
                        </div>
                        <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                        <div class="text_para">
                            <p>A website designer must be industry-ready, possessing a solid understanding of web development languages (HTML, CSS, JavaScript), design principles, and responsive design. They should excel in using industry-standard design software and tools. Effective communication, project management skills, and a keen eye for aesthetics are also crucial. Being up-to-date with the latest design and technology trends ensures they can meet the demands of the rapidly evolving web design industry.</p>
                        </div>
                        <div class="sub_text mt-4 text-white">Program Highlights</div>
                            <div class="text_para">
                              <ul>
                                  <li>Increase design and visualization skills</li>
                                  <li>Learn complete Web Design pipeline</li>
                                  <li>Continuous evaluation through competitions and activities</li>
                                  <li>Placement support</li>
                              </ul>
                            </div>
                              <div class="accordion mt-2" id="accordionExample1">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                        Module 01
                                    </button>
                                  </h2>
                                  <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-6">
                                        <li>Website Design concept</li>
                                        <li>Web Animation</li>
                                        <li>Scripting with Animate</li>
                                        <li>Website Basic</li>
                                      </div>
                                      <div class="col-6">
                                        <ul>
                                          <li>Web Script</li>
                                          <li>Web Layout</li>
                                          <li>Server-Side Script</li>
                                          <li>Responsive Layout - UI</li> 
                                          <li>Video Editing</li>
                                          </ul>
                                      </div>
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                              <p>After successful completion of the said program, a student is eligible for following job categories.</p>
                              <div class="row mt-3">
                                <div class="col-6">
                                    <ul>
                                        <li>UI Designer</li>
                                        <li>UX Designer</li>
                                        <li>Website creator</li>
                                      </ul>
                                </div>
                                <div class="col-6">
                                    <ul>
                                        <li>Web Animator</li>
                                        <li>Video Editor</li>
                                      </ul>
                                </div>
                              </div>
                             

                              <!-- <div class="sub_text mt-4 text-white">2. Professional Program in Animation<span
                                class="sub_text_color">(1yr)</span></div>
                                <div class="text_para">
                                    <p>From Magazines, Newspapers, Websites, Mobile apps to product packaging we can see work of Graphic designers everywhere. Infect every kind of Advertisements include use graphic design.</p>
                                </div> -->
                                <!-- <div class="accordion mt-2" id="accordionExample1">
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                            Module 01: Program Highlights
                                        </button>
                                      </h2>
                                      <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                        <div class="accordion-body">
                                        <div class="row">
                                          <div class="col-6">
                                              <li>Increase design and visualization skills</li>
                                              <li>Learn complete Web Design pipeline</li>
                                          </div>
                                          <div class="col-6">
                                            <li>Continuous evaluation through competitions and activities</li>
                                            <li>Placement support</li>
                                          </div>
                                        </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
                                            Module 02: Delivery method
                                        </button>
                                      </h2>
                                      <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample1">
                                        <div class="accordion-body">
                                        <div class="row">
                                          <div class="col-6">
                                            	<li>Weekday batches: 5 days a week (2 hours per day)</li>
                                            	<li>Practical session backed by theory explanation</li>
                                          </div>
                                          <div class="col-6">
                                            <li>Practical assignment and Project-based learning</li>
                                            <li>Industry-aligned creative and technical programs</li>
                                        </div>
                                        </div>  
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                      <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="" data-bs-target="#collapseThree3" aria-expanded="false" aria-controls="collapseThree3">
                                            Module 03: PROGRAM OUTLINE
                                        </button>
                                      </h2>
                                      <div id="collapseThree3" class="accordion-collapse collapse" aria-labelledby="headingThree3" data-bs-parent="#accordionExample1">
                                        <div class="accordion-body">
                                          <div class="row">
                                            <div class="col-6">
                                                <li>Website Design concept</li>
                                                <li>Web Animation</li>
                                                <li>Scripting with Animate</li>
                                                <li>Website Basic</li>
                                            </div>
                                            <div class="col-6">
                                              <li>Web Script</li>
                                              <li>Web Layout</li>
                                              <li>Server-Side Script</li>
                                              <li>Responsive Layout - UI </li>
                                              <li>Video Editing</li>
                                          </div>
                                          </div>  
                                        </div>
                                      </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFour">
                                          <button class="accordion-button collapsed" type="button" data-bs-toggle="" data-bs-target="#headingFour4" aria-expanded="false" aria-controls="headingFour4">
                                            Module 04: Editing & Compositing (3 Months)
                                          </button>
                                        </h2>
                                        <div id="headingFour4" class="accordion-collapse collapse" aria-labelledby="headingFour4" data-bs-parent="#accordionExample1">
                                          <div class="accordion-body">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                          </div>
                                        </div>
                                      </div>
                                  </div> -->
    
                                  <!-- <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                                  <div class="row mt-3">
                                    <div class="col-6">
                                        <ul>
                                            <li>Modeling Artist</li>
                                            <li>Character Animator</li>
                                            <li>FX Artist</li>
                                            <li>Compositor</li>
                                            <li>Motion Graphics Artist</li>
                                            <li>Matchmoving Artist</li>
                                            <li>Lighting Artist</li>
                                          </ul>
                                    </div>
                                    <div class="col-6">
                                        <ul>
                                            <li>Lighting Artist</li>
                                            <li>Render Artist</li>
                                            <li>Layout Artist</li>
                                            <li>Digital sculptor</li>
                                            <li>Rigging Artist</li>
                                            <li>Roto and Clean-Up Artist</li>
                                            <li>Video Editor</li>
                                        
                                          </ul>
                                    </div>
                                  </div> -->
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="list_box">
                    <div class="list">
                    <div class="letf_text sub_text_color">Level</div>
                    <div class="right_text">Intermediate</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Duration</div>
                    <div class="right_text">6 Months</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Eligibility</div>
                    <div class="right_text">10th Pass</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Avg. Package</div>
                    <div class="right_text">12LPA</div>
                </div>
                </div>
            </div> -->
        </div>
    </div>

    <div class="box3">
        <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
        <div class="text_para text-center">
            <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                Visual FX Labs And Imaging Technologies.</p>
        </div>
        <app-blogs></app-blogs>
        </div>
        <div class="box4 mt-5">
            <div class="row">
                <div class="col-md-6">
                  <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                  </div>
                  <div class="text_para">
                   <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Web Design">
                  </div>
                </div>
                <div class="col-md-6">
                    <app-faqs></app-faqs>
                </div>
            </div>
        </div>
        
        <div class="box5">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                   
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <div class="text_para">
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p> -->
                  </div>
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
               
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Web Design">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p>
                    </div>
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Web Design">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <!-- <app-franchise-form></app-franchise-form> -->
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
</section>