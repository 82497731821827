<section>
    <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="box1">
        <div class="row">
            <div class="col-md-6">
                <div class="left_box">
                    <div class="text2">
                        Franchies<span class="color_text"> Business</span> Opportunity
                    </div>
                    <div class="text_para">
                        <p class="mt-2">Partner With India’s Leading Education Company Zee Learn, A Part Of The Zee Group.</p>
                        <p><span class="color_text">All you need</span> is a minimum 2000* sq feet<br>
                            <span class="color_text"> space and an</span> minimum investment ₹ 30* lakhs<br>
                            <span class="color_text"> with</span> Committed Entrepreneurial Spirit</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="right_box">
                    <img src="../../assets/img/zica/franchise_img.png" width="100%" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="box2">
        <div class="franchise_form">
            <div class="text2 text-center">Franchise<span class="color_text"> Enquiry</span> Form</div>
            <!-- <div class="text_para text-center">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
            </div> -->
            <div class="mt-5">
         <app-franchise-form ></app-franchise-form>
        </div>
        </div>
    </div>
    <div class="box3 mt-5">
        <div class="text_content">
            <div class="title">Building Partnership</div>
            <div class="text_para">
                <p>In the year 1995, the Chairman Dr. Subash Candra started Zee Institute of Creative Art (ZICA), the
                    nation's first full-fledged Classical and Digital Animation Training Academy that trains youth in
                    classical 2D and modern 3D animation. The past 21 years ZICA has nurtured some of the brightest
                    talents in the world of animation. ZICA adopted a novel training style and is focused entirely on
                    creating a stimulating environment for its students.</p>
                <p>ZICA, with its state-of-the-art infrastructure, gives practical training in 2D Animation, 3D
                    Animation, Visual Effects & Gaming, covering the stages of visualization, Pre-production, production
                    and post-production. At ZICA, a lot of emphasis is laid on the principles of art and design and
                    fundamentals of classical animation, while extracting the same knowledge to computer based Digital
                    animation.
                    Today ZICA, with over 15 centres in cities including Mumbai, Pune, Bangalore, Noida, Lucknow,
                    Bhubaneswar, Indore etc. emerges as young India's chosen partner to turn passion into profession</p>
            </div>

            <div class="title">Why Animation & VFX business?</div>
            <div class="text_para">
                <li class="mt-3">According to a report released by Ernst & Young, the higher education sector in India is expected to
                    grow by 18% (CAGR) till 2020</li>
                <li>Animation & VFX industry itself is on an upswing: KPMG expects this sector to grow at 18.5% to reach
                    55.9 billion rupees by 2015. The growing demand for television content continues to be a key driver
                    for growth of the animation & VFX industry</li>
                <p>With the entry of global players such as Disney & Cartoon Network in India, and major Hollywood
                    production houses constantly outsourcing their work to Indian studios, there is a huge gap between
                    demand & supply of trained people to work in this industry.</p>
                <p>DELIVERABLES OF ZEE INSTITUTE OF CREATIVE ART</p>
                <p>Content development</p>
                <p>Our content development team comprises SMEs who work closely with industry experts to build up any
                    content that needs to be developed even If it means customizing content to cater to the varying and
                    unique demands of our Institutional business partners or internal customers. Significantly, we also
                    provide you with the added advantage of tailoring the training to meet the current market trends and
                    needs.</p>
                <p>Structured Training Programme</p>
                <p>The ZICA programs’ curriculum is designed as per university standards and is in the vanguard of these
                    industries by way of exhaustive research and meticulous planning and design.
                    Advertising & National campaigns
                    ZICA handles the brand building activities. Local level promotions will be funded and executed by
                    the franchisee
                    Art works and Designs
                    ZICA will provide all the designs for the marketing activities that are required to promote the
                    programs.
                    Placement Services
                    The Placement cell provides 100 % placement assistance for all students undertaking the ZICA
                    programs. Senior Industry professionals are invited to the institute on a regular basis to offer
                    up-to-date industry insights to the students
                    Quality Assessors
                    Visit the center to ensure quality standards are achieved in all aspects and to gauge on the levels
                    of competencies in processes and procedures to enhance productivity.
                    Training of Institute Staff
                    At ZICA we are highly committed to enhancing skills and knowledge of the Administrative and Sales
                    staff to empower our franchisees to maximize on sales and revenue resulting from the deployment of
                    the contemporary solutions we offer.
                    Experts from our team will conduct seminars and specialized training programs. These training
                    sessions are tailored to fit specific regional requirements. Every member of the institute will be
                    equipped with a clear understanding of product knowledge software usage development methodologies
                    Hands on training to all staff members are conducted by the ZICA team on product, operations,
                    accounts and sales techniques. The training is held at the regional Office of ZICA and within the
                    premises of the institute. The cost of lodging and boarding, traveling, etc. would be borne by
                    Franchisee.</p>
            </div>
        </div>
    </div>
    <div class="box4">
        <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
        <div class="text_para text-center">
            <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                Visual FX Labs And Imaging Technologies.</p>
        </div>
        <app-blogs></app-blogs>
    </div>
</section>