<section>
    <div class="main_body">
        <div class="bitCard_content">
            <app-sub-menu></app-sub-menu>
        </div>
        <div class="box1">
            <div class="row">
                <div class="col-md-6">
                    <div class="left_box">
                        <!-- <div class="text1">Resources / Awards & Recognition</div> -->
                        <div class="text2">Awards & <br>Recognition

                        </div>
                        <div class="text_para">
                            <p>ZICA Celebrates AESTHETICS, student conclave of Animation, VFX and Film Making professionals, students and corporates in the related fields. It gives a common platform to the students where the students participate from all corners of the country,
                                 exhibit their creative talent and win awards.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="right_box">
                        <div class="img_box">
                            <img src="../../../assets/img/zica/awards1.png" width="100%" alt="Awards And Recognition">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box2">
            <div class="text2 text-center">ZICA Awards & Recognition</div>
            <div class="text_para text-center">
                <p>ZICA is the part of Zee Group, India’s Largest TV Network which comprises of Channels like Zee Tv,
                    Zee Cinema, Zee News, & Tv, & Pictures etc. ZICA as a brand started its journey 20 yrs back in
                    Mumbai.</p>
            </div>
            <div class="awardImg_content">
                <div class="row">
                    <div class="col-md-6">
                        <div class="awardImg">
                            <img src="../../../assets/img/zica/award/awardImg.png" width="100%" alt="Awards And Recognition">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="awardImg">
                            <img src="../../../assets/img/zica/award/awardImg2.png" width="100%" alt="Awards And Recognition">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="awardImg">
                            <img src="../../../assets/img/zica/award/awardImg3.png" width="100%" alt="Awards And Recognition">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box3">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">

                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <!-- <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p>
                    </div> -->
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">

                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Awards And Recognition">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <!-- <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p>
                    </div> -->
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Awards And Recognition">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <!-- <app-franchise-form></app-franchise-form> -->
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>