

<section>
    <div class="box1">
        <div class="row">
            <div class="col-md-6">
                <div class="text2">ZICA Unveils <br>the<span class="color_text">  Palette of Possibilities!</span>
                </div>
                <div class="text_para">
                    <p>Discover Our Programs – Animation, VFX, Gaming, Interior Design, Fashion Design, Digital Marketing and Photography. Your Canvas Awaits, Your Masterpiece Begins Now!</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img_box">
                    <img src="../../../assets/img/zica/palette.png" width="100%" alt="Programmes">
                </div>
            </div>
        </div>
    </div>
    <div class="box2">
        <div class="body_content d-flex justify-content-center align-items-center">
            <div class="card p-3">
                <nav>
                    <div class="nav nav-tabs mb-3 buttonList d-flex justify-content-between" id="nav-tab" role="tablist">
                        <button class="nav-link" id="nav-Animation-tab" data-bs-toggle="tab" data-bs-target="#nav-Animation" type="button" role="tab" aria-controls="nav-Animation" aria-selected="true" (click)="showContent('Animation')">Animation</button>
                        <button class="nav-link" id="nav-Visual-tab" data-bs-toggle="tab" data-bs-target="#nav-Visual" type="button" role="tab" aria-controls="nav-Visual" aria-selected="true" (click)="showContent('Visual')">Visual Design</button>
                        <button class="nav-link" id="nav-Game-tab" data-bs-toggle="tab" data-bs-target="#nav-Game" type="button" role="tab" aria-controls="nav-Game" aria-selected="true" (click)="showContent('Game')">Game Design</button>
                        <button class="nav-link" id="nav-Interior-tab" data-bs-toggle="tab" data-bs-target="#nav-Interior" type="button" role="tab" aria-controls="nav-Interior" aria-selected="true" (click)="showContent('Interior')">Interior Design</button>
                        <button class="nav-link" id="nav-Visual1-tab" data-bs-toggle="tab" data-bs-target="#nav-Visual1" type="button" role="tab" aria-controls="nav-Visual1" aria-selected="true" (click)="showContent('Visual1')">Visual1 Design</button>
                        <button class="nav-link" id="nav-Fashion-tab" data-bs-toggle="tab" data-bs-target="#nav-Fashion" type="button" role="tab" aria-controls="nav-Fashion" aria-selected="true" (click)="showContent('Fashion')">Fashion Design</button>
                        <button class="nav-link" id="nav-Graphic-tab" data-bs-toggle="tab" data-bs-target="#nav-Graphic" type="button" role="tab" aria-controls="nav-Graphic" aria-selected="true" (click)="showContent('Graphic')">Graphic Design</button>
                        <button class="nav-link" id="nav-Graphic1-tab" data-bs-toggle="tab" data-bs-target="#nav-Graphic1" type="button" role="tab" aria-controls="nav-Graphic1" aria-selected="true" (click)="showContent('Graphic1')">Graphic1 Design</button>
                    </div>
                </nav>

                <div class="tab-pane" *ngIf="showBox == 'Animation' || showBox == 'All'" >
                    <div class="row mt-5 flex-column-reverse flex-lg-row">
                        <div class="col-md-6">
                            <div class="text2">Animation</div>
                            <div class="text_para">
                                <!-- <p class="para_color">Central India’s leading Institute offering graphic designing & web designing courses</p> -->
                                <p>This unique program is focused on 3D Animation Visual Effects, wherein every student will create a short film during the course. This program begins with Fundamentals of Art and Design, Digital Painting and Digital 2D Animation, which is necessary for 3D Animation. The program ends with Visual Effects which is an industry demand today.</p>
                            </div>
                            <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>
                        <div class="col-md-6">
                            <div class="img_box_bgNon">
                                <img src="../../../assets/img/zica/palette.png" width="100%" alt="Programmes">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade active show" id="nav-Visual" *ngIf="showBox == 'Visual' || showBox == 'All'" role="tabpanel" aria-labelledby="nav-Visual-tab">
                    <div class="row mt-5">
                        <div class="col-md-6">
                            <div class="img_box_bgNon">
                                <img src="../../../assets/img/zica/vs_design.png" width="100%" alt="Programmes">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="text2">Visual Design</div>
                            <div class="text_para">
                                <!-- <p class="para_color">Central India’s leading Institute offering graphic designing & web designing courses</p> -->
                                <p>The first module is to establish any small or large business to promote or market any ideas, products, share thoughts or propagate any information at lightning speed with the whole world. In this semester student will learn how to design any kind of webpage with simple tools such as Adobe Photoshop, HTML5 / CSS3, JAVA Script, Bootstrap, Adobe Dreamweaver, Adobe Edge Animate, Action Script, Adobe Premiere. The second module will help the student to work on the backend of any webpage. This is session will deal with a server-side script, content management system, framework, dynamic site and domain hosting. In this module students will learn the web development skill through AJAX, PHP, CMS, Codeigniter, Wordpress and Joomla.

                                </p>
                            </div>
                            <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>
                     
                    </div>
                </div>
                <div class="tab-pane fade active show" id="nav-Game" *ngIf="showBox == 'Game' || showBox == 'All'" role="tabpanel" aria-labelledby="nav-Game-tab">
                    <div class="row mt-5 flex-column-reverse flex-lg-row">
                        <div class="col-md-6">
                            <div class="text2">Game Design</div>
                            <div class="text_para">
                                <!-- <p class="para_color">Central India’s leading Institute offering graphic designing & web designing courses</p> -->
                                <p>
                                    Learn to create concept Game Art, Storyboard, Digital Paint, Matte paint, Crate props, environment and character. To provide students with a foundational understanding of the principles and concepts specific to the field of game art.
	                                To teach the fundamental principles of computer graphics, including pixel manipulation, rendering, and image processing.
	                                To train students in the art of creating organic and character models with a focus on topology, realism, and aesthetics.
                                </p>
                            </div>
                            <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>
                        <div class="col-md-6">
                            <div class="img_box_bgNon">
                                <img src="../../../assets/img/zica/gd.png" width="100%" alt="Programmes">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade active show" id="nav-Interior" *ngIf="showBox == 'Interior' || showBox == 'All'" role="tabpanel" aria-labelledby="nav-Interior-tab">
                    <div class="row mt-5">
                        <div class="col-md-6">
                            <div class="img_box_bgNon">
                                <img src="../../../assets/img/zica/ID.png" width="100%" alt="Programmes">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="text2">Interior Design</div>
                            <div class="text_para">
                                <!-- <p class="para_color">Central India’s leading Institute offering graphic designing & web designing courses</p> -->
                                <p>
                                    To introduce students to the fundamental principles, concepts, and theories of interior design as a discipline.
To specialize in designing residential interiors, considering the unique requirements and preferences of homeowners.
To learn advanced computer rendering techniques for creating realistic 3D walkthroughs of interior spaces.
	To gain expertise in designing restaurant and hospitality spaces, considering customer experience and functional requirements.
	To develop research and communication skills essential for academic and professional advancement.
	To expand and update the professional portfolio with advanced and specialized projects.
                                </p>
                            </div>
                            <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>
                     
                    </div>
                </div>
              
                <div class="tab-pane fade active show" id="nav-Fashion" *ngIf="showBox == 'Fashion' || showBox == 'All'" role="tabpanel" aria-labelledby="nav-Fashion-tab">
                    <div class="row mt-5 flex-column-reverse flex-lg-row">
                        <div class="col-md-6">
                            <div class="text2">Fashion Design</div>
                            <div class="text_para">
                                <!-- <p class="para_color">Central India’s leading Institute offering graphic designing & web designing courses</p> -->
                                <p>The program covers a broad spectrum of topics, including drawing, design principles, garment construction, textiles, CAD software, and fashion marketing. 
                                    Students have the opportunity to apply their knowledge through hands-on design projects, draping, pattern making, and garment construction, allowing them to develop practical design skills.
                                    The curriculum includes modules on fashion forecasting, merchandising, export-import business, and quality control, preparing students to understand and navigate the real-world challenges of the fashion industry.
                                    Students are equipped with essential digital design skills through training through industry standard software, which are vital for contemporary fashion design and presentation.</p>
                            </div>
                            <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>
                        <div class="col-md-6">
                            <div class="img_box_bgNon">
                                <img src="../../../assets/img/zica/FD.png" width="100%" alt="Programmes">
                            </div>
                        </div>
                      
                     
                    </div>
                </div>
                <div class="tab-pane fade active show" id="nav-Graphic" *ngIf="showBox == 'Graphic' || showBox == 'All'" role="tabpanel" aria-labelledby="nav-Graphic-tab">
                    <div class="row mt-5">
                        
                        <div class="col-md-6">
                            <div class="img_box_bgNon">
                                <img src="../../../assets/img/zica/gd_design.png" width="100%" alt="Programmes">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="text2">Graphic Design</div>
                            <div class="text_para">
                                <!-- <p class="para_color">Central India’s leading Institute offering graphic designing & web designing courses</p> -->
                                <p>Comprehensive Graphic design program to cater extremely growing demand in the industry.
                                    	A wide range of graphic design job opportunities.
                                    	Introduction of Augmented and Virtual Reality.
                                    	Create an outstanding portfolio.</p>
                            </div>
                            <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div> 
                     
                    </div>
                </div>
                <div class="tab-pane fade active show" id="nav-Graphic" *ngIf="showBox == 'Graphic' || showBox == 'All'" role="tabpanel" aria-labelledby="nav-Graphic-tab">
                    <div class="row mt-5 flex-column-reverse flex-lg-row">
                        <div class="col-md-6">
                            <div class="text2">Web Design</div>
                            <div class="text_para">
                                <!-- <p class="para_color">Central India’s leading Institute offering graphic designing & web designing courses</p> -->
                                <p>A complete web design program as per current market trend.
                                    Incredible placement record.
                                    Create an outstanding web design portfolio.</p>
                            </div>
                            <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div> 
                        <div class="col-md-6">
                            <div class="img_box_bgNon">
                                <img src="../../../assets/img/zica/wd.png" width="100%" alt="Programmes">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade active show" id="nav-Graphic1" *ngIf="showBox == 'Graphic1' || showBox == 'All'" role="tabpanel" aria-labelledby="nav-Graphic1-tab">
                    <div class="row mt-5">
                        <div class="col-md-6">
                            <div class="img_box_bgNon">
                                <img src="../../../assets/img/zica/dm.png" width="100%" alt="Programmes">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="text2">Digital Marketing</div>
                            <div class="text_para">
                                <!-- <p class="para_color">Central India’s leading Institute offering graphic designing & web designing courses</p> -->
                                <p>
                                    Along with ZICA’s program completion certificate, the student can apply for multiple certificates from Google, Facebook, Hubspot, LinkedIn etc.
                                    Increase technical and visualization skills.
                                    Learn from Google certified professionals.</p>
                            </div>
                            <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>   
                       
                    </div>
                </div>
     
                <div class="tab-pane fade active show" id="nav-Graphic1" role="tabpanel" *ngIf="showBox == 'Visual1' || showBox == 'All'" aria-labelledby="nav-Graphic1-tab">
                    <div class="row mt-5 flex-column-reverse flex-lg-row">
                               <div class="col-md-6">
                            <div class="text2">Digital Photography</div>
                            <div class="text_para">
                                <!-- <p class="para_color">Central India’s leading Institute offering graphic designing & web designing courses</p> -->
                                <p>From Magazines, Newspapers, Websites, Mobile apps to product packaging we can see work of Graphic designers everywhere. Infect every kind of Advertisements include use graphic design.Website & Mobile apps cannot be made without the active contribution of web designer. In this Digital Era each & every business needs websites & apps to present their offerings to customers. </p>
                            </div>
                            <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>   
                        <div class="col-md-6">
                            <div class="img_box_bgNon">
                                <img src="../../../assets/img/zica/dp.png" width="100%" alt="Programmes">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>