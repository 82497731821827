import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-centers',
  templateUrl: './centers.component.html',
  styleUrls: ['./centers.component.css']
})
export class CentersComponent implements OnInit {
  showAllLogo: boolean = false;
  placementLogoList: any;
  careersPlacementList: any;
  centerNM:string="";
  centerInfodata:any=[];
  StudentWorkdata:any=[];
  careersAssignCategory:any=[];
  AllStudentWorkdata:any=[];
  street:string="";
  PlacementCompanydata:any=[];
  careerplacementdata:any=[];
  StudentTestimonialdata:any=[];
  Faqdata!:any;
  centerdata:any=[];
  mapurl!:any;
  videoUrl:any
  constructor(private _service: CommonService, private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxSpinner: NgxSpinnerService, public sanitizer: DomSanitizer,
    private projectService: ProjectSeoService,
    private apiService: ApicallService) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
  }
  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      params = params['centerPath'];
      this.centerNM=params;
      localStorage.setItem('iscenter','true');
      setTimeout(() => {
        this.getZicawebdata();
      }, 1000)  
    });

   
  }

  showAllLosgoList() {
    this.showAllLogo = true
  }

  // getCenterLogoList() {
  //   let obj = {
  //     "Type": "PLACEMENT COMPANY",
  //     "Project_Id": 3
  //   }
  //   this._service.getCMSContent(obj).subscribe(
  //     res => {
  //       this.placementLogoList = JSON.parse(res.data[0].contentData)
  //       this.getPlacementList();
  //     }
  //   )
  // }

  // getPlacementList() {
  //   let obj = {
  //     "Type": "CAREER PLACEMENT",
  //     "Project_Id": 3
  //   }
  //   this._service.getCMSContent(obj).subscribe(
  //     res => {
  //       this.careersPlacementList = JSON.parse(res.data[0].contentData)
  //      this.getZicawebdata();
  //     }
  //   )
  // }

  getZicawebdata(){
    let jdata={
      "slug":this.centerNM
    }
    this._service.getZicaWebdata(jdata).subscribe({
      next:(resp:any)=>{
        this.getcenterdetails();
        if(resp){
          if(resp.data[0].CenterInfo){
            this.centerInfodata=JSON.parse(resp.data[0].CenterInfo);
          }
          if(resp.data[0].StudentWork){
            this.StudentWorkdata=JSON.parse(resp.data[0].StudentWork);
            this.AllStudentWorkdata=JSON.parse(resp.data[0].StudentWork);            
          }
          if(resp.data[0].AssignStudentWorkCategory){
            this.careersAssignCategory=JSON.parse(resp.data[0].AssignStudentWorkCategory);
          }
          if(resp.data[0].PlacementCompany){
            this.PlacementCompanydata=JSON.parse(resp.data[0].PlacementCompany);
          }   
          if(resp.data[0].careerplacement){
            this.careerplacementdata=JSON.parse(resp.data[0].careerplacement);
          }   
          if(resp.data[0].StudentTestimonial){
            this.StudentTestimonialdata=JSON.parse(resp.data[0].StudentTestimonial);
          }    
          if(resp.data[0].FAQs){
            this.Faqdata=JSON.parse(resp.data[0].FAQs);
          }
        }
      },
      error:(error:any)=>{
        this.getcenterdetails();
        console.log(error);
      }
    })
  }

  playvideo(url:string){
    if(url){
      this.videoUrl =this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+url)
    }
   
  }

  getcenterdetails(){
    let obj={
      "slug":this.centerNM
    }
    this._service.getCenterList(obj).subscribe(
      res => {
        this.centerdata = res[0];        
        this.street=this.centerdata?.Franchisee_Name +' '+ this.centerdata?.Address1 +' ' + this.centerdata?.Address2 +' '+ + this.centerdata?.City_Name +' '+ + this.centerdata?.State_Name 
        this.mapurl=this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${this.street}%20&t=&z=20&ie=UTF8&iwloc=&output=embed`);
        this._service.setcenterdt(this.centerdata);
      })
  }


  getCatagory(catId: any) {
    let arr = this.AllStudentWorkdata.filter((x: any) => {
      return catId === parseInt(x.category)
    })
    this.StudentWorkdata =arr;
  }
}
