<section>
    <div class="box1">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
         </div>
        <div class="row">
            <div class="col-md-6">
                <div class="text1">Course Detail</div>
                <div class="text2">Graphic Design
                </div>
                <div class="text_para">
                    <ul>
                      <li>Comprehensive Graphic design program to cater extremely growing demand in the industry.</li>
                      <li>A wide range of graphic design job opportunities.</li>
                      <li>Introduction of Augmented and Virtual Reality.</li>
                      <li>Create an outstanding portfolio.</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img_box">
                    <div class="imgSubBox d-flex">
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/grapihic1.png" class="aniImg1" alt="Graphic Design">
                        </div>
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/grapihic2.png" class="aniImg2" alt="Graphic Design">
                        </div>
                    </div>
                    <div class="imgContent2">
                        <img src="../../../../assets/img/zica/course/grapihic3.png" class="aniImg3" alt="Graphic Design">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box2">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-12">
                <div class="left_box">
                    <div class="text2">Professional Program In Graphic Design <span class="color_text"> (12
                            Months)</span>
                    </div>
                    <div class="sub_text mt-4 text-white">Learn from<span
                            class="sub_text_color">Professionals</span></div>
                    <div class="text_para">
                        <p>
                          Graphic designers create visual concepts to communicate ideas that inspire, inform or captivate consumers. Students will develop the overall layout and production design for advertisements, brochures, magazines and corporate reports. The graphic design industry has been evolved with a more presentable design due to which we have updated the program with the latest tools and technologies so that student can create outstanding artworks.
                        </p>
                        <div class="sub_text mt-3 text-white">Become A Professional Artist</div>
                        <div class="text_para">
                            <p>This program is divided into two parts, the first part deals with fundamental of art, design, digital illustration and computer graphics. We focus on teaching how to make business collaterals such as logos, posters, marketing collaterals, package design etc. by using digital tools. The second module of the program deals with design prototyping, Design for Printing, Advertising, Packaging and Photorealistic 3D Design. At the end of the module, students will also get an opportunity to create an outstanding project.</p>
                        </div>
                        <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                        <div class="text_para">
                            <p>Students walk away with a rich portfolio comprising of text, image, illustrations, logo design etc. This portfolio will be a gateway to joining the graphic design industry.</p>
                        </div>
                        <div class="sub_text mt-4 text-white">Program Highlights</div>
                            <div class="text_para">
                              <ul>
                                <li>Increase design and visualization skills</li>
                                <li>Learn complete Graphic Design production pipeline</li>
                                <li>Continuous evaluation through examination, competitions, and activities</li>
                                <li>Placement support</li>
                              </ul>
                            </div>
                            <div class="accordion mt-2" id="accordionExample">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Module 01: Fundamental Of Art, Design, Illustration And Computer Graphics (6 Months)
                                    </button>
                                  </h2>
                                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-6">
                                 <ul>
                                  <li>Fundamentals of Drawing</li>
                                  <li>Principle of Design</li>
                                  <li>Colour Theory</li>
                                 
                                 </ul>
                                    </div>
                                    <div class="col-6">
                                      <li>Digital Illustrations - 1</li>
                                      <li>Computer Graphics</li>
                                      <li>Digital Illustrations - 2</li>
                                        </div>
                                  </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Module 02: Layout Design, Ui, Motion Graphics, 3d Photorealistic Design (6 Months)
                                    </button>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-6">
                                        <ul>
                                          <li>Layout Design</li> 
                                          <li>Design for Printing, Advertising and Packaging</li>
                                          <li>Video Editing</li> 
                                          <li>Audio Editing</li>
                                         
                                        </ul>
                                      </div>
                                      <div class="col-6">
                                     <ul>
                                      <li>Motion Graphics
                                        <li>User Interface - 1</li> 
                                        <li>User Interface - 2</li>
                                        <li>Photorealistic 3D Design</li> 
                                        <li>Project</li>
                                      </ul>
                                      </div>
                                    </div> 
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                              <div class="row mt-3">
                                <div class="col-6">
                                    <ul>
                                      <li>Graphic Designer</li>
                                      <li>Logo Designer</li>
                                      <li>Illustrator</li>
                                      <li>Layout Designer</li>
                                      <li>Desktop publisher</li>
                                     
                                      </ul>
                                </div>
                                <div class="col-6">
                                    <ul>
                                      <li>Typographer</li>
                                      <li>Motion Graphics Artist</li>
                                      <li>UI Designers</li>
                                      <li>Video Editors</li>
                                      <li>Marketing collateral designer</li>
                                    
                                      </ul>
                                </div>
                              </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="list_box">
                    <div class="list">
                    <div class="letf_text sub_text_color">Level</div>
                    <div class="right_text">Intermediate</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Duration</div>
                    <div class="right_text">12 Months</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Eligibility</div>
                    <div class="right_text">10th Pass</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Avg. Package</div>
                    <div class="right_text">12LPA</div>
                </div>
                </div>
            </div> -->
        </div>



        <div class="row flex-column-reverse flex-lg-row mt5">
          <div class="col-md-12">
              <div class="left_box">
                  <div class="text2">Program In Graphic Design <span class="color_text"> (6
                          Months)</span>
                  </div>
                  <div class="sub_text mt-4 text-white">Learn from<span
                          class="sub_text_color">Professionals</span></div>
                  <div class="text_para">
                      <p>
                        Graphic Design is a combination of text, images, designs & illustrations in a presentable layout for print. It has a wide array of requirements like Design for Art, Illustration, Advertising, Photography, Image Editing for Newspapers, Magazines, Displays, The Print Media etc., to name a few. 
                      </p>
                      <div class="sub_text mt-3 text-white">Become A Professional Artist</div>
                      <div class="text_para">
                          <p>This program caters to all different forms of print-related activities, wherein a student learns right from the basics till the advanced forms of Graphic Design. In this program, we cover the world’s best printing tools and technologies with a strong focus on Design.</p>
                      </div>
                      <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                      <div class="text_para">
                          <p>Students walk away with a rich portfolio comprising of text, image, illustrations, logo design etc. This portfolio will be a gateway to joining the graphic design industry.</p>
                      </div>
                      <div class="sub_text mt-4 text-white">Program Highlights</div>
                          <div class="text_para">
                            <ul>
                              <li>Increase design and visualization skills</li>
                              <li>Learn complete Graphic Design production pipeline</li>
                              <li>Continuous evaluation through examination, competitions, and activities</li>
                              <li>Placement support</li>
                            </ul>
                          </div>
                          <div class="accordion mt-2" id="accordionExample1">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne1">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                      Module 01:Program Module
                                  </button>
                                </h2>
                                <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                                  <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-6">
                                   <ul>
                                    <li>Design & Illustration concept</li>
                                    <li>Computer Graphics</li>
                                    <li>Digital Illustration – 1</li>
                                   </ul>
                                      </div>
                                      <div class="col-6">
                                        <ul>
                                          <li>Digital Illustration – 2</li>
                                          <li>Digital Layout Design</li>
                                          <li>Design for Printing, Advertising and Packaging</li>
                                          <li>Assignment & Portfolio Building</li>
                                        </ul>
                                          </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                            <div class="row mt-3">
                              <div class="col-6">
                                  <ul>
                                    <li>Graphic Designer</li>
                                    <li>Logo Designer</li>
                                    <li>Illustrator</li>
                                    <li>Layout Designer</li>
                                    <li>Desktop publisher</li>
                                   
                                    </ul>
                              </div>
                              <div class="col-6">
                                  <ul>
                                    <li>Typographer</li>
                                    <li>Motion Graphics Artist</li>
                                    <li>UI Designers</li>
                                    <li>Video Editors</li>
                                    <li>Marketing collateral designer</li>
                                  
                                    </ul>
                              </div>
                            </div>
                  </div>
              </div>
          </div>
          <!-- <div class="col-md-4">
              <div class="list_box">
                  <div class="list">
                  <div class="letf_text sub_text_color">Level</div>
                  <div class="right_text">Intermediate</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Duration</div>
                  <div class="right_text">6 Months</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Eligibility</div>
                  <div class="right_text">10th Pass</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Avg. Package</div>
                  <div class="right_text">12LPA</div>
              </div>
              </div>
          </div> -->
      </div>
    </div>

    <div class="box3">
        <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
        <div class="text_para text-center">
            <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                Visual FX Labs And Imaging Technologies.</p>
        </div>
        <app-blogs></app-blogs>
        </div>
        <div class="box4 mt-5">
            <div class="row">
                <div class="col-md-6">
                  <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                  </div>
                  <div class="text_para">
                   <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Graphic Design">
                  </div>
                </div>
                <div class="col-md-6">
                    <app-faqs></app-faqs>
                </div>
            </div>
        </div>
        
        <div class="box5">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                   
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <div class="text_para">
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p> -->
                  </div>
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
               
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Graphic Design">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <div class="text_para">
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p> -->
                    </div>
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Graphic Design">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <!-- <app-franchise-form></app-franchise-form> -->
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
</section>