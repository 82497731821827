import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css'],
})
export class BlogsComponent implements OnInit {
  project$: Observable<any> | undefined;
  subscriptionnav!: Subscription;
  blogdata:any;
  spinner:boolean=true
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,
    private _servie: CommonService,
  ) {
  }
  ngOnInit(): void { 
   setTimeout(()=>{
    let tbody = {
      slug: 'blog',
      Projectid: environment.projectid,
    };
    // this.apiService.getGetseo(tbody).subscribe((data: any) => {
    //  this.projectService.sendMessagebread(data?.data?.breadcrumb);
    //  });
     this.getCMSdataList()
  
    // this.subscriptionnav = this.projectService
    //   .onblogMessage()
    //   .subscribe((message) => {
    //     if (message) {
    //       this.blogdata = message.text;
    //     }
    //   });
   
   },3000)
  }

  getCMSdataList(){
    let obj = {"Type":"blog","Project_Id":3}
    this._servie.getCMSContent(obj).subscribe(
      res=>{
        this.blogdata = JSON.parse(res.data[0].contentData);
        console.log('blog',this.blogdata);
      }
    )
  }
}
