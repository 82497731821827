import { Component } from '@angular/core';

@Component({
  selector: 'app-blender',
  templateUrl: './blender.component.html',
  styleUrls: ['./blender.component.css','../../../../../src/app/components/commonCss/program.css']
})
export class BlenderComponent {

}
