<section>
    <div class="mainDiv">
        <div class="banner_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
        </div>
      <div class="subContent">
        <div class="row">
            <div class="col-md-3">
                <div class="pentemindeLogo">
                    <img src="../../../assets/img/pentemind_Img.png" class="pentemindImg center" alt="Pentemind" align="center">
                </div>
            </div>
            <div class="col-md-9">
                <div class="pentemindeContent">
                    <p>The Learning Minds, our pedagogy nurtures the ‘Learning Minds’ in every child, It shapes children’s overall development in a fun and inspiring manner. With an aim to nurture the future global leaders who possess the mindset of ‘We First’ instead of ‘ Me First’, who will be much in demand internationally.</p>
                </div>
            </div>
        </div>
      </div>
      <div class="learningMindImgList">
        <div class="learningTitle text-center">Meet our Learning minds</div>
        <div class="row mt-5">
            <div class="col-md-1"></div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey11.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Goes deep into a topic with focus and
                            single-minded attention.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey22.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Selects crucial information from the copious amounts available and arrays that information in ways that make sense to self and others.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey33.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Goes beyond existing knowledge to pose new questions and offer new solutions.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey44.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Responds sympathetically and constructively to differences among individuals.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey55.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Lives in harmony with the natural world.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
      </div>
    </div>
</section>