<section>
  <div class="main_body">
    <div class="bitCard_content">
      <app-sub-menu></app-sub-menu>
    </div>
    <div class="box1">
      <div class="row">
        <div class="col-md-6">
          <div class="left_box">
            <!-- <div class="text1">Resources / Our Events</div> -->
            <div class="text2">Let’s Explore Through<br>
              <span class="color_text">ZICA Events</span>
            </div>
            <!-- <div class="text_para">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua</p>
            </div> -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="right_box">
            <div class="">
              <div class="imgBox1">
                <img src="../../../assets/img/zica/studentPhoto.png" class="studentImg img-fluid" alt="Our Events">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="">
        <div class="text2 text-center">Events
          <span class="color_text">Gallery</span>
        </div>
      </div>
      <div class="body_content d-flex justify-content-center align-items-center">
        <div class="card p-3">
          <nav>
            <div class="nav nav-tabs mb-3 buttonList d-flex justify-content-start" id="nav-tab" role="tablist">
              <div class="" *ngFor="let a of careersAssignCategory">
                <button class="nav-link" id="nav-Manual-tab" data-bs-toggle="tab" data-bs-target="#nav-Manual"
                  type="button" role="tab" aria-controls="nav-Manual" aria-selected="true"
                  (click)="getCatagory(a.categoryId)">{{a.categoryname}}</button>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div class="imggallery">
        <div class="row">
          <div class="col-md-4 d-flex justify-content-center" *ngFor="let a of careersPlacementData">
            <div class="imgList" *ngFor="let b of a.logofiles">
              <img src="{{b.value}}" alt="Our Events" class="stdImg">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box3">
      <div class="row ">
        <div class="col-lg-6 col-6 text-white mobileRes">

          <div class="text2"><span class="color_text"> Get In Touch</span><br>
            With Us </div>
          <div class="text_para">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua</p>
          </div>
        </div>
        <div class="col-lg-6 col-6 text-white mobileRes">

          <div class="girl_img">
            <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Our Events">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 desktopRes">
          <div class="text2"><span class="color_text"> Get In Touch</span><br>
            With Us </div>
          <div class="text_para">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua</p>
          </div>
          <div class="girl_img">
            <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Our Events">
          </div>
        </div>
        <div class="col-md-6">
          <div class="franchise_form_content">
            <!-- <app-franchise-form></app-franchise-form> -->
            <app-amission-course></app-amission-course>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>