<section>
    <div class=content>
        <div class="wrapper-1">
          <div class="wrapper-2">
            <h1>Thank you !</h1>
            <p>Thanks for submitting your enquiry to our Website.  </p>
            <p>You should receive a Email/Call soon to discusse further  </p>
            <button class="btn back_btn" routerLink="">
            Go home
            </button>
          </div>
      </div>
      </div>
        </section>
        