import { Component } from '@angular/core';

@Component({
  selector: 'app-unrel',
  templateUrl: './unrel.component.html',
  styleUrls: ['./unrel.component.css','../../../../../src/app/components/commonCss/program.css']
})
export class UnrelComponent {

}
