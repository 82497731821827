<section>
    <div class="legancy_content">
        <div class="whiteTitle">{{headerTitle}}</div>
        <div class="subMenu_content">
            <app-sub-menu></app-sub-menu>
        </div>
    </div>
    <div class="legancy_content_res">
        <div class="whiteTitle">{{headerTitle}}</div>
        <div class="subMenu_content">
            <app-sub-menu></app-sub-menu>
        </div>
    </div>
    <div class="buttonList_content">
        <button class="btn grey_btn" [ngClass]="Legacy ? 'activeTab' : null"
            (click)="openTab(1,'Legacy')">Legacy</button>
        <button class="btn grey_btn" [ngClass]="kidzeeAdvantage ? 'activeTab' : null"
            (click)="openTab(2,'Our Approach')">Kidzee Advantage</button>
        <button class="btn grey_btn" [ngClass]="eduction? 'activeTab' : null"
            (click)="openTab(3,'Vision & Mission')">Vision & Mission</button>
        <button class="btn grey_btn" [ngClass]="awards ? 'activeTab' : null"
            (click)="openTab(4,'Awards & Recognition')">Awards & Recognition</button>
    </div>

    <div class="mainDiv mt-5" *ngIf="Legacy">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-6">
                <div class="">
                    <img srcset="../../../assets/img/lagecy_child.png" alt="Legacy" loading="lazy" class="leftDiv responsive"
                        align="center">
                </div>
            </div>
            <div class="col-md-6">
                <div class="rightDiv">
                    <div class="title mt-4">Legacy</div>
                    <div class="paragraph_content mt-2">
                        <p>We believe that there is ‘unique potential’ in every child; and Kidzee nurtures it. Touted to
                            be one of the best preschools in India, we nurture and shape minds with the help of our
                            best-in-class age appropriate progressive curriculum. We are changing the face of early
                            childhood education with consistent upgradation and innovation to meet the current needs
                            with a proactive future, focussed on grooming them to be ‘ever-ready for life’.

                            Our commitment towards quality education also values aspects like self-reliance, peer
                            interaction, and individual growth. Our solid foundation and polished business model makes
                            it profitable for all the stakeholders involved.</p>
                    </div>
                </div>
                <div class="legancyMonkey"></div>
            </div>
        </div>
    </div>

    <div class="mainDiv1 mt-5" *ngIf="kidzeeAdvantage">
        <div class="row">
            <div class="title_kidzee mt-4 d-lg-block d-sm-none d-md-none d-none">"Ranked amongst the top 100 franchise
                opportunities by Franchise India for 2022, we are the experts in Early Childhood Care and Education.
            </div>
            <div class="col-md-6">
                <div class="title_kidzee mt-4 d-lg-none">"Ranked amongst the top 100 franchise opportunities by
                    Franchise India for 2022, we are the experts in Early Childhood Care and Education.</div>
                <div class="subTitle_kidzee">We encourage, educate, and equip today's children to be tomorrow's leaders.
                </div>
                <div class="listTitle">The Golden Five-Point Kidzee Edges:</div>
                <div class="leftDiv1">

                    <div class="paragraph_content mt-2">
                        <ul class="kidzee_points">
                            <li><span class="dot">.</span>Widely spread preschool chain in India.</li>
                            <li><span class="dot">.</span> Focus on What is Right for the Child</li>
                            <li><span class="dot">.</span>Integrated Curriculum</li>
                            <li><span class="dot">.</span> I Care trained facilitators</li>
                            <li><span class="dot">.</span>An innovative pedagogy designed to help nurture collaborative
                                team players and breakthrough leaders</li>
                        </ul>
                    </div>
                </div>

                <div class="listTitle">The Golden Five-Point Kidzee Edges:</div>
                <div class="leftDiv1">

                    <div class="paragraph_content mt-2">
                        <ul class="kidzee_points">
                            <li><span class="dot">.</span>Kidzee is a leader and a trendsetter in Early Childhood Care
                                and Education (ECCE). Our business partners’ passion and drive are vital for our brand’s
                                success.</li>
                            <li><span class="dot">.</span>Our Business Partners don't just take the name, they also take
                                our pedagogy- Péntemind, guidelines to teach, prescribes books/plays to be followed and
                                so on to the ground.</li>
                            <li><span class="dot">.</span>Starting a preschool requires a lot of researching, planning,
                                designing, and organising. Kidzee makes this journey easier and rewarding for all our
                                Business Partners.</li>
                            <li><span class="dot">.</span>We also provide pre-opening and post-opening support apart
                                from the regular support that is given.</li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="col-md-6">
                <div class=" mt-5">
                    <img srcset="../../../assets/img/whiteTshirt.png" loading="lazy" alt="kidzeeAdvantage"
                        class="leftDiv_whiteImg responsive">
                </div>
                <div class=" mt-5">
                    <img srcset="../../../assets/img/aeroplane.png" loading="lazy" alt="kidzeeAdvantage"
                        class="paperaeroplane responsive">
                </div>
            </div>
        </div>
    </div>

    <div class="mainDiv2 mt-5" *ngIf="eduction">
        <div class="row">
            <div class="col-md-6">
                <div class="title_kidzee mt-4">Vision of our preschool education</div>
                <div class="leftDiv1">

                    <div class="paragraph_content mt-2">
                        <p>A child's human rights are non-negotiable for us.</p>
                        <p> We prioritise a child's human rights as part of our ‘What's Right for the Child’ (WRFC)
                            ethos. Our fundamental belief and ideology is shaped by the right to trust, the right to be
                            respected, and the right to live in an abuse-free world.</p>
                    </div>
                </div>

            </div>
            <div class="col-md-6">
                <div class=" mt-3">
                    <img srcset="../../../assets/img/aeroplane.png" loading="lazy" alt="Vision of our preschool education"
                        class="paperaeroplane responsive">
                </div>
            </div>

        </div>
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-6">
                <div class=" mt-3">
                    <img srcset="../../../assets/img/sunaeroplane.png" loading="lazy" alt="Mission of our preschool education"
                        class="sun_paperaeroplane responsive">
                </div>
            </div>
            <div class="col-md-6">
                <div class="rightDiv">
                    <div class="title_kidzee mt-4">Mission of our preschool education</div>
                    <div class="paragraph_content mt-2">
                        <p>We are committed to instilling skills, knowledge, and values in our children in order to give
                            them an inner voice for the twenty-first century. </p>
                        <p>Our learning environment allows each child to nurture the learning minds namely the Focused
                            Mind, Analytical Mind, Inventive Mind, Empathetic Mind, and Conscientious Mind.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mainDiv3 mt-5" *ngIf="awards">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-6">
                <div class=" mt-3">
                    <img srcset="../../../assets/img/award.png" loading="lazy" alt="Awards and Recognition" class="awardImg responsive"
                        align="center">
                </div>
            </div>
            <div class="col-md-6">
                <div class="rightDiv">
                    <div class="title_kidzee mt-4">India's most recognized Preschool Franchise</div>
                    <div class="yearsListTab">
                        <div class="active_years" (click)="clickYear(1)" id="year1">2022</div>
                        <div class="years" (click)="clickYear(2)" id="year2">2021</div>
                        <div class="years" (click)="clickYear(3)" id="year3">2020</div>
                        <div class="years" (click)="clickYear(4)" id="year4">2019</div>
                        <div class="years">and Before</div>
                    </div>
                    <div class="yearInfo_content">
                        <ul class="yearContentList" *ngIf="year22">
                            <li><span class="dot">.</span>India's Most Trusted Preschool: TRA's Brand Trust Report, 2022
                            </li>
                            <li><span class="dot">.</span>Ranked amongst the Top 100 Franchise Opportunities, 2022:
                                Franchise India</li>
                        </ul>
                        <ul class="yearContentList" *ngIf="year21">
                            <li><span class="dot">.</span>India's Most Trusted Preschool: TRA's Brand Trust Report, 2021
                            </li>
                            <li><span class="dot">.</span>Ranked amongst the Top 100 Franchise Opportunities, 2021:
                                Franchise India</li>
                        </ul>
                        <ul class="yearContentList" *ngIf="year20">
                            <li><span class="dot">.</span>India's Most Trusted Preschool: TRA's Brand Trust Report, 2020
                            </li>
                            <li><span class="dot">.</span>Ranked amongst the Top 100 Franchise Opportunities, 2020:
                                Franchise India</li>
                        </ul>
                        <ul class="yearContentList" *ngIf="year19">
                            <li><span class="dot">.</span>India's Most Trusted Preschool: TRA's Brand Trust Report, 2019
                            </li>
                            <li><span class="dot">.</span>Ranked amongst the Top 100 Franchise Opportunities, 2019:
                                Franchise India</li>
                        </ul>
                    </div>
                </div>
                <div class=" mt-5">
                    <img srcset="../../../assets/img/stdMonkey.png" loading="lazy" alt="" class="award_standingMonkey responsive"
                        align="center">
                </div>
            </div>
            <div class="galleryList">
                <app-news></app-news>
            </div>
        </div>
    </div>
</section>