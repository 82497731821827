<section>
    <div class="box1">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
         </div>
        <div class="row">
            <div class="col-md-6">
                <div class="text1">Course Detail</div>
                <div class="text2">Game Design
                </div>
                <div class="text_para">
                   <ul>
                      <li>Learn to create concept Game Art, Storyboard, Digital Paint, Matte paint, Crate props, environment and character.</li>
                      <li>To provide students with a foundational understanding of the principles and concepts specific to the field of game art.</li>
                      <li>To teach the fundamental principles of computer graphics, including pixel manipulation, rendering, and image processing.</li>
                      <li>To train students in the art of creating organic and character models with a focus on topology, realism, and aesthetics.</li>
                   </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img_box">
                    <div class="imgSubBox d-flex">
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/game1.png" class="aniImg1" alt="Game">
                        </div>
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/game2.png" class="aniImg2" alt="Game">
                        </div>
                    </div>
                    <div class="imgContent2">
                        <img src="../../../../assets/img/zica/course/game3.png" class="aniImg3" alt="Game">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box2">
      <!-- 1st -->
        

<div class="row flex-column-reverse flex-lg-row mt-5">
  <div class="col-md-12">
      <div class="left_box">
          <div class="text2">Professional Program In Advanced Game Art And Design<span class="color_text"> (12 Months)</span>
          </div>
          <div class="sub_text mt-4 text-white">Learn from<span
                  class="sub_text_color">The Professionals</span></div>
          <div class="text_para">
              <p>
                The learning objectives for advanced game art and design encompass a broad range of skills and knowledge that are crucial for individuals aiming to excel in the field of game development. Develop a deep understanding of fundamental art principles such as color theory, composition, perspective, and visual storytelling. Proficiently use industry-standard 3D modeling software to create complex 3D assets, characters, and environments. Acquire expertise in high-resolution sculpting using tools like ZBrush for creating intricate character and prop models.</p>
              <div class="sub_text mt-3 text-white">Become a professional artist</div>
              <div class="text_para">
                  <p>Develop skills in character concept art, design, and character sheet creation, ensuring the consistency and appeal of in-game characters. Create immersive game environments by mastering level design, terrain modeling, and the use of assets like buildings, props, and terrain textures. Become proficient in using game engines like Unreal Engine or Unity, including asset import, scene setup, and scripting for interactivity. </p>
              </div>
              <div class="sub_text mt-3 text-white">Be Industry Ready</div>
              <div class="text_para">
                  <p>Master the art of conveying narrative and storytelling elements through gameplay, level design, and environmental storytelling. Create a professional portfolio showcasing a diverse range of high-quality game art and design work that demonstrates your skills and creativity.</p>
              </div>
              <div class="sub_text mt-4 text-white">Program Highlights</div>
                  <div class="text_para">
                     <ul>
                        <li>Increase design and visualization skills</li>
                        <li>Learn complete Game Art production pipeline</li>
                        <li>Continuous evaluation through examination, competitions and activities</li>
                     </ul>
                  </div>
                  <div class="accordion mt-2" id="accordionExample2">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne2">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                            Module – 1
                          </button>
                        </h2>
                        <div id="collapseOne2" class="accordion-collapse collapse" aria-labelledby="headingOne2" data-bs-parent="#accordionExample2">
                          <div class="accordion-body">
                          <div class="row">
                            <div class="col-md-6">
                              <li>Introduction to Game Art</li>
                              <li>Computer Graphics</li>
                              <li>3D Modelling</li>
                              <li>Human Figure Study</li>
                              <li>Character design</li>
                            </div>
                            <div class="col-md-6">
                              <li>Organic / Character Modelling</li>
                              <li>Texturing</li>
                              <li>Lighting</li>
                              <li>Rigging</li>
                              <li>Character Animation</li>
                            </div>
                          </div> 
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo2">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="true" aria-controls="collapseTwo2">
                            Module – 2
                          </button>
                        </h2>
                        <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample2">
                          <div class="accordion-body">
                          <div class="row">
                            <div class="col-md-6">
                              <li>Advance Character Sculpting for Game</li>
                              <li>Advance Texturing</li>
                            </div>
                            <div class="col-md-6">
                              <li>FOLIAGE & PARTICLES / FX</li>
                              <li>Level Design</li>
                            </div>
                          </div> 
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                    <div class="row mt-3">
                      <div class="col-6">
                          <ul>
                            <li>3D Game artist</li>
                            <li>Character artist</li>
                            <li>Game designer and animator</li>
                            </ul>
                      </div>
                      <div class="col-6">
                          <ul>
                            <li>Game environment artist</li>
                            <li>3D Game designer</li>
                            <li>Game Level designer</li>
                            </ul>
                      </div>
                    </div>
          </div>
      </div>
  </div>
  <!-- <div class="col-md-4">
      <div class="list_box">
          <div class="list">
          <div class="letf_text sub_text_color">Level</div>
          <div class="right_text">Intermediate</div>
      </div>
      <div class="list">
          <div class="letf_text sub_text_color">Duration</div>
          <div class="right_text">12 Months</div>
      </div>
      <div class="list">
          <div class="letf_text sub_text_color">Eligibility</div>
          <div class="right_text">10th Pass</div>
      </div>
      <div class="list">
          <div class="letf_text sub_text_color">Avg. Package</div>
          <div class="right_text">12LPA</div>
      </div>
      </div>
  </div> -->
</div>
        <!-- 2nd -->

        <div class="row flex-column-reverse flex-lg-row mt-5">
          <div class="col-md-12">
              <div class="left_box">
                  <div class="text2">Program In Advanced Game Art And Design<span class="color_text"> (6 Months)</span>
                  </div>
                  <div class="sub_text mt-4 text-white">Learn from<span
                          class="sub_text_color">The Professionals</span></div>
                  <div class="text_para">
                      <p>
                        In this program students will learn how to create advanced visual elements for game, such as detailed human and animal sculpting, convert high to low poly characters, advance texturing, presenting the assets in Engine and creation of foliage, emitters and particles. Students will also learn how to design levels in a game. </p>
                      <div class="sub_text mt-3 text-white">Become a professional artist</div>
                      <div class="text_para">
                          <p>Student will learn how to research and then design advance game art which are needed to create any 3D game. The high-end equipment, practical exposure and mentors will inspire the student to learn in very professional atmosphere.</p>
                      </div>
                      <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                      <div class="text_para">
                          <p>Student will walk away with a rich portfolio comprising various game assets and characters. Their portfolio will be a gateway to join the Game art design studio / firm.</p>
                      </div>
                      <div class="sub_text mt-4 text-white">Program Highlights</div>
                          <div class="text_para">
                             <ul>
                              <li>Increase design and visualization skills</li>
                              <li>Learn complete Game Art production pipeline</li>
                              <li>Continuous evaluation through examination, competitions and activities</li>
                             </ul>
                          </div>
                          <div class="accordion mt-2" id="accordionExample1">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne1">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                    Module Outline
                                  </button>
                                </h2>
                                <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                                  <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-md-6">
                                       <li>Advance Character Sculpting for Game</li>
                                     <li>Advance Texturing</li>
                                    </div>
                                    <div class="col-md-6">
                                      <li>Foliage & Particles / FX</li>
                                      <li>Level Design</li>
                                    </div>
                                  </div> 
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                            <div class="row mt-3">
                              <div class="col-6">
                                  <ul>
                                    <li>3D Game artist</li>
                                    <li>Character artist</li>
                                    <li>Game designer and animator</li>
                                    </ul>
                              </div>
                              <div class="col-6">
                                  <ul>
                                    <li>Game environment artist</li>
                                    <li>3D Game designer</li>
                                    <li>Game Level designer</li>
                                    </ul>
                              </div>
                            </div>
                  </div>
              </div>
          </div>
          <!-- <div class="col-md-4">
              <div class="list_box">
                  <div class="list">
                  <div class="letf_text sub_text_color">Level</div>
                  <div class="right_text">Intermediate</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Duration</div>
                  <div class="right_text">6 Months</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Eligibility</div>
                  <div class="right_text">10th Pass</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Avg. Package</div>
                  <div class="right_text">12LPA</div>
              </div>
              </div>
          </div> -->
      </div>

<!-- 3rd -->
      <div class="row flex-column-reverse flex-lg-row">
        <div class="col-md-12">
            <div class="left_box">
                <div class="text2">Program In Game Art<span class="color_text"> (6 Months)</span>
                </div>
                <div class="sub_text mt-4 text-white">Learn from<span
                        class="sub_text_color">The Professionals</span></div>
                <div class="text_para">
                    <p>
                      Game art is basically all the visual elements you see while playing a game. Anyone who has artistic talent and wants to use them to create video games are preferred to become game artist. In this program student will learn to concept game art, storyboard, digital paint, matte paint the scenery, create objects / props, characters, environment etc., which will be used in the game.
                    </p>
                    <div class="sub_text mt-3 text-white">Become a professional artist</div>
                    <div class="text_para">
                        <p>Student will learn how to research and then design their own game art which are needed to create any 3D game. The high-end equipment, practical exposure and mentors will inspire the student to learn in very professional atmosphere.</p>
                    </div>
                    <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                    <div class="text_para">
                        <p>The field of game art is an ever-evolving and dynamic industry that demands a unique blend of creativity, technical proficiency, and adaptability. To become industry-ready in game art, individuals must embark on a journey of continuous learning and skill development.</p>
                    </div>
                    <div class="sub_text mt-4 text-white">Program Highlights</div>
                        <div class="text_para">
                           <ul>
                              <li>Increase design and visualization skills</li>
                              <li>Learn complete Game Art production pipeline</li>
                              <li>Continuous evaluation through examination, competitions and activities</li>
                           </ul>
                        </div>
                        <div class="accordion mt-2" id="accordionExample">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  Module Outline
                                </button>
                              </h2>
                              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                <div class="row">
                                  <div class="col-md-6">
                                    <li>Introduction to Game Art</li>
                                    <li>Computer Graphics</li> 
                                    <li>3D Modelling</li>
                                    <li>Human Figure Study</li>
                                    <li>Character design</li>
                                  </div>
                                  <div class="col-md-6">
                                    <li>Organic / Character Modelling</li>
                                    <li>Texturing</li>
                                    <li>Lighting</li>
                                    <li>Rigging</li>
                                    <li>Character Animation</li>
                                  </div>
                                </div> 
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                          <div class="row mt-3">
                            <div class="col-6">
                                <ul>
                                  <li>Game asset creator</li>
                                  <li>Game character modelor</li>
                                  <li>Texturing Artist</li>
                                  </ul>
                            </div>
                            <div class="col-6">
                                <ul>
                                  <li>Concept Art Designer</li>
                                  <li>Lighting Artist</li>
                                  <li>Character Designer</li>
                                  </ul>
                            </div>
                          </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-4">
            <div class="list_box">
                <div class="list">
                <div class="letf_text sub_text_color">Level</div>
                <div class="right_text">Intermediate</div>
            </div>
            <div class="list">
                <div class="letf_text sub_text_color">Duration</div>
                <div class="right_text">6 Months</div>
            </div>
            <div class="list">
                <div class="letf_text sub_text_color">Eligibility</div>
                <div class="right_text">10th Pass</div>
            </div>
            <div class="list">
                <div class="letf_text sub_text_color">Avg. Package</div>
                <div class="right_text">12LPA</div>
            </div>
            </div>
        </div> -->
    </div>
    </div>

    <div class="box3">
        <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
        <div class="text_para text-center">
            <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                Visual FX Labs And Imaging Technologies.</p>
        </div>
        <app-blogs></app-blogs>
        </div>
        <div class="box4 mt-5">
            <div class="row">
                <div class="col-md-6">
                  <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                  </div>
                  <div class="text_para">
                   <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Game">
                  </div>
                </div>
                <div class="col-md-6">
                    <app-faqs></app-faqs>
                </div>
            </div>
        </div>
        
        <div class="box5">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                   
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <div class="text_para">
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p> -->
                  </div>
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
               
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/course/Enquiry_pic.png" width="100%" alt="Game">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <!-- <div class="text_para">
                      <ul>
                        <li>Learn to create concept Game Art, Storyboard, Digital Paint, Matte paint, Crate props, environment and character.</li>
                        <li>To provide students with a foundational understanding of the principles and concepts specific to the field of game art.</li>
                        <li>To teach the fundamental principles of computer graphics, including pixel manipulation, rendering, and image processing.</li>
                        <li>To train students in the art of creating organic and character models with a focus on topology, realism, and aesthetics.</li>
                      </ul>
                    </div> -->
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Game">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <!-- <app-franchise-form></app-franchise-form> -->
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
</section>