<section>
    <div class="main_body">
        <div class="bitCard_content">
            <app-sub-menu></app-sub-menu>
             </div>
        <div class="box1">
            <div class="row">
                <div class="col-md-6">
                    <div class="text1">Student Placements</div>
                    <div class="text2">A Platform To <br>Recognise <br><span class="color_text">The Best Talent</span>
                    </div>
                    <!-- <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                    </div> -->
                </div>
                <div class="col-md-6">
                    <div class="img_box">
                        <img src="../../../assets/img/zica/whioutBg.png" width="100%" alt="Careers Placement">
                    </div>
                </div>
            </div>
        </div>

        <div class="box2 mt-5">
            <div class="">
                <div class="text2 text-center">ZICA’s<span class="color_text"> Placement Cell</span>
                </div>
                <div class="text_para text-center">
                    <p class="text-center">Discover Our Programs – Animation, VFX, Gaming, Interior Design, Fashion Design, Digital Marketing and Photography. Your Canvas Awaits, Your Masterpiece Begins Now!</p>
                </div>
            </div>
            <div class="imggallery mt-4">
                <div class="row">
                  <div class="col-md-4 d-flex justify-content-center mt-3" *ngFor="let a of careersPlacementList">
                    <div class="imgList" *ngFor="let b of a.logofiles">
                        <div class='photo'>
                            <div *ngIf="b.value">
                                <img src="{{b.value}}" class="inner_img">
                            </div>
                            <div *ngIf="!b.value">
                                <img src="../../../assets/img/zica/ZICA_Logo.png" class="inner_img">
                            </div>
                            <div class='info'>
                                <div class="heading">{{a.Title}}</div>
                                <div class="sub_text">{{a.short}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="imgList" *ngIf="!a.logofiles">
                        <img src="../../../assets/img/zica/ZICA_Logo.png" class="inner_img">
                        <div class='info'>
                            <div class="heading">{{a.Title}}</div>
                            <div class="sub_text">{{a.short}}</div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>

        <div class="box3">
            <app-testimonial></app-testimonial>
        </div>

        <div class="box4 mt-5">
            <div class="girlContent">
            </div>
            <div class="bagContent">
            </div>
            <div class="companies_main">
            <div class="companies">
                <div class="">
                    <div class="text2 text-center">Few Companies Where<span class="color_text"> ZICA Students</span>Have
                        <span class="color_text">Been Hired</span>
                    </div>
                    <div class="text_para text-center">
                        <p>We, at ZICA, provide our students with an appropriate environment to develop their skills which meet industry requirements. Our students have been placed across various levels in top most animation & production companies.</p>
                    </div>
                </div>
            </div>
            <div class="iconList">
                <div class="row">
                    <div class="col-lg-2 col-3 d-flex justify-content-center">
                        <div class="icon">
                            <img src="../../../assets/img/zica/icon1.png" class="icon" alt="Careers Placement">
                        </div>
                    </div>
                    <div class="col-lg-2 col-3 d-flex justify-content-center">
                        <div class="icon">
                            <img src="../../../assets/img/zica/icon2.png" class="icon" alt="Careers Placement">
                        </div>
                    </div>
                    <div class="col-lg-2 col-3 d-flex justify-content-center">
                        <div class="icon">
                            <img src="../../../assets/img/zica/icon3.png" class="icon" alt="Careers Placement">
                        </div>
                    </div>
                    <div class="col-lg-2 col-3 d-flex justify-content-center">
                        <div class="icon">
                            <img src="../../../assets/img/zica/icon4.png" class="icon" alt="Careers Placement">
                        </div>
                    </div>
                    <div class="col-lg-2 col-3 d-flex justify-content-center">
                        <div class="icon">
                            <img src="../../../assets/img/zica/icon5.png" class="icon" alt="Careers Placement">
                        </div>
                    </div>
                    <div class="col-lg-2 col-3 d-flex justify-content-center">
                        <div class="icon">
                            <img src="../../../assets/img/zica/icon6.png" class="icon" alt="Careers Placement">
                        </div>
                    </div>  
                </div>
            </div>
        </div>
        </div>

        <div class="box5">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                    <!-- test1 -->
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <!-- <div class="text_para">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p>
                  </div> -->
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
                    <!-- test2 -->
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Careers Placement">
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-6 desktopRes">
                  <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <!-- <div class="text_para">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p>
                  </div> -->
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Careers Placement">
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="franchise_form_content">
                      <!-- <app-franchise-form></app-franchise-form> -->
                      <app-amission-course></app-amission-course>
                  </div>
              </div>
          </div>
          </div>
    </div>
</section>