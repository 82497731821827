<div class="blogs_content">
    <div class="row">
        <div class="col-md-4" *ngFor="let item of blogdata ;let i=index">
            <div class="blog_img_content">
                <div class="book">
                    <img src={{item.logofiles[0].value}} height="230px" width="370px" class="inner-img"  alt={{item.logofiles[0].key}}>
                </div>
                <div class="text_info_content" routerLink="/blog/{{item.slug}}">
                    <button class="three_d_btn" routerLink="/blog/{{item.slug}}">3D Animation home</button>
                <div class="profile_name mt-2">{{item.Title}}</div>
                <div class="para">
                <p>{{item.Short}}</p>
                </div>
           <div class="bottom_text">Published on : {{item.StartDate}}</div>
        </div>
            </div>
        </div>
    </div>
    <div class="viwe_btn_content mt-5 d-flex justify-content-center">
        <button class="btn btn_learnMore">Learn more<i class="fa fa-arrow-right" aria-hidden="true"></i></button>
    </div>
</div>