import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-locate-us',
  templateUrl: './locate-us.component.html',
  styleUrls: ['./locate-us.component.css']
})
export class LocateUsComponent implements OnInit {
  headerTitle = "Locate Us"
  all_data_list: any;
  countryList: any = [];
  stateList: any = [];
  cityList: any = [];
  franchiseeList: any = [];
  getContryId: any;
  getStateId: any;
  getCityId: any;
  getLocationId: any;
  centerList: any;
  ucenterList: any;
  more: boolean = true;
  lgd: any;

  indiaCountry: any = [];
  searchForm: FormGroup;
  findex: number = 0;
  tindex: number = 50
  totalrecord: number = 0;
  virtual_url: any;
  getCenterListAllData: any;
  getZoon: any;
  zoonTitle: any;
  street: any = [];
  mapurl: any;
  pushData: any = [];
  filterCenterData: any;
  allCenterData: any;
  withoutFilter: any;

  constructor(private _service: CommonService, private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxSpinner: NgxSpinnerService, public sanitizer: DomSanitizer,
    private projectService: ProjectSeoService,
    private apiService: ApicallService) {
    this.searchForm = fb.group({
      country: [''],
      city: [''],
      state: [''],
      location: [''],
    })
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    }
  }

  ngOnInit(): void {
    let tbody = {
      slug: 'locateUs',
      Projectid: environment.projectid,
    };
    this.apiService.getGetseo(tbody).subscribe((data: any) => {
     this.projectService.sendMessagebread(data?.data?.breadcrumb);
     this.route.params.subscribe((params: any) => {
      params = params['zoon'];
      this.zoonTitle = params
      this.getZoon = params.charAt(0).toUpperCase();
      console.log('ZONE',this.getZoon)
    });
    this.tindex = 50;
    this.getAllDataList();
    });
 
  }

  getAllDataList() {
    this.ngxSpinner.show();
    let obj={};
    this._service.getCenterList(obj).subscribe(
      res => {
        this.ngxSpinner.hide();
        this.all_data_list = res
        this.india_country();
        this.setcountry();
      }
    )
  }

  india_country() {
    let lgd = this.all_data_list.filter(function (lg: any) {
      return lg.Country_Name === "India";
    }).map(function (lg: any) {
      return lg;
    })
    console.log('lgd',lgd);
    const key = 'India';
    this.indiaCountry = [...new Map(lgd.map((item: any) => [item[key], item])).values()]
    this.searchForm.get('country')?.patchValue(this.indiaCountry[0].Country_Id)
    let lgd1 = this.all_data_list.filter((lg: any) => {
      return lg.Country_Id === Number(this.indiaCountry[0].Country_Id);
    }).map(function (lg: any) {
      return lg;
    })
    const key1 = 'State_Name';
    this.stateList = [...new Map(lgd.map((item: any) => [item[key1], item])).values()]
   
    if(this.getZoon != 'A'){
      let selectState = this.stateList.filter((x:any)=>{
        return x.Zone_Code == this.getZoon
      })
      this.stateList = selectState
    }else{
      this.stateList = this.stateList

    }
    this.stateList = this.stateList.sort((a: any, b: any) =>
      a.State_Name !== b.State_Name ? (a.State_Name < b.State_Name ? -1 : 1) : 0
    );
    this.ucenterList = lgd1;
    this.setcentrelist();
  }

  setcountry(): any {
    const key = 'Country_Name';
    this.countryList = [...new Map(this.all_data_list.map((item: any) => [item[key], item])).values()]
  }

  selectCountry(contryId: any) {
    this.ngxSpinner.show();
    this.getContryId = contryId
    let lgd = this.all_data_list.filter(function (lg: any) {
      return lg.Country_Id === Number(contryId);
    }).map(function (lg: any) {
      return lg;
    })
    const key = 'State_Name';
    this.stateList = [...new Map(lgd.map((item: any) => [item[key], item])).values()]
    this.searchForm.get('state')?.setValue("");
    this.searchForm.get('city')?.setValue("");
    this.searchForm.get('location')?.setValue("");
    // this.filterData();

  }

  selectState(stateId: any) {
    this.getCityId= undefined;
    this.getStateId = stateId
    let lgd = this.all_data_list.filter(function (lg: any) {
      return lg.State_Id === Number(stateId);
    }).map(function (lg: any) {
      return lg;
    })

    const key = 'City_Name';
    this.cityList = [...new Map(lgd.map((item: any) => [item[key], item])).values()]
    this.cityList = this.cityList.sort((a: any, b: any) =>
      a.City_Name !== b.City_Name ? (a.City_Name < b.City_Name ? -1 : 1) : 0
    );
    // this.filterData();
  }

  selectCity(cityId: any) {
    this.getCityId = cityId
    // let lgd = this.all_data_list.filter(function (lg: any) {
    //   return lg.City_Id === Number(cityId);
    // }).map(function (lg: any) {
    //   return lg;
    // })
    // const key = 'Franchisee_Name';
    // this.franchiseeList = [...new Map(lgd.map((item: any) => [item[key], item])).values()]
    // this.filterData();
  }

  setcentrelist() {
    this.centerList = []
    let dlenth = this.ucenterList.length;
    this.totalrecord = dlenth;
    if (dlenth > 50) {
      this.tindex = 50;
    }
    else {
      this.tindex = dlenth;
      this.more = false;
    }
    if (this.getZoon == "A") {
      this.centerList = this.ucenterList.slice(this.findex, this.tindex);
      this.centerList.forEach((element: any) => {
        this.pushData.push({
          Address1: element.Address1,
          Address2: element.Address2,
          City_Id: element.City_Id,
          City_Name: element.City_Name,
          Country_Id: element.Country_Id,
          Country_Name: element.Country_Name,
          Email_Id: element.Email_Id,
          Franchisee_Code: element.Franchisee_Code,
          Franchisee_Name: element.Franchisee_Name,
          Mobile_No: element.Mobile_No,
          Phone_No1: element.Phone_No1,
          Phone_No2: element.Phone_No2,
          Pin_Code: element.Pin_Code,
          Place: element.Place,
          Row: element.Row,
          State_Id: element.State_Id,
          State_Name: element.State_Name,
          Zone_Code: element.Zone_Code,
          slug:element.slug,
          mapurl: this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${element.Franchisee_Name + ' ' + element.Address1}%20&t=&z=20&ie=UTF8&iwloc=&output=embed`)
        })
      });
      this.centerList = this.pushData
      this.allCenterData = this.centerList
      this.withoutFilter = this.allCenterData
    } else {
      this.centerList = this.ucenterList.slice(this.findex, this.tindex);
      this.centerList.forEach((element: any) => {
        this.pushData.push({
          Address1: element.Address1,
          Address2: element.Address2,
          City_Id: element.City_Id,
          City_Name: element.City_Name,
          Country_Id: element.Country_Id,
          Country_Name: element.Country_Name,
          Email_Id: element.Email_Id,
          Franchisee_Code: element.Franchisee_Code,
          Franchisee_Name: element.Franchisee_Name,
          Mobile_No: element.Mobile_No,
          Phone_No1: element.Phone_No1,
          Phone_No2: element.Phone_No2,
          Pin_Code: element.Pin_Code,
          Place: element.Place,
          Row: element.Row,
          State_Id: element.State_Id,
          State_Name: element.State_Name,
          Zone_Code: element.Zone_Code,
          slug:element.slug,
          mapurl: this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${element.Franchisee_Name + ' ' + element.Address1}%20&t=&z=20&ie=UTF8&iwloc=&output=embed`)
        })
      });
      this.centerList = this.pushData
      this.allCenterData = this.centerList
      this.centerList = this.pushData.filter((x: any) => {
        return x.Zone_Code == this.getZoon.toUpperCase()
      })
      this.withoutFilter = this.centerList
    }

  }
  setcentrelistclick() {
    let dlenth = this.ucenterList.length;
    let nindex = this.tindex + 50;

    if (dlenth > this.tindex) {


      if (nindex > dlenth) {
        this.tindex = dlenth;
        this.more = false;
      }
      else {
        this.tindex += 5;
      }
    }
    else {

      this.more = false;
    }
    if (this.getZoon == "A") {
      this.centerList = this.ucenterList.slice(this.findex, this.tindex);
      this.withoutFilter = this.centerList
    } else {
      this.centerList = this.centerList.filter((x: any) => {
        return x.Zone_Code == this.getZoon.toUpperCase()
      })
      this.withoutFilter = this.centerList
    }

  }
  selectLocation(locationId: any) {
    this.getLocationId = locationId
  }

  filterData() {
    if(this.getStateId != undefined && this.getCityId == ""){
      this.filterCenterData = this.allCenterData.filter((x:any)=>{
        return x.State_Id == this.getStateId
  })
  this.centerList = this.filterCenterData
    }else if(this.getStateId != undefined && this.getCityId != undefined){
      this.filterCenterData = this.allCenterData.filter((x:any)=>{
        return x.State_Id == this.getStateId && x.City_Id == this.getCityId
  })
  this.centerList = this.filterCenterData
    }else{
      this.centerList = this.withoutFilter
    }
  }



  openVirtualUrl(url: any) {
    this.virtual_url = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
 
}
