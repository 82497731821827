<section>
    <div class="box1">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
         </div>
        <div class="row">
            <div class="col-md-6">
                <div class="text1">Course Detail</div>
                <div class="text2">Visual Effects
                </div>
                <div class="text_para">
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua</p> -->
                        <ul><li>Comprehensive Animation and Visual Effects (VFX) program to cater to exceedingly growing demand in the industry
                          </li>
                        <li>A wide range of VFX job opportunities
                          </li>
                        <li>Produce Short film by using Live action, 3D and Visual Effects.</li></ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img_box">
                    <div class="imgSubBox d-flex">
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/visual1.png" class="aniImg1" alt="Three-D Animation">
                        </div>
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/visual2.png" class="aniImg2" alt="Three-D Animation">
                        </div>
                    </div>
                    <div class="imgContent2">
                        <img src="../../../../assets/img/zica/course/visual3.png" class="aniImg3" alt="Three-D Animation">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box2">
      <!-- 1st month -->
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-12">
                <div class="left_box">
                    <div class="text2">PDP In Filmmaking Visual Effects<span class="color_text"><br>(24
                            Months)</span>
                    </div>
                    <div class="sub_text mt-4 text-white">Learn from <span
                            class="sub_text_color"> Professionals</span></div>
                    <div class="text_para">
                        <p>
                          This program has been designed to deal with Filmmaking by using Live Action, 3D Animation & Visual Effects. The first module deals with Fundamentals of Art, Design & Composition. A student then understands the basics of Photography and Filmmaking. The second module covers CG Assets & Characters, Rigging & Animation that is required for a VFX film. The third module deals with Dynamics & Tracking. The fourth module combines CG with live action footage using timelines and a node-based compositing software. At the end of the program, the student develops a Visual Effects short film using Live Action & CG.
                        </p>
                        <div class="sub_text mt-3 text-white">Become a professional artist</div>
                        <div class="text_para">
                            <p>A well laid out assessment procedure ensures the students’ acceptability as distinguished professionals in the world of Visual Effects. ZICA’s placement cell provides placement assistance to all ZICA students across the country. ZICA training academy is equipped with high-end animation tools, 2D animation facilities, 3D software, Visual FX Labs and Imaging Technologies.
                            </p>
                        </div>
                        <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                        <div class="text_para">
                            <p>Students walk away with a rich portfolio comprising of live action, 3D and VFX project. This portfolio will be a gateway to joining animation and visual effect industry.</p>
                        </div>
                        <div class="sub_text mt-4 text-white">Program Highlights:</div>
            <div class="text_para">
              <ul>
                <li>Increase design and visualization skills</li>
                <li>Learn complete Production and Post-Production Pipeline</li>
                <li>Continuous evaluation through examination, competitions and activities</li>
                <li>Introduction of Augmented and Virtual Reality</li>
                <li>Placement support</li>
                </ul>
            </div>
                       
                            <div class="accordion mt-2" id="accordionExample">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Module 01: Fundamentals of Art, Design & Filmmaking (6 Months)
                                    </button>
                                  </h2>
                                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                      <div class="row">
                                        <div class="col-6">
                                          <ul>
                                            <li>Introduction</li>
                                            <li>Fundamentals of Art</li>
                                            <li>Computer Graphics</li>
                                            <li>Principles of Design</li>
                                            <li>Perspective</li>
                                            <li>Color Theory</li>
                                            <li>Storyboard</li>
                                          </ul>
                                        </div>
                                        <div class="col-6">
                                          <p><b>Filmmaking</b></p>
                                          <ul> 
                                            <li>Foundation</li>
                                            <li>Screen Writing</li>
                                            <li>Direction - 1</li>
                                            <li>Acting</li>
                                            <li>Cinematography</li>                                            
                                          	<li>Film Editing</li>
                                            <li>Sound Design</li>
                                            <li>Direction - 2</li>
                                            <li>Other Formats</li>
</ul>
                                        </div>
                                      </div>
                                    
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Module 02: 3D Animation & Dynamics (6 Months)
                                    </button>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                      <div class="row">
                                        <div class="col-12">
                                          <ul>
                                          <li>Modelling by using</li> 
                                            <li>Texturing</li> 
                                            <li>Basic Lighting</li>
                                            <li>Rigging</li>
                                            <li>3D Character Animation</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Module 03: Advance Dynamics & Visual Effects (6 Months)
                                    </button>
                                  </h2>
                                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                      <div class="row">
                                        <div class="col-6">
                                          <ul>
                                          <li>Dynamics</li>
                                            <li>Tech Animation</li>
                                            <li>Liquid Simulation</li>
                                          </ul>
                                        </div>
                                        <div class="col-6">
                                          <ul>
                                            <li>Digital Compositing - 1</li>
                                            <li>Element 3D</li>
                                            <li>Digital 2D Tracking</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingThree0">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree0" aria-expanded="false" aria-controls="collapseThree0">
                                        Module 04: Advance Compositing, Tracking And AR Vr (6 Months)
                                    </button>
                                  </h2>
                                  <div id="collapseThree0" class="accordion-collapse collapse" aria-labelledby="headingThree0" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                      <div class="row">
                                        <div class="col-6">
                                          <ul>
                                            <li>Digital Compositing - 2</li>
                                            <li>Digital Compositing - 3</li>
                                            <li>Digital Tracking</li>
                                          </ul>
                                        </div>
                                        <div class="col-6">
                                          <ul>
                                            <li>Advanced Digital Tracking </li>
                                            <li>Digital Compositing - 4</li>
                                            <li>Introduction to AR & VR</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                              <div class="row mt-3">
                                <div class="col-6">
                                    <ul>
                                      <li>Modeling Artist</li>
                                      <li>Texturing Artist</li>
                                      <li>Lighting Artist</li>
                                      <li>Rigger</li>
                                      <li>Animator</li>
                                      <li>FX / Simulation Artist</li>
                                      <li>Render Wrangler</li>
                                      </ul>
                                </div>
                                <div class="col-6">
                                    <ul>
                                      <li>Rotoscope Artist</li>
                                      <li>Prep / Paint Artist</li>
                                      <li>Clean-up Artist</li>
                                      <li>Matchmover / 3D Tracking Artist</li>
                                      <li>Motion Graphics Artist</li>
                                      <li>Rotomation Artist</li>
                                      <li>Compositor</li>
                                      <li>VFX Artist</li>
                                      <li>Stereoscopy Artist</li>
                                      </ul>
                                </div>
                              </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="list_box">
                    <div class="list">
                    <div class="letf_text sub_text_color">Level</div>
                    <div class="right_text">Intermediate</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Duration</div>
                    <div class="right_text">24 Months</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Eligibility</div>
                    <div class="right_text">10th Pass</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Avg. Package</div>
                    <div class="right_text">12LPA</div>
                </div>
                </div>
            </div> -->
        </div>

      <!-- 2st month -->
        <div class="row flex-column-reverse flex-lg-row mt-5">
          <div class="col-md-12">
              <div class="left_box">
                  <div class="text2">Professional Program In Visual Effects<span class="color_text"><br>(12 Months)</span>
                  </div>
                  <div class="sub_text mt-4 text-white">Learn from <span
                          class="sub_text_color"> Professionals</span></div>
                  <div class="text_para">
                      <p>
                        This program provides a gateway to the Visual Effects world. The program starts with Fundamentals of Film Making & Computer Graphics. Then the program introduces the students to 3D Computer Graphics for a short period, thereby strengthening their skills in Dynamics, Tracking, Rotoscopy and Compositing, which forms the foundation for Visual Effects. A student will compile different kinds of VFX shots to build an outstanding portfolio during the last month of the program.
                      </p>
                      <div class="sub_text mt-3 text-white">Become a professional artist</div>
                      <div class="text_para">
                          <p>A well laid out assessment procedure ensures the students’ acceptability as distinguished professionals in the world of Visual Effects. ZICA’s placement cell provides placement assistance to all ZICA students across the country. ZICA training academy is equipped with high-end animation tools, 2D animation facilities, 3D software, Visual FX Labs and Imaging Technologies.</p>
                      </div>
                      <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                      <div class="text_para">
                          <p>Students walk away with a rich portfolio comprising of live action and VFX project. This portfolio will be a gateway to joining visual effect industry.</p>
                      </div>
                      <div class="sub_text mt-4 text-white">Program Highlights:</div>
          <div class="text_para">
            <ul>
            <li>Increase design and visualization skills</li>
              <li>Learn complete Production and Post-Production Pipeline</li>
              <li>Continuous evaluation through examination, competitions, and activities</li>
              <li>Introduction of Augmented and Virtual reality</li>
              <li>Placement support</li>
              </ul>
          </div>
                     
                          <div class="accordion mt-2" id="accordionExample04">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingTwo04">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo04" aria-expanded="false" aria-controls="collapseTwo04">
                                  Module 01: Fundamentals Of Filmmaking & Computer Graphics (2 Months)
                                </button>
                              </h2>
                              <div id="collapseTwo04" class="accordion-collapse collapse" aria-labelledby="headingTwo04" data-bs-parent="#accordionExample04">
                                <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-6">
                                      <ul>
                                        <li>History of VFX</li> 
                                        <li>Principles of Design</li>
                                        <li>Photography</li>
                                        <li>Computer Graphics</li>
                                        <li>Video Editing</li>
                                        <li>Audio Editing</li>
                                      </ul>
                                    </div>
                                    <div class="col-6">
                                      <p><b>Filmmaking</b></p>
                                      <ul> 
                                        <li>Camera</li>
                                        <li>Cinematography</li>
                                        <li>Composition</li>
                                        <li>Lighting</li>
                                        <li>Screenwriting</li>
                                        <li>Shooting</li>
                                        </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingTwo05">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo05" aria-expanded="false" aria-controls="collapseTwo05">
                                  Module 01: Introduction To 3D Animation (2.5 Months)
                                </button>
                              </h2>
                              <div id="collapseTwo05" class="accordion-collapse collapse" aria-labelledby="headingTwo05" data-bs-parent="#accordionExample04">
                                <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-12">
                                      <ul>
                                      <li>Basics of 3D Modeling</li>
                                        <li>Basics of Texturing</li>
                                        <li>Basics of Lighting</li>
                                        <li>Basics of Rigging</li>
                                        <li>Basics of Animation</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingTwo06">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo06" aria-expanded="false" aria-controls="collapseTwo06">
                                  Module 03: Dynamics And Tech Animation (1.5 Months)
                                </button>
                              </h2>
                              <div id="collapseTwo06" class="accordion-collapse collapse" aria-labelledby="headingTwo06" data-bs-parent="#accordionExample04">
                                <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-12">
                                      <ul>
                                        <li>Particles and Dynamics</li>
                                        <li>Technical Animation</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingTwo07">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo07" aria-expanded="false" aria-controls="collapseTwo07">
                                  Module 04: Visual Effects And Tracking (4.5 Months)
                                </button>
                              </h2>
                              <div id="collapseTwo07" class="accordion-collapse collapse" aria-labelledby="headingTwo07" data-bs-parent="#accordionExample04">
                                <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-6">
                                      <ul>
                                        <li>Digital Compositing – 1</li>
                                        <li>Digital Compositing – 2</li>
                                       
                                      </ul>
                                    </div>
                                    <div class="col-6">
                                      <ul>
                                        <li>Digital Compositing – 3</li>
                                        <li>Digital Tracking</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>

                            <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                            <div class="row mt-3">
                              <div class="col-6">
                                  <ul>
                                  <li>Rotoscope Artist</li>
                                    <li>Prep / Paint Artist</li>
                                    <li>Clean-up Artist</li>
                                    <li>Matchmover / 3D Tracking Artist</li>
                                    </ul>
                              </div>
                              <div class="col-6">
                                  <ul>
                                    <li>Motion Graphics Artist</li>
                                    <li>Rotomation Artist</li>
                                    <li>Compositor</li>
                                    <li>VFX Artist</li>
                                    </ul>
                              </div>
                            </div>
                  </div>
              </div>
          </div>
          <!-- <div class="col-md-4">
              <div class="list_box">
                  <div class="list">
                  <div class="letf_text sub_text_color">Level</div>
                  <div class="right_text">Intermediate</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Duration</div>
                  <div class="right_text">12 Months</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Eligibility</div>
                  <div class="right_text">10th Pass</div>
              </div>
              <div class="list">
                  <div class="letf_text sub_text_color">Avg. Package</div>
                  <div class="right_text">12LPA</div>
              </div>
              </div>
          </div> -->
      </div>

      <!-- 2st month -->
      <div class="row flex-column-reverse flex-lg-row mt-5">
        <div class="col-md-12">
            <div class="left_box">
                <div class="text2">Program Compositing<span class="color_text"> (6 Months)</span>
                </div>
                <div class="sub_text mt-4 text-white">Learn from<span
                        class="sub_text_color">the Masters</span></div>
                <div class="text_para">
                    <p>
                      This program is designed for the student who wants to make a career in the VFX industry. The program starts with the basic understanding of how Computer Graphic works. We then expose students to layer based and node based Compositing software. Students learn all important areas of compositing such as Rotoscopy, Color Correction, Paint, Wire Removal and Tracking etc.
                    </p>
                    <div class="sub_text mt-3 text-white">Become a professional artist</div>
                    <div class="text_para">
                        <p>This program starts with the introduction of Computer Graphics. Then the program covers layer based and node-based compositing tools which cover rotoscoping, colour correction, keying, Tracking etc.  A student will compile VFX assignments to build the portfolio.</p>
                    </div>
                    <div class="sub_text mt-3 text-white">Infographic</div>
                    <div class="text_para">
                        <p>‘Learning by Doing’ approach to Education pipeline</p>
                    </div>
                    <div class="sub_text mt-4 text-white">Program Highlights:</div>
        <div class="text_para">
          <ul>
           <li>Learn an advanced technical skill</li>
            <li>Learn complete Post-Production Pipeline</li>
            <li>Placement support</li>
            </ul>
        </div>
                   
                        <div class="accordion mt-2" id="accordionExample3">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="headingOne3">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne3" aria-expanded="true" aria-controls="headingOne3">
                                    Module 01: Program Module
                                </button>
                              </h2>
                              <div id="collapseOne3" class="accordion-collapse collapse" aria-labelledby="headingOne3" data-bs-parent="#accordionExample3">
                                <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-6">
                                      <ul>
                                        <li>Computer Graphics</li>
                                        <li>Digital Compositing – 1</li>
                                        <li>3D object based particle (Plug-in)</li>
                                       
                                      </ul>
                                    </div>
                                    <div class="col-6">
                                     
                                      <ul> 
                                        <li>Digital Compositing – 2</li>
                                        <li>Digital Compositing – 3</li>
</ul>
                                    </div>
                                  </div>
                                
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                          <div class="row mt-3">
                            <div class="col-6">
                                <ul>
                                <li>Rotoscope Artist</li>
                                  <li>Prep / Paint Artist</li>
                                  <li>Clean-up Artist</li>
                                  <li>Matchmover / 3D Tracking Artist</li>
                                  </ul>
                            </div>
                            <div class="col-6">
                                <ul>
                                  <li>Motion Graphics Artist</li>
                                  <li>Rotomation Artist</li>
                                  <li>Compositor</li>
                                  <li>VFX Artist</li>
                                  </ul>
                            </div>
                          </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-4">
            <div class="list_box">
                <div class="list">
                <div class="letf_text sub_text_color">Level</div>
                <div class="right_text">Intermediate</div>
            </div>
            <div class="list">
                <div class="letf_text sub_text_color">Duration</div>
                <div class="right_text">6 Months</div>
            </div>
            <div class="list">
                <div class="letf_text sub_text_color">Eligibility</div>
                <div class="right_text">10th Pass</div>
            </div>
            <div class="list">
                <div class="letf_text sub_text_color">Avg. Package</div>
                <div class="right_text">12LPA</div>
            </div>
            </div>
        </div> -->
    </div>
    </div>

    <div class="box3">
        <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
        <div class="text_para text-center">
            <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                Visual FX Labs And Imaging Technologies.</p>
        </div>
        <app-blogs></app-blogs>
        </div>
        <div class="box4 mt-5">
            <div class="row">
                <div class="col-md-6">
                  <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                  </div>
                  <div class="text_para">
                   <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Three-D Animation">
                  </div>
                </div>
                <div class="col-md-6">
                    <app-faqs></app-faqs>
                </div>
            </div>
        </div>
        
        <div class="box5">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                   
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <div class="text_para">
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p> -->
                  </div>
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
               
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Three-D Animation">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <!-- <div class="text_para">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p>
                    </div> -->
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Three-D Animation">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <!-- <app-franchise-form></app-franchise-form> -->
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
</section>