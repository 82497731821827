import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-careers-work-with-us',
  templateUrl: './careers-work-with-us.component.html',
  styleUrls: ['./careers-work-with-us.component.css']
})
export class CareersWorkWithUsComponent {
  careersPlacementList: any;

  constructor(private _service: CommonService, private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxSpinner: NgxSpinnerService, public sanitizer: DomSanitizer,
    private projectService: ProjectSeoService,
    private apiService: ApicallService) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
      }
    }

ngOnInit(): void {
  let tbody = {
    slug: 'careersWorkWithUs',
    Projectid: environment.projectid,
  };
  this.apiService.getGetseo(tbody).subscribe((data: any) => {
   this.projectService.sendMessagebread(data?.data?.breadcrumb);
   this.getCMSDataList();
  });
 
}

getCMSDataList(){
  let obj = {
    "Type":"Job Recruitment",
    "Project_Id":3
  }
this._service.getCMSContent(obj).subscribe(
  res=>{
    this.careersPlacementList = JSON.parse(res.data[0].contentData)
    console.log((this.careersPlacementList));
  }
)
}
}
