<section>
  <div class="main_body">
    <div class="bitCard_content">
      <app-sub-menu></app-sub-menu>
  </div>
    <div class="box1">
      <div class="innerBlogCOntent">
        <div class="text2 text-center">The Power Of VR<br> Technology</div>
        <div class="blogProfile mt-4 d-flex justify-content-center">
          <div class="date_content d-flex">
            <div class="icon">
              <img src="../../../../assets/img/zica/icon/dateicons.png" width="25px" height="25px" alt="Blogs">
            </div>
            <div class="content_text text-white">Nov 21, 2023</div>
          </div>
          <div class="profile d-flex">
            <div class="icon">
              <img src="../../../../assets/img/zica/icon/blogProfile.png" width="25px" height="25px" alt="Blogs">
            </div>
            <div class="content_text text-white">By Vini Shah</div>
          </div>
        </div>
            <div class="blog_img_content mt-4">
              <img src={{blogdetails.files[0].url}} width="100%" alt={{blogdetails.files[0].filename}}>
            </div>
            <div class="blog_text_content text-white mt-5">
            <p [innerHTML]="blogdetails.long1"></p>
          </div>
      </div>
    </div>
    <div class="box2 mt-5">
      <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
      <div class="text_para text-center">
          <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
              Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
              Visual FX Labs And Imaging Technologies.</p>
      </div>
      <app-blogs></app-blogs>
    </div>

    <div class="box3 mt-5">
      <div class="row ">
        <div class="col-lg-6 col-6 text-white mobileRes">
           
         <div class="text2"><span class="color_text"> Get In Touch</span><br>
              With Us </div>
          <div class="text_para">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua</p>
          </div>
        </div>
        <div class="col-lg-6 col-6 text-white mobileRes">
       
          <div class="girl_img">
              <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Blogs">
          </div>
        </div>
    </div>
      <div class="row">
        <div class="col-md-6 desktopRes">
            <div class="text2"><span class="color_text"> Get In Touch</span><br>
                With Us </div>
            <div class="text_para">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua</p>
            </div>
            <div class="girl_img">
                <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Blogs">
            </div>
        </div>
        <div class="col-md-6">
            <div class="franchise_form_content">
                <app-franchise-form></app-franchise-form>
            </div>
        </div>
    </div>
    </div>
  </div>
</section>