import { Component } from '@angular/core';

@Component({
  selector: 'app-digital-audio-video-editing',
  templateUrl: './digital-audio-video-editing.component.html',
  styleUrls: ['./digital-audio-video-editing.component.css', '../../../../../src/app/components/commonCss/program.css']
})
export class DigitalAudioVideoEditingComponent {

}
