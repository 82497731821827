<section>
    <div class=content>
        <div class="wrapper-1">
          <div class="wrapper-2">
            <h1>coming soon...</h1>
            <button class="btn back_btn" routerLink="">
            Go home
            </button>
          </div>
      </div>
      </div>
        </section>