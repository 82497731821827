import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
  @Input() FAQsdata:any=[];
  faqsDataList: any;
  filterData: any=[];
  getFaqCount: any;
  getFaqsData: any;
  findex:number=0;
  fqindex:number=5;
  showBtn:boolean = true;
  closeBtn:boolean = false;
  faqsDataListFinel: any;
  constructor(private _service: CommonService, private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ngxSpinner: NgxSpinnerService, public sanitizer: DomSanitizer,
    private projectService: ProjectSeoService,
    private apiService: ApicallService) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
      }
    }

ngOnInit(): void { 
  if(this.FAQsdata!=""){
    this.faqsDataList=this.FAQsdata;
  }
  else{
    this.getFAQsList();
  }
}

getFAQsList(){
  let obj = {
    "Type":"faqs",
    "Project_Id":3
  }

this._service.getCMSContent(obj).subscribe(
  res=>{
 this.faqsDataList = JSON.parse(res.data[0].contentData)
 this.getCount();
  }
)
}

getCount(){

  this.getFaqCount = this.faqsDataList.length
  this.getFaqsData=this.faqsDataList.slice(this.findex,this.fqindex);
  this.faqsDataListFinel = this.getFaqsData
}

showMore(){
this.fqindex +=5
if(this.fqindex == 10){
this.closeBtn = true
}
if(this.fqindex === this.faqsDataList.length){
  this.closeBtn = true;
  this.showBtn = false;
}
this.getFaqsData=this.faqsDataList.slice(this.findex,this.fqindex);
this.faqsDataListFinel = this.getFaqsData
}

closeMore(){
  this.fqindex -=5
  this.showBtn = true;
  if(this.fqindex < 10){
    this.closeBtn = false;
    }
  this.getFaqsData=this.faqsDataList.slice(this.findex,this.fqindex);
  this.faqsDataListFinel = this.getFaqsData
}
}
