<section>
    <div class="news">
        <div class="title text-center">In News</div>
       <div class="row mt-4">
        <div class="col-md-4">
            <div class="news_img_content">
                <div class="news_imgList">
                    <img src="../../../assets/img/news.png" class="newsImg" alt="">
                </div>
                <div class="dateInfo">
                    <div class="month time">January 9, 2023</div>
                    <div class="time">09:30 a.m.</div>
                </div>
                <div class="news_title">NDTV</div>
                <div class="news_content">
                    <div class="subPara">
                        <div class="box text-center">
                            <input type="checkbox" id="expanded1">
                            <p>Parents of toddlers are cautious about choosing the first school for the apple of their eye. Young parents are anxious to make the right choice for their precious little one and this often begs the question ‘How do I find the best preschool where my child is safe and learns happily?’</p>
                   
                            <label for="expanded1" class="readMoreBtn" role="button">Read More</label>
                        </div>
                    </div>
                </div>
             </div>
        </div>
        <div class="col-md-4">
            <div class="news_img_content">
                <div class="news_imgList">
                    <img src="../../../assets/img/news.png" class="newsImg" alt="">
                </div>
                <div class="dateInfo">
                    <div class="month time">January 9, 2023</div>
                    <div class="time">09:30 a.m.</div>
                </div>
                <div class="news_title">NDTV</div>
                <div class="news_content">
                    <div class="subPara">
                        <div class="box text-center">
                            <input type="checkbox" id="expanded2">
                            <p>Parents of toddlers are cautious about choosing the first school for the apple of their eye. Young parents are anxious to make the right choice for their precious little one and this often begs the question ‘How do I find the best preschool where my child is safe and learns happily?’</p>
                   
                            <label for="expanded2" class="readMoreBtn" role="button">Read More</label>
                        </div>
                    </div>
                </div>
             </div>
        </div>
        <div class="col-md-4">
            <div class="news_img_content">
                <div class="news_imgList">
                    <img src="../../../assets/img/news.png" class="newsImg" alt="">
                </div>
                <div class="dateInfo">
                    <div class="month time">January 9, 2023</div>
                    <div class="time">09:30 a.m.</div>
                </div>
                <div class="news_title">NDTV</div>
                <div class="news_content">
                    <div class="subPara">
                        <div class="box text-center">
                            <input type="checkbox" id="expanded3">
                            <p>Parents of toddlers are cautious about choosing the first school for the apple of their eye. Young parents are anxious to make the right choice for their precious little one and this often begs the question ‘How do I find the best preschool where my child is safe and learns happily?’</p>
                   
                            <label for="expanded3" class="readMoreBtn" role="button">Read More</label>
                        </div>
                    </div>
                </div>
             </div>
        </div>
       </div>
    </div>
</section>