<section>
    <div class="mainDiv">
        <div class="banner_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
        </div>
        <div class="sub_content mt-5">
                <div class="row flex-column-reverse flex-lg-row">
                    <div class="col-md-5">
                        <div class="dayCardImg">
                            <div class="">
                                <img srcset="../../../assets/img/dayCare_child.png" loading="lazy" alt="" class="schoolGirlImg responsive"
                                    align="center">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">

                        <div class="dayCareText">
                            <div class="right_content1">
                                <div class="title">
                                    A home away from home for your child!
                                </div>
                                <div class="paragraph_content mt-2">
                                    <p>We understand that the early years in the child’s life are important for the overall development. The primary role of parents in these years is to provide their children with an environment that is loving, caring, fun-filled and one which provides happy experiences. As these aspects play a very important role in fostering the developmental areas, like cognitive development, physical development, socio-emotional development, and so on. Hence, our objective is to create a home away from home for them to feel safe, secure, and happy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </div>

        <div class="dayCareImg_content mt-5">
            <div class="subTitle">Take a walkthrough of a day at Kidzee DayCare Facility</div>
            <div class="dayCareImgContent">
                <img srcset="../../../assets/img/dayCare.png" loading="lazy" class="dayCareImg" alt="">
            </div>
        </div>
        <div>
     <app-faqs></app-faqs>

        </div>
        </div>
        </section>
