<div class="whiteHeader_dark desktopNav" id="whiteHeader_dark">
  <div class="side_con">
    <a href="https://wa.me/9152010855" target="_blank"><div class="watsapp_icon"></div></a>
    <div class="download_icon"></div>
</div>

  <nav class="nav navbar navbar-expand-lg">
    <div class="container-fluid">
      <div class="navbar-brand d-flex">
        <div class="">
          <a href="">
            <img src="../../../../assets/img/zica/ZICA_Logo_finle.png" class="zica_logo" alt="" routerLink="">
          </a>
        </div>
        <div class="">
          <img src="../../../../assets/img/zica/year_logo.png" class="header_logo2 zica_logo" routerLink="" alt="">
        </div>
      </div>
      <div class="menu_Img" data-bs-toggle="collapse" data-bs-target="#navbarNav" id="menuClose">
        <div class="menu_icon"></div>
      </div>
      <div class="collapse navbar-collapse " id="navbarNav">
        <ul class="navbar-nav ul_content">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" routerLink="/programs" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" [ngClass]="getVal == 'Our Programs'? 'activeMenu':'deActive'"
              (click)="clickMenu('Our Programs')">
              Our Programs
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/BVocAnimationVisualEffects">B.Voc Degree</a>
              <a class="dropdown-item" routerLink="/animation">3D Animation</a>
              <a class="dropdown-item" routerLink="/2D_Animation">2D Animation</a>
              <a class="dropdown-item" routerLink="/visualEffect">Visual Effects(VFX)</a>
              <a class="dropdown-item" routerLink="/game">Game Art Design</a>
              <a class="dropdown-item" routerLink="/graphicDesign">Graphic Design</a>
              <a class="dropdown-item" routerLink="/UiUx">UI & UX</a>
              <a class="dropdown-item" routerLink="/webDesign">Web Design</a>
              <a class="dropdown-item" routerLink="/motionGraphics">Motion Graphics</a>
              <a class="dropdown-item" routerLink="/interiorDesign">Interior Design</a>
              <a class="dropdown-item" routerLink="/fashionDesign">Fashion Design</a>
              <a class="dropdown-item" routerLink="/digitalMarketing">Digital Marketing</a>
              <a class="dropdown-item" routerLink="/digitalPhotography">Digital Photography</a>
              <a class="dropdown-item" routerLink="/blender">Blender</a>
              <a class="dropdown-item" routerLink="/unrel">Unrel</a> 
              <a class="dropdown-item" routerLink="/digitalAudioVideoEditing">Digital Audio Video Editing</a> 
              
             
            
             
              
              
              
              

            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" routerLink="/studentsZone"
              [ngClass]="getVal == 'Student Zone'? 'activeMenu':'deActive'" (click)="clickMenu('Student Zone')">Student
              Zone</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" [ngClass]="getVal == 'Our Centres'? 'activeMenu':'deActive'"
              (click)="clickMenu('Our Centres')">
              Our Centres
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/locateUs/North">North</a>
              <a class="dropdown-item" routerLink="/locateUs/East">East</a>
              <a class="dropdown-item" routerLink="/locateUs/West">West</a>
              <a class="dropdown-item" routerLink="/locateUs/South">South</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" [ngClass]="getVal == 'Careers'? 'activeMenu':'deActive'"
              (click)="clickMenu('Careers')">
              Careers
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/careersPlacement"
                [ngClass]="getVal == 'Placements'? 'activeMenu':'deActive'"
                (click)="clickMenu('Placements')">Placements</a>
              <a class="dropdown-item" routerLink="/careersWorkWithUs"
                [ngClass]="getVal == 'Work With Us'? 'activeMenu':'deActive'" (click)="clickMenu('Work With Us')">Work
                With Us</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" [ngClass]="getVal == 'Resources'? 'activeMenu':'deActive'"
              (click)="clickMenu('Resources')">
              Resources
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/aboutUs" [ngClass]="getVal == 'About ZICA'? 'activeMenu':'deActive'"
                (click)="clickMenu('About ZICA')">About ZICA</a>
              <a class="dropdown-item" routerLink="/awards-and-recognition"
                [ngClass]="getVal == 'Awards and Recognition'? 'activeMenu':'deActive'"
                (click)="clickMenu('Awards and Recognition')">Awards and Recognition</a>
              <a class="dropdown-item" routerLink="/event" [ngClass]="getVal == 'Our Events'? 'activeMenu':'deActive'"
                (click)="clickMenu('Our Events')">Our Events</a>
              <a class="dropdown-item" routerLink="/blog/all" [ngClass]="getVal == 'Our Blogs'? 'activeMenu':'deActive'"
                (click)="clickMenu('Our Blogs')">Our Blogs</a>

            </div>
          </li>
          <li class="nav-item">
            <button class="btn admission_enq_btn" routerLink="/franchise"
              [ngClass]="getVal == 'Start a Franchise'? 'activeMenu':'deActive'"
              (click)="clickMenu('Start a Franchise')">Start a Franchise</button>
          </li>
          <li class="nav-item">
            <button class="btn admission_enq_btn" routerLink="/admissions">Admissions Enquiry</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="header_btn d-flex justify-content-center" routerLink="/admissions" (click)="getadmissionpage()"><img
        src="../../../../assets/img/zica/icon/cap_icon.png" width="25px" height="25px" alt="">Admissions Open Now
      2024-2025</div>
  </nav>

</div>

<div class="whiteHeader_dark mobile_nav" id="whiteHeader_dark">
  <div class="side_con">
    <a href="https://wa.me/9152010855" target="_blank"><div class="watsapp_icon"></div></a>
    <div class="download_icon"></div>
</div>
  <nav class="nav navbar navbar-expand-lg">
    <div class="container-fluid">
      <div class="navbar-brand d-flex">
        <div class="">
          <img src="../../../../assets/img/zica/ZICA_Logo_finle.png" class="zica_logo" alt="" routerLink="">
        </div>
        <div class="">
          <img src="../../../../assets/img/zica/year_logo.png" class="header_logo2" alt="">
        </div>
      </div>
      <div class="menu_Img" data-bs-toggle="collapse" data-bs-target="#navbarNav" id="menuClose">
        <div class="menu_icon"></div>
      </div>
      <div class="collapse navbar-collapse " id="navbarNav">
        <ul class="navbar-nav ul_content">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              Our Programs
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

              <a class="dropdown-item" (click)="closeMenu()" routerLink="/BVocAnimationVisualEffects">B.Voc Degree</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/animation">3D Animation</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/2D_Animation">2D Animation</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/visualEffect">Visual Effects(VFX)</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/game">Game Art Design</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/graphicDesign">Graphic Design</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/UiUx">UI & UX</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/webDesign">Web Design</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/motionGraphics">Motion Graphics</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/interiorDesign">Interior Design</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/fashionDesign">Fashion Design</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/digitalMarketing">Digital Marketing</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/digitalPhotography">Digital Photography</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/blender">Blender</a>
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/unrel">Unrel</a> 
              <a class="dropdown-item" (click)="closeMenu()" routerLink="/digitalAudioVideoEditing">Digital Audio Video Editing</a> 

<!-- 
              <a href="/BVocAnimationVisualEffects" (click)="closeMenu()">B.Voc. in Animation & Visual Effects</a>
              <a class="dropdown-item" routerLink="/animation" (click)="closeMenu()">3D Animation</a>
              <a class="dropdown-item" routerLink="/2D_Animation" (click)="closeMenu()">2D Animation</a>
              <a class="dropdown-item" routerLink="/visualEffect" (click)="closeMenu()">Visual Effects</a>
              <a class="dropdown-item" routerLink="/graphicDesign" (click)="closeMenu()">Graphic Design</a>
              <a class="dropdown-item" routerLink="/webDesign" (click)="closeMenu()">Web Art & Design</a>
              <a class="dropdown-item" routerLink="/interiorDesign" (click)="closeMenu()">Interior Design</a>
              <a class="dropdown-item" routerLink="/fashionDesign" (click)="closeMenu()">Fashion Design</a>
              <a class="dropdown-item" routerLink="/digitalMarketing" (click)="closeMenu()">Digital Marketing</a>
              <a class="dropdown-item" routerLink="/digitalPhotography" (click)="closeMenu()">Digital Photography</a>
              <a class="dropdown-item" routerLink="/game" (click)="closeMenu()">Game Design</a> -->
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="/studentsZone" (click)="closeMenu()">Student
              Zone</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" [ngClass]="getVal == 'Our Programs'? 'activeMenu':'deActive'"
              (click)="clickMenu('Our Programs')">
              Our Centres
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/locateUs/North" (click)="closeMenu()">North</a>
              <a class="dropdown-item" routerLink="/locateUs/East" (click)="closeMenu()">East</a>
              <a class="dropdown-item" routerLink="/locateUs/West" (click)="closeMenu()">West</a>
              <a class="dropdown-item" routerLink="/locateUs/South" (click)="closeMenu()">South</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              Careers
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/careersPlacement" (click)="closeMenu()">Placements</a>
              <a class="dropdown-item" routerLink="/careersWorkWithUs" (click)="closeMenu()">Work With Us</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              Resources
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/aboutUs" (click)="closeMenu()">About ZICA</a>
              <a class="dropdown-item" routerLink="/awards-and-recognition" (click)="closeMenu()">Awards and
                Recognition</a>
              <a class="dropdown-item" routerLink="/event" (click)="closeMenu()">Our Events</a>
              <a class="dropdown-item" routerLink="/blog/all" (click)="closeMenu()">Our Blogs</a>

            </div>
          </li>
          <li class="nav-item">
            <button class="btn startFr_btn" routerLink="/franchise" (click)="closeMenu()">Start a Franchise</button>
          </li>
          <li class="nav-item">
            <button class="btn admission_enq_btn" routerLink="/admissions" (click)="closeMenu()">Admissions
              Enquiry</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="header_btn d-flex justify-content-center" routerLink="/admissions"><img src="../../../../assets/img/zica/icon/cap_icon.png"
        width="25px" height="25px" alt="">Admissions Open Now
        2024-2025</div>
  </nav>

</div>
<router-outlet></router-outlet>
<app-footer></app-footer>