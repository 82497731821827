import { Component } from '@angular/core';

@Component({
  selector: 'app-three-danimation',
  templateUrl: './three-danimation.component.html',
  styleUrls: ['./three-danimation.component.css','../../../../../src/app/components/commonCss/program.css']
})
export class ThreeDanimationComponent {

}
