import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-partner-page',
  templateUrl: './partner-page.component.html',
  styleUrls: ['./partner-page.component.css']
})
export class PartnerPageComponent implements OnInit{
  admissionForm: FormGroup;
  otp_ValidMsg: boolean = false
  otp_inValidMsg: boolean = false
  submitted: boolean = false
  otpInput: boolean = false
  randomOtp: any;
  countryList: any;
  stateList: any;
  cityList: any;
  franchiseeList: any;
  selectedcity:boolean=false;
  utm_source:string="Website";
  utm_medium:string="Website";
  utm_compaign:string="Website";
  gclid:string="Website";
  utm_term:string="Website";
  utm_content:string="Website";
  utm_ad:string="Website";
  keyword = 'name';
  state_list_data = {
    "root": {
       "subroot": [
          {
             "Country_Name": "India",
             "State": [
                {
                   "State_Name": "Andaman and Nicobar Islands",
                   "City": [
                      {
                         "City_Name": "N/a"
                      },
                      {
                         "City_Name": "Port blair"
                      },
                      {
                         "City_Name": "Garacharma"
                      }
                   ]
                },
                {
                   "State_Name": "Andhra Pradesh",
                   "City": [
                      {
                         "City_Name": "Guntur",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4710",
                            "Franchisee_Name": "Kidzee Tenali",
                            "Operating_Status": "A",
                            "Address1": "D.No- 11/10/21,Ground Floor,",
                            "Address2": "Captain Vijay Prasad Street, Chenchupet,",
                            "Place": "Tenali, Near Bus stop",
                            "City_Id": "393",
                            "State_Id": "2",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4710@kidzee.com",
                            "Mobile_No": "7259897766",
                            "Phone_No1": "7259897766",
                            "Phone_No2": "",
                            "Latitude": "16.237901",
                            "Longitude": "80.636691"
                         }
                      },
                      {
                         "City_Name": "Kakinada",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-2576",
                               "Franchisee_Name": "Kidzee Kakinada",
                               "Operating_Status": "A",
                               "Address1": "Door No: 2/44/43,",
                               "Address2": "",
                               "Place": "Bhaskar Nagar,",
                               "City_Id": "519",
                               "State_Id": "2",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2576@kidzee.com",
                               "Mobile_No": "9603441344",
                               "Phone_No1": "0884-2381344",
                               "Phone_No2": "",
                               "Latitude": "16.971127",
                               "Longitude": "82.240401",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/864778/10711/5176cd12_e992_43f6_8c57_3671a69d9bc9.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-U-U-817",
                               "Franchisee_Name": "Kidzee- Kakinada- AP",
                               "Operating_Status": "A",
                               "Address1": "D.No-69-3-18, Opposite- Andhra Bank Colony,",
                               "Address2": "",
                               "Place": "Nagavanam,",
                               "City_Id": "519",
                               "State_Id": "2",
                               "Country_Id": "1",
                               "Email_Id": "kidzee817@kidzee.com",
                               "Mobile_No": "9490710708",
                               "Phone_No1": "9849059248",
                               "Phone_No2": "",
                               "Latitude": "16.971683",
                               "Longitude": "82.252898"
                            }
                         ]
                      },
                      {
                         "City_Name": "Kodad / Kodada"
                      },
                      {
                         "City_Name": "Kurnool"
                      },
                      {
                         "City_Name": "Nellore",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-5515",
                            "Franchisee_Name": "KIidzee Bakthavasala Nagar",
                            "Operating_Status": "A",
                            "Address1": "D no-26-12-1036",
                            "Address2": "Bank Colony , RTO Office Road, Opp Nuda Park, SBI Bank Colony",
                            "Place": "Bakthavasala Nagar",
                            "City_Id": "729",
                            "State_Id": "2",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5515@kidzee.com",
                            "Mobile_No": "7093528676",
                            "Phone_No1": "861-3562283",
                            "Phone_No2": "",
                            "Latitude": "14.413087131723906",
                            "Longitude": "79.97249722480774"
                         }
                      },
                      {
                         "City_Name": "Vijayawada",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4246",
                            "Franchisee_Name": "Kidzee Patamata Vijayawada",
                            "Operating_Status": "A",
                            "Address1": "H No- 70-2-25,",
                            "Address2": "Siva Sankar Nagar,",
                            "Place": "Patamata Vijayawada, VHM Public School,",
                            "City_Id": "1012",
                            "State_Id": "2",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4246@kidzee.com",
                            "Mobile_No": "9959123009",
                            "Phone_No1": "9959123009",
                            "Phone_No2": "",
                            "Latitude": "16.49036",
                            "Longitude": "80.66423"
                         }
                      },
                      {
                         "City_Name": "Visakhapatnam / Vaishakhapuram / Vizag",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-3914",
                               "Franchisee_Name": "Kidzee Seethammadhara",
                               "Operating_Status": "A",
                               "Address1": "D.No. 50-117-8,",
                               "Address2": "North Extension,",
                               "Place": "Seethammadhara ,Opp. Cancer Hospital",
                               "City_Id": "1019",
                               "State_Id": "2",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3914@kidzee.com",
                               "Mobile_No": "9299404303",
                               "Phone_No1": "0929-9404303",
                               "Phone_No2": "",
                               "Latitude": "17.74467000000001",
                               "Longitude": "83.30835681349184",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/2889d0c4_1079_4a48_989d_4524bc46f921.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/a0af5d6a_a9ff_4999_8220_647320af574f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/c04c2852_e5ea_4357_8a2a_a2cdad8f5b82.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/e5994618_f503_43ad_9710_5caedd592c30.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/32cbec05_6cde_4c92_872b_01909b9b10dd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/5ffe7b2f_7be8_4fcd_9b02_968f999c7fdc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/b16f3f85_de9d_4906_9283_1255d04634df.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/9d1ba490_e5a9_4c2f_9b25_041a9327390d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/c3cd4739_5ce7_45b6_8aa9_3b48ae59d8c9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/ce37f4fc_b2b6_40b0_aabc_6dabb5503ee0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/f76e8f8a_c2fd_48fd_bb30_2e9a03d54ae5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/02ce2543_e458_4efa_b1ce_e912d847151e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/b1b688c6_1986_4224_9497_947fb3abf069.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/d6d3fad7_1de2_475c_96b5_62b5ad707ad3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1948240/10657/7b7f5229_6a1c_480d_bc5c_b137db527ccd.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-3045",
                               "Franchisee_Name": "KIDZEE  MADHAVADHARA",
                               "Operating_Status": "A",
                               "Address1": "D.No-39-33-104/1/2,",
                               "Address2": "HIG-43, Vuda Colony, Phase-2,",
                               "Place": "Near-AP Pollution Control Board Road, Vuda Layout,",
                               "City_Id": "1019",
                               "State_Id": "2",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3045@kidzee.com",
                               "Mobile_No": "9912855855",
                               "Phone_No1": "9912855855",
                               "Phone_No2": "",
                               "Latitude": "17.75095",
                               "Longitude": "83.24760"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5253",
                               "Franchisee_Name": "Kidzee Sujatha Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot no - 62",
                               "Address2": "Satabdi Nagar 80 feet road",
                               "Place": "Sujatha Nagar",
                               "City_Id": "1019",
                               "State_Id": "2",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5253@kidzee.com",
                               "Mobile_No": "9246623370",
                               "Phone_No1": "9246623370",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Warangal"
                      },
                      {
                         "City_Name": "Khammam"
                      },
                      {
                         "City_Name": "Quthbullapur"
                      },
                      {
                         "City_Name": "Tadepalligudem"
                      },
                      {
                         "City_Name": "Nidadavole / Nidadavolu / Niravadyapuram"
                      },
                      {
                         "City_Name": "Chirala"
                      },
                      {
                         "City_Name": "Jadcherla / Jadcharla"
                      },
                      {
                         "City_Name": "Kadiri"
                      },
                      {
                         "City_Name": "Tirupati",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2425",
                            "Franchisee_Name": "Kidzee Prakasam Educational Society  Chirala",
                            "Operating_Status": "A",
                            "Address1": "14-2-15, High School Road,",
                            "Address2": "",
                            "Place": "Chirala, Near Munthavari Center,",
                            "City_Id": "1314",
                            "State_Id": "2",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2425@kidzee.com",
                            "Mobile_No": "7013327303",
                            "Phone_No1": "7013327303",
                            "Phone_No2": "",
                            "Latitude": "15.825036",
                            "Longitude": "80.351925",
                            "Images": {
                               "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/792013/10737/3b939383_5805_4797_ab81_1edca15b8de7.jpg"
                            }
                         }
                      },
                      {
                         "City_Name": "Rajahmundry"
                      },
                      {
                         "City_Name": "Kalyandurg"
                      },
                      {
                         "City_Name": "Ananthapur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-5067",
                               "Franchisee_Name": "Kidzee Hindupuram",
                               "Operating_Status": "A",
                               "Address1": "Sri vidya nagar,",
                               "Address2": "Mukkidipeta, Hindupuram,",
                               "Place": "Hindupur ,Pipeline Road",
                               "City_Id": "1387",
                               "State_Id": "2",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5067@kidzee.com",
                               "Mobile_No": "7696141640",
                               "Phone_No1": "7696141640",
                               "Phone_No2": "",
                               "Latitude": "13.82015",
                               "Longitude": "77.48502"
                            },
                            {
                               "Franchisee_Code": "A-S-A-1010",
                               "Franchisee_Name": "Ankurum  Tadipatri",
                               "Operating_Status": "A",
                               "Address1": "Krishnapuram,",
                               "Address2": "7th road,",
                               "Place": "Tadipatri ,not lunched",
                               "City_Id": "1387",
                               "State_Id": "2",
                               "Country_Id": "1",
                               "Email_Id": "rachamallusiva9@gmail.com",
                               "Mobile_No": "9490859931",
                               "Phone_No1": "9490859931",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Wanaparthi"
                      },
                      {
                         "City_Name": "Armoor"
                      },
                      {
                         "City_Name": "Nirmal"
                      },
                      {
                         "City_Name": "Ramachandrapuram"
                      },
                      {
                         "City_Name": "Nandyal"
                      },
                      {
                         "City_Name": "Mahabaubanagar"
                      },
                      {
                         "City_Name": "Tadpatri"
                      },
                      {
                         "City_Name": "Mancherial"
                      },
                      {
                         "City_Name": "Nandigama"
                      },
                      {
                         "City_Name": "Adoni"
                      },
                      {
                         "City_Name": "Ongole"
                      },
                      {
                         "City_Name": "Dharmavaram"
                      },
                      {
                         "City_Name": "Tanuku"
                      },
                      {
                         "City_Name": "Chittoor",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-3036",
                            "Franchisee_Name": "Kidzee Chittoor  AP",
                            "Operating_Status": "A",
                            "Address1": "Lane -No-2-36, Ground Floor,",
                            "Address2": "Gurunagar Colony,",
                            "Place": "Chittoor, KR Palli,",
                            "City_Id": "1565",
                            "State_Id": "2",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3036@kidzee.com",
                            "Mobile_No": "9492321916",
                            "Phone_No1": "08572234116",
                            "Phone_No2": "",
                            "Latitude": "13.217504635213112",
                            "Longitude": "79.10812318325043",
                            "Images": [
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3036/307/9a85a379_e4e7_4956_b31a_f7d3bb3b9297.JPG"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3036/307/5825acac_6e33_45c6_bf5c_ffa582fd2f46.JPG"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3036/307/67a8979e_6757_4cea_8fe1_a2ae5b175ea8.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Cuddaph"
                      },
                      {
                         "City_Name": "Srikakulam",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-4410",
                               "Franchisee_Name": "Kidzee Palasa Mandal",
                               "Operating_Status": "A",
                               "Address1": "Door No-13-14-248, Ground Floor,",
                               "Address2": "Gunneswara Nagar, Kasibugga,  Palasa Mandal, Srikakulam District,",
                               "Place": "Palasa ,Near-Oxyzen Hospital Lane,",
                               "City_Id": "1604",
                               "State_Id": "2",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4410@kidzee.com",
                               "Mobile_No": "8978522588",
                               "Phone_No1": "8978522588",
                               "Phone_No2": "",
                               "Latitude": "18.762916",
                               "Longitude": "84.419614"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4410",
                               "Franchisee_Name": "Kidzee Palasa Mandal",
                               "Operating_Status": "A",
                               "Address1": "Door No-13-14-248, Ground Floor,",
                               "Address2": "Gunneswara Nagar, Kasibugga,  Palasa Mandal, Srikakulam District,",
                               "Place": "Palasa ,Near-Oxyzen Hospital Lane,",
                               "City_Id": "1604",
                               "State_Id": "2",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4410@kidzee.com",
                               "Mobile_No": "8978522588",
                               "Phone_No1": "8978522588",
                               "Phone_No2": "",
                               "Latitude": "18.762916",
                               "Longitude": "84.419614"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4410",
                               "Franchisee_Name": "Kidzee Palasa Mandal",
                               "Operating_Status": "A",
                               "Address1": "Door No-13-14-248, Ground Floor,",
                               "Address2": "Gunneswara Nagar, Kasibugga,  Palasa Mandal, Srikakulam District,",
                               "Place": "Palasa ,Near-Oxyzen Hospital Lane,",
                               "City_Id": "1604",
                               "State_Id": "2",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4410@kidzee.com",
                               "Mobile_No": "8978522588",
                               "Phone_No1": "8978522588",
                               "Phone_No2": "",
                               "Latitude": "18.762916",
                               "Longitude": "84.419614"
                            }
                         ]
                      },
                      {
                         "City_Name": "Proddatur"
                      },
                      {
                         "City_Name": "Bhimavaram",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-3730",
                            "Franchisee_Name": "Kidzee Bhimavaram",
                            "Operating_Status": "A",
                            "Address1": "House No-25-8-7   Ward No-30,",
                            "Address2": "Peddeti Vari Street",
                            "Place": "Bhimavaram",
                            "City_Id": "1634",
                            "State_Id": "2",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3730@kidzee.com",
                            "Mobile_No": "9949688295",
                            "Phone_No1": "9949688295",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Vizag"
                      },
                      {
                         "City_Name": "Anakapalli"
                      },
                      {
                         "City_Name": "Anantapur"
                      },
                      {
                         "City_Name": "Gajuwaka"
                      },
                      {
                         "City_Name": "Amalapuram"
                      },
                      {
                         "City_Name": "Vissannapeta"
                      },
                      {
                         "City_Name": "Narshipatnam"
                      },
                      {
                         "City_Name": "Vizianagaram",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4682",
                            "Franchisee_Name": "Kidzee Vizianagram",
                            "Operating_Status": "A",
                            "Address1": "H.No. MIG-III-32, K.A. Peta,",
                            "Address2": "",
                            "Place": "Vizianagaram, Vuda Phase-I,",
                            "City_Id": "1886",
                            "State_Id": "2",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4682@kidzee.com",
                            "Mobile_No": "9405158915",
                            "Phone_No1": "9405158915",
                            "Phone_No2": "",
                            "Latitude": "18.11114",
                            "Longitude": "83.38285"
                         }
                      },
                      {
                         "City_Name": "Tadipatri"
                      },
                      {
                         "City_Name": "Razole",
                         "Franchisee": {
                            "Franchisee_Code": "A-S-A-1012",
                            "Franchisee_Name": "Ankurum Razole",
                            "Operating_Status": "A",
                            "Address1": "H.No- 7-86, Dr. KT Razole, Mandal",
                            "Address2": "Raja Rao street,  East Godavari",
                            "Place": "Razole ,Near Sri Vanuvulamma Temple",
                            "City_Id": "1917",
                            "State_Id": "2",
                            "Country_Id": "1",
                            "Email_Id": "snigdha317@gmail.com",
                            "Mobile_No": "9494048118",
                            "Phone_No1": "9848148118",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Tenali"
                      }
                   ]
                },
                {
                   "State_Name": "Arunachal Pradesh",
                   "City": [
                      {
                         "City_Name": "Daporijo"
                      },
                      {
                         "City_Name": "Itanagar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-U-611",
                               "Franchisee_Name": "Kidzee-Itanagar/ Kids Angel",
                               "Operating_Status": "A",
                               "Address1": "RCC Building,",
                               "Address2": "Tadar Tang Marg,",
                               "Place": "Niti Vihar,",
                               "City_Id": "463",
                               "State_Id": "4",
                               "Country_Id": "1",
                               "Email_Id": "kidzee611@kidzee.com",
                               "Mobile_No": "7005886097",
                               "Phone_No1": "7005886097",
                               "Phone_No2": "",
                               "Latitude": "27.084368",
                               "Longitude": "93.605316"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2679",
                               "Franchisee_Name": "KIDZEE ITANAGAR",
                               "Operating_Status": "A",
                               "Address1": "R K Naam,",
                               "Address2": "Forest Colony,",
                               "Place": "Itanagar ,P-Sector,",
                               "City_Id": "463",
                               "State_Id": "4",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2679@kidzee.com",
                               "Mobile_No": "9862173608",
                               "Phone_No1": "9862173608",
                               "Phone_No2": "",
                               "Latitude": "27.084368",
                               "Longitude": "93.605316"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5265",
                               "Franchisee_Name": "Kidzee Banderewa",
                               "Operating_Status": "A",
                               "Address1": "Dooyin colony",
                               "Address2": "Near CRC, PTC",
                               "Place": "Banderewa",
                               "City_Id": "463",
                               "State_Id": "4",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5265@kidzee.com",
                               "Mobile_No": "8708107536",
                               "Phone_No1": "8708107536",
                               "Phone_No2": "",
                               "Latitude": "27.0952",
                               "Longitude": "93.8274"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5334",
                               "Franchisee_Name": "Kidzee Tiniali",
                               "Operating_Status": "A",
                               "Address1": "NH415 , Near- KendriyaVidyalaya",
                               "Address2": "Ambani Building, Gohpur Tiniali",
                               "Place": "Tiniali",
                               "City_Id": "463",
                               "State_Id": "4",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5334@kidzee.com",
                               "Mobile_No": "7005763199",
                               "Phone_No1": "7005763199",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Naharlagun",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-C-827",
                               "Franchisee_Name": "Kidzee-Naharlagan/Hema Kids Paradise",
                               "Operating_Status": "A",
                               "Address1": "Polo Colony, PO- Naharlagun,",
                               "Address2": "",
                               "Place": "Polo Colony,",
                               "City_Id": "1061",
                               "State_Id": "4",
                               "Country_Id": "1",
                               "Email_Id": "kidzee827@kidzee.com",
                               "Mobile_No": "8974951339",
                               "Phone_No1": "9402258486",
                               "Phone_No2": "",
                               "Latitude": "27.0979351",
                               "Longitude": "93.6965407"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5312",
                               "Franchisee_Name": "Kidzee Papu Nallah",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor,",
                               "Address2": "Jully Road,",
                               "Place": "Pupu Nallah,",
                               "City_Id": "1061",
                               "State_Id": "4",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5312@kidzee.com",
                               "Mobile_No": "9436040214",
                               "Phone_No1": "7005374995",
                               "Phone_No2": "",
                               "Latitude": "27.100734",
                               "Longitude": "93.61727"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5379",
                               "Franchisee_Name": "Kidzee Lower Model Village",
                               "Operating_Status": "A",
                               "Address1": "Lower Model Village,",
                               "Address2": "Papumpare,",
                               "Place": "Lower Model Village",
                               "City_Id": "1061",
                               "State_Id": "4",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5379@kidzee.com",
                               "Mobile_No": "8132887916",
                               "Phone_No1": "8132887916",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Along",
                         "Franchisee": {
                            "Franchisee_Code": "E-C-U-1137",
                            "Franchisee_Name": "Kidzee- Along-Arunachal Pradesh- Mubu- Aba- Childhome",
                            "Operating_Status": "A",
                            "Address1": "Lane-11, Bogdo,",
                            "Address2": "Along,",
                            "Place": "Muba- Aba- Home,",
                            "City_Id": "1063",
                            "State_Id": "4",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1137@kidzee.com",
                            "Mobile_No": "8258976285",
                            "Phone_No1": "8414805056",
                            "Phone_No2": "",
                            "Latitude": "28.162784",
                            "Longitude": "94.805414"
                         }
                      },
                      {
                         "City_Name": "Khonsa",
                         "Franchisee": {
                            "Franchisee_Code": "E-A-M-1400",
                            "Franchisee_Name": "Kidzee-Khonsa-Arunachal Pradesh- Tirap Valley School",
                            "Operating_Status": "A",
                            "Address1": "Plot No-368, Type V-Colony,",
                            "Address2": "Khonsa,",
                            "Place": "Type V-Colony,",
                            "City_Id": "1193",
                            "State_Id": "4",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1400@kidzee.com",
                            "Mobile_No": "8974388311",
                            "Phone_No1": "3786222867",
                            "Phone_No2": "",
                            "Latitude": "26.987784720157215",
                            "Longitude": "95.5113678685509"
                         }
                      },
                      {
                         "City_Name": "Deomali"
                      },
                      {
                         "City_Name": "Pasighat",
                         "Franchisee": {
                            "Franchisee_Code": "E-A-M-1425",
                            "Franchisee_Name": "KIDZEE-PASIGHAT-TANGU RINGU MEMORIAL SCHOOL",
                            "Operating_Status": "A",
                            "Address1": "C/o Er Tapi Darang,",
                            "Address2": "",
                            "Place": "Pasighat Market,",
                            "City_Id": "1245",
                            "State_Id": "4",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1425@kidzee.com",
                            "Mobile_No": "7005507491",
                            "Phone_No1": "",
                            "Phone_No2": "",
                            "Latitude": "28.068108",
                            "Longitude": "95.330354"
                         }
                      },
                      {
                         "City_Name": "Ziro"
                      },
                      {
                         "City_Name": "Namsai",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3040",
                            "Franchisee_Name": "Kidzee  Namsai",
                            "Operating_Status": "A",
                            "Address1": "Chownok Memorial Society, C/o Zenith Child School,",
                            "Address2": "North Point Campus,",
                            "Place": "Lohit Dist Namsai ,Near-Circuit House,",
                            "City_Id": "1566",
                            "State_Id": "4",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3040@kidzee.com",
                            "Mobile_No": "8794504178",
                            "Phone_No1": "8794504178",
                            "Phone_No2": "",
                            "Latitude": "27.669211",
                            "Longitude": "95.864402"
                         }
                      },
                      {
                         "City_Name": "SEPPA, EAST KAMENG"
                      },
                      {
                         "City_Name": "Nirjuli",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4719",
                            "Franchisee_Name": "Kidzee Nirjuli",
                            "Operating_Status": "A",
                            "Address1": "Papumpare,",
                            "Address2": "Near Poilice Station,",
                            "Place": "Bage Tiniali, Near Police Station",
                            "City_Id": "1900",
                            "State_Id": "4",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4719@kidzee.com",
                            "Mobile_No": "9436042741",
                            "Phone_No1": "0360-2257319",
                            "Phone_No2": "",
                            "Latitude": "27.1316303",
                            "Longitude": "93.7379593"
                         }
                      },
                      {
                         "City_Name": "Longding",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-4810",
                               "Franchisee_Name": "Kidzee Longding",
                               "Operating_Status": "A",
                               "Address1": "Niaunu Village P.O,",
                               "Address2": "Longding P.S.",
                               "Place": "Niaunu Village",
                               "City_Id": "1906",
                               "State_Id": "4",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4810@kidzee.com",
                               "Mobile_No": "8256916731",
                               "Phone_No1": "8256916731",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5558",
                               "Franchisee_Name": "Kidzee Kanubari",
                               "Operating_Status": "A",
                               "Address1": "Kanubari Town",
                               "Address2": "Behind ADC Office",
                               "Place": "Kanubari",
                               "City_Id": "1906",
                               "State_Id": "4",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5558@kidzee.com",
                               "Mobile_No": "7085334441",
                               "Phone_No1": "7085334441",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Lower Dinbang Valley",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5692",
                            "Franchisee_Name": "Kidzee Roing",
                            "Operating_Status": "A",
                            "Address1": "C/o Happy Mene. Mayu II.",
                            "Address2": "Roing Circle, Lower Dibang Valley",
                            "Place": "Kera Atis Roing",
                            "City_Id": "1907",
                            "State_Id": "4",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5692@kidzee.com",
                            "Mobile_No": "9862939963",
                            "Phone_No1": "9862939963",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Doimukh",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4834",
                            "Franchisee_Name": "Kidzee Doimukh",
                            "Operating_Status": "A",
                            "Address1": "Huto village",
                            "Address2": "Doimukh",
                            "Place": "Doimukh",
                            "City_Id": "1911",
                            "State_Id": "4",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4834@kidzee.com",
                            "Mobile_No": "8794355817",
                            "Phone_No1": "8794355817",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Bhalukhpong"
                      },
                      {
                         "City_Name": "BASAR",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5164",
                            "Franchisee_Name": "Kidzee Bashar",
                            "Operating_Status": "A",
                            "Address1": "C/O-Dr. Migo Riba,",
                            "Address2": "Kadi,",
                            "Place": "Basar, Near-ITBP HQ,",
                            "City_Id": "1938",
                            "State_Id": "4",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5164@kidzee.com",
                            "Mobile_No": "8729905504",
                            "Phone_No1": "8729905504",
                            "Phone_No2": "",
                            "Latitude": "27.9930929",
                            "Longitude": "94.6864800",
                            "Images": [
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2750105/11049/ff380f4d_777c_4b4a_aad4_864bd949eec7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2750105/11049/90bc790a_7ccb_4319_a386_6f58f179628f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2750105/11049/ffc3eaee_c5af_4dc9_b069_683b6b224dde.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2750105/11049/06cf79fa_7ea1_4b0c_947d_2c0a08ec9dee.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2750105/11049/333d59d0_0772_4f14_aa9e_71390912e6f7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2750105/11049/195925d7_65b7_48d4_87ea_521326604873.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2750105/11049/88e1e27c_1745_4286_bd8b_8bf27dad3f92.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2750105/11049/8cc6f98c_f244_4599_8e9f_392341ad20f5.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2750105/11049/abf60b18_3be0_4868_a788_b4063123f7d2.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2750105/11049/885f7b83_f434_41c4_b1eb_c9ea6a68985a.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "PALIN",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5191",
                            "Franchisee_Name": "Kidzee Palin",
                            "Operating_Status": "A",
                            "Address1": "Kra Dadi District",
                            "Address2": "P.O/P.S Palin",
                            "Place": "New Palin",
                            "City_Id": "1949",
                            "State_Id": "4",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5191@kidzee.com",
                            "Mobile_No": "8794919119",
                            "Phone_No1": "8119080150",
                            "Phone_No2": "",
                            "Latitude": "27.693354",
                            "Longitude": "93.634992"
                         }
                      },
                      {
                         "City_Name": "Bombdila"
                      },
                      {
                         "City_Name": "YAZALI"
                      },
                      {
                         "City_Name": "YUPIA"
                      },
                      {
                         "City_Name": "JAIRAMPUR",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5627",
                            "Franchisee_Name": "Kidzee Changlang",
                            "Operating_Status": "A",
                            "Address1": "17  Mile Terit Nagar",
                            "Address2": "Village Kovin",
                            "Place": "Changlang",
                            "City_Id": "1996",
                            "State_Id": "4",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5627@kidzee.com",
                            "Mobile_No": "8453921389",
                            "Phone_No1": "8453921389",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Assam",
                   "City": [
                      {
                         "City_Name": "Guwahati",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-B-U-300",
                               "Franchisee_Name": "Kidzee- Guwahati Maligaon-Assam- SUNFLOWER EDUMATICS",
                               "Operating_Status": "A",
                               "Address1": "Kidzee Maligaon Center",
                               "Address2": "Pandu Port Road, Opposite SBI Bank, Anandanagar",
                               "Place": "Maligaon",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee300@kidzee.com",
                               "Mobile_No": "9864018576",
                               "Phone_No1": "9864018576",
                               "Phone_No2": "",
                               "Latitude": "26.15001",
                               "Longitude": "91.687412"
                            },
                            {
                               "Franchisee_Code": "E-B-U-269",
                               "Franchisee_Name": "Kidzee- Rehabari- Guwahati-KIDS INTELLECTUALS",
                               "Operating_Status": "A",
                               "Address1": "Rehabari Bilpar, Behind Jagaran,",
                               "Address2": "A K Azad Road,",
                               "Place": "Rehabari ,Near Jagaran",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee269@kidzee.com",
                               "Mobile_No": "9864104783",
                               "Phone_No1": "9864104783",
                               "Phone_No2": "",
                               "Latitude": "26.173241",
                               "Longitude": "91.749051",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10681/e13ad412_a413_45d6_babc_1a0a1a9eb868.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10682/d9d90e85_bee4_4a84_b794_e6320227ba70.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10681/95611473_ef37_48af_b924_e3e45e238a54.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10683/316edd9b_5f7d_4f92_b1e9_2610ab1aaec2.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10684/e4d1768e_8381_4b18_8c02_cb67201afbb3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10684/6e42a032_97f1_43d0_89ae_bbf631f1bca0.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10684/bf4ce2ac_588c_4ab5_8659_93d7f60cdaf8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10685/8be5fe90_b7ff_4321_b758_6344c70a793f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10685/2294b7e0_f3f3_4a51_95f5_01f5e05c82c9.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10685/c2c5e1c7_4151_4975_aa4a_9d892b583517.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10685/f81b98a7_a8f9_4498_80ea_320177046f50.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10685/fd1a0381_36b4_4650_814c_579b0d8f7074.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/113/10684/bfce8d3b_e593_4ed4_8464_791b9607c743.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-U-U-416",
                               "Franchisee_Name": "Kidzee- Airport VIP Chowk-BLOOMING LEARNERS ",
                               "Operating_Status": "A",
                               "Address1": "Airport, VIP Center,",
                               "Address2": "Besides- SBI airport branch,",
                               "Place": "Airport Vip Chowk, Near SBI",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee416@kidzee.com",
                               "Mobile_No": "9864118811",
                               "Phone_No1": "9864118811",
                               "Phone_No2": "",
                               "Latitude": "26.122397",
                               "Longitude": "91.60217"
                            },
                            {
                               "Franchisee_Code": "E-U-U-509",
                               "Franchisee_Name": "Kidzee- Narengi- Guwahati-KID'S GARDEN",
                               "Operating_Status": "A",
                               "Address1": "Forest Gate,",
                               "Address2": "City Bus Stop,",
                               "Place": "Narengi, Near Forest Gate",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee509@kidzee.com",
                               "Mobile_No": "9435112698",
                               "Phone_No1": "9954707234",
                               "Phone_No2": "",
                               "Latitude": "26.181199",
                               "Longitude": "91.832294"
                            },
                            {
                               "Franchisee_Code": "E-A-M-1389",
                               "Franchisee_Name": "Kidzee- Baghorbori  Guwahati",
                               "Operating_Status": "A",
                               "Address1": "House No- 32, Vidya Nagar, Shiv Mandir Path,",
                               "Address2": "Near Children Park,",
                               "Place": "Bagorbori Satgaon Road, Near-ICICI Bank,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1389@kidzee.com",
                               "Mobile_No": "9864076715",
                               "Phone_No1": "9864076715",
                               "Phone_No2": "",
                               "Latitude": "26.143927",
                               "Longitude": "91.830668"
                            },
                            {
                               "Franchisee_Code": "E-A-M-1390",
                               "Franchisee_Name": "Kidzee- Mathura Nagar- Dispur Guwahati- Khushi's (Downtown)",
                               "Operating_Status": "A",
                               "Address1": "C/o-Zeenat Zamal,",
                               "Address2": "House No-8, Bye Lane No-7, Mathura Nagar,",
                               "Place": "Dispur, DownTown Hospital,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1390@kidzee.com",
                               "Mobile_No": "8638823276",
                               "Phone_No1": "8638823276",
                               "Phone_No2": "",
                               "Latitude": "26.143346",
                               "Longitude": "91.789795"
                            },
                            {
                               "Franchisee_Code": "E-S-C-1611",
                               "Franchisee_Name": "Kidzee-Khanamukh",
                               "Operating_Status": "A",
                               "Address1": "Rupkonwar Housing Complex,",
                               "Address2": "Opposte Khanamukh Check Gate,",
                               "Place": "Khanamukh, Khanamukh Check Gate",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1611@kidzee.com",
                               "Mobile_No": "8011577534",
                               "Phone_No1": "8011577534",
                               "Phone_No2": "",
                               "Latitude": "26.140199",
                               "Longitude": "91.639008"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3246",
                               "Franchisee_Name": "Kidzee Bhetapara",
                               "Operating_Status": "A",
                               "Address1": "Horopriya Path, Sarbodiya Nagar,",
                               "Address2": "Vidya Mandir Lane,",
                               "Place": "Bhetapara, Near Priya Residency",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3246@kidzee.com",
                               "Mobile_No": "9954199445",
                               "Phone_No1": "9954199445",
                               "Phone_No2": "",
                               "Latitude": "26.115458460682945",
                               "Longitude": "91.78398595089149",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3246/201/eec7369f_0588_4ce3_905b_4613adcfdca6.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-R-C-3034",
                               "Franchisee_Name": "SUNSHINE EDUMATICS",
                               "Operating_Status": "A",
                               "Address1": "Kidzee-Boragaon, Near-Gotanagar,",
                               "Address2": "Rajahua Namghar Maligaon,",
                               "Place": "Guwathi ,Redission Tiniali,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3034@kidzee.com",
                               "Mobile_No": "9864151249",
                               "Phone_No1": "-",
                               "Phone_No2": "",
                               "Latitude": "26.16626",
                               "Longitude": "91.720942"
                            },
                            {
                               "Franchisee_Code": "E-S-C-1669",
                               "Franchisee_Name": "Kidzee-Uzanbazaar Guwahati",
                               "Operating_Status": "A",
                               "Address1": "House No.44, Ward street, Uzanbazaar,",
                               "Address2": "District- kamrup (M)",
                               "Place": "Uzanbazaar,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1669@kidzee.com",
                               "Mobile_No": "9864094384",
                               "Phone_No1": "9864094384",
                               "Phone_No2": "",
                               "Latitude": "26.185968",
                               "Longitude": "91.745364"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3379",
                               "Franchisee_Name": "Kidzee Zoo Narengi  Road",
                               "Operating_Status": "A",
                               "Address1": "M.G.Trade & Industry Pvt Ltd,",
                               "Address2": "5th By Lane House No -12,",
                               "Place": "Zoo Narengi Road, Ambikagirinagar,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3379@kidzee.com",
                               "Mobile_No": "8638331224",
                               "Phone_No1": "8638331224",
                               "Phone_No2": "",
                               "Latitude": "26.178199",
                               "Longitude": "91.773379"
                            },
                            {
                               "Franchisee_Code": "E-U-C-916",
                               "Franchisee_Name": "KIDZEE-SIX MILE- GUWAHATI, KIDS KEMP",
                               "Operating_Status": "A",
                               "Address1": "VIP Road, By Lane No-1,",
                               "Address2": "House No-17,",
                               "Place": "Six Miles, VIP Road,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee916@kidzee.com",
                               "Mobile_No": "9613012442",
                               "Phone_No1": "9436802632",
                               "Phone_No2": "",
                               "Latitude": "26.135752",
                               "Longitude": "91.809767"
                            },
                            {
                               "Franchisee_Code": "E-U-C-810",
                               "Franchisee_Name": "KIDZEE-BAMUNIMAIDAM- Daisy india Pvt Ltd.",
                               "Operating_Status": "A",
                               "Address1": "Bamunimaidam,",
                               "Address2": "Near-SBI Main Branch, M.R.D. Road,",
                               "Place": "Bamunimaidam,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee810@kidzee.com",
                               "Mobile_No": "9864094384",
                               "Phone_No1": "2650521",
                               "Phone_No2": "",
                               "Latitude": "26.144758",
                               "Longitude": "91.738425"
                            },
                            {
                               "Franchisee_Code": "E-R-C-1933",
                               "Franchisee_Name": "Kidzee FCI Noonmati",
                               "Operating_Status": "A",
                               "Address1": "House No-22, Shyamlaya,",
                               "Address2": "Anandnagar, P.O. Bamunimaldam Kamrup (M),",
                               "Place": "Noonmati,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1933@kidzee.com",
                               "Mobile_No": "9085050557",
                               "Phone_No1": "09085050557",
                               "Phone_No2": "",
                               "Latitude": "26.198583",
                               "Longitude": "91.800149"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3464",
                               "Franchisee_Name": "Kidzee Lokhra",
                               "Operating_Status": "A",
                               "Address1": "Lokhra Chariali,",
                               "Address2": "PO-Sawkuchi,",
                               "Place": "Lokhra, Opp-BOI,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3464@kidzee.com",
                               "Mobile_No": "9864271067",
                               "Phone_No1": "9864271067",
                               "Phone_No2": "",
                               "Latitude": "26.11062",
                               "Longitude": "91.745957"
                            },
                            {
                               "Franchisee_Code": "E-U-C-972",
                               "Franchisee_Name": "KIDZEE-MANIPURI BASTI-Guwahati- KIDS AB ZONE",
                               "Operating_Status": "A",
                               "Address1": "C/o- Dr.Rajib Singh, House No-4,",
                               "Address2": "",
                               "Place": "Manipuri Basti, Near-Shiv Mandir,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee972@kidzee.com",
                               "Mobile_No": "9864261291",
                               "Phone_No1": "9864261291",
                               "Phone_No2": "",
                               "Latitude": "26.181049",
                               "Longitude": "91.762991"
                            },
                            {
                               "Franchisee_Code": "E-G-K-1089",
                               "Franchisee_Name": "Kidzee- Kamrup- Assam-Fun World",
                               "Operating_Status": "A",
                               "Address1": "VII-Baruabari,",
                               "Address2": "Kamrup, PO-",
                               "Place": "Sonapur Fly Over,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1089@kidzee.com",
                               "Mobile_No": "9954152808",
                               "Phone_No1": "0361-1111111",
                               "Phone_No2": "",
                               "Latitude": "26.265796",
                               "Longitude": "91.699051"
                            },
                            {
                               "Franchisee_Code": "E-C-U-1102",
                               "Franchisee_Name": "Kidzee-Diphu-Assam-Langming Kids",
                               "Operating_Status": "A",
                               "Address1": "Rongkhelan,",
                               "Address2": "Near-Don Bosco High School,",
                               "Place": "Diphu,(Karbi Amglong), Don Bosco High School,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1102@kidzee.com",
                               "Mobile_No": "9954894830",
                               "Phone_No1": "7670063086",
                               "Phone_No2": "",
                               "Latitude": "25.846525",
                               "Longitude": "93.429875"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2304",
                               "Franchisee_Name": "Kidzee Bijaynagar  Kamrup Guwahati",
                               "Operating_Status": "A",
                               "Address1": "Bijay nagar",
                               "Address2": "Near poddar maruti service centre",
                               "Place": "Bijay Nagar Kamrup ,Near Maruti Service Centre",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2304@kidzee.com",
                               "Mobile_No": "9864016846",
                               "Phone_No1": "9864016846",
                               "Phone_No2": "",
                               "Latitude": "26.103392",
                               "Longitude": "74.945475",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/762850/10509/05558038_ae1d_4ae3_9c97_11fa84533d6e.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-R-C-2304",
                               "Franchisee_Name": "Kidzee Bijaynagar  Kamrup Guwahati",
                               "Operating_Status": "A",
                               "Address1": "Bijay nagar",
                               "Address2": "Near poddar maruti service centre",
                               "Place": "Bijay Nagar Kamrup ,Near Maruti Service Centre",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2304@kidzee.com",
                               "Mobile_No": "9864016846",
                               "Phone_No1": "9864016846",
                               "Phone_No2": "",
                               "Latitude": "26.10192",
                               "Longitude": "91.506822",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/762850/10509/05558038_ae1d_4ae3_9c97_11fa84533d6e.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-R-C-4024",
                               "Franchisee_Name": "Kidzee Lal Ganesh",
                               "Operating_Status": "A",
                               "Address1": "Banik Para,",
                               "Address2": "Govinda Nagar,",
                               "Place": "Lal Ganesh, Near Axis Bank,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4024@Kidzee.com",
                               "Mobile_No": "8473818480",
                               "Phone_No1": "8473818480",
                               "Phone_No2": "",
                               "Latitude": "26.141699",
                               "Longitude": "91.740082"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4769",
                               "Franchisee_Name": "Kidzee Beltola",
                               "Operating_Status": "A",
                               "Address1": "H. NO 51, Garukachi Path",
                               "Address2": "Beltola Bazzar Road",
                               "Place": "Beltola Bazzar Road, Beltola, Kamrup District",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4769@kidzee.com",
                               "Mobile_No": "7086046479",
                               "Phone_No1": "7086046479",
                               "Phone_No2": "",
                               "Latitude": "26.121657",
                               "Longitude": "91.801739"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4575",
                               "Franchisee_Name": "Kidzee Batahguli",
                               "Operating_Status": "A",
                               "Address1": "Daag No-100,",
                               "Address2": "Tiniali, Panjabari,",
                               "Place": "Batahguli, Batahguli Public Health Centre,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4575@kidzee.com",
                               "Mobile_No": "9435195911",
                               "Phone_No1": "8811892706",
                               "Phone_No2": "",
                               "Latitude": "26.132043",
                               "Longitude": "91.838396"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4887",
                               "Franchisee_Name": "Kidzee Kahilipara",
                               "Operating_Status": "A",
                               "Address1": "House No 12, Bye lane 5 ,",
                               "Address2": "Jatia,",
                               "Place": "Kahilipara, ,Near Jyoti Chitraban",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4887@kidzee.com",
                               "Mobile_No": "9435194196",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "26.148072",
                               "Longitude": "91.779952"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4932",
                               "Franchisee_Name": "Kidzee Jhalukbari",
                               "Operating_Status": "A",
                               "Address1": "Samannay Path, Bye  No, Path No-3,",
                               "Address2": "Pragjyotishpur Nagar, B,G-Colony,",
                               "Place": "North Jhalukbari, Near-Greentech Apartment,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4932@kidzee.com",
                               "Mobile_No": "7002752625",
                               "Phone_No1": "7002752625",
                               "Phone_No2": "",
                               "Latitude": "26.152335",
                               "Longitude": "91.679525"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4918",
                               "Franchisee_Name": "Kidzee Bonda",
                               "Operating_Status": "A",
                               "Address1": "Bonda Gaon , Near SBI , infront of Assam Roofing Limited ,",
                               "Address2": "3, State Highway , Chandrapur Road , ",
                               "Place": "Bonda ,Opp Assam Roofing Ltd.",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4918@kidzee.com",
                               "Mobile_No": "9435199505",
                               "Phone_No1": "9435199505",
                               "Phone_No2": "",
                               "Latitude": "26.185259",
                               "Longitude": "91.840262"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4648",
                               "Franchisee_Name": "Kidzee Dharapur",
                               "Operating_Status": "A",
                               "Address1": "West Dharapur , Kochpara,",
                               "Address2": "P.O Dharapur , P.S . Azara ,New Airport Road,Dist of Kamrup (Metro)",
                               "Place": "Dharapur ,Bharat Petrolium Petrol Pump",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4648@kidzee.com",
                               "Mobile_No": "7002371474",
                               "Phone_No1": "7002371474",
                               "Phone_No2": "",
                               "Latitude": "26.08199",
                               "Longitude": "91.36536"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4913",
                               "Franchisee_Name": "Kidzee Ullubari",
                               "Operating_Status": "A",
                               "Address1": "house no.8, Green Path .",
                               "Address2": "near S.B Deorah College , Ulubari",
                               "Place": "Guwahati ,Near S B Deorah College",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4913@kidzee.com",
                               "Mobile_No": "9075737046",
                               "Phone_No1": "9075737046",
                               "Phone_No2": "",
                               "Latitude": "26.10120",
                               "Longitude": "91.45583"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5006",
                               "Franchisee_Name": "Kidzee Sawkuchi",
                               "Operating_Status": "A",
                               "Address1": "Shri  Bishnu  Path",
                               "Address2": "Dakhinagaon , Sawkuchi Road ",
                               "Place": "SAWKUCHI ,Dakhingaon Road",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5006@kidzee.com",
                               "Mobile_No": "9854051623",
                               "Phone_No1": "9854051623",
                               "Phone_No2": "",
                               "Latitude": "26.07216",
                               "Longitude": "91.45367"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4546",
                               "Franchisee_Name": "Kidzee Hengrabari",
                               "Operating_Status": "A",
                               "Address1": "House No. 4, L. Nahar, Beltola Mouza,",
                               "Address2": "Suravi Path, Sarumotoria, District-Kamrup (Metro),",
                               "Place": "Hengrabari, Dr. Zakir Hussain Road,",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4546@kidzee.com",
                               "Mobile_No": "8638705774",
                               "Phone_No1": "8638705774",
                               "Phone_No2": "",
                               "Latitude": "26.08471",
                               "Longitude": "91.48041"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5368",
                               "Franchisee_Name": "Kidzee Basistha",
                               "Operating_Status": "A",
                               "Address1": "Village Maidan Gaon,Mouza Beltola,",
                               "Address2": "Patarkuchi Road,Navadoy Nagar, Bylane No-4, Dist.Kamrup Metro,",
                               "Place": "Basistha",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5368@kidzee.com",
                               "Mobile_No": "7002765207",
                               "Phone_No1": "7002765207",
                               "Phone_No2": "",
                               "Latitude": "26.10967",
                               "Longitude": "91.80285"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5760",
                               "Franchisee_Name": "Kidzee Jyotikuchi",
                               "Operating_Status": "A",
                               "Address1": "H.no.2,Bylane no.8,Jaymati nagar,Pandu,",
                               "Address2": "Dhupulia Road, Dist Kamrup M",
                               "Place": "Jyotikuchi",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5760@kidzee.com",
                               "Mobile_No": "8876737471",
                               "Phone_No1": "8876737471",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5721",
                               "Franchisee_Name": "Kidzee Azara",
                               "Operating_Status": "A",
                               "Address1": "NH 37, Bharali Para,",
                               "Address2": "Near Azara Sabhaghar, Opp HDFC bank",
                               "Place": "Azara",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5721@kidzee.com",
                               "Mobile_No": "9577385851",
                               "Phone_No1": "9577385851",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5667",
                               "Franchisee_Name": "Kidzee Amingaon",
                               "Operating_Status": "A",
                               "Address1": "Ground floor , House Np137,",
                               "Address2": "Kamrup (Rural), Hajo Amingaon Road",
                               "Place": "Amingaon",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5667@kidzee.com",
                               "Mobile_No": "9436312548",
                               "Phone_No1": "9436312548",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "A-E-A-1051",
                               "Franchisee_Name": "Ankurum Rangmahal",
                               "Operating_Status": "A",
                               "Address1": "Revenue village- rangmahal",
                               "Address2": "P.O  Rangmahal, District Kamrup Rural at Amingaon",
                               "Place": "Rangmahal",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "das.basanta@gmail.com",
                               "Mobile_No": "9864858380",
                               "Phone_No1": "7399782244",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5719",
                               "Franchisee_Name": "Kidzee Ghoramara",
                               "Operating_Status": "A",
                               "Address1": "House No. 9  Surajmukhi Path Ghoramara",
                               "Address2": "Kamrup Metro",
                               "Place": "Ghoramara",
                               "City_Id": "10",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5719@kidzee.com",
                               "Mobile_No": "8135951120",
                               "Phone_No1": "8135951120",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Barpeta",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-M-461",
                               "Franchisee_Name": "Kidzee-Barpeta-Assam-Aura Group",
                               "Operating_Status": "A",
                               "Address1": "PO- Patshala,",
                               "Address2": "",
                               "Place": "Pathsala, Dist- Barpeta, Near Balaji College",
                               "City_Id": "120",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee461@kidzee.com",
                               "Mobile_No": "9435123987",
                               "Phone_No1": "9954358442",
                               "Phone_No2": "",
                               "Latitude": "26.330421",
                               "Longitude": "91.004055"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4730",
                               "Franchisee_Name": "KIDZEE SIMLAGURI",
                               "Operating_Status": "A",
                               "Address1": "House No-154, Barpeta Road,",
                               "Address2": "Barpeta Road,",
                               "Place": "Simlaguri, Near Dilip Cinema Hall",
                               "City_Id": "120",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4730@kidzee.com",
                               "Mobile_No": "6002053346",
                               "Phone_No1": "9864152132",
                               "Phone_No2": "",
                               "Latitude": "26.29206",
                               "Longitude": "90.58045"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4261",
                               "Franchisee_Name": "Kidzee Barpeta",
                               "Operating_Status": "A",
                               "Address1": "Village - Metuakuchi,",
                               "Address2": "1st Floor, Patta No-134,",
                               "Place": "Jania Road, Barpeta Town,",
                               "City_Id": "120",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4261@kidzee.com",
                               "Mobile_No": "7002190665",
                               "Phone_No1": "7002190665",
                               "Phone_No2": "",
                               "Latitude": "26.19313",
                               "Longitude": "90.59593"
                            }
                         ]
                      },
                      {
                         "City_Name": "Bongaigaon",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5281",
                            "Franchisee_Name": "Kidzee Abhyapuri",
                            "Operating_Status": "A",
                            "Address1": "abhyapuri Town ward no-3  , near Police station",
                            "Address2": "Dist. Bongaigaon, Revenue Circle, Shri Jangram,",
                            "Place": "Abhyapuri ,Near Police Station",
                            "City_Id": "182",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5281@kidzee.com",
                            "Mobile_No": "9954980959",
                            "Phone_No1": "9954980959",
                            "Phone_No2": "",
                            "Latitude": "26.20330",
                            "Longitude": "90.39289"
                         }
                      },
                      {
                         "City_Name": "Dhemaji"
                      },
                      {
                         "City_Name": "Dhubri"
                      },
                      {
                         "City_Name": "Dibrugarh"
                      },
                      {
                         "City_Name": "Digboi",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5299",
                            "Franchisee_Name": "Kidzee Matri Sanghai",
                            "Operating_Status": "A",
                            "Address1": "Digboi Tiniali Near-Joyram Dairy,",
                            "Address2": "P.O/P.S Digboi, District - Tinsukia,",
                            "Place": "Matri Sangha",
                            "City_Id": "276",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5299@kidzee.com",
                            "Mobile_No": "8473867954",
                            "Phone_No1": "9818719002",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Dispur"
                      },
                      {
                         "City_Name": "Goalpara",
                         "Franchisee": {
                            "Franchisee_Code": "E-A-M-1259",
                            "Franchisee_Name": "Kidzee- Goalpara- Assam- Little Angels",
                            "Operating_Status": "A",
                            "Address1": "J N Road,",
                            "Address2": "Near-P.R Government High School,",
                            "Place": "J N Road, Bapujinagar, Near-Church,",
                            "City_Id": "376",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1259@kidzee.com",
                            "Mobile_No": "9706265823",
                            "Phone_No1": "9706265823",
                            "Phone_No2": "",
                            "Latitude": "26.179875",
                            "Longitude": "90.629701"
                         }
                      },
                      {
                         "City_Name": "Golaghat",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-3584",
                               "Franchisee_Name": "Kidzee Numaligarh",
                               "Operating_Status": "A",
                               "Address1": "Numaligarh Road",
                               "Address2": "Golaghat",
                               "Place": "Numaligarh ,Dhansiri Bridge",
                               "City_Id": "378",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3584@kidzee.com",
                               "Mobile_No": "9435593256",
                               "Phone_No1": "8403961968",
                               "Phone_No2": "",
                               "Latitude": "26.631267002293",
                               "Longitude": "93.7285441160202",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3584/254/3d2e9642_5d70_49b4_9ea9_31d39c07f3bb.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3584/254/e2bab449_4a9f_45fc_8ec1_de0ad429dac7.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3584/254/2321e8ae_a7e7_40f0_a2e0_301ba893665f.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3584/254/2754fdd5_7fc4_4153_8429_5d32801c756c.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3584/254/b27aca92_0508_4acf_8628_5f8fe8c05929.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3584/254/0d06827d_dfa8_466a_9a0c_6a5cdc002635.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3584/254/f70dc8b0_7e12_432a_9b82_473fae7c550c.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3584/254/a36842b3_5f6f_4e20_b9f6_737d341a9155.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3584/254/6a0de8b7_a9cc_4954_86e8_4eb3a24a883b.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3584/254/31df9a64_e35f_4f8b_847d_ece283573ac8.JPG"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-U-C-903",
                               "Franchisee_Name": "KIDZEE-GOLAGHAT- Assam-Little Buds",
                               "Operating_Status": "A",
                               "Address1": "East- Amolapatty, Ward-12,",
                               "Address2": "",
                               "Place": "Old D.T.O.Office, Opp-Hotel Hamdoi,",
                               "City_Id": "378",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee903@kidzee.com",
                               "Mobile_No": "9435053833",
                               "Phone_No1": "280817",
                               "Phone_No2": "",
                               "Latitude": "26.523738",
                               "Longitude": "93.963608"
                            }
                         ]
                      },
                      {
                         "City_Name": "Hojai"
                      },
                      {
                         "City_Name": "Jorhat",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-4902",
                               "Franchisee_Name": "Kidzee Teok",
                               "Operating_Status": "A",
                               "Address1": "Bangali Gaon,",
                               "Address2": "Teok Mouza,",
                               "Place": "Teok, Jyoti Vidyapith,",
                               "City_Id": "510",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4902@kidzee.com",
                               "Mobile_No": "9954178357",
                               "Phone_No1": "9954178357",
                               "Phone_No2": "",
                               "Latitude": "26.50147",
                               "Longitude": "94.25261"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5241",
                               "Franchisee_Name": "Kidzee Jorhat  Milan Nagar",
                               "Operating_Status": "A",
                               "Address1": "Block no.6",
                               "Address2": "Milan Nagar ",
                               "Place": "Jorhat  Milan Nagar",
                               "City_Id": "510",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5241@kidzee.com",
                               "Mobile_No": "9957091614",
                               "Phone_No1": "9957091614",
                               "Phone_No2": "",
                               "Latitude": "26.772363",
                               "Longitude": "94.209052"
                            }
                         ]
                      },
                      {
                         "City_Name": "Kalabari"
                      },
                      {
                         "City_Name": "Karimganj",
                         "Franchisee": {
                            "Franchisee_Code": "E-U-C-582",
                            "Franchisee_Name": "Kidzee- Karimganj- Assam- Little Angels",
                            "Operating_Status": "A",
                            "Address1": "Settlement Road,",
                            "Address2": "Karimganj,",
                            "Place": "Settlement Road,",
                            "City_Id": "538",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "kidzee582@kidzee.com",
                            "Mobile_No": "8876073403",
                            "Phone_No1": "8812920476",
                            "Phone_No2": "",
                            "Latitude": "24.864913",
                            "Longitude": "92.359153"
                         }
                      },
                      {
                         "City_Name": "Kokrajhar"
                      },
                      {
                         "City_Name": "Mangaldoi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-C-500",
                               "Franchisee_Name": "Kidzee- Mangaldai-Assam- Summer Hill",
                               "Operating_Status": "A",
                               "Address1": "Radha Krishna Complex,",
                               "Address2": "Near- ASTC Bus Stand,",
                               "Place": "Darrang, Near Radha Krishna Mandir",
                               "City_Id": "647",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee500@kidzee.com",
                               "Mobile_No": "9435011623",
                               "Phone_No1": "9435011623",
                               "Phone_No2": "",
                               "Latitude": "26.435225",
                               "Longitude": "92.025272"
                            },
                            {
                               "Franchisee_Code": "E-U-C-500",
                               "Franchisee_Name": "Kidzee- Mangaldai-Assam- Summer Hill",
                               "Operating_Status": "A",
                               "Address1": "Radha Krishna Complex,",
                               "Address2": "Near- ASTC Bus Stand,",
                               "Place": "Darrang, Near Radha Krishna Mandir",
                               "City_Id": "647",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee500@kidzee.com",
                               "Mobile_No": "9435011623",
                               "Phone_No1": "9435011623",
                               "Phone_No2": "",
                               "Latitude": "26.435225",
                               "Longitude": "92.025272"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4722",
                               "Franchisee_Name": "Kidzee Mangaldai",
                               "Operating_Status": "A",
                               "Address1": "Baruapara,ward no.7",
                               "Address2": "Telipara",
                               "Place": "Kerimeri Road ,Baruapara",
                               "City_Id": "647",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4722@kidzee.com",
                               "Mobile_No": "6900965760",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "26.27148",
                               "Longitude": "92.0150"
                            }
                         ]
                      },
                      {
                         "City_Name": "Margherita",
                         "Franchisee": {
                            "Franchisee_Code": "E-C-U-178",
                            "Franchisee_Name": "Kidzee- Margherita- Assam- RHEANKITA EDUCARE",
                            "Operating_Status": "A",
                            "Address1": "c/o Rayankica educare, behind NRL energy stn,",
                            "Address2": "Bargoli,Margherita",
                            "Place": "Tinsukia ,Near NRL Energy Station",
                            "City_Id": "654",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "kidzee178@kidzee.com",
                            "Mobile_No": "9101340253",
                            "Phone_No1": "0000000000",
                            "Phone_No2": "",
                            "Latitude": "27.290038",
                            "Longitude": "95.672582"
                         }
                      },
                      {
                         "City_Name": "Mirza"
                      },
                      {
                         "City_Name": "Nagaon"
                      },
                      {
                         "City_Name": "Nalbari",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-4125",
                               "Franchisee_Name": "Kidzee Tihu",
                               "Operating_Status": "A",
                               "Address1": "Tihu town,",
                               "Address2": "District- Nalbari,",
                               "Place": "Tihu, Bimal Maruti Suzuki Showroom,",
                               "City_Id": "707",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4125@kidzee.com",
                               "Mobile_No": "8638241265",
                               "Phone_No1": "8638241265",
                               "Phone_No2": "",
                               "Latitude": "26.2853",
                               "Longitude": "91.1614"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4134",
                               "Franchisee_Name": "Kidzee Barama",
                               "Operating_Status": "A",
                               "Address1": "Village - Kaljar,",
                               "Address2": "Tihu Town, Baksa-Dist,",
                               "Place": "Barama,",
                               "City_Id": "707",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4134@kidzee.com",
                               "Mobile_No": "6003129239",
                               "Phone_No1": "08876047366",
                               "Phone_No2": "",
                               "Latitude": "26.31519",
                               "Longitude": "91.21043"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5700",
                               "Franchisee_Name": "Kidzee Madhabdev Path",
                               "Operating_Status": "A",
                               "Address1": "Nalbari town",
                               "Address2": "Santipur ,Po & Dist Nalbari",
                               "Place": "Madhabdev Path",
                               "City_Id": "707",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5700@kidzee.com",
                               "Mobile_No": "8471821963",
                               "Phone_No1": "8471821963",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "North lakhimpur",
                         "Franchisee": {
                            "Franchisee_Code": "E-U-C-667",
                            "Franchisee_Name": "KIDZEE-NORTH LAKHIMPUR- HAZARIKA ENTERPRISE",
                            "Operating_Status": "A",
                            "Address1": "Opp Panindra Vidyalaya",
                            "Address2": "Panindra Road",
                            "Place": "Ward No.6 Lakhimpur",
                            "City_Id": "744",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee667@kidzee.com",
                            "Mobile_No": "8638733095",
                            "Phone_No1": "8011-086903",
                            "Phone_No2": "",
                            "Latitude": "27.225319",
                            "Longitude": "94.105331"
                         }
                      },
                      {
                         "City_Name": "Panchgram"
                      },
                      {
                         "City_Name": "Patacharkuchi"
                      },
                      {
                         "City_Name": "Pathsala"
                      },
                      {
                         "City_Name": "Silchar",
                         "Franchisee": {
                            "Franchisee_Code": "E-A-M-1184",
                            "Franchisee_Name": "Kidzee- Ambikapatty-Assam- Kids Care Nursery",
                            "Operating_Status": "A",
                            "Address1": "D.S.Lane,",
                            "Address2": "",
                            "Place": "Ambikapatty,",
                            "City_Id": "903",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1184@kidzee.com",
                            "Mobile_No": "9954655397",
                            "Phone_No1": "9101377738",
                            "Phone_No2": "",
                            "Latitude": "24.821364",
                            "Longitude": "92.794647"
                         }
                      },
                      {
                         "City_Name": "Sivasagar",
                         "Franchisee": {
                            "Franchisee_Code": "A-E-A-1044",
                            "Franchisee_Name": "Ankurum  Nazira Sivasagar",
                            "Operating_Status": "A",
                            "Address1": "Nazira Town, Amulapatty",
                            "Address2": "Near Kefai Shoppe, Ward no 7",
                            "Place": "P.S/P.o Nazira",
                            "City_Id": "918",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "khirodneog@rocketmail.com",
                            "Mobile_No": "7002298794",
                            "Phone_No1": "9401934301",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Tezpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-M-505",
                               "Franchisee_Name": "Kidzee- Tezpur- Assam-KIDS EDUTAINMENT",
                               "Operating_Status": "A",
                               "Address1": "Near-Law College,",
                               "Address2": "Opp-",
                               "Place": "Collegate Field ,Near Law College",
                               "City_Id": "952",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee505@kidzee.com",
                               "Mobile_No": "9435082957",
                               "Phone_No1": "9435082957",
                               "Phone_No2": "",
                               "Latitude": "26.652849",
                               "Longitude": "92.792559"
                            },
                            {
                               "Franchisee_Code": "E-S-C-1587",
                               "Franchisee_Name": "Kidzee-Tezpur-Mission Chariali-Little Paradise",
                               "Operating_Status": "A",
                               "Address1": "C/o Balbir Singh  Kidzee Little Paradise,",
                               "Address2": "Mission Charali, Near 4Crops Hq",
                               "Place": "Gotonga ,Near- 4 Corps HQ",
                               "City_Id": "952",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1587@kidzee.com",
                               "Mobile_No": "9435373956",
                               "Phone_No1": "03712-260171",
                               "Phone_No2": "",
                               "Latitude": "26.65734126395323",
                               "Longitude": "92.76310834501646",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1587/34/5bcaeca9_ba44_4c8f_9746_47886335b551.JPG"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/458846/10572/71a56d01_b7cd_4e15_81b5_432cd5cf510f.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4560",
                               "Franchisee_Name": "Kidzee Goraimari",
                               "Operating_Status": "A",
                               "Address1": "Goraimari, NH-52,",
                               "Address2": "Betanijhar, Sonitpur, Haleswar,",
                               "Place": "Goraimari, ,Near-Airforce Station,",
                               "City_Id": "952",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4560@kidzee.com",
                               "Mobile_No": "9706615065",
                               "Phone_No1": "9706-615065",
                               "Phone_No2": "",
                               "Latitude": "26.715560413947173",
                               "Longitude": "92.76572227478016",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2429126/10885/7e3e07ca_2c6c_4b10_9749_84432a819e3b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2429126/10885/bd3c2ba1_e11c_46a4_93b4_b3f7b3535e41.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "A-E-A-1019",
                               "Franchisee_Name": "Ankurum A D Learning",
                               "Operating_Status": "A",
                               "Address1": "sankardev path, ward no .2",
                               "Address2": "near Police Station",
                               "Place": "Rangapara,Sonitpur ,Near Police Station",
                               "City_Id": "952",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4943@kidzee.com",
                               "Mobile_No": "8811072084",
                               "Phone_No1": "8811072084",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Tinsukia"
                      },
                      {
                         "City_Name": "Udalguri / Odalguri"
                      },
                      {
                         "City_Name": "Marigaon",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5724",
                            "Franchisee_Name": "Kidzee Morigaon",
                            "Operating_Status": "A",
                            "Address1": "MB Road Morigoan town",
                            "Address2": "Near Gandhi Bhavan",
                            "Place": "Bonkonwar Nagar",
                            "City_Id": "1126",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5724@kidzee.com",
                            "Mobile_No": "9706553082",
                            "Phone_No1": "9706553082",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Kharupetia",
                         "Franchisee": {
                            "Franchisee_Code": "E-S-C-1646",
                            "Franchisee_Name": "Kidzee-Kharupetia-Assam",
                            "Operating_Status": "A",
                            "Address1": "C/O- Janata Sarkar, In frount of JainMandir,",
                            "Address2": "Ward 6, Mahabir Road, Kharupetia Chaliali,",
                            "Place": "Chariali ,In front of Jain Mandir",
                            "City_Id": "1132",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1646@kidzee.com",
                            "Mobile_No": "9435738097",
                            "Phone_No1": "9435738097",
                            "Phone_No2": "",
                            "Latitude": "26.513165",
                            "Longitude": "92.143918"
                         }
                      },
                      {
                         "City_Name": "Kochpara"
                      },
                      {
                         "City_Name": "Gossaigaon"
                      },
                      {
                         "City_Name": "Lumding"
                      },
                      {
                         "City_Name": "Hailakandi",
                         "Franchisee": {
                            "Franchisee_Code": "E-S-C-2000",
                            "Franchisee_Name": "Kidzee Halikandi",
                            "Operating_Status": "A",
                            "Address1": "Global Public Central School,",
                            "Address2": "Lakhmisahar Ward No-16,",
                            "Place": "Global Public Central School,",
                            "City_Id": "1234",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2000@kidzee.com",
                            "Mobile_No": "9508015432",
                            "Phone_No1": "7002924887",
                            "Phone_No2": "",
                            "Latitude": "24.681102",
                            "Longitude": "92.563805"
                         }
                      },
                      {
                         "City_Name": "Rangia",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3737",
                            "Franchisee_Name": "Kidzee Kamrup (Rural)",
                            "Operating_Status": "A",
                            "Address1": "Dipti nagar",
                            "Address2": "Ward No. 3",
                            "Place": "Kamrup Rangia ,Near Army Camp",
                            "City_Id": "1312",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3737@kidzee.com",
                            "Mobile_No": "9859646926",
                            "Phone_No1": "9859646926",
                            "Phone_No2": "",
                            "Latitude": "26.423484",
                            "Longitude": "91.629635"
                         }
                      },
                      {
                         "City_Name": "Biswanath chariali"
                      },
                      {
                         "City_Name": "Hajo",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5633",
                            "Franchisee_Name": "Kidzee Kalitakuchi",
                            "Operating_Status": "A",
                            "Address1": "House no.12,sewali path",
                            "Address2": "b/l-5,Hatigaon Guwahati,Hatigaon Chariali",
                            "Place": "Kalitakuchi",
                            "City_Id": "1541",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5633@kidzee.com",
                            "Mobile_No": "9854022000",
                            "Phone_No1": "9854022000",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "HOWRAGHAT",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3626",
                            "Franchisee_Name": "Kidzee Karbi Anlong",
                            "Operating_Status": "A",
                            "Address1": "Clirso Global, C/O Jonaki Market Complex",
                            "Address2": "Ward no. 2, P.O- Howraghat, P.S- Howraghat",
                            "Place": "Karbi Anlong ,Jonaki Market Complex",
                            "City_Id": "1627",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3626@kidzee.com",
                            "Mobile_No": "9678381144",
                            "Phone_No1": "7099853749",
                            "Phone_No2": "",
                            "Latitude": "26.116130",
                            "Longitude": "93.034430"
                         }
                      },
                      {
                         "City_Name": "Chirang",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3959",
                            "Franchisee_Name": "Kidzee Chirang",
                            "Operating_Status": "A",
                            "Address1": "G. B.Road, Ward No.03,",
                            "Address2": "P.O. & P.S. Basugaon, BTC",
                            "Place": "Sidli Revenue Circle ,Basugaon Ward No.3",
                            "City_Id": "1640",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3959@kidzee.com",
                            "Mobile_No": "9957451951",
                            "Phone_No1": "8638379026",
                            "Phone_No2": "",
                            "Latitude": "26.463034",
                            "Longitude": "90.419588"
                         }
                      },
                      {
                         "City_Name": "Bijni",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3733",
                            "Franchisee_Name": "Kidzee Amguri",
                            "Operating_Status": "A",
                            "Address1": "Ward no. 3",
                            "Address2": "Ground Floor",
                            "Place": "Amguri ,Bijni",
                            "City_Id": "1645",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3733@kidzee.com",
                            "Mobile_No": "9954849583",
                            "Phone_No1": "9954849583",
                            "Phone_No2": "",
                            "Latitude": "26.4862658887907",
                            "Longitude": "90.7058072090149"
                         }
                      },
                      {
                         "City_Name": "BASUGAON"
                      },
                      {
                         "City_Name": "SONARI"
                      },
                      {
                         "City_Name": "Baksa"
                      },
                      {
                         "City_Name": "Baihata Chariali",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5604",
                            "Franchisee_Name": "Kidzee Baihata Chariali",
                            "Operating_Status": "A",
                            "Address1": "Goreswar Rad,Near Ramanujan Academy",
                            "Address2": "Niz-Madartola, Dist Kamprup",
                            "Place": "Baihata Chariali",
                            "City_Id": "1855",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5604@kidzee.com",
                            "Mobile_No": "7086630817",
                            "Phone_No1": "7086630817",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Bokakhat",
                         "Franchisee": {
                            "Franchisee_Code": "E-G-K-922",
                            "Franchisee_Name": "KIDZEE-GRAMIN-BOKAKHAT, Ayush Kingdom",
                            "Operating_Status": "A",
                            "Address1": "Bishnupur, Bokakhat Town,",
                            "Address2": "W.No-3, P.O & P.S.Bokakhat,",
                            "Place": "Dist.Golaghat, Assam, Near-Indian Oil Petrol Pump,",
                            "City_Id": "1880",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "kidzee922@kidzee.com",
                            "Mobile_No": "9435728844",
                            "Phone_No1": "9435146781",
                            "Phone_No2": "",
                            "Latitude": "26.643430",
                            "Longitude": "93.607076"
                         }
                      },
                      {
                         "City_Name": "BOKAJAN"
                      },
                      {
                         "City_Name": "BARPETA ROAD"
                      },
                      {
                         "City_Name": "Zamartal",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-C-748",
                               "Franchisee_Name": "KIDZEE-MIRJA, SUNBEAM KIDS",
                               "Operating_Status": "A",
                               "Address1": "Saraswati Niwas, (Boys Hostel),",
                               "Address2": "Near-Saraswati Sikhsha Niketan,",
                               "Place": "Mirja, Saraswati Niwas Hostel,",
                               "City_Id": "1927",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee748@kidzee.com",
                               "Mobile_No": "7002685088",
                               "Phone_No1": "9864016846",
                               "Phone_No2": "",
                               "Latitude": "26.097941",
                               "Longitude": "91.53623",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/548/10507/56fbff7a_772f_45c1_8ca8_d3ed989f2979.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-U-C-748",
                               "Franchisee_Name": "KIDZEE-MIRJA, SUNBEAM KIDS",
                               "Operating_Status": "A",
                               "Address1": "Saraswati Niwas, (Boys Hostel),",
                               "Address2": "Near-Saraswati Sikhsha Niketan,",
                               "Place": "Mirja, Saraswati Niwas Hostel,",
                               "City_Id": "1927",
                               "State_Id": "6",
                               "Country_Id": "1",
                               "Email_Id": "kidzee748@kidzee.com",
                               "Mobile_No": "7002685088",
                               "Phone_No1": "9864016846",
                               "Phone_No2": "",
                               "Latitude": "26.097941",
                               "Longitude": "91.53623",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/548/10507/56fbff7a_772f_45c1_8ca8_d3ed989f2979.jpg"
                               }
                            }
                         ]
                      },
                      {
                         "City_Name": "Bilasipara",
                         "Franchisee": {
                            "Franchisee_Code": "A-E-A-1073",
                            "Franchisee_Name": "Ankurum Bilasipara",
                            "Operating_Status": "A",
                            "Address1": "Bigha 1 Katha",
                            "Address2": "Revenue Village ,Surjyakhata Part 3,District :Dhubri",
                            "Place": "Revenue Circle Bilasipara",
                            "City_Id": "1968",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "anantasaha45@gmail.com",
                            "Mobile_No": "8638137175",
                            "Phone_No1": "8638137175",
                            "Phone_No2": "",
                            "Latitude": "26.23881",
                            "Longitude": "90.22758"
                         }
                      },
                      {
                         "City_Name": "SUWALKUCHI"
                      },
                      {
                         "City_Name": "AMINGAON"
                      },
                      {
                         "City_Name": "Gohpur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5687",
                            "Franchisee_Name": "Kidzee Swahid",
                            "Operating_Status": "A",
                            "Address1": "Kushal Konwar Path",
                            "Address2": "Kalyanpur, Near Office of Biswanatha Zilla Parishad, Biswanath Chariali",
                            "Place": "Swahid",
                            "City_Id": "1999",
                            "State_Id": "6",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5687@kidzee.com",
                            "Mobile_No": "8876973601",
                            "Phone_No1": "8876973601",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Mushalpur"
                      },
                      {
                         "City_Name": "Tamulpur"
                      }
                   ]
                },
                {
                   "State_Name": "Bihar",
                   "City": [
                      {
                         "City_Name": "Ara",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-C-U-1144",
                               "Franchisee_Name": "Kidzee- Arah- Bihar- Kids School",
                               "Operating_Status": "A",
                               "Address1": "Patel Nagar, Maulabagh",
                               "Address2": "Near Dr.Isa Pakri Road",
                               "Place": "Ara Bihar ,Next lane to Dr. Isa Clinic",
                               "City_Id": "82",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1144@kidzee.com",
                               "Mobile_No": "9334277055",
                               "Phone_No1": "9470803444",
                               "Phone_No2": "",
                               "Latitude": "25.5601040",
                               "Longitude": "84.6575160",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/855/10620/c2cdbd6a_a676_46fc_bf14_a984e7946032.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-A-M-1407",
                               "Franchisee_Name": "Kidzee- Arah- Bhojpur-Kids School",
                               "Operating_Status": "A",
                               "Address1": "Mohalla Chandwa More,",
                               "Address2": "Near Police Line,",
                               "Place": "Bhojpur District, Near Ambey ITI,",
                               "City_Id": "82",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1407@kidzee.com",
                               "Mobile_No": "9470803444",
                               "Phone_No1": "9470803444",
                               "Phone_No2": "",
                               "Latitude": "25.556044",
                               "Longitude": "84.660331"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5213",
                               "Franchisee_Name": "Kidzee Ramdihara",
                               "Operating_Status": "A",
                               "Address1": "Mouza -Ramdihara ,Ps- Behea ,thana no-84",
                               "Address2": "Salempur,  Bihiya Road",
                               "Place": "Ramdihara ,non operational",
                               "City_Id": "82",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5213@kidzee.com",
                               "Mobile_No": "7061338632",
                               "Phone_No1": "7061338632",
                               "Phone_No2": "",
                               "Latitude": "25.64214040878791",
                               "Longitude": "84.52446285648348",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2770797/11044/61c941ee_8722_42d9_9d8d_7f96f670657a.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-R-C-4723",
                               "Franchisee_Name": "KIDZEE  JAGDISHPUR",
                               "Operating_Status": "A",
                               "Address1": "The Garh Palace,From Bishwakarma mandir",
                               "Address2": "Jhanjhriya Pokhra,Vill + Post-Jagdishpur,Dist-Bhojpur",
                               "Place": "Jagdishpur ,Near by Airtel Tower, Garh Palace",
                               "City_Id": "82",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4723@kidzee.com",
                               "Mobile_No": "7488395107",
                               "Phone_No1": "7488395107",
                               "Phone_No2": "",
                               "Latitude": "25.46620",
                               "Longitude": "84.42003"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3466",
                               "Franchisee_Name": "Kidzee Ara",
                               "Operating_Status": "A",
                               "Address1": "Ahirpurwa,",
                               "Address2": "Dharara,",
                               "Place": "Ara Patna Bypass Road. Near-Milan Garden Resort,",
                               "City_Id": "82",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3466@kidzee.com",
                               "Mobile_No": "9470800000",
                               "Phone_No1": "07633855266",
                               "Phone_No2": "",
                               "Latitude": "25.56745",
                               "Longitude": "84.68539"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5187",
                               "Franchisee_Name": "Kidzee Behea",
                               "Operating_Status": "A",
                               "Address1": "Kateya Road ,Near",
                               "Address2": "Law Kush Restorant",
                               "Place": "Behea",
                               "City_Id": "82",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5187@kidzee.com",
                               "Mobile_No": "9199503010",
                               "Phone_No1": "7004909548",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Begusarai",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-U-624",
                               "Franchisee_Name": "KIDZEE-ULAV-BEGUSARAI- Vatsalya Vihar",
                               "Operating_Status": "A",
                               "Address1": "Town- Ulav, Begusarai, Dist- Begusarai,",
                               "Address2": "",
                               "Place": "Town Ulav, Near- E-2, Mandir, IOC Township,",
                               "City_Id": "129",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee624@kidzee.com",
                               "Mobile_No": "9905884491",
                               "Phone_No1": "6243274246",
                               "Phone_No2": "9204791005",
                               "Latitude": "25.418626",
                               "Longitude": "86.091184"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3261",
                               "Franchisee_Name": "Kidzee Vishnu chowk",
                               "Operating_Status": "A",
                               "Address1": "Opposite-GD College,",
                               "Address2": "Main Road,",
                               "Place": "Vishnu Chowk, Near Vishnu Picture Hall",
                               "City_Id": "129",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3261@kidzee.com",
                               "Mobile_No": "8092600388",
                               "Phone_No1": "8092600388",
                               "Phone_No2": "",
                               "Latitude": "25.416675",
                               "Longitude": "86.129379"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4482",
                               "Franchisee_Name": "Kidzee Teghra",
                               "Operating_Status": "A",
                               "Address1": "Ward No-3, Babu Chandrashekhar, Bindeswari Prasad Singh Market,",
                               "Address2": "Main Road Teghra",
                               "Place": "Near SVR High School, Teghra",
                               "City_Id": "129",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4482@kidzee.com",
                               "Mobile_No": "7991127700",
                               "Phone_No1": "7991-127700",
                               "Phone_No2": "",
                               "Latitude": "25.499001219429378",
                               "Longitude": "85.93191860574339"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5681",
                               "Franchisee_Name": "Kidzee Gharpura",
                               "Operating_Status": "A",
                               "Address1": "In front of Girls High School",
                               "Address2": "Gharpura Bazar",
                               "Place": "Gharpura",
                               "City_Id": "129",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5681@kidzee.com",
                               "Mobile_No": "9110947498",
                               "Phone_No1": "9110947498",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5417",
                               "Franchisee_Name": "Kidzee Barauni",
                               "Operating_Status": "A",
                               "Address1": "Near Sudha Dairy",
                               "Address2": "Shokhara",
                               "Place": "Barauni",
                               "City_Id": "129",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5417@kidzee.com",
                               "Mobile_No": "9430583861",
                               "Phone_No1": "9430583861",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Bettiah",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-G-K-998",
                               "Franchisee_Name": "Kidzee-Bettiah- Bihar-Lovely Kids",
                               "Operating_Status": "A",
                               "Address1": "Kamalnath Nagar,",
                               "Address2": "St.Joseph School Road,",
                               "Place": "Bettiah, Near-St. Joseph School,",
                               "City_Id": "137",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee998@kidzee.com",
                               "Mobile_No": "7004755470",
                               "Phone_No1": "9576101812",
                               "Phone_No2": "",
                               "Latitude": "26.804863",
                               "Longitude": "84.506926"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4869",
                               "Franchisee_Name": "Kidzee Collectorate Chowk Bettiah",
                               "Operating_Status": "A",
                               "Address1": "Asha Dube.W/o-Ramesh Chandra pathak,21",
                               "Address2": "Station chowk pokhra,",
                               "Place": "Ujaain Tola , Bettiah ,near Collectorate Chowk Bettiah",
                               "City_Id": "137",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4869@kidzee.com",
                               "Mobile_No": "9431643184",
                               "Phone_No1": "9431643184",
                               "Phone_No2": "",
                               "Latitude": "26.80017481351346",
                               "Longitude": "84.52673826366663"
                            }
                         ]
                      },
                      {
                         "City_Name": "Bhagalpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-4437",
                               "Franchisee_Name": "Kidzee Aliganj",
                               "Operating_Status": "A",
                               "Address1": "Mohaddinagar,",
                               "Address2": "Mirzanhatt,",
                               "Place": "Aliganj, Near Durga Asthan Bhagalpur,",
                               "City_Id": "141",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4437@kidzee.com",
                               "Mobile_No": "8987595826",
                               "Phone_No1": "8987595826",
                               "Phone_No2": "",
                               "Latitude": "25.228227",
                               "Longitude": "86.980956"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4683",
                               "Franchisee_Name": "Kidzee Sultanganj",
                               "Operating_Status": "A",
                               "Address1": "Pithdouri, Chowk Bazar,",
                               "Address2": "Bhagalpur,",
                               "Place": "Pithdouri, Near-Rajendra Press, Sultanganj",
                               "City_Id": "141",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4683@kidzee.com",
                               "Mobile_No": "8409862510",
                               "Phone_No1": "8409862510",
                               "Phone_No2": "",
                               "Latitude": "25.24846",
                               "Longitude": "86.73819"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5561",
                               "Franchisee_Name": "Kidzee Naugachhia",
                               "Operating_Status": "A",
                               "Address1": "Professor Colony",
                               "Address2": "Makhhatakiya",
                               "Place": "Naugachhia",
                               "City_Id": "141",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "sumanworld2010@gmail.com",
                               "Mobile_No": "9983415274",
                               "Phone_No1": "9983415274",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5727",
                               "Franchisee_Name": "Kidzee Banka",
                               "Operating_Status": "A",
                               "Address1": "Babu Tola",
                               "Address2": "Behind Jail Banka",
                               "Place": "Banka",
                               "City_Id": "141",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5727@kidzee.com",
                               "Mobile_No": "8809238100",
                               "Phone_No1": "8809238100",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Chhapra / Chapra",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5445",
                            "Franchisee_Name": "Kidzee Chapra",
                            "Operating_Status": "A",
                            "Address1": "Shyam Chowk Gudri,",
                            "Address2": "Near RCC state Bank of India",
                            "Place": "Chapra",
                            "City_Id": "201",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5445@kidzee.com",
                            "Mobile_No": "9334219194",
                            "Phone_No1": "9334219194",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Danapur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-A-M-1181",
                               "Franchisee_Name": "Kidzee- Danapur- Bihar- Rajyashree's Kidzone",
                               "Operating_Status": "A",
                               "Address1": "Shriji Nikun Ganga Vihar,(Kidzee-School Bldg),",
                               "Address2": "Raghunathpath,",
                               "Place": "Belly Road, Near Rps More Danapur,",
                               "City_Id": "233",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1181@kidzee.com",
                               "Mobile_No": "9939004697",
                               "Phone_No1": "6123266682",
                               "Phone_No2": "",
                               "Latitude": "25.620667",
                               "Longitude": "85.049325"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4725",
                               "Franchisee_Name": "KIDZEE DANAPUR",
                               "Operating_Status": "A",
                               "Address1": "Pravin Kumar, C/o Anita Sharma, Anandpur Niwas, Plaot No-273,",
                               "Address2": "Vijay Singh yadav Path, Near RKM Palace, Saguna Khagual Main Road, Danapur",
                               "Place": "Khagaul, Vijay Singh Yadav Road",
                               "City_Id": "233",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4725@kidzee.com",
                               "Mobile_No": "9810117403",
                               "Phone_No1": "9810117403",
                               "Phone_No2": "",
                               "Latitude": "25.59763193617389",
                               "Longitude": "85.0408958597717"
                            }
                         ]
                      },
                      {
                         "City_Name": "Darbhanga",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-U-326",
                               "Franchisee_Name": "Kidzee Balbadhrapur",
                               "Operating_Status": "A",
                               "Address1": "Near N P Mishra Chowk",
                               "Address2": "Building S S V Villa , Lehariasarai",
                               "Place": "Balbhadrapur ,Near N P Mishra Chowk ",
                               "City_Id": "235",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee326@kidzee.com",
                               "Mobile_No": "9471642412",
                               "Phone_No1": "7464097665",
                               "Phone_No2": "",
                               "Latitude": "26.121259",
                               "Longitude": "85.905506"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2609",
                               "Franchisee_Name": "Kidzee Darbhanga Bihar",
                               "Operating_Status": "A",
                               "Address1": "Professor’s Colony, Ward No.18,",
                               "Address2": "Holding No.746, Dighi- West,",
                               "Place": "Mirzapur, Darbhanga ,Professor's Colony,",
                               "City_Id": "235",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2609@kidzee.com",
                               "Mobile_No": "7004437711",
                               "Phone_No1": "7004437711",
                               "Phone_No2": "",
                               "Latitude": "26.15035374184555",
                               "Longitude": "85.90342246171576",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/880595/10811/1dde9d99_280e_4a9d_a47b_e670529dc4da.jpg"
                               }
                            }
                         ]
                      },
                      {
                         "City_Name": "Gaya",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-3112",
                               "Franchisee_Name": "Kidzee BodhGaya",
                               "Operating_Status": "A",
                               "Address1": "Mastipur, Ward No-12,",
                               "Address2": "",
                               "Place": "Bodh Gaya, Near Root Institute",
                               "City_Id": "350",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3112@kidzee.com",
                               "Mobile_No": "7870765349",
                               "Phone_No1": "1234-000000",
                               "Phone_No2": "",
                               "Latitude": "24.6887291",
                               "Longitude": "84.9755054"
                            },
                            {
                               "Franchisee_Code": "E-S-C-1722",
                               "Franchisee_Name": "Kidzee Radiance Gaya",
                               "Operating_Status": "A",
                               "Address1": "Narayani Bhawan, A.N. Road",
                               "Address2": "Murarpur",
                               "Place": "Gaya ,Near Murarpur Chowk",
                               "City_Id": "350",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1722@kidzee.com",
                               "Mobile_No": "9334294666",
                               "Phone_No1": "0923-4477666",
                               "Phone_No2": "",
                               "Latitude": "24.80204",
                               "Longitude": "85.011651"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3747",
                               "Franchisee_Name": "Kidzee Belaganj",
                               "Operating_Status": "A",
                               "Address1": "Beladih",
                               "Address2": "Ground floor",
                               "Place": "Belaganj ,Near Thakurbadi, Beladih",
                               "City_Id": "350",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3747@kidzee.com",
                               "Mobile_No": "9472282744",
                               "Phone_No1": "9334062247",
                               "Phone_No2": "",
                               "Latitude": "24.970744",
                               "Longitude": "84.96875",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1646429/10810/1ef3f5ac_baa2_4f7c_a10d_5da0e888a8ba.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1646429/10810/438b428c_a0c8_4364_b801_44c49c566e12.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1646429/10810/dc7e67d4_e8bc_439d_837e_f06c5301e1a2.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1646429/10810/bd80aed1_d9fb_4048_918f_c548a3688f1a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1646429/10810/ac7fc1c6_146d_4eab_bb5d_7d8ea6cc57ec.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-5259",
                               "Franchisee_Name": "Kidzee Khizersarai Fancy Learning LLP",
                               "Operating_Status": "A",
                               "Address1": "Khizersarai Atri main road ",
                               "Address2": "Mauja Bana thana ,Bear By pass",
                               "Place": "Khizersarai ,Near By pass",
                               "City_Id": "350",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5259@kidzee.com",
                               "Mobile_No": "8587099402",
                               "Phone_No1": "8587-099402",
                               "Phone_No2": "",
                               "Latitude": "24.958740601033725",
                               "Longitude": "85.0966590342579",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2769053/11047/8ad9c62c_db00_4b92_9f1a_448fa236d9a9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2769053/11047/21e59013_abc7_4998_936e_1b5112698f4a.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-5391",
                               "Franchisee_Name": "Kidzee Magadh Colony",
                               "Operating_Status": "A",
                               "Address1": "House No-504,",
                               "Address2": "Road No-15,Near Gaya Medical College,",
                               "Place": "Magadh Colony,",
                               "City_Id": "350",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5391@kidzee.com",
                               "Mobile_No": "7658004148",
                               "Phone_No1": "7658004148",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Hajipur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-A-M-1438",
                               "Franchisee_Name": "Kidzee-Hajipur-Anamika Kids",
                               "Operating_Status": "A",
                               "Address1": "Royal Anamika Hotel,",
                               "Address2": "",
                               "Place": "Johri Bazar, Opp. Ganapati Hospital, Near SBI Bank",
                               "City_Id": "416",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1438@kidzee.com",
                               "Mobile_No": "9905767255",
                               "Phone_No1": "9234303181",
                               "Phone_No2": "",
                               "Latitude": "26.035779",
                               "Longitude": "85.148753"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4286",
                               "Franchisee_Name": "Kidzee Hajipur",
                               "Operating_Status": "A",
                               "Address1": "Maa Kunj, Shri Upendra Narayan Singh,",
                               "Address2": "Road No-5, Near-Paswan Chowk,",
                               "Place": "Rajpoot Nagar,",
                               "City_Id": "416",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4286@kidzee.com",
                               "Mobile_No": "9905767255",
                               "Phone_No1": "9905767255",
                               "Phone_No2": "",
                               "Latitude": "25.702000",
                               "Longitude": "85.215600",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2232802/10960/d9b50375_8b01_40f5_b5a2_712127c20c7d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2232802/10960/4e6e6138_8031_4b25_9b33_074bc2cd7cfe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2232802/10960/aae4b342_81df_436d_accd_8f3c64845873.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-5508",
                               "Franchisee_Name": "Kidzee Jadhua",
                               "Operating_Status": "A",
                               "Address1": "Mahnar Road",
                               "Address2": "Ground Floor, Dist Vaishali",
                               "Place": "Jadhua",
                               "City_Id": "416",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5508@kidzee.com",
                               "Mobile_No": "7479555204",
                               "Phone_No1": "7479555204",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5776",
                               "Franchisee_Name": "Kidzee Dighi Kala",
                               "Operating_Status": "A",
                               "Address1": "Chakbigjani",
                               "Address2": "Post-Dighi Kala",
                               "Place": "Dighi Kala",
                               "City_Id": "416",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5776@kidzee.com",
                               "Mobile_No": "7632979136",
                               "Phone_No1": "7632979136",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Lalganj",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4525",
                            "Franchisee_Name": "Kidzee Lalganj",
                            "Operating_Status": "A",
                            "Address1": "At Ward No-4, Bajaji Road, Naka No.2,",
                            "Address2": "Suryana Mandir Road, Thana-Lalganj, Dist-Vaishali,",
                            "Place": "Lalganj Vaishali, Near-Suriyani Mandir,",
                            "City_Id": "615",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4525@kidzee.com",
                            "Mobile_No": "9939293961",
                            "Phone_No1": "9939293961",
                            "Phone_No2": "",
                            "Latitude": "25.8667980",
                            "Longitude": "85.1718290"
                         }
                      },
                      {
                         "City_Name": "Madhubani",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-3134",
                               "Franchisee_Name": "Kidzee Madhubani Patna",
                               "Operating_Status": "A",
                               "Address1": "H/O Late Prof.R.P.Mahaseth,",
                               "Address2": "",
                               "Place": "Near Head Post Office, Near V Mart",
                               "City_Id": "633",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3134@kidzee.com",
                               "Mobile_No": "9798884778",
                               "Phone_No1": "9798884778",
                               "Phone_No2": "",
                               "Latitude": "25.594095",
                               "Longitude": "85.137565"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5562",
                               "Franchisee_Name": "Kidzee Kaluahi",
                               "Operating_Status": "A",
                               "Address1": "Haripur Tole Majarahi",
                               "Address2": "PS Anchal South Kaluahi",
                               "Place": "Kaluahi",
                               "City_Id": "633",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5562@kidzee.com",
                               "Mobile_No": "8527802933",
                               "Phone_No1": "8527802933",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Motihari",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-B-S-703",
                               "Franchisee_Name": "KIDZEE-MOTIHARI- BIHAR- MARVEL KIDS",
                               "Operating_Status": "A",
                               "Address1": "Baluatal,",
                               "Address2": "Motihari,",
                               "Place": "Beside Railway Crossing, Chandmari Chowk,",
                               "City_Id": "680",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee703@kidzee.com",
                               "Mobile_No": "9430938839",
                               "Phone_No1": "9431074213",
                               "Phone_No2": "",
                               "Latitude": "26.651952077689025",
                               "Longitude": "84.90484893321991"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4796",
                               "Franchisee_Name": "Kidzee East Champaran",
                               "Operating_Status": "A",
                               "Address1": "Raghunathpur,Near Pipal Chowk,Colony Road",
                               "Address2": "P.S.Turkauliya, PO Motihari",
                               "Place": "East Champaran ,Gandak Colony",
                               "City_Id": "680",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4796@kidzee.com",
                               "Mobile_No": "9060445700",
                               "Phone_No1": "06252-296677",
                               "Phone_No2": "",
                               "Latitude": "26.639552",
                               "Longitude": "84.893589"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4821",
                               "Franchisee_Name": "KIDZEE SHRI KRISHNA NAGAR",
                               "Operating_Status": "A",
                               "Address1": "Near-Sapahi Devi Mandir,",
                               "Address2": "",
                               "Place": "Shri krishna Nagar, Near-Sapahi Devi Mandir, Shri ",
                               "City_Id": "680",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4821@kidzee.com",
                               "Mobile_No": "9354672887",
                               "Phone_No1": "9354672887",
                               "Phone_No2": "",
                               "Latitude": "26.65110",
                               "Longitude": "84.91607"
                            }
                         ]
                      },
                      {
                         "City_Name": "Muzaffarpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-M-373",
                               "Franchisee_Name": "Kidzee- Muzaffarpur-Bihar- Tirut Educational and Charitable Trust",
                               "Operating_Status": "A",
                               "Address1": "Baligardh Garden,",
                               "Address2": "Near- MDDM College,PO-",
                               "Place": "Ramna ,Near Grand Mall, Muzaffarpur",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee373@kidzee.com",
                               "Mobile_No": "9931705252",
                               "Phone_No1": "0621-22901243",
                               "Phone_No2": "",
                               "Latitude": "26.1094",
                               "Longitude": "85.3956",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/242/10638/4b8c245f_5e76_4d94_abaa_5675d1401160.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/242/10638/ea0c9429_22d0_4d5c_b12f_addb5503195f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/242/10638/869467da_9c9c_4f72_96fe_e853d80049da.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/242/10638/6c9ca5c6_4876_41a6_b6bc_faa16d33c550.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/242/10638/7a1dae9a_1c8e_4c96_b9c5_9208992d8fed.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-A-M-1211",
                               "Franchisee_Name": "Kidzee- Chakkar Road- Muzaffarpur- Muzaffar Kidz School",
                               "Operating_Status": "A",
                               "Address1": "Muzzafarpur Kidzee School,",
                               "Address2": "Rasalpur Zilani,Chakkar Road, Near-Sanjay Cinema,",
                               "Place": "Chakkar Maidan ,Muzzafarpur Kidzee School, Rasalp,",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1211@kidzee.com",
                               "Mobile_No": "9204056492",
                               "Phone_No1": "6212260243",
                               "Phone_No2": "",
                               "Latitude": "26.120888",
                               "Longitude": "85.36472"
                            },
                            {
                               "Franchisee_Code": "E-A-M-1271",
                               "Franchisee_Name": "Kidzee- Sikandarpur- Muzaffarpur- Rose Buds",
                               "Operating_Status": "A",
                               "Address1": "C/O- Mr. Praveen Tiwari,",
                               "Address2": "Main Road-",
                               "Place": "Sikandarpur, Near-Rani Sati Mandir,",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1271@kidzee.com",
                               "Mobile_No": "9431239023",
                               "Phone_No1": "06212210483",
                               "Phone_No2": "",
                               "Latitude": "26.130042",
                               "Longitude": "85.384858"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3087",
                               "Franchisee_Name": "Kidzee Ahiyapur  Muzaaffarpur",
                               "Operating_Status": "A",
                               "Address1": "Khata No-613,Kasra No-580,",
                               "Address2": "",
                               "Place": "Ahiyapur, Near Vishwakarma Dharmkanta",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3087@kidzee.com",
                               "Mobile_No": "9431239023",
                               "Phone_No1": "0621-0000000",
                               "Phone_No2": "",
                               "Latitude": "26.148553",
                               "Longitude": "85.399324"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2835",
                               "Franchisee_Name": "KBUNL EDUCATIONAL TRUST  KANTI   MUZAFFARPUR",
                               "Operating_Status": "A",
                               "Address1": "D-16 /1 / 4,  MTPS, PO-Kanti Thermal Dist-",
                               "Address2": "",
                               "Place": "Kanti, Muzaffarpur,Inside NTPC Campus",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2835@kidzee.com",
                               "Mobile_No": "9304234836",
                               "Phone_No1": "9934602476",
                               "Phone_No2": "",
                               "Latitude": "26.120888",
                               "Longitude": "85.36472"
                            },
                            {
                               "Franchisee_Code": "E-S-C-2092",
                               "Franchisee_Name": "Kidzee Muzaffarpur",
                               "Operating_Status": "A",
                               "Address1": "Near Sanjay Cinema",
                               "Address2": "Barampura Damodarpur Road",
                               "Place": "Barampura Damodarpur Road, Near Sanjay Cinema Hall",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2092@kidzee.com",
                               "Mobile_No": "9204056493",
                               "Phone_No1": "9835455551",
                               "Phone_No2": "",
                               "Latitude": "26.120888",
                               "Longitude": "85.36472"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3844",
                               "Franchisee_Name": "Kidzee Ramdayalu Nagar",
                               "Operating_Status": "A",
                               "Address1": "Bhikhanpura,",
                               "Address2": "Hajipur Road",
                               "Place": "Ramdayalu Nagar ,Opp. Malang Asthan, Near Atithi H",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3844@kidzee.com",
                               "Mobile_No": "8651519154",
                               "Phone_No1": "8651519154",
                               "Phone_No2": "",
                               "Latitude": "26.085028",
                               "Longitude": "85.380198"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3562",
                               "Franchisee_Name": "Kidzee Damodarpur",
                               "Operating_Status": "A",
                               "Address1": "Vishnu Vihar Appartment Lane,",
                               "Address2": "",
                               "Place": "Bairiya, Jaipraksh Nagar,",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3562@kidzee.com",
                               "Mobile_No": "7635093388",
                               "Phone_No1": "0621-261067",
                               "Phone_No2": "",
                               "Latitude": "26.145840998754007",
                               "Longitude": "85.35597824732974"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3562",
                               "Franchisee_Name": "Kidzee Damodarpur",
                               "Operating_Status": "A",
                               "Address1": "Vishnu Vihar Appartment Lane,",
                               "Address2": "",
                               "Place": "Bairiya, Jaipraksh Nagar,",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3562@kidzee.com",
                               "Mobile_No": "7635093388",
                               "Phone_No1": "0621-261067",
                               "Phone_No2": "",
                               "Latitude": "26.144725",
                               "Longitude": "85.359272"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4660",
                               "Franchisee_Name": "Kidzee Bhikhanpur",
                               "Operating_Status": "A",
                               "Address1": "Bhikhanpur, East of NH-77, Near SKMCH Medical College,",
                               "Address2": "Muzaffarpur, P.O.Bhikhanpur,",
                               "Place": "Bhikhanpur ,Near SKMCH",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4660@kidzee.com",
                               "Mobile_No": "8252293595",
                               "Phone_No1": "8252293595",
                               "Phone_No2": "",
                               "Latitude": "26.176791",
                               "Longitude": "85.398843"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4660",
                               "Franchisee_Name": "Kidzee Bhikhanpur",
                               "Operating_Status": "A",
                               "Address1": "Bhikhanpur, East of NH-77, Near SKMCH Medical College,",
                               "Address2": "Muzaffarpur, P.O.Bhikhanpur,",
                               "Place": "Bhikhanpur ,Near SKMCH",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4660@kidzee.com",
                               "Mobile_No": "8252293595",
                               "Phone_No1": "8252293595",
                               "Phone_No2": "",
                               "Latitude": "26.176791",
                               "Longitude": "85.398843"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4752",
                               "Franchisee_Name": "Kidzee Sherpur",
                               "Operating_Status": "A",
                               "Address1": "Er. K B Tiwary,house Name-Shriwar Dwellings,Majhouli Dharamdash",
                               "Address2": "Near Gandak Project Office,OPP Ranjit Singh cold storage,PO MIC Bela,Distt",
                               "Place": "Sherpur ,Near Ranjeet Singh cold store kacchi pakki",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4752@kidzee.com",
                               "Mobile_No": "9297517231",
                               "Phone_No1": "9135670078",
                               "Phone_No2": "",
                               "Latitude": "26.100402",
                               "Longitude": "85.391884"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5244",
                               "Franchisee_Name": "KIDZEE KANHAULI",
                               "Operating_Status": "A",
                               "Address1": "Malang Sthan , Bari Kotiya",
                               "Address2": "KANHAULI Bishundat",
                               "Place": "Kanhauli",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5244@kidzee.com",
                               "Mobile_No": "8603144751",
                               "Phone_No1": "6201080881",
                               "Phone_No2": "",
                               "Latitude": "26.13879",
                               "Longitude": "85.35684"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4435",
                               "Franchisee_Name": "Kidzee Kanti",
                               "Operating_Status": "A",
                               "Address1": "Kanti Kasava,",
                               "Address2": "AT+PO-",
                               "Place": "Kanti, Muzaffarpur , Kanti High School Road,",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4435@kidzee.com",
                               "Mobile_No": "9304234836",
                               "Phone_No1": "9934602476",
                               "Phone_No2": "",
                               "Latitude": "26.21846",
                               "Longitude": "85.30097"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4689",
                               "Franchisee_Name": "Kidzee Gobarsahi",
                               "Operating_Status": "A",
                               "Address1": "Prabhat Nagar,Purbi Road no 01",
                               "Address2": "PS Sadar gobarsahi,",
                               "Place": "Gobarsahi ,non operational",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3113@kidzee.com",
                               "Mobile_No": "9470711101",
                               "Phone_No1": "9308711101",
                               "Phone_No2": "",
                               "Latitude": "26.11485",
                               "Longitude": "85.35870"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5609",
                               "Franchisee_Name": "Kidzee Motipur",
                               "Operating_Status": "A",
                               "Address1": "Mahna Bazar",
                               "Address2": "",
                               "Place": "MOTIPUR",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5609@kidzee.com",
                               "Mobile_No": "8210214073",
                               "Phone_No1": "8210214073",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5742",
                               "Franchisee_Name": "Kidzee Khabra",
                               "Operating_Status": "A",
                               "Address1": "Khabra Road Lane no-4,",
                               "Address2": "Kalambag Chowk,",
                               "Place": "Khabra",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5742@kidzee.com",
                               "Mobile_No": "8507855981",
                               "Phone_No1": "8507855981",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5574",
                               "Franchisee_Name": "KIDZEE NARSANDA",
                               "Operating_Status": "A",
                               "Address1": "KANTI ROAD",
                               "Address2": "NEAR NARSANDA FLY OVER",
                               "Place": "NARSANDA",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5574@kidzee.com",
                               "Mobile_No": "9150686868",
                               "Phone_No1": "9150686868",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5544",
                               "Franchisee_Name": "Kidzee Marwan",
                               "Operating_Status": "A",
                               "Address1": "Village-Bagahi ,Panchayat-Mahmadpur Subey",
                               "Address2": "Anchal - Marwan",
                               "Place": "Marwan",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5544@kidzee.com",
                               "Mobile_No": "9905013004",
                               "Phone_No1": "9905013004",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5568",
                               "Franchisee_Name": "Kidzee  Kanti",
                               "Operating_Status": "A",
                               "Address1": "KANTI KASBA",
                               "Address2": "KANTI NARSANDA ROAD",
                               "Place": "KANTI",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5568@kidzee.com",
                               "Mobile_No": "9771123146",
                               "Phone_No1": "9771123146",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5593",
                               "Franchisee_Name": "Kidzee Mahnar",
                               "Operating_Status": "A",
                               "Address1": "Kharjamma Ward -4",
                               "Address2": "Dist. Vaishali, , P.S & Anchal Manhar",
                               "Place": "Mahnar",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5593@kidzee.com",
                               "Mobile_No": "9546081857",
                               "Phone_No1": "9546081857",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5621",
                               "Franchisee_Name": "Kidzee Paigambarpur",
                               "Operating_Status": "A",
                               "Address1": "Kolhua, PS Abhaypuri,",
                               "Address2": "Sub Registry Office Motipur, Munsifi",
                               "Place": "Paigambarpur",
                               "City_Id": "689",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5621@kidzee.com",
                               "Mobile_No": "7033918019",
                               "Phone_No1": "7033918019",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Nalanda",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2022",
                               "Franchisee_Name": "Kidzee-Tiny Tulips",
                               "Operating_Status": "A",
                               "Address1": "Shivpuri, Palika Bazar,",
                               "Address2": "Ranchi Road,",
                               "Place": "Nalanda, Opp.Lane To Jeevan Jyoti Superspeciality ",
                               "City_Id": "706",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2022@kidzee.com",
                               "Mobile_No": "7250771253",
                               "Phone_No1": "9973606801",
                               "Phone_No2": "",
                               "Latitude": "25.201432690993236",
                               "Longitude": "85.5158028064194"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2815",
                               "Franchisee_Name": "Kidzee Rajgir  Bihar",
                               "Operating_Status": "A",
                               "Address1": "Rajgir-Bihar Road,",
                               "Address2": "PO-Rajgir,",
                               "Place": "Dist-Nalanda, Near-Panditpur, Rajgir,",
                               "City_Id": "706",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee2815@kidzee.com",
                               "Mobile_No": "9430098947",
                               "Phone_No1": "9430098947",
                               "Phone_No2": "",
                               "Latitude": "25.017257",
                               "Longitude": "85.416164"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3504",
                               "Franchisee_Name": "Kidzee Manglasthan Biharshariff",
                               "Operating_Status": "A",
                               "Address1": "Bharat Gas Godown Road,",
                               "Address2": "Bimla Mandir,",
                               "Place": "Manglasthan Biharshariff, Near Bharat Gas Godown,",
                               "City_Id": "706",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3504@kidzee.com",
                               "Mobile_No": "9386357390",
                               "Phone_No1": "9386357390",
                               "Phone_No2": "",
                               "Latitude": "25.191298266548117",
                               "Longitude": "85.50797059059141"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4582",
                               "Franchisee_Name": "Kidzee Parwalpur",
                               "Operating_Status": "A",
                               "Address1": "Chhatarpur Road",
                               "Address2": "Po+Ps-Parwalpur,",
                               "Place": "Parwalpur",
                               "City_Id": "706",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4582@kidzee.com",
                               "Mobile_No": "9386597887",
                               "Phone_No1": "9386597887",
                               "Phone_No2": "",
                               "Latitude": "25.20890",
                               "Longitude": "85.36452"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5352",
                               "Franchisee_Name": "Kidzee Silao",
                               "Operating_Status": "A",
                               "Address1": "Nona,Surajpur",
                               "Address2": "P.O Nalanda, Block Silao-Dist-Nalanda",
                               "Place": "Silao",
                               "City_Id": "706",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5352@kidzee.com",
                               "Mobile_No": "9234271405",
                               "Phone_No1": "9234271405",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5344",
                               "Franchisee_Name": "KIDZEE SOSARAI",
                               "Operating_Status": "A",
                               "Address1": "NTO MORA TALAO",
                               "Address2": "SOSARAI",
                               "Place": "BIHAR SHARIF",
                               "City_Id": "706",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5344@kidzee.com",
                               "Mobile_No": "9473196900",
                               "Phone_No1": "9473196900",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-3984",
                               "Franchisee_Name": "Kidzee Hilsa",
                               "Operating_Status": "A",
                               "Address1": "Hilsa,",
                               "Address2": "Ground Floor,",
                               "Place": "Hilsa, Opp-Lane Of Maya Medico, Station Road,",
                               "City_Id": "706",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3984@kidzee.com",
                               "Mobile_No": "7766917745",
                               "Phone_No1": "9939288558",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Patna",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-B-N-206",
                               "Franchisee_Name": "Kidzee-Kankarbagh/LITTLE ANGEL",
                               "Operating_Status": "A",
                               "Address1": "A-56, P.C. Colony,",
                               "Address2": "Kankarbagh",
                               "Place": "Kankarbagh ,A/56, PC Colony, Kankarbagh,  Srinivas",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee206@kidzee.com",
                               "Mobile_No": "9693743658",
                               "Phone_No1": "9693743658",
                               "Phone_No2": "",
                               "Latitude": "25.602212",
                               "Longitude": "85.15798"
                            },
                            {
                               "Franchisee_Code": "E-B-U-77",
                               "Franchisee_Name": "Kidzee- Rajendra Nagar- Patna-KIDZ ERA",
                               "Operating_Status": "A",
                               "Address1": "KIdz Era Kidzee,P.P.Path, Rajendra Nagar,",
                               "Address2": "Opposite Telephone Exchange,Patna. ",
                               "Place": "Rajendra Nagar ,Near Moinul Haque Stadium",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee77@kidzee.com",
                               "Mobile_No": "9308662771",
                               "Phone_No1": "9934218131",
                               "Phone_No2": "",
                               "Latitude": "25.606273",
                               "Longitude": "85.165055"
                            },
                            {
                               "Franchisee_Code": "E-B-N-132",
                               "Franchisee_Name": "Kidzee- Canal Road- Patna-KID'S DEN",
                               "Operating_Status": "A",
                               "Address1": "Dahara House,",
                               "Address2": "East Boring canal road ",
                               "Place": "East Boring Canal Road ,Opp. Harilal Sweets Boring",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee132@kidzee.com",
                               "Mobile_No": "9334110068",
                               "Phone_No1": "9334110068",
                               "Phone_No2": "",
                               "Latitude": "25.615935",
                               "Longitude": "85.120699"
                            },
                            {
                               "Franchisee_Code": "E-U-U-479",
                               "Franchisee_Name": "Kidzee-Patliputra/Kid's Den Senior",
                               "Operating_Status": "A",
                               "Address1": "198-",
                               "Address2": "Pataliputra Colony,",
                               "Place": "Opp- Lane To Sahyog Hospital",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee479@kidzee.com",
                               "Mobile_No": "9334110068",
                               "Phone_No1": "9934085449",
                               "Phone_No2": "",
                               "Latitude": "25.627595",
                               "Longitude": "85.108032"
                            },
                            {
                               "Franchisee_Code": "E-U-U-716",
                               "Franchisee_Name": "KIDZEE-ASHIANA ROAD- Patna- MARRY GOLD",
                               "Operating_Status": "A",
                               "Address1": "Z-15,",
                               "Address2": "Phase-1,",
                               "Place": "Ashiana Nagar, Near SBI Bank,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee716@kidzee.com",
                               "Mobile_No": "9835288785",
                               "Phone_No1": "9931448817",
                               "Phone_No2": "",
                               "Latitude": "25.594095",
                               "Longitude": "85.137565"
                            },
                            {
                               "Franchisee_Code": "E-S-C-1550",
                               "Franchisee_Name": "Kidzee-Jakkanpur-Patna",
                               "Operating_Status": "A",
                               "Address1": "Jai Prakash Nagar, G.P.O., P.G.Line Road, Near Telephone Exchange (North)",
                               "Address2": "\" AYUSHEE ENCLAVE \"",
                               "Place": "Jakkanpur ,Tinpulwa Kadam gali",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1550@kidzee.com",
                               "Mobile_No": "9934773538",
                               "Phone_No1": "022-40343900",
                               "Phone_No2": "",
                               "Latitude": "25.58689093330581",
                               "Longitude": "85.12941108459472"
                            },
                            {
                               "Franchisee_Code": "E-C-U-1148",
                               "Franchisee_Name": "Kidzee- Chamdoria- Qickstep Ayaan",
                               "Operating_Status": "A",
                               "Address1": "Shiv Bhawan Shiv",
                               "Address2": "Narayan Road",
                               "Place": "Chamdoria Quila Road ,200 mtrs East of Takht Shri Harmandir Saheb",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1148@kidzee.com",
                               "Mobile_No": "7488543433",
                               "Phone_No1": "7909086928",
                               "Phone_No2": "",
                               "Latitude": "25.595552776864682",
                               "Longitude": "85.23360167356384"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2218",
                               "Franchisee_Name": "Kidzee Beur. Mahavir Colony",
                               "Operating_Status": "A",
                               "Address1": "S.K. Vihar Colony",
                               "Address2": "Near Beur Jail Anisabad",
                               "Place": "S.K. Vihar Colony, mahavir colony, near mahavir ma",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2218@kidzee.com",
                               "Mobile_No": "8825123911",
                               "Phone_No1": "07279874190",
                               "Phone_No2": "",
                               "Latitude": "25.594095",
                               "Longitude": "85.137565"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2296",
                               "Franchisee_Name": "Kidzee Sandalpur Patna",
                               "Operating_Status": "A",
                               "Address1": "B3, PATNA UNIVERSITY PROF. COLONY,",
                               "Address2": "Bajrangpuri,",
                               "Place": "Sandalpur, Near Gaighat Toll Tax Barrier",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee2296@Kidzee.com",
                               "Mobile_No": "7352707770",
                               "Phone_No1": "0612-234556",
                               "Phone_No2": "",
                               "Latitude": "25.503173",
                               "Longitude": "85.174798",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/752322/10700/f275b1f7_6039_476c_9553_49fcfe0199d8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/752322/10700/e0051fd2_7fe6_4a8c_983b_86602bdf8c9a.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-2436",
                               "Franchisee_Name": "Kidzee Gola Road",
                               "Operating_Status": "A",
                               "Address1": "Gola Road,",
                               "Address2": "Chanakyapuri - Sanik Colony,",
                               "Place": "Gola Road, Near Prachin Hanuman Mandir,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2436@kidzee.com",
                               "Mobile_No": "9934012907",
                               "Phone_No1": "9934012907",
                               "Phone_No2": "",
                               "Latitude": "25.620667",
                               "Longitude": "85.049325"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2587",
                               "Franchisee_Name": "Kidzee- PATNA",
                               "Operating_Status": "A",
                               "Address1": "East Of Golki More, Near- Shiv Mandir,",
                               "Address2": "PO-Manoharpur Kachhuara, PS-",
                               "Place": "Ramkrishna Nagar, Khemnichak Main Road, Near- Golk",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2587@kidzee.com",
                               "Mobile_No": "8936023911",
                               "Phone_No1": "882-5123911",
                               "Phone_No2": "",
                               "Latitude": "25.57491407633232",
                               "Longitude": "85.15854173831178",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/912357/10492/06aa3af0_0110_4785_a00a_ae91f434732f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/912357/10492/bd59443e_3bab_4fe8_bcc7_10281e0a0804.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/912357/10492/d24c458f_acc3_46ea_8ff6_15879a3473d5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/912357/10492/d4a36d38_7393_4b77_8a45_848097b6c3a3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/912357/10492/dc4cfdbb_1fd7_4e5c_84e5_b872be79f45e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/912357/10492/bada1524_90f0_4d2a_8da1_999370ccdf1c.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3044",
                               "Franchisee_Name": "Kidzee Sadhanapuri Patna",
                               "Operating_Status": "A",
                               "Address1": "Ramjanam Bhawan,  A/20,MC Lane,",
                               "Address2": "6D-Road,",
                               "Place": "Sadhanapuri, Near SBI Bank Gardanibagh,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3044@kidzee.com",
                               "Mobile_No": "9835096092",
                               "Phone_No1": "9835096092",
                               "Phone_No2": "",
                               "Latitude": "25.588217",
                               "Longitude": "85.116166"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3043",
                               "Franchisee_Name": "Kidzee Rajiv Nagar Patna",
                               "Operating_Status": "A",
                               "Address1": "Road No-6,",
                               "Address2": "House No-262, Ps: Rajiv Nagar, PO: Keshri Nagar,",
                               "Place": "Rajiv Nagar ,Road No. 6  , Dead End,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3043@kidzee.com",
                               "Mobile_No": "9835374777",
                               "Phone_No1": "9835374777",
                               "Phone_No2": "",
                               "Latitude": "25.626988",
                               "Longitude": "85.100913"
                            },
                            {
                               "Franchisee_Code": "E-U-U-818",
                               "Franchisee_Name": "KIDZEE-BHOOTNATH ROAD, ANAND KIDS",
                               "Operating_Status": "A",
                               "Address1": "Bahadur Housing Colony,",
                               "Address2": "Plot-No- 4H/9,",
                               "Place": "Bhootnath Road, Near-Swagat Banket Hall,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee818@kidzee.com",
                               "Mobile_No": "9934409234",
                               "Phone_No1": "06123299297",
                               "Phone_No2": "",
                               "Latitude": "25.593525",
                               "Longitude": "85.173886"
                            },
                            {
                               "Franchisee_Code": "E-U-U-822",
                               "Franchisee_Name": "KIDZEE-SORANGPUR- Patna- KIDS CARRER",
                               "Operating_Status": "A",
                               "Address1": "Ramkrishna Nagar, Swarmpura,",
                               "Address2": "Nr.Ramlakhan Path, Dhaneshwari Market,",
                               "Place": "East-Ramkrishna Nagar ,New Bypass Road, Ram Lakhan",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee822@kidzee.com",
                               "Mobile_No": "8298446428",
                               "Phone_No1": "9304644805",
                               "Phone_No2": "",
                               "Latitude": "25.594095",
                               "Longitude": "85.137565"
                            },
                            {
                               "Franchisee_Code": "E-S-C-1681",
                               "Franchisee_Name": "Kidzee-Ranigath-Patna",
                               "Operating_Status": "A",
                               "Address1": "Professor Colony,",
                               "Address2": "Mahendru,",
                               "Place": "Ranigath, Near-Dr. Arun Tiwari Clinic,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1681@kidzee.com",
                               "Mobile_No": "9308766651",
                               "Phone_No1": "09576613716",
                               "Phone_No2": "",
                               "Latitude": "25.594095",
                               "Longitude": "85.137565"
                            },
                            {
                               "Franchisee_Code": "E-R-C-1931",
                               "Franchisee_Name": "Kidzee Phulwari Sharif Patna",
                               "Operating_Status": "A",
                               "Address1": "House No-114,",
                               "Address2": "F.C.I Road, Sanskri Bhawan Nearr-Rangbumi Maidan,",
                               "Place": "Near-Phulwari Sharif High School,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1931@kidzee.com",
                               "Mobile_No": "9934409234",
                               "Phone_No1": "9304268003",
                               "Phone_No2": "",
                               "Latitude": "25.574127",
                               "Longitude": "85.0701"
                            },
                            {
                               "Franchisee_Code": "E-U-C-873",
                               "Franchisee_Name": "KIDZEE- Baily Road- SDM Educational & Welfare Trust",
                               "Operating_Status": "A",
                               "Address1": "185 /133 A, Post. Off Road,",
                               "Address2": "Behind-J.D.Women's College,",
                               "Place": "Baily Road, Near-JD Women College,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee873@kidzee.com",
                               "Mobile_No": "9334704634",
                               "Phone_No1": "8084712345",
                               "Phone_No2": "",
                               "Latitude": "25.605808",
                               "Longitude": "85.096745"
                            },
                            {
                               "Franchisee_Code": "E-R-C-1896",
                               "Franchisee_Name": "Vijay Nagar Patna",
                               "Operating_Status": "A",
                               "Address1": "C/O-Prabhakar Singh,",
                               "Address2": "Lane No.1-C,",
                               "Place": "Shivpuram, Near-Sai Corporate Rukanpura, Bailey Ro",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1896@kidzee.com",
                               "Mobile_No": "9939882762",
                               "Phone_No1": "000-0000",
                               "Phone_No2": "",
                               "Latitude": "25.6044090858997",
                               "Longitude": "85.1471543312073"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2586",
                               "Franchisee_Name": "KIDZEE BIHTA",
                               "Operating_Status": "A",
                               "Address1": "Opposite GJ College, Near-Bharat Gas Godown,",
                               "Address2": "Dumri Road,",
                               "Place": "Bihta, Opposite GJ College,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2586@kidzee.com",
                               "Mobile_No": "8987308193",
                               "Phone_No1": "9162452049",
                               "Phone_No2": "",
                               "Latitude": "25.563323",
                               "Longitude": "84.869808"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2694",
                               "Franchisee_Name": "Kidzee-BARH, Patna",
                               "Operating_Status": "A",
                               "Address1": "Ward No.4, Suleman Dargah,",
                               "Address2": "Muhalla Wajidpur,",
                               "Place": "Barh, Patna , Near-S.B.R Chowk, Opp. Church School",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2694@kidzee.com",
                               "Mobile_No": "8409907915",
                               "Phone_No1": "8409907915",
                               "Phone_No2": "",
                               "Latitude": "25.594095",
                               "Longitude": "85.137565"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3547",
                               "Franchisee_Name": "Kidzee Kudos",
                               "Operating_Status": "A",
                               "Address1": "Arpana Bank Colony, Danapur Cantt,",
                               "Address2": "House No-128, Road No-4,Tata Ion Exam Center,",
                               "Place": "Danapur Patna, Near-Dr. B R Ambedkar Dental Colleg",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3547@kidzee.com",
                               "Mobile_No": "9973470001",
                               "Phone_No1": "9973-470001",
                               "Phone_No2": "",
                               "Latitude": "25.6076260654258",
                               "Longitude": "85.0576865673065",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1608699/10628/fdb221ec_c999_4008_8cfe_b81322af69f6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1608699/10628/f43ce065_4a4d_4339_b744_e2549b4fdbdd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1608699/10628/7a2b2e37_c47e_4c6a_b4fa_19708a393b44.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1608699/10628/ff955b92_3429_4685_9144_c7f11ef7b8cd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1608699/10628/bf4cf658_d186_492a_99b7_698389d8592c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1608699/10628/9168ab6b_9cf2_451b_95dc_1d093dc46764.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1608699/10628/4481e7ec_da9d_4ac8_ada2_59cf18780ad3.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3803",
                               "Franchisee_Name": "Kidzee Chhoti Pahari",
                               "Operating_Status": "A",
                               "Address1": "Doctor's Colony,",
                               "Address2": "agamkuan",
                               "Place": "Chhoti Pahari ,near Arogyam Surgical and Troma roa",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3803@kidzee.com",
                               "Mobile_No": "9771415062",
                               "Phone_No1": "0612-000000",
                               "Phone_No2": "",
                               "Latitude": "25.588277",
                               "Longitude": "85.199489",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/f3f04361_6a02_4435_a14e_0f5798870228.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/5a968fe6_bdfc_49dd_b1ec_6390c7152f29.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/5b656506_7044_48c9_b4c1_5c4d5c9ab956.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/3f5b2f48_7893_4972_a15d_d5e15b3e4b9f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/2d444967_cabc_4678_8916_86190e360c17.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/ced71064_fa98_4351_b1e6_3c026f2bb177.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/fcd351a8_325c_49f8_8587_e21e79ecc903.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/7b1120ce_cf9b_4e8b_8be2_af93915b12fc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/0ace6d9a_3f35_4a0d_98a1_9ba53d1d4e41.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/949f822d_83c3_4446_ab15_ba667642153e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/f59e6fba_8840_498a_98b1_33afe63df730.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/f02ac009_f1f8_4e16_893c_a9f32f3ff6b9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/8c03cbb1_df6b_4865_99d4_a1eabd21df14.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/b44b7f56_225c_4f63_998b_ffb209c414d3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/917a214b_4f5f_436e_ae5e_23b72f39897a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/37a095cc_f43a_4630_9839_df3f728184b5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/a9c3eb47_49c1_40df_84e7_435bbed88962.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/36bc7d76_0a96_4154_8672_e16d98ca7825.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/3d43379e_f570_4e11_88ab_6e07aeb266f2.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3803",
                               "Franchisee_Name": "Kidzee Chhoti Pahari",
                               "Operating_Status": "A",
                               "Address1": "Doctor's Colony,",
                               "Address2": "agamkuan",
                               "Place": "Chhoti Pahari ,near Arogyam Surgical and Troma roa",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3803@kidzee.com",
                               "Mobile_No": "9771415062",
                               "Phone_No1": "0612-000000",
                               "Phone_No2": "",
                               "Latitude": "25.58819958748192",
                               "Longitude": "85.19933879629514",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/f3f04361_6a02_4435_a14e_0f5798870228.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/5a968fe6_bdfc_49dd_b1ec_6390c7152f29.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/5b656506_7044_48c9_b4c1_5c4d5c9ab956.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/3f5b2f48_7893_4972_a15d_d5e15b3e4b9f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/2d444967_cabc_4678_8916_86190e360c17.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/ced71064_fa98_4351_b1e6_3c026f2bb177.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/fcd351a8_325c_49f8_8587_e21e79ecc903.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/7b1120ce_cf9b_4e8b_8be2_af93915b12fc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/0ace6d9a_3f35_4a0d_98a1_9ba53d1d4e41.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/949f822d_83c3_4446_ab15_ba667642153e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/f59e6fba_8840_498a_98b1_33afe63df730.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/f02ac009_f1f8_4e16_893c_a9f32f3ff6b9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/8c03cbb1_df6b_4865_99d4_a1eabd21df14.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/b44b7f56_225c_4f63_998b_ffb209c414d3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/917a214b_4f5f_436e_ae5e_23b72f39897a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/37a095cc_f43a_4630_9839_df3f728184b5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/a9c3eb47_49c1_40df_84e7_435bbed88962.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/36bc7d76_0a96_4154_8672_e16d98ca7825.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1826323/10465/3d43379e_f570_4e11_88ab_6e07aeb266f2.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3628",
                               "Franchisee_Name": "Kidzee Chitragupt Nagar",
                               "Operating_Status": "A",
                               "Address1": "Devyan",
                               "Address2": "Behind Sanjeevani Hospital, Kankarbagh",
                               "Place": "Chitragupt Nagar ( Kankarbagh ) ,Behind Sanjeevani",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3628@kidzee.com",
                               "Mobile_No": "9031020000",
                               "Phone_No1": "9031-020000",
                               "Phone_No2": "",
                               "Latitude": "25.594855866108038",
                               "Longitude": "85.16416426231842",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/d19e1197_7cb8_45e1_b01f_7fad99d176a1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/46b24499_2267_4dde_9bba_a1b81e0586df.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/26948dd2_1171_4815_bdeb_3095cf327de5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/9ec0ee94_f0bb_466c_948c_919fd8b6418c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/7e651b0f_3c07_4da9_9139_5d55ca8e2870.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/50e36d78_c066_424c_b501_347fc78c5e01.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/5abf97d7_e889_449d_b467_cc080db23d9c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/d24499c5_fefa_45c1_90f6_84ede6f7af50.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/a30fda7c_ffb4_40ee_97ae_39eeeb4bbbec.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/e772e8bb_2dca_431b_9875_0bc3e7c39538.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/f6ef1042_6e1b_46f1_8ff5_eb7d54d95d64.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/fcb3ee05_3125_4d18_879e_6eecbd2e4054.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/e4316d1d_319d_48ae_b740_5707e1a794b3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/d4bf2591_7bd9_4221_84ef_84c1d9251387.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/09db426f_2135_40a7_8279_400e27da6b5f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/8d3c2535_3f84_4b65_9930_b10e3c227830.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/654a64a6_b2a9_41df_ac13_4e493beff8a4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/57f946c9_aff9_48c7_a6d0_d38fdde0718c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/e5859f47_615b_48ef_92f2_e008a986d450.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/786f991b_cecf_48b6_8185_17e7dfec9102.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/6dd26527_dcb3_4026_84bf_edc040eda16c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/e9df12e9_64cf_4300_ac3b_f8c7ac0923a3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/51e0c7e2_d056_4d9c_b08f_d7c5dd0fce02.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/389cf226_9b01_47a7_95b6_78059dbf5842.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/6d28b9e5_d926_47b0_988a_28154b99f5b6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/8b716a6e_c9a1_4aad_9ff9_0832a18da3fe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/5a444f49_7c64_46f3_b69b_a3ec34138dcf.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/e181eec6_565b_4f86_9ba1_f4386f564be7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/5ecba623_90d3_4f9b_81e8_49c83f562628.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/dc2e9d61_0387_4cb5_837c_079b73664152.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/330e970f_fa62_48d4_b5df_d0efef9c4ecf.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/7b89c494_33aa_4fb5_9b68_e62571b3d66e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/fb85f09e_9b8b_416a_8655_b640922054ec.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10535/5246bbcd_2185_4ba8_9d6a_d1ed213bd57a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/5553bd2e_3a2c_489c_a87c_556194e0ac81.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/28d95a84_c537_429f_ae5a_99a905b14094.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/f4060653_7b2a_420b_b4ab_25c5a7ad2bc8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/a49475fe_850c_413b_b7ba_18be896e3dfa.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/118725d6_3729_476b_a782_9488a54b70ec.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/1d559b87_a295_4a10_b4a2_bc5d1ef68eac.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/83309b76_de78_4784_8353_91331131c23b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/46c1794d_ddec_47f3_9457_8b461b674a49.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/a16bbdc0_c3ce_47f4_a3eb_c4dd09d1f839.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/d9bb77af_85d8_4c38_b188_0e2393a37068.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/a1db1fc4_9e47_472a_a57a_1dc99e075f7b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/c2415b68_f713_48e8_80b1_aed4e800f748.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/15446568_47d2_4aa1_9ee2_2dc1658a2c12.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/366e6ef9_0c82_4934_a81d_3d308a9a96ab.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/50ab8284_5431_4648_89b6_e626592d29ba.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/62557b8d_90eb_469e_8971_77d76e658ea9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/7ec39689_3dc9_4136_8468_236af528cfdd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/4cfd3f70_95c0_41c6_85c2_b9907883ef6e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/910a5727_070d_4734_9242_0e396e12e5d8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/55f048c9_fe04_47a7_a3e9_6a40941b5b50.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/564acf9b_db71_424e_8125_26d2c1920ed9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10536/0ff6d945_6869_48f5_95fe_03ce1864b238.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/f25f11f8_3486_4ab5_8c55_6453751cedb3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/a2363e76_add4_42ce_8437_4d4c0d2c5899.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/f47f8f6a_819b_4927_bade_edbd943ab84c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/fbc7b090_4374_421b_9c20_f290a8a5ad85.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/2ca4d1cb_a3a0_462c_8adc_da35d12a491a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/a47384f1_9f38_46fd_91cb_a93ba63be020.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/78f5f975_6349_4e8d_883d_ca7a4637e764.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/0ad29e90_e2af_41c1_abec_a1579817e59c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/b2a96346_335f_49f5_b783_06f1b3eaaf6a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/a6555e11_2778_41ed_bb88_59abb8b1a263.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/6c682ac4_764d_4e0e_9f16_726e0db8bed0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/24943da5_ee56_4d53_b582_bbceccd979cb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/3761ad2c_b488_4448_8c90_1bde3e207b5b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/710c06d1_599d_4d1b_a59f_1477e5c9d392.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/191c2511_b7a6_40e1_8d21_d4189d5598f2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/825b92e3_bfc7_4f60_b5a0_a08126c1a243.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/cf51d834_eedf_4088_9918_76729eb3a258.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/650845bd_608d_4877_bda5_fd304a081033.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/1c644d31_394c_4d61_a4c2_f62e88127970.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/eceb3c3a_f3f1_4fa8_83ab_1ed3ad1933dc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/d82f8705_3451_40d1_8f0c_58eb139d6f74.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/a3be3247_6814_4b4f_9d66_e47fefc27b20.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/690f436c_33e5_4a41_bb93_ed396c874be6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/bcf5ddea_3d19_48cb_9129_0292cf2fbf89.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/e00249d4_ee2c_4cd5_b155_df22baf300e2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/ae27fe15_f8a6_4342_820b_d7946862fcc4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/f7d70740_a340_4ae5_a9bd_0cbc6114f857.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/497707c4_1173_4dda_b4c1_9a4b5825f82d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/07010d6a_0fcd_4b5e_8495_6b52baac9bb6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/3d5990f2_9954_4812_934f_4ece2dd5d92c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/9f77db24_baa3_42c0_a305_dee97910897a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/ae35ed6f_239b_4646_97d9_3caa004a9e1d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/42dd59e2_5095_444a_a4c7_3ac2c414c656.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1639687/10537/24524b70_e81c_4282_8731_bd77527eeb1a.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3042",
                               "Franchisee_Name": "Kidzee Patel Nagar",
                               "Operating_Status": "A",
                               "Address1": "House-No-594/24,",
                               "Address2": "",
                               "Place": "Patel Nagar, Road No-14, Infront of Nirmala Reside",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3042@kidzee.com",
                               "Mobile_No": "9835374777",
                               "Phone_No1": "9835374777",
                               "Phone_No2": "",
                               "Latitude": "25.6179590",
                               "Longitude": "85.1007000"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3861",
                               "Franchisee_Name": "Kidzee New A.G. Colony",
                               "Operating_Status": "A",
                               "Address1": "D-17, Danapur,",
                               "Address2": "Near G D Goenka Public School, Khagaul Road, ",
                               "Place": "New AG Colony , West Danapur,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3861@kidzee.com",
                               "Mobile_No": "9891555170",
                               "Phone_No1": "9891555170",
                               "Phone_No2": "",
                               "Latitude": "25.6142020",
                               "Longitude": "85.0362050",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1904399/10729/d2888912_f914_4511_8159_fa47a8e9e927.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1904399/10729/4bc630f3_73b5_4c56_8a22_9fccc770e441.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1904399/10729/6079ea42_1625_4a98_b869_9079af69a53c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1904399/10730/24be3bab_d8b3_4ad6_b2e8_6cb519a3af88.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1904399/10730/a0d46064_1b32_4f18_9b9c_659c1d4e8033.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3860",
                               "Franchisee_Name": "Kidzee Anand Vihar Colony",
                               "Operating_Status": "A",
                               "Address1": "Mangal Niwas,",
                               "Address2": "",
                               "Place": "Shahapur, Anand Vihar Colony,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3860@kidzee.com",
                               "Mobile_No": "7004082448",
                               "Phone_No1": "9334336844",
                               "Phone_No2": "",
                               "Latitude": "25.568946777947865",
                               "Longitude": "85.15139850540163"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3629",
                               "Franchisee_Name": "Kidzee Patna City",
                               "Operating_Status": "A",
                               "Address1": "Deep Nagar, Road No-4",
                               "Address2": "P.S.Mehandiganj",
                               "Place": "Patna City ,Behind Gulzarbagh Gumti",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3629@kidzee.com",
                               "Mobile_No": "9835267818",
                               "Phone_No1": "9835267818",
                               "Phone_No2": "",
                               "Latitude": "25.59605",
                               "Longitude": "85.20573"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3926",
                               "Franchisee_Name": "Kidzee  Kachchidargah",
                               "Operating_Status": "A",
                               "Address1": "AT Nathachak",
                               "Address2": "PO Kachchidargah",
                               "Place": "PS Didarganj ,Between Didarganj Toll Plaza and Chh",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3926@kidzee.com",
                               "Mobile_No": "9835210988",
                               "Phone_No1": "0612-2235527",
                               "Phone_No2": "",
                               "Latitude": "25.546062499847334",
                               "Longitude": "85.26198749570085"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4375",
                               "Franchisee_Name": "Kidzee Parsa  Bazar",
                               "Operating_Status": "A",
                               "Address1": "Sobhnath Complex Dariyapur Road,",
                               "Address2": "Kurthol,",
                               "Place": "Parsa Bazar, Near By Narayan Market,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4375@kidzee.com",
                               "Mobile_No": "8789937601",
                               "Phone_No1": "7250212524",
                               "Phone_No2": "7062023342",
                               "Latitude": "25.558772",
                               "Longitude": "85.126688",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2195272/10871/45607147_99c9_4425_9ca4_e15bbf8350a4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2195272/10880/3bd60ae9_26fb_44cc_bfab_4bff0dd7b62f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2195272/10887/f3f9a3e7_c008_47ac_a5cc_0a8e3aa59d28.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2195272/10898/b2340ff0_ebb9_4f2f_bce5_66bb6cf2a3cf.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4492",
                               "Franchisee_Name": "Kidzee Bihta",
                               "Operating_Status": "A",
                               "Address1": "Bihta ARA Main Road, NH-30 Near Bihta Churaha,",
                               "Address2": "Khedarpura,",
                               "Place": "Bihta, Near Maner Sweets,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4492@kidzee.com",
                               "Mobile_No": "8507276432",
                               "Phone_No1": "620-4260033",
                               "Phone_No2": "",
                               "Latitude": "25.573550851670465",
                               "Longitude": "84.86639881960866",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2291998/10859/232be9e5_e446_4aa0_9f06_5c5897dc447d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2291998/10859/f27b5c75_0445_4289_ad1c_c0b64f28a4da.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2291998/10859/f2dbb5fb_cfa6_48e5_bb5e_bf4ec42dac65.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2291998/10859/ffc94f1d_2d1a_4747_be46_010093bc2a4d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2291998/10859/eaca96b3_7fea_487f_b45f_2ad695a061d2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2291998/10859/bfb35e03_53f4_4c8a_8bee_c90f4153cb74.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2291998/10859/f52580da_bfe4_49b1_a619_ba41454fb808.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4651",
                               "Franchisee_Name": "Kidzee Khagaul",
                               "Operating_Status": "A",
                               "Address1": "Sitagouri, Utsavsthal, Khagaul Bihta Road,",
                               "Address2": "Vill - Jamaluddinchak, Shiwala,",
                               "Place": "Khagual ,Near K G Petrol Pump",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4651@kidzee.com",
                               "Mobile_No": "9102800101",
                               "Phone_No1": "9102800101",
                               "Phone_No2": "",
                               "Latitude": "25.581629866985356",
                               "Longitude": "85.0354158782958"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4515",
                               "Franchisee_Name": "Kidzee Zero Mile",
                               "Operating_Status": "A",
                               "Address1": "Jakariyapur,",
                               "Address2": "",
                               "Place": "Zero Mile, Near-Krishna Niketan School,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4515@kidzee.com",
                               "Mobile_No": "7004450516",
                               "Phone_No1": "7004450516.",
                               "Phone_No2": "",
                               "Latitude": "25.582270",
                               "Longitude": "85.182690"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3787",
                               "Franchisee_Name": "Kidzee Khagaul",
                               "Operating_Status": "A",
                               "Address1": "Shivajinagar",
                               "Address2": "Kothwan Road",
                               "Place": "Khagaul ,Dallu Chowk, Kothwan, near baswari",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3787@kidzee.com",
                               "Mobile_No": "7761964964",
                               "Phone_No1": "7761964964",
                               "Phone_No2": "",
                               "Latitude": "25.58396",
                               "Longitude": "85.04802"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3885",
                               "Franchisee_Name": "Kidzee Padri Ki Haweli",
                               "Operating_Status": "A",
                               "Address1": "Kajekala,",
                               "Address2": "Patna City,",
                               "Place": "Padri Ki Haweli, Behind The Church, Khajekalan,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3885@kidzee.com",
                               "Mobile_No": "9709849726",
                               "Phone_No1": "9110982217",
                               "Phone_No2": "",
                               "Latitude": "25.60131",
                               "Longitude": "85.21865"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4266",
                               "Franchisee_Name": "Kidzee Danapur",
                               "Operating_Status": "A",
                               "Address1": "Near-Modi Plastic Factory,",
                               "Address2": "Biscuit Factory Road, Nasriganj,",
                               "Place": "Danapur, Near-Modi Plastic Factory,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4266@kidzee.com",
                               "Mobile_No": "8294221161",
                               "Phone_No1": "8294221161",
                               "Phone_No2": "",
                               "Latitude": "25.64336",
                               "Longitude": "85.06654"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4273",
                               "Franchisee_Name": "Kidzee Digha",
                               "Operating_Status": "A",
                               "Address1": "38-SBI Colony,",
                               "Address2": "Banki Kothi,",
                               "Place": "Digha,",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4273@kidzee.com",
                               "Mobile_No": "9931448817",
                               "Phone_No1": "9931-448817",
                               "Phone_No2": "",
                               "Latitude": "25.64578",
                               "Longitude": "85.09592"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5704",
                               "Franchisee_Name": "Kidzee Gaurichak",
                               "Operating_Status": "A",
                               "Address1": "Mauza Shreepalpur Kamarjee",
                               "Address2": "Pargana Azimjabad PS Gaurichak Opp Power Grid House",
                               "Place": "Gaurichak",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5704@kidzee.com",
                               "Mobile_No": "8210339702",
                               "Phone_No1": "8210339702",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5764",
                               "Franchisee_Name": "Kidzee P C Colony",
                               "Operating_Status": "A",
                               "Address1": "PC Colony , SEC - F ,",
                               "Address2": "Near DR. Madhukar Clinic",
                               "Place": "Kankarbag",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5764@kidzee.com",
                               "Mobile_No": "8210921018",
                               "Phone_No1": "8210921018",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5597",
                               "Franchisee_Name": "Kidzee Parsa",
                               "Operating_Status": "A",
                               "Address1": "532, Kurthaul,",
                               "Address2": "Parsa bazar",
                               "Place": "Parsa",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1550@kidzee.com",
                               "Mobile_No": "9934773538",
                               "Phone_No1": "9934773538",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5567",
                               "Franchisee_Name": "Kidzee Kadamkuan",
                               "Operating_Status": "A",
                               "Address1": "Near OM Bihar Apartment kadamkuan",
                               "Address2": "Ground Floor",
                               "Place": "Kadamkuan",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5567@kidzee.com",
                               "Mobile_No": "9611284830",
                               "Phone_No1": "9611284830",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-3400",
                               "Franchisee_Name": "Kidzee Shanit Niwas Police Colony",
                               "Operating_Status": "A",
                               "Address1": "B-56,",
                               "Address2": "",
                               "Place": "Shanti Niwas Police Colony,A-114, Anishabad, Land:",
                               "City_Id": "785",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3400@kidzee.com",
                               "Mobile_No": "7667600031",
                               "Phone_No1": "08210921499",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Purnea",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-S-C-2097",
                               "Franchisee_Name": "Kidzee Purnia Bihar",
                               "Operating_Status": "A",
                               "Address1": "NH-31",
                               "Address2": "Kaptan pada, Khuskigahh",
                               "Place": "Khuskigahh, Near Smile Indane Gas Agency",
                               "City_Id": "811",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2097@kidzee.com",
                               "Mobile_No": "8797852130",
                               "Phone_No1": "9471010844",
                               "Phone_No2": "",
                               "Latitude": "25.7852665",
                               "Longitude": "87.5081081"
                            },
                            {
                               "Franchisee_Code": "E-C-U-1090",
                               "Franchisee_Name": "Kidzee- Purnia-Bihar-Jonny Kids",
                               "Operating_Status": "A",
                               "Address1": "Bhatta Durgabari,",
                               "Address2": "Purnia,",
                               "Place": "Mehboob Khan Tola Road, Near-Kali Mandir,",
                               "City_Id": "811",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1090@kidzee.com",
                               "Mobile_No": "9934809415",
                               "Phone_No1": "7739166660",
                               "Phone_No2": "",
                               "Latitude": "25.771976",
                               "Longitude": "87.475874"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2380",
                               "Franchisee_Name": "Kidzee Purnea  Bihar",
                               "Operating_Status": "A",
                               "Address1": "S/O-Binod Choudhury,",
                               "Address2": "PS-K Hat, Sanskrit Bahwan",
                               "Place": "Near Rangbhumi Maidan",
                               "City_Id": "811",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2380@kidzee.com",
                               "Mobile_No": "7004511525",
                               "Phone_No1": "9431259925",
                               "Phone_No2": "",
                               "Latitude": "25.7867884127359",
                               "Longitude": "87.4712294340134"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2651",
                               "Franchisee_Name": "Kidzee Gulabagh Purnea",
                               "Operating_Status": "A",
                               "Address1": "Market Chowk,",
                               "Address2": "",
                               "Place": "Market Chowk, Gulabbagh Purnia",
                               "City_Id": "811",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2651@kidzee.com",
                               "Mobile_No": "7369086130",
                               "Phone_No1": "9801731900",
                               "Phone_No2": "",
                               "Latitude": "25.777139",
                               "Longitude": "87.475255"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4053",
                               "Franchisee_Name": "Kidzee Purnea",
                               "Operating_Status": "A",
                               "Address1": "Ram Bagh Sarna Chowk",
                               "Address2": "Maharajganj Road",
                               "Place": "Purnea",
                               "City_Id": "811",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4053@kidzee.com",
                               "Mobile_No": "9470636328",
                               "Phone_No1": "1234-000000",
                               "Phone_No2": "",
                               "Latitude": "25.794949",
                               "Longitude": "87.490568"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5242",
                               "Franchisee_Name": "Kidzee Milki",
                               "Operating_Status": "A",
                               "Address1": "Mauja- Milki,",
                               "Address2": "Ward No 10",
                               "Place": "Naya Tola",
                               "City_Id": "811",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5242@kidzee.com",
                               "Mobile_No": "9709501995",
                               "Phone_No1": "7004752641",
                               "Phone_No2": "",
                               "Latitude": "25.78426",
                               "Longitude": "87.48543"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5542",
                               "Franchisee_Name": "Kidzee Kalipath",
                               "Operating_Status": "A",
                               "Address1": "Siphai Tola",
                               "Address2": "PS K. Hat",
                               "Place": "Kalipath",
                               "City_Id": "811",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5542@kidzee.com",
                               "Mobile_No": "6202291629",
                               "Phone_No1": "6202291629",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Sitamarhi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2294",
                               "Franchisee_Name": "Kidzee Sitamarhi Bihar",
                               "Operating_Status": "A",
                               "Address1": "Adarsh Nagar, Ward No-26, Holding No 319,",
                               "Address2": "Adarsh Nagar,",
                               "Place": "Sitamarhi, Amit Kumar Tunna, Congress Vidhayak Aaw",
                               "City_Id": "917",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2294@kidzee.com",
                               "Mobile_No": "9852670326",
                               "Phone_No1": "7463853551",
                               "Phone_No2": "",
                               "Latitude": "26.35234132",
                               "Longitude": "85.30152136",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2294/317/417446a7_3eff_4a7a_921e_ce0e0a83c5a9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/750343/10522/dfdfba9e_d1c6_4df9_9ba4_3f3880552812.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/750343/10522/356c823e_6a7f_4888_aa6d_c38b04d48d8e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/750343/10522/ae792a41_5520_4cf7_8672_93d440bac74a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/750343/10522/d4388f7f_4cd1_4447_915a_ed10a7184e63.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/750343/10522/f1e22024_00d3_4463_8cd8_67cd0eda6f5f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/750343/10522/1256750b_f17d_4b2d_909c_5f5fdd3399f8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/750343/10522/e51f63b1_1cce_4ad0_a199_ba22326a77e9.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-5215",
                               "Franchisee_Name": "Kidzee  Sursand",
                               "Operating_Status": "A",
                               "Address1": "Mauze Banauli Thana No-27 ,Khata-335",
                               "Address2": "Khesra 1379,1376",
                               "Place": "P S Sursand",
                               "City_Id": "917",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5215@kidzee.com",
                               "Mobile_No": "8210141057",
                               "Phone_No1": "8210141057",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Siwan",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-C-1032",
                               "Franchisee_Name": "Kidzee Siwan",
                               "Operating_Status": "A",
                               "Address1": "Siswan Dhala Kirani Ka Hata,",
                               "Address2": "",
                               "Place": "Lakshmipur, Behing-Mahavir Mandir,",
                               "City_Id": "919",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1032@kidzee.com",
                               "Mobile_No": "9431274155",
                               "Phone_No1": "9934530570",
                               "Phone_No2": "",
                               "Latitude": "26.219621",
                               "Longitude": "84.356659"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4615",
                               "Franchisee_Name": "Kidzee Mahadeva Siwan",
                               "Operating_Status": "A",
                               "Address1": "Plot No-57, Ward No-16, House No-126, Usha Bhavan Back of church,",
                               "Address2": "Mahadeva,",
                               "Place": "Siwan, Behind Church Mission compound",
                               "City_Id": "919",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4615@kidzee.com",
                               "Mobile_No": "8709588273",
                               "Phone_No1": "8709588273",
                               "Phone_No2": "",
                               "Latitude": "26.238177",
                               "Longitude": "84.358652"
                            }
                         ]
                      },
                      {
                         "City_Name": "Sonepur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-1807",
                               "Franchisee_Name": "Kidzee Sonepur Little Stars",
                               "Operating_Status": "A",
                               "Address1": "Gandhi Ashram,",
                               "Address2": "Sonepur,",
                               "Place": "Gandhi Ashram, Near Sonepur Block,",
                               "City_Id": "922",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1807@kidzee.com",
                               "Mobile_No": "9334228532",
                               "Phone_No1": "9334228532",
                               "Phone_No2": "",
                               "Latitude": "25.698368",
                               "Longitude": "85.166083"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4753",
                               "Franchisee_Name": "KIDZEE GAUTAM CHOWK",
                               "Operating_Status": "A",
                               "Address1": "Near Braj Kishore Singh ITI",
                               "Address2": "Dudhailla,Gachhi",
                               "Place": "Gautam Chowk ,Near Brajkishor Singh Pvt. ITI ",
                               "City_Id": "922",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4753@kidzee.com",
                               "Mobile_No": "8434465000",
                               "Phone_No1": "6200434800",
                               "Phone_No2": "",
                               "Latitude": "25.703808",
                               "Longitude": "85.1554722"
                            }
                         ]
                      },
                      {
                         "City_Name": "Katihar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-3041",
                               "Franchisee_Name": "Kidzee Barmasia",
                               "Operating_Status": "A",
                               "Address1": "Barmasia,",
                               "Address2": "Brahmachari Field Road,",
                               "Place": "Barmasia, Near Munilal Chowk,",
                               "City_Id": "1039",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3041@kidzee.com",
                               "Mobile_No": "9504722969",
                               "Phone_No1": "9504722969",
                               "Phone_No2": "",
                               "Latitude": "25.552048",
                               "Longitude": "87.571861"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5059",
                               "Franchisee_Name": "Kidzee Sirsa  Army Camp",
                               "Operating_Status": "A",
                               "Address1": "Army Camp",
                               "Address2": "ground floor",
                               "Place": "Sirsa, Station Headquarters, Katihar",
                               "City_Id": "1039",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5059@kidzee.com",
                               "Mobile_No": "7903994938",
                               "Phone_No1": "7903994938",
                               "Phone_No2": "",
                               "Latitude": "25.58490",
                               "Longitude": "87.55742"
                            }
                         ]
                      },
                      {
                         "City_Name": "Lakhisarai"
                      },
                      {
                         "City_Name": "Champaran",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5322",
                            "Franchisee_Name": "Kidzee Bagaha",
                            "Operating_Status": "A",
                            "Address1": "129 ,Ward No 18",
                            "Address2": "Gandhi Nagar ,Near Janki Petrol Pump",
                            "Place": "Bagaha",
                            "City_Id": "1043",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5322@kidzee.com",
                            "Mobile_No": "7996205047",
                            "Phone_No1": "7996205047",
                            "Phone_No2": "",
                            "Latitude": "27.101540",
                            "Longitude": "84.080345"
                         }
                      },
                      {
                         "City_Name": "Biharsharif",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5051",
                            "Franchisee_Name": "Kidzee Kalyanpur",
                            "Operating_Status": "A",
                            "Address1": "Saur Vatika Apartment, Block 1-G-1",
                            "Address2": "Bhagwan Buddha High School n Nagar Nigam, Bazirpur Baignabad",
                            "Place": "Kalyanpur Station Road,",
                            "City_Id": "1044",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5051@kidzee.com",
                            "Mobile_No": "8340110155",
                            "Phone_No1": "8340110155",
                            "Phone_No2": "",
                            "Latitude": "25.2057378",
                            "Longitude": "85.5337592"
                         }
                      },
                      {
                         "City_Name": "Kishanganj",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-S-C-1457",
                               "Franchisee_Name": "Kidzee-Kishanganj-First Step",
                               "Operating_Status": "A",
                               "Address1": "Halim Chowk,",
                               "Address2": "Salafi House",
                               "Place": "Near Halim Chowk",
                               "City_Id": "1045",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1457@kidzee.com",
                               "Mobile_No": "9431466815",
                               "Phone_No1": "9431-466815",
                               "Phone_No2": "",
                               "Latitude": "26.088929",
                               "Longitude": "87.923352"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5680",
                               "Franchisee_Name": "Kidzee Thakurganj",
                               "Operating_Status": "A",
                               "Address1": "Bhagwat Dwar Gate",
                               "Address2": "Near Pragya Jyoti School, Block Road",
                               "Place": "Thakurganj",
                               "City_Id": "1045",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5680@kidzee.com",
                               "Mobile_No": "7783888529",
                               "Phone_No1": "7783888529",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Samastipur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2695",
                               "Franchisee_Name": "KIDZEE  SAMASTIPUR",
                               "Operating_Status": "A",
                               "Address1": "C/O-Ashoke Prasad Singh,",
                               "Address2": "Ward No-29,",
                               "Place": "Samastipur, Near-Shiv Mandir, Madhuri Chowk,",
                               "City_Id": "1101",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2695@kidzee.com",
                               "Mobile_No": "9304571815",
                               "Phone_No1": "9431025907",
                               "Phone_No2": "",
                               "Latitude": "25.862968",
                               "Longitude": "85.781026"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3694",
                               "Franchisee_Name": "Kidzee Kavichowk",
                               "Operating_Status": "A",
                               "Address1": "Shahpur Undi",
                               "Address2": "Patory",
                               "Place": "Kavichowk ,Near Shiv Temple Godam Road, Patori",
                               "City_Id": "1101",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3694@kidzee.com",
                               "Mobile_No": "7779888642",
                               "Phone_No1": "7779888642",
                               "Phone_No2": "",
                               "Latitude": "25.63343",
                               "Longitude": "85.59263"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5637",
                               "Franchisee_Name": "Kidzee Tajpur Road",
                               "Operating_Status": "A",
                               "Address1": "Sri Krishnapuri, Ground floor",
                               "Address2": "Near R.N.A.R college",
                               "Place": "Tajpur Road",
                               "City_Id": "1101",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5637@kidzee.com",
                               "Mobile_No": "9304571815",
                               "Phone_No1": "9304571815",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Buxar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-S-C-1622",
                               "Franchisee_Name": "Kidzee-Buxar",
                               "Operating_Status": "A",
                               "Address1": "House No.464,",
                               "Address2": "",
                               "Place": "By Pass Road, Near Kali Asthan",
                               "City_Id": "1108",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1622@kidzee.com",
                               "Mobile_No": "9891206891",
                               "Phone_No1": "0072-50448655",
                               "Phone_No2": "",
                               "Latitude": "25.568709376267986",
                               "Longitude": "83.98051762235215"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4930",
                               "Franchisee_Name": "Kidzee Ashok Nagar",
                               "Operating_Status": "A",
                               "Address1": "Itadhi Road,",
                               "Address2": "Near-Indian Oil Pump,",
                               "Place": "Ashok Nagar, Non Operational,",
                               "City_Id": "1108",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4930@kidzee.com",
                               "Mobile_No": "7050329999",
                               "Phone_No1": "7050329999",
                               "Phone_No2": "",
                               "Latitude": "25.58227",
                               "Longitude": "84.03874"
                            }
                         ]
                      },
                      {
                         "City_Name": "Gopal ganj",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-1989",
                            "Franchisee_Name": "Kidzee-Gopalgunj",
                            "Operating_Status": "A",
                            "Address1": "Kidzee- Gopalgunj, Kids Park, Manikpur Road,",
                            "Address2": "Haziyapur More, NH-28,",
                            "Place": "Kidzee- Gopalgunj, Kids Park, Barai Tola, Jyoti Na",
                            "City_Id": "1113",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1989@kidzee.com",
                            "Mobile_No": "9939939772",
                            "Phone_No1": "0",
                            "Phone_No2": "",
                            "Latitude": "26.4691138",
                            "Longitude": "84.4514517"
                         }
                      },
                      {
                         "City_Name": "Raxaul"
                      },
                      {
                         "City_Name": "Mahua"
                      },
                      {
                         "City_Name": "Aurangabad"
                      },
                      {
                         "City_Name": "Jehanabad",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5705",
                            "Franchisee_Name": "Kidzee Gorakshini",
                            "Operating_Status": "A",
                            "Address1": "Main Road ,",
                            "Address2": "Khata No-14 ,Plot No-112",
                            "Place": "Gorakshini",
                            "City_Id": "1187",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5705@kidzee.com",
                            "Mobile_No": "9934611528",
                            "Phone_No1": "9934611528",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Sasaram / Sahasram"
                      },
                      {
                         "City_Name": "Munger",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-1973",
                            "Franchisee_Name": "Kidzee Munger",
                            "Operating_Status": "A",
                            "Address1": "East Shivaji Chowk,",
                            "Address2": "Bekapur,",
                            "Place": "Near-Annapurana Transport, Yash Academy Computer C",
                            "City_Id": "1252",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1973@kidzee.com",
                            "Mobile_No": "9234915997",
                            "Phone_No1": "06344228223",
                            "Phone_No2": "",
                            "Latitude": "25.374756",
                            "Longitude": "86.473525"
                         }
                      },
                      {
                         "City_Name": "Madhepura"
                      },
                      {
                         "City_Name": "Masaurhi"
                      },
                      {
                         "City_Name": "Forbesganj"
                      },
                      {
                         "City_Name": "Saharsa",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2279",
                            "Franchisee_Name": "KIDZEE SAHARSA BIHAR",
                            "Operating_Status": "A",
                            "Address1": "Ward No-17,Plot No 443, Pachvati Chowk,",
                            "Address2": "Gangjalla",
                            "Place": "Gangjalla, Saharsa Bus Stand Rd",
                            "City_Id": "1339",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2279@kidzee.com",
                            "Mobile_No": "8877932093",
                            "Phone_No1": "8877932093",
                            "Phone_No2": "",
                            "Latitude": "25.884964583641",
                            "Longitude": "86.5985995531082"
                         }
                      },
                      {
                         "City_Name": "Simri"
                      },
                      {
                         "City_Name": "Khagaria",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5224",
                            "Franchisee_Name": "Kidzee Jayprakash Nagar",
                            "Operating_Status": "A",
                            "Address1": "Colony Jayprakash Nagar, Near-J.N.K.T. Inter School Ward No-21,",
                            "Address2": "Helicopter Bhawan Road,",
                            "Place": "Jayprakash Nagar,",
                            "City_Id": "1345",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5224@kidzee.com",
                            "Mobile_No": "7782011114",
                            "Phone_No1": "6201013366",
                            "Phone_No2": "",
                            "Latitude": "25.505160",
                            "Longitude": "86.474266"
                         }
                      },
                      {
                         "City_Name": "Araria"
                      },
                      {
                         "City_Name": "Nawada",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3508",
                            "Franchisee_Name": "Kidzee Nawada Town",
                            "Operating_Status": "A",
                            "Address1": "VIP Colony, Nawada,",
                            "Address2": "Behind-Nawada Court,",
                            "Place": "Nawada Town, Near-Indira Gandhi Chowk,",
                            "City_Id": "1355",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3508@kidzee.com",
                            "Mobile_No": "7070202099",
                            "Phone_No1": "7004831264 ",
                            "Phone_No2": "",
                            "Latitude": "24.899488124166",
                            "Longitude": "85.5458432435989",
                            "Images": [
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1602279/10549/db7f8c4a_f069_4f62_a4cd_50102391ccaa.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1602279/10549/e81c115b_d4ba_431a_81c4_f7d66210b3db.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Supaul",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-5308",
                               "Franchisee_Name": "Kidzee Supaul",
                               "Operating_Status": "A",
                               "Address1": "Ward No.8, Ambedkar Chawk, Opp Gandhi Maidan",
                               "Address2": "Near R.B.B Girls High School",
                               "Place": "Supaul",
                               "City_Id": "1361",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5308@kidzee.com",
                               "Mobile_No": "9471226781",
                               "Phone_No1": "6202810262",
                               "Phone_No2": "",
                               "Latitude": "26.1186834",
                               "Longitude": "86.5991299"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5790",
                               "Franchisee_Name": "Kidzee Rampur",
                               "Operating_Status": "A",
                               "Address1": "Rampur, Tola - Rampur",
                               "Address2": "Rampur, Block- Chhatapur",
                               "Place": "Rampur",
                               "City_Id": "1361",
                               "State_Id": "9",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5790@kidzee.com",
                               "Mobile_No": "7643055588",
                               "Phone_No1": "7643055588",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Jamui",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2378",
                            "Franchisee_Name": "Kidzee Jamui Bihar",
                            "Operating_Status": "A",
                            "Address1": "Kachari Road, Near Prasad X-Ray,",
                            "Address2": "Mohalla, Krishnapatti",
                            "Place": "Jamui, Near Prasad X-Ray",
                            "City_Id": "1373",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2378@kidzee.com",
                            "Mobile_No": "9955338004",
                            "Phone_No1": "7209472088",
                            "Phone_No2": "",
                            "Latitude": "24.928472",
                            "Longitude": "86.221567"
                         }
                      },
                      {
                         "City_Name": "Dehri on sone"
                      },
                      {
                         "City_Name": "Rajgir"
                      },
                      {
                         "City_Name": "Bhabua",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3016",
                            "Franchisee_Name": "Kidzee Bhabua (Kaimur)",
                            "Operating_Status": "A",
                            "Address1": "Vasistha Niket,",
                            "Address2": "",
                            "Place": "Kachahari Road, Infront Sadar Hospital",
                            "City_Id": "1563",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3016@kidzee.com",
                            "Mobile_No": "9934529736",
                            "Phone_No1": "9431680634",
                            "Phone_No2": "",
                            "Latitude": "25.042623",
                            "Longitude": "83.605573",
                            "Images": {
                               "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3016/262/ea7d8c73_ce5e_46f8_81f8_073848221486.jpg"
                            }
                         }
                      },
                      {
                         "City_Name": "Bodh gaya"
                      },
                      {
                         "City_Name": "HISUA"
                      },
                      {
                         "City_Name": "SHEOHAR",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4800",
                            "Franchisee_Name": "Kidzee Sheohar",
                            "Operating_Status": "A",
                            "Address1": "ward no 11, Khata 305,",
                            "Address2": "Near new police line{sanjay pandey smarak}",
                            "Place": "Sheohar ,Infront of Sshid Sanjay Pandey Smarak She",
                            "City_Id": "1587",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4800@kidzee.com",
                            "Mobile_No": "7070431543",
                            "Phone_No1": "7070-431543",
                            "Phone_No2": "",
                            "Latitude": "26.52106300690049",
                            "Longitude": "85.30552621957396"
                         }
                      },
                      {
                         "City_Name": "Sonpur"
                      },
                      {
                         "City_Name": "Jhajha"
                      },
                      {
                         "City_Name": "Sheikhpura",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3434",
                            "Franchisee_Name": "Kidzee Sheikhpura",
                            "Operating_Status": "A",
                            "Address1": "Mohalla,",
                            "Address2": "Jamalpur Bigha,",
                            "Place": "Sheikhpura, Near-Miyan Pokhar,",
                            "City_Id": "1612",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3434@kidzee.com",
                            "Mobile_No": "9234783533",
                            "Phone_No1": "9234783533",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Mokama"
                      },
                      {
                         "City_Name": "Dumraon"
                      },
                      {
                         "City_Name": "Koilwar"
                      },
                      {
                         "City_Name": "Simribakhtiyarpur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2249",
                            "Franchisee_Name": "Kidzee  Wonder Kids  Simribakhtiyarpur",
                            "Operating_Status": "A",
                            "Address1": "Main Road, back of sahara india office..",
                            "Address2": "P.O.Simribakhtiyarpur Dist- Saharsa (Bihar)",
                            "Place": "Simribakhtiyarpur ,back of sahara india office",
                            "City_Id": "1883",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2249@kidzee.com",
                            "Mobile_No": "9431243426",
                            "Phone_No1": "9431243426",
                            "Phone_No2": "",
                            "Latitude": "25.883496",
                            "Longitude": "86.600625"
                         }
                      },
                      {
                         "City_Name": "Kudra"
                      },
                      {
                         "City_Name": "Bikramganj",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5309",
                            "Franchisee_Name": "Kidzee Bikramgnaj",
                            "Operating_Status": "A",
                            "Address1": "Utari VIP colony",
                            "Address2": "Nahar Wala Rasta",
                            "Place": "Nasriganj Road",
                            "City_Id": "1958",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5309@kidzee.com",
                            "Mobile_No": "7660012829",
                            "Phone_No1": "1234-00000000",
                            "Phone_No2": "",
                            "Latitude": "25.222465",
                            "Longitude": "84.270802"
                         }
                      },
                      {
                         "City_Name": "Biraul",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5670",
                            "Franchisee_Name": "Kidzee Biraul",
                            "Operating_Status": "A",
                            "Address1": "Supaul Bazar ,Afzala Tole",
                            "Address2": "Khewa,Gara Bazar",
                            "Place": "PO & PS- Biraul",
                            "City_Id": "2002",
                            "State_Id": "9",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5670@kidzee.com",
                            "Mobile_No": "9263839713",
                            "Phone_No1": "9263839713",
                            "Phone_No2": "",
                            "Latitude": "25.944628",
                            "Longitude": "86.247858"
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Chhattisgarh",
                   "City": [
                      {
                         "City_Name": "Ambikapur"
                      },
                      {
                         "City_Name": "Bhilai"
                      },
                      {
                         "City_Name": "Bilaspur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-5246",
                               "Franchisee_Name": "KIDZEE MOKPA BILASPUR",
                               "Operating_Status": "A",
                               "Address1": "Ramakrishna Nagar ,Near Mopka",
                               "Address2": "Kutipara Road",
                               "Place": "Mopka",
                               "City_Id": "174",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5246@kidzee.com",
                               "Mobile_No": "8225948243",
                               "Phone_No1": "8770150210",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5423",
                               "Franchisee_Name": "Kidzee Nehru Nagar",
                               "Operating_Status": "A",
                               "Address1": "E-3/L2 , Shanti Nagar ,Behind Rani Sati Mandir",
                               "Address2": "Beside Arogya Hospital,Ring Road No-02,Gaurav Path ,Ameri Chowk",
                               "Place": "Nehru Nagar",
                               "City_Id": "174",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5423@kidzee.com",
                               "Mobile_No": "7999213924",
                               "Phone_No1": "9826460858",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Dhamtari",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4896",
                            "Franchisee_Name": "Kidzee Dhamtari",
                            "Operating_Status": "A",
                            "Address1": "P.H.No-33,Gram Dhamtari,",
                            "Address2": "Motor Stand Ward,",
                            "Place": "Near Gurudwara, Dhamtari,",
                            "City_Id": "254",
                            "State_Id": "10",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4896@kidzee.com",
                            "Mobile_No": "7024540141",
                            "Phone_No1": "9407671271",
                            "Phone_No2": "",
                            "Latitude": "20.709182",
                            "Longitude": "81.548518"
                         }
                      },
                      {
                         "City_Name": "Durg"
                      },
                      {
                         "City_Name": "Jagdalpur",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4829",
                            "Franchisee_Name": "Kidzee Bastar",
                            "Operating_Status": "A",
                            "Address1": "HIG Duplex-1 , Chandra Shekhar Ward",
                            "Address2": "No 41 ,Housing Board ,Hatkachora",
                            "Place": "Bastar ,Partner is looking for relocation",
                            "City_Id": "470",
                            "State_Id": "10",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4829@kidzee.com",
                            "Mobile_No": "9106953716",
                            "Phone_No1": "7381769160",
                            "Phone_No2": "",
                            "Latitude": "19.084774",
                            "Longitude": "82.041252"
                         }
                      },
                      {
                         "City_Name": "Korba",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-U-U-587",
                               "Franchisee_Name": "Kidzee - Korba, D.D.M",
                               "Operating_Status": "A",
                               "Address1": "D.D.M Marg,",
                               "Address2": "",
                               "Place": "Tulsi Nagar, Behind-Indira Stadium,",
                               "City_Id": "596",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "kidzee587@kidzee.com",
                               "Mobile_No": "7389939245",
                               "Phone_No1": "9111299999",
                               "Phone_No2": "",
                               "Latitude": "22.357663",
                               "Longitude": "82.698401"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3084",
                               "Franchisee_Name": "Kidzee NTPC Jamnipali",
                               "Operating_Status": "A",
                               "Address1": "HIG 507, SADA, SRVP Sardar Vallabbhai Patel Nagar,",
                               "Address2": "Jamnipali , Teh - Katghora, ",
                               "Place": "NTPC Jamnipali ,NTPC Colony ",
                               "City_Id": "596",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3084@kidzee.com",
                               "Mobile_No": "7389939245",
                               "Phone_No1": "7389939245",
                               "Phone_No2": "",
                               "Latitude": "22.414039",
                               "Longitude": "82.664689"
                            }
                         ]
                      },
                      {
                         "City_Name": "Raigarh",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-4806",
                               "Franchisee_Name": "Kidzee Kharsia",
                               "Operating_Status": "A",
                               "Address1": "Near Girls College",
                               "Address2": "TIT  Colony",
                               "Place": "Kharsia ,Near Girls CollegeTIT Colony",
                               "City_Id": "817",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4806@kidzee.com",
                               "Mobile_No": "9617830405",
                               "Phone_No1": "8983226348",
                               "Phone_No2": "",
                               "Latitude": "21.986381",
                               "Longitude": "83.103400"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5061",
                               "Franchisee_Name": "Kidzee Raigarh Krishna Vihar",
                               "Operating_Status": "A",
                               "Address1": "120 ,Krishna Vihar,",
                               "Address2": "",
                               "Place": "Krishna Vihar,121, Dimrapur Chowk, Raigarh,",
                               "City_Id": "817",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5061@kidzee.com",
                               "Mobile_No": "8817216565",
                               "Phone_No1": "8817216565",
                               "Phone_No2": "",
                               "Latitude": "21.54538",
                               "Longitude": "83.22497"
                            }
                         ]
                      },
                      {
                         "City_Name": "Raipur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2514",
                               "Franchisee_Name": "Kidzee Fafadih Raipur",
                               "Operating_Status": "A",
                               "Address1": "Omega Kidzee, Behind Central College,",
                               "Address2": "Near-Sindhu Sadan Pathak Hospital Gali,",
                               "Place": "Fafadih, Raipur, Near-Sindhu Sadan Pathak Nursing ",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2514@kidzee.com",
                               "Mobile_No": "9424203356",
                               "Phone_No1": "9425504256",
                               "Phone_No2": "",
                               "Latitude": "21.263749551108262",
                               "Longitude": "81.63656258035667"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3091",
                               "Franchisee_Name": "Kidzee  Samta colony Raipur",
                               "Operating_Status": "A",
                               "Address1": "House No-353,",
                               "Address2": "Samta Colony Main Road, opp bank of baroda",
                               "Place": "Raipur",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3091@kidzee.com",
                               "Mobile_No": "9669555514",
                               "Phone_No1": "9669555514",
                               "Phone_No2": "",
                               "Latitude": "21.246493",
                               "Longitude": "81.622904"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3146",
                               "Franchisee_Name": "Kidzee Sunder nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot no 25,",
                               "Address2": "House no: 307, Near Bakshi Dental Clinic",
                               "Place": "Sunder nagar ,Near Bakshi Dental Clinic",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3146@kidzee.com",
                               "Mobile_No": "8982441161",
                               "Phone_No1": "8982441161",
                               "Phone_No2": "",
                               "Latitude": "21.232675",
                               "Longitude": "81.609771"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3399",
                               "Franchisee_Name": "Kidzee Kids Castle",
                               "Operating_Status": "A",
                               "Address1": "J-4,",
                               "Address2": "Sector-2,",
                               "Place": "Avanti Vihar, Near Hitvada Office, J-4 Sector -2,",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3399@kidzee.com",
                               "Mobile_No": "9301155219",
                               "Phone_No1": "9424217943",
                               "Phone_No2": "",
                               "Latitude": "21.24375",
                               "Longitude": "81.67145",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10574/d396ad16_df0d_46d1_af63_d77787194db4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10574/1a67702d_b0f1_4c4d_8212_6335e06e3201.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10574/0e363e4b_f720_4608_a014_e11dff919c73.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10574/a67f0bc2_3943_48a4_a121_8070b342bffb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10575/e377f55f_e7fb_48db_9c66_fdc7964b899d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10575/1560d0bd_7744_4349_a375_64a7aa14fb0d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10575/742d7852_7dcf_4b2e_9181_7e4f87611788.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10575/bf99908a_35c7_494e_8d5d_e0ecee91b997.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10575/aba8c5cb_7e04_4449_a271_a881519804b1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10576/59b390b9_c1d9_4749_92a2_44ed0bd204fb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10576/14b1eddf_b519_41a5_a17b_a54d7f70ef7e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10576/f7467867_9785_4f8f_b9cc_993bea10bc83.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10576/8fc258c6_79da_4b4c_a94e_fb8dd026ea0b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1530599/10577/2ca1b3b3_b266_4e3b_aa35_587bb45ac537.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-B-U-838",
                               "Franchisee_Name": "KIDZEE-TATIBANDH- Chattisgarh- JINGLE BELLS NURSERY",
                               "Operating_Status": "A",
                               "Address1": "A-5, Shri Shanti Nath Nagar,",
                               "Address2": "opp Bharat Mata School",
                               "Place": "Tatibandh ,opp Bharat Mata School",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "kidzee838@kidzee.com",
                               "Mobile_No": "9424201301",
                               "Phone_No1": "6260448991",
                               "Phone_No2": "",
                               "Latitude": "21.257521012700803",
                               "Longitude": "81.57430622090146",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/633/10647/4e644800_e81b_447e_8788_9b44b609cb87.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/633/10647/e1cb3e34_af6a_4420_93ff_38e088985558.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/633/10647/4def81c5_196b_4bc6_8a53_a5876dcfcc9f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/633/10647/bf55d6a5_7c0a_45b3_845d_3a666ee484c6.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-3350",
                               "Franchisee_Name": "Kidzee Kota",
                               "Operating_Status": "A",
                               "Address1": "Opposite-FCA Godown,",
                               "Address2": "Revenue Circle No.Raipur-1,",
                               "Place": "Main Road Kota,Opposite FCA Godown, Kota,",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3350@kidzee.com",
                               "Mobile_No": "9300298000",
                               "Phone_No1": "9300298000",
                               "Phone_No2": "",
                               "Latitude": "21.238426",
                               "Longitude": "81.619331"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3625",
                               "Franchisee_Name": "Kidzee Baloda Bazar",
                               "Operating_Status": "A",
                               "Address1": "Infront of Krishnayan Colony",
                               "Address2": "Baloda Bazar",
                               "Place": "Bhatapara road ,Infront of Krishnayan Colony. Balo",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3625@kidzee.com",
                               "Mobile_No": "9039608000",
                               "Phone_No1": "9691132001",
                               "Phone_No2": "",
                               "Latitude": "21.6610252",
                               "Longitude": "82.1352035"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3625",
                               "Franchisee_Name": "Kidzee Baloda Bazar",
                               "Operating_Status": "A",
                               "Address1": "Infront of Krishnayan Colony",
                               "Address2": "Baloda Bazar",
                               "Place": "Bhatapara road ,Infront of Krishnayan Colony. Balo",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3625@kidzee.com",
                               "Mobile_No": "9039608000",
                               "Phone_No1": "9691132001",
                               "Phone_No2": "",
                               "Latitude": "21.6610252",
                               "Longitude": "82.1352035"
                            },
                            {
                               "Franchisee_Code": "W-B-U-838",
                               "Franchisee_Name": "KIDZEE-TATIBANDH- Chattisgarh- JINGLE BELLS NURSERY",
                               "Operating_Status": "A",
                               "Address1": "A-5, Shri Shanti Nath Nagar,",
                               "Address2": "opp Bharat Mata School",
                               "Place": "Tatibandh ,opp Bharat Mata School",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "kidzee838@kidzee.com",
                               "Mobile_No": "9424201301",
                               "Phone_No1": "6260448991",
                               "Phone_No2": "",
                               "Latitude": "21.257631",
                               "Longitude": "81.574038",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/633/10647/4e644800_e81b_447e_8788_9b44b609cb87.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/633/10647/e1cb3e34_af6a_4420_93ff_38e088985558.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/633/10647/4def81c5_196b_4bc6_8a53_a5876dcfcc9f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/633/10647/bf55d6a5_7c0a_45b3_845d_3a666ee484c6.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4302",
                               "Franchisee_Name": "Kidzee Mowa",
                               "Operating_Status": "A",
                               "Address1": "Mittan Vihar,",
                               "Address2": "Ground Floor, Daldal Seoni Road,",
                               "Place": "Mowa, Opp-Sumit Wholesale Bazaar,",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4302@kidzee.com",
                               "Mobile_No": "7000633292",
                               "Phone_No1": "9981000913",
                               "Phone_No2": "",
                               "Latitude": "21.274036",
                               "Longitude": "81.6656260",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2194945/10891/f6503a56_4360_40d3_9da1_ebd8e13900de.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2194945/10892/197c2e10_2ff1_45a1_9be3_22dd2098d945.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2194945/10892/2b593c51_4aea_4562_8ecd_6b975b64f762.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2194945/10892/85a5da9e_db06_4834_9a38_449aab271471.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-4736",
                               "Franchisee_Name": "Kidzee Tagore Nagar",
                               "Operating_Status": "A",
                               "Address1": "D-394 , Sector 5, Gokul Garden",
                               "Address2": "Near MLA rest House,",
                               "Place": "Tagore Nagar ,Near MLA rest House",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4736@kidzee.com",
                               "Mobile_No": "7828578819",
                               "Phone_No1": "7004811414",
                               "Phone_No2": "9109916170",
                               "Latitude": "21.2252118",
                               "Longitude": "81.6505932"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4344",
                               "Franchisee_Name": "Kidzee Amlidih",
                               "Operating_Status": "A",
                               "Address1": "A-105, Aakriti Vihar,",
                               "Address2": "Main Road,",
                               "Place": "Amlidih, Near-SBI ATM,",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4344@kidzee.com",
                               "Mobile_No": "8319811591",
                               "Phone_No1": "8319811591",
                               "Phone_No2": "",
                               "Latitude": "22.586917",
                               "Longitude": "88.494139"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5019",
                               "Franchisee_Name": "Kidzee Kachna Raipur",
                               "Operating_Status": "A",
                               "Address1": "H No.  H .I G - 6,  Near Aishwarya Kingdom",
                               "Address2": "Ground Floor ,Housing Board Colony",
                               "Place": "Kachna ,Near Aishwarya Kingdom",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5019@kidzee.com",
                               "Mobile_No": "7566206030",
                               "Phone_No1": "9713884231",
                               "Phone_No2": "",
                               "Latitude": "21.1538.5",
                               "Longitude": "81.4139.0"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5099",
                               "Franchisee_Name": "Kidzee Deendayal Upadhaya Nagar",
                               "Operating_Status": "A",
                               "Address1": "House No 86,HIG II,",
                               "Address2": "Sector 4",
                               "Place": "Deendayal Upadhaya Nagar ,Near Passport Office D",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5099@kidzee.com",
                               "Mobile_No": "8305577703",
                               "Phone_No1": "8305577703",
                               "Phone_No2": "",
                               "Latitude": "21.239627",
                               "Longitude": "81.596580"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5752",
                               "Franchisee_Name": "Kidzee Devendra Nagar",
                               "Operating_Status": "A",
                               "Address1": "C-54 ,Beside Forest Office",
                               "Address2": "Sector 2",
                               "Place": "Devendra Nagar",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5752@kidzee.com",
                               "Mobile_No": "9179850287",
                               "Phone_No1": "9179850287",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5367",
                               "Franchisee_Name": "Kidzee Kabir Nagar Raipur",
                               "Operating_Status": "A",
                               "Address1": "123 ,MIG Duplex",
                               "Address2": "Phase-4 ,",
                               "Place": "Kabir Nagar",
                               "City_Id": "820",
                               "State_Id": "10",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5367@kidzee.com",
                               "Mobile_No": "9584751651",
                               "Phone_No1": "9584751651",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Janjgir"
                      },
                      {
                         "City_Name": "Kota"
                      },
                      {
                         "City_Name": "Champa"
                      },
                      {
                         "City_Name": "Manendragarh"
                      },
                      {
                         "City_Name": "Bijuri"
                      },
                      {
                         "City_Name": "Baloda Bazar"
                      },
                      {
                         "City_Name": "Shivrinarayan"
                      },
                      {
                         "City_Name": "Baikunthpur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3975",
                            "Franchisee_Name": "Kidzee Baikanthapur",
                            "Operating_Status": "A",
                            "Address1": "House No-220,",
                            "Address2": "M.L.A.Nagar, Baikanthpur  Dist.Koriya,",
                            "Place": "Baikanthpur, Partner is Looking For Relocation,",
                            "City_Id": "1839",
                            "State_Id": "10",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3975@kidzee.com",
                            "Mobile_No": "9294838888",
                            "Phone_No1": "9294838888",
                            "Phone_No2": "",
                            "Latitude": "23.254994",
                            "Longitude": "82.5446590"
                         }
                      },
                      {
                         "City_Name": "Bhatapura",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4343",
                            "Franchisee_Name": "Kidzee Bhatapura",
                            "Operating_Status": "A",
                            "Address1": "Khasara No-379/5,",
                            "Address2": "Bhattar Farms,",
                            "Place": "Bhatapara, Near Gurukul School, Tarenga Road,",
                            "City_Id": "1862",
                            "State_Id": "10",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4343@kidzee.com",
                            "Mobile_No": "7354200788",
                            "Phone_No1": "9171858800",
                            "Phone_No2": "",
                            "Latitude": "21.74775",
                            "Longitude": "81.92839"
                         }
                      },
                      {
                         "City_Name": "Mungeli",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4728",
                            "Franchisee_Name": "Kidzee Mungeli",
                            "Operating_Status": "A",
                            "Address1": "Gunjan Palace ,Maharana Pratap Ward",
                            "Address2": "Vinoba Nagar ,Mungeli PS Mungeli",
                            "Place": "PO Mungeli ,non operational",
                            "City_Id": "1922",
                            "State_Id": "10",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4728@kidzee.com",
                            "Mobile_No": "7543091871",
                            "Phone_No1": "7543091871",
                            "Phone_No2": "",
                            "Latitude": "22.06718",
                            "Longitude": "81.69002"
                         }
                      },
                      {
                         "City_Name": "Rajnandgaon"
                      },
                      {
                         "City_Name": "Surajpur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5603",
                            "Franchisee_Name": "Kidzee Surajpur",
                            "Operating_Status": "A",
                            "Address1": "Plot No 2175",
                            "Address2": "Nehru Park Road",
                            "Place": "Surajpur",
                            "City_Id": "1992",
                            "State_Id": "10",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5603@kidzee.com",
                            "Mobile_No": "9717767044",
                            "Phone_No1": "9717767044",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Bemetara"
                      },
                      {
                         "City_Name": "Surajpur"
                      },
                      {
                         "City_Name": "Bemetara"
                      }
                   ]
                },
                {
                   "State_Name": "Delhi",
                   "City": [
                      {
                         "City_Name": "Delhi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-A-M-570",
                               "Franchisee_Name": "Kidzee-Dwarika,Delhi",
                               "Operating_Status": "A",
                               "Address1": "A-153,",
                               "Address2": "Vivekananda Marg,",
                               "Place": "A-Block, Sector-8, Dwarka,",
                               "City_Id": "248",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee570@kidzee.com",
                               "Mobile_No": "9811850004",
                               "Phone_No1": "011-45062937 ",
                               "Phone_No2": "",
                               "Latitude": "28.604772",
                               "Longitude": "77.18577"
                            },
                            {
                               "Franchisee_Code": "N-A-M-1431",
                               "Franchisee_Name": "Kidzee-Yamuna Vihar",
                               "Operating_Status": "A",
                               "Address1": "C-6/206, (Behind BSES Office),",
                               "Address2": "",
                               "Place": "Yamuna Vihar, Behind BSES office,",
                               "City_Id": "248",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1431@kidzee.com",
                               "Mobile_No": "9811122415",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "28.700252",
                               "Longitude": "77.273725"
                            },
                            {
                               "Franchisee_Code": "N-R-S-2099",
                               "Franchisee_Name": "Kidzee Paschim Vihar Delhi",
                               "Operating_Status": "A",
                               "Address1": "No.2, Block, B-3, Paschim Vihar,",
                               "Address2": "New Delhi,",
                               "Place": "Paschim Vihar, Bloack-B,",
                               "City_Id": "248",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2099@kidzee.com",
                               "Mobile_No": "9999892345",
                               "Phone_No1": "9999892345",
                               "Phone_No2": "",
                               "Latitude": "28.665707",
                               "Longitude": "77.107711"
                            },
                            {
                               "Franchisee_Code": "N-R-S-2052",
                               "Franchisee_Name": "Kidzee saidullajab Saket Delhi",
                               "Operating_Status": "A",
                               "Address1": "E-29, Parvaran Complex,",
                               "Address2": "",
                               "Place": "Saket, Ignoo Main Road,",
                               "City_Id": "248",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2052@kidzee.com",
                               "Mobile_No": "9810267160",
                               "Phone_No1": "01129534629",
                               "Phone_No2": "",
                               "Latitude": "28.514317",
                               "Longitude": "77.201712"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3374",
                               "Franchisee_Name": "Kidzee Gujranwalan Town",
                               "Operating_Status": "A",
                               "Address1": "Flying Birds Play School, B-51,",
                               "Address2": "Phase -1,",
                               "Place": "Gujranwalan Town, Near-Gurudwara,",
                               "City_Id": "248",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3374@kidzee.com",
                               "Mobile_No": "9873553221",
                               "Phone_No1": "011-40204920",
                               "Phone_No2": "",
                               "Latitude": "28.702601",
                               "Longitude": "77.187841"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4480",
                               "Franchisee_Name": "Kidzee VPO Dhansa",
                               "Operating_Status": "A",
                               "Address1": "Allahbad Bank Lane,",
                               "Address2": "",
                               "Place": "Dhansa ,Behind- Allahbad Bank ( Najafgarh Road)",
                               "City_Id": "248",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4480@kidzee.com",
                               "Mobile_No": "9319555886",
                               "Phone_No1": "9319555886",
                               "Phone_No2": "",
                               "Latitude": "28.558880",
                               "Longitude": "76.864564"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3792",
                               "Franchisee_Name": "Kidzee Chattarpur",
                               "Operating_Status": "A",
                               "Address1": "128, Chaudhari Ram Ratan Farm House,",
                               "Address2": "Opp Golden Tulip Hotel, Assola Fatehpur Beri",
                               "Place": "Chattarpur",
                               "City_Id": "248",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3792@kidzee.com",
                               "Mobile_No": "9999798300",
                               "Phone_No1": "9873801235",
                               "Phone_No2": "",
                               "Latitude": "28.456375",
                               "Longitude": "77.185644"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5478",
                               "Franchisee_Name": "Kidzee Vasundhra Enclave",
                               "Operating_Status": "A",
                               "Address1": "001, Ground Floor  E 477",
                               "Address2": "New Ashok Nagar, Opp Mahesh Apartment",
                               "Place": "Vasundhra Enclave",
                               "City_Id": "248",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5478@kidzee.com",
                               "Mobile_No": "9911488144",
                               "Phone_No1": "9911488144",
                               "Phone_No2": "",
                               "Latitude": "28.597506",
                               "Longitude": "77.313148"
                            }
                         ]
                      },
                      {
                         "City_Name": "Karawal nagar"
                      },
                      {
                         "City_Name": "Khajoori Khas"
                      },
                      {
                         "City_Name": "Nangloi jat / Nangloi",
                         "Franchisee": {
                            "Franchisee_Code": "N-A-M-1337",
                            "Franchisee_Name": "Kidzee-Nangloi Najafgarh Road",
                            "Operating_Status": "A",
                            "Address1": "Plot No.10, 1st floor,",
                            "Address2": "Naresh Park Extension, Nangloi Najafgarh Road",
                            "Place": "Nangloi ,Nangloi - Najafgarh road",
                            "City_Id": "712",
                            "State_Id": "11",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1337@kidzee.com",
                            "Mobile_No": "9899824846",
                            "Phone_No1": "32602696",
                            "Phone_No2": "",
                            "Latitude": "28.684121",
                            "Longitude": "77.063294"
                         }
                      },
                      {
                         "City_Name": "Narela"
                      },
                      {
                         "City_Name": "New delhi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-A-M-1285",
                               "Franchisee_Name": "Kidzee-Mohan Garden,New Delhi",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 7, Avatar Vihar,",
                               "Address2": "Main Budh Bazaar Road,Mohan Garden, Uttam Nagar",
                               "Place": "Mohan Garden, Uttam Nagar",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1285@kidzee.com",
                               "Mobile_No": "8130278656",
                               "Phone_No1": "8130-278656",
                               "Phone_No2": "",
                               "Latitude": "28.629769754386203",
                               "Longitude": "77.03192450534061",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1285/68/ac1006f3_2021_4832_a840_6f6aff47269c.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-S-2648",
                               "Franchisee_Name": "Kidzee  Chattarpur  NewDelhi",
                               "Operating_Status": "A",
                               "Address1": "Shree Karni Villa,",
                               "Address2": "186-A Rajpur Khurd Extension,",
                               "Place": "Chattarpur NewDelhi ,Near Zara farms",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2648@kidzee.com",
                               "Mobile_No": "9999646974",
                               "Phone_No1": "26303847",
                               "Phone_No2": "",
                               "Latitude": "28.487398",
                               "Longitude": "77.190212"
                            },
                            {
                               "Franchisee_Code": "N-R-S-2270",
                               "Franchisee_Name": "Kidzee Uttam Nagar New Delhi",
                               "Operating_Status": "A",
                               "Address1": "C-17, Kiran Garden,",
                               "Address2": "Kiran Garden",
                               "Place": "Uttam Nagar, Near Metro Pllar No 720",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2270@kidzee.com",
                               "Mobile_No": "9711598580",
                               "Phone_No1": "9711598580",
                               "Phone_No2": "",
                               "Latitude": "28.62032612087687",
                               "Longitude": "77.04961671579554"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2749",
                               "Franchisee_Name": "KIDZEE  MAHAVIR ENCLAVE NEW DELHI",
                               "Operating_Status": "A",
                               "Address1": "RZ, B-5, Gurudwra Road,",
                               "Address2": "",
                               "Place": "Mahavir Enclave, Near-Gurudwara,",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2749@kidzee.com",
                               "Mobile_No": "8448126344",
                               "Phone_No1": "8448126344",
                               "Phone_No2": "",
                               "Latitude": "28.60045",
                               "Longitude": "77.080866"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3489",
                               "Franchisee_Name": "Kidzee Raj Nagar, Part 2, Palam Colony",
                               "Operating_Status": "A",
                               "Address1": "R2H - 131, B, Gali No-5,",
                               "Address2": "Raj Nagar, Part 2, ",
                               "Place": "Palam Colony, Near-Solanki Chowk,",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3489@kidzee.com",
                               "Mobile_No": "7900090081",
                               "Phone_No1": "9810496455",
                               "Phone_No2": "",
                               "Latitude": "28.5843",
                               "Longitude": "77.0907"
                            },
                            {
                               "Franchisee_Code": "N-S-S-1895",
                               "Franchisee_Name": "Kirti Nagar New Delhi",
                               "Operating_Status": "A",
                               "Address1": "B-11 A,",
                               "Address2": "Kirti Nagar,11. Block -C,",
                               "Place": "Delhi",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1895@kidzee.com",
                               "Mobile_No": "9873533676",
                               "Phone_No1": "9873533676",
                               "Phone_No2": "",
                               "Latitude": "28.65059",
                               "Longitude": "77.14429"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3571",
                               "Franchisee_Name": "Kidzee Najafgarh",
                               "Operating_Status": "A",
                               "Address1": "RZ-39, G-Block, 40-Feet Road,",
                               "Address2": "New-RoshanPura, Near-Shri Shyam Mandir,",
                               "Place": "Najafgarh, On-40-Ft Road,",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3571@kidzee.com",
                               "Mobile_No": "9313650250",
                               "Phone_No1": "9313650250",
                               "Phone_No2": "",
                               "Latitude": "28.6040884529629",
                               "Longitude": "74.9928849935532"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3666",
                               "Franchisee_Name": "Kidzee Dwarka",
                               "Operating_Status": "A",
                               "Address1": "Plot No.114,",
                               "Address2": "Pocket C/8,",
                               "Place": "Sector-17, Dwarka ,In front of Police station,",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3666@kidzee.com",
                               "Mobile_No": "9811850006",
                               "Phone_No1": "9811041000",
                               "Phone_No2": "",
                               "Latitude": "28.587706",
                               "Longitude": "77.029278",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3666/305/183fe96b_c33a_4742_88d1_e2331e350afc.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3666/305/06c4aa4e_68f3_444c_8083_85bf9095b583.JPG"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-S-4149",
                               "Franchisee_Name": "Kidzee Naraina Vihar",
                               "Operating_Status": "A",
                               "Address1": "G 27,",
                               "Address2": "Ground Floor,",
                               "Place": "Naaraina vihar",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4149@kidzee.com",
                               "Mobile_No": "9717746688",
                               "Phone_No1": "9717-746688",
                               "Phone_No2": "",
                               "Latitude": "28.6290",
                               "Longitude": "77.1413",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2054256/10701/4b84ddae_88b2_42d2_82e3_a37d628bc972.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-4036",
                               "Franchisee_Name": "Kidzee Badarpur",
                               "Operating_Status": "A",
                               "Address1": "B- 51, Bharat Singh Complex,",
                               "Address2": "Near- Shaheed Vijender Singh Murti,",
                               "Place": "Badarpur, Near-Molarband Neher,",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4036@kidzee.com",
                               "Mobile_No": "9911111697",
                               "Phone_No1": "9211-199990",
                               "Phone_No2": "",
                               "Latitude": "28.500880200126485",
                               "Longitude": "77.3136153785706",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1965677/10905/7f91e662_cc63_4ade_8830_d310a313d74e.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-S-3843",
                               "Franchisee_Name": "Kidzee Dwarka Sector -19",
                               "Operating_Status": "A",
                               "Address1": "A12,",
                               "Address2": "Ground Floor",
                               "Place": "Dwarka Sector -19 ,Near Vardhman Mall",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3843@kidzee.com",
                               "Mobile_No": "8860877751",
                               "Phone_No1": "8860877751",
                               "Phone_No2": "",
                               "Latitude": "28.575749",
                               "Longitude": "77.051442"
                            },
                            {
                               "Franchisee_Code": "COCSS-4496",
                               "Franchisee_Name": "Kidzee Pitampura",
                               "Operating_Status": "A",
                               "Address1": "F1U/34,",
                               "Address2": "Ground Floor,",
                               "Place": "Pitampura,Coco",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4496@kidzee.com",
                               "Mobile_No": "9152010789",
                               "Phone_No1": "9152010789",
                               "Phone_No2": "",
                               "Latitude": "28.713762",
                               "Longitude": "77.141392"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4665",
                               "Franchisee_Name": "Kidzee Najafgarh",
                               "Operating_Status": "A",
                               "Address1": "21/A & 22/1, Near Udasin Ashram & Green Valley School,",
                               "Address2": "Tehsil Road, Deepak Vihar,",
                               "Place": "Najafgarh, Near-Green Valley School",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4665@kidzee.com",
                               "Mobile_No": "9313650250",
                               "Phone_No1": "9313650250",
                               "Phone_No2": "",
                               "Latitude": "28.620743",
                               "Longitude": "76.989184"
                            },
                            {
                               "Franchisee_Code": "N-R-S-4726",
                               "Franchisee_Name": "Kidzee Vasant Kunj",
                               "Operating_Status": "A",
                               "Address1": "158-B-II/9, Forming part of khasra no. 605,",
                               "Address2": "Village Kishangarh, Vasant Kunj, New Delhi,",
                               "Place": "Vasant Kunj",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4726@kidzee.com",
                               "Mobile_No": "9990500855",
                               "Phone_No1": "011-46107555",
                               "Phone_No2": "",
                               "Latitude": "28.5159043",
                               "Longitude": "77.1548808"
                            },
                            {
                               "Franchisee_Code": "N-R-S-4867",
                               "Franchisee_Name": "Kidzee Sarita Vihar",
                               "Operating_Status": "A",
                               "Address1": "Flat No 61, Ground Floor",
                               "Address2": "Category - II, Pocket -F",
                               "Place": "Sarita Vihar ,Near F Block Market",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4867@kidzee.com",
                               "Mobile_No": "9315158063",
                               "Phone_No1": "9315158063",
                               "Phone_No2": "",
                               "Latitude": "28.532793",
                               "Longitude": "77.295164"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5080",
                               "Franchisee_Name": "Kidzee Lajpat Nagar 4",
                               "Operating_Status": "A",
                               "Address1": "41-A, Ground Floor, Ring Road,",
                               "Address2": "Captain Gaur Marg",
                               "Place": "Lajpat Nagar-4 ,Near Lajpat Nagar Flyover",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5080@kidzee.com",
                               "Mobile_No": "9650139595",
                               "Phone_No1": "120-4753123",
                               "Phone_No2": "",
                               "Latitude": "28.5643",
                               "Longitude": "77.2369",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2722178/11054/6928bbcb_f848_4b93_8c21_aec3baf34355.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-4970",
                               "Franchisee_Name": "Kidzee Aya Nagar",
                               "Operating_Status": "A",
                               "Address1": "299D, Ghora Mohalla,",
                               "Address2": "Ground Floor,",
                               "Place": "Aya Nagar, Near-Ghitorni Metro St.",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4970@kidzee.com",
                               "Mobile_No": "9911208030",
                               "Phone_No1": "9911208030",
                               "Phone_No2": "",
                               "Latitude": "28.47892916638606",
                               "Longitude": "77.12908148979643"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5434",
                               "Franchisee_Name": "Kidzee Kakrola",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 74 - 75, Ground Floor, Khasra No. - 27 / 8",
                               "Address2": "Shiv Park, Kakrola",
                               "Place": "Dwarka",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5434@kidzee.com",
                               "Mobile_No": "9350018580",
                               "Phone_No1": "9350018580",
                               "Phone_No2": "",
                               "Latitude": "28.610115",
                               "Longitude": "77.033432"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5482",
                               "Franchisee_Name": "Kidzee Khanpur",
                               "Operating_Status": "A",
                               "Address1": "DDA LIG FLAT NO 13",
                               "Address2": "GAYATRI ENCLAVE ,MB ROAD",
                               "Place": "KHANPUR",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5482@kidzee.com",
                               "Mobile_No": "8595833283",
                               "Phone_No1": "011-45733874",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4392",
                               "Franchisee_Name": "Kidzee Jonapur",
                               "Operating_Status": "A",
                               "Address1": "No.16,Ground Floor and 1st Floor,",
                               "Address2": "South Delhi,",
                               "Place": "Jonapur, Near Talab, Desu Mandi,",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4392@kidzee.com",
                               "Mobile_No": "9953141364",
                               "Phone_No1": "9953141364",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5663",
                               "Franchisee_Name": "Kidzee V Chhawla",
                               "Operating_Status": "A",
                               "Address1": "Kh. No. 507/508/509, Plot No-82 V",
                               "Address2": "Chhawala",
                               "Place": "V Chhawla",
                               "City_Id": "732",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3571@kidzee.com",
                               "Mobile_No": "9313650250",
                               "Phone_No1": "9313650250",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Patpar ganj / Gharonda neemka bangar"
                      },
                      {
                         "City_Name": "Sadat pur gujran"
                      },
                      {
                         "City_Name": "Shahdara delhi"
                      },
                      {
                         "City_Name": "East Delhi",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-S-3681",
                            "Franchisee_Name": "Kidzee Laxmi Nagar",
                            "Operating_Status": "A",
                            "Address1": "N-19, Laxmi Nagar, Near Jagat Ram Park,",
                            "Address2": "Opposite to HDFC Bank ATM, Shyam Lassi Wali Gali",
                            "Place": "Laxmi Nagar ,Behind M block CHurch",
                            "City_Id": "1661",
                            "State_Id": "11",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3681@kidzee.com",
                            "Mobile_No": "8882267733",
                            "Phone_No1": "9999622908",
                            "Phone_No2": "",
                            "Latitude": "28.635778583473925",
                            "Longitude": "77.27551018650817",
                            "Images": [
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1627285/10799/94e433cf_07b5_4f3e_bebb_adeb92894896.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1627285/10799/0cc3b1e0_29fe_4931_87c6_38bc9227608b.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1627285/10799/c0c5340b_342a_4a2d_8fd1_63e63ce1b9af.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1627285/10799/19b6b804_095c_4794_8a6e_fc072cc3af8f.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1627285/10799/1ab6636c_943d_4954_8b59_55f91dc1441f.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1627285/10799/5fb3d634_ddcd_4fea_a92a_c259a004acb4.jpg"
                               }
                            ]
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Goa",
                   "City": [
                      {
                         "City_Name": "Chicalim"
                      },
                      {
                         "City_Name": "Dabolim"
                      },
                      {
                         "City_Name": "Old goa",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2418",
                            "Franchisee_Name": "Kidzee Old Goa",
                            "Operating_Status": "A",
                            "Address1": "No : E 113/1 Kanthiya Bhat",
                            "Address2": "Near Miracle Cross Ella Old Goa",
                            "Place": "Near Miracle Crossing ELLA",
                            "City_Id": "374",
                            "State_Id": "12",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2418@kidzee.com",
                            "Mobile_No": "8459431170",
                            "Phone_No1": "9921822672",
                            "Phone_No2": "",
                            "Latitude": "15.502774",
                            "Longitude": "73.915407"
                         }
                      },
                      {
                         "City_Name": "Loutolim / Loutulim / Lottli"
                      },
                      {
                         "City_Name": "Margao",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3103",
                               "Franchisee_Name": "Kidzee Salcette Goa",
                               "Operating_Status": "A",
                               "Address1": "H.No. 4/2767",
                               "Address2": ", Aquem , Margao",
                               "Place": ", opposite Santos garage",
                               "City_Id": "630",
                               "State_Id": "12",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3103@kidzee.com",
                               "Mobile_No": "8459204305",
                               "Phone_No1": "8459204305",
                               "Phone_No2": "",
                               "Latitude": "15.282705",
                               "Longitude": "73.986708"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2146",
                               "Franchisee_Name": "Kidzee Salcete, Goa",
                               "Operating_Status": "A",
                               "Address1": "Dambab Vihar",
                               "Address2": "Near Amul ice cream parlour",
                               "Place": "Ambaji Fatorda ",
                               "City_Id": "630",
                               "State_Id": "12",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2146@kidzee.com",
                               "Mobile_No": "8459538918",
                               "Phone_No1": "8459538918",
                               "Phone_No2": "",
                               "Latitude": "15.283219",
                               "Longitude": "73.986191"
                            }
                         ]
                      },
                      {
                         "City_Name": "Mapusa",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2525",
                            "Franchisee_Name": "Kidzee Feira Alta Mapusa Goa",
                            "Operating_Status": "A",
                            "Address1": "G1/ G2, Annapurna Apartments,",
                            "Address2": "Dr.Babasaheb Ambedkar Road,",
                            "Place": "Feira Alta, Altinho, Behind-Electricity Office,",
                            "City_Id": "651",
                            "State_Id": "12",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2525@kidzee.com",
                            "Mobile_No": "8554080408",
                            "Phone_No1": "8554080408",
                            "Phone_No2": "",
                            "Latitude": "15.600227",
                            "Longitude": "73.812498"
                         }
                      },
                      {
                         "City_Name": "Navelim"
                      },
                      {
                         "City_Name": "Panjim",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4144",
                            "Franchisee_Name": "Kidzee Lake View Colony",
                            "Operating_Status": "A",
                            "Address1": "H.No 1810, behind chodankar Hospital",
                            "Address2": "Goa",
                            "Place": "Alto Porvorim Bardez",
                            "City_Id": "768",
                            "State_Id": "12",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4144@kidzee.com",
                            "Mobile_No": "9325324097",
                            "Phone_No1": "9890156141",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Vasco-da-gama",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-U-S-441",
                               "Franchisee_Name": "Kidzee-Vasco-da-Gama, Little Flowers",
                               "Operating_Status": "A",
                               "Address1": "125,Jayram Nager,",
                               "Address2": "Vasco- Da-Gama,",
                               "Place": "Dabolim, JayRam Nagar",
                               "City_Id": "1005",
                               "State_Id": "12",
                               "Country_Id": "1",
                               "Email_Id": "kidzee441@kidzee.com",
                               "Mobile_No": "9309729032",
                               "Phone_No1": "9309729032",
                               "Phone_No2": "",
                               "Latitude": "15.395484",
                               "Longitude": "73.854096"
                            },
                            {
                               "Franchisee_Code": "W-U-C-944",
                               "Franchisee_Name": "Kidzee-Patronga-Goa",
                               "Operating_Status": "A",
                               "Address1": "House No.123, Pramrose,",
                               "Address2": "Airport Road,",
                               "Place": "Chicalim, Nr.Mary Children School,",
                               "City_Id": "1005",
                               "State_Id": "12",
                               "Country_Id": "1",
                               "Email_Id": "kidzee944@kidzee.com",
                               "Mobile_No": "9309755169",
                               "Phone_No1": "9309755169",
                               "Phone_No2": "",
                               "Latitude": "15.392422",
                               "Longitude": "73.834981"
                            }
                         ]
                      },
                      {
                         "City_Name": "Zuari nagar"
                      },
                      {
                         "City_Name": "Ponda",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2373",
                            "Franchisee_Name": "Kidzee Ponda Goa",
                            "Operating_Status": "A",
                            "Address1": "Hno.156/12, at Sodri,",
                            "Address2": "Next to zonal agricultural office",
                            "Place": "Santacruz, Ponda, Next to zonal agricultural offic",
                            "City_Id": "1384",
                            "State_Id": "12",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2373@kidzee.com",
                            "Mobile_No": "8407979409",
                            "Phone_No1": "8407979409",
                            "Phone_No2": "",
                            "Latitude": "15.397533",
                            "Longitude": "74.004327"
                         }
                      },
                      {
                         "City_Name": "Colva",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2419",
                            "Franchisee_Name": "Kidzee Colva",
                            "Operating_Status": "A",
                            "Address1": "Villa No:15, St Mary's Garden, House No:6D/15,",
                            "Address2": "Povacao,",
                            "Place": "Benaulim, St Mary Garden,",
                            "City_Id": "1402",
                            "State_Id": "12",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2419@kidzee.com",
                            "Mobile_No": "8459539674",
                            "Phone_No1": "8459539674",
                            "Phone_No2": "",
                            "Latitude": "15.27874",
                            "Longitude": "73.93473"
                         }
                      },
                      {
                         "City_Name": "Porvorim"
                      },
                      {
                         "City_Name": "Goa",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-5287",
                               "Franchisee_Name": "Kidzee Bicholim Aisha's",
                               "Operating_Status": "A",
                               "Address1": "Sr.No 41/5,",
                               "Address2": "Muslim Wada,",
                               "Place": "Bicholim ,Muslim Wada",
                               "City_Id": "1861",
                               "State_Id": "12",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5287@kidzee.com",
                               "Mobile_No": "8669028805",
                               "Phone_No1": "0832-2954405",
                               "Phone_No2": "",
                               "Latitude": "15.586983735897649",
                               "Longitude": "73.95701855421066",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2767308/11061/ac225842_a03f_4d1f_b5bb_531269e910a5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2767308/11061/4be353fe_1626_42b9_8c29_2bcd1423c9ac.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2767308/11061/0ead0ed1_42da_4d52_a496_be5ed2f0cf31.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "A-W-A-1041",
                               "Franchisee_Name": "Ankurum Parnem",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 254/7, Opp  St Joseph School,",
                               "Address2": "Surbanwada",
                               "Place": "Pernem ,Opp St Joseph School",
                               "City_Id": "1861",
                               "State_Id": "12",
                               "Country_Id": "1",
                               "Email_Id": "siddeshwork27@gmail.com",
                               "Mobile_No": "7798786869",
                               "Phone_No1": "7798786869",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "PERNEM"
                      }
                   ]
                },
                {
                   "State_Name": "Gujarat",
                   "City": [
                      {
                         "City_Name": "Gandhidham"
                      },
                      {
                         "City_Name": "Ahmedabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3184",
                               "Franchisee_Name": "Kidzee Vastral",
                               "Operating_Status": "A",
                               "Address1": "58.Gopal Charan Bunglows,",
                               "Address2": "",
                               "Place": "Vastral, Near Arpan School",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3184@kidzee.com",
                               "Mobile_No": "9925009252",
                               "Phone_No1": "9925009252",
                               "Phone_No2": "",
                               "Latitude": "22.995275",
                               "Longitude": "72.662983"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3186",
                               "Franchisee_Name": "Kidzee - Aakash Education , Nava Naroda",
                               "Operating_Status": "A",
                               "Address1": "88,Shriram Tenament,",
                               "Address2": "Near Shyam Enclave,Opp.Mevada Green Partyplot,",
                               "Place": "Nava Naroda ,Opp. Mevada Garden Party Plot",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3186@kidzee.com",
                               "Mobile_No": "9374013623",
                               "Phone_No1": "9374013623",
                               "Phone_No2": "",
                               "Latitude": "23.070887",
                               "Longitude": "72.671289"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3315",
                               "Franchisee_Name": "Kidzee Gota",
                               "Operating_Status": "A",
                               "Address1": "GF - 9/10/11, Simandhar Status,",
                               "Address2": "Opp-Shayona Green, B/H- Vodafone Tower,",
                               "Place": "SG Road, Gota, Behind Vodafone Tower Lane,",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3315@kidzee.com",
                               "Mobile_No": "9427609669",
                               "Phone_No1": "0000-000000",
                               "Phone_No2": "",
                               "Latitude": "23.105428",
                               "Longitude": "72.544009",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1460425/10694/233f6410_3758_4a0e_b278_408c97d90ee0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1460425/11088/89278d67_dae4_4698_9607_03706569c1fb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1460425/11088/5539ad1f_44ee_425d_8ade_cbc8a7058cca.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1460425/11088/60f757fd_aa7b_4a85_a818_7bcda3b7cf77.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-1855",
                               "Franchisee_Name": "Kidzee- Ranip Ahmedabad",
                               "Operating_Status": "A",
                               "Address1": "12, Srinidhi Society,",
                               "Address2": "Near-Balkrishna Mandir,",
                               "Place": "Ranip,",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1855@kidzee.com",
                               "Mobile_No": "9879590475",
                               "Phone_No1": "9879590475",
                               "Phone_No2": "",
                               "Latitude": "23.080254",
                               "Longitude": "72.57193"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2837",
                               "Franchisee_Name": "Kidzee  Ghatlodia Ahmedabad",
                               "Operating_Status": "A",
                               "Address1": "K.K.Education,",
                               "Address2": "35/36 Nirman Bunglows,B/H, shayona international school",
                               "Place": "Prasang Party Plot Rd, Shayona City, Chanakyapuri,",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2837@kidzee.com",
                               "Mobile_No": "9925870543",
                               "Phone_No1": "9106268885",
                               "Phone_No2": "",
                               "Latitude": "23.081110",
                               "Longitude": "72.538615"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3344",
                               "Franchisee_Name": "Kidzee K K Nagar",
                               "Operating_Status": "A",
                               "Address1": "A6-Parul Society,",
                               "Address2": "KK Nagar Road,",
                               "Place": "K K Nagar, Ghatlodia ,Opp. Vidhata Society,",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3344@kidzee.com",
                               "Mobile_No": "9687099864",
                               "Phone_No1": "9687099864",
                               "Phone_No2": "",
                               "Latitude": "23.070396",
                               "Longitude": "72.547074"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2879",
                               "Franchisee_Name": "Kidzee Satellite",
                               "Operating_Status": "A",
                               "Address1": "A12,Falgun Tenament,",
                               "Address2": "Opp. Devanshi Appt, Jodhpur Gam Road,",
                               "Place": "Satellite Road, Near- Amul Park,",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2879@kidzee.com",
                               "Mobile_No": "8140956146",
                               "Phone_No1": "8140956146",
                               "Phone_No2": "",
                               "Latitude": "23.027064",
                               "Longitude": "72.521793"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2894",
                               "Franchisee_Name": "Kidzee Angel Academy Bapu Nagar",
                               "Operating_Status": "A",
                               "Address1": "75-Narnarayan Society,",
                               "Address2": "LB Road, Harshad Colony Road,",
                               "Place": "Bapu Nagar, Opp.Lane of D-Mart",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2894@kidzee.com",
                               "Mobile_No": "9898013570",
                               "Phone_No1": "9898013570",
                               "Phone_No2": "",
                               "Latitude": "23.044150",
                               "Longitude": "72.632925"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2715",
                               "Franchisee_Name": "Kidzee Vastrapur, Ahmedabad",
                               "Operating_Status": "A",
                               "Address1": "13/B Nehru Park,",
                               "Address2": "B/h Balaji Complex,",
                               "Place": "Near Vastrapur Lake,Vastrapur",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2715@kidzee.com",
                               "Mobile_No": "9925037650",
                               "Phone_No1": "9925037650",
                               "Phone_No2": "",
                               "Latitude": "23.039237",
                               "Longitude": "72.521907"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3695",
                               "Franchisee_Name": "Kidzee  New Ranip",
                               "Operating_Status": "A",
                               "Address1": "Banglow No 6,  Shreeji Twin Banglow,",
                               "Address2": "Near Khodiar Temple,  Chenpur Road,",
                               "Place": "New Ranip ,Near Khodiyar Temple",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3695@kidzee.com",
                               "Mobile_No": "7096811520",
                               "Phone_No1": "7096811520",
                               "Phone_No2": "",
                               "Latitude": "23.088900",
                               "Longitude": "72.559441"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3754",
                               "Franchisee_Name": "Kidzee Chandkheda  IOC road",
                               "Operating_Status": "A",
                               "Address1": "A/05, Gyaneshwar Park Society,",
                               "Address2": "",
                               "Place": "Chandkheda IOC Road, Opp. Chandkheda Railway Stati",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3754@kidzee.com",
                               "Mobile_No": "9099914644",
                               "Phone_No1": "079-29289183",
                               "Phone_No2": "",
                               "Latitude": "23.108241",
                               "Longitude": "72.563818",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1693742/10594/8e9bb087_174a_40fb_b29a_ec368c85dddb.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-4116",
                               "Franchisee_Name": "Kidzee Prahlad Nagar",
                               "Operating_Status": "A",
                               "Address1": "C- 8, Ram Jalaram Bungalow,",
                               "Address2": "Opp Heritage Skyz,B/h Venus Atlantis,",
                               "Place": "Prahaladnagar Cross Road",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4116@kidzee.com",
                               "Mobile_No": "9825252336",
                               "Phone_No1": "9825252336",
                               "Phone_No2": "",
                               "Latitude": "23.0104600",
                               "Longitude": "72.5090870"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4331",
                               "Franchisee_Name": "Kidzee New Naroda",
                               "Operating_Status": "A",
                               "Address1": "36,Nilkanth Bungalows,",
                               "Address2": "Kathwada Road, Nr.Panchtirth School,",
                               "Place": "New Naroda,",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4331@kidzee.com",
                               "Mobile_No": "9687330331",
                               "Phone_No1": "9687330331",
                               "Phone_No2": "",
                               "Latitude": "23.066149",
                               "Longitude": "72.667999"
                            },
                            {
                               "Franchisee_Code": "COWSS-4531",
                               "Franchisee_Name": "Kidzee Mani Nagar",
                               "Operating_Status": "A",
                               "Address1": "6, Radhavallabh Colony,",
                               "Address2": "Opp - Durga Apartments, Jaihind Circle,",
                               "Place": "Maninagar,",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4531@kidzee.com",
                               "Mobile_No": "8291104084",
                               "Phone_No1": "8291104084",
                               "Phone_No2": "",
                               "Latitude": "22.995036",
                               "Longitude": "72.606354"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4630",
                               "Franchisee_Name": "Kidzee Vaishnodevi circle",
                               "Operating_Status": "A",
                               "Address1": "GF-30,31,32,Malabar County-2,",
                               "Address2": "BH, Nirma University, Near- Vaishno Devi Circle, Tragad Road, Tragad",
                               "Place": "Vaishno Devi Circle",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4630@kidzee.com",
                               "Mobile_No": "6358745577",
                               "Phone_No1": "6358-745577",
                               "Phone_No2": "",
                               "Latitude": "23.128594",
                               "Longitude": "72.548489",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461495/10909/0bfdd452_d299_44d3_a72f_b94d1524d3c8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461495/10909/1a19d684_ad7b_4f43_8ed2_d3a07e1a82bd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461495/10909/abf685f4_8ed0_4c0f_ac2f_572a03e44ea7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461495/10909/bdc175bc_9125_4fee_bc6f_323ba2f24c87.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461495/10909/292d7753_483b_464e_9ca4_917c9754388f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461495/10909/23f94cd3_81c0_4ede_a085_845ccf40fd05.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461495/10909/3ee5fe34_a7cd_4b96_9c09_f2563699b7f9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461495/10909/a22e0a4d_cb2b_4424_bf1c_7786ba20e20d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461495/10909/3306d1ef_50b5_471c_bbdf_6b0bf9e5cb89.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-5303",
                               "Franchisee_Name": "Kidzee New Vastral",
                               "Operating_Status": "A",
                               "Address1": "31,Akshardham Homes,TP-113",
                               "Address2": "BH-Reliance Petrol Pump,Nr. United School,SP.Ring Road ,New Vastral",
                               "Place": "New Vastral Near Reliance Petrol Pump",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5303@kidzee.com",
                               "Mobile_No": "8511034318",
                               "Phone_No1": "8511034318",
                               "Phone_No2": "",
                               "Latitude": "23.00474",
                               "Longitude": "72.67036"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5537",
                               "Franchisee_Name": "KIDZEE JANTA NAGAR,",
                               "Operating_Status": "A",
                               "Address1": "A-14, GYANSAGAR SOCIETY, NR KEDIA NAKA",
                               "Address2": "CHANDKHEDA",
                               "Place": "JANTA NAGAR,",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "KIDZEE3754@KIDZEE.COM",
                               "Mobile_No": "9099914571",
                               "Phone_No1": "9099914571",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4909",
                               "Franchisee_Name": "Kidzee Motera Laugh N' Learn Academy",
                               "Operating_Status": "A",
                               "Address1": "A/19, Shraddha Bungalows,",
                               "Address2": "BH,4D, Square Mall,Motera,",
                               "Place": "Motera,",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4909@kidzee.com",
                               "Mobile_No": "9427955065",
                               "Phone_No1": "9427955065",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4974",
                               "Franchisee_Name": "Kidzee Chandkheda",
                               "Operating_Status": "A",
                               "Address1": "Shop No.1,2,3, Madhuram Royal, Zundal-Tragad Road,",
                               "Address2": "Nr. Swati Green, BH,Styamev Hospital",
                               "Place": "New-Chandkheda,",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4974@kidzee.com",
                               "Mobile_No": "9265534379",
                               "Phone_No1": "9427398989",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4766",
                               "Franchisee_Name": "KIDZEE NAROL",
                               "Operating_Status": "A",
                               "Address1": "A-03, Sangani Upvan Bungalow,",
                               "Address2": "Opp. Laxmi Niwas, Nr-Karnavati-4,",
                               "Place": "Narol",
                               "City_Id": "38",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4766@kidzee.com",
                               "Mobile_No": "9879869077",
                               "Phone_No1": "9879869077",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Anjar"
                      },
                      {
                         "City_Name": "Baroda",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-S-S-1631",
                               "Franchisee_Name": "Kidzee – Karelibaug, Baroda",
                               "Operating_Status": "A",
                               "Address1": "Saidham Bungalow, Next to Shri Hari Apartment,",
                               "Address2": "Near-Chandravati Society, Chandravali Char Rasta, Water Tank Road",
                               "Place": "Karelibaug ,Water tank road",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1631@kidzee.com",
                               "Mobile_No": "9687621744",
                               "Phone_No1": "9687621744",
                               "Phone_No2": "",
                               "Latitude": "22.320242",
                               "Longitude": "73.204199"
                            },
                            {
                               "Franchisee_Code": "W-S-S-1658",
                               "Franchisee_Name": "Kidzee - Vasna Road",
                               "Operating_Status": "A",
                               "Address1": "Plot No 5, Besides Yoeshwar Apartment,",
                               "Address2": "Near Old Vasna Jakat Naka,",
                               "Place": "Vadodara ,Near Old Vasna Jakat Naka",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1658@kidzee.com",
                               "Mobile_No": "9537000600",
                               "Phone_No1": "9687621744",
                               "Phone_No2": "",
                               "Latitude": "22.294883",
                               "Longitude": "73.151578"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3565",
                               "Franchisee_Name": "Kidzee Padra",
                               "Operating_Status": "A",
                               "Address1": "C 3, Gokuldham Society,",
                               "Address2": "Station Road,",
                               "Place": "Padra, Near Main Gate Gokuldham Society,",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3565@kidzee.com",
                               "Mobile_No": "9714148101",
                               "Phone_No1": "9714148101",
                               "Phone_No2": "",
                               "Latitude": "22.237665",
                               "Longitude": "73.088487"
                            },
                            {
                               "Franchisee_Code": "W-B-S-841",
                               "Franchisee_Name": "KIDZEE-ALKAPURI-BARODA / Little Angels",
                               "Operating_Status": "A",
                               "Address1": "6/B, Anand Nagar Society,",
                               "Address2": "Productivity Road, ",
                               "Place": "Alkapuri, Near Mahadev Temple,",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee841@kidzee.com",
                               "Mobile_No": "9879690803",
                               "Phone_No1": "2652320244",
                               "Phone_No2": "",
                               "Latitude": "22.308073",
                               "Longitude": "73.171371"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4574",
                               "Franchisee_Name": "Kidzee Gorwa",
                               "Operating_Status": "A",
                               "Address1": "B-2,Sahayog Society,",
                               "Address2": "Refinery Road,",
                               "Place": "Gorwa, Near-Sahayog Garden,",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4574@kidzee.com",
                               "Mobile_No": "6352789297",
                               "Phone_No1": "7567866987",
                               "Phone_No2": "",
                               "Latitude": "22.33659",
                               "Longitude": "73.149224"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4625",
                               "Franchisee_Name": "Kidzee Harni",
                               "Operating_Status": "A",
                               "Address1": "C-32, Castle Villa,",
                               "Address2": "Harni Motnath Road, BH Cygnus World School,",
                               "Place": "Harni, Behind Cygnus World School",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4625@kidzee.com",
                               "Mobile_No": "9909226969",
                               "Phone_No1": "9909-226969",
                               "Phone_No2": "",
                               "Latitude": "27.2038",
                               "Longitude": "77.5011"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5090",
                               "Franchisee_Name": "Kidzee Manjalpur",
                               "Operating_Status": "A",
                               "Address1": "34 A Abhishek Society",
                               "Address2": "Near. Vrajdham Mandir",
                               "Place": "Manjalpur ,Near Vrajdham Mandir",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5090@kidzee.com",
                               "Mobile_No": "8200247408",
                               "Phone_No1": "8200247408",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5571",
                               "Franchisee_Name": "Kidzee Sun Pharma Road",
                               "Operating_Status": "A",
                               "Address1": "B-5 Shreejee Banglow ,",
                               "Address2": "opposite Vad garden ,Vadodara",
                               "Place": "Sun pharma road",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5571@kidzee.com",
                               "Mobile_No": "9879512793",
                               "Phone_No1": "9879512793",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5030",
                               "Franchisee_Name": "Kidzee Sayajipura",
                               "Operating_Status": "A",
                               "Address1": "B15, Upvan Villa,",
                               "Address2": "Opp. Banyan City, Near.Sayaji Town Ship,",
                               "Place": "Sayajipura,",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5030@kidzee.com",
                               "Mobile_No": "9687621744",
                               "Phone_No1": "9687621744",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5022",
                               "Franchisee_Name": "Kidzee Sama Salvi Road",
                               "Operating_Status": "A",
                               "Address1": "6,Naimisharanya Society",
                               "Address2": "Beside Gangotri Party Plot,Opp. Sama Lake",
                               "Place": "Sama-Savli Road ,Opp. Sama Lake",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5022@kidzee.com",
                               "Mobile_No": "9662540021",
                               "Phone_No1": "9662540021",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5332",
                               "Franchisee_Name": "Kidzee Nizampura",
                               "Operating_Status": "A",
                               "Address1": "B/17, Ramnagar Society,",
                               "Address2": "B/H Mehsana Nagar",
                               "Place": "Nizampura",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee5332@kidzee.com",
                               "Mobile_No": "9586724245",
                               "Phone_No1": "9586724245",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5475",
                               "Franchisee_Name": "Kidzee MAKHARPURA",
                               "Operating_Status": "A",
                               "Address1": "C-109, PRANAV SOCIETY,",
                               "Address2": "TULSIDHAM ROAD, MANJALPUR",
                               "Place": "MAKHARPURA",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5475@kidzee.com",
                               "Mobile_No": "9714008931",
                               "Phone_No1": "9714008931",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5320",
                               "Franchisee_Name": "Kidzee  Ajwa-Waghodia Crossing",
                               "Operating_Status": "A",
                               "Address1": "GF-1&2,Lily-1,KC Residency",
                               "Address2": "L&T Knowledge City,N.H.-8,Nr. Ajwa-Waghodia Crossing",
                               "Place": "Vadodara ,Inside L&T",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5320@kidzee.com",
                               "Mobile_No": "8511467076",
                               "Phone_No1": "8511-467076",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": "",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2788224/11050/1cda7eca_d24d_488d_a55d_30bc29aac574.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2788224/11050/e82a0be8_8193_41b4_9e58_75d26e5feff0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2788224/11050/5d09aa32_955d_49c7_b708_efa9ba8013e7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2788224/11050/390eb0a6_9e7e_40a2_af8f_d4e361152034.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2788224/11050/56a5141a_9e08_4aaf_92df_fdb90e9cfc7a.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-4313",
                               "Franchisee_Name": "Kidzee Sangam Cross Road",
                               "Operating_Status": "A",
                               "Address1": "The New Horizon School,",
                               "Address2": "Nr.Sangam Cross Road, Opp.Vrundavan Township, Karelibag,",
                               "Place": "Harni Road, New Horizon School,",
                               "City_Id": "119",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4313@kidzee.com",
                               "Mobile_No": "9913792283",
                               "Phone_No1": "9898318186",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Bharuch",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2880",
                               "Franchisee_Name": "Kidzee Zadeshwar,Bharuch",
                               "Operating_Status": "A",
                               "Address1": "Intelectual Academy,B-35 Atmiya nagar",
                               "Address2": "opp.KGM school",
                               "Place": "Zadeshwar Dist Bharuch,",
                               "City_Id": "148",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2880@kidzee.com",
                               "Mobile_No": "7016501022",
                               "Phone_No1": "7600818328",
                               "Phone_No2": "",
                               "Latitude": "21.714214",
                               "Longitude": "73.031673"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4961",
                               "Franchisee_Name": "Kidzee Bharuch",
                               "Operating_Status": "A",
                               "Address1": "B-56-57,Falshruti Nagar,",
                               "Address2": "Near-Railway Station Road,",
                               "Place": "Near-Railway Station, Bharuch,",
                               "City_Id": "148",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4961@kidzee.com",
                               "Mobile_No": "8490890849",
                               "Phone_No1": "8490890849",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Bhavnagar",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-5250",
                            "Franchisee_Name": "Kidzee Kaliyabid Bhavnagar",
                            "Operating_Status": "A",
                            "Address1": "D-41 Kaliya Bid",
                            "Address2": "Opp. Lakhubha Hall,Nr. Chamunda Xerox",
                            "Place": "Kaliabid ,Bhavnagar",
                            "City_Id": "150",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5250@kidzee.com",
                            "Mobile_No": "9586909333",
                            "Phone_No1": "9586909333",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Bhuj",
                         "Franchisee": {
                            "Franchisee_Code": "W-B-C-835",
                            "Franchisee_Name": "KIDZEE-BHUJ-GUJARAT",
                            "Operating_Status": "A",
                            "Address1": "Plot No-30, Near-Sarvajanik Plot,",
                            "Address2": "Mandvi Road,",
                            "Place": "Jadavji Nagar,",
                            "City_Id": "167",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzee835@kidzee.com",
                            "Mobile_No": "8460253000",
                            "Phone_No1": "8460253000",
                            "Phone_No2": "",
                            "Latitude": "23.24487",
                            "Longitude": "69.671572"
                         }
                      },
                      {
                         "City_Name": "Deesa",
                         "Franchisee": {
                            "Franchisee_Code": "W-U-U-414",
                            "Franchisee_Name": "Kidzee-Parent's Pride - Deesa",
                            "Operating_Status": "A",
                            "Address1": "Abhinandan Society,",
                            "Address2": "",
                            "Place": "Opp- Jalaram Mandir Highway, Dessa",
                            "City_Id": "240",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzee414@kidzee.com",
                            "Mobile_No": "9265856355",
                            "Phone_No1": "9265856355",
                            "Phone_No2": "",
                            "Latitude": "24.264032",
                            "Longitude": "72.185854"
                         }
                      },
                      {
                         "City_Name": "Derol",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4271",
                            "Franchisee_Name": "Kidzee Dhrol",
                            "Operating_Status": "A",
                            "Address1": "Pramukh Darshan Residency,",
                            "Address2": "",
                            "Place": "Dhrol, Kharva Road,",
                            "City_Id": "249",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4271@kidzee.com",
                            "Mobile_No": "6351060498",
                            "Phone_No1": "9979176727",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Ghandhidham"
                      },
                      {
                         "City_Name": "Gandhinagar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3764",
                               "Franchisee_Name": "Kidzee Raysan",
                               "Operating_Status": "A",
                               "Address1": "7,symphony park,opp - sarthak camphor",
                               "Address2": "raysan petrol pump road,raysan,",
                               "Place": "Raysan ,Near PDPU",
                               "City_Id": "343",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3764@kidzee.com",
                               "Mobile_No": "9099465160",
                               "Phone_No1": "9099465160",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4117",
                               "Franchisee_Name": "Kidzee Kudasan",
                               "Operating_Status": "A",
                               "Address1": "29.Navkar Bungalows,",
                               "Address2": "Nr. Mahalaxmi-4, Urjanagar,",
                               "Place": "Kudasan,",
                               "City_Id": "343",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4117@kidzee.com",
                               "Mobile_No": "7016262728",
                               "Phone_No1": "7016262728",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Jamnagar",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2951",
                            "Franchisee_Name": "Kidzee Jamnagar",
                            "Operating_Status": "A",
                            "Address1": "Plot no. 58/3",
                            "Address2": "Janta society, Near Janta Fatak,",
                            "Place": "Jamnagar ,Near Janta Phatak",
                            "City_Id": "484",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2951@kidzee.com",
                            "Mobile_No": "9925114111",
                            "Phone_No1": "02882713316",
                            "Phone_No2": "",
                            "Latitude": "22.46998",
                            "Longitude": "70.050511"
                         }
                      },
                      {
                         "City_Name": "Junagadh"
                      },
                      {
                         "City_Name": "Kalol"
                      },
                      {
                         "City_Name": "Lunavada",
                         "Franchisee": {
                            "Franchisee_Code": "W-G-K-762",
                            "Franchisee_Name": "Kidzee - lunavada",
                            "Operating_Status": "A",
                            "Address1": "Near-52,Patidar Samaj Ghar,",
                            "Address2": "Near-",
                            "Place": "Char Kosiya Naka,",
                            "City_Id": "627",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzee762@kidzee.com",
                            "Mobile_No": "9979698101",
                            "Phone_No1": "02674-250443",
                            "Phone_No2": "",
                            "Latitude": "23.132",
                            "Longitude": "73.602"
                         }
                      },
                      {
                         "City_Name": "Navsari"
                      },
                      {
                         "City_Name": "Palanpur"
                      },
                      {
                         "City_Name": "Pethapur"
                      },
                      {
                         "City_Name": "Rajkot",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2469",
                               "Franchisee_Name": "Kidzee Bhakti Nagar  Rajkot",
                               "Operating_Status": "A",
                               "Address1": "Plot No 64, Opp Gurukul Gate,",
                               "Address2": "Near-RMC Office, Dhebar Road,",
                               "Place": "Bhakti Nagar,",
                               "City_Id": "827",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2469@kidzee.com",
                               "Mobile_No": "8141927554",
                               "Phone_No1": "8141927554",
                               "Phone_No2": "",
                               "Latitude": "22.27669332185902",
                               "Longitude": "70.80058795770263"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5202",
                               "Franchisee_Name": "Kidzee Rajkot",
                               "Operating_Status": "A",
                               "Address1": "Plot No 21/D,R.S. No. 85/1",
                               "Address2": "Jyotinagar main road,University Road,",
                               "Place": "Beside masoom school,Akashwani Chowk,",
                               "City_Id": "827",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5202@kidzee.com",
                               "Mobile_No": "7575099199",
                               "Phone_No1": "7575099199",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Samakhiali"
                      },
                      {
                         "City_Name": "Surat",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-B-S-598",
                               "Franchisee_Name": "Kidzee - Parvatpatia - Surat",
                               "Operating_Status": "A",
                               "Address1": "39, Karuna Sagar Society,",
                               "Address2": "Near-Model Town Circle,",
                               "Place": "Parvat Patia, Near-Char Rasta,",
                               "City_Id": "937",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee598@kidzee.com",
                               "Mobile_No": "8141322522",
                               "Phone_No1": "9377401002",
                               "Phone_No2": "",
                               "Latitude": "21.17024",
                               "Longitude": "72.831061"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2821",
                               "Franchisee_Name": "Kidzee Kamrej, Surat",
                               "Operating_Status": "A",
                               "Address1": "29/30 Radhe Krishna Society,",
                               "Address2": "Kamrej Gam Road,",
                               "Place": "Near- Sarjan Hospital, Kamrej , Near Petrol Pump,",
                               "City_Id": "937",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2821@kidzee.com",
                               "Mobile_No": "9106619152",
                               "Phone_No1": "7069871148",
                               "Phone_No2": "",
                               "Latitude": "21.267554",
                               "Longitude": "72.960857"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4359",
                               "Franchisee_Name": "Kidzee New City Light Road",
                               "Operating_Status": "A",
                               "Address1": "Moje-Althan, Survey No- 81/1/B, Block No-100,",
                               "Address2": "Plot No-95, China Gate -2,",
                               "Place": "New City Light Road, Surat, ",
                               "City_Id": "937",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4359@kidzee.com",
                               "Mobile_No": "9090903790",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "21.152181",
                               "Longitude": "72.798936"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4792",
                               "Franchisee_Name": "Kidzee Sachin",
                               "Operating_Status": "A",
                               "Address1": "Plot no 233,",
                               "Address2": "Near Nawab Sahib Killa,",
                               "Place": "Surat Navsari main road, Sachin ,Sachin",
                               "City_Id": "937",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4792@kidzee.com",
                               "Mobile_No": "9714109614",
                               "Phone_No1": "9714109614",
                               "Phone_No2": "",
                               "Latitude": "21.0858190",
                               "Longitude": "72.8820776"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4866",
                               "Franchisee_Name": "Kidzee Kosad",
                               "Operating_Status": "A",
                               "Address1": "Plot No-75, Avadh Row House,",
                               "Address2": "Opp. Srushti Row House,",
                               "Place": "Kosad,",
                               "City_Id": "937",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4866@kidzee.com",
                               "Mobile_No": "9904222006",
                               "Phone_No1": "9904-222006",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": "",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2606735/11019/4bdf5985_a47b_4ced_abad_1c175b70a2de.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-5307",
                               "Franchisee_Name": "Kidzee Mota Varachha",
                               "Operating_Status": "A",
                               "Address1": "Plot No.47,Bahucharnagar Soc.",
                               "Address2": "Opp. Lack Garden, Ram Chowk",
                               "Place": "Mota Varachha ,Ram chowk",
                               "City_Id": "937",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5307@kidzee.com",
                               "Mobile_No": "7777919190",
                               "Phone_No1": "9898645065",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Surendranagar",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2710",
                            "Franchisee_Name": "Kidzee Wadhwan Surendranagar",
                            "Operating_Status": "A",
                            "Address1": "Near -C.U. Shah, Medical Hall, Plot No-1213,",
                            "Address2": "80 Ft Road,",
                            "Place": "Wadhwan, Surendranagar,",
                            "City_Id": "939",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2710@kidzee.com",
                            "Mobile_No": "8866586503",
                            "Phone_No1": "02752-240232",
                            "Phone_No2": "",
                            "Latitude": "22.720717214916945",
                            "Longitude": "71.66171304356385"
                         }
                      },
                      {
                         "City_Name": "Valsad / Bulsar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3345",
                               "Franchisee_Name": "Kidzee Parnera",
                               "Operating_Status": "A",
                               "Address1": "Saraswati  Bhavan, Bunglow No-28, Parnera Hills,",
                               "Address2": "Parnera Valsad Road,  DIST.Valsad,",
                               "Place": "Parnera,",
                               "City_Id": "995",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3345@kidzee.com",
                               "Mobile_No": "8401204428",
                               "Phone_No1": "7874960095",
                               "Phone_No2": "",
                               "Latitude": "20.558169",
                               "Longitude": "72.948504"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4346",
                               "Franchisee_Name": "Kidzee Bhilad",
                               "Operating_Status": "A",
                               "Address1": "Plot No-06,",
                               "Address2": "Survey No.171/1, Bhilad,Tal-",
                               "Place": "Umbergaon, Valsad,",
                               "City_Id": "995",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4346@kidzee.com",
                               "Mobile_No": "9898968688",
                               "Phone_No1": "9898968688",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4872",
                               "Franchisee_Name": "Kidzee Killa-Pardi , Valsad",
                               "Operating_Status": "A",
                               "Address1": "House No.4441, Rashmi Society,",
                               "Address2": "N.H. No.8,",
                               "Place": "Killa-Pardi, Valsad,",
                               "City_Id": "995",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4872@kidzee.com",
                               "Mobile_No": "9601600146",
                               "Phone_No1": "9601600146",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Vapi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-C-U-242",
                               "Franchisee_Name": "Kidzee-Vapi / Shishtachar",
                               "Operating_Status": "A",
                               "Address1": "Plot D-1, Opp. Jain Temple,",
                               "Address2": " Near GIDC Garden, ",
                               "Place": "Sristachar ,Nr Jain Temple",
                               "City_Id": "997",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee242@kidzee.com",
                               "Mobile_No": "9824100656",
                               "Phone_No1": "02606453392",
                               "Phone_No2": "",
                               "Latitude": "20.376934",
                               "Longitude": "72.924555"
                            },
                            {
                               "Franchisee_Code": "W-L-C-1659",
                               "Franchisee_Name": "Kidzee-Vapi",
                               "Operating_Status": "A",
                               "Address1": "Sanatan Hall, Ajit Nagar,",
                               "Address2": "Chala,",
                               "Place": "Daman Road, Vapi Daman Road,",
                               "City_Id": "997",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1659@kidzee.com",
                               "Mobile_No": "9979937686",
                               "Phone_No1": "9824350009",
                               "Phone_No2": "",
                               "Latitude": "20.390841",
                               "Longitude": "72.896181"
                            }
                         ]
                      },
                      {
                         "City_Name": "Veraval",
                         "Franchisee": {
                            "Franchisee_Code": "A-W-A-1090",
                            "Franchisee_Name": "Ankurum Verval",
                            "Operating_Status": "A",
                            "Address1": "Pitru Smriti, Srinathji Nagar, Nr. Water Tank",
                            "Address2": "Junagarh Road, Dist. Gir Somnath",
                            "Place": "Verval",
                            "City_Id": "1007",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzeeveraval@gmail.com",
                            "Mobile_No": "9426443099",
                            "Phone_No1": "9426443099",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Vavol"
                      },
                      {
                         "City_Name": "Mundra town"
                      },
                      {
                         "City_Name": "Mansa"
                      },
                      {
                         "City_Name": "Dwarka"
                      },
                      {
                         "City_Name": "Kadod"
                      },
                      {
                         "City_Name": "Meghpar"
                      },
                      {
                         "City_Name": "Bhiloda"
                      },
                      {
                         "City_Name": "Modasa"
                      },
                      {
                         "City_Name": "Anand"
                      },
                      {
                         "City_Name": "Andada"
                      },
                      {
                         "City_Name": "Vallabh vidhyanagar / V. V. nagar"
                      },
                      {
                         "City_Name": "Mehsana",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4771",
                            "Franchisee_Name": "Kidzee Radhanpur Road",
                            "Operating_Status": "A",
                            "Address1": "2-Jayambe Society,",
                            "Address2": "Opp.Sai Krishna Society,",
                            "Place": "Radhanpur Road ,Radhanpur Road,",
                            "City_Id": "1346",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4771@kidzee.com",
                            "Mobile_No": "9898669877",
                            "Phone_No1": "9898669877",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Mahuva",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2282",
                            "Franchisee_Name": "kidzee Mahuva  Gujarat",
                            "Operating_Status": "A",
                            "Address1": "7-Shrijiniketan Bungloes,",
                            "Address2": "Nr Shikshak Society,",
                            "Place": "Near Shikshak Society",
                            "City_Id": "1348",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2282@kidzee.com",
                            "Mobile_No": "9825830393",
                            "Phone_No1": "9825830393",
                            "Phone_No2": "",
                            "Latitude": "21.7161",
                            "Longitude": "72.9958"
                         }
                      },
                      {
                         "City_Name": "Morbi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2363",
                               "Franchisee_Name": "Kidzee Morbi-2 Gujrat",
                               "Operating_Status": "A",
                               "Address1": "New Shanti Bhuvan Palace",
                               "Address2": "Opp Mayur Hospital, L E College Road",
                               "Place": "Morbi ,Mayur Hospital",
                               "City_Id": "1371",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2363@kidzee.com",
                               "Mobile_No": "9099278017",
                               "Phone_No1": "9429577848",
                               "Phone_No2": "",
                               "Latitude": "22.811989",
                               "Longitude": "70.823619"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5441",
                               "Franchisee_Name": "Kidzee Ravapar Road, Morbi",
                               "Operating_Status": "A",
                               "Address1": "SERVEY NO 155/3, P-4 ",
                               "Address2": "At Ravapar ,Near. Navajivan  Vidhyalaya,",
                               "Place": "Ravapar Road",
                               "City_Id": "1371",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5441@kidzee.com",
                               "Mobile_No": "9879582540",
                               "Phone_No1": "9879582540",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Jhalod"
                      },
                      {
                         "City_Name": "Amreli",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4093",
                            "Franchisee_Name": "Kidzee Rajula",
                            "Operating_Status": "A",
                            "Address1": "Shree Dharnath Krupa,",
                            "Address2": "Krishna Nagar Society, BH. Gayatri Temple,",
                            "Place": "Rajula,",
                            "City_Id": "1405",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4093@kidzee.com",
                            "Mobile_No": "9825162890",
                            "Phone_No1": "027941111",
                            "Phone_No2": "",
                            "Latitude": "21.0270120",
                            "Longitude": "71.4442030"
                         }
                      },
                      {
                         "City_Name": "Ankaleshwar",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2300",
                            "Franchisee_Name": "Kidzee Ankleshwar",
                            "Operating_Status": "A",
                            "Address1": "Plot No. 2533, Near Sajjan India Colony,",
                            "Address2": "GIDC",
                            "Place": "Near Sajjan India Colony",
                            "City_Id": "1407",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2300@kidzee.com",
                            "Mobile_No": "9227455102",
                            "Phone_No1": "9227455106",
                            "Phone_No2": "",
                            "Latitude": "23.042242",
                            "Longitude": "72.634335"
                         }
                      },
                      {
                         "City_Name": "Borsad"
                      },
                      {
                         "City_Name": "Himatnagar",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4512",
                            "Franchisee_Name": "Kidzee Himmatnagar",
                            "Operating_Status": "A",
                            "Address1": "19,Shanti Sadan Society,",
                            "Address2": "Khed-Tasiya Road, Beside-Digambar Wadi, Sabarkantha,",
                            "Place": "Mahavirnagar, Beside-Digambar Wadi,",
                            "City_Id": "1486",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4512@kidzee.com",
                            "Mobile_No": "9558306280",
                            "Phone_No1": "7575004709",
                            "Phone_No2": "",
                            "Latitude": "23.599308",
                            "Longitude": "72.970617"
                         }
                      },
                      {
                         "City_Name": "Arakalgud"
                      },
                      {
                         "City_Name": "Kapadvanj"
                      },
                      {
                         "City_Name": "Godhra"
                      },
                      {
                         "City_Name": "Porbandar"
                      },
                      {
                         "City_Name": "Kutch",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-4052",
                               "Franchisee_Name": "Kidzee Nakhatrana",
                               "Operating_Status": "A",
                               "Address1": "Madhav Residency,",
                               "Address2": "Jaday Road, Saradar Road,",
                               "Place": "Nakhatrana,",
                               "City_Id": "1497",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4052@kidzee.com",
                               "Mobile_No": "9909227222",
                               "Phone_No1": "028351111",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-3933",
                               "Franchisee_Name": "Kidzee Vidya Bhuj",
                               "Operating_Status": "A",
                               "Address1": "House No 9",
                               "Address2": "Santosh Society, Near VD high school,",
                               "Place": "Bhuj ,Nead VD High School",
                               "City_Id": "1497",
                               "State_Id": "13",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3933@kidzee.com",
                               "Mobile_No": "9925154456",
                               "Phone_No1": "9925154456",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Talod",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2818",
                            "Franchisee_Name": "Kidzee Talod  S K Gujarat",
                            "Operating_Status": "A",
                            "Address1": "Jain Society, Opp Nagar Panchayat,",
                            "Address2": "Nagrik Bank Lane,",
                            "Place": "Talod Bazar, S.K.Gujarat, Opp. Jain Temple,",
                            "City_Id": "1513",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2818@kidzee.com",
                            "Mobile_No": "9998217519",
                            "Phone_No1": "9998217519",
                            "Phone_No2": "",
                            "Latitude": "23.351227",
                            "Longitude": "72.950495"
                         }
                      },
                      {
                         "City_Name": "Radhanpur",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2876",
                            "Franchisee_Name": "Kidzee Radhanpur  Patan",
                            "Operating_Status": "A",
                            "Address1": "No-6, Chamunda Society,",
                            "Address2": "Near- College campus, Near-Himmat Vidya Nagar,",
                            "Place": "Radhanpur Patan,",
                            "City_Id": "1537",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2876@kidzee.com",
                            "Mobile_No": "9429528176",
                            "Phone_No1": "8401566700",
                            "Phone_No2": "",
                            "Latitude": "23.828635",
                            "Longitude": "71.613935"
                         }
                      },
                      {
                         "City_Name": "Zadaeshwar"
                      },
                      {
                         "City_Name": "Dabhoi"
                      },
                      {
                         "City_Name": "Rajpipla"
                      },
                      {
                         "City_Name": "Parnera"
                      },
                      {
                         "City_Name": "Padra"
                      },
                      {
                         "City_Name": "UNA",
                         "Franchisee": {
                            "Franchisee_Code": "W-G-K-1033",
                            "Franchisee_Name": "Kidzee Gramin-Junagardh, Smart Academy",
                            "Operating_Status": "A",
                            "Address1": "12, Dayanand Society,",
                            "Address2": "",
                            "Place": "Unna, Girgadhada Road, Opp Dr Baldania Hospital,",
                            "City_Id": "1832",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1033@kidzee.com",
                            "Mobile_No": "9998855105",
                            "Phone_No1": "9825057255",
                            "Phone_No2": "",
                            "Latitude": "20.922992",
                            "Longitude": "70.92266"
                         }
                      },
                      {
                         "City_Name": "BAPUNAGAR"
                      },
                      {
                         "City_Name": "Rajula"
                      },
                      {
                         "City_Name": "Kheda"
                      },
                      {
                         "City_Name": "Halol",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4741",
                            "Franchisee_Name": "Kidzee Halol",
                            "Operating_Status": "A",
                            "Address1": "House No 2,Narmadanagar Soc",
                            "Address2": "Kanjari Road",
                            "Place": "Halol-Panchmahal Road ,Narmada Nagar Society",
                            "City_Id": "1891",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4741@kidzee.com",
                            "Mobile_No": "9426534304",
                            "Phone_No1": "9979988559",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Botad",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4744",
                            "Franchisee_Name": "Kidzee Botad",
                            "Operating_Status": "A",
                            "Address1": "Plot No-3, Opp Old Marketing Yard,",
                            "Address2": "Behind DySP Office,",
                            "Place": "Paliyad Road, Botad ,Behind DySP office",
                            "City_Id": "1892",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4744@kidzee.com",
                            "Mobile_No": "7984565003",
                            "Phone_No1": "7984565003",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Dhanera",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4791",
                            "Franchisee_Name": "Kidzee Dhanera",
                            "Operating_Status": "A",
                            "Address1": "Plot No. 35-36,Shreeram Society",
                            "Address2": "Nr. Vinayak School,",
                            "Place": "Banaskantha ,Dhanera",
                            "City_Id": "1902",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4791@kidzee.com",
                            "Mobile_No": "9727261262",
                            "Phone_No1": "9727261262",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Patan",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-5119",
                            "Franchisee_Name": "Kidzee Patan",
                            "Operating_Status": "A",
                            "Address1": "1,Karma Bhumi Society,",
                            "Address2": "Telephone Exchange Road",
                            "Place": "Patan ,Telephone Exchange Road",
                            "City_Id": "1935",
                            "State_Id": "13",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5119@kidzee.com",
                            "Mobile_No": "9898400792",
                            "Phone_No1": "9898400792",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Vyara"
                      },
                      {
                         "City_Name": "Dahod"
                      },
                      {
                         "City_Name": "UMBERGAON"
                      }
                   ]
                },
                {
                   "State_Name": "Haryana",
                   "City": [
                      {
                         "City_Name": "Ambala",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-4750",
                               "Franchisee_Name": "Kidzee Saha",
                               "Operating_Status": "A",
                               "Address1": "Mauja Dhakola,",
                               "Address2": "Hadbast Number-135,",
                               "Place": "Saha, Mauja Dhakola,",
                               "City_Id": "50",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4750@kidzee.com",
                               "Mobile_No": "9845162317",
                               "Phone_No1": "9845162317",
                               "Phone_No2": "",
                               "Latitude": "30.295980364647267",
                               "Longitude": "76.96716785430908"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5455",
                               "Franchisee_Name": "Kidzee Ambala City",
                               "Operating_Status": "A",
                               "Address1": "House no 431,",
                               "Address2": "Manali House",
                               "Place": "Ambala City",
                               "City_Id": "50",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5455@kidzee.com",
                               "Mobile_No": "8168172968",
                               "Phone_No1": "8168172968",
                               "Phone_No2": "",
                               "Latitude": "30.371270",
                               "Longitude": "76.786133"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5104",
                               "Franchisee_Name": "Kidzee Moti nagar Ambala",
                               "Operating_Status": "A",
                               "Address1": "1883/11, near gurudwara sahib",
                               "Address2": "basti lyalpuri, moti nagar",
                               "Place": "Moti Nagar ,Gurudwar Sahib basti iyalapuri",
                               "City_Id": "50",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5104@kidzee.com",
                               "Mobile_No": "9416944988",
                               "Phone_No1": "9416944988",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Bhiwani"
                      },
                      {
                         "City_Name": "Dharuhera"
                      },
                      {
                         "City_Name": "Sirsa"
                      },
                      {
                         "City_Name": "Fatehabad"
                      },
                      {
                         "City_Name": "Hisar"
                      },
                      {
                         "City_Name": "Jagadhri"
                      },
                      {
                         "City_Name": "Jhajjar",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4677",
                            "Franchisee_Name": "Kidzee Beri",
                            "Operating_Status": "A",
                            "Address1": "Swaraj Ganj Beri",
                            "Address2": "Swaraj Ganj Beri Near Main Bazar Beri",
                            "Place": "Ward no.10, Swaraj ganj, Pana Chajjan, tehsil beri",
                            "City_Id": "492",
                            "State_Id": "16",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4677@kidzee.com",
                            "Mobile_No": "9811261944",
                            "Phone_No1": "0125-1681916",
                            "Phone_No2": "",
                            "Latitude": "20.7013 N",
                            "Longitude": "76.5806 E",
                            "Images": [
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481128/10963/b65126c4_9364_4fff_97e6_4e431ad85345.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481128/10963/1b0f9425_c373_4ad0_b168_df17e3f0dc95.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481128/11000/27d321ce_ca12_4d88_8e79_00bc6b804870.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481128/11000/b9bde49f_b988_47d7_9bce_c578d8ffea2c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481128/11000/1fff711b_4a54_41ed_868f_56789d5f42a5.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481128/11000/da34bbd3_8aba_4f1e_afeb_bbdd65586867.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Jind"
                      },
                      {
                         "City_Name": "Kaithal",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-A-M-1364",
                               "Franchisee_Name": "Kidzee-Kaithal-Haryana",
                               "Operating_Status": "A",
                               "Address1": "Central Sen, Secondary School,",
                               "Address2": "",
                               "Place": "Kaithal, Khurana By Pass Road,",
                               "City_Id": "518",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1364@kidzee.com",
                               "Mobile_No": "9034301000",
                               "Phone_No1": "1746230294",
                               "Phone_No2": "",
                               "Latitude": "29.810408",
                               "Longitude": "76.397353"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5768",
                               "Franchisee_Name": "Kidzee Suncity Township",
                               "Operating_Status": "A",
                               "Address1": "Block no C, Sector 33",
                               "Address2": "Dhand Road",
                               "Place": "Suncity Township",
                               "City_Id": "518",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5768@kidzee.com",
                               "Mobile_No": "9034801097",
                               "Phone_No1": "9034801097",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5602",
                               "Franchisee_Name": "Kidzee Kaithal",
                               "Operating_Status": "A",
                               "Address1": "Radha Krishna Sr sec School",
                               "Address2": "Near New Green Market,",
                               "Place": "Model Town",
                               "City_Id": "518",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5602@kidzee.com",
                               "Mobile_No": "9416253068",
                               "Phone_No1": "9416253068",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5480",
                               "Franchisee_Name": "Kidzee Kaithal",
                               "Operating_Status": "A",
                               "Address1": "Adjacent to Ganpati Hospital ",
                               "Address2": "Near Padma City Mall",
                               "Place": "Kaithal ",
                               "City_Id": "518",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5480@kidzee.com",
                               "Mobile_No": "9034664920",
                               "Phone_No1": "9034664920",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Karnal",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-5289",
                               "Franchisee_Name": "Kidzee Char Chaman",
                               "Operating_Status": "A",
                               "Address1": "New Char Chaman, Behind HDFC Bank",
                               "Address2": "Kunjpura Road,",
                               "Place": "New Char Chaman, Behind HDFC Bank, Kunjpura Road,",
                               "City_Id": "539",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5289@kidzee.com",
                               "Mobile_No": "9882589864",
                               "Phone_No1": "7082503873",
                               "Phone_No2": "",
                               "Latitude": "29.687992",
                               "Longitude": "76.992502"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4430",
                               "Franchisee_Name": "Kidzee Karan Vihar",
                               "Operating_Status": "A",
                               "Address1": "Brij Sharma Colony,",
                               "Address2": "",
                               "Place": "Karan Vihar, Brij Sharma Colny,",
                               "City_Id": "539",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4430@kidzee.com",
                               "Mobile_No": "8930406918",
                               "Phone_No1": "8930406918",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Khajuri"
                      },
                      {
                         "City_Name": "Kurukshetra",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-A-M-1248",
                               "Franchisee_Name": "Kidzee-Kurukshetra,Haryana/Bal shiksha Niketan Samiti",
                               "Operating_Status": "A",
                               "Address1": "Opposite House No.709,",
                               "Address2": "",
                               "Place": "Kurukshetra, Sector-3, Urban Estate,",
                               "City_Id": "604",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1248@kidzee.com",
                               "Mobile_No": "8396000116",
                               "Phone_No1": "8396000116",
                               "Phone_No2": "",
                               "Latitude": "30.696064",
                               "Longitude": "76.864889"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4594",
                               "Franchisee_Name": "Kidzee Ismailabad",
                               "Operating_Status": "A",
                               "Address1": "Near-Kamla Rice Mill,",
                               "Address2": "",
                               "Place": "Ismailabad, Near-Katgarh Road,",
                               "City_Id": "604",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4594@kidzee.com",
                               "Mobile_No": "9416084954",
                               "Phone_No1": "9416084954",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5710",
                               "Franchisee_Name": "Kidzee Thanesar",
                               "Operating_Status": "A",
                               "Address1": "House No.-1458, ",
                               "Address2": "Word No. -5, Jyoti Nagar",
                               "Place": "Thanesar",
                               "City_Id": "604",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5710@kidzee.com",
                               "Mobile_No": "9896698412",
                               "Phone_No1": "9896698412",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4395",
                               "Franchisee_Name": "Kidzee Kalyan Nagar",
                               "Operating_Status": "A",
                               "Address1": "Gali No -2,  Number 949/ 26, Darra Khera,",
                               "Address2": "Opposite K.U.K. 2nd Gate, Thanesar",
                               "Place": "Kalyan Nagar, Opposite Civil Hospital,",
                               "City_Id": "604",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4395@kidzee.com",
                               "Mobile_No": "8397070555",
                               "Phone_No1": "9034301000",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Mandi dabwali"
                      },
                      {
                         "City_Name": "Narnaul"
                      },
                      {
                         "City_Name": "Panchkula",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2765",
                               "Franchisee_Name": "Kidzee Village Bhagwanpur  Panchkula",
                               "Operating_Status": "A",
                               "Address1": "Amravati Enclave,",
                               "Address2": "Village Bhagwanpur,",
                               "Place": "Panchkula, Amravati Enclave,",
                               "City_Id": "758",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee2765@kidzee.com",
                               "Mobile_No": "9855649931",
                               "Phone_No1": "9814015297",
                               "Phone_No2": "",
                               "Latitude": "30.751596",
                               "Longitude": "76.911006"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4471",
                               "Franchisee_Name": "Kidzee Panchkula",
                               "Operating_Status": "A",
                               "Address1": "Swastik Vihar, Phase-III,",
                               "Address2": "MDC Complex, Jai Shree Tower,",
                               "Place": "Panchkula, MDC Sector-5,",
                               "City_Id": "758",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4471@kidzee.com",
                               "Mobile_No": "9872400093",
                               "Phone_No1": "9872400093",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Palwal"
                      },
                      {
                         "City_Name": "Panipat",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-G-K-1147",
                               "Franchisee_Name": "Kidzee-Samalkha City",
                               "Operating_Status": "A",
                               "Address1": "Behind-Bala Ji Market,",
                               "Address2": "",
                               "Place": "Samalkha ,Near-Saroj Hospital,",
                               "City_Id": "767",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1147@kidzee.com",
                               "Mobile_No": "9996361232",
                               "Phone_No1": "8708916358",
                               "Phone_No2": "",
                               "Latitude": "29.240563",
                               "Longitude": "77.011305"
                            },
                            {
                               "Franchisee_Code": "N-S-C-1613",
                               "Franchisee_Name": "Kidzee-Panipat",
                               "Operating_Status": "A",
                               "Address1": "285-L, Near Singla Nursing Home,",
                               "Address2": "",
                               "Place": "Model Town, Panipat ,Near Goyal Nursing Home",
                               "City_Id": "767",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee1613@kidzee.com",
                               "Mobile_No": "9729435800",
                               "Phone_No1": "01804014700",
                               "Phone_No2": "",
                               "Latitude": "76.958511",
                               "Longitude": "29.388081",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/464640/10924/50a4d218_e4f4_4a0f_99c7_7c964c79c8a9.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-5315",
                               "Franchisee_Name": "Kidzee Panipat",
                               "Operating_Status": "A",
                               "Address1": "SC-1, Sector-4",
                               "Address2": "Estate GT Road,",
                               "Place": "Eldeco",
                               "City_Id": "767",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5315@kidzee.com",
                               "Mobile_No": "9817207317",
                               "Phone_No1": "9817207317",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5023",
                               "Franchisee_Name": "Kidzee Sushant City",
                               "Operating_Status": "A",
                               "Address1": "Block -F,Nursery School Site",
                               "Address2": "Ansal Sushant City",
                               "Place": "Ansal, Ansal Sushant City",
                               "City_Id": "767",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5023@kidzee.com",
                               "Mobile_No": "9812600181",
                               "Phone_No1": "9812600181",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Rewari",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-U-377",
                               "Franchisee_Name": "Kidzee-Bountra Academy",
                               "Operating_Status": "A",
                               "Address1": "Near-HUDA Gymkhana Club,",
                               "Address2": "NS-6,",
                               "Place": "Sector-4, Huda Gymkhana Club",
                               "City_Id": "838",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "kidzee377@kidzee.com",
                               "Mobile_No": "9728158282",
                               "Phone_No1": "1274251443",
                               "Phone_No2": "",
                               "Latitude": "28.185514",
                               "Longitude": "76.630441"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3312",
                               "Franchisee_Name": "Kidzee  Rewari",
                               "Operating_Status": "A",
                               "Address1": "Street No-1, Phase-1,",
                               "Address2": "Rao Tula Ram Vihar,",
                               "Place": "Rewari, Near-Gopal Dev Chowk,",
                               "City_Id": "838",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3312@kidzee.com",
                               "Mobile_No": "9416897032",
                               "Phone_No1": "9466227032",
                               "Phone_No2": "",
                               "Latitude": "28.193259",
                               "Longitude": "76.597785",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1390284/10489/0b28f162_8dbe_4272_81c4_6ba278bc7aa5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1390284/10489/132cdc7c_e057_4a80_beac_fa1a95bd2e88.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1390284/10489/53eef397_688e_4edd_becc_89fa433d1e96.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1390284/10489/f134ed2c_8c1d_4d24_b7fd_1aa49282f0d7.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1390284/10731/83b61e55_f66a_4371_bd68_74a932c589cd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1390284/10731/d42c8f8e_e77e_4f94_ac95_307a050142f3.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-4299",
                               "Franchisee_Name": "Kidzee Suncity Rewari",
                               "Operating_Status": "A",
                               "Address1": "NS-3, Block-C,",
                               "Address2": "Ground Floor,",
                               "Place": "Suncity,",
                               "City_Id": "838",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4299@kidzee.com",
                               "Mobile_No": "9466414666",
                               "Phone_No1": "9711919382",
                               "Phone_No2": "",
                               "Latitude": "28.2071130",
                               "Longitude": "76.6296150"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4374",
                               "Franchisee_Name": "Kidzee Nangli",
                               "Operating_Status": "A",
                               "Address1": "Nalanda Education Society,",
                               "Address2": "Ground Floor,",
                               "Place": "Nangali Parshapur,",
                               "City_Id": "838",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4374@kidzee.com",
                               "Mobile_No": "9871289696",
                               "Phone_No1": "9871289696",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5644",
                               "Franchisee_Name": "Kidzee Kosli",
                               "Operating_Status": "A",
                               "Address1": "Plot no.-122",
                               "Address2": "Village Kosli",
                               "Place": "Kosli",
                               "City_Id": "838",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5644@kidzee.com",
                               "Mobile_No": "8278151111",
                               "Phone_No1": "8278151111",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4831",
                               "Franchisee_Name": "Kidzee Kalka Road",
                               "Operating_Status": "A",
                               "Address1": "Plot No.7680/G/F/13/A,",
                               "Address2": "Gali No.5, Mahavir Nagar,",
                               "Place": "Kalka Road,",
                               "City_Id": "838",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4831@kidzee.com",
                               "Mobile_No": "9810538678",
                               "Phone_No1": "9810538678",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Rohtak",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-U-892",
                               "Franchisee_Name": "Kidzee-Rohtak-Haryana",
                               "Operating_Status": "A",
                               "Address1": "C/O- Ghyan Bharti High School,",
                               "Address2": "",
                               "Place": "Sector-4, Near-Sonepat Road,",
                               "City_Id": "845",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "kidzee892@kidzee.com",
                               "Mobile_No": "8818001262",
                               "Phone_No1": "8816904951",
                               "Phone_No2": "",
                               "Latitude": "28.900733",
                               "Longitude": "76.625671"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5645",
                               "Franchisee_Name": "Kidzee Indra Colony",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 445-448",
                               "Address2": "Ward No.-2,60 Feet Road,",
                               "Place": "Indra Colony",
                               "City_Id": "845",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5645@kidzee.com",
                               "Mobile_No": "9812941012",
                               "Phone_No1": "9812941012",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Sonipat",
                         "Franchisee": {
                            "Franchisee_Code": "N-A-M-1397",
                            "Franchisee_Name": "Kidzee - Sonepat / Raj Education",
                            "Operating_Status": "A",
                            "Address1": "H.No.63,  Sectore -14,",
                            "Address2": "Near-M.G. Mall,",
                            "Place": "Sonipat, Near-Railway Road,",
                            "City_Id": "921",
                            "State_Id": "16",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee1397@kidzee.com",
                            "Mobile_No": "9541626040",
                            "Phone_No1": "9541626040",
                            "Phone_No2": "",
                            "Latitude": "28.999545",
                            "Longitude": "77.032738"
                         }
                      },
                      {
                         "City_Name": "Pehowa"
                      },
                      {
                         "City_Name": "Bahadur garh"
                      },
                      {
                         "City_Name": "Nuh"
                      },
                      {
                         "City_Name": "Sohna"
                      },
                      {
                         "City_Name": "Hodal",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2253",
                            "Franchisee_Name": "Kidzee Hodal Haryana",
                            "Operating_Status": "A",
                            "Address1": "Brij Kutir, NH-2,",
                            "Address2": "Shyam Colony",
                            "Place": "Near Kishan Singh Hospital,",
                            "City_Id": "1341",
                            "State_Id": "16",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2253@kidzee.com",
                            "Mobile_No": "9992672575",
                            "Phone_No1": "9992672575",
                            "Phone_No2": "",
                            "Latitude": "27.896488",
                            "Longitude": "77.372638"
                         }
                      },
                      {
                         "City_Name": "Ladwa",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2675",
                            "Franchisee_Name": "Kidzee Ladwa",
                            "Operating_Status": "A",
                            "Address1": "VIP  Colony,",
                            "Address2": "Ladwa Tehsil Thanesar,",
                            "Place": "Ladwa ,VIP Colony,",
                            "City_Id": "1342",
                            "State_Id": "16",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2675@kidzee.com",
                            "Mobile_No": "9991603080",
                            "Phone_No1": "9812580945",
                            "Phone_No2": "",
                            "Latitude": "29.969615",
                            "Longitude": "76.819752"
                         }
                      },
                      {
                         "City_Name": "Gohana"
                      },
                      {
                         "City_Name": "Jakhal mandi"
                      },
                      {
                         "City_Name": "Safidon"
                      },
                      {
                         "City_Name": "Cheeka"
                      },
                      {
                         "City_Name": "Shahabad markanda",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4192",
                            "Franchisee_Name": "Kidzee Shahbad Markanda",
                            "Operating_Status": "A",
                            "Address1": "653/3, Guru Nanak Pura,",
                            "Address2": "",
                            "Place": "Shahabad Markanda, Near-Devi Mandir Huda,",
                            "City_Id": "1414",
                            "State_Id": "16",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4192@kidzee.com",
                            "Mobile_No": "9466400024",
                            "Phone_No1": "9466400024",
                            "Phone_No2": "",
                            "Latitude": "30.1672",
                            "Longitude": "76.8684"
                         }
                      },
                      {
                         "City_Name": "Ratia"
                      },
                      {
                         "City_Name": "Manesar"
                      },
                      {
                         "City_Name": "Mewat"
                      },
                      {
                         "City_Name": "Nilokheri"
                      },
                      {
                         "City_Name": "Yamunanagar",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3335",
                            "Franchisee_Name": "Kidzee Yamunanagar",
                            "Operating_Status": "A",
                            "Address1": "H.No - 1/6",
                            "Address2": "Behind Mahindra Petrol pump",
                            "Place": "Yamunanagar, Madhu Colony,",
                            "City_Id": "1602",
                            "State_Id": "16",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3335@kidzee.com",
                            "Mobile_No": "8222092229",
                            "Phone_No1": "8222092229",
                            "Phone_No2": "",
                            "Latitude": "30.135241",
                            "Longitude": "77.298141"
                         }
                      },
                      {
                         "City_Name": "Mahendragarh",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-3566",
                               "Franchisee_Name": "Kidzee Mahendergarh",
                               "Operating_Status": "A",
                               "Address1": "VPO Palri,",
                               "Address2": "",
                               "Place": "Mahendergarh, Palri Panihara,",
                               "City_Id": "1628",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3566@kidzee.com",
                               "Mobile_No": "9416151478",
                               "Phone_No1": "9416151478",
                               "Phone_No2": "",
                               "Latitude": "28.27939999999999",
                               "Longitude": "76.15520932540892",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1603749/10501/690dd4c0_e1bd_4ac9_aa02_487b7189a0fe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1603749/10501/87731c1f_9a53_4857_b550_cbde61e3e29d.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "A-N-A-1082",
                               "Franchisee_Name": "Ankurum Nangal Sirohi",
                               "Operating_Status": "A",
                               "Address1": "Sarswati School ,",
                               "Address2": "VPO Nangal Sirohi,",
                               "Place": "Nangal Sirohi",
                               "City_Id": "1628",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "ramns73@gmail.com",
                               "Mobile_No": "9416964069",
                               "Phone_No1": "9416964069",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Charkhi Dadri"
                      },
                      {
                         "City_Name": "CHARKHI DADRI"
                      },
                      {
                         "City_Name": "Mahendragarh"
                      },
                      {
                         "City_Name": "Nangal Sirohi"
                      },
                      {
                         "City_Name": "Ateli Mandi",
                         "Franchisee": {
                            "Franchisee_Code": "A-N-A-1084",
                            "Franchisee_Name": "Ankurum  Ateli Mandi",
                            "Operating_Status": "A",
                            "Address1": "ward no 10 ,House No-831",
                            "Address2": "Dist. Mahendragarh,",
                            "Place": "Ateli Mandi",
                            "City_Id": "1963",
                            "State_Id": "16",
                            "Country_Id": "1",
                            "Email_Id": "jpsrao71@gmail.com",
                            "Mobile_No": "9416906785",
                            "Phone_No1": "9416906785",
                            "Phone_No2": "",
                            "Latitude": "28.103424",
                            "Longitude": "76.253563"
                         }
                      },
                      {
                         "City_Name": "Nangal Chaudhary",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3683",
                            "Franchisee_Name": "Kidzee Nangal chaudhary",
                            "Operating_Status": "A",
                            "Address1": "Dr. Narish Lane to Govt Girl’s school",
                            "Address2": "Narnaul Road,",
                            "Place": "Nangal Chaudhary,",
                            "City_Id": "1973",
                            "State_Id": "16",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3683@kidzee.com",
                            "Mobile_No": "9467352126",
                            "Phone_No1": "9467352126",
                            "Phone_No2": "",
                            "Latitude": "76.113589",
                            "Longitude": "27.894694"
                         }
                      },
                      {
                         "City_Name": "Naraingarh",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3634",
                            "Franchisee_Name": "Kidzee Naraingarh",
                            "Operating_Status": "A",
                            "Address1": "H No.97 Ward No. 6,",
                            "Address2": "Channa Colony,Near Sd High School",
                            "Place": "Near Sd High School, Channa Colony, Naraingarh",
                            "City_Id": "1976",
                            "State_Id": "16",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3634@kidzee.com",
                            "Mobile_No": "9810721845",
                            "Phone_No1": "7419800078",
                            "Phone_No2": "",
                            "Latitude": "30.480232",
                            "Longitude": "77.131346"
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Himachal Pradesh",
                   "City": [
                      {
                         "City_Name": "Baddi",
                         "Franchisee": {
                            "Franchisee_Code": "N-U-C-604",
                            "Franchisee_Name": "Kidzee-Baddi-H.P",
                            "Operating_Status": "A",
                            "Address1": "Amaravati Apartment,",
                            "Address2": "Adj Housing Board, PH-III",
                            "Place": "Baddi, Housing Board Chowk,",
                            "City_Id": "98",
                            "State_Id": "17",
                            "Country_Id": "1",
                            "Email_Id": "kidzee604@kidzee.com",
                            "Mobile_No": "9816020344",
                            "Phone_No1": "9816020344",
                            "Phone_No2": "",
                            "Latitude": "30.943359",
                            "Longitude": "76.813775"
                         }
                      },
                      {
                         "City_Name": "Dharamshala / Dharamsala / Bhagsu"
                      },
                      {
                         "City_Name": "Kullu",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3785",
                            "Franchisee_Name": "Kidzee Kullu",
                            "Operating_Status": "A",
                            "Address1": "182/3, Village Badah",
                            "Address2": "Mohal(35/84)",
                            "Place": "Kullu ,Badah",
                            "City_Id": "601",
                            "State_Id": "17",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3785@kidzee.com",
                            "Mobile_No": "7018925271",
                            "Phone_No1": "9459200021",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Palampur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-U-267",
                               "Franchisee_Name": "TINY TOTS -Palampur",
                               "Operating_Status": "A",
                               "Address1": "Dharamshala By pass Road, Ward-4,",
                               "Address2": "Palampur",
                               "Place": "Palampur ,Dharmshala By Pass Road",
                               "City_Id": "761",
                               "State_Id": "17",
                               "Country_Id": "1",
                               "Email_Id": "kidzee267@kidzee.com",
                               "Mobile_No": "9816015959",
                               "Phone_No1": "01894234559",
                               "Phone_No2": "",
                               "Latitude": "32.11086",
                               "Longitude": "76.536253"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5217",
                               "Franchisee_Name": "Kidzee Kakru",
                               "Operating_Status": "A",
                               "Address1": "Near Blue start school",
                               "Address2": "Near Hari om Motors",
                               "Place": "Kakru",
                               "City_Id": "761",
                               "State_Id": "17",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5217@kidzee.com",
                               "Mobile_No": "7018702570",
                               "Phone_No1": "7018702570",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Parwanoo"
                      },
                      {
                         "City_Name": "Shimla",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5587",
                            "Franchisee_Name": "Kidzee New Shimla",
                            "Operating_Status": "A",
                            "Address1": "Sector 1, Phase -1",
                            "Address2": "House No-B-90",
                            "Place": "New Shimla",
                            "City_Id": "896",
                            "State_Id": "17",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5587@kidzee.com",
                            "Mobile_No": "9418380277",
                            "Phone_No1": "9857347773",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Kangra",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4431",
                            "Franchisee_Name": "Kidzee Paprola",
                            "Operating_Status": "A",
                            "Address1": "Mandi Pathankot",
                            "Address2": "National Highway,",
                            "Place": "Paprola, Near-Prakash Filling Station",
                            "City_Id": "1067",
                            "State_Id": "17",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4431@kidzee.com",
                            "Mobile_No": "9768089091",
                            "Phone_No1": "1234-000000",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Solan"
                      },
                      {
                         "City_Name": "Rampur"
                      },
                      {
                         "City_Name": "Darcha"
                      },
                      {
                         "City_Name": "Mandi",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2268",
                            "Franchisee_Name": "Kidzee  PO KARSOG, DIST MANDI HP",
                            "Operating_Status": "A",
                            "Address1": "Village Barol,",
                            "Address2": "PO Karsog, Dist Mandi",
                            "Place": "Karsog, Near BSNL exchange",
                            "City_Id": "1343",
                            "State_Id": "17",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2268@kidzee.com",
                            "Mobile_No": "9805289192",
                            "Phone_No1": "9805289192",
                            "Phone_No2": "",
                            "Latitude": "31.381337",
                            "Longitude": "77.204587"
                         }
                      },
                      {
                         "City_Name": "Hamirpur",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3855",
                            "Franchisee_Name": "Kidzee Amroh",
                            "Operating_Status": "A",
                            "Address1": "Village Sihal, Post Office Amroh,",
                            "Address2": "District Hamirpur",
                            "Place": "Amroh",
                            "City_Id": "1358",
                            "State_Id": "17",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3855@kidzee.com",
                            "Mobile_No": "9882470256",
                            "Phone_No1": "01972-262782",
                            "Phone_No2": "",
                            "Latitude": "31.736389",
                            "Longitude": "76.473247",
                            "Images": [
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1912781/10723/1e7a63ec_ceae_4d78_95d5_e5d70edb9478.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1912781/10723/63b70d44_c78b_4ab1_a50d_a32e19870168.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1912781/10723/14a7c00d_3f99_415c_9ab2_0ef77203707a.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1912781/10723/a70754ce_0c80_45aa_8eb3_9ebab5832e07.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Nahan",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2645",
                            "Franchisee_Name": "Kidzee Nahan Himachal Pradesh",
                            "Operating_Status": "A",
                            "Address1": "424/2,",
                            "Address2": "Sunder Bag Colony,",
                            "Place": "Nahan, Sunder Bagh,",
                            "City_Id": "1495",
                            "State_Id": "17",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2645@kidzee.com",
                            "Mobile_No": "8580999173",
                            "Phone_No1": "9736738382",
                            "Phone_No2": "",
                            "Latitude": "30.559933",
                            "Longitude": "77.295483"
                         }
                      },
                      {
                         "City_Name": "Una",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2574",
                               "Franchisee_Name": "Kidzee Una H P",
                               "Operating_Status": "A",
                               "Address1": "Friends Colony, Una-Amb Road,",
                               "Address2": "Khasra No. 355/1 355/2,",
                               "Place": "Friends Colony, Una-Amb Road,",
                               "City_Id": "1521",
                               "State_Id": "17",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2574@kidzee.com",
                               "Mobile_No": "7018483306",
                               "Phone_No1": "7018483306",
                               "Phone_No2": "",
                               "Latitude": "31.468445",
                               "Longitude": "76.270775"
                            },
                            {
                               "Franchisee_Code": "A-N-A-1064",
                               "Franchisee_Name": "Ankurum Tahiwal",
                               "Operating_Status": "A",
                               "Address1": "Village Tahliwal",
                               "Address2": "Tehsil Haroli ,Po Bathu",
                               "Place": "Tahliwal ,Bathu",
                               "City_Id": "1521",
                               "State_Id": "17",
                               "Country_Id": "1",
                               "Email_Id": "chaudharysunil39@gmail.com",
                               "Mobile_No": "7018483306",
                               "Phone_No1": "7018483306",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5611",
                               "Franchisee_Name": "Kidzee Ghaluwal",
                               "Operating_Status": "A",
                               "Address1": "Village Ghaluwal",
                               "Address2": "P.O.Saloh Sub-Tehsil Ispur Dist",
                               "Place": "Ghaluwal",
                               "City_Id": "1521",
                               "State_Id": "17",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5611@kidzee.com",
                               "Mobile_No": "9988219762",
                               "Phone_No1": "9988219762",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Nalagarh"
                      }
                   ]
                },
                {
                   "State_Name": "Jammu and Kashmir",
                   "City": [
                      {
                         "City_Name": "Anantnag"
                      },
                      {
                         "City_Name": "Badgam",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5393",
                            "Franchisee_Name": "Kidzee Ompara",
                            "Operating_Status": "A",
                            "Address1": "Syed Abad Colony,",
                            "Address2": "Sheikpora",
                            "Place": "Ompara",
                            "City_Id": "99",
                            "State_Id": "18",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5393@kidzee.com",
                            "Mobile_No": "9419008667",
                            "Phone_No1": "9419008667",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Doda"
                      },
                      {
                         "City_Name": "Jammu",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-U-471",
                               "Franchisee_Name": "Kidzee Bohri - Jammu",
                               "Operating_Status": "A",
                               "Address1": "S.M.Shishu Niketan Higher Secondary School,",
                               "Address2": "",
                               "Place": "Anand Nagar, Opp J&K Bank ATM, Bhori,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "kidzee471@kidzee.com",
                               "Mobile_No": "8899131674",
                               "Phone_No1": "96222 77106",
                               "Phone_No2": "",
                               "Latitude": "32.741358",
                               "Longitude": "74.818549"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2540",
                               "Franchisee_Name": "Kidzee Rehari Colony Jammu",
                               "Operating_Status": "A",
                               "Address1": "H.No-314,",
                               "Address2": "Jammu,",
                               "Place": "Rehari Colony,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2540@kidzee.com",
                               "Mobile_No": "9906903845",
                               "Phone_No1": "9906903845",
                               "Phone_No2": "",
                               "Latitude": "32.746889",
                               "Longitude": "74.849982"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3061",
                               "Franchisee_Name": "Kidzee  Paloura, Jammu",
                               "Operating_Status": "A",
                               "Address1": "Mohalla Khajurian, Ward No-69,",
                               "Address2": "",
                               "Place": "Jammu, Paloura",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3061@kidzee.com",
                               "Mobile_No": "9419130301",
                               "Phone_No1": "0191-2536301",
                               "Phone_No2": "",
                               "Latitude": "32.721782",
                               "Longitude": "74.857654"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3025",
                               "Franchisee_Name": "Kidzee Kunjwani bypass",
                               "Operating_Status": "A",
                               "Address1": "Kunjwani Bypass, Village Lunger,",
                               "Address2": "Opp-Vishal Megamart, Near-Carmal School,",
                               "Place": "Jammu, Near Cresent Convent School,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3025@kidzee.com",
                               "Mobile_No": "9419119285",
                               "Phone_No1": "9419119285",
                               "Phone_No2": "",
                               "Latitude": "32.672571",
                               "Longitude": "74.881096"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2011",
                               "Franchisee_Name": "Kidzee Channi tehsil  Jammu",
                               "Operating_Status": "A",
                               "Address1": "H No-267, Sector-2,",
                               "Address2": "Channi -Tehsil,",
                               "Place": "Chhanni-Himmat, Opp,Shiv Temple,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2011@kidzee.com",
                               "Mobile_No": "8899783008",
                               "Phone_No1": "9622277106",
                               "Phone_No2": "",
                               "Latitude": "32.691913",
                               "Longitude": "74.893473"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2943",
                               "Franchisee_Name": "Kidzee Dream City Mutthi",
                               "Operating_Status": "A",
                               "Address1": "H.No-192, Lane No-5,",
                               "Address2": "Mutthi,",
                               "Place": "Jammu, Dream City,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee2943@kidzee.com",
                               "Mobile_No": "9419198576",
                               "Phone_No1": "9419198576",
                               "Phone_No2": "",
                               "Latitude": "32.721904",
                               "Longitude": "74.856817"
                            },
                            {
                               "Franchisee_Code": "N-S-C-2025",
                               "Franchisee_Name": "Kidzee Skylark Pre School Sidhra Jammu School",
                               "Operating_Status": "A",
                               "Address1": "Plot Khasra No-74,",
                               "Address2": "Major Raghunath Singh Marg,",
                               "Place": "Sidhra,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2025@kidzee.com",
                               "Mobile_No": "8803500711",
                               "Phone_No1": "8803500711",
                               "Phone_No2": "",
                               "Latitude": "32.721782",
                               "Longitude": "74.857654"
                            },
                            {
                               "Franchisee_Code": "N-C-C-1127",
                               "Franchisee_Name": "Kidzee-Gandhi nagar,Jammu",
                               "Operating_Status": "A",
                               "Address1": "H.No.16-B,",
                               "Address2": "Ext.Gandhi Nagar, Near-Main Nanak Nagar Gurdwara,",
                               "Place": "Opp.First Cry, Gandhi Nagar,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1127@kidzee.com",
                               "Mobile_No": "9419183008",
                               "Phone_No1": "191-2430031",
                               "Phone_No2": "",
                               "Latitude": "32.689964",
                               "Longitude": "74.857612"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2341",
                               "Franchisee_Name": "Kidzee Talab Tillo Jammu",
                               "Operating_Status": "A",
                               "Address1": "N.S.M.HIgher Secondary School Near Gole Puli,",
                               "Address2": "",
                               "Place": "Talab Tillo, Near Gole Pulli Tabalb Billo,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2341@kidzee.com",
                               "Mobile_No": "9055584070",
                               "Phone_No1": "01912554807",
                               "Phone_No2": "",
                               "Latitude": "32.757911",
                               "Longitude": "74.834218"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2727",
                               "Franchisee_Name": "Kidzee Roop Nagar Enclave",
                               "Operating_Status": "A",
                               "Address1": "Roop Nagar Enclave,",
                               "Address2": "Jammu,",
                               "Place": "Roop Nagar,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2727@kidzee.com",
                               "Mobile_No": "8899205584",
                               "Phone_No1": "8899205584",
                               "Phone_No2": "",
                               "Latitude": "32.772311",
                               "Longitude": "74.839821"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4334",
                               "Franchisee_Name": "kidzee Rajouri",
                               "Operating_Status": "A",
                               "Address1": "Jawahar Nagar,",
                               "Address2": "Near-RTO ,",
                               "Place": "Opp RTO Office, Rajouri,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4334@kidzee.com",
                               "Mobile_No": "9906000416",
                               "Phone_No1": "9906000884",
                               "Phone_No2": "",
                               "Latitude": "33.36503078108459",
                               "Longitude": "74.32227767112931"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4129",
                               "Franchisee_Name": "Kidzee Akhnoor",
                               "Operating_Status": "A",
                               "Address1": "Ward No.9,",
                               "Address2": "Opposite-BSNL Exchange,",
                               "Place": "Akhnoor,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4129@kidzee.com",
                               "Mobile_No": "9419123595",
                               "Phone_No1": "8285-480163",
                               "Phone_No2": "",
                               "Latitude": "32.901473",
                               "Longitude": "74.742161"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3717",
                               "Franchisee_Name": "Kidzee R S Pura",
                               "Operating_Status": "A",
                               "Address1": "Khewat No.4, Kata no.483",
                               "Address2": "Purana Pind",
                               "Place": "R S Pura",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3717@kidzee.com",
                               "Mobile_No": "8492971704",
                               "Phone_No1": "9796015720",
                               "Phone_No2": "",
                               "Latitude": "32.6039360",
                               "Longitude": "74.7357340"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5234",
                               "Franchisee_Name": "Kidzee Manyal",
                               "Operating_Status": "A",
                               "Address1": "Ward No 8. Village Manyal",
                               "Address2": "Brahmana, tehsil Marh",
                               "Place": "Manyal",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5234@kidzee.com",
                               "Mobile_No": "7298170318",
                               "Phone_No1": "8974614167",
                               "Phone_No2": "",
                               "Latitude": "32.7649150",
                               "Longitude": "74.7620570"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4982",
                               "Franchisee_Name": "Kidzee Gangyal",
                               "Operating_Status": "A",
                               "Address1": "VIL-Bablianan",
                               "Address2": "",
                               "Place": "Near-Shivam Resort Babliana, Gangyal,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4982@kidzee.com",
                               "Mobile_No": "7006156713",
                               "Phone_No1": "9018833802",
                               "Phone_No2": "",
                               "Latitude": "32.66718",
                               "Longitude": "74.85788"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4547",
                               "Franchisee_Name": "Kidzee Khour",
                               "Operating_Status": "A",
                               "Address1": "Village Khour Camp, Ward No-6,",
                               "Address2": "Near-Community Health Center,",
                               "Place": "Khour,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4547@kidzee.com",
                               "Mobile_No": "9622147551",
                               "Phone_No1": "9622147551",
                               "Phone_No2": "",
                               "Latitude": "32.6770700",
                               "Longitude": "74.8813620"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5103",
                               "Franchisee_Name": "Kidzee Nowshera",
                               "Operating_Status": "A",
                               "Address1": "Near TMP School, Amar Bagh Bela,",
                               "Address2": "Ward No. 1, Dist Rajouri",
                               "Place": "Nowshera",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5103@kidzee.com",
                               "Mobile_No": "9363534155",
                               "Phone_No1": "9149674026",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3992",
                               "Franchisee_Name": "Kidzee Airport Road Jammu",
                               "Operating_Status": "A",
                               "Address1": "Satwari  House No-1.",
                               "Address2": "Rani Baugh",
                               "Place": "Near Airport Road ,Airport Road",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3992@kidzee.com",
                               "Mobile_No": "8713034343",
                               "Phone_No1": "9419187959",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3823",
                               "Franchisee_Name": "Kidzee Sainik Colony-Sec G",
                               "Operating_Status": "A",
                               "Address1": "House no 212,",
                               "Address2": "Sector G ",
                               "Place": "Sainik Colony-Sec G ,sainik Colony",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3823@kidzee.com",
                               "Mobile_No": "7889820127",
                               "Phone_No1": "9727600614",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3943",
                               "Franchisee_Name": "Kidzee Nagbani",
                               "Operating_Status": "A",
                               "Address1": "VPO Nagbani,",
                               "Address2": "",
                               "Place": "Nagbani ,Near Shee mahal palace,Nagbani",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3943@kidzee.com",
                               "Mobile_No": "9796453594",
                               "Phone_No1": "9796453594",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5690",
                               "Franchisee_Name": "Kidzee Nardani",
                               "Operating_Status": "A",
                               "Address1": "Nardani",
                               "Address2": "Bhalwal Road",
                               "Place": "Nardani",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5690@kidzee.com",
                               "Mobile_No": "9149511218",
                               "Phone_No1": "9149511218",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3636",
                               "Franchisee_Name": "Kidzee Kachi Chawni",
                               "Operating_Status": "A",
                               "Address1": "No-1, Exchange Road,",
                               "Address2": "Adjoining BSNL Exchange,",
                               "Place": "Behind- Exchnge, Kachi chawani,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3636@kidzee.com",
                               "Mobile_No": "9086800000",
                               "Phone_No1": "9419135248",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4230",
                               "Franchisee_Name": "Kidzee Jourian",
                               "Operating_Status": "A",
                               "Address1": "Ward No.6, Ground Floor,",
                               "Address2": "Maniwala, Main Road,",
                               "Place": "Jourian,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4230@kidzee.com",
                               "Mobile_No": "9419123595",
                               "Phone_No1": "9814251016",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4072",
                               "Franchisee_Name": "Kidzee Bari-brahmana",
                               "Operating_Status": "A",
                               "Address1": "Bari Brahmana,",
                               "Address2": "Ground Floor,",
                               "Place": "Bari Brahmana,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4072@kidzee.com",
                               "Mobile_No": "9596605333",
                               "Phone_No1": "9596605333",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4385",
                               "Franchisee_Name": "Kidzee Bantalab",
                               "Operating_Status": "A",
                               "Address1": "Lane No.4,",
                               "Address2": "Sukhi Nehar Bharat Nagar,",
                               "Place": "Ban Talab ,Bantlab,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4385@kidzee.com",
                               "Mobile_No": "9797828178",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4073",
                               "Franchisee_Name": "Kidzee Bishnah",
                               "Operating_Status": "A",
                               "Address1": "Revenue Village,",
                               "Address2": "Chak  Avtara,",
                               "Place": "Bishnah,",
                               "City_Id": "482",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4073@kidzee.com",
                               "Mobile_No": "9149543730",
                               "Phone_No1": "9086553305",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Kashmir"
                      },
                      {
                         "City_Name": "Kathua",
                         "Franchisee": {
                            "Franchisee_Code": "N-U-C-482",
                            "Franchisee_Name": "Indian Public School",
                            "Operating_Status": "A",
                            "Address1": "Indian Public School Kalibari, Near- Rajiv Gandhi  Memorial College of Education,",
                            "Address2": "GT Road,",
                            "Place": "Kalibari",
                            "City_Id": "547",
                            "State_Id": "18",
                            "Country_Id": "1",
                            "Email_Id": "kidzee482@kidzee.com",
                            "Mobile_No": "9419107702",
                            "Phone_No1": "9149647938",
                            "Phone_No2": "",
                            "Latitude": "32.386502",
                            "Longitude": "75.517329"
                         }
                      },
                      {
                         "City_Name": "Reasi"
                      },
                      {
                         "City_Name": "Lakhanpur"
                      },
                      {
                         "City_Name": "Srinagar",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4363",
                            "Franchisee_Name": "Kidzee Saida Kadal",
                            "Operating_Status": "A",
                            "Address1": "Saida Kadal,",
                            "Address2": "",
                            "Place": "Saida Kadal, Near Bridge Rainawari,",
                            "City_Id": "929",
                            "State_Id": "18",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4363@kidzee.com",
                            "Mobile_No": "9469050000",
                            "Phone_No1": "7006064883",
                            "Phone_No2": "",
                            "Latitude": "34.105851",
                            "Longitude": "74.830844"
                         }
                      },
                      {
                         "City_Name": "Udhampur"
                      },
                      {
                         "City_Name": "Samba",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2778",
                               "Franchisee_Name": "Kidzee  Ramgarh",
                               "Operating_Status": "A",
                               "Address1": "Ward No-5, Ramgarh,",
                               "Address2": "Tehsil Ramgarh, Dist-Samba",
                               "Place": "Ramgarh,",
                               "City_Id": "1377",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee2778@kidzee.com",
                               "Mobile_No": "9419188083",
                               "Phone_No1": "9419101426",
                               "Phone_No2": "",
                               "Latitude": "32.57621",
                               "Longitude": "75.016376"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2393",
                               "Franchisee_Name": "KIDZEE SAMBA,",
                               "Operating_Status": "A",
                               "Address1": "SAMBA, WARD NO 1 ,",
                               "Address2": "Tehsil and District  Samba (J&K)",
                               "Place": "Samba ,Near police station,Samba",
                               "City_Id": "1377",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2393@kidzee.com",
                               "Mobile_No": "9697523712",
                               "Phone_No1": "1923-246551",
                               "Phone_No2": "",
                               "Latitude": "32.5643099",
                               "Longitude": "75.1219578"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4356",
                               "Franchisee_Name": "Kidzee Ghagwal",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor",
                               "Address2": "Narsingh Temple , P O Ghagwal",
                               "Place": "Ghagwal ,Narsingh temple,ghagwal",
                               "City_Id": "1377",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4356@kidzee.com",
                               "Mobile_No": "9419107455",
                               "Phone_No1": "01922-230950",
                               "Phone_No2": "",
                               "Latitude": "32.5092280",
                               "Longitude": "75.21131800"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4308",
                               "Franchisee_Name": "Kidzee Gurah Slathia",
                               "Operating_Status": "A",
                               "Address1": "Mandi UDH,",
                               "Address2": "Badwal Morh,",
                               "Place": "Gurah Slathia,",
                               "City_Id": "1377",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4308@kidzee.com",
                               "Mobile_No": "8716932008",
                               "Phone_No1": "9697992506",
                               "Phone_No2": "",
                               "Latitude": "32.59568211753097",
                               "Longitude": "75.0359559059143"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5068",
                               "Franchisee_Name": "Kidzee Rahya",
                               "Operating_Status": "A",
                               "Address1": "Ranjari More",
                               "Address2": "Central university Road,",
                               "Place": "Vill. Rahya Jammu ,Central unoiversity road,Rahya",
                               "City_Id": "1377",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5068@kidzee.com",
                               "Mobile_No": "9149534319",
                               "Phone_No1": "9149534319",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4413",
                               "Franchisee_Name": "Kidzee Vijaypur",
                               "Operating_Status": "A",
                               "Address1": "Ward No-13,",
                               "Address2": "Near Water Tank,",
                               "Place": "Canal Road, Vijaypur,",
                               "City_Id": "1377",
                               "State_Id": "18",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4413@kidzee.com",
                               "Mobile_No": "7889636533",
                               "Phone_No1": "9796733777",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Akhnoor"
                      },
                      {
                         "City_Name": "Kishtwar",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2942",
                            "Franchisee_Name": "Kidzee Kishtwar J & K",
                            "Operating_Status": "A",
                            "Address1": "Shaheedi Mazar,",
                            "Address2": "Shakti Nagar,",
                            "Place": "Behind ITI,Kishtwar",
                            "City_Id": "1547",
                            "State_Id": "18",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2942@kidzee.com",
                            "Mobile_No": "9419273465",
                            "Phone_No1": "9419273465",
                            "Phone_No2": "",
                            "Latitude": "32.737358",
                            "Longitude": "74.839747"
                         }
                      },
                      {
                         "City_Name": "Bohri"
                      },
                      {
                         "City_Name": "Anand Nagar"
                      },
                      {
                         "City_Name": "Katra",
                         "Franchisee": {
                            "Franchisee_Code": "N-A-M-1193",
                            "Franchisee_Name": "Kidzee-Panchavati,Katra,J & K",
                            "Operating_Status": "A",
                            "Address1": "Panchavati, Ward No-1,",
                            "Address2": "Jammu Road",
                            "Place": "Katra,",
                            "City_Id": "1925",
                            "State_Id": "18",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1193@kidzee.com",
                            "Mobile_No": "9419164289",
                            "Phone_No1": "01991-231111",
                            "Phone_No2": "",
                            "Latitude": "32.989384",
                            "Longitude": "74.925705",
                            "Images": [
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/3cd2899e_b17f_4ea5_9b09_fa5d6cacc54e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/552bdce1_7a43_4350_a82c_a931d2019877.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/c571f3e2_df72_400d_9c6c_c71889ec16c0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/faba256a_ff6d_4518_990e_93422a9cf2cf.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/04a48bad_4435_4761_b6e8_15c31ce76836.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/2bacb3b5_7ac8_4c7f_b44f_b61aaec663a5.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/39febada_f7a3_4cc4_b98e_97084bd8c03f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/5a088d56_63f9_4994_8246_c6995bebcaa7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/e63b90c8_10f6_4cdf_a1a4_34cc8e1cdd6f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/49f19ff7_2c72_495b_b328_da7e842bc135.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/cd1b667c_655b_4f66_b91c_36dfd592ed41.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/4ccda88b_8ed7_4899_9139_1ae8ab251cdc.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/e428dbf8_45a7_4cd4_962a_534525bb06ab.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11029/181f3ce7_cec7_4063_a896_6c027d3bd77e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/325ef0ef_e692_41ca_a974_6dea721b5c97.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/10b93690_c37a_43d6_b7b3_656e08a8dcfc.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/4fd69373_bb18_4af3_b805_51722e2f3f33.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/ea5bcfb9_8384_49fb_822f_e0944a4bb3fb.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/b2725afb_8173_4c37_a057_b1f3fa5dbe19.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/02904478_c22e_4ad5_82eb_e32cc84f8bce.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/2aa0fe00_7e84_4cd5_944f_9c26baebfb9c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/92df0828_2b23_4c3f_be1f_1c18006fead9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/c7a6579a_8857_42dd_b912_8c284144c0d8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/560b1535_0151_463b_933c_d61610d89d94.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/1bfcbe17_cf5b_43bc_9b09_742567f85c2e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/0245af6c_d77f_40e4_8205_3f3d43c4aff0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/e5c0efdc_88c1_456a_9d12_9965ed677456.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/dcea015a_2ea4_4d2f_b349_3a47073880fc.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/134ab1dd_327c_486c_8c6f_8068e9631b20.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/7eb6a351_a174_47ae_8999_704b3db789b3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/ca1d5e1c_228b_4ec6_955a_ebe3df7dc6a8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/8aafdf71_9907_4193_bcac_02358736e356.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/8902601f_eeb6_4155_a958_4706e2f77ca0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/90ace1ea_10d5_4c8b_ae70_085cf6578917.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/c715d0b3_75d4_48fc_a83d_118046fdd88c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/4688817d_d8a8_4360_a40e_65b857a19b7e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/a8e30803_ad63_49cb_b782_7796c1e2f198.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/28f1dcaa_ce78_4243_9942_e9a0590d1542.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/91fd2776_d676_45c9_8279_fa2c785bf16a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/665d1d07_d10f_48ae_8420_7b5df54e755d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/04d556f5_131d_4ae2_b4e7_c5673ef0213a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/c1cd44ec_a92a_44f7_9a1d_3c131e8aa72d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/cf5dc81c_9ce4_4548_88b7_7eb885e79c64.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/5e59fffa_0c31_42fe_bbf5_aa10e560ee9c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11030/38f04d80_d35c_43c5_afbe_1457b42b73fa.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/f9a41a0e_9180_4f08_9264_67400812593f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/172ae0dc_ea15_4813_962a_68fa4195c846.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/1dad063d_2760_484b_8d7e_1d226e277400.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/3cd1ef7d_0ca0_4fb3_b4fd_6c6e7468f8b7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/b6ccced8_5436_4c89_b953_f54f28eb61f4.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/98f35374_a01a_47bc_8b43_b53c69e84e1f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/520f3776_4196_47fe_8743_308a079fd8d8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/02d5dfc0_4d31_48ca_9bd2_dd060460a09f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/ab333949_30ac_4cb8_b34a_493a5f353632.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/e58f7e1a_b871_486e_9dbb_a180ebfb73f1.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/3b5a52ca_5cbb_4796_a760_113adb58d918.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/1ce03760_00ce_4fe3_9254_ce49a5de0180.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/c47c58e0_ae38_42ce_8b9d_b8c765307d41.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/eb96fd11_8af6_45b2_9c39_984a4a6f9a9e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/5832031d_59a8_4fe8_90ec_51c8eb0945a8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/48278126_aa34_4546_80c9_0ecf2fae674d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/05bebc5d_5d0b_4bac_9ed2_21c8b018e86e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/7726e0c2_a859_4013_b810_0d942dabbe57.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/6b1b9261_a554_4dbc_958f_485612fc17de.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/428e3b91_cb56_4ace_ae3e_9df98d6b3a84.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/1d58e037_9e44_44f4_8583_84bf25a40006.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/eb73ac5d_e380_4ffc_920a_497adb0f5d48.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/e045c95d_25e7_4f31_ad17_009c35ad3144.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/9bac55c2_7f40_4de7_a93d_683a7e59b7cc.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/efffa6ec_9336_48de_a7c6_55ebc8ae01e7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/a8a2fe7f_c1c0_456a_85e1_017f6a1dac85.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/02812404_c51c_4812_abb9_159d970a2c36.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/0d070403_047d_45e5_816d_7e3ed8c9f333.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/091e6b7b_a965_4e03_bd88_58a4852b0aaa.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/0e9c796a_83d4_476c_84d2_a02333ccc49c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/316d6245_a95c_4d5e_9e52_93a771822839.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/bd34d582_0a11_4887_85fb_e4ac74dc0426.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/2f286eec_c7fd_4a97_9eec_fe4777f830f9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/f0618609_2602_4b1a_8cd4_3a032e41040a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/61a05199_66ec_46d1_aa88_53d6f7004501.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/59f34382_dbd8_4d35_aa39_895a2bb1368a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/0a12528e_1c55_4471_9c3e_54387f05d469.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/bd82da27_dc70_4205_9787_407d47ca451c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/f141e37a_aca0_42f9_b9a7_88915b0882de.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/4535a414_ff7f_485f_b4be_af123a8d471f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/950c91a0_ef5e_4f94_809c_4596e741714e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/1cfae70d_1a84_421e_90b6_456579e669c2.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/a181355e_800b_4ac2_b68f_5e1a790cb443.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/92e6ed0c_b2dd_4dcf_ad19_66ad74dc1e96.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/b8604625_b552_4510_85df_2cf073b5d348.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/b37b8e72_63a9_4d2b_bfe6_970b6454aa4a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/137a2765_3d37_4d6b_8ebf_17f4fd7616c0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/6e1bb46f_cebe_49e1_a317_7fee8f517d7f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/23869c4c_6f19_4ac5_874f_6a00ea03f907.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/62f3a6f3_29ff_4542_b56d_c91ded4ea859.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/58a93c65_74f8_4608_8b31_33954f6e6279.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/e7ef5633_cd1e_4a9f_9e1a_58013a7fb9a6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/09c6b4a1_74a4_4712_be64_56dbcfe9ecae.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/9dd4fb6a_eef8_42cc_b292_e818e872425f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/b8df2690_a077_4a6b_823c_cdf0c3fa323e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/7e2428f3_66ad_4db9_9b1f_270ff1d80a4f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/5525b853_f686_46fc_8ca3_fbcf013e6951.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/6e9df001_8267_41b2_93db_9b8358ff42b9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/00293890_8a05_4597_97a3_b1ff1a17fa85.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/8ad0ea74_e8b5_42ac_b2d8_751725bcde4b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/8640dcb1_dd86_482a_906a_dc44a4061c5d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/ae0ee685_ef12_44b8_9939_387b7749e186.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/8a02828e_6930_4dd8_8445_404648b3651f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/b940958a_592c_4d92_9dc3_e0b90a3dd380.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/9cdc5928_98fe_413b_bb9b_4594e39e6ab7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/89ecd14e_3b9d_486b_823c_07e040fca808.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/194ee411_44b9_4896_a32d_3e26cad83724.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11032/95a01119_4d9a_472a_98c8_01d510c2e020.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/55da96ee_3a4c_4273_90d6_bedba1903a9c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/8ee8e3bf_5688_46b7_a124_08f0c3dbfbe0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/152a4094_788a_4ee7_af72_4331a052d70b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/43ec2f98_8803_4393_9681_5fa4c099e7f3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/f70692ef_1502_44f2_9882_70e56504d17f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/94366198_2cc2_4141_ad5b_d9cc56193570.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/22fcdee3_c8d1_48d9_ae7f_be1ddaefdda6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/0209c582_fc08_4fed_b7b2_084f38a92387.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/aa8726ed_72ed_49ee_b9c2_d16b0b6478ad.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/a08c3e5e_723d_4b75_8f6f_6e264ee148d7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/73eb9745_bc3d_4100_81cf_18eae1b3b6c7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/6a7bbf6a_b6dc_4b4f_94a0_88396c17be66.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/20effdf7_dda3_4189_976e_713b18d35e1c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/c0d5fadd_4488_474e_aab4_ee3175ad27c6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/f886f319_4e2c_4658_966a_7ec7bf994705.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/253d4ab6_95d9_4bd5_a7a5_759cea4638fd.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/84877d35_57ca_4498_b508_8e865bda161f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/a8da4c74_a437_4fcc_a303_8aab1902e1c0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/bf007c66_4485_43a0_babf_0b46d6b7e74d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/24ff5161_757b_47c7_8cd5_948478461199.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/f31e809d_6a85_478a_95b4_94575537660e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/3f056f6a_b7ad_4a96_8108_b3110ea87566.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/dbb7da2f_353d_473f_9b8b_34fa92e1c558.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/be68ceb4_d0ea_4864_ab2b_0ecef30c829f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/f13dd47f_efef_41de_929b_19655c22497d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/fe6a07ac_2b60_4964_b61e_f0d20b66bb11.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/4df2cad5_680d_43d6_be07_0688207cefe4.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/d7a95cb2_9cf8_4195_b5f7_86f582893b47.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/062b7e2d_9121_4d62_963a_ee9d13eeffd6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/b6d0fb35_ebd1_41a3_ac28_3d6fd8b4cf59.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/a29e1812_1b01_4ce5_9fa0_6bfa696a3c3d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/27b30687_5dbb_4f44_abab_047d13d0c02b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/054fde8c_1c24_4147_8ae4_46aba9f8cccc.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/4db69a22_23a1_45fd_983a_ca646a919e29.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/62aea51f_2626_4a57_a04d_e1aec2542e61.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/8611da1a_63b5_4cbd_8b8f_7ecc62f918d1.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/7e197175_c615_4bf2_ad11_6a697b2d389a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/a9c1cef0_abdb_4440_b24f_6423c898c8bf.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11033/0fe7e44e_5e2c_4340_9b42_ff4c8beb5ae9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/c9cd636a_b37d_4f1b_bc61_ce870e530750.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/9926ecb4_bbdf_442c_bdd5_38f69e977db7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/5fc6b46d_f292_4115_9bff_8f0a3da0df4d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/e62227a4_d3d2_433c_9f68_33b1bd15917e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/ea259a1a_89cd_4d15_879e_1a77fcc5d626.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/ab2f8845_1604_4001_af88_d71b83254b58.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/e157a74f_76a2_430f_8bf9_4ca51eb03f48.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/6bab8488_a442_4eb6_ae81_4cd6285759b8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/2ee55b65_95af_43d8_8e1b_cf4505b58208.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/0cac60b3_fc88_44ac_af32_90fd5c74d968.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/72f342bb_329e_4565_b966_2ae16c902cbd.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/b0373bb1_0bdc_4a39_8628_25f6f6a6384c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/a9491337_aa93_4e4e_887b_6e55d1c887bd.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/90757de5_8411_4198_a0e1_b42efc6ea899.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/2c576a84_930c_4ed3_9d17_c8ae46aa4e5f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/d9190fd3_11d9_498f_9f14_61b2c66de56b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/45c01b2d_53ba_49c1_94de_b1b6a6eeb176.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/9ab411d5_d274_4ed2_8a85_4b321acb35be.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/8ab2d1d5_7059_42c4_82a5_c8254b1bbeaa.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/dd689a02_1b3f_47a0_b268_2e649a683021.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/cb2c441c_84a4_4bf0_9cb6_af1fe2165263.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/a15527d9_2d12_4dca_9984_4b221ff155c6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/217b788b_5574_4221_9c6f_e15dcdeedf7e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/b4ab645d_efa4_4f24_b7bd_bd81c2620958.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/6cc1a35c_14ee_4141_b609_1c974703f0d6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/0f6aea63_f80c_4b08_92ce_cd921d6ffb24.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11034/d4e25cd3_440e_4885_9fe1_7dcf59259c01.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/5cb0fec1_541e_4619_8b0f_b01379973131.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/7a0b13e8_2e61_4bd1_8f45_76bdba61c923.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/3af33ea1_e23c_4ac1_a17b_933745de479a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/b49418a0_77f9_4525_9944_595db4f49e5b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/757d2489_f2bd_4c00_822c_dc38201dba07.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/ee44abaf_bf9c_4c97_ac4a_70cfa6657de3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/365e87a5_b7a4_4409_a5d9_5040d921a65e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/7f5225a1_0527_4486_84fb_770a68570c48.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/60ea0792_48bc_417f_a300_64363bb97604.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/b4015057_a498_43e5_a359_ff0367f3b980.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/84df98f9_1a98_432d_a12f_94aeea73532d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/6b99a136_e486_45a8_9213_7b2c75bbb6fb.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/8017a63e_f010_4699_8adf_5b2d6ea8b08e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/1cb41f08_17fa_40e2_abd5_56c949feb126.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/30807ba3_553a_4d04_b6ed_a8832da0ed13.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/0abab3cc_285c_45ab_b5ab_72e44c840b86.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/c2bbf3fb_ac35_457f_90b9_0a8775b64763.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/764d483e_8765_4cd7_a159_8995bffdcc3c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/0208c7ad_fbf4_4d2b_8c0e_037fef73cd9e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/e6cac4e1_14d2_4527_a19d_f68ea67cc9b6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/96376b97_7443_4b3f_abd5_7fdebd08fda7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/9674715b_3ac2_4a52_86ea_4391727784d6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/effd5109_e1be_494b_a6c4_46abb6162cc5.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/ad14c303_c03e_475e_a202_3b05c375267c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/ab555417_11a6_4065_9b8a_11accac0f59f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/e4805704_8a8f_4d33_8ff2_05551a2faaf8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/0bd56b81_47c5_46e4_ad0e_c560a2ca445d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/bd0e555a_1ffb_4ac6_a055_fd56400e8077.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/4fb9f307_5615_43e6_8180_cb57f0fe7390.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/21212bd8_3b2a_46dd_88f7_c1cda1187108.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/1e25a750_7d2c_4a17_aacc_885caff71df9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/899f42c1_943c_428a_8d1d_fcbb92a1d9d1.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/b5e47396_ca97_455c_bffb_cc645ff3240f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/fc2562c6_9ba6_4da2_853e_98047d1ca7b1.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/47ebd586_7330_4bbc_8792_6dbbff4f9ab8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/b335258e_972f_43d9_b31e_a9e95915a1cc.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/5e4b90cc_f131_44b1_b4f2_12d9f5239b3f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/c3802b32_339f_4c05_b073_c4de09a54f1e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/892ad027_3ef1_4fe8_bd2f_7e0a9d9cb64d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/ba89e33d_9ddf_40db_bd80_cfd107e699b2.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/617ed50d_af83_4e53_91f5_e211fa7f9f69.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/a6e70045_01d9_4a36_bb57_1cc4e7d4be98.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/a7e1ed17_43e7_477a_81a4_4d8f674da40e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/b9efd79c_24c8_41ee_82ee_a618419c77fc.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/ce9e2ce2_3efa_4963_80a2_bb22dab269c7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/528bdea7_ef9f_4c3d_aec9_f4211ff8295f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/ac3eade9_e778_4774_8d98_d0c9996fa55f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/c4806a9b_3385_4d9c_bbb0_0fa761f4ff7b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/c0fdb587_440a_4cce_bcbd_dc44ea5f6355.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11035/ce867757_4b07_47c7_8b81_dabb54875653.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/e2f52bc1_d5f6_4877_bf33_b12fe3c273d9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/9a66025d_f5c6_4f29_809c_cdb320dbf603.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/4c6296fc_4e82_4662_989e_57163f7d170a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/b821d2d5_706a_4ab2_8919_e39cf28361a0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/bebbe1dc_ef01_4dda_9d89_b291503f1292.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/f0ccebaa_687e_4303_88da_1c3f9101ed69.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/2a23d39e_5f89_460d_b240_870d8299e8bc.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/d5d7eeb5_8177_4b37_befc_922e4b7f9435.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/e45ce266_4f78_4530_9b22_0d9b4faf78c2.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/8c4a63ed_1337_4f55_a3b7_edcd62774fef.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/920d3065_1630_4461_8004_71d4e2f7a8b3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/dcd4dbdc_b82a_445b_bf62_81dc8323ff64.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/47a8b7cc_277f_4e68_8df8_f156e7660015.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/b5d447fe_c1ba_42d2_aec0_bd55985b81d3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/b561d495_121c_46c4_8022_2d57c925f0ea.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/60ba922a_856f_4586_aa82_53ff3d1a9e6c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/b3aa0937_0cb2_4c9d_80d3_93c066373d9d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/e8fc6974_333f_4c0f_bcd2_26225234ea72.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/02ea4c62_337d_4c3f_b53b_a5adc7e8baca.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/85631287_2fbf_4f06_b31d_56633164abd4.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/1a07b656_e5ad_4482_b22c_a500802f627e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/258988ff_6188_485a_bef7_4872ce4f35c9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/99812438_8dfb_4f76_8e49_65157cfd8994.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/07707bda_ec5f_4dbc_9b3c_d7e86dd80d94.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/6e870229_0ea9_4dff_b9b0_6e28a0055370.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/85b584cc_4add_4b46_ad69_aaa73cd92830.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/80213e3a_95df_4266_9cd7_6a4b57b13b59.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/141b270e_363b_4a49_81f7_2e57058a1e79.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/219ffc20_d75e_4889_a40b_5b0388d34f9e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/99502a53_6d0a_48ce_a2cc_56154c06199e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/c27689a1_9a93_4153_8bf1_b0036da8c18e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/b3f3c604_44eb_4f98_9b80_1b05fc690232.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/99906281_6ecc_43ce_9155_6a43672cadf8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/3f95d984_1af4_40d2_9958_57c173cd7d0e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/dcc8d58b_a0ba_4c17_9885_e69387939176.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/4263db3e_dff2_411a_9348_1edcdd25e576.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/0abfdb13_fd3a_44f9_a705_7d794b2acf7b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/191c00c0_6f70_4ef2_9254_296db5eaf089.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/4c043d29_6d06_4585_a9ea_c50c056d94b5.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/72776720_3a75_431e_bf2a_823055318502.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/e771efbd_ebe4_4ace_89b9_65b1452d8065.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/fc11f5aa_585a_4905_b6e3_967326909f15.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/bba37f5f_49f6_4fb3_9022_749702fc6ec3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/14100d2c_362d_498c_b478_b986e167677c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/4bc97da5_8dc7_4c1c_9a9d_e739146df7de.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/7d9816cc_35eb_49ec_b605_c51a945bffe6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/2f0b02fa_e8f0_4231_8f21_3a52762ec4ee.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/eb5af6bb_491d_4d1e_aca2_fc6335866548.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/4a2de287_0abd_4f8d_8702_de91110b4e26.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/ec6099c2_3187_4fb0_995f_1a0e12194b7a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/165e691e_542a_432f_bb0c_18661f87fe3e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/efd5d4fc_5d56_483a_a959_070d4b3fe08c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11031/7aa936e8_9973_47e0_aad6_97e0416f63f3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/cde0766d_6ef6_4920_9496_ec83cd618977.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/cb1b2cbd_721a_4f8a_a7f1_a65ec2235c41.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/6350a804_89dd_48be_9d3e_7a766222be27.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/791db58c_028e_45bf_a549_0004f8c0c0fd.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/a3b7fffe_155d_410e_bcec_090419441a3a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/da0f1b25_52db_4869_b58f_933e3a1448ee.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/755b9260_74c3_4b11_89f0_466efa95423d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/fbe39a60_ece8_4e2a_8625_afd712333032.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/9c64b4e4_fc10_4250_913a_b30b37c476eb.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/6a851ab5_df21_4713_857d_ff4eefc04806.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/83aff755_3931_4bc0_8af3_6ec66b671e79.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/8f8f94a7_212f_46ea_85fc_a92949b33880.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/793b2218_7dbc_4c7b_a71e_1fc4c1c48a2f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/1b0ca421_9c35_4fa2_9055_58358ba3a701.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/7a8b1937_ea5d_4918_b4de_167aa4dcb877.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/028aabe5_fcb0_44dd_b15e_970a867466ae.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/ddd679bf_897b_4aff_83b4_a52c9bf17127.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/a359e9be_719a_4c66_a760_87d0dabe4aaf.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/75b79217_bbdf_404a_afee_e3022c45e050.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/178b511a_717a_487a_ba6c_22e36542bfa1.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/7c74eb63_6a21_4786_b4f3_13b61d3db670.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/7d9b5fbe_e60a_4a5c_8247_5f7e8bebfd3a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/7fd5484b_b0f9_4d38_8345_a1903b2e7194.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/b361ca14_3173_43bf_ae81_82597eedfced.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/83780953_79be_404d_bb1f_e9b606ed4b60.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/7dee15d7_c3bc_4652_98f0_6468c69fe8d0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/62d57399_7cf9_450b_ad7f_1631df1c16af.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/911/11036/e473effc_22aa_4892_a10a_9322d667b62a.jpg"
                               }
                            ]
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Jharkhand",
                   "City": [
                      {
                         "City_Name": "Bokaro steel city",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2318",
                               "Franchisee_Name": "KIDZEE  BALIDIH BOKARO",
                               "Operating_Status": "A",
                               "Address1": "AT-Datkidih Sr No 23, Plot No 4703",
                               "Address2": "PO-Balidih, Bokaro Steel City, Bokaro",
                               "Place": "Balidih, Near Housing Colony, Behind Govind Market",
                               "City_Id": "180",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2318@kidzee.com",
                               "Mobile_No": "9334212012",
                               "Phone_No1": "06542-253234",
                               "Phone_No2": "",
                               "Latitude": "23.641508508483586",
                               "Longitude": "86.08101993696414",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/757188/10973/97753d68_e490_4aac_af61_cba55922e0fc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/757188/10990/b19cb519_07ad_4585_9a55_279c9d836c44.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4510",
                               "Franchisee_Name": "Kidzee Bokaro",
                               "Operating_Status": "A",
                               "Address1": "Plot No-410,",
                               "Address2": "Steel City,",
                               "Place": "Bokaro, Bari Cooperative Colony,",
                               "City_Id": "180",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4510@kidzee.com",
                               "Mobile_No": "7979052716",
                               "Phone_No1": "9431127314",
                               "Phone_No2": "",
                               "Latitude": "23.63471",
                               "Longitude": "86.11884",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2472586/10930/f1cd4f92_cdae_4aaa_939f_0968b3c8775f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2472586/10931/dd92a49f_c5be_4bf0_b920_c4217fa403fd.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4598",
                               "Franchisee_Name": "Kidzee Chas",
                               "Operating_Status": "A",
                               "Address1": "Abhimanyu Nagar,",
                               "Address2": "",
                               "Place": "Chas ,Near Chas Block,",
                               "City_Id": "180",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4598@kidzee.com",
                               "Mobile_No": "7717745812",
                               "Phone_No1": "7717-745812",
                               "Phone_No2": "",
                               "Latitude": "23.624209648810265",
                               "Longitude": "86.17888011038303",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10971/16f1bdfd_d0f7_47db_93bd_9eccb6d44108.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10971/f39e4fff_3388_4bc1_838c_c6cec45f6154.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10971/0efdc24c_de8b_4a34_9883_c89b98fadd16.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10971/db5d3750_2f61_47d2_b0fd_61f63d5b2a9c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10971/5b4780df_cb4d_4a1f_8f83_8621b7ec7aee.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10971/770d28f8_96f6_441c_8d58_c04e393040e0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/91f79ed3_6553_43d1_928d_25d36c58651d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/3c129b22_eec7_4c31_9152_81f2d33b1b4b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/fb996940_d593_4b7e_bf00_a0317eb433e3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/eb3b7795_68b0_4415_8083_746d9d197391.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/13bb1c47_ff0c_4af8_91e2_2729d9b8df50.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/8d5b764f_5a3c_4905_84c5_2651e48bf308.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/4135f606_dcad_49f2_8d51_43acf90a22fd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/1f782305_de68_423d_b360_8d33485aef0e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/20bea8ad_6b04_47bd_8104_c38fa08a14e4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/d386eafc_25ad_40b0_98a9_5e376e1f7f98.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/fbe422b8_acde_46b4_9997_9e0b3159ae4a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/b5dcf25c_4f43_4648_b0ea_9d57f1c7b5b6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2469536/10972/8ce2902d_9c9c_4da0_9fc3_b3d5c7143657.jpg"
                                  }
                               ]
                            }
                         ]
                      },
                      {
                         "City_Name": "Chaibasa",
                         "Franchisee": {
                            "Franchisee_Code": "E-U-C-1015",
                            "Franchisee_Name": "Kidzee- Guru Nanak Pre-School",
                            "Operating_Status": "A",
                            "Address1": "New Colony,",
                            "Address2": "Nimidh Landmark,",
                            "Place": "Nimidh, Residence of Sadarjeet Singh,",
                            "City_Id": "194",
                            "State_Id": "19",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1015@kidzee.com",
                            "Mobile_No": "9031533980",
                            "Phone_No1": "256622",
                            "Phone_No2": "",
                            "Latitude": "22.544386",
                            "Longitude": "85.801015"
                         }
                      },
                      {
                         "City_Name": "Daltonganj"
                      },
                      {
                         "City_Name": "Dhanbad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-4287",
                               "Franchisee_Name": "Kidzee Saraidhella",
                               "Operating_Status": "A",
                               "Address1": "Sri Niket New-Bank Colony,",
                               "Address2": "",
                               "Place": "Saraidhela, Near-Steel Gate,",
                               "City_Id": "255",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4287@kidzee.com",
                               "Mobile_No": "7004741758",
                               "Phone_No1": "7004741758",
                               "Phone_No2": "",
                               "Latitude": "23.81589",
                               "Longitude": "86.46046"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5794",
                               "Franchisee_Name": "Kidzee Dhanbad",
                               "Operating_Status": "A",
                               "Address1": "Piperabera",
                               "Address2": "",
                               "Place": "Kusum Vihar",
                               "City_Id": "255",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5794@kidzee.com",
                               "Mobile_No": "8981691446",
                               "Phone_No1": "8981691446",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Deoghar",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5007",
                            "Franchisee_Name": "KIDZEE MADHUPUR",
                            "Operating_Status": "A",
                            "Address1": "Abdul Aziz Road",
                            "Address2": "Patharchapti",
                            "Place": "Madhupur ,Patharchapti",
                            "City_Id": "265",
                            "State_Id": "19",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5007@kidzee.com",
                            "Mobile_No": "8603337773",
                            "Phone_No1": "8603-337773",
                            "Phone_No2": "",
                            "Latitude": "24.2635'N",
                            "Longitude": "86.6409'E"
                         }
                      },
                      {
                         "City_Name": "Hazaribagh",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-5055",
                               "Franchisee_Name": "Kidzee Ramnagar Hazaribag",
                               "Operating_Status": "A",
                               "Address1": "Plot No-1752, Khata No 34",
                               "Address2": "Ramnagar Vishnupuri",
                               "Place": "Ramnagar, Vishnupuri",
                               "City_Id": "428",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5055@kidzee.com",
                               "Mobile_No": "7979022506",
                               "Phone_No1": "7979022506",
                               "Phone_No2": "",
                               "Latitude": "23.98942",
                               "Longitude": "85.33676"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5263",
                               "Franchisee_Name": "Kidzee Baihari",
                               "Operating_Status": "A",
                               "Address1": "Vill-Guruwa, Near Lalpur Chowk",
                               "Address2": "Ansar Road, PS- Hazaribagh, PO-Seotagarha",
                               "Place": "Baihari, Near Lalpur Chowk",
                               "City_Id": "428",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5263@kidzee.com",
                               "Mobile_No": "7004992932",
                               "Phone_No1": "7004992932",
                               "Phone_No2": "",
                               "Latitude": "23.96508",
                               "Longitude": "85.39967"
                            }
                         ]
                      },
                      {
                         "City_Name": "Jamshedpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-U-738",
                               "Franchisee_Name": "KIDZEE-DIMNA-JAMSHEDPUR- IMPRESSION",
                               "Operating_Status": "A",
                               "Address1": "NH-33, Beside-SBI Dimna,",
                               "Address2": "Rivett Colony, Road No-3,",
                               "Place": "Dimna, Near Sidhu Kanu School,",
                               "City_Id": "485",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "kidzee738@kidzee.com",
                               "Mobile_No": "9905504921",
                               "Phone_No1": "9905504921",
                               "Phone_No2": "",
                               "Latitude": "22.838921",
                               "Longitude": "86.222202"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3575",
                               "Franchisee_Name": "Kidzee Baradwari",
                               "Operating_Status": "A",
                               "Address1": "90, New Baradwari,",
                               "Address2": "Sakchi,",
                               "Place": "Baradwari, Near-Baradwari Ground,",
                               "City_Id": "485",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3575@kidzee.com",
                               "Mobile_No": "9431113940",
                               "Phone_No1": "9431113940",
                               "Phone_No2": "",
                               "Latitude": "22.807989",
                               "Longitude": "86.211387"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3557",
                               "Franchisee_Name": "Kidzee C H Area",
                               "Operating_Status": "A",
                               "Address1": "Circuit House Area, (North) Road No-5,",
                               "Address2": "House No-9, East Singhbhum,",
                               "Place": "C.H. Area, Near Army Camp,",
                               "City_Id": "485",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3557@kidzee.com",
                               "Mobile_No": "9234901000",
                               "Phone_No1": "9234901000",
                               "Phone_No2": "",
                               "Latitude": "22.815658",
                               "Longitude": "86.185825"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4714",
                               "Franchisee_Name": "Kidzee Tinplate",
                               "Operating_Status": "A",
                               "Address1": "House No-10, Nanak Nagar,",
                               "Address2": "East Singhbhum,",
                               "Place": "Tinplate, Near South Indian Association",
                               "City_Id": "485",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4714@kidzee.com",
                               "Mobile_No": "9934977750",
                               "Phone_No1": "0657-222000",
                               "Phone_No2": "",
                               "Latitude": "22.787614",
                               "Longitude": "86.225641",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2489987/11007/cee2e9f3_e99b_4bc1_b2ae_8bd5cfa1e6e2.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-R-C-4947",
                               "Franchisee_Name": "Kidzee Sundernagar",
                               "Operating_Status": "A",
                               "Address1": "Mouza/Village Purihasa,Sundernagar",
                               "Address2": "Plot No 816(P),Khata No 1183, Dist Singhbhum East",
                               "Place": "PO & PS Sundernagar ,Near Bank of India",
                               "City_Id": "485",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4947@kidzee.com",
                               "Mobile_No": "8271499191",
                               "Phone_No1": "0657-2954371",
                               "Phone_No2": "",
                               "Latitude": "22.73817",
                               "Longitude": "86.19732"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3704",
                               "Franchisee_Name": "Kidzee Chepa Pul ,Mango",
                               "Operating_Status": "A",
                               "Address1": "Sky Touch Enclave , ward no 8, Ground floor ,Azadnagar",
                               "Address2": "Near police Check Post,Beside Amarjyoti School Old Purulia Road",
                               "Place": "Chepa Pul ,Mango ,Beside Amarjyoti School Old Puru",
                               "City_Id": "485",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3704@kidzee.com",
                               "Mobile_No": "7091272704",
                               "Phone_No1": "7091272704",
                               "Phone_No2": "",
                               "Latitude": "22.84291",
                               "Longitude": "86.20177"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5666",
                               "Franchisee_Name": "Kidzee Jugsalai",
                               "Operating_Status": "A",
                               "Address1": "House No 468 ",
                               "Address2": "Beside Rajashthan Sewa Sadan",
                               "Place": "Jugsalai",
                               "City_Id": "485",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5666@kidzee.com",
                               "Mobile_No": "8092277723",
                               "Phone_No1": "8092277723",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Ranchi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2208",
                               "Franchisee_Name": "Kidzee Bariatu Ranchi",
                               "Operating_Status": "A",
                               "Address1": "House No-2154 RZ",
                               "Address2": "Mohala No-427, Ward No-20,Near SK Gas Godown",
                               "Place": "Bariatu ,Near Cheshire Home Rd",
                               "City_Id": "832",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2208@kidzee.com",
                               "Mobile_No": "9570280555",
                               "Phone_No1": "9570220555",
                               "Phone_No2": "",
                               "Latitude": "23.395531",
                               "Longitude": "85.362687"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4265",
                               "Franchisee_Name": "Kidzee Hinoo",
                               "Operating_Status": "A",
                               "Address1": "2/45, New Area Gandhi Nagar",
                               "Address2": "Hinoo",
                               "Place": "Ranchi ,Behind Premsons Motor, Birsha chowk",
                               "City_Id": "832",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4265@kidzee.com",
                               "Mobile_No": "8942959989",
                               "Phone_No1": "8942959989 ",
                               "Phone_No2": "",
                               "Latitude": "23.336127",
                               "Longitude": "85.309604"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4330",
                               "Franchisee_Name": "Kidzee Tupudana",
                               "Operating_Status": "A",
                               "Address1": "Aayush Vihar,",
                               "Address2": "Tonko, Tonko Bridge,",
                               "Place": "Tupudana, Near-Ring Road,",
                               "City_Id": "832",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4330@kidzee.com",
                               "Mobile_No": "9937161222",
                               "Phone_No1": "9348-053307",
                               "Phone_No2": "",
                               "Latitude": "23.26348303067866",
                               "Longitude": "85.32755048063848",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184202/10895/488438bc_be2e_4df5_9254_a032741300f1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184202/10895/8b46cf6a_cfd7_4850_8b13_e7e29177c016.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184202/10895/aa498efe_702f_4a46_9d2b_1b8198e51a15.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184202/10900/d7f035ba_407e_431d_9e7c_3d0329c9ce16.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184202/10900/df82eddc_1aa9_4503_ba18_d851ad3842d4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184202/10900/1d04d1cf_6b80_4f47_8569_2462068632b6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184202/10912/33f6268b_d165_4187_9d16_6be927aa52e6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184202/10912/401511fe_0c23_49b7_8fb9_1d2339391b97.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184202/10912/9c64ec87_a603_4e29_a6d8_d059419a1112.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4516",
                               "Franchisee_Name": "Kidzee Kanke Road",
                               "Operating_Status": "A",
                               "Address1": "Rama Niwas,",
                               "Address2": "",
                               "Place": "Kanke Road, Beside-Holiday Home,",
                               "City_Id": "832",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4516@kidzee.com",
                               "Mobile_No": "7903146060",
                               "Phone_No1": "7903146060",
                               "Phone_No2": "",
                               "Latitude": "23.4126964",
                               "Longitude": "85.3161871"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5039",
                               "Franchisee_Name": "Kidzee Singhmore",
                               "Operating_Status": "A",
                               "Address1": "Latma Road,Singhmore Hatia",
                               "Address2": "Vikas Nagar,Road No 3",
                               "Place": "Singhmore ,Behind Sabitri Enclave",
                               "City_Id": "832",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5039@kidzee.com",
                               "Mobile_No": "9427609900",
                               "Phone_No1": "9427609900",
                               "Phone_No2": "",
                               "Latitude": "23.29917",
                               "Longitude": "85.31489"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4264",
                               "Franchisee_Name": "Kidzee Ratu Road",
                               "Operating_Status": "A",
                               "Address1": "571/A, Krishna Nagar Colony,",
                               "Address2": "PS:Sukhdeo Nagar, PO:GPO,",
                               "Place": "Ratu Road, Ranchi, Mount Motor Gali,",
                               "City_Id": "832",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4264@kidzee.com",
                               "Mobile_No": "7779872222",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "23.38398",
                               "Longitude": "85.30735"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5754",
                               "Franchisee_Name": "Kidzee Jhiri Road",
                               "Operating_Status": "A",
                               "Address1": "Next to Jhiri Enclave",
                               "Address2": "Ground Floor",
                               "Place": "Jhiri Road",
                               "City_Id": "832",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5754@kidzee.com",
                               "Mobile_No": "8986833500",
                               "Phone_No1": "8986833500",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5683",
                               "Franchisee_Name": "Kidzee Basargarh",
                               "Operating_Status": "A",
                               "Address1": "ro.18, om prakash nagar",
                               "Address2": "Tupudana",
                               "Place": "Basargarh",
                               "City_Id": "832",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5683@kidzee.com",
                               "Mobile_No": "8709244137",
                               "Phone_No1": "8709244137",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5592",
                               "Franchisee_Name": "Kidzee New Pundag",
                               "Operating_Status": "A",
                               "Address1": "Road no.4, Hanuman Nagar",
                               "Address2": "Sail city road, Near SBI atm",
                               "Place": "New Pundag",
                               "City_Id": "832",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5592@kidzee.com",
                               "Mobile_No": "9312482095",
                               "Phone_No1": "9312482095",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5581",
                               "Franchisee_Name": "Kidzee Sukurhutu",
                               "Operating_Status": "A",
                               "Address1": "PS Kanke",
                               "Address2": "Ranchi Ring Road",
                               "Place": "Sukurhutu",
                               "City_Id": "832",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5581@kidzee.com",
                               "Mobile_No": "8210517068",
                               "Phone_No1": "8210517068",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Saraikela / Seraikella"
                      },
                      {
                         "City_Name": "Kodarma",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5746",
                            "Franchisee_Name": "Kidzee Telaiya",
                            "Operating_Status": "A",
                            "Address1": "C H high school road",
                            "Address2": "Jhumri Telaiya",
                            "Place": "Telaiya",
                            "City_Id": "1124",
                            "State_Id": "19",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5746@kidzee.com",
                            "Mobile_No": "7258054254",
                            "Phone_No1": "7258054254",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Ghatsila"
                      },
                      {
                         "City_Name": "Ramgarh"
                      },
                      {
                         "City_Name": "Gomoh"
                      },
                      {
                         "City_Name": "Pakur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2583",
                            "Franchisee_Name": "Kidzee Pakur",
                            "Operating_Status": "A",
                            "Address1": "Kurapara, College Road,",
                            "Address2": "PO + PS: Dist. Pakur,",
                            "Place": "College Road,",
                            "City_Id": "1524",
                            "State_Id": "19",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2583@kidzee.com",
                            "Mobile_No": "7763853330",
                            "Phone_No1": "7763-853330",
                            "Phone_No2": "",
                            "Latitude": "24.633355",
                            "Longitude": "87.850188",
                            "Images": [
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/3e985562_2685_4f93_847a_151e591bfc0c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/b58a019c_1bcb_4861_91ed_5f7904d3c49c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/56b16e8b_5013_494a_8fc5_c0d79d996602.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/4562747b_149d_4513_857d_d59599d4b97d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/70d8222a_3578_4b69_995c_1431bb95116e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/0a30d5a1_e57c_4237_bec5_ef9b8d4f6012.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/5ca91b12_dcc1_4923_9362_fa45a6aaa4cd.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/6d2502b5_efc4_465e_be22_fb56ede6b524.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/5621bec5_3438_4f14_b3da_80bf3c5da490.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/c9c79d29_b1bc_4812_9de9_3692d4a7215f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/4dffa465_433c_48e3_aa9f_44f31cdfa2a3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/eddc4237_3b59_4c5d_8a9f_0dbc772dc8bd.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/75c39f7a_6dd3_44f7_a82a_04ca3fee91a8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/cc237ce1_c25b_49f1_b232_67c01a97ecc1.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/6b899804_918a_40c8_80c8_d73bd1b7689f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/9c546ae6_0408_4f38_8ffc_c53dd48f9c6c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/0b3f8222_ced1_4459_9a3e_187d7be492a3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/4f8aa4f6_62b2_4b97_8a0f_6fbd165148c9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/f21c9e6d_cf6a_4179_af4c_b5b1b3dfc3d7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/74b38c83_8076_4848_8b63_4ac0ed5829c4.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/a0280b64_17fc_4218_9168_72dc7da0e624.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/4e9b41a0_0060_4f12_9f7a_e61134ff4672.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/717769a4_8adf_4db9_83e6_85a703c109cd.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/956715/10877/2a991ce8_4443_4eec_96d6_e421d0878ccf.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Chakradharpur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3468",
                            "Franchisee_Name": "Kidzee Chakradharpur",
                            "Operating_Status": "A",
                            "Address1": "Holding No-126,",
                            "Address2": "Ward No-4, Pump Road,",
                            "Place": "Chakradharpur Municipality, Near-Gramin Bank,",
                            "City_Id": "1610",
                            "State_Id": "19",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3468@kidzee.com",
                            "Mobile_No": "8292842403",
                            "Phone_No1": "8292842402",
                            "Phone_No2": "",
                            "Latitude": "22.6801592992082",
                            "Longitude": "85.6196469068527",
                            "Images": [
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3468/263/e4968975_96ac_457e_bce5_0a0ee9885b9c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3468/263/7d06a19c_915c_4370_923a_de023d69763a.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Garhwa",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3401",
                            "Franchisee_Name": "Kidzee Garhwa",
                            "Operating_Status": "A",
                            "Address1": "Ward No-5,",
                            "Address2": "Sai Mohalla,",
                            "Place": "Garhwa, Near- Oriental Bank Of Commerce,",
                            "City_Id": "1615",
                            "State_Id": "19",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3401@kidzee.com",
                            "Mobile_No": "9234109989",
                            "Phone_No1": "8298981098",
                            "Phone_No2": "",
                            "Latitude": "23.3441",
                            "Longitude": "85.309562"
                         }
                      },
                      {
                         "City_Name": "Godda",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5744",
                            "Franchisee_Name": "Kidzee Godda",
                            "Operating_Status": "A",
                            "Address1": "Lohiya Nagar",
                            "Address2": "Near Jila Parishad",
                            "Place": "Godda",
                            "City_Id": "1633",
                            "State_Id": "19",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5744@kidzee.com",
                            "Mobile_No": "8697879121",
                            "Phone_No1": "8697879121",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Dumka"
                      },
                      {
                         "City_Name": "Latehar",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4659",
                            "Franchisee_Name": "Kidzee Latehar",
                            "Operating_Status": "A",
                            "Address1": "Gaytrinagar,",
                            "Address2": "Near-Children Convent,",
                            "Place": "Latehar, Near-Children Convent",
                            "City_Id": "1887",
                            "State_Id": "19",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4659@kidzee.com",
                            "Mobile_No": "7991131880",
                            "Phone_No1": "9431360617",
                            "Phone_No2": "",
                            "Latitude": "23.744532303798255",
                            "Longitude": "84.49571578741075",
                            "Images": [
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481339/10938/e4d2596c_be2c_4678_89ac_2183a4fafd13.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481339/10939/3d074e24_a2de_4bf6_b2aa_fda29ab84be1.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481339/10939/f7ca5eec_72ff_45dc_90af_53c22226ca8b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481339/10939/f6039395_95c4_425e_ade0_6eaae5e79b93.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481339/10939/c2e16304_db14_4868_aaa7_8dbaa77f2d35.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481339/10939/0417aae5_9a94_4d34_99c4_5ba523e4503d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481339/10939/0e28ffc8_4cb2_441f_8152_7be27fe9156e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481339/10939/e0c84827_199a_4bfc_a414_fbe0c8df9359.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481339/10939/a46759d9_0bf1_4ffc_ba0c_3d72bf694ca9.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Giridih",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-4697",
                               "Franchisee_Name": "Kidzee Karbala Road",
                               "Operating_Status": "A",
                               "Address1": "Jay Prakash Nagar,",
                               "Address2": "",
                               "Place": "Karbala Road, Ramayan Utsav Palace",
                               "City_Id": "1888",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4697@kidzee.com",
                               "Mobile_No": "9693225111",
                               "Phone_No1": "9693225111",
                               "Phone_No2": "",
                               "Latitude": "24.185939681364335",
                               "Longitude": "86.31903290748596"
                            },
                            {
                               "Franchisee_Code": "A-E-A-1030",
                               "Franchisee_Name": "Ankurum Parasanath Dumri",
                               "Operating_Status": "A",
                               "Address1": "At -Jamtara, PO-Jamtara",
                               "Address2": "Ps- Dumri ,",
                               "Place": "Dumri ,Parasnath Railway ,Non-operational",
                               "City_Id": "1888",
                               "State_Id": "19",
                               "Country_Id": "1",
                               "Email_Id": "Raushan.kumar223@gmail.com",
                               "Mobile_No": "9006160091",
                               "Phone_No1": "7909098462",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Mahagama",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5373",
                            "Franchisee_Name": "Kidzee Mahagama",
                            "Operating_Status": "A",
                            "Address1": "Kechua Chowk",
                            "Address2": "Ram Nagar",
                            "Place": "Mahagama",
                            "City_Id": "1965",
                            "State_Id": "19",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5373@kidzee.com",
                            "Mobile_No": "8882873040",
                            "Phone_No1": "8882873040",
                            "Phone_No2": "",
                            "Latitude": "25.038151",
                            "Longitude": "87.317213",
                            "Images": [
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2881720/11081/4872bf56_1497_47c6_8153_a14788d6d1ac.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2881720/11081/9858ddd2_f9e5_42ab_91a2_7ebfacdc5b5e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2881720/11081/acb7143a_c317_4d0d_a0de_809d27fec3b6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2881720/11081/c9a70a62_d029_4a3f_8eaf_a9bb6e1c1dc9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2881720/11082/43e3b69d_f7aa_401f_9962_2bc037895ee3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2881720/11082/c0f1b69c_03a4_48c5_8621_dfa22459845a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2881720/11082/1c670d55_d75b_42b7_8b20_98b47c5cf9b2.jpg"
                               }
                            ]
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Karnataka",
                   "City": [
                      {
                         "City_Name": "Bengaluru",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-B-M-381",
                               "Franchisee_Name": "KIDZEE-Sakshi Education Initiative",
                               "Operating_Status": "A",
                               "Address1": "No-64, 5th Main, 2nd D Cross,",
                               "Address2": "Hanumanthappa Layout,",
                               "Place": "Sultanpalya, Pushpanjali Theatre",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee381@kidzee.com",
                               "Mobile_No": "9343103235",
                               "Phone_No1": "9341444727",
                               "Phone_No2": "",
                               "Latitude": "13.033565",
                               "Longitude": "77.605501",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/159/bd9bd67b_73bf_4870_9f60_ee925b2c5eeb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/159/708f0b69_d13e_4c19_a223_f121ebe82e66.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/159/98ee2aa6_7341_4b5c_8bf2_4533bed48621.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/159/8ed99d6a_6f38_4506_b6bd_1ad84edce174.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/159/8bf377e5_6613_4890_a6c0_41af9ac39bd7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/160/c959fe2d_cac0_435e_b065_55200e49a212.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/160/fc86a65d_b421_4a33_a1e7_9d99fd2f54d9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/160/88c62b35_5705_4d64_bd59_eb355ebe4845.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/160/9b2ec650_a68a_4ee0_aa7b_b49cb85bb534.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/162/48a213eb_29dc_4593_833c_149f32770d24.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/162/62eaba1b_ee13_432b_9625_41d5f5f6adad.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/162/d6006bcb_89a6_4b73_b7cc_cdc0b33b1eda.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/162/78c6b64f_def4_4e58_8bee_69891abc43c7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/162/fcb6250d_ec44_420c_9631_912691afb4c3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/162/8931525e_8cef_4dd7_b41a_180350bb2655.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/163/dcc640ea_3591_43b6_99de_873c924328f6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/163/8da0ff52_4eeb_4fba_84ca_bacee341e574.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/163/1f589b6a_0556_4ac2_88cf_59c731f2d7ac.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/381/163/4f5b63af_bd2d_41b2_a3a1_926696a325eb.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-B-S-436",
                               "Franchisee_Name": "Kidzee - Jayanagar LITTLE HEARTS",
                               "Operating_Status": "A",
                               "Address1": "No-71, (Old No-916),",
                               "Address2": "28th Main Road, 9th Block,",
                               "Place": "Jayanagar, 9th Block, Next to Karnataka Bank",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee436@kidzee.com",
                               "Mobile_No": "9341916711",
                               "Phone_No1": "9341916711",
                               "Phone_No2": "",
                               "Latitude": "12.926073",
                               "Longitude": "77.595324"
                            },
                            {
                               "Franchisee_Code": "S-B-M-486",
                               "Franchisee_Name": "Kidzee-Smart Kids School",
                               "Operating_Status": "A",
                               "Address1": "#1023,16th Main,",
                               "Address2": "1st stage,1st phase, Land Mark,",
                               "Place": "BTM Layout, Near- Advaitha Petrol Bunk",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee486@kidzee.com",
                               "Mobile_No": "9845448549",
                               "Phone_No1": "26680255",
                               "Phone_No2": "",
                               "Latitude": "12.913172",
                               "Longitude": "77.61011"
                            },
                            {
                               "Franchisee_Code": "S-B-M-498",
                               "Franchisee_Name": "KIDZEE-MILLENNIUM KIDZ",
                               "Operating_Status": "A",
                               "Address1": "706, Shruthi",
                               "Address2": "RBI Layout, JP Nagar",
                               "Place": "J P Nagar, 7th Phase",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee498@kidzee.com",
                               "Mobile_No": "9886148570",
                               "Phone_No1": "9886148570",
                               "Phone_No2": "",
                               "Latitude": "12.900074",
                               "Longitude": "77.58258",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/498/200/ebea995d_f4e9_4411_b9bf_6530e68de1e0.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-A-M-745",
                               "Franchisee_Name": "Kidzee-Malleswaram- Bangalore",
                               "Operating_Status": "A",
                               "Address1": "No-2, 12th Cross,",
                               "Address2": "6th Main,",
                               "Place": "Malleswaram, Near-Vidya Mandir School,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee745@kidzee.com",
                               "Mobile_No": "9036645264",
                               "Phone_No1": "9036645264",
                               "Phone_No2": "",
                               "Latitude": "13.002467",
                               "Longitude": "77.566614",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/745/167/99015227_ea21_407e_99ca_7fb091c3ddad.JPG"
                               }
                            },
                            {
                               "Franchisee_Code": "S-A-M-755",
                               "Franchisee_Name": "KIDZEE- H B R LAYOUT- BANGALORE",
                               "Operating_Status": "A",
                               "Address1": "#792,10th Main Road,",
                               "Address2": "HBR Layout,1st Phase,3rd Block",
                               "Place": "Brindavannagar",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee755@kidzee.com",
                               "Mobile_No": "9916351515",
                               "Phone_No1": "9916351515",
                               "Phone_No2": "",
                               "Latitude": "13.012043",
                               "Longitude": "77.619342"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1101",
                               "Franchisee_Name": "Kidzee-HSR Layout",
                               "Operating_Status": "A",
                               "Address1": "1492.20th Main",
                               "Address2": "Near Park Square Apartment",
                               "Place": "HSR Layout , Sector 1",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1101@kidzee.com",
                               "Mobile_No": "9449065321",
                               "Phone_No1": "9449-065321",
                               "Phone_No2": "",
                               "Latitude": "12.908136",
                               "Longitude": "77.647608",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/833/10541/ee68506a_928a_4328_bf9c_79ad4cb8448d.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-A-M-1116",
                               "Franchisee_Name": "Kidzee-Wilson Garden,bangalore",
                               "Operating_Status": "A",
                               "Address1": "No.390/ 31, 5th Cross,",
                               "Address2": "",
                               "Place": "Near-Wilson Garden Police Station,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1116@kidzee.com",
                               "Mobile_No": "8050335393",
                               "Phone_No1": "8095525171",
                               "Phone_No2": "",
                               "Latitude": "12.951041",
                               "Longitude": "77.595912"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1178",
                               "Franchisee_Name": "Kidzee Jeevan Bhima Nagar",
                               "Operating_Status": "A",
                               "Address1": "16/ A,8 Cross, 24 10th Cross Road",
                               "Address2": "HAL 3rd Stage",
                               "Place": "Kodihalli",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1178@kidzee.com",
                               "Mobile_No": "9741085973",
                               "Phone_No1": "9741085973",
                               "Phone_No2": "",
                               "Latitude": "12.57512",
                               "Longitude": "77.38564",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1178/213/bec54af7_8cd3_4662_9438_de9052d35ed4.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-A-M-1379",
                               "Franchisee_Name": "Kidzee - HMT Layout",
                               "Operating_Status": "A",
                               "Address1": "HMT Layout,",
                               "Address2": "Behind-RT Nagar Police Station,",
                               "Place": "HMT Layout,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1379@kidzee.com",
                               "Mobile_No": "9343103235",
                               "Phone_No1": "8023434445",
                               "Phone_No2": "",
                               "Latitude": "13.038616",
                               "Longitude": "77.591825",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/a099134d_d4ab_4505_866f_684694a30545.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/c8a824e4_2a47_4f11_930f_0057606553f1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/f63a8915_3e9f_4007_bad5_b6470f75a886.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/6cc86fe5_2a19_480e_95c3_5eb8a13fcd8d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/58/f3f0eeb7_e326_45d2_b90b_7627ac915816.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/58/84b92b37_40a1_4d6a_aa4e_1b9a2c6e5a39.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/57/7bf1722f_54ff_4922_9cc0_744f57adeb76.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/57/400d84d5_7af4_4dd6_b80b_5f0e05041df8.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/d80165ee_28cc_47b0_a3ba_92e4095f5911.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/9df96bb9_32be_4357_986f_6c2c1da02611.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/d0d0644a_41f8_40f7_8709_5a1b66146d1f.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/13b20b29_ea3e_415a_8a6e_e279830adfb8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/3921a868_b3b1_4f01_93cd_584b86b2ae52.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/79/8b76245d_8f55_4551_85bd_241d6ab316eb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/79/efb4b551_11f0_4d90_8dd5_92e5f2c14eae.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/58/c71faf8e_192c_49fa_af99_49b36221c02d.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/58/4fe23563_0cf3_4342_a5c5_534601af5cf6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/cf82d9af_8865_49a3_a359_14558598ddeb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/56/a69376bc_c1bf_46f0_a2cf_3384cafd9ecb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1379/57/f33c6730_b933_46d5_a498_c45ec9e9344f.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-A-M-1383",
                               "Franchisee_Name": "Kidzee - Chord Road",
                               "Operating_Status": "A",
                               "Address1": "#49/23,19th Main,",
                               "Address2": "2nd Block,",
                               "Place": "Rajajinagar, Behind-City Hospital,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1383@kidzee.com",
                               "Mobile_No": "9036645264",
                               "Phone_No1": "080-65791155",
                               "Phone_No2": "",
                               "Latitude": "12.996973",
                               "Longitude": "77.55118"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1398",
                               "Franchisee_Name": "Kidzee - Kalena Agrahara",
                               "Operating_Status": "A",
                               "Address1": "No.80, Meenakshi Layout, Beside Vitola Apartment,",
                               "Address2": "Kalena Agrahara,",
                               "Place": "Bannerghatta Road, Beside Vitola Apartment,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1398@kidzee.com",
                               "Mobile_No": "9036848881",
                               "Phone_No1": "8971488320",
                               "Phone_No2": "",
                               "Latitude": "12.914252",
                               "Longitude": "77.599887"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1409",
                               "Franchisee_Name": "Kidzee - Electronic City",
                               "Operating_Status": "A",
                               "Address1": "#320, 4th Cross, Celebrity Paradise Layout,",
                               "Address2": "Doddathogur Village E City-1,",
                               "Place": "Electronic City Doddathogur, Celebrity Paradise La",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1409@kidzee.com",
                               "Mobile_No": "6366438687",
                               "Phone_No1": "9108039707",
                               "Phone_No2": "",
                               "Latitude": "12.912756",
                               "Longitude": "77.59723"
                            },
                            {
                               "Franchisee_Code": "S-S-S-1478",
                               "Franchisee_Name": "Kidzee - Kodichikanahalli Begur",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 100, Lake City Layout,",
                               "Address2": "",
                               "Place": "Kodichikanahalli ",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1478@kidzee.com",
                               "Mobile_No": "8095115115",
                               "Phone_No1": "8095115728",
                               "Phone_No2": "",
                               "Latitude": "12.898498",
                               "Longitude": "77.617482"
                            },
                            {
                               "Franchisee_Code": "S-S-S-1478",
                               "Franchisee_Name": "Kidzee - Kodichikanahalli Begur",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 100, Lake City Layout,",
                               "Address2": "",
                               "Place": "Kodichikanahalli ",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1478@kidzee.com",
                               "Mobile_No": "8095115115",
                               "Phone_No1": "8095115728",
                               "Phone_No2": "",
                               "Latitude": "12.898498",
                               "Longitude": "77.617482"
                            },
                            {
                               "Franchisee_Code": "S-S-S-1586",
                               "Franchisee_Name": "Kidzee -  Bannerghatta Road Doddakammanahalli",
                               "Operating_Status": "A",
                               "Address1": "No 10 D N P Layout",
                               "Address2": "Near Amoda Valmark Apartment,",
                               "Place": "Doddakammanahali, Opposite-SLV Heights Apartment",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1586@kidzee.com",
                               "Mobile_No": "9740335600",
                               "Phone_No1": "9740335600",
                               "Phone_No2": "",
                               "Latitude": "12.8593",
                               "Longitude": "77.6014",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1586/53/c0cde3c8_6d43_47a5_b30e_99ff03e4018f.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1586/223/8617be7d_996e_4e03_bdbd_e0e6f2d6d64e.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-L-S-1537",
                               "Franchisee_Name": "Kidzee-Tatanagar",
                               "Operating_Status": "A",
                               "Address1": "# 229, 6th Main, 5th Cross,",
                               "Address2": "Kodigehalli",
                               "Place": "Tata Nagar ,Near Tatanagar Bus stop",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1537@kidzee.com",
                               "Mobile_No": "7676335776",
                               "Phone_No1": "7676335776",
                               "Phone_No2": "",
                               "Latitude": "12.971599",
                               "Longitude": "77.594563",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1537/27/3826c75b_f018_4d05_bcf7_0e44b54858a8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1537/27/6143d69f_dc64_4574_b8ef_a185dda990c3.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1537/27/e79cf32e_21b8_4afd_b4cc_bafb84bec9c5.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1537/27/54daf458_5d81_4d40_9ee3_b5cb6da4e265.JPG"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-S-S-1609",
                               "Franchisee_Name": "Kidzee-BEML Layout",
                               "Operating_Status": "A",
                               "Address1": "No - 402, 4th Cross, 8th Main, BEML Layout, 5th Stage,",
                               "Address2": "2nd Phase, Rajarajeshwarinagar,",
                               "Place": "BEML Layout",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1609@kidzee.com",
                               "Mobile_No": "8431984401",
                               "Phone_No1": "080-29700441",
                               "Phone_No2": "",
                               "Latitude": "12.9098486",
                               "Longitude": "77.5246201"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2328",
                               "Franchisee_Name": "Kidzee Defence colony sahakara nagar",
                               "Operating_Status": "A",
                               "Address1": "No 154,  Sai Srishti,",
                               "Address2": "5th Cross, Defence colony",
                               "Place": "sahakara nagar ,Near Raghavendra mutt",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2328@kidzee.com",
                               "Mobile_No": "9902084758",
                               "Phone_No1": "9902084758",
                               "Phone_No2": "",
                               "Latitude": "13.0606074512981",
                               "Longitude": "77.5832748994751"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2518",
                               "Franchisee_Name": "Kidzee-KR Puram, Bangalore",
                               "Operating_Status": "A",
                               "Address1": "#34, Lake City Township, TC Palya Bus Stop,",
                               "Address2": "Garden City College Road,",
                               "Place": "KR Puram, Near Royal Mart TC Palya,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2518@kidzee.com",
                               "Mobile_No": "8105121835",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "13.01761361072635",
                               "Longitude": "77.70180143415928",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2518/10440/78c06569_35ec_4e2f_8369_9ec84396051d.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-R-S-2383",
                               "Franchisee_Name": "Kidzee Jalahalli east, MS Palya Bangalore",
                               "Operating_Status": "A",
                               "Address1": "Near Sambhram College,",
                               "Address2": "Jalahalli east",
                               "Place": "MS Palya, Near Sambhram College",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2383@kidzee.com",
                               "Mobile_No": "9686331913",
                               "Phone_No1": "8073830808",
                               "Phone_No2": "",
                               "Latitude": "13.088382",
                               "Longitude": "77.54675",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/780277/10476/fa3b4824_78ae_4ccb_8bd2_1b61eca5b12a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/780277/10476/14d58400_16cf_4da4_b1f4_0744a62d7333.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-2430",
                               "Franchisee_Name": "Kidzee B Narayanpura, Bangalore",
                               "Operating_Status": "A",
                               "Address1": "#32/33, 1st Main Road,",
                               "Address2": "8th Cross,  Anugrahar Layout, Akash Nagar,",
                               "Place": "B-Narayanpura, Near Pragathi Club,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2430@kidzee.com",
                               "Mobile_No": "9945569650",
                               "Phone_No1": "9945569650",
                               "Phone_No2": "",
                               "Latitude": "12.988084",
                               "Longitude": "77.674483"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3140",
                               "Franchisee_Name": "Kidzee 8th mile- Hessaraghatta  Road",
                               "Operating_Status": "A",
                               "Address1": "No.46/1, Mudalamane, Hessaraghatta Main Road,",
                               "Address2": "Bhuvaneshwari Nagar, 1st Main, 2nd cross, T. Dasarahalli, BBMP Ward No-15,",
                               "Place": "8th Mile (Hessaraghatta Road)",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3140@kidzee.com",
                               "Mobile_No": "7348923505",
                               "Phone_No1": "8884144899",
                               "Phone_No2": "",
                               "Latitude": "13.0350501896835",
                               "Longitude": "77.4979931116104"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3305",
                               "Franchisee_Name": "Kidzee Spencer Road",
                               "Operating_Status": "A",
                               "Address1": "#32/4, Old 32/1,",
                               "Address2": "Pulikeshi Nagar, Fraser Town,Muncipal Ward No -91,",
                               "Place": "Spencer Road, Seventh Day Hospital,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3305@kidzee.com",
                               "Mobile_No": "7411553131",
                               "Phone_No1": "7411553131",
                               "Phone_No2": "",
                               "Latitude": "12.9956478223932",
                               "Longitude": "77.6100504398346",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/527a3cdf_5eab_4b64_9617_4bfda6bfd5f7.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/d5695ffc_09e2_48e0_80ef_585c14c2b79e.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/21af48f4_133d_4a0e_abc1_643add0d487f.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/19cf08c4_2e40_43f2_a276_9f2193c0c55f.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/6fed764a_cb29_4b24_9b82_599d86b38617.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/8f933ae2_944d_43c6_947c_5abb55678d24.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/4d5c9507_70f7_4c38_8aa4_306203df9e2c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/8bb7c40b_6844_4000_a9d2_6ead70c6d3dd.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/3e5e82c4_20ea_47c2_89a3_06ff1433d8e9.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/f2d14013_e0bf_4457_a013_32b363456abc.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/3acfd164_8aa7_4c5c_bc8c_e94cb93a213c.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/57f94582_82e4_48d3_bc24_5847ab77f1ad.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/792a6489_cfc8_4fe6_8cfb_0cbfdd235c3a.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/906b439d_ef96_4fdc_9d23_5f529ff4ee70.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/1bc9ce78_6ede_42d4_b921_0f8507ddf63e.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3305/308/f22f77c4_e416_41cf_9921_6765340c6f20.JPG"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-3316",
                               "Franchisee_Name": "Kidzee Ramamurthynagar.",
                               "Operating_Status": "A",
                               "Address1": "No.1,Dwaraka,1st Cross,",
                               "Address2": "Reddy Layout,",
                               "Place": "Near-Gopal Dev Chowk,T C Palya Road,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3316@kidzee.com",
                               "Mobile_No": "9880022522",
                               "Phone_No1": "9880022522",
                               "Phone_No2": "",
                               "Latitude": "13.016459936989575",
                               "Longitude": "77.66942918300629",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10882/703bd544_b387_40d7_93ed_9aeebb5727ca.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10882/19b15cfd_d6f8_4dd0_b2e7_4d38b3dfe598.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10882/b3382aab_5ba1_4187_bae8_1843bfe18db1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10883/77bd4174_e9dc_4072_9c72_fc57d05db36a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10883/0a35ef03_1550_4652_96f3_2c43fdd31244.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10883/585fe393_5a14_473f_8f10_47073f1199ed.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10889/9575f7fa_2b8b_45a6_95e6_b9d78935c65c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10889/24ada733_041e_413e_bbc3_f3c4cb030a7b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10889/555c5e8c_22e3_4e78_882d_e5bf91411ba7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10889/c659ead6_7918_433b_b94a_02f9f45bc242.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10893/4cbcd484_b2ad_4f9f_b359_3add45471049.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10903/bd6713fb_0d53_4028_b492_95ecb5d62078.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10903/6b50f7f1_c76b_4be1_9cfd_53b7510f057b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10882/314a1d5c_5e57_4535_83ee_463a4f5a98d8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10882/386736ee_fb9c_48bf_bace_0e7571988861.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10903/691ee44d_587d_4cea_8beb_4dba96849a1c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/11069/035d86f8_e6a3_4483_b3b6_46d9b0b6cabe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/11069/1199556f_200b_4acf_a85f_f105b61fcabd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10882/da644acf_84b3_4526_9155_0bcf5ccf0b1d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1503862/10893/dfb0ffb2_c13e_4396_993a_2970049b21da.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-3062",
                               "Franchisee_Name": "Kidzee  Kogilu Yalahanka",
                               "Operating_Status": "A",
                               "Address1": "No.15/4 Maruthi Nagar,",
                               "Address2": "Balaji Layout",
                               "Place": "Kogilu Yalahanka ,maruthi nagar",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3062@kidzee.com",
                               "Mobile_No": "9901067223",
                               "Phone_No1": "9901067223",
                               "Phone_No2": "",
                               "Latitude": "13.103516",
                               "Longitude": "77.616281"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3139",
                               "Franchisee_Name": "Kidzee Seegehalli  Kadugodi",
                               "Operating_Status": "A",
                               "Address1": "H. No 226, Behind Venugopala Swamy Temple,",
                               "Address2": "Seegehalli Village, Bidarahalli",
                               "Place": "Seegehalli-Kadugodi",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3139@kidzee.com",
                               "Mobile_No": "8792538745",
                               "Phone_No1": "8792538745",
                               "Phone_No2": "",
                               "Latitude": "12.9905242837932",
                               "Longitude": "77.7602112293243"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3324",
                               "Franchisee_Name": "Kidzee Basapura",
                               "Operating_Status": "A",
                               "Address1": "No.23, 1st Main Road, Maruti Layout,",
                               "Address2": "Hosa Road Signal, Electronic City,",
                               "Place": "Basapura,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3324@kidzee.com",
                               "Mobile_No": "9845232799",
                               "Phone_No1": "8880579579",
                               "Phone_No2": "",
                               "Latitude": "12.866352",
                               "Longitude": "77.645731",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3324/117/89f09c24_2d2d_4fad_80da_a73612fd150b.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-S-S-1632",
                               "Franchisee_Name": "Kidzee Yelahanka new Town",
                               "Operating_Status": "A",
                               "Address1": "NO 338/14, Sri Nilayam,",
                               "Address2": "3rd , A main 4th cross,",
                               "Place": "Yelahanka new Town,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1632@kidzee.com",
                               "Mobile_No": "9945381816",
                               "Phone_No1": "9945381816",
                               "Phone_No2": "",
                               "Latitude": "13.05362",
                               "Longitude": "77.36065"
                            },
                            {
                               "Franchisee_Code": "S-S-S-1633",
                               "Franchisee_Name": "Kidzee Kannehalli-Sarjapur Road.",
                               "Operating_Status": "A",
                               "Address1": "Brindavanam #18 AET college road,",
                               "Address2": "DoddaKannelli Carmelram Post",
                               "Place": "DoddaKannelli ,AET College Road",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1633@kidzee.com",
                               "Mobile_No": "9980444424",
                               "Phone_No1": "9980-444424",
                               "Phone_No2": "",
                               "Latitude": "12.916690605154166",
                               "Longitude": "77.69942164421082",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/483910/10469/c9061e8f_dde0_4632_be4c_b0b90fa9d87d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/483910/10469/d58c4fcd_98e3_4bd6_8d4e_7b3b44ab1e6b.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-S-S-1638",
                               "Franchisee_Name": "Kidzee-HSR Layout",
                               "Operating_Status": "A",
                               "Address1": "House at No - 493, 16th B Cross,",
                               "Address2": "",
                               "Place": "HSR Layout Sector - 6 ",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1638@kidzee.com",
                               "Mobile_No": "9980761521",
                               "Phone_No1": "9980761521",
                               "Phone_No2": "",
                               "Latitude": "12.913771",
                               "Longitude": "77.62927"
                            },
                            {
                               "Franchisee_Code": "S-S-S-1675",
                               "Franchisee_Name": "Kidzee Arekere",
                               "Operating_Status": "A",
                               "Address1": "98, Royal Residency Lyt,",
                               "Address2": "Bannerghatta Road,",
                               "Place": "Arekere, Shirdi Sai Baba Temple, DLF Newtown Road,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1675@kidzee.com",
                               "Mobile_No": "9538656669",
                               "Phone_No1": "9538656669",
                               "Phone_No2": "",
                               "Latitude": "12.874925",
                               "Longitude": "77.616032",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1675/50/0e37987f_e968_4ccf_bbee_351ce347eed2.JPG"
                               }
                            },
                            {
                               "Franchisee_Code": "S-S-S-1727",
                               "Franchisee_Name": "Kidzee-Vijayanagar-Banglore",
                               "Operating_Status": "A",
                               "Address1": "No.703/10,",
                               "Address2": "11th Cross,",
                               "Place": "M.C.Layout, Vijayanagar,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1727@kidzee.com",
                               "Mobile_No": "9900847100",
                               "Phone_No1": "9986674429",
                               "Phone_No2": "",
                               "Latitude": "12.975672",
                               "Longitude": "77.541574"
                            },
                            {
                               "Franchisee_Code": "S-S-S-1764",
                               "Franchisee_Name": "Kidzee-S.G.Palya-Bangalore",
                               "Operating_Status": "A",
                               "Address1": "No.16, Swagath, 4th Cross, 2nd Main,",
                               "Address2": "New-Seenappa Layout, S.G.Palya,",
                               "Place": "C.V. Raman Nagar, Near-Baghmane Tech Park,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1764@kidzee.com",
                               "Mobile_No": "9886363660",
                               "Phone_No1": "08042024982",
                               "Phone_No2": "",
                               "Latitude": "12.982811",
                               "Longitude": "77.655044",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/556847/11038/e9d21ddf_6fde_4b59_aad3_1c56c126568a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/556847/11038/1167784f_e1bf_4093_8ebc_23bfabf53148.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-S-S-1849",
                               "Franchisee_Name": "Kidzee Laggere",
                               "Operating_Status": "A",
                               "Address1": "441, Kempegowda Layout,",
                               "Address2": "",
                               "Place": "Laggere, Near-Sai Baba Temple,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1849@kidzee.com",
                               "Mobile_No": "9972424242",
                               "Phone_No1": "9972223344",
                               "Phone_No2": "",
                               "Latitude": "13.009765",
                               "Longitude": "77.525396"
                            },
                            {
                               "Franchisee_Code": "S-R-S-1858",
                               "Franchisee_Name": "Kidzee-Hennur",
                               "Operating_Status": "A",
                               "Address1": "2014,",
                               "Address2": "K. Narayanpuram, ",
                               "Place": "Hennur, Opp.Kristu Jayanthi College,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1858@kidzee.com",
                               "Mobile_No": "9845861501",
                               "Phone_No1": "9036011175",
                               "Phone_No2": "",
                               "Latitude": "13.057211915257016",
                               "Longitude": "77.64487079629521"
                            },
                            {
                               "Franchisee_Code": "S-R-S-1864",
                               "Franchisee_Name": "Kidzee-Banshankari",
                               "Operating_Status": "A",
                               "Address1": "687, 50 ft road, Kathriguppe",
                               "Address2": "Banashankari 3rd Stage, Near-Kathriguppe Water tank and SBI",
                               "Place": "Kathriguppe",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1864@kidzee.com",
                               "Mobile_No": "0804115892",
                               "Phone_No1": "6362847699",
                               "Phone_No2": "",
                               "Latitude": "12.931783",
                               "Longitude": "77.54778"
                            },
                            {
                               "Franchisee_Code": "S-R-S-1866",
                               "Franchisee_Name": "Kidzee-HAL Airport-Bangalore",
                               "Operating_Status": "A",
                               "Address1": "Unit-11, No-19, 1st Main, Vinayak Nagar, B-Block,",
                               "Address2": "Konena Agrahara,",
                               "Place": "HAL Airport Road, Near- Ganesha Temple,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1866@kidzee.com",
                               "Mobile_No": "9845493818",
                               "Phone_No1": "08025230026",
                               "Phone_No2": "",
                               "Latitude": "12.955448",
                               "Longitude": "77.659299"
                            },
                            {
                               "Franchisee_Code": "S-R-S-1873",
                               "Franchisee_Name": "Kidzee-MCECH Layout",
                               "Operating_Status": "A",
                               "Address1": "No. 529, 11th Cross,",
                               "Address2": " MCECHS Layout, Dr. Shivaram Karanth Nagar, ",
                               "Place": "Jakkur, Behind-Corporation Bank,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1873@kidzee.com",
                               "Mobile_No": "9886173590",
                               "Phone_No1": "990-1568003",
                               "Phone_No2": "",
                               "Latitude": "13.073534",
                               "Longitude": "77.629622",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1873/20/a22e074a_fd1f_41d0_8693_6d3c7d5ff4b7.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1873/23/c470891a_66b0_49da_96e0_c8eaec9ad51a.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1873/23/f91fd82e_7a39_492c_9b95_b14840c8756d.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1873/23/eaf39e44_5898_4835_b74c_c50e74300411.JPG"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-1901",
                               "Franchisee_Name": "Kidzee-Surya City, Bangalore",
                               "Operating_Status": "A",
                               "Address1": "HIG-72",
                               "Address2": "5th A Cross, Surya City 1st Phase",
                               "Place": "Chandapura, Anekal Taluk",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1901@kidzee.com",
                               "Mobile_No": "9739166300",
                               "Phone_No1": "9845773513",
                               "Phone_No2": "",
                               "Latitude": "12.793063223098653",
                               "Longitude": "77.70304292590333"
                            },
                            {
                               "Franchisee_Code": "S-R-S-1912",
                               "Franchisee_Name": "Kidzee-Kasavanahalli",
                               "Operating_Status": "A",
                               "Address1": "A 1, Owner Court East,",
                               "Address2": "Off-Sarjapur Road,",
                               "Place": "Kasavanahalli, Near Shell Petrol Pump,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1912@kidzee.com",
                               "Mobile_No": "9611058682",
                               "Phone_No1": "8884750750",
                               "Phone_No2": "",
                               "Latitude": "12.908558",
                               "Longitude": "77.675445"
                            },
                            {
                               "Franchisee_Code": "S-R-S-1913",
                               "Franchisee_Name": "Kidzee-Basaveshwar Nagar",
                               "Operating_Status": "A",
                               "Address1": "#737, 9th Main, 3rd Block, 3rd Cross,",
                               "Address2": "Opposite- Panacea Hospital,",
                               "Place": "Basaweshwarnagar, ",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1913@kidzee.com",
                               "Mobile_No": "9663666655",
                               "Phone_No1": "7204555559",
                               "Phone_No2": "",
                               "Latitude": "12.987692",
                               "Longitude": "77.54367"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2759",
                               "Franchisee_Name": "Thanisandra Bengaluru Kidzee",
                               "Operating_Status": "A",
                               "Address1": "No-14,Kath No-775 New Katha No: 2296/1, Sadiq Layout (Ashwathnagar),",
                               "Address2": "Near-Anna Enclave, Sarayi Playa,  Arabic College Post, K.R.Puram,",
                               "Place": "Thanisandra, Bengaluru, Narayana Swamy Road,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2759@kidzee.com",
                               "Mobile_No": "9845995696",
                               "Phone_No1": "8892274167",
                               "Phone_No2": "",
                               "Latitude": "13.050370",
                               "Longitude": "77.634401"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2823",
                               "Franchisee_Name": "Kidzee Hosakote Bangalore",
                               "Operating_Status": "A",
                               "Address1": "Manashu, V V Extension,7th Cross,",
                               "Address2": "",
                               "Place": "Hosakote, Behind-PU Govt College,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2823@kidzee.com",
                               "Mobile_No": "9900128843",
                               "Phone_No1": "9900128843",
                               "Phone_No2": "",
                               "Latitude": "13.023722",
                               "Longitude": "77.55372"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2919",
                               "Franchisee_Name": "Kidzee Attibele Bangalore",
                               "Operating_Status": "A",
                               "Address1": "No.35, Behind Corporation Bank,",
                               "Address2": "Jayanna Layout,",
                               "Place": "Attibele ",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2919@kidzee.com",
                               "Mobile_No": "9980054178",
                               "Phone_No1": "9972581766",
                               "Phone_No2": "",
                               "Latitude": "12.923289",
                               "Longitude": "77.51142"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2936",
                               "Franchisee_Name": "Kidzee Gangamma circle, Jalahalli",
                               "Operating_Status": "A",
                               "Address1": "#35, Gangamma Circle,",
                               "Address2": "Jalahalli,",
                               "Place": "Gangamma Circle, Behind St. Mary's Orthodox Church",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2936@kidzee.com",
                               "Mobile_No": "9743769381",
                               "Phone_No1": "08023453085",
                               "Phone_No2": "",
                               "Latitude": "13.057118",
                               "Longitude": "77.548002"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2988",
                               "Franchisee_Name": "Kidzee Girinagar",
                               "Operating_Status": "A",
                               "Address1": "No-176, Honnu,",
                               "Address2": "50th Road, Avalahalli, BSK 3rd Stage,",
                               "Place": "Girinagar, Opp to Corporation Bank,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2988@kidzee.com",
                               "Mobile_No": "9036645264",
                               "Phone_No1": "9036645264",
                               "Phone_No2": "",
                               "Latitude": "12.943329283335508",
                               "Longitude": "77.54356229563143",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1243016/10836/b2fdadb1_b19d_45ee_9657_939fe69ae956.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1243016/10836/4d31dee2_6c92_415c_94d1_ee5c57aad708.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1243016/10836/48c7e5a4_f856_4a84_9e91_fbd7a634fb69.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1243016/10836/468a0212_b8ac_4b3e_a023_6e6656e6c530.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-2991",
                               "Franchisee_Name": "Kidzee Kalkere",
                               "Operating_Status": "A",
                               "Address1": "NO.12/13,6TH MAIN ROAD",
                               "Address2": "PUNYABHOOMI LAYOUT",
                               "Place": "KALKERE POST, HORAMAVU",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2991@kidzee.com",
                               "Mobile_No": "7795131329",
                               "Phone_No1": "7795131329",
                               "Phone_No2": "",
                               "Latitude": "13.024611239991264",
                               "Longitude": "77.68262711114505"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3003",
                               "Franchisee_Name": "KIDZEE BRS EDUCATIONAL & CHARITABLE TRUST KAIKONDRAHALLI",
                               "Operating_Status": "A",
                               "Address1": "BRS Educational and Charitable Trust,",
                               "Address2": "Sy-No.11/2,",
                               "Place": "Kaikondrahalli, Sarjapura Road ,Opp to Fire Statio",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3003@kidzee.com",
                               "Mobile_No": "9945010044",
                               "Phone_No1": "9901901234",
                               "Phone_No2": "",
                               "Latitude": "12.914587",
                               "Longitude": "77.672554",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3003/228/7840f6bd_c605_49ee_9fe4_bbccb34aaa7b.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-R-S-3352",
                               "Franchisee_Name": "Kidzee Mylasandra off Mysore Road",
                               "Operating_Status": "A",
                               "Address1": "107, Jenu Goodu, 9th Cross,",
                               "Address2": "Krishna Gardens, ",
                               "Place": "Bhagegowda Road, Rajarajeshwari Nagar, Near-Global",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3352@kidzee.com",
                               "Mobile_No": "9448072044",
                               "Phone_No1": "8277-168465",
                               "Phone_No2": "",
                               "Latitude": "12.913592503066035",
                               "Longitude": "77.50416219234467",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3352/99/98333753_c2e2_459f_afeb_68fb58246569.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3352/99/9c460154_4013_42af_8af9_3fdc81e2738d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1483619/10801/ae40d3b5_3000_46bf_9056_d3e27ec397c8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1483619/10801/e68e758b_20d2_481f_bdec_a281cad7a868.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-3429",
                               "Franchisee_Name": "Kidzee Yelahanka New Town 4th Phase",
                               "Operating_Status": "A",
                               "Address1": "no, D 1.285, Housing Board colony, ",
                               "Address2": "SFS- 407, 4th Stage ",
                               "Place": "Yelahanka ,Housing Board colony,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3429@kidzee.com",
                               "Mobile_No": "9845556495",
                               "Phone_No1": "9845556495",
                               "Phone_No2": "",
                               "Latitude": "12.962267346499337",
                               "Longitude": "77.59742259979248",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3429/319/86146345_7afb_4180_bc63_d609805945a3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3429/319/57697daa_dcc5_4246_adf6_cded2e3c845f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3429/319/89e7504e_9960_43e4_b95a_ada3852e8ded.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3429/319/6bb47c4c_cb65_4689_b464_903a9358ca11.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3429/319/a76b2eee_c97e_4f9f_90c7_c634256e1f60.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3429/319/3bd71d0d_78cc_4bc4_b2d2_b8af5c55066d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3429/319/94dbd82f_fc54_4c8b_953d_c12b7ed17fef.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3429/319/7c87366e_578f_4b9c_b2bb_4402a6b0d0e8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1598699/10917/ae7a3cf9_a6b7_494f_8a75_83d0351b79f2.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-3548",
                               "Franchisee_Name": "Kidzee Poorna pragna layout",
                               "Operating_Status": "A",
                               "Address1": "1382, 43rd Main,",
                               "Address2": "52nd Cross,",
                               "Place": "Poorna Pragna Layout, Near-Uttaralli Lake",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3548@kidzee.com",
                               "Mobile_No": "9845313735",
                               "Phone_No1": "9845313735",
                               "Phone_No2": "",
                               "Latitude": "12.90871262357779",
                               "Longitude": "77.53994906341552",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1589426/10448/d8396f6e_6169_4975_8402_48bac24825b8.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-A-M-959",
                               "Franchisee_Name": "Kidzee- Rajajinagar- Bangalore",
                               "Operating_Status": "A",
                               "Address1": "#1, FTI Layout, 1st Main Road,",
                               "Address2": "Opp-Water Tank,",
                               "Place": "Nandini Layout, Near-Nandini Layout Bus Stop,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee959@kidzee.com",
                               "Mobile_No": "9448512127",
                               "Phone_No1": "9448512127",
                               "Phone_No2": "",
                               "Latitude": "13.014903866615938",
                               "Longitude": "77.53448818783568"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1004",
                               "Franchisee_Name": "Kidzee-Bagalur Road",
                               "Operating_Status": "A",
                               "Address1": "Kidzee-Bagalur Road, G.L.Rajunagar,",
                               "Address2": "Plot No-179, 6th Main, IAS Post,",
                               "Place": "Near- Old IB Ext. Area, Behind-Andra Bank,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1004@kidzee.com",
                               "Mobile_No": "9611146483",
                               "Phone_No1": "9886333969",
                               "Phone_No2": "",
                               "Latitude": "12.971599",
                               "Longitude": "77.594563"
                            },
                            {
                               "Franchisee_Code": "S-S-C-1952",
                               "Franchisee_Name": "Kidzee Huskur Road Ananthnagar",
                               "Operating_Status": "A",
                               "Address1": "#60/15, Huskur Main Road,",
                               "Address2": "Electronic City Post,",
                               "Place": "Huskur Main Gate,  Ananth Nagar,Near-Fruits Market",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1952@kidzee.com",
                               "Mobile_No": "9740544586",
                               "Phone_No1": "080-990007599",
                               "Phone_No2": "",
                               "Latitude": "12.840042",
                               "Longitude": "77.686468"
                            },
                            {
                               "Franchisee_Code": "S-R-S-1987",
                               "Franchisee_Name": "Kidzee  Banashankari",
                               "Operating_Status": "A",
                               "Address1": "1038, 14th cross,30th Main, Bsk 2nd Stage",
                               "Address2": "Diagonally opposite to Sri Hari Kalyana Mantapa",
                               "Place": "Banashankari",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1987@kidzee.com",
                               "Mobile_No": "8861021021",
                               "Phone_No1": "8123108616",
                               "Phone_No2": "",
                               "Latitude": "12.92880902",
                               "Longitude": "77.56302496"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2012",
                               "Franchisee_Name": "Kidzee-Kadugodi Post, Bangalore",
                               "Operating_Status": "A",
                               "Address1": "Sri Sai Jyothi, Sai Colony,",
                               "Address2": "Belthur Village,",
                               "Place": "Kadugodi Post, Near- Dominos Pizza,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2012@kidzee.com",
                               "Mobile_No": "9980129808",
                               "Phone_No1": "9980129808",
                               "Phone_No2": "",
                               "Latitude": "13.003630000765186",
                               "Longitude": "77.75612354572445"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2037",
                               "Franchisee_Name": "Kidzee- Mico Layout, Arekere",
                               "Operating_Status": "A",
                               "Address1": "House 180, 3rd Cross, ",
                               "Address2": "Panduranga Nagar,",
                               "Place": "Mico Layout, Arekere ,Near L & T South City",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2037@kidzee.com",
                               "Mobile_No": "7032909126",
                               "Phone_No1": "7032909126",
                               "Phone_No2": "",
                               "Latitude": "12.890977194412672",
                               "Longitude": "77.59264331178679"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2766",
                               "Franchisee_Name": "Kidzee- Devanahalli, Bangalore",
                               "Operating_Status": "A",
                               "Address1": "#639, SLN Ashwath Narayan Building,",
                               "Address2": "4th Division, 20th Ward, Sulibele Road,",
                               "Place": "Devanahalli Town, Bangalore",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2766@kidzee.com",
                               "Mobile_No": "9481473525",
                               "Phone_No1": "9481-473525",
                               "Phone_No2": "",
                               "Latitude": "13.25897340725093",
                               "Longitude": "77.71693892198186"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2151",
                               "Franchisee_Name": "Kidzee Basavanagudi Bangalore",
                               "Operating_Status": "A",
                               "Address1": "Ratna, House No.28, New No.9,",
                               "Address2": "Shankar Mutt Road, Shankarapuram,",
                               "Place": "Basavanagudi, Opposite to Srinivasa Scanning Centr",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2151@kidzee.com",
                               "Mobile_No": "9845118552",
                               "Phone_No1": "9972936816",
                               "Phone_No2": "",
                               "Latitude": "12.95082626949329",
                               "Longitude": "77.57002893005938"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2169",
                               "Franchisee_Name": "Kidzee Sompura Gate, Bangalore",
                               "Operating_Status": "A",
                               "Address1": "Survey No 18, Sompura Gate",
                               "Address2": "Off Sarjapur Road, Anekal Taluk",
                               "Place": "Sompura Gate, Near Souparnika ananda apartment",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2169@kidzee.com",
                               "Mobile_No": "8861624705",
                               "Phone_No1": "9886507333",
                               "Phone_No2": "",
                               "Latitude": "12.926123",
                               "Longitude": "77.623106"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2728",
                               "Franchisee_Name": "Kidzee Prashant Layout Banglore",
                               "Operating_Status": "A",
                               "Address1": "#112, 3rd Cross, Prashanth Extension Layout,",
                               "Address2": "Whitefield Main Road,",
                               "Place": "Prashant Layout ITPL, Near-Hope Farm Signal,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2728@kidzee.com",
                               "Mobile_No": "9591688991",
                               "Phone_No1": "9591688991",
                               "Phone_No2": "",
                               "Latitude": "12.983572",
                               "Longitude": "77.74878"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2659",
                               "Franchisee_Name": "Kidzee-AECS Layout,Kudlu.",
                               "Operating_Status": "A",
                               "Address1": "K K Education Trust, #571, A Block,  AECS Layout,",
                               "Address2": "Kudlu Gate, Hosur Road,",
                               "Place": "Kudlu-AECS Layout, Saai Eye Hospital,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2659@kidzee.com",
                               "Mobile_No": "9900467766",
                               "Phone_No1": "9900467766",
                               "Phone_No2": "",
                               "Latitude": "12.885880060994381",
                               "Longitude": "77.6490135732904",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2659/180/3953da77_765f_47a9_934d_aaadd78c4b14.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-R-S-2677",
                               "Franchisee_Name": "Kidzee-Roopena agrahara, Bangalore",
                               "Operating_Status": "A",
                               "Address1": "#8, 4th Main,",
                               "Address2": "Off Hosur Main Road,",
                               "Place": "Roopena Agrahara, Bangalore, Near-Mahindra Car Sho",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2677@kidzee.com",
                               "Mobile_No": "9741006681",
                               "Phone_No1": "08025733474",
                               "Phone_No2": "",
                               "Latitude": "12.911663",
                               "Longitude": "77.622791"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3672",
                               "Franchisee_Name": "Kidzee Ananthnagar phase ll",
                               "Operating_Status": "A",
                               "Address1": "Villa No.3, Deccan Palms Villa,",
                               "Address2": "Electronic City Post,",
                               "Place": "Ananthnagar Phase-2, Inside Deccan Palms Villa,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3672@kidzee.com",
                               "Mobile_No": "9739738234",
                               "Phone_No1": "9739738234",
                               "Phone_No2": "",
                               "Latitude": "12.8351204086579",
                               "Longitude": "77.6962888240814"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3699",
                               "Franchisee_Name": "Kidzee Carmelaram post",
                               "Operating_Status": "A",
                               "Address1": "Plot no.10, Govinda Reddy Layout",
                               "Address2": "Kodathi gate, sarjapur road",
                               "Place": "Carmelaram post ,Near Saint patrick school",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3699@kidzee.com",
                               "Mobile_No": "9902815716",
                               "Phone_No1": "9902815716",
                               "Phone_No2": "",
                               "Latitude": "12.8968260702964",
                               "Longitude": "77.7175211906433"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3777",
                               "Franchisee_Name": "Kidzee Kanakpura Road.",
                               "Operating_Status": "A",
                               "Address1": "No 289, 5th main, BCMC layout",
                               "Address2": "Raghuvanahalli,  ",
                               "Place": "Kanakpura Road,Behind Nitesh Apartments",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3777@kidzee.com",
                               "Mobile_No": "9845741979",
                               "Phone_No1": "7899120008",
                               "Phone_No2": "",
                               "Latitude": "12.8808",
                               "Longitude": "77.5461"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3777",
                               "Franchisee_Name": "Kidzee Kanakpura Road.",
                               "Operating_Status": "A",
                               "Address1": "No 289, 5th main, BCMC layout",
                               "Address2": "Raghuvanahalli,  ",
                               "Place": "Kanakpura Road,Behind Nitesh Apartments",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3777@kidzee.com",
                               "Mobile_No": "9845741979",
                               "Phone_No1": "7899120008",
                               "Phone_No2": "",
                               "Latitude": "12.8808",
                               "Longitude": "77.5461"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3709",
                               "Franchisee_Name": "Kidzee  Devarachikkanahalli Road, Royal Shelters",
                               "Operating_Status": "A",
                               "Address1": "262, Royal Shelters,",
                               "Address2": "Ground Floor,",
                               "Place": "Devarachikkanahalli Road, Royal Shelters",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3709@kidzee.com",
                               "Mobile_No": "9739315401",
                               "Phone_No1": "0973-9315401",
                               "Phone_No2": "",
                               "Latitude": "12.8921198424728",
                               "Longitude": "77.6196366548538"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3509",
                               "Franchisee_Name": "Kidzee Krishnarajapura",
                               "Operating_Status": "A",
                               "Address1": "Limbersoft Technologies  Seegehalli, Property No-31/2,",
                               "Address2": "Krishnarajapura,",
                               "Place": "Kr Puram, Seegehalli, Scaleme Research Centre, See",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3509@kidzee.com",
                               "Mobile_No": "8095446119",
                               "Phone_No1": "8095446119",
                               "Phone_No2": "",
                               "Latitude": "13.015325",
                               "Longitude": "77.713484"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3415",
                               "Franchisee_Name": "Kidzee Narayanaghatta",
                               "Operating_Status": "A",
                               "Address1": "Survey No- 2/2,  Narayanaghatta Village,",
                               "Address2": "Bommasandra Via Muthanallur Post, Sarjapura Hobli,",
                               "Place": "Narayanaghatta, Muthanallur Post,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3415@kidzee.com",
                               "Mobile_No": "9449525457",
                               "Phone_No1": "9449-525457",
                               "Phone_No2": "",
                               "Latitude": "12.837049430523534",
                               "Longitude": "77.72431790828705",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10555/226f85ea_ecca_4963_8a58_59e53f954a29.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10555/0fe3b6cd_6a94_4fb7_b6db_263ac93f2752.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10555/afa05198_fb93_4f27_842d_65587cd96c78.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10555/d443fdbe_dbc8_403e_a54e_c46f01f4de64.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10613/ec9298d9_94ef_44cd_9908_ce8332275c61.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10613/db06a283_0d25_441d_9eb6_120c69d92dc6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10613/d5a6b2de_ba0b_453f_9a3a_f2b400708086.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10613/ee82145a_7787_4cf3_9d9a_d15f92e23d41.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10613/61e7e1f1_2bd1_490e_ac89_4fbc467a58c6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10613/8b9f7895_d231_4649_8674_4547cac8e300.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10613/13233b3e_6dbd_4f4c_9263_940c1a1008a1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10613/041e210d_aef2_4b19_bea1_ad58c6fb4b18.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1906809/10613/967ae9d2_c4aa_4374_bd3f_34ee3d183bce.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-4015",
                               "Franchisee_Name": "Kidzee Annapoorneswari nagar,",
                               "Operating_Status": "A",
                               "Address1": "No. 273, 2nd Main, 3rd Cross,",
                               "Address2": "Nagarbhavi,",
                               "Place": "Near-Annapoorneshwari Temple,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4015@kidzee.com",
                               "Mobile_No": "8884284423",
                               "Phone_No1": "9019888143",
                               "Phone_No2": "",
                               "Latitude": "12.981024",
                               "Longitude": "77.507668"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4019",
                               "Franchisee_Name": "Kidzee Channasandra,Whitefield",
                               "Operating_Status": "A",
                               "Address1": "#43, Near-Government School,",
                               "Address2": "Opposite to Sawaramma Temple,",
                               "Place": "Channasandra, Kadugodi Post ,Besides SLV Ratnagiri",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4019@kidzee.com",
                               "Mobile_No": "9423506982",
                               "Phone_No1": "9108044443",
                               "Phone_No2": "",
                               "Latitude": "12.980240",
                               "Longitude": "77.769027"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4029",
                               "Franchisee_Name": "Kidzee Nagarbhavi 2nd stage",
                               "Operating_Status": "A",
                               "Address1": "#258, 1st Main, 6th Cross,",
                               "Address2": "Vinayaka Layout,",
                               "Place": "Nagarbhavi, 2nd Stage, Near-Hanumagiri Temple,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4029@kidzee.com",
                               "Mobile_No": "7022299995",
                               "Phone_No1": "725-9539999",
                               "Phone_No2": "",
                               "Latitude": "12.965039794962934",
                               "Longitude": "77.51559325119024",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.kidzee.com/images/PhotoGallery/ImageGallery/1943640/10666/80fefc55_277d_48ed_a1d7_ef68707accf3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.kidzee.com/images/PhotoGallery/ImageGallery/1943640/10666/ac25a60d_7532_4d47_ac6e_e893e6229c2a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.kidzee.com/images/PhotoGallery/ImageGallery/1943640/10666/394482c1_6c15_4193_b647_94b8a8486884.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.kidzee.com/images/PhotoGallery/ImageGallery/1943640/10665/8032eb7d_aa5a_4ef5_b859_007b9d0bfbbc.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.kidzee.com/images/PhotoGallery/ImageGallery/1943640/10665/0392cc67_9b6e_43b2_827a_c8216742c6c3.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-3507",
                               "Franchisee_Name": "Kidzee Horamavu agara",
                               "Operating_Status": "A",
                               "Address1": "No-9,CRP Enclave,",
                               "Address2": "Banjara Layout,",
                               "Place": "Horamavu Agara, Opp-Banjara Apartment,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3507@kidzee.com",
                               "Mobile_No": "8861359959",
                               "Phone_No1": "8861359959",
                               "Phone_No2": "",
                               "Latitude": "13.0315090",
                               "Longitude": "77.6626310"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3960",
                               "Franchisee_Name": "Kidzee  Madanayakanahalli",
                               "Operating_Status": "A",
                               "Address1": "M Thimmarasaiah building, Madavara post,",
                               "Address2": "Near Swamy service station, Dasanpura hobli",
                               "Place": "Madanayakanahalli ,Near Petrol Bunk",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3960@kidzee.com",
                               "Mobile_No": "9535568009",
                               "Phone_No1": "080-23713373",
                               "Phone_No2": "",
                               "Latitude": "13.0619948",
                               "Longitude": "77.459313",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1935690/10648/81efc624_cda6_4cbd_ab55_884311a567b8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1935690/10648/dd3af2ef_2ae9_4038_b96e_2c327a6ae2b8.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-4000",
                               "Franchisee_Name": "Kidzee Varthur hobli",
                               "Operating_Status": "A",
                               "Address1": "2/3, opposite Shobbha Dream acres",
                               "Address2": "Panathur,Varthur main road,Balagere,",
                               "Place": "Varthur hobli,Near Star market",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4000@kidzee.com",
                               "Mobile_No": "7337763399",
                               "Phone_No1": "07337-763399",
                               "Phone_No2": "",
                               "Latitude": "12.939783625014991",
                               "Longitude": "77.72778868675232"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4066",
                               "Franchisee_Name": "Kidzee Kasturi nagar",
                               "Operating_Status": "A",
                               "Address1": "# 1-AM-210, 1st, A- Main,",
                               "Address2": "East of NGEF Layout, ",
                               "Place": "Kasturi Nagar, Diagonally Opposite to Airtel offic",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4066@kidzee.com",
                               "Mobile_No": "8884788874",
                               "Phone_No1": "080-48542443",
                               "Phone_No2": "",
                               "Latitude": "13.002734218056414",
                               "Longitude": "77.66024030226902"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4175",
                               "Franchisee_Name": "Kidzee Akshayanagar",
                               "Operating_Status": "A",
                               "Address1": "House No. 2, Sreyas, Opp fullinfaws college",
                               "Address2": "Akshayanagar main road, Yelenahalli",
                               "Place": "Akshayanagar , Begur",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4175@kidzee.com",
                               "Mobile_No": "9916025276",
                               "Phone_No1": "9916025276",
                               "Phone_No2": "",
                               "Latitude": "12.86868345420865",
                               "Longitude": "77.6160829619713"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3879",
                               "Franchisee_Name": "Kidzee Hosa Road",
                               "Operating_Status": "A",
                               "Address1": "No. 54, Near Neo Super market,",
                               "Address2": "Meenakshi Layout , Parappana Agrahara,  Electronic city post. ",
                               "Place": "Hosa road.",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3879@kidzee.com",
                               "Mobile_No": "7899035900",
                               "Phone_No1": "7899035900",
                               "Phone_No2": "",
                               "Latitude": "12.881809299295238",
                               "Longitude": "77.67295814797995"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4272",
                               "Franchisee_Name": "Kidzee Tubarahalli",
                               "Operating_Status": "A",
                               "Address1": "H. No 3, Bright Farm,",
                               "Address2": "Varthur Main Road, Whitefield,",
                               "Place": "Thubarahalli, Near-Cafe Buzzinga,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4272@kidzee.com",
                               "Mobile_No": "7899819878",
                               "Phone_No1": "9632499759",
                               "Phone_No2": "",
                               "Latitude": "12.955577497808942",
                               "Longitude": "77.72335231304169"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4222",
                               "Franchisee_Name": "Kidzee Dommasandra",
                               "Operating_Status": "A",
                               "Address1": "Site No- 07, Khata No.01,",
                               "Address2": "Bidarahalli Hobli,",
                               "Place": "Dommasandra, Near-Sai Apartment",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4222@kidzee.com",
                               "Mobile_No": "7337704272",
                               "Phone_No1": "7022028811",
                               "Phone_No2": "",
                               "Latitude": "13.0105990",
                               "Longitude": "77.7372940"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3909",
                               "Franchisee_Name": "Kidzee Dasanapur",
                               "Operating_Status": "A",
                               "Address1": "#26, Behind St. Mary English school,",
                               "Address2": "Devannapalya",
                               "Place": "Dasanpura ARASINAKUNTE ,Behind St. Mary English sc",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3909@kidzee.com",
                               "Mobile_No": "9343551111",
                               "Phone_No1": "080-27728388",
                               "Phone_No2": "",
                               "Latitude": "13.076084149777015",
                               "Longitude": "77.42775707133819",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2171017/10848/c92f2173_ce07_4ba5_8fb8_f1610d593f4b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2171017/10847/e4f97c2e_6014_4c6e_84d2_69fb12177229.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2171017/10849/a24e11be_6eac_4b48_947f_03b24a9252e3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2171017/10850/86e1c9e3_6acc_4587_a70c_9a8db73bc3a9.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-4434",
                               "Franchisee_Name": "Kidzee Vignan Nagar",
                               "Operating_Status": "A",
                               "Address1": "43-44, 1st Main 3rd Cross Rd, ..",
                               "Address2": "LBS Nagar, Vimanapura, ",
                               "Place": "Vignan nagar",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4434@kidzee.com",
                               "Mobile_No": "8884637215",
                               "Phone_No1": "8884637215",
                               "Phone_No2": "",
                               "Latitude": "12.971576",
                               "Longitude": "77.679195"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4473",
                               "Franchisee_Name": "Kidzee Bel Layout",
                               "Operating_Status": "A",
                               "Address1": "BEL Layout,",
                               "Address2": "Bharath Nagar, Phase-1, 8th Block, Bedarahalli,",
                               "Place": "Bel Layout, Near East West Engineering College,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4473@kidzee.com",
                               "Mobile_No": "9538316555",
                               "Phone_No1": "9538316555",
                               "Phone_No2": "",
                               "Latitude": "12.980996666666666",
                               "Longitude": "77.48561"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4477",
                               "Franchisee_Name": "Kidzee Isro Layout South",
                               "Operating_Status": "A",
                               "Address1": "1587, Eight Cross First Stage",
                               "Address2": "Eight Cross, First Stage",
                               "Place": "Kumaraswamy Layout, Bangalore",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4477@kidzee.com",
                               "Mobile_No": "9449659532",
                               "Phone_No1": "9449659532",
                               "Phone_No2": "",
                               "Latitude": "12.9013",
                               "Longitude": "77.5580"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4433",
                               "Franchisee_Name": "Kidzee Rajaji Nagar",
                               "Operating_Status": "A",
                               "Address1": "Premises No.1086/4,",
                               "Address2": "18th Main Road, 5th Block,",
                               "Place": "Rajaji Nagar, Behind Sidvin Hospital,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4433@kidzee.com",
                               "Mobile_No": "9686688336",
                               "Phone_No1": "9686688336",
                               "Phone_No2": "",
                               "Latitude": "12.9825266",
                               "Longitude": "77.5496619"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4445",
                               "Franchisee_Name": "Kidzee Nagasandra-Shikshana Trust",
                               "Operating_Status": "A",
                               "Address1": "No-71, Pushpagiri Nilaya,",
                               "Address2": "Budhajyothi Layout, Chikkabidarakallu, post-",
                               "Place": "Nagasandra, Near Prestige Jindal City,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4445@kidzee.com",
                               "Mobile_No": "9811148607",
                               "Phone_No1": "1234-123445",
                               "Phone_No2": "",
                               "Latitude": "13.054836",
                               "Longitude": "77.491433",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2214376/10858/6d03de06_b7be_4e48_a59d_6056678fe2d3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2214376/10858/5a40628b_e201_4c0f_bef1_c11fc029ae68.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-4325",
                               "Franchisee_Name": "Kidzee Abbigere",
                               "Operating_Status": "A",
                               "Address1": "Site No.05, Sy No. 23/2, 1st, Cross,",
                               "Address2": "Lakshmaiah Layout, Chikkabanavara-Post,",
                               "Place": "Abbigere Bus Stop,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4325@kidzee.com",
                               "Mobile_No": "9538334555",
                               "Phone_No1": "9538334555",
                               "Phone_No2": "",
                               "Latitude": "13.0734989",
                               "Longitude": "77.520584"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4641",
                               "Franchisee_Name": "Kidzee Hado Siddapura",
                               "Operating_Status": "A",
                               "Address1": "Plot No-7 & 8, Ground and First Floor, Thomas layout,",
                               "Address2": "Carmelaram, Sarjapur Road,",
                               "Place": "Hado Siddapura, Behind RGA Tech Park",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4641@kidzee.com",
                               "Mobile_No": "9491312446",
                               "Phone_No1": "9491312446",
                               "Phone_No2": "",
                               "Latitude": "12.904040",
                               "Longitude": "77.702960"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4626",
                               "Franchisee_Name": "Kidzee ITI Layout",
                               "Operating_Status": "A",
                               "Address1": "721,",
                               "Address2": "2nd Main Road,",
                               "Place": "ITI Layout, Next to Mangammatalya, Next to Mangamm",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4626@kidzee.com",
                               "Mobile_No": "7760088366",
                               "Phone_No1": "7760088366",
                               "Phone_No2": "",
                               "Latitude": "12.9006322",
                               "Longitude": "77.6396721"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4783",
                               "Franchisee_Name": "Kidzee J P Nagar 2nd Phase",
                               "Operating_Status": "A",
                               "Address1": "#667, 14th cross, ",
                               "Address2": "I4th Main,",
                               "Place": "J.P. Nagar 2nd Phase, Beside V.E.T college",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4783@kidzee.com",
                               "Mobile_No": "9986370123",
                               "Phone_No1": "9900417663 ",
                               "Phone_No2": "",
                               "Latitude": "12.906920",
                               "Longitude": "77.593126"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4664",
                               "Franchisee_Name": "Kidzee Doddanagamangala",
                               "Operating_Status": "A",
                               "Address1": "No,281, Doddanamangala, Near-Bhararti Food Flour Mill,",
                               "Address2": "Begur Hobli, Electronic City Post, Phase-2,",
                               "Place": "Doddanamangala, Near-Mahindra",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4664@kidzee.com",
                               "Mobile_No": "7338115548",
                               "Phone_No1": "7338115548",
                               "Phone_No2": "",
                               "Latitude": "12.862923",
                               "Longitude": "77.673409"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4293",
                               "Franchisee_Name": "KidzeeDoddanakundi Extension",
                               "Operating_Status": "A",
                               "Address1": "No 3,8th main road,Nandeeswara Nilaya",
                               "Address2": "Vinayak Layout, opp to Indira Canteen",
                               "Place": "Doddanakundi Extension ,Opposite Indira Canteen",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4293@kidzee.com",
                               "Mobile_No": "9840698071",
                               "Phone_No1": "9840-698071",
                               "Phone_No2": "",
                               "Latitude": "13.041491",
                               "Longitude": "77.417836"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4748",
                               "Franchisee_Name": "Kidzee Lingadirahalli",
                               "Operating_Status": "A",
                               "Address1": "#11, 01st, Cross,",
                               "Address2": "Andrahalli Main Road,",
                               "Place": "Lingadheerahalli, Near-Andhra Bank",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4748@kidzee.com",
                               "Mobile_No": "8618570853",
                               "Phone_No1": "8553-368779",
                               "Phone_No2": "",
                               "Latitude": "12.989691196025428",
                               "Longitude": "77.49876158691404"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5093",
                               "Franchisee_Name": "Kidzee Cholanayakanahalli",
                               "Operating_Status": "A",
                               "Address1": "No-32 2nd cross",
                               "Address2": "Amarajothi Nagar RT Nagar, Hebbal",
                               "Place": "Chalonayakanahalli ,Amar jyothi layout, Chola nagar",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5093@kidzee.com",
                               "Mobile_No": "7406555542",
                               "Phone_No1": "7406555542",
                               "Phone_No2": "",
                               "Latitude": "13.034195",
                               "Longitude": "77.594605"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5203",
                               "Franchisee_Name": "Kidzee Margondanahalli.",
                               "Operating_Status": "A",
                               "Address1": "House no.3 ",
                               "Address2": "Kithaganur Main Road, Margondanahalli, K R Puram Post Bangalore",
                               "Place": "Margondanahalli, Kithaganur Main Road",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5203@kidzee.com",
                               "Mobile_No": "9902919280",
                               "Phone_No1": "9902069902",
                               "Phone_No2": "",
                               "Latitude": "13.036809346234335",
                               "Longitude": "77.69389437301635"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5101",
                               "Franchisee_Name": "Kidzee Vijayanagara - Whitefield",
                               "Operating_Status": "A",
                               "Address1": "143, 6th cross, near shani temple",
                               "Address2": "Vijayanagara,",
                               "Place": "Vijayanagara - Whitefield ,Besides Nagaraj Layout",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5101@kidzee.com",
                               "Mobile_No": "6364118686",
                               "Phone_No1": "9620555612",
                               "Phone_No2": "",
                               "Latitude": "12.976290",
                               "Longitude": "77.756961"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3978",
                               "Franchisee_Name": "Kidzee Yalahanka",
                               "Operating_Status": "A",
                               "Address1": "No.79, 12th cross, Duo marvel layout,",
                               "Address2": "Near Ramanashree california resort",
                               "Place": "Yalahanka",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3978@kidzee.com",
                               "Mobile_No": "8884438833",
                               "Phone_No1": "8884438833.",
                               "Phone_No2": "",
                               "Latitude": "13.118114",
                               "Longitude": "77.569997"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4121",
                               "Franchisee_Name": "Kidzee Lakshmipura Cross",
                               "Operating_Status": "A",
                               "Address1": "No.19/11, Bethel, Shantiniketan Layout,",
                               "Address2": "Vidhyaranyapura Post,",
                               "Place": "Lakshmipura Cross, Near-Lakshmipura Bus Stop,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4121@kidzee.com",
                               "Mobile_No": "9902225940",
                               "Phone_No1": "080-111111",
                               "Phone_No2": "",
                               "Latitude": "13.093286471295766",
                               "Longitude": "77.53218283311033",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2070002/10872/90832612_da7d_45ac_adac_fed02db9005a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2070002/10872/85d6317d_99d8_40b9_b7da_5d92ea6a2f4f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2070002/10872/08ecad26_b0c2_420d_a44d_18dae73e1777.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2070002/10872/2dd9ac92_d004_4c60_b3cd_562b2aa2d020.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2070002/10872/20841f81_c4ee_41f8_9957_6beca8bc2604.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2070002/10872/6a645b4c_7450_4e22_92d0_73f857f62eea.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-4572",
                               "Franchisee_Name": "Kidzee Yamlur",
                               "Operating_Status": "A",
                               "Address1": "Board No-409,",
                               "Address2": "Leela Nanda Nilaya,",
                               "Place": "Yemalur, Near-Prestige New-Garden,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4572@kidzee.com",
                               "Mobile_No": "9845493818",
                               "Phone_No1": "080-45493818",
                               "Phone_No2": "",
                               "Latitude": "12.947668799999999",
                               "Longitude": "77.68019129999999"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4634",
                               "Franchisee_Name": "Kidzee Koramangala",
                               "Operating_Status": "A",
                               "Address1": "No-655, 2nd, C-Main, 2nd, B- Cross,",
                               "Address2": "8th, Block,",
                               "Place": "Koramangala, Near-Udupi Fast Food",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4634@kidzee.com",
                               "Mobile_No": "9845001247",
                               "Phone_No1": "9749780172",
                               "Phone_No2": "",
                               "Latitude": "12.94341",
                               "Longitude": "77.61620"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4853",
                               "Franchisee_Name": "Kidzee Kithiganur Main Road",
                               "Operating_Status": "A",
                               "Address1": "146-147,Padmavathy Nagar",
                               "Address2": "Near Garden City College",
                               "Place": "Kithiganur Main Road ,Near Garden City College",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4853@kidzee.com",
                               "Mobile_No": "6366707999",
                               "Phone_No1": "9901403183",
                               "Phone_No2": "",
                               "Latitude": "13.03036",
                               "Longitude": "77.709585"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4861",
                               "Franchisee_Name": "Bharathi Education Trust(R)",
                               "Operating_Status": "A",
                               "Address1": "Bharathi Education Trust",
                               "Address2": "Mandya dist (K M Doddi),",
                               "Place": "Bharathi nagar ,Bharathi College Campus",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4861@kidzee.com",
                               "Mobile_No": "7022926882",
                               "Phone_No1": "08232-245006",
                               "Phone_No2": "",
                               "Latitude": "12.48271",
                               "Longitude": "77.03324"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4905",
                               "Franchisee_Name": "Kidzee Hesaraghatta main Road",
                               "Operating_Status": "A",
                               "Address1": "Sri Manjunatha, 405, 5th Cross,",
                               "Address2": "3rd main, Kirloskar layout",
                               "Place": "Hessarghatta main road ,Hesaraghatta road",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4905@kidzee.com",
                               "Mobile_No": "9739732933",
                               "Phone_No1": "9739732933",
                               "Phone_No2": "",
                               "Latitude": "13.04675",
                               "Longitude": "77.50879"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4926",
                               "Franchisee_Name": "Kidzee Electronic City Phase-1",
                               "Operating_Status": "A",
                               "Address1": "No-185/151/6, Survey No-151,",
                               "Address2": "Ground Floor, 16A Cross, Neeladri Road, Phase-1,",
                               "Place": "Electronic City Phase-1, Besides Ajmera Infinity,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4926@kidzee.com",
                               "Mobile_No": "9591721830",
                               "Phone_No1": "9591721830",
                               "Phone_No2": "",
                               "Latitude": "12.84252",
                               "Longitude": "77.64591"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4929",
                               "Franchisee_Name": "Geetha M S",
                               "Operating_Status": "A",
                               "Address1": "R /at No.167",
                               "Address2": "Kasthuri Nivasa Road,Basaveshwara Temple SKN post",
                               "Place": "Thanisandra ,Basaveshwara Temple",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4929@kidzee.com",
                               "Mobile_No": "8105883608",
                               "Phone_No1": "8105883608",
                               "Phone_No2": "",
                               "Latitude": "13.05550",
                               "Longitude": "77.63043"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3583",
                               "Franchisee_Name": "Kidzee BTM layout Behind Ganesha temple,",
                               "Operating_Status": "A",
                               "Address1": "No.867, 25th Main, 4th cross",
                               "Address2": "2nd stage, 1st phase",
                               "Place": "Doddakammanahali ,Opposite SLV Heights Apartment",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3583@kidzee.com",
                               "Mobile_No": "9986230007",
                               "Phone_No1": "9886148570",
                               "Phone_No2": "",
                               "Latitude": "12.91243",
                               "Longitude": "77.61334"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5132",
                               "Franchisee_Name": "Kidzee Munnekollal",
                               "Operating_Status": "A",
                               "Address1": "26,27,11th Cross Rd, Rajasree Layout",
                               "Address2": "Gandhi Nagar,  ",
                               "Place": "Munnekollal, Ekam Sports",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee5132@kidzee.com",
                               "Mobile_No": "9019888143",
                               "Phone_No1": "9019888143",
                               "Phone_No2": "",
                               "Latitude": "12.95311",
                               "Longitude": "77.70704"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5168",
                               "Franchisee_Name": "Kidzee Channenahalli",
                               "Operating_Status": "A",
                               "Address1": "S.No 18/2  Sri Chowdeshwari Layout",
                               "Address2": "Jettipalaya,  Kadabagere Post , Bengaluru South Taluka",
                               "Place": "Channenahalli, ,Sri Chowdeshwari Layout,Jettipalay",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5168@kidzee.com",
                               "Mobile_No": "9538133337",
                               "Phone_No1": "9538233337",
                               "Phone_No2": "",
                               "Latitude": "12.97403",
                               "Longitude": "77.43157"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5179",
                               "Franchisee_Name": "Kidzee Kudlu",
                               "Operating_Status": "A",
                               "Address1": "No.10, Jaya Pragathi Layout, ",
                               "Address2": "Behind Vasthu Layout,",
                               "Place": "Kudlu ,Next to White Pearl Apartment",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5179@kidzee.com",
                               "Mobile_No": "8095180399",
                               "Phone_No1": "8095180399",
                               "Phone_No2": "",
                               "Latitude": "12.88669",
                               "Longitude": "77.65766"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4527",
                               "Franchisee_Name": "Kidzee Panathur",
                               "Operating_Status": "A",
                               "Address1": "Flat No-205, Beside K M Manohar Apartment, Chroma Road,",
                               "Address2": "Near-Sriven Appartment,Sy No-79 Croma Railway Station Road,",
                               "Place": "Panathur, Beside K M Manohar Apartment,",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4527@kidzee.com",
                               "Mobile_No": "9740819922",
                               "Phone_No1": "9945006066",
                               "Phone_No2": "",
                               "Latitude": "12.94157",
                               "Longitude": "77.70303"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5522",
                               "Franchisee_Name": "Kidzee Gollahalli",
                               "Operating_Status": "A",
                               "Address1": "Survey No 21/1 4th Cross ",
                               "Address2": "Siraj Layout Shikaripalya Electronic City",
                               "Place": "Gollahalli",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5522@kidzee.com",
                               "Mobile_No": "7709847851",
                               "Phone_No1": "7700-049900",
                               "Phone_No2": "",
                               "Latitude": "12.832210780790975",
                               "Longitude": "77.65999206423442",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2973900/11074/8d21ba22_4455_42ae_a555_d854f69098f6.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-R-S-5673",
                               "Franchisee_Name": "Kidzee Varthur",
                               "Operating_Status": "A",
                               "Address1": "No 96 Church Road",
                               "Address2": "Madhura Nagar",
                               "Place": "2nd Stage Varthur",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5673@kidzee.com",
                               "Mobile_No": "9889883753",
                               "Phone_No1": "9889883753",
                               "Phone_No2": "",
                               "Latitude": "12.940218",
                               "Longitude": "77.760976"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5524",
                               "Franchisee_Name": "Kidzee  Dasarahalli",
                               "Operating_Status": "A",
                               "Address1": "Vishal Public School T Dasarahalli ",
                               "Address2": "3rd cross Muneshwara Block Pipeline Road ",
                               "Place": "T Dasarahalli",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5524@kidzee.com",
                               "Mobile_No": "9980029999",
                               "Phone_No1": "9980029999",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5671",
                               "Franchisee_Name": "Kidzee Padmanabhanagar",
                               "Operating_Status": "A",
                               "Address1": "No 648 Mayur Darshan",
                               "Address2": "18th Main Road",
                               "Place": "Padmanabhanagar",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5671@kidzee.com",
                               "Mobile_No": "9731724169",
                               "Phone_No1": "9731724169",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5659",
                               "Franchisee_Name": "Kidzee Sanjayanagar",
                               "Operating_Status": "A",
                               "Address1": "NO 11 Shiva Enclave Naidu Layout",
                               "Address2": "RMV 2nd Stage",
                               "Place": "Sanjayanagar",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5659@kidzee.com",
                               "Mobile_No": "9880306700",
                               "Phone_No1": "9738007777",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-C-5049",
                               "Franchisee_Name": "Kidzee Vidya Nagar Tiptur",
                               "Operating_Status": "A",
                               "Address1": "Vidya Nagar",
                               "Address2": "Bengaluru-Honnavar Rd,",
                               "Place": "Tiptur ,Opposite Kalpataru Grand Hotel",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5049@kidzee.com",
                               "Mobile_No": "9632408901",
                               "Phone_No1": "9632408901",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5728",
                               "Franchisee_Name": "Kidzee Nagarbhavi",
                               "Operating_Status": "A",
                               "Address1": "No 572 2nd Cross 2nd Stage",
                               "Address2": "A Block RHCS Layout, Annapurneshwari Nagar",
                               "Place": "Nagarbhavi",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5728@kidzee.com",
                               "Mobile_No": "9740201704",
                               "Phone_No1": "9740201704",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5738",
                               "Franchisee_Name": "Kidzee Kothanur",
                               "Operating_Status": "A",
                               "Address1": "No 23, Ground Floor",
                               "Address2": "Byrathi Hoodi",
                               "Place": "Kothanur",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5738@kidzee.com",
                               "Mobile_No": "9620474556",
                               "Phone_No1": "9620474556",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5410",
                               "Franchisee_Name": "Kidzee K R Puram",
                               "Operating_Status": "A",
                               "Address1": "No.22, SBI Colony, Phase 3",
                               "Address2": "T.C. Palya",
                               "Place": "K R Puram",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5410@kidzee.com",
                               "Mobile_No": "8095615656",
                               "Phone_No1": "8095615656",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5126",
                               "Franchisee_Name": "Kidzee Rayasandra Road",
                               "Operating_Status": "A",
                               "Address1": "BM-14 Chikkanagamangala Road",
                               "Address2": "Park way Doddanagamangala",
                               "Place": "Rayasandra Road",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5126@kidzee.com",
                               "Mobile_No": "6366709634",
                               "Phone_No1": "9535228818",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5525",
                               "Franchisee_Name": "Kidzee Coffee Board Layout",
                               "Operating_Status": "A",
                               "Address1": "1st Cross Road Nada Prabhu Kempegowda Main",
                               "Address2": "Sandyagappa Layout ",
                               "Place": "Coffee Board Layout",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5525@kidzee.com",
                               "Mobile_No": "9108481980",
                               "Phone_No1": "9108481980",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5519",
                               "Franchisee_Name": "Kidzee Doddanekudi",
                               "Operating_Status": "A",
                               "Address1": "60 feet road ",
                               "Address2": "Opposite KTM service station",
                               "Place": "Doddanekudi",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5519@kidzee.com",
                               "Mobile_No": "7829781096",
                               "Phone_No1": "7829781096",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5610",
                               "Franchisee_Name": "Kidzee Beneganahalli",
                               "Operating_Status": "A",
                               "Address1": "No.540/541 , Vidyanagar, Old Madras Road",
                               "Address2": "Opposite to Vishal home apartment ,Pai Layout",
                               "Place": "Pai Layout Beneganahalli",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5610@kidzee.com",
                               "Mobile_No": "8904456611",
                               "Phone_No1": "9902210940",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-C-5662",
                               "Franchisee_Name": "Kidzee Anekal Taluk",
                               "Operating_Status": "A",
                               "Address1": "Site No 10/2 Chandapura Anekal road",
                               "Address2": "Near VBHC Apartment Byagadadenahalli Village",
                               "Place": "Anekal Taluk",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5662@kidzee.com",
                               "Mobile_No": "7338531604",
                               "Phone_No1": "9844767160",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5590",
                               "Franchisee_Name": "kidzee Immadahalli",
                               "Operating_Status": "A",
                               "Address1": "Ground, 1st & 2nd Floors near Anganavadi",
                               "Address2": "Immadahalli ",
                               "Place": "Whitefied",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5590@kidzee.com",
                               "Mobile_No": "9739521088",
                               "Phone_No1": "9739521088",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-C-5535",
                               "Franchisee_Name": "Kidzee Arasinakunte",
                               "Operating_Status": "A",
                               "Address1": "Kalp Jain Motor World ",
                               "Address2": "Vijaya Vittala Nagar,Kasba  Hobli, Neelamangala Taluka",
                               "Place": "Arasinakunte",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5535@kidzee.com",
                               "Mobile_No": "7676087676",
                               "Phone_No1": "7676087676",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5596",
                               "Franchisee_Name": "Kidzee Peenya",
                               "Operating_Status": "A",
                               "Address1": "# 26/27 1st Main Rd opp ",
                               "Address2": "Sneha Sambhrama Kalyana Mantap Ganapathy Nagar 3rd Stage ",
                               "Place": "Peenya",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5596@kidzee.com",
                               "Mobile_No": "9845904082",
                               "Phone_No1": "9845904082",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-C-5016",
                               "Franchisee_Name": "Kidzee Nagasandra Post",
                               "Operating_Status": "A",
                               "Address1": "No 1,2, 5th Cross",
                               "Address2": "Defence Colony Hesaraghatta Main road",
                               "Place": "Nagasandra Post",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5016@kidzee.com",
                               "Mobile_No": "7338494409",
                               "Phone_No1": "7338494409",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-5656",
                               "Franchisee_Name": "Kidzee Kalyan Nagar",
                               "Operating_Status": "A",
                               "Address1": "No 819, 3rd Cross",
                               "Address2": "HRBR Layout, 1st Block",
                               "Place": "Kalyan Nagar",
                               "City_Id": "110",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5656@kidzee.com",
                               "Mobile_No": "9019048926",
                               "Phone_No1": "9019048926",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Belgaum",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-5153",
                               "Franchisee_Name": "Kidzee Hindawadi",
                               "Operating_Status": "A",
                               "Address1": "Manick Chaya (Manick Compound)",
                               "Address2": "Maruti Mandir Marg Behind Gomatesh Vidyapeet",
                               "Place": "Hindwadi ,Behind Gomatesh Vidyapeet",
                               "City_Id": "131",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "vijetahattiholi@gmail.com",
                               "Mobile_No": "8971608113",
                               "Phone_No1": "8971608113",
                               "Phone_No2": "",
                               "Latitude": "15.834756",
                               "Longitude": "74.512295"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4441",
                               "Franchisee_Name": "Kidzee Sadashiv Nagar",
                               "Operating_Status": "A",
                               "Address1": "1st Cross,",
                               "Address2": "1st Main Road,",
                               "Place": "Sadashiv Nagar, Lakshmi Complex,",
                               "City_Id": "131",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4441@kidzee.com",
                               "Mobile_No": "9611202970",
                               "Phone_No1": "9632155179",
                               "Phone_No2": "",
                               "Latitude": "15.875415",
                               "Longitude": "74.510332"
                            }
                         ]
                      },
                      {
                         "City_Name": "Bellary"
                      },
                      {
                         "City_Name": "Bidar",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2891",
                            "Franchisee_Name": "Kidzee Bidar Karnataka",
                            "Operating_Status": "A",
                            "Address1": "Ground Floor, ShashiNarayan Nivas,",
                            "Address2": "8-9-407/1, Guru Nanak Colony, Near Chanshetty Hospital",
                            "Place": "Bidar ",
                            "City_Id": "170",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2891@kidzee.com",
                            "Mobile_No": "9538449090",
                            "Phone_No1": "9036573657",
                            "Phone_No2": "",
                            "Latitude": "17.91488",
                            "Longitude": "77.50461"
                         }
                      },
                      {
                         "City_Name": "Bijapur",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-3095",
                            "Franchisee_Name": "Kidzee Bijapur Jalanagar",
                            "Operating_Status": "A",
                            "Address1": "H.No-20, Navraspur Extension Area,",
                            "Address2": "Jalnagar",
                            "Place": "Bijapur Jalnagar, Near Water Tank",
                            "City_Id": "172",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3095@kidzee.com",
                            "Mobile_No": "9164301127",
                            "Phone_No1": "9964301127",
                            "Phone_No2": "",
                            "Latitude": "16.804919",
                            "Longitude": "75.720948"
                         }
                      },
                      {
                         "City_Name": "Koppal"
                      },
                      {
                         "City_Name": "Gadag"
                      },
                      {
                         "City_Name": "Hospet"
                      },
                      {
                         "City_Name": "Hubballi"
                      },
                      {
                         "City_Name": "Khajuri"
                      },
                      {
                         "City_Name": "Mangalore"
                      },
                      {
                         "City_Name": "Mysore",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-1956",
                               "Franchisee_Name": "Kidzee-Kuvempunagar-Mysore",
                               "Operating_Status": "A",
                               "Address1": "House # 844, 12th Main, Saraswathi Puram,",
                               "Address2": "Behind-Bata Showroom, Akshaya Bhandar Circle,",
                               "Place": "Kuvempunagar ,Near Saraswati Park,",
                               "City_Id": "690",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1956@kidzee.com",
                               "Mobile_No": "8884414358",
                               "Phone_No1": "8884-414358",
                               "Phone_No2": "",
                               "Latitude": "12.296786275004955",
                               "Longitude": "76.62833553621101",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/591339/10894/017bd460_78ea_4f57_9c6b_e54318430a6b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/591339/10894/cc8b6405_f336_441d_bb1b_0a3a50341499.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-C-3506",
                               "Franchisee_Name": "Kidzee Vijayanagar General KS Thimmaiah Road",
                               "Operating_Status": "A",
                               "Address1": "No.5662, Basava Belaku,",
                               "Address2": "Gen KS Thimmaiah Road, 1st Stage, Opp to Kodava Samaja Kalyana Mantapa,",
                               "Place": "Vijayanagar Thimmaiah Road,",
                               "City_Id": "690",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3506@kidzee.com",
                               "Mobile_No": "9513056993",
                               "Phone_No1": "0821-2512767",
                               "Phone_No2": "",
                               "Latitude": "12.3323096765368",
                               "Longitude": "76.6081005334854"
                            },
                            {
                               "Franchisee_Code": "S-R-C-3618",
                               "Franchisee_Name": "Kidzee  Vijayanagar 3rd Stage",
                               "Operating_Status": "A",
                               "Address1": "# HIG-229,",
                               "Address2": "",
                               "Place": "Vijayanagar, 3rd Stage, Near Sangam Circle,",
                               "City_Id": "690",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3618@kidzee.com",
                               "Mobile_No": "9986601988",
                               "Phone_No1": "9986601988",
                               "Phone_No2": "",
                               "Latitude": "12.320485553278854",
                               "Longitude": "76.605144739151"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4370",
                               "Franchisee_Name": "Kidzee Vijayanagar 4th Stage Mysore",
                               "Operating_Status": "A",
                               "Address1": "House No 2564, 4Th stage, 2nd Phase",
                               "Address2": "Vijayanagar, near tripura glass ",
                               "Place": "Kidzee Vijayanagar 4th Stage Mysore ,near tripura glass",
                               "City_Id": "690",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4370@kidzee.com",
                               "Mobile_No": "9686316900",
                               "Phone_No1": "9686316900",
                               "Phone_No2": "",
                               "Latitude": "12.3165591",
                               "Longitude": "76.5795273"
                            },
                            {
                               "Franchisee_Code": "A-S-A-1000",
                               "Franchisee_Name": "Ankurum Chamrajnagar",
                               "Operating_Status": "A",
                               "Address1": "MIG 74 ,",
                               "Address2": "HOUSING BOARD COLONY,NEAR PARK",
                               "Place": "CHAMARAJANAGAR ,District - Chamarajanagar",
                               "City_Id": "690",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kannika.rshnik@gmail.com",
                               "Mobile_No": "9972633361",
                               "Phone_No1": "9972633361",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Raichur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-2467",
                               "Franchisee_Name": "Kidzee Raichur",
                               "Operating_Status": "A",
                               "Address1": "H.No.1-12-122/13, 4th CROSS,",
                               "Address2": "(Beside-Dady's Colony) (Landmark-Chaitanya Hospital)",
                               "Place": "PCB Colony Raichur, Near Chaithanya Hospital,",
                               "City_Id": "815",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2467@kidzee.com",
                               "Mobile_No": "9036526590",
                               "Phone_No1": "8549-074619",
                               "Phone_No2": "",
                               "Latitude": "16.251490385693828",
                               "Longitude": "77.36289660742182",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/828128/10430/88506b5b_c94d_402f_b6e9_54fcc0375775.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/828128/10430/748cd561_39c1_416e_a068_5ad409a27c8c.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-C-4118",
                               "Franchisee_Name": "Kidzee Manvi",
                               "Operating_Status": "A",
                               "Address1": "#14-6-577, Opp. APMC Yard, 4th Gate,",
                               "Address2": "Sindhanur Road,",
                               "Place": "Manvi, APMC Yard, 4th Gate,",
                               "City_Id": "815",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4118@kidzee.com",
                               "Mobile_No": "9986462699",
                               "Phone_No1": "9986462699",
                               "Phone_No2": "",
                               "Latitude": "15.9951",
                               "Longitude": "77.04781"
                            }
                         ]
                      },
                      {
                         "City_Name": "Tiptur",
                         "Franchisee": {
                            "Franchisee_Code": "S-G-K-960",
                            "Franchisee_Name": "Kidzee Gramin - Tiptur- Karnataka",
                            "Operating_Status": "A",
                            "Address1": "#229/A, Sri Gangigara Samudaya Bhavan,",
                            "Address2": "K.H.B. Colony Housing Board, 4th Main Road,",
                            "Place": "K.H.B. Colony, Sri Gangigara Samudaya Bhavan,",
                            "City_Id": "958",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "kidzee960@kidzee.com",
                            "Mobile_No": "9886952020",
                            "Phone_No1": "8134251979",
                            "Phone_No2": "",
                            "Latitude": "13.263781",
                            "Longitude": "76.470295"
                         }
                      },
                      {
                         "City_Name": "Tumkur",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4692",
                            "Franchisee_Name": "Kidzee Sira",
                            "Operating_Status": "A",
                            "Address1": "6th Cross, Jyothi Nagara,",
                            "Address2": "Agali Road,",
                            "Place": "Sira,",
                            "City_Id": "966",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4692@kidzee.com",
                            "Mobile_No": "9900128843",
                            "Phone_No1": "9900128843",
                            "Phone_No2": "",
                            "Latitude": "13.75545",
                            "Longitude": "76.89219"
                         }
                      },
                      {
                         "City_Name": "Udupi",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4685",
                            "Franchisee_Name": "Kidzee Manipal",
                            "Operating_Status": "A",
                            "Address1": "No.16-62 (B),",
                            "Address2": "Ananth Nagar,",
                            "Place": "Manipal ,Near-Goswal Ayurvedic Center",
                            "City_Id": "972",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4685@kidzee.com",
                            "Mobile_No": "7411112345",
                            "Phone_No1": "7411112345",
                            "Phone_No2": "",
                            "Latitude": "13.344366",
                            "Longitude": "74.782979"
                         }
                      },
                      {
                         "City_Name": "Siriguppa"
                      },
                      {
                         "City_Name": "Davangere",
                         "Franchisee": {
                            "Franchisee_Code": "S-G-K-1025",
                            "Franchisee_Name": "Kidzee- Davangere",
                            "Operating_Status": "A",
                            "Address1": "B-8,Main Road, Near Vidyanagar,",
                            "Address2": "Davangere,",
                            "Place": "2nd Bus Stop, Vidyanagar,",
                            "City_Id": "1082",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1025@kidzee.com",
                            "Mobile_No": "9886238427",
                            "Phone_No1": "9844600690",
                            "Phone_No2": "",
                            "Latitude": "13.028571",
                            "Longitude": "77.628377"
                         }
                      },
                      {
                         "City_Name": "Shimoga"
                      },
                      {
                         "City_Name": "Nelamangala",
                         "Franchisee": {
                            "Franchisee_Code": "S-S-C-1612",
                            "Franchisee_Name": "Kidzee - Nelamangala",
                            "Operating_Status": "A",
                            "Address1": "No - 743, Lion Bhavan Road,",
                            "Address2": "",
                            "Place": "Subhas nagar ,Opp to Bharath petrol pump",
                            "City_Id": "1106",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1612@kidzee.com",
                            "Mobile_No": "9845762172",
                            "Phone_No1": "9742100497",
                            "Phone_No2": "",
                            "Latitude": "13.096144730918736",
                            "Longitude": "77.40443995233159"
                         }
                      },
                      {
                         "City_Name": "Bagalkot",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-U-C-1006",
                               "Franchisee_Name": "Kidzee-Bagalkot",
                               "Operating_Status": "A",
                               "Address1": "Sirdesai Compound,",
                               "Address2": "Near-Old IB,Extn Area,",
                               "Place": "Bagalkot, Near-St Annes Lions School,",
                               "City_Id": "1112",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1006@kidzee.com",
                               "Mobile_No": "9448368655",
                               "Phone_No1": "8354220550",
                               "Phone_No2": "",
                               "Latitude": "16.172298",
                               "Longitude": "75.655342"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5256",
                               "Franchisee_Name": "Kidzee Vidyagiri",
                               "Operating_Status": "A",
                               "Address1": "Plot No 125/1/A/, 11 cross , Malikarjun ,",
                               "Address2": "Nilaya,",
                               "Place": "Vidyagiri",
                               "City_Id": "1112",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5256@kidzee.com",
                               "Mobile_No": "9986308359",
                               "Phone_No1": "9900924434",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Mandya",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-S-C-1610",
                               "Franchisee_Name": "Kidzee - Mandya",
                               "Operating_Status": "A",
                               "Address1": "No. 1365 / 2, Udayaravi,",
                               "Address2": "12th Cross Chamundeshwari Nagar,",
                               "Place": "Chamundeshwari Nagar ,Near Channel Circle",
                               "City_Id": "1114",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1610@kidzee.com",
                               "Mobile_No": "9844545101",
                               "Phone_No1": "9844545101",
                               "Phone_No2": "",
                               "Latitude": "12.512367480451402",
                               "Longitude": "76.88271372883605"
                            },
                            {
                               "Franchisee_Code": "S-R-C-3109",
                               "Franchisee_Name": "Green Valley education trust",
                               "Operating_Status": "A",
                               "Address1": "Green Valley Education Trust  No - D-1-1013 / 2077,",
                               "Address2": "1st, Cross, Subhash Nagar,",
                               "Place": "Mandya, Triveni Electronics Road",
                               "City_Id": "1114",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3109@kidzee.com",
                               "Mobile_No": "9986041885",
                               "Phone_No1": "9986041885",
                               "Phone_No2": "",
                               "Latitude": "12.527445164162328",
                               "Longitude": "76.88438919441228"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4798",
                               "Franchisee_Name": "Kidzee Mandya",
                               "Operating_Status": "A",
                               "Address1": "1294  2nd cross",
                               "Address2": "Ground Floor",
                               "Place": "Nehru Nagar ,Near Shilpashree Nursing Home",
                               "City_Id": "1114",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4798@kidzee.com",
                               "Mobile_No": "9844380558",
                               "Phone_No1": "9844380558",
                               "Phone_No2": "",
                               "Latitude": "12.52787",
                               "Longitude": "76.88452"
                            }
                         ]
                      },
                      {
                         "City_Name": "Karwar",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-3027",
                            "Franchisee_Name": "Kidzee Karwar Karnataka",
                            "Operating_Status": "A",
                            "Address1": "Shivakrupa,",
                            "Address2": "Old KHB Colony,",
                            "Place": "Kaiga Road,Opp KSRTC Bus depot,",
                            "City_Id": "1191",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3027@kidzee.com",
                            "Mobile_No": "8861607773",
                            "Phone_No1": "8861607773",
                            "Phone_No2": "",
                            "Latitude": "14.818481",
                            "Longitude": "74.141613"
                         }
                      },
                      {
                         "City_Name": "Sindhanur"
                      },
                      {
                         "City_Name": "Sirsi"
                      },
                      {
                         "City_Name": "Hiriyur",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-1902",
                            "Franchisee_Name": "Kidzee-Hiriyur",
                            "Operating_Status": "A",
                            "Address1": "Plot No.5, Behind- Pushpanjali Theater,",
                            "Address2": "Near-Sharadha Ashram,",
                            "Place": "Kuvempu Nagar,",
                            "City_Id": "1226",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1902@kidzee.com",
                            "Mobile_No": "8095004224",
                            "Phone_No1": "08193227853",
                            "Phone_No2": "",
                            "Latitude": "13.939769",
                            "Longitude": "76.612027"
                         }
                      },
                      {
                         "City_Name": "Dharwad",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2804",
                            "Franchisee_Name": "Kidzee Dharwad",
                            "Operating_Status": "A",
                            "Address1": "Mallikarjun-Plot No-67, 2nd Main Road, Sampige Nagar,",
                            "Address2": "Goa Road,",
                            "Place": "Dharwad, Opp Kulkarni Petrol Pump, Near-Basavaradd",
                            "City_Id": "1269",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2804@kidzee.com",
                            "Mobile_No": "9449828269",
                            "Phone_No1": "9066562869",
                            "Phone_No2": "",
                            "Latitude": "15.4643945",
                            "Longitude": "74.963256"
                         }
                      },
                      {
                         "City_Name": "Arsikere",
                         "Franchisee": {
                            "Franchisee_Code": "S-S-C-2087",
                            "Franchisee_Name": "Kidzee Arsikere",
                            "Operating_Status": "A",
                            "Address1": "K.N. Appanna Setty Building, 3rd Cross,",
                            "Address2": "",
                            "Place": "Subhash Nagar, Arsikere ,Opposite Kateyamma Temple",
                            "City_Id": "1310",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2087@kidzee.com",
                            "Mobile_No": "8174231966",
                            "Phone_No1": "7975081069",
                            "Phone_No2": "",
                            "Latitude": "13.318520",
                            "Longitude": "76.254572"
                         }
                      },
                      {
                         "City_Name": "Gulbarga",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-2397",
                               "Franchisee_Name": "Kidzee Gulbarga",
                               "Operating_Status": "A",
                               "Address1": "Vishwanath Raddewadi, 29, Ashirwad,",
                               "Address2": "Near Hanuman Temple, Om Nagar, Sedam Road,",
                               "Place": "Om Nagar ",
                               "City_Id": "1327",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2397@kidzee.com",
                               "Mobile_No": "9980783751",
                               "Phone_No1": "9980783751",
                               "Phone_No2": "",
                               "Latitude": "17.331864",
                               "Longitude": "76.841174"
                            },
                            {
                               "Franchisee_Code": "S-R-C-3056",
                               "Franchisee_Name": "Kidzee - Vithal Nagar, Gulbarga",
                               "Operating_Status": "A",
                               "Address1": "H. No-10-3/31, 8th cross,",
                               "Address2": "Kalaburgi",
                               "Place": "Vittal Nagar, H.No-10-3/96,1st cross, Vithal Nagar",
                               "City_Id": "1327",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3056@kidzee.com",
                               "Mobile_No": "9242424242",
                               "Phone_No1": "9590259590",
                               "Phone_No2": "",
                               "Latitude": "17.329731",
                               "Longitude": "76.834296"
                            },
                            {
                               "Franchisee_Code": "S-S-C-2183",
                               "Franchisee_Name": "Kidzee- Gulbarga",
                               "Operating_Status": "A",
                               "Address1": "Plot no.118, Nr. Sai Mandir",
                               "Address2": "Towards High Court, Ring Road, Nr. Sarvagna College",
                               "Place": "Ring Road, Near Ram mandir circle",
                               "City_Id": "1327",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2183@kidzee.com",
                               "Mobile_No": "8050305555",
                               "Phone_No1": "9740205555",
                               "Phone_No2": "",
                               "Latitude": "17.329731",
                               "Longitude": "76.834296"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4981",
                               "Franchisee_Name": "Kidzee Sedam Road",
                               "Operating_Status": "A",
                               "Address1": "plot no. 27, NGO colony, Okaly camp",
                               "Address2": "opp: om nagar gate, sedam road",
                               "Place": "Sedam Road ,Okaly camp Opp: Om Nagar gate",
                               "City_Id": "1327",
                               "State_Id": "20",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4981@kidzee.com",
                               "Mobile_No": "9945523342",
                               "Phone_No1": "9945523342",
                               "Phone_No2": "",
                               "Latitude": "17.32436",
                               "Longitude": "76.86125"
                            }
                         ]
                      },
                      {
                         "City_Name": "Hassan",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-5539",
                            "Franchisee_Name": "Shwetha Vidhya Samsthe ",
                            "Operating_Status": "A",
                            "Address1": "Beside Students PU College",
                            "Address2": "Ring Road ",
                            "Place": "Viveka nagara",
                            "City_Id": "1365",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5539@kidzee.com",
                            "Mobile_No": "8970408915",
                            "Phone_No1": "8172295220",
                            "Phone_No2": "",
                            "Latitude": "13.019372465204825",
                            "Longitude": "76.11451670000001"
                         }
                      },
                      {
                         "City_Name": "Haveri"
                      },
                      {
                         "City_Name": "Hunsur"
                      },
                      {
                         "City_Name": "Yadgir",
                         "Franchisee": {
                            "Franchisee_Code": "A-S-A-1058",
                            "Franchisee_Name": "Ankurum Yadagiri",
                            "Operating_Status": "A",
                            "Address1": "R/O Giridhar Nilaya Nazeer Colony",
                            "Address2": "Near M.G.School Station Area",
                            "Place": "Yadagiri ,Near M.G.School Station Area",
                            "City_Id": "1426",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "arunodaya786.as@gmail.com",
                            "Mobile_No": "8660723849",
                            "Phone_No1": "8660723849",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Virajpetcity"
                      },
                      {
                         "City_Name": "Jagalur"
                      },
                      {
                         "City_Name": "Ramanagara"
                      },
                      {
                         "City_Name": "Chikmagalur"
                      },
                      {
                         "City_Name": "Belur"
                      },
                      {
                         "City_Name": "Chitradurga"
                      },
                      {
                         "City_Name": "Arakalgud",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2664",
                            "Franchisee_Name": "Kidzee Arakalgud, Hassan",
                            "Operating_Status": "A",
                            "Address1": "Excellent Education Trust, Opposite- Agriculture Office,",
                            "Address2": "Hassan Road,",
                            "Place": "Fort Arakalgud, Hassan Dist. Opposite Agriculture ",
                            "City_Id": "1474",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2664@kidzee.com",
                            "Mobile_No": "9480303703",
                            "Phone_No1": "9902550907",
                            "Phone_No2": "",
                            "Latitude": "12.763075",
                            "Longitude": "76.061981"
                         }
                      },
                      {
                         "City_Name": "Basavakalyan"
                      },
                      {
                         "City_Name": "Jamakhandi"
                      },
                      {
                         "City_Name": "Devanahalli"
                      },
                      {
                         "City_Name": "Kolar"
                      },
                      {
                         "City_Name": "Chikkodi"
                      },
                      {
                         "City_Name": "Huliyar"
                      },
                      {
                         "City_Name": "Chickballapur",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-3105",
                            "Franchisee_Name": "Kidzee Chikkaballapura",
                            "Operating_Status": "A",
                            "Address1": "Swami Vivekanand Foundation House No-1, Vishweshwarayya Layout,",
                            "Address2": "Ward No.6, Behind Municipal College,",
                            "Place": "Chikkaballapura, Near Rajesh Clinic",
                            "City_Id": "1570",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3105@kidzee.com",
                            "Mobile_No": "9008160899",
                            "Phone_No1": "9008160899",
                            "Phone_No2": "",
                            "Latitude": "13.4364999158044",
                            "Longitude": "77.7207237482071"
                         }
                      },
                      {
                         "City_Name": "Doddaballapur"
                      },
                      {
                         "City_Name": "Gangavathi"
                      },
                      {
                         "City_Name": "KGF",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4065",
                            "Franchisee_Name": "Kidzee KGF",
                            "Operating_Status": "A",
                            "Address1": "2nd Cross, Lavelle Road,",
                            "Address2": "Robertsonpet,",
                            "Place": "KGF -Behind Muncipal Ground,",
                            "City_Id": "1852",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4065@kidzee.com",
                            "Mobile_No": "9449611994",
                            "Phone_No1": "9449611994",
                            "Phone_No2": "",
                            "Latitude": "12.951629",
                            "Longitude": "78.274159"
                         }
                      },
                      {
                         "City_Name": "Mudhol",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4418",
                            "Franchisee_Name": "Kidzee Mudhol",
                            "Operating_Status": "A",
                            "Address1": "Laxmikant S Malagan Building,  KHB Colony,",
                            "Address2": "P M Budni Road,",
                            "Place": "Mudhol ,Bhakshi Plot, P M Budni Road,",
                            "City_Id": "1854",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4418@kidzee.com",
                            "Mobile_No": "9731933949",
                            "Phone_No1": "9731933949",
                            "Phone_No2": "",
                            "Latitude": "16.34325",
                            "Longitude": "75.28978"
                         }
                      },
                      {
                         "City_Name": "Athani"
                      },
                      {
                         "City_Name": "Sira"
                      },
                      {
                         "City_Name": "Nippani"
                      },
                      {
                         "City_Name": "Chamraj nagar"
                      },
                      {
                         "City_Name": "Maddur",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4709",
                            "Franchisee_Name": "Kidzee Maddur",
                            "Operating_Status": "A",
                            "Address1": "Appu Abhi Nilaya,",
                            "Address2": "6th Cross, V.V. Nagar",
                            "Place": "Maddur ,Karnataka theatre road",
                            "City_Id": "1903",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4709@kidzee.com",
                            "Mobile_No": "7353455693",
                            "Phone_No1": "9742193406",
                            "Phone_No2": "",
                            "Latitude": "12.58096",
                            "Longitude": "77.04562"
                         }
                      },
                      {
                         "City_Name": "Ranebennur"
                      },
                      {
                         "City_Name": "Bharathinagar"
                      },
                      {
                         "City_Name": "Dandeli"
                      },
                      {
                         "City_Name": "Belagavi",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-5186",
                            "Franchisee_Name": "Vadgaon Old Belagavi",
                            "Operating_Status": "A",
                            "Address1": "Padma Raj RS No.102/1",
                            "Address2": " Near Kalmeshwar Temple  ",
                            "Place": "Vadgaon Old Belagavi",
                            "City_Id": "1959",
                            "State_Id": "20",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5186@kidzee.com",
                            "Mobile_No": "8884810108",
                            "Phone_No1": "8884810108",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Kerala",
                   "City": [
                      {
                         "City_Name": "Aluva"
                      },
                      {
                         "City_Name": "Cochin",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-S-2611",
                               "Franchisee_Name": "Kidzee kadavanthara, Kochi",
                               "Operating_Status": "A",
                               "Address1": "Sunny Side, Plot No-2, Aishwarya Lane,",
                               "Address2": "Gandhi Nagar,",
                               "Place": "Kadavanthara, Kochi, Near State Bank of India,",
                               "City_Id": "219",
                               "State_Id": "21",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2611@kidzee.com",
                               "Mobile_No": "7356227776",
                               "Phone_No1": "8111860003",
                               "Phone_No2": "",
                               "Latitude": "9.973524198984043",
                               "Longitude": "76.29720640146638",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/880625/10870/c1faf129_1f9f_4d4b_9818_290742dd19c1.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-R-S-2606",
                               "Franchisee_Name": "Kidzee-Infopark",
                               "Operating_Status": "A",
                               "Address1": "Kidzee Infopark",
                               "Address2": "4  Link valley, Plot 1 Opp. Kusumagiri Hospital.",
                               "Place": "Kakkanad ,Near Infopark",
                               "City_Id": "219",
                               "State_Id": "21",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2606@kidzee.com",
                               "Mobile_No": "9746457915",
                               "Phone_No1": "9746457915",
                               "Phone_No2": "",
                               "Latitude": "10.014442358657293",
                               "Longitude": "76.35941620964809"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2848",
                               "Franchisee_Name": "Kidzee-Thrippunithara",
                               "Operating_Status": "A",
                               "Address1": "Murickal,",
                               "Address2": "H.No-16/390/D,",
                               "Place": "Thrippunithara, Elamana Jetty, Vikas Nagar",
                               "City_Id": "219",
                               "State_Id": "21",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2848@kidzee.com",
                               "Mobile_No": "9496447507",
                               "Phone_No1": "94957 88093",
                               "Phone_No2": "",
                               "Latitude": "9.940844",
                               "Longitude": "76.321757"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2680",
                               "Franchisee_Name": "Kidzee Thoppumpady Kerala",
                               "Operating_Status": "A",
                               "Address1": "Yadukul,",
                               "Address2": "Mundemveli, P.O. Cochin,",
                               "Place": "Thoppumpady Cochin, Opposite to Santhome Church, M",
                               "City_Id": "219",
                               "State_Id": "21",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2680@kidzee.com",
                               "Mobile_No": "9995835846",
                               "Phone_No1": "9446047248",
                               "Phone_No2": "",
                               "Latitude": "9.935594",
                               "Longitude": "76.260946"
                            }
                         ]
                      },
                      {
                         "City_Name": "Ernakulam"
                      },
                      {
                         "City_Name": "Trivandrum"
                      },
                      {
                         "City_Name": "Palakkad / Palghat",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2774",
                            "Franchisee_Name": "Kidzee PALAKKAD",
                            "Operating_Status": "A",
                            "Address1": "S/335, KK Road, Sahyadri Colony,",
                            "Address2": "Chandranagar PO,",
                            "Place": "Palakkad",
                            "City_Id": "1080",
                            "State_Id": "21",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2774@kidzee.com",
                            "Mobile_No": "9496862414",
                            "Phone_No1": "9497138755",
                            "Phone_No2": "",
                            "Latitude": "10.776166",
                            "Longitude": "76.677365"
                         }
                      },
                      {
                         "City_Name": "Thiruvalla / Tiruvalla",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4705",
                            "Franchisee_Name": "Kidzee Adoor",
                            "Operating_Status": "A",
                            "Address1": "S.S.Sadabam, Kannamkode Muri,",
                            "Address2": "Adoor,",
                            "Place": "Pathanamthitta, Near- Adoor Muncipality",
                            "City_Id": "1093",
                            "State_Id": "21",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4705@kidzee.com",
                            "Mobile_No": "9833252944",
                            "Phone_No1": "9833252944",
                            "Phone_No2": "",
                            "Latitude": "9.149899",
                            "Longitude": "76.736914"
                         }
                      },
                      {
                         "City_Name": "Thrissur / Trichur / Thrisivaperoor"
                      },
                      {
                         "City_Name": "Kottayam"
                      },
                      {
                         "City_Name": "Chittilappilly"
                      },
                      {
                         "City_Name": "Calicut"
                      },
                      {
                         "City_Name": "Aroor"
                      },
                      {
                         "City_Name": "Thodupuzha",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2773",
                            "Franchisee_Name": "Kidzee-THODUPUZHA",
                            "Operating_Status": "A",
                            "Address1": "Thachuarambil House,",
                            "Address2": "Nethaji Road,",
                            "Place": "Thodupuzha East P.O-Near Ideal home,",
                            "City_Id": "1511",
                            "State_Id": "21",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2773@kidzee.com",
                            "Mobile_No": "9961067002",
                            "Phone_No1": "04862-224886",
                            "Phone_No2": "",
                            "Latitude": "9.89731492029347",
                            "Longitude": "76.72139679595944"
                         }
                      },
                      {
                         "City_Name": "Perumbavoor"
                      },
                      {
                         "City_Name": "Thalasserry",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-3257",
                               "Franchisee_Name": "Kidzee Thalassery",
                               "Operating_Status": "A",
                               "Address1": "Pradhiba, 50/308,",
                               "Address2": "State Bank Colony Road,",
                               "Place": "Thalassery ",
                               "City_Id": "1535",
                               "State_Id": "21",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3257@kidzee.com",
                               "Mobile_No": "8281716919",
                               "Phone_No1": "9447008671",
                               "Phone_No2": "",
                               "Latitude": "11.753288",
                               "Longitude": "75.492878"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2805",
                               "Franchisee_Name": "KIDZEE THALASSERY,KANNUR",
                               "Operating_Status": "A",
                               "Address1": "Door No.27/175, Krishnapuram,",
                               "Address2": "P.O-Paral,",
                               "Place": "Thalassery, Kannur, Near-Kallilthazha, Kodiyeri,",
                               "City_Id": "1535",
                               "State_Id": "21",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2805@kidzee.com",
                               "Mobile_No": "8281716919",
                               "Phone_No1": "8281716919",
                               "Phone_No2": "",
                               "Latitude": "11.740141",
                               "Longitude": "75.532899"
                            }
                         ]
                      },
                      {
                         "City_Name": "Kolenchery"
                      },
                      {
                         "City_Name": "Kothamangalam"
                      },
                      {
                         "City_Name": "Karunagapally"
                      },
                      {
                         "City_Name": "Kasaragod"
                      },
                      {
                         "City_Name": "Kottarakkara"
                      },
                      {
                         "City_Name": "DescriptionMalappuram"
                      },
                      {
                         "City_Name": "Mavelikkara"
                      }
                   ]
                },
                {
                   "State_Name": "Madhya Pradesh",
                   "City": [
                      {
                         "City_Name": "Banapura"
                      },
                      {
                         "City_Name": "Bhopal",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2349",
                               "Franchisee_Name": "Kidzee Lalghati Bhopal MP",
                               "Operating_Status": "A",
                               "Address1": "250, Jain Nagar Main Road,",
                               "Address2": "Gufa Mandir Road,",
                               "Place": "Near Jain Gufa mandir, Lalghati",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2349@kidzee.com",
                               "Mobile_No": "9039747198",
                               "Phone_No1": "0755-4011860",
                               "Phone_No2": "",
                               "Latitude": "23.279168",
                               "Longitude": "77.373714"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3281",
                               "Franchisee_Name": "Kidzee Gulmohar",
                               "Operating_Status": "A",
                               "Address1": "Balglow No-1,4 & 5, Campus",
                               "Address2": "Banglow No-6,",
                               "Place": "Gulmohor Main Road, Near Shahpura Poilce Thana",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3281@kidzee.com",
                               "Mobile_No": "9407513786",
                               "Phone_No1": "9407513786",
                               "Phone_No2": "",
                               "Latitude": "23.185662",
                               "Longitude": "77.432573"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3119",
                               "Franchisee_Name": "Kidzee Minal Residency",
                               "Operating_Status": "A",
                               "Address1": "New Minal Residency, Super Deluxe Bunglow No.88,",
                               "Address2": "J.K Road Govindpura, Main Road GATE NO-3",
                               "Place": "Minal Residency, Near Minal Gate No-3",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3119@kidzee.com",
                               "Mobile_No": "8818884499",
                               "Phone_No1": "07554058999",
                               "Phone_No2": "",
                               "Latitude": "23.275834",
                               "Longitude": "77.454421",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3119/168/7fec4299_8b72_45f3_a8e7_72b6b3b8df6d.JPG"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-3176",
                               "Franchisee_Name": "Kidzee Awadhpuri Aadharshila.",
                               "Operating_Status": "A",
                               "Address1": "H -547, East Block Adhaarshila,",
                               "Address2": "Berkhera,",
                               "Place": "Adhaarshila, Awadhpuri",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3176@kidzee.com",
                               "Mobile_No": "7869235513",
                               "Phone_No1": "8358909222 ",
                               "Phone_No2": "",
                               "Latitude": "23.219296",
                               "Longitude": "77.488731",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1247260/10688/1bf348b4_1fc0_4030_bbaf_dca68e361c5c.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-3226",
                               "Franchisee_Name": "Kidzee - Berasia , M.P.",
                               "Operating_Status": "A",
                               "Address1": "H.No-11,",
                               "Address2": "Krishna Colony, Near Rajnandini Garden,",
                               "Place": "Bhopal Road, Berasia, Near Rajnandini Garden",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3226@kidzee.com",
                               "Mobile_No": "9981339214",
                               "Phone_No1": "9981339214",
                               "Phone_No2": "",
                               "Latitude": "23.626339578099866",
                               "Longitude": "77.4318167672119"
                            },
                            {
                               "Franchisee_Code": "E-S-C-1870",
                               "Franchisee_Name": "Kidzee Gautam Nagar",
                               "Operating_Status": "A",
                               "Address1": "M183,",
                               "Address2": "",
                               "Place": "Gautam Nagar, Near-Chetak Bridge,",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1870@kidzee.com",
                               "Mobile_No": "8109206599",
                               "Phone_No1": "8109206599",
                               "Phone_No2": "",
                               "Latitude": "23.283286",
                               "Longitude": "77.397394"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2521",
                               "Franchisee_Name": "Kidzee Aashima Aryan Wing",
                               "Operating_Status": "A",
                               "Address1": "Bunglow No.1, Aryan Wings,",
                               "Address2": "Jatkhedi Road,",
                               "Place": "Aryan Wings, Near Aashima Mall,",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2521@kidzee.com",
                               "Mobile_No": "9300812293",
                               "Phone_No1": "9300812293",
                               "Phone_No2": "",
                               "Latitude": "23.173904",
                               "Longitude": "77.478801"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3800",
                               "Franchisee_Name": "Kidzee Ashoka Garden  Bhopal",
                               "Operating_Status": "A",
                               "Address1": "House No- 152, Old Ashoka Garden,",
                               "Address2": "Behind Sam Girls college",
                               "Place": "Raisen road.",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3800@kidzee.com",
                               "Mobile_No": "9098776611",
                               "Phone_No1": "07554010305",
                               "Phone_No2": "",
                               "Latitude": "23.253669",
                               "Longitude": "77.432225"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3458",
                               "Franchisee_Name": "Kidzee Karond",
                               "Operating_Status": "A",
                               "Address1": "C-12, Elaxir Garden,",
                               "Address2": "Opp. Truba College, New Jail Road,",
                               "Place": "Karond, Opp. Truba College,",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3458@kidzee.com",
                               "Mobile_No": "9039747198",
                               "Phone_No1": "9039747198",
                               "Phone_No2": "",
                               "Latitude": "23.299397",
                               "Longitude": "77.388416"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4167",
                               "Franchisee_Name": "Kidzee Katara",
                               "Operating_Status": "A",
                               "Address1": "House No.52 A/B, Swarn Kunj Colony,",
                               "Address2": "Amaltas Housing Society,",
                               "Place": "Katara Hills, Opp.Global Park,",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4167@kidzee.com",
                               "Mobile_No": "9039001160",
                               "Phone_No1": "075511111",
                               "Phone_No2": "",
                               "Latitude": "23.188526",
                               "Longitude": "77.489048"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4214",
                               "Franchisee_Name": "KIDZEE SACHIDANAND NIZAMUDDIN ROAD",
                               "Operating_Status": "A",
                               "Address1": "B-12, Sachidanand Nagar,",
                               "Address2": "",
                               "Place": "Nizamuddin Main Road, Hujur, Near-Srawankanta Pala",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4214@kidzee.com",
                               "Mobile_No": "8881888449",
                               "Phone_No1": "9993746065",
                               "Phone_No2": "",
                               "Latitude": "23.262965",
                               "Longitude": "77.464875"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4695",
                               "Franchisee_Name": "Kidzee BMHRC Bhopal",
                               "Operating_Status": "A",
                               "Address1": "House No. 2, Premier orchard",
                               "Address2": "Behind people's mall ayodhyaa by pass road",
                               "Place": "BMHRC Bhopal ,Behind people's mall ayodhyaa by pas",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4695@kidzee.com",
                               "Mobile_No": "7354740497",
                               "Phone_No1": "7354740497",
                               "Phone_No2": "",
                               "Latitude": "23.310532",
                               "Longitude": "77.421143"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4147",
                               "Franchisee_Name": "Kidzee Nehru Nagar",
                               "Operating_Status": "A",
                               "Address1": "Swami Vivekanand School,",
                               "Address2": "In Front of House Number S-1014,",
                               "Place": "Nehru Nagar ,",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4147@kidzee.com",
                               "Mobile_No": "9753099277",
                               "Phone_No1": "9753099277",
                               "Phone_No2": "",
                               "Latitude": "23.2096413",
                               "Longitude": "77.3956449"
                            },
                            {
                               "Franchisee_Code": "A-W-A-1053",
                               "Franchisee_Name": "Ankurum Lambakheda",
                               "Operating_Status": "A",
                               "Address1": "House Number 95, New Lambakheda",
                               "Address2": "Near Shiv Mandir",
                               "Place": "Lambakheda ,Near Shiv Mandir",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "prerna8asinha@gmail.com",
                               "Mobile_No": "7000835254",
                               "Phone_No1": "7000835254",
                               "Phone_No2": "",
                               "Latitude": "23.321606",
                               "Longitude": "77.404443"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5354",
                               "Franchisee_Name": "Kidzee Airport Road, Bhopal",
                               "Operating_Status": "A",
                               "Address1": "B-202",
                               "Address2": "Indra vihar Colony",
                               "Place": "Behind Canara bank,Airport Road.",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5354@kidzee.com",
                               "Mobile_No": "9479661565",
                               "Phone_No1": "8989545451",
                               "Phone_No2": "",
                               "Latitude": "23.2824140",
                               "Longitude": "77.3580220"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5437",
                               "Franchisee_Name": "Kidzee Saket nagar",
                               "Operating_Status": "A",
                               "Address1": "H No-72/ 2-C ,",
                               "Address2": "Infront of Aiims gate No-2,",
                               "Place": "Saket nagar",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5437@kidzee.com",
                               "Mobile_No": "7566005637",
                               "Phone_No1": "7566005637",
                               "Phone_No2": "",
                               "Latitude": "23.213204",
                               "Longitude": "77.460870"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5381",
                               "Franchisee_Name": "Kidzee Rohit Nagar",
                               "Operating_Status": "A",
                               "Address1": "141, Phase 2",
                               "Address2": "Nearby Akriti Eco city",
                               "Place": "Rohit Nagar",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzeebabystar@gmail.com",
                               "Mobile_No": "8982681868",
                               "Phone_No1": "6263171196",
                               "Phone_No2": "",
                               "Latitude": "23.172565",
                               "Longitude": "77.439425"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4304",
                               "Franchisee_Name": "Kidzee Danish Kunj Kolar Road",
                               "Operating_Status": "A",
                               "Address1": "DK-2/206, Danish Kunj Colony,",
                               "Address2": "Village Banjari, Ward No.82, Kolar Road, Tehshil Huzur,",
                               "Place": "Danish Kunj,",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4304@kidzee.com",
                               "Mobile_No": "7000835254",
                               "Phone_No1": "7000835254",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4383",
                               "Franchisee_Name": "Kidzee Vrindavan Nagar",
                               "Operating_Status": "A",
                               "Address1": "Makan No.12,",
                               "Address2": "Jeet Homes, Gram Hathaikheda, Tehsheel Huzur,",
                               "Place": "Vrindavan Nagar, Near Church,",
                               "City_Id": "159",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4383@kidzee.com",
                               "Mobile_No": "9753099277",
                               "Phone_No1": "9753099277",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Chindwara",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2508",
                               "Franchisee_Name": "Kidzee-Chindwara",
                               "Operating_Status": "A",
                               "Address1": "No.526/2, Ward No.24/222,",
                               "Address2": "",
                               "Place": "South Civil Lines,",
                               "City_Id": "207",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2508@kidzee.com",
                               "Mobile_No": "9907762973",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "22.051945",
                               "Longitude": "78.929624"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2004",
                               "Franchisee_Name": "kidzee Chhindwara",
                               "Operating_Status": "A",
                               "Address1": "Near-PG Collage,",
                               "Address2": "Kalibadi Temple Road, Kalibadi Hills Colony,",
                               "Place": "Dharamtekri, Opp-Central School,",
                               "City_Id": "207",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2004@kidzee.com",
                               "Mobile_No": "9713509713",
                               "Phone_No1": "7000072049 ",
                               "Phone_No2": "",
                               "Latitude": "23.242519",
                               "Longitude": "77.487313"
                            }
                         ]
                      },
                      {
                         "City_Name": "Dewas",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-C-U-1088",
                               "Franchisee_Name": "Kidzee- Dewas- MP- Maa Chamunda Educational & Research Society Dewas",
                               "Operating_Status": "A",
                               "Address1": "Mahalaxmi Nagar,",
                               "Address2": "Plot No-6,7,",
                               "Place": "In Front of Apex Hospital, AB Road,",
                               "City_Id": "252",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1088@kidzee.com",
                               "Mobile_No": "9826030723",
                               "Phone_No1": "9407087098",
                               "Phone_No2": "",
                               "Latitude": "22.9573520070785",
                               "Longitude": "76.0413390398026"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4570",
                               "Franchisee_Name": "Kidzee Double Chowki",
                               "Operating_Status": "A",
                               "Address1": "Village Double Chowki,",
                               "Address2": "Post-",
                               "Place": "Double Chowki, Harda Road,",
                               "City_Id": "252",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4570@kidzee.com",
                               "Mobile_No": "9981268133",
                               "Phone_No1": "7828688778",
                               "Phone_No2": "",
                               "Latitude": "22.70288663",
                               "Longitude": "76.1310454"
                            }
                         ]
                      },
                      {
                         "City_Name": "Gwalior",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-S-C-2040",
                               "Franchisee_Name": "Kidzee Thatipur Gwalior",
                               "Operating_Status": "A",
                               "Address1": "House No-21, Gandhi Road,",
                               "Address2": "Murar,",
                               "Place": "Thatipur, Above Canra Bank,",
                               "City_Id": "408",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2040@kidzee.com",
                               "Mobile_No": "9977456333",
                               "Phone_No1": "8989131666",
                               "Phone_No2": "",
                               "Latitude": "26.216968",
                               "Longitude": "78.216187"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3623",
                               "Franchisee_Name": "Kidzee City Center  Gwalior",
                               "Operating_Status": "A",
                               "Address1": "15, New Govindpuri",
                               "Address2": "Sachin Tendulkar Marg",
                               "Place": "City Center Gwalior ,New Govindpuri",
                               "City_Id": "408",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3623@kidzee.com",
                               "Mobile_No": "9826510884",
                               "Phone_No1": "9303059791",
                               "Phone_No2": "",
                               "Latitude": "26.204572",
                               "Longitude": "78.201767"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4770",
                               "Franchisee_Name": "Kidzee Anand Nagar",
                               "Operating_Status": "A",
                               "Address1": "Hari har Nagar",
                               "Address2": "Sagar tal Road",
                               "Place": "Anand Nagar ,Sagartal Road",
                               "City_Id": "408",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4770@kidzee.com",
                               "Mobile_No": "7024160850",
                               "Phone_No1": "0751-6085000",
                               "Phone_No2": "",
                               "Latitude": "26.23898",
                               "Longitude": "78.15587",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2507206/10962/30b72fc4_1ce3_4afd_b197_1067991e4b46.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2507206/10962/f2b4814c_0357_4909_bf77_d5c09401d6a0.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-3688",
                               "Franchisee_Name": "Kidzee Gole Ka Mandir",
                               "Operating_Status": "A",
                               "Address1": "A-38",
                               "Address2": "Bank Colony",
                               "Place": "Gole Ka Mandir ,Near Overhead water tank",
                               "City_Id": "408",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3688@kidzee.com",
                               "Mobile_No": "9074251789",
                               "Phone_No1": "9074251789",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-3654",
                               "Franchisee_Name": "Kidzee Jiwaji Nagar",
                               "Operating_Status": "A",
                               "Address1": "299 Jiwaji nagar",
                               "Address2": "near Thatipur Petrol pump, Ground Floor",
                               "Place": "Jiwaji Nagar ,Near Optimus College",
                               "City_Id": "408",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3654@kidzee.com",
                               "Mobile_No": "9425756416",
                               "Phone_No1": "9893410204",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4047",
                               "Franchisee_Name": "Kidzee DD Nagar",
                               "Operating_Status": "A",
                               "Address1": "B-20 Balram Nagar",
                               "Address2": "Near BhindRoad D D Nagar",
                               "Place": "Gwalior",
                               "City_Id": "408",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4047@kidzee.com",
                               "Mobile_No": "9074251789",
                               "Phone_No1": "075111111",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5469",
                               "Franchisee_Name": "Kidzee Blue Lotus Hill Colony",
                               "Operating_Status": "A",
                               "Address1": "I-01",
                               "Address2": "Near Collectorate City",
                               "Place": "Blue Lotus Hill Colony",
                               "City_Id": "408",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5469@kidzee.com",
                               "Mobile_No": "7879701059",
                               "Phone_No1": "7879701059",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Indore",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-S-C-2214",
                               "Franchisee_Name": "Kidzee Annapurna",
                               "Operating_Status": "A",
                               "Address1": "76, Chanakyapuri",
                               "Address2": "Narmada Nagar",
                               "Place": "Annapurna Road",
                               "City_Id": "458",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2214@kidzee.com",
                               "Mobile_No": "9329777008",
                               "Phone_No1": "9329777008",
                               "Phone_No2": "",
                               "Latitude": "22.68787469",
                               "Longitude": "75.82919474"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3756",
                               "Franchisee_Name": "Kidzee Airport road",
                               "Operating_Status": "A",
                               "Address1": "House No- 246  Ground Floor,",
                               "Address2": "Vindhanchal Nagar,",
                               "Place": "Airport Road, Vindhanchal Nagar, Airport Road,",
                               "City_Id": "458",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3756@kidzee.com",
                               "Mobile_No": "9926226526",
                               "Phone_No1": "1234-0000000",
                               "Phone_No2": "",
                               "Latitude": "22.721145",
                               "Longitude": "75.835324"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5371",
                               "Franchisee_Name": "Kidzee  Rau Indore",
                               "Operating_Status": "A",
                               "Address1": "Richa Chaturvedi",
                               "Address2": "A -47 Silicon City",
                               "Place": "Rau Indore MP",
                               "City_Id": "458",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5371@kidzee.com",
                               "Mobile_No": "7987775182",
                               "Phone_No1": "7987775182",
                               "Phone_No2": "",
                               "Latitude": "22.64521",
                               "Longitude": "75.83316"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5341",
                               "Franchisee_Name": "Kidzee Usha Nagar",
                               "Operating_Status": "A",
                               "Address1": "Campus 396 Usha Nagar",
                               "Address2": "Near Namo Sandwich",
                               "Place": "Kidzee Sudama Nagar, D Sector",
                               "City_Id": "458",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5341@kidzee.com",
                               "Mobile_No": "9131027507",
                               "Phone_No1": "9713742939",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5416",
                               "Franchisee_Name": "Kidzee Nanda Nagar",
                               "Operating_Status": "A",
                               "Address1": "64/2",
                               "Address2": "Near Teen Pulia",
                               "Place": "Nanda Nagar",
                               "City_Id": "458",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5416@kidzee.com",
                               "Mobile_No": "9303022222",
                               "Phone_No1": "9303022222",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5651",
                               "Franchisee_Name": "Vijay Nagar Indore",
                               "Operating_Status": "A",
                               "Address1": "143, BG,",
                               "Address2": "Yojayana Scheme 74, CIDA,",
                               "Place": "Vijay Nagar",
                               "City_Id": "458",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5651@kidzee.com",
                               "Mobile_No": "9611100524",
                               "Phone_No1": "9611100524",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "A-W-A-1013",
                               "Franchisee_Name": "Ankurum Nisarpur",
                               "Operating_Status": "A",
                               "Address1": "Gram Nisarpur",
                               "Address2": "Tehshil Kukshi, District Dhar",
                               "Place": "Nisarpur ",
                               "City_Id": "458",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "er.sohanpatidar@gmail.com",
                               "Mobile_No": "9893857655",
                               "Phone_No1": "9893857655",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Jabalpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2563",
                               "Franchisee_Name": "Kidzee Madan Mahal Jabalpur",
                               "Operating_Status": "A",
                               "Address1": "662-Mauja Maheshpur, Prem Nagar,",
                               "Address2": "Behind Saluja Tyres, Opp-Hero Honda Service Center,",
                               "Place": "Madan Mahal, Opp.Hero Honda Service Center,",
                               "City_Id": "467",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2563@kidzee.com",
                               "Mobile_No": "8962018399",
                               "Phone_No1": "8962018399",
                               "Phone_No2": "",
                               "Latitude": "23.153372",
                               "Longitude": "79.918023"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3327",
                               "Franchisee_Name": "Kidzee  Jasuja city",
                               "Operating_Status": "A",
                               "Address1": "Plot No-119, Dhanvantri Nagar,",
                               "Address2": "PNT Colony,",
                               "Place": "Jasuja City, Jasuja City,",
                               "City_Id": "467",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3327@kidzee.com",
                               "Mobile_No": "9826186088",
                               "Phone_No1": "761-6986088",
                               "Phone_No2": "",
                               "Latitude": "23.159673",
                               "Longitude": "79.8703818723526",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1450865/10868/5547d63e_0d34_4457_ac19_c09702a4cfe8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1450865/10868/15bb4c42_ae16_464f_84b0_b888b78547ba.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1450865/10868/5df8dedb_0953_4914_a799_93fc1fe4c83b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1450865/10868/f8094e2f_1333_4656_b70b_3feadc12333c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1450865/10868/f2a23fed_f58d_4bec_91eb_cfc326d1e93c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1450865/10868/bced5246_b830_4517_9f9b_cf2ecf4e69ab.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1450865/10868/0b3a4643_4920_472c_b114_16ded7f4b2bc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1450865/10868/99d0461d_4e75_4266_a041_dac8cace1944.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1450865/10868/ceb9a2e5_3646_4b4d_80cb_37e9ae751023.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-2726",
                               "Franchisee_Name": "Kidzee Jabalpur",
                               "Operating_Status": "A",
                               "Address1": "5 Aditiya Colony,",
                               "Address2": "Narmada Road,",
                               "Place": "Jabalpur, Behind-Heera Sweets,",
                               "City_Id": "467",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2726@kidzee.com",
                               "Mobile_No": "9993191134",
                               "Phone_No1": "9993191134",
                               "Phone_No2": "",
                               "Latitude": "23.139217",
                               "Longitude": "79.926503"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3962",
                               "Franchisee_Name": "Kidzee Trimurti Nagar",
                               "Operating_Status": "A",
                               "Address1": "283,",
                               "Address2": "Shastri Vihar",
                               "Place": "Trimurti Nagar, Jabalpur",
                               "City_Id": "467",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3962@kidzee.com",
                               "Mobile_No": "9424657445",
                               "Phone_No1": "9424657445",
                               "Phone_No2": "",
                               "Latitude": "23.196269",
                               "Longitude": "79.925105"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3693",
                               "Franchisee_Name": "Kidzee Bhitoni",
                               "Operating_Status": "A",
                               "Address1": "138/6, Village Bhamki, Infront Of Bharat Gas Plant,",
                               "Address2": "Bypass Road, Shahpura",
                               "Place": "Bhitoni, Infront Of Bharat Gas Plant, Bypass Road",
                               "City_Id": "467",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3693@kidzee.com",
                               "Mobile_No": "9893201570",
                               "Phone_No1": "9303052221",
                               "Phone_No2": "",
                               "Latitude": "23.1410590",
                               "Longitude": "79.6852540"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3538",
                               "Franchisee_Name": "Kidzee Sihora",
                               "Operating_Status": "A",
                               "Address1": "H.No.1/11 Near-Vijay Petrol Pump Mansakra Sihora,",
                               "Address2": "Mansakra",
                               "Place": "Sihora, Near-Vijay Petrol Pump,",
                               "City_Id": "467",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3538@kidzee.com",
                               "Mobile_No": "9826556907",
                               "Phone_No1": "9826556907",
                               "Phone_No2": "",
                               "Latitude": "23.496671",
                               "Longitude": "80.112808"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5688",
                               "Franchisee_Name": "Vijay Nagar Jabalpur",
                               "Operating_Status": "A",
                               "Address1": "House no. 1067,",
                               "Address2": "Scheme No.5",
                               "Place": ", near Shalby Hospital ,Vijaynagar",
                               "City_Id": "467",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5688@kidzee.com",
                               "Mobile_No": "8770969988",
                               "Phone_No1": "8770969988",
                               "Phone_No2": "",
                               "Latitude": "23.1853466",
                               "Longitude": "79.9071197"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5400",
                               "Franchisee_Name": "Kidzee Jabalpur Ekam center",
                               "Operating_Status": "A",
                               "Address1": "Aaradhya Infotech Pvt. Ltd",
                               "Address2": "196/1 wright town",
                               "Place": "Goumata chowk",
                               "City_Id": "467",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "aaradhyagroup@gmail.com",
                               "Mobile_No": "9201377777",
                               "Phone_No1": "9201377777",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5424",
                               "Franchisee_Name": "Kidzee Shivpuri",
                               "Operating_Status": "A",
                               "Address1": "Katiya ghat road ,",
                               "Address2": "Kajarwara ward no. 66 Bhita ",
                               "Place": "Shivpuri",
                               "City_Id": "467",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5424@kidzee.com",
                               "Mobile_No": "7974099466",
                               "Phone_No1": "8962188101",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Mandla",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2670",
                               "Franchisee_Name": "Kidzee Mandla",
                               "Operating_Status": "A",
                               "Address1": "Tilak Ward,",
                               "Address2": "Civil Lines,",
                               "Place": "Mandla, Nehru Smarak,",
                               "City_Id": "646",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee2670@kidzee.com",
                               "Mobile_No": "7999581124",
                               "Phone_No1": "7999581124",
                               "Phone_No2": "",
                               "Latitude": "22.597922",
                               "Longitude": "80.371385"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5780",
                               "Franchisee_Name": "Kidzee Mandla",
                               "Operating_Status": "A",
                               "Address1": "Near Maharshri  school",
                               "Address2": "Katra Mandla",
                               "Place": "MK Colony,",
                               "City_Id": "646",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5780@kidzee.com",
                               "Mobile_No": "7000323868",
                               "Phone_No1": "7000323868",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Morena"
                      },
                      {
                         "City_Name": "Ratlam",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3854",
                               "Franchisee_Name": "Kidzee Kasturba Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot no. 405,",
                               "Address2": "Kasturba Nagar",
                               "Place": "Ratlam ,Gali No.7.",
                               "City_Id": "835",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3854@kidzee.com",
                               "Mobile_No": "9669199987",
                               "Phone_No1": "9669-199987",
                               "Phone_No2": "",
                               "Latitude": "23.3382474517557",
                               "Longitude": "75.0285530090332",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1874368/10627/36522124_08cb_44d5_bff0_72327c074665.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-5462",
                               "Franchisee_Name": "Kidzee Nazar Bagh Ratlam",
                               "Operating_Status": "A",
                               "Address1": "Shri 6, Nazar Bagh",
                               "Address2": "Palace Road",
                               "Place": "Ratlam",
                               "City_Id": "835",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5462@kidzee.com",
                               "Mobile_No": "9303944771",
                               "Phone_No1": "9303944771",
                               "Phone_No2": "",
                               "Latitude": "23.324756",
                               "Longitude": "75.042532"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5459",
                               "Franchisee_Name": "Kidzee Jaora",
                               "Operating_Status": "A",
                               "Address1": "Shri Sajna Sath Oswal Samaj",
                               "Address2": "Lala Gali (Nora) , Nazar bagh, Palace Road,",
                               "Place": "Jaora",
                               "City_Id": "835",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5459@kidzee.com",
                               "Mobile_No": "9303944229",
                               "Phone_No1": "9303944229",
                               "Phone_No2": "",
                               "Latitude": "23.6377300",
                               "Longitude": "75.1291365"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5789",
                               "Franchisee_Name": "Kidzee Siddhi Vinayak Colony",
                               "Operating_Status": "A",
                               "Address1": "Site 30,",
                               "Address2": "Near Deen Dayal Nagar,",
                               "Place": "Siddhi Vinayak Colony",
                               "City_Id": "835",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5789@kidzee.com",
                               "Mobile_No": "9424868322",
                               "Phone_No1": "9424868322",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Rewa",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-4007",
                               "Franchisee_Name": "Kidzee  Ratahara",
                               "Operating_Status": "A",
                               "Address1": "15/595,",
                               "Address2": "",
                               "Place": "Ratahara, Behind- Landmark Hotel- Ratahara,",
                               "City_Id": "837",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4007@kidzee.com",
                               "Mobile_No": "9977550256",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "24.5447088",
                               "Longitude": "81.3315666"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3689",
                               "Franchisee_Name": "Kidzee Chirhaula colony , Rewa",
                               "Operating_Status": "A",
                               "Address1": "Ward No.44,",
                               "Address2": "",
                               "Place": "Chirhaula Colony, Beside- Mook Badhir School,",
                               "City_Id": "837",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3689@kidzee.com",
                               "Mobile_No": "9424337590",
                               "Phone_No1": "9424337590",
                               "Phone_No2": "",
                               "Latitude": "24.518052",
                               "Longitude": "81.306584"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4657",
                               "Franchisee_Name": "Kidzee Sirmour Chouraha",
                               "Operating_Status": "A",
                               "Address1": "MIG 35, Behind P.K. School",
                               "Address2": "Near Anupam Nursing Home",
                               "Place": "Sirmour Chouraha",
                               "City_Id": "837",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4657@kidzee.com",
                               "Mobile_No": "7898986644",
                               "Phone_No1": "7898986644",
                               "Phone_No2": "",
                               "Latitude": "24.54266",
                               "Longitude": "81.30391"
                            }
                         ]
                      },
                      {
                         "City_Name": "Satna",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-B-U-337",
                               "Franchisee_Name": "Kidzee- Satna- Madhya Pradesh-Tiny Tots Play School",
                               "Operating_Status": "A",
                               "Address1": "Saraswati School Road,",
                               "Address2": "",
                               "Place": "Krishna Nagar",
                               "City_Id": "878",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee337@kidzee.com",
                               "Mobile_No": "9009953333",
                               "Phone_No1": "07672-409454",
                               "Phone_No2": "",
                               "Latitude": "24.569346",
                               "Longitude": "80.844522"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3269",
                               "Franchisee_Name": "Kidzee Panna Naka",
                               "Operating_Status": "A",
                               "Address1": "38- Prabhat Vihar colony,",
                               "Address2": "",
                               "Place": "Panna Naka ,Opposite Kalyan Petrol Pump,",
                               "City_Id": "878",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3269@kidzee.com",
                               "Mobile_No": "9323938675",
                               "Phone_No1": "9770-715555",
                               "Phone_No2": "",
                               "Latitude": "24.57399105026427",
                               "Longitude": "80.81811695123861",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1415154/10631/0c4811f6_1ec7_4f58_8edf_243a9edbff77.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1415154/10631/c9f2acca_07bd_408a_9bf9_64e0cf65aacb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1415154/10631/9d303ac1_6d33_4cce_95d5_24078c0b0599.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-5463",
                               "Franchisee_Name": "Kidzee Utaily",
                               "Operating_Status": "A",
                               "Address1": "Ward No 22, Utaily Main Road, Near Housing Bord Colony, Utaily, Satna, Madhyapradesh ,495001.",
                               "Address2": "Near Housing Bord Colony,",
                               "Place": "Utaily,",
                               "City_Id": "878",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "mrinal.mt@gmail.com",
                               "Mobile_No": "9329917346",
                               "Phone_No1": "9329917346",
                               "Phone_No2": "",
                               "Latitude": "24.568270",
                               "Longitude": "80.864060"
                            }
                         ]
                      },
                      {
                         "City_Name": "Sehora"
                      },
                      {
                         "City_Name": "Ujjain"
                      },
                      {
                         "City_Name": "Narsinghpur / Narsimhapur",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-5089",
                            "Franchisee_Name": "Kidzee Narsinghpur",
                            "Operating_Status": "A",
                            "Address1": "Rani Avanti Bai Ward,Near Gupta Iron",
                            "Address2": "Infront Raj Bhavan,",
                            "Place": "Kandeli ,Near Gupta IronInfront Raj Bhavan,",
                            "City_Id": "1070",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5089@kidzee.com",
                            "Mobile_No": "9340100818",
                            "Phone_No1": "9340100818",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Sultanpur"
                      },
                      {
                         "City_Name": "Raisen",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "A-W-A-1068",
                               "Franchisee_Name": "Ankurum Badi",
                               "Operating_Status": "A",
                               "Address1": "Ward No. 05, Mahaveer Chhaya Colony",
                               "Address2": "Tehsil Badi, Dist. Raisen",
                               "Place": "Badi",
                               "City_Id": "1144",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "eryashjain93@gmail.com",
                               "Mobile_No": "7987123941",
                               "Phone_No1": "7987123941",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "A-W-A-1020",
                               "Franchisee_Name": "Ankurum Khargone JJ Road",
                               "Operating_Status": "A",
                               "Address1": "Brij Bihari Sharma, House Number 117",
                               "Address2": "Kasba Khargone, JJ Road Teh Shil Bareli,",
                               "Place": "Khargone JJ Road ,non operational",
                               "City_Id": "1144",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "reshubareli@yahoo.co.in, kidzeeankurumk@gmail.com",
                               "Mobile_No": "9907090354",
                               "Phone_No1": "9907090354",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Mau"
                      },
                      {
                         "City_Name": "Bhind"
                      },
                      {
                         "City_Name": "Sagar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3515",
                               "Franchisee_Name": "Kidzee Sagar Tili Road",
                               "Operating_Status": "A",
                               "Address1": "H.No-21, Tirupati Puram Colony,",
                               "Address2": "",
                               "Place": "Sagar Tili Road, Opposite-Bundelkhand Medical Coll",
                               "City_Id": "1148",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3515@kidzee.com",
                               "Mobile_No": "8435431195",
                               "Phone_No1": "8435431195",
                               "Phone_No2": "",
                               "Latitude": "23.8186663",
                               "Longitude": "78.7541145",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1595118/10626/0d37aa78_a02e_496c_b1b9_1dc5efb00fac.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595118/10626/8b1e0997_ccd6_40e0_bc94_8ae4ad75a230.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595118/10626/e1ab6c54_2be4_4921_94ef_003372062e04.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595118/10626/1e801546_eb54_4de9_a5f1_2aee8fe23034.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-4555",
                               "Franchisee_Name": "Kidzee Bhagwangunj Sagar",
                               "Operating_Status": "A",
                               "Address1": "Opposite-Honda Showroom,",
                               "Address2": "",
                               "Place": "Bhagwangunj Sagar, Opposite-Honda Showroom,",
                               "City_Id": "1148",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4555@kidzee.com",
                               "Mobile_No": "7405510559",
                               "Phone_No1": "7405510559",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4048",
                               "Franchisee_Name": "Kidzee Makroniya",
                               "Operating_Status": "A",
                               "Address1": "Near-Jain Mandir,",
                               "Address2": "Neha Nagar,",
                               "Place": "Makroniya, Sagar",
                               "City_Id": "1148",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4048@kidzee.com",
                               "Mobile_No": "9425193525",
                               "Phone_No1": "9425642339",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": "",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1955568/10705/8662e7e9_0d5f_4e58_b06a_0e1dffeecc1e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1955568/10705/c1d4a75b_3b9f_4910_9443_7dc3775a4392.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1955568/10705/5efc50b6_32b4_4109_825f_5cc31bf62c57.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1955568/10705/34e67e18_986e_4d0f_b547_8521766f4d88.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1955568/10705/ab191bea_4d8b_462a_a7f0_bfb9fafd95d4.jpg"
                                  }
                               ]
                            }
                         ]
                      },
                      {
                         "City_Name": "Kareli"
                      },
                      {
                         "City_Name": "Jhabua"
                      },
                      {
                         "City_Name": "Singrauli",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2177",
                               "Franchisee_Name": "Kidzee WHIZKIDS Learningand Educational Trust Whaidan",
                               "Operating_Status": "A",
                               "Address1": "plot no. 123 & 124",
                               "Address2": "Behind post office Main road Bhilogi Waidhan",
                               "Place": "Waidhan ,Behind Waidhan Post office",
                               "City_Id": "1212",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2177@kidzee.com",
                               "Mobile_No": "9754144454",
                               "Phone_No1": "9619440877",
                               "Phone_No2": "",
                               "Latitude": "24.062748",
                               "Longitude": "82.624775"
                            },
                            {
                               "Franchisee_Code": "E-R-C-1920",
                               "Franchisee_Name": "Kidzee Singrauli",
                               "Operating_Status": "A",
                               "Address1": "Ward No.4,",
                               "Address2": "Near Azad Chowk Ncl Colony.",
                               "Place": "Ambedker Nagar, Morwa",
                               "City_Id": "1212",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1920@kidzee.com",
                               "Mobile_No": "9753445905",
                               "Phone_No1": "8839381575",
                               "Phone_No2": "",
                               "Latitude": "24.19921",
                               "Longitude": "82.664547"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5230",
                               "Franchisee_Name": "Kidzee Vindhyanagar",
                               "Operating_Status": "A",
                               "Address1": "Near Tata Commercial",
                               "Address2": "Dhoti Dist.",
                               "Place": "Vindhya Nagar Road",
                               "City_Id": "1212",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "jitendra_singh220@yahoo.com",
                               "Mobile_No": "8839381575",
                               "Phone_No1": "9753445905",
                               "Phone_No2": "",
                               "Latitude": "24.077032",
                               "Longitude": "82.650728"
                            }
                         ]
                      },
                      {
                         "City_Name": "Chhatarpur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-1953",
                            "Franchisee_Name": "Kidzee Chatarpur",
                            "Operating_Status": "A",
                            "Address1": "Loknathpuram,",
                            "Address2": "",
                            "Place": "Chatarpur, Near.Dr. M.P.N khare,",
                            "City_Id": "1215",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1953@kidzee.com",
                            "Mobile_No": "9669191962",
                            "Phone_No1": "9424566656",
                            "Phone_No2": "",
                            "Latitude": "24.899132278971166",
                            "Longitude": "79.57982043782044"
                         }
                      },
                      {
                         "City_Name": "Jaora"
                      },
                      {
                         "City_Name": "Tikamgarh"
                      },
                      {
                         "City_Name": "Vidisha"
                      },
                      {
                         "City_Name": "Hoshangabad",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3283",
                            "Franchisee_Name": "Kidzee Shanti Nagar",
                            "Operating_Status": "A",
                            "Address1": "c/o Dr Abshishek Jain,",
                            "Address2": "Kulamadi Road,  Near Mahila Jail,",
                            "Place": "Hoshangabad ,Near Mahila jail",
                            "City_Id": "1309",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3283@kidzee.com",
                            "Mobile_No": "9479647323",
                            "Phone_No1": "9479647323",
                            "Phone_No2": "",
                            "Latitude": "22.744108",
                            "Longitude": "77.736967"
                         }
                      },
                      {
                         "City_Name": "Katni"
                      },
                      {
                         "City_Name": "Khandwa",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4324",
                            "Franchisee_Name": "Kidzee Khandwa",
                            "Operating_Status": "A",
                            "Address1": "Kirti Kumar Rai,175,",
                            "Address2": "Mata Chauk,",
                            "Place": "Jaswadi Road, Vitthal Nagar Colony,",
                            "City_Id": "1324",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4324@kidzee.com",
                            "Mobile_No": "7000245699",
                            "Phone_No1": "0733-2984174",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Panna",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2083",
                            "Franchisee_Name": "Kidzee Panna MP",
                            "Operating_Status": "A",
                            "Address1": "Bajrang ward no 3, Ajaygarh road",
                            "Address2": "Near Geeta Petrol pump",
                            "Place": "Indrapuri colony",
                            "City_Id": "1326",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2083@kidzee.com",
                            "Mobile_No": "9981620465",
                            "Phone_No1": "9981620465",
                            "Phone_No2": "",
                            "Latitude": "24.728481",
                            "Longitude": "80.192672"
                         }
                      },
                      {
                         "City_Name": "Khajuraho",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-1968",
                            "Franchisee_Name": "Kidzee Khajuraho MP",
                            "Operating_Status": "A",
                            "Address1": "Kidzee, Vidyadhar Colony,",
                            "Address2": "",
                            "Place": "Vidyadhar Colony, Raj Nagar Bypass,",
                            "City_Id": "1330",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1968@kidzee.com",
                            "Mobile_No": "9755441111",
                            "Phone_No1": "9755441111",
                            "Phone_No2": "",
                            "Latitude": "24.86392",
                            "Longitude": "79.926266"
                         }
                      },
                      {
                         "City_Name": "Shivpuri",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2347",
                               "Franchisee_Name": "Kidzee Shivpuri MP",
                               "Operating_Status": "A",
                               "Address1": "KTM building,",
                               "Address2": "Shivpuri By-pass road,",
                               "Place": "Shivpuri By-pass road,",
                               "City_Id": "1364",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2347@kidzee.com",
                               "Mobile_No": "9993548411",
                               "Phone_No1": "9993548411",
                               "Phone_No2": "",
                               "Latitude": "25.435787",
                               "Longitude": "77.665066"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5730",
                               "Franchisee_Name": "Kidzee Shivpuri",
                               "Operating_Status": "A",
                               "Address1": "Fatehpur",
                               "Address2": "Tongra -Piparsama Road, Near Mahadev ware house",
                               "Place": "Tongra",
                               "City_Id": "1364",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5730@kidzee.com",
                               "Mobile_No": "9329630500",
                               "Phone_No1": "9329630500",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-3622",
                               "Franchisee_Name": "Kidzee Khinni Naka Shivpuri",
                               "Operating_Status": "A",
                               "Address1": "Circular Road",
                               "Address2": "Near forset office",
                               "Place": "Shivpuri ,Khinni Nakka",
                               "City_Id": "1364",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3622@kidzee.com",
                               "Mobile_No": "7697701511",
                               "Phone_No1": "7697701511",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Mandsaur"
                      },
                      {
                         "City_Name": "Khurai"
                      },
                      {
                         "City_Name": "Neemuch"
                      },
                      {
                         "City_Name": "Bagh,dhar"
                      },
                      {
                         "City_Name": "Kukshi"
                      },
                      {
                         "City_Name": "Sendhwa",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3577",
                            "Franchisee_Name": "Kidzee Sendhwa",
                            "Operating_Status": "A",
                            "Address1": "Plot No.98, Virdavan Dham colony,",
                            "Address2": "",
                            "Place": "Sendhwa, Niwali Road,",
                            "City_Id": "1430",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3577@kidzee.com",
                            "Mobile_No": "9425090771",
                            "Phone_No1": "07281-224331",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Nandyal"
                      },
                      {
                         "City_Name": "Guna"
                      },
                      {
                         "City_Name": "Betul",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3455",
                               "Franchisee_Name": "Kidzee Betul",
                               "Operating_Status": "A",
                               "Address1": "Housing Board Colony Nazul,",
                               "Address2": "Plot No.66, Ganesh Ward, Behind Axis Bank Civil Lines,",
                               "Place": "Betul Ganj,",
                               "City_Id": "1435",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3455@kidzee.com",
                               "Mobile_No": "9424431981",
                               "Phone_No1": "9340777603",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "A-W-A-1043",
                               "Franchisee_Name": "Ankurum Amla",
                               "Operating_Status": "A",
                               "Address1": "Shrimati Fatima Memorial Shikshan Samiti Amla",
                               "Address2": "Ward No 14, Bheemnagar",
                               "Place": "Amla ,Betul",
                               "City_Id": "1435",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "bankadv1@gmail.com",
                               "Mobile_No": "8305522244",
                               "Phone_No1": "8305522244",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Balaghat",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3871",
                               "Franchisee_Name": "Kidzee Balaghat",
                               "Operating_Status": "A",
                               "Address1": "Ward No 1,Nema colony",
                               "Address2": "Next to Govind Palace Lawn (Behind shivalaya Temple),Bhatera Road.",
                               "Place": "Balaghat",
                               "City_Id": "1438",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3871@kidzee.com",
                               "Mobile_No": "9713382203",
                               "Phone_No1": "7773060333",
                               "Phone_No2": "",
                               "Latitude": "21.821565",
                               "Longitude": "80.185967"
                            },
                            {
                               "Franchisee_Code": "A-W-A-1034",
                               "Franchisee_Name": "Ankurum Waraseoni",
                               "Operating_Status": "A",
                               "Address1": "Ward no. 6, Durgavati Nagar",
                               "Address2": "Lalbarra Road Waraseoni",
                               "Place": "Waraseoni",
                               "City_Id": "1438",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "dpcoachingbalaghat@gmail.com",
                               "Mobile_No": "9424665124",
                               "Phone_No1": "9424665124",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Khargone",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4169",
                            "Franchisee_Name": "Kidzee Khargone",
                            "Operating_Status": "A",
                            "Address1": "35, Nutan Nagar,",
                            "Address2": "",
                            "Place": "Khargone, Near- Swastik Hotel,",
                            "City_Id": "1440",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4169@kidzee.com",
                            "Mobile_No": "9806714135",
                            "Phone_No1": "",
                            "Phone_No2": "",
                            "Latitude": "21.824036",
                            "Longitude": "75.620797"
                         }
                      },
                      {
                         "City_Name": "Dhamnod"
                      },
                      {
                         "City_Name": "Pandhana"
                      },
                      {
                         "City_Name": "Anjad"
                      },
                      {
                         "City_Name": "Rajnandgaon"
                      },
                      {
                         "City_Name": "Manawar"
                      },
                      {
                         "City_Name": "Maheshwar"
                      },
                      {
                         "City_Name": "Shahdol",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3839",
                            "Franchisee_Name": "Kidzee Shahdol",
                            "Operating_Status": "A",
                            "Address1": "Infront of Irrgation Department,",
                            "Address2": "Police Line Road,",
                            "Place": "Shahdol,",
                            "City_Id": "1498",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3839@kidzee.com",
                            "Mobile_No": "7389070446",
                            "Phone_No1": "9174535598",
                            "Phone_No2": "",
                            "Latitude": "23.30529",
                            "Longitude": "81.35871"
                         }
                      },
                      {
                         "City_Name": "Parasia",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2808",
                            "Franchisee_Name": "Kidzee Parasia  Chindwara",
                            "Operating_Status": "A",
                            "Address1": "Happy Children Education Society,  Khirasodh,",
                            "Address2": "Chindwara,",
                            "Place": "Parasia Chindwara,",
                            "City_Id": "1508",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2808@kidzee.com",
                            "Mobile_No": "9977225555",
                            "Phone_No1": "9977225555",
                            "Phone_No2": "",
                            "Latitude": "22.193199",
                            "Longitude": "78.756015"
                         }
                      },
                      {
                         "City_Name": "Bareli",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2487",
                            "Franchisee_Name": "Kidzee Bareli Raisen MP",
                            "Operating_Status": "A",
                            "Address1": "Ward No.4, JJ Road P.H.No-30,",
                            "Address2": "Tehsil- Bareily,",
                            "Place": "Bareli Raisen,",
                            "City_Id": "1523",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2487@kidzee.com",
                            "Mobile_No": "9407267700",
                            "Phone_No1": "9407267700",
                            "Phone_No2": "",
                            "Latitude": "22.994398",
                            "Longitude": "78.230975"
                         }
                      },
                      {
                         "City_Name": "Harda",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-4188",
                               "Franchisee_Name": "Kidzee Timarni",
                               "Operating_Status": "A",
                               "Address1": "Street Number-2, Rewapuram,",
                               "Address2": "Joshi Colony,",
                               "Place": "Timarni, Rahatgaon Road,",
                               "City_Id": "1539",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4188@kidzee.com",
                               "Mobile_No": "9826702035",
                               "Phone_No1": "9826-702036",
                               "Phone_No2": "",
                               "Latitude": "22.36804",
                               "Longitude": "77.2292"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4188",
                               "Franchisee_Name": "Kidzee Timarni",
                               "Operating_Status": "A",
                               "Address1": "Street Number-2, Rewapuram,",
                               "Address2": "Joshi Colony,",
                               "Place": "Timarni, Rahatgaon Road,",
                               "City_Id": "1539",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4188@kidzee.com",
                               "Mobile_No": "9826702035",
                               "Phone_No1": "9826-702036",
                               "Phone_No2": "",
                               "Latitude": "22.36804",
                               "Longitude": "77.2292"
                            }
                         ]
                      },
                      {
                         "City_Name": "Sidhi"
                      },
                      {
                         "City_Name": "Berasia"
                      },
                      {
                         "City_Name": "ASHOK NAGAR",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3228",
                               "Franchisee_Name": "Kidzee Ashok Nagar -MP",
                               "Operating_Status": "A",
                               "Address1": "Ward No-22, Behin Life Line Hospital,",
                               "Address2": "Mahavir Colony,",
                               "Place": "Ashok Nagar, Behind Lifeline Hospital",
                               "City_Id": "1588",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3228@kidzee.com",
                               "Mobile_No": "8224848222",
                               "Phone_No1": "07543-225422",
                               "Phone_No2": "",
                               "Latitude": "24.58188728783005",
                               "Longitude": "77.73198674609375",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3228/91/ca4ff03d_e9f4_40fa_853e_af5876a9cb12.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3228/91/6f70cfb1_7ce0_4988_bf9f_8253cfd44f47.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3228/91/f796129d_f7e1_4aaf_b80e_87467264d885.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3228/91/bd1fb0fc_8d38_40d3_b2e0_5083295aeaf7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3228/91/c8f8f4b1_d553_4904_bdbc_4f98edc811c6.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-5534",
                               "Franchisee_Name": "Shri Prachin Balaji Siksha Kalyan Avan Paryavaran ",
                               "Operating_Status": "A",
                               "Address1": "Vidisha Road,",
                               "Address2": "Balaji Institute building",
                               "Place": "Ashok nagar ",
                               "City_Id": "1588",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5534@kidzee.com",
                               "Mobile_No": "8224848222",
                               "Phone_No1": "8224848222",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Nowgong",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3328",
                            "Franchisee_Name": "Kidzee Nowgong",
                            "Operating_Status": "A",
                            "Address1": "Bhatt Ka Bangla, Ward No-07,",
                            "Address2": "Front Of Khajuraho Tube Bells,",
                            "Place": "Nowgong,",
                            "City_Id": "1595",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3328@kidzee.com",
                            "Mobile_No": "9425144901",
                            "Phone_No1": "9425144901",
                            "Phone_No2": "",
                            "Latitude": "25.062253",
                            "Longitude": "79.439662"
                         }
                      },
                      {
                         "City_Name": "Mhow"
                      },
                      {
                         "City_Name": "BARWANI"
                      },
                      {
                         "City_Name": "BIJURI"
                      },
                      {
                         "City_Name": "Sheopur",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3656",
                            "Franchisee_Name": "Kidzee Sheopur",
                            "Operating_Status": "A",
                            "Address1": "C-4, Maduvan Nagar",
                            "Address2": "Near Bypass Road",
                            "Place": "Sheopur ,Sheopur By pass Road",
                            "City_Id": "1653",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3656@kidzee.com",
                            "Mobile_No": "9425430029",
                            "Phone_No1": "9425430029",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Datia"
                      },
                      {
                         "City_Name": "GADARWARA"
                      },
                      {
                         "City_Name": "BANKHEDI"
                      },
                      {
                         "City_Name": "Seoni",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3938",
                            "Franchisee_Name": "Kidzee Seoni",
                            "Operating_Status": "A",
                            "Address1": "New Archipuram Colony,",
                            "Address2": "Beside Yash Hospital",
                            "Place": "Barghat Road ,Archi puram Colony",
                            "City_Id": "1840",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3938@kidzee.com",
                            "Mobile_No": "9131307279",
                            "Phone_No1": "9131307279",
                            "Phone_No2": "",
                            "Latitude": "22.088884",
                            "Longitude": "79.560155"
                         }
                      },
                      {
                         "City_Name": "Burhar",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3539",
                            "Franchisee_Name": "Kidzee Burhar",
                            "Operating_Status": "A",
                            "Address1": "H.No.84, Ward No.14, Behind Chamaria Petrol Pump,",
                            "Address2": "Adarsh Colony,",
                            "Place": "Burhar, Behind-Chamaria Petrol pump,",
                            "City_Id": "1842",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3539@kidzee.com",
                            "Mobile_No": "8962329200",
                            "Phone_No1": "9826116095",
                            "Phone_No2": "",
                            "Latitude": "23.207067",
                            "Longitude": "81.532248"
                         }
                      },
                      {
                         "City_Name": "Maksi ",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4233",
                            "Franchisee_Name": "Kidzee Maksi",
                            "Operating_Status": "A",
                            "Address1": "16, Ujjain Road,",
                            "Address2": "Ward 6, 4, Tehsil Shajapur, District Shajapur,",
                            "Place": "Maksi, Behind-Vrindavan Heights,",
                            "City_Id": "1843",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4233@kidzee.com",
                            "Mobile_No": "7987239375",
                            "Phone_No1": "07363-233431",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Maihar"
                      },
                      {
                         "City_Name": "Pipariya",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4426",
                            "Franchisee_Name": "Kidzee Pipariya",
                            "Operating_Status": "A",
                            "Address1": "Cement Road Behind Punjab National Bank",
                            "Address2": "",
                            "Place": "Pipariya ,",
                            "City_Id": "1857",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4426@kidzee.com",
                            "Mobile_No": "9977778885",
                            "Phone_No1": "9977778885",
                            "Phone_No2": "",
                            "Latitude": "22.7565066",
                            "Longitude": "78.3492458"
                         }
                      },
                      {
                         "City_Name": "Katangi",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4447",
                            "Franchisee_Name": "KIDZEE KATANGI",
                            "Operating_Status": "A",
                            "Address1": "Ward No-07, Behind Krishna Temple,",
                            "Address2": "Jabalpur Road, Dist.Balaghat,",
                            "Place": "Katangi, Behind Krishna Temple,",
                            "City_Id": "1858",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4447@kidzee.com",
                            "Mobile_No": "9691235363",
                            "Phone_No1": "9691235363",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Indargarh"
                      },
                      {
                         "City_Name": "Damoh",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-4485",
                               "Franchisee_Name": "Harsh Education and Welfare Society",
                               "Operating_Status": "A",
                               "Address1": "Harsh Computer and Welfare Society,",
                               "Address2": "",
                               "Place": "Near-State Bank, Main Road Patharia,",
                               "City_Id": "1873",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4485@kidzee.com",
                               "Mobile_No": "8770268540",
                               "Phone_No1": "8770268540",
                               "Phone_No2": "",
                               "Latitude": "23.89938944267904",
                               "Longitude": "79.19405043125153",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2287674/10854/045b2fac_96fb_4700_960a_5f6cf102d005.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2287674/10854/0f7b9ef4_48fc_48df_a3e5_6d691d5bbb06.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-5062",
                               "Franchisee_Name": "Kidzee Damoh",
                               "Operating_Status": "A",
                               "Address1": "Khasra No. 2/14, Chopra raiyatwari,",
                               "Address2": "Opposit Polytechnic College, Beside SBI raja patna branch, Jabalpur road",
                               "Place": "Damoh ,Opposit Polytechnic College,",
                               "City_Id": "1873",
                               "State_Id": "22",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5062@kidzee.com",
                               "Mobile_No": "8815332933",
                               "Phone_No1": "9826027412",
                               "Phone_No2": "",
                               "Latitude": "23.48414",
                               "Longitude": "79.27013"
                            }
                         ]
                      },
                      {
                         "City_Name": "SEMARIYA",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4467",
                            "Franchisee_Name": "Kidzee Semariya Rewa",
                            "Operating_Status": "A",
                            "Address1": "Khashra Number-238/5/K/5/Man-419/161 J /335,",
                            "Address2": "Rakwa Number-0007,",
                            "Place": "Semariya,",
                            "City_Id": "1881",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4467@kidzee.com",
                            "Mobile_No": "9993439350",
                            "Phone_No1": "9993439350",
                            "Phone_No2": "",
                            "Latitude": "24.790078",
                            "Longitude": "81.145984"
                         }
                      },
                      {
                         "City_Name": "Udaipura",
                         "Franchisee": {
                            "Franchisee_Code": "A-W-A-1007",
                            "Franchisee_Name": "ANKURUM UDAIPURA",
                            "Operating_Status": "A",
                            "Address1": "Taran Bhawan,",
                            "Address2": "Prem Nagar Colony, District-Raisen,",
                            "Place": "Udaipura,",
                            "City_Id": "1909",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "akshayjain01998@gmail.com",
                            "Mobile_No": "8982855344",
                            "Phone_No1": "8982855344",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Nisarpur",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-5733",
                            "Franchisee_Name": "Kidzee Nisarpur",
                            "Operating_Status": "A",
                            "Address1": "Ind front of the govt . boys high school",
                            "Address2": "",
                            "Place": "kadmal road Nisarpur",
                            "City_Id": "1915",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5733@kidzee.com",
                            "Mobile_No": "9893857655",
                            "Phone_No1": "9893857655",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Sehore"
                      },
                      {
                         "City_Name": "Mandideep",
                         "Franchisee": {
                            "Franchisee_Code": "A-W-A-1031",
                            "Franchisee_Name": "Ankurum Mandideep",
                            "Operating_Status": "A",
                            "Address1": "House No. 61 Ward No. 23 (New)",
                            "Address2": "Near Railway station, Dahod Road, Dist. Raisen",
                            "Place": "Mandideep ,Near Railway Station",
                            "City_Id": "1931",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "arvinds135@gmail.com",
                            "Mobile_No": "9826386680",
                            "Phone_No1": "9826386680",
                            "Phone_No2": "",
                            "Latitude": "23.091981",
                            "Longitude": "77.510284"
                         }
                      },
                      {
                         "City_Name": "Itarsi",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4971",
                            "Franchisee_Name": "Kidzee Itarsi",
                            "Operating_Status": "A",
                            "Address1": "Near Harsangat Gurudwara,",
                            "Address2": "MalviGanj Itarsi ( RNT college campus )",
                            "Place": "Itarsi ,Near Harsangat Gurudwara",
                            "City_Id": "1934",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4971@kidzee.com",
                            "Mobile_No": "7000335988",
                            "Phone_No1": "7000335988",
                            "Phone_No2": "",
                            "Latitude": "22.618315",
                            "Longitude": "77.760732"
                         }
                      },
                      {
                         "City_Name": "Niwari",
                         "Franchisee": {
                            "Franchisee_Code": "A-W-A-1062",
                            "Franchisee_Name": "Ankurum  Niwari By pass road",
                            "Operating_Status": "A",
                            "Address1": "Divya Sanskar Sikshan Sansthan",
                            "Address2": "Chaturvedi Complex By Pass Road, Dist Tikamgad",
                            "Place": "Niwari bypass road",
                            "City_Id": "1945",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "deepaknikhara1971@gmail.com",
                            "Mobile_No": "9893679873",
                            "Phone_No1": "9425714344",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Pichhore"
                      },
                      {
                         "City_Name": "Pithampur",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4184",
                            "Franchisee_Name": "Kidzee Pithampur",
                            "Operating_Status": "A",
                            "Address1": "House No.C.L. 347, Housing Board Colony, Pithampur",
                            "Address2": "",
                            "Place": "Pithampur, Sector-1,",
                            "City_Id": "1953",
                            "State_Id": "22",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4184@kidzee.com",
                            "Mobile_No": "9098388774",
                            "Phone_No1": "072921111",
                            "Phone_No2": "",
                            "Latitude": "22.601082",
                            "Longitude": "75.685308"
                         }
                      },
                      {
                         "City_Name": "Amla"
                      }
                   ]
                },
                {
                   "State_Name": "Maharashtra",
                   "City": [
                      {
                         "City_Name": "Ahmednagar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2929",
                               "Franchisee_Name": "Little Angel Educational Center-Ahmednagar",
                               "Operating_Status": "A",
                               "Address1": "Plot No-122, Survey No-87-A, RIddhi Siddhi Colony",
                               "Address2": "Near Police Chowky,",
                               "Place": "Gulmohar Road Ahmednagar, Near Gulomhor Hotel",
                               "City_Id": "40",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2929@kidzee.com",
                               "Mobile_No": "9890705575",
                               "Phone_No1": "02412343444",
                               "Phone_No2": "",
                               "Latitude": "19.120998",
                               "Longitude": "74.742508"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3351",
                               "Franchisee_Name": "Kidzee Ahmednagar",
                               "Operating_Status": "A",
                               "Address1": "Plot No.113/6,",
                               "Address2": "Station Road, ",
                               "Place": "Ahmednagar,",
                               "City_Id": "40",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3351@kidzee.com",
                               "Mobile_No": "9226799184",
                               "Phone_No1": "9226799184",
                               "Phone_No2": "",
                               "Latitude": "19.075197969908903",
                               "Longitude": "74.73424668222579",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1479242/11071/e990ed72_e00f_4c81_a85e_6d1b05bb0fc0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1479242/11071/50a28c1b_70b1_408a_8ef0_00a98f41a94f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1479242/11071/1b70a6cf_f3ee_4d82_a21d_fe6094f76b96.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1479242/11071/e3b3646a_0698_477d_8a27_1a1c94539b4d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1479242/11071/7fa945dd_7171_48f3_a614_81a57cf295b0.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-B-U-886",
                               "Franchisee_Name": "Kidzee-Solapur Road, Ahmednagar",
                               "Operating_Status": "A",
                               "Address1": "C/O, Bhausaheb Kanade, Kanade Mala,",
                               "Address2": "Behind-Market Yard,",
                               "Place": "Solapur Road,",
                               "City_Id": "40",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee886@kidzee.com",
                               "Mobile_No": "9226799184",
                               "Phone_No1": "9226799184",
                               "Phone_No2": "",
                               "Latitude": "19.08482106339942",
                               "Longitude": "74.74861580760194",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1082/10557/38189a4a_2199_4ee7_8bd4_d44b6b061675.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1082/10557/0b176d44_2e29_4eb4_9afe_610d1ba4a68c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1082/10557/c0de21b7_dc74_4bb7_84c6_3209ff69afaa.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-4566",
                               "Franchisee_Name": "Kidzee Bhushan Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot no 53, Near Mahalaxmi Gas Agency,",
                               "Address2": "Near Durga Heights building, Link Road, Bhushan Nagar",
                               "Place": "Bhushan Nagar ,Near Mahalaxmi Gas Agency ",
                               "City_Id": "40",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4566@kidzee.com",
                               "Mobile_No": "9156413513",
                               "Phone_No1": "9545521545",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5443",
                               "Franchisee_Name": "Kidzee Ahmednagar",
                               "Operating_Status": "A",
                               "Address1": "Fulari Mala Near Essar Petrol pump",
                               "Address2": "Plot No-176/13",
                               "Place": "Balika Ashram Road",
                               "City_Id": "40",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5443@kidzee.com",
                               "Mobile_No": "9226799184",
                               "Phone_No1": "9226799184",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Ambernath / Ambarnath"
                      },
                      {
                         "City_Name": "Amravati",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2440",
                               "Franchisee_Name": "Kidzee Amravati",
                               "Operating_Status": "A",
                               "Address1": "43-Shramsaflya Colony VMV Walgaon,",
                               "Address2": "",
                               "Place": "Katora Naka,",
                               "City_Id": "71",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2440@kidzee.com",
                               "Mobile_No": "7972361166",
                               "Phone_No1": "9890940439",
                               "Phone_No2": "",
                               "Latitude": "20.937424",
                               "Longitude": "77.779551"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4303",
                               "Franchisee_Name": "Kidzee Gawande  Layout",
                               "Operating_Status": "A",
                               "Address1": "Patel Nagar,",
                               "Address2": "Ground Floor,",
                               "Place": "Gawande Layout,",
                               "City_Id": "71",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4303@kidzee.com",
                               "Mobile_No": "8625803407",
                               "Phone_No1": "721-2578419",
                               "Phone_No2": "",
                               "Latitude": "20.901972",
                               "Longitude": "77.744785"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4939",
                               "Franchisee_Name": "Kidzee Warud",
                               "Operating_Status": "A",
                               "Address1": "Plot No-02, Saneguruji Colony,",
                               "Address2": "Multai Road,",
                               "Place": "Warud, Sane Guruji Colony,",
                               "City_Id": "71",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4939@kidzee.com",
                               "Mobile_No": "7972651636",
                               "Phone_No1": "9850229275",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4454",
                               "Franchisee_Name": "Kidzee Camp Amravati",
                               "Operating_Status": "A",
                               "Address1": "Plot No-10/6,",
                               "Address2": "Sheet No-42, Mouje Camp,",
                               "Place": "Camp Amravati,",
                               "City_Id": "71",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4454@kidzee.com",
                               "Mobile_No": "9423124304",
                               "Phone_No1": "8888-253253",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": "",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2221323/10899/6382325a_18fe_4d7a_acb2_5bf6517e749b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2221323/10899/4647ad97_f633_44a3_a93d_f26616f3ebb4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2221323/10899/f3299af9_2206_4a86_bbfd_f76d0e7048da.jpg"
                                  }
                               ]
                            }
                         ]
                      },
                      {
                         "City_Name": "Aurangabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2968",
                               "Franchisee_Name": "Kidzee  Sai nagar ,Cidco",
                               "Operating_Status": "A",
                               "Address1": "House No-1176,",
                               "Address2": "",
                               "Place": "Sai Nagar, Cidco-N-6,",
                               "City_Id": "92",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2968@kidzee.com",
                               "Mobile_No": "9423778738",
                               "Phone_No1": "0240-2339693",
                               "Phone_No2": "",
                               "Latitude": "19.88431",
                               "Longitude": "75.360066",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1245090/10727/581deffd_eecb_416e_b81f_df4e02701dc5.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1245090/10727/5548d0a1_6c5e_4ed2_bb05_be048ecd7d5f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1245090/10727/0191344f_42ad_4f06_bf9e_790ea1e634dd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1245090/10727/36e62157_a0d8_46f4_b0c4_4ad9ee21e67d.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-4973",
                               "Franchisee_Name": "Kidzee Jatwada Road",
                               "Operating_Status": "A",
                               "Address1": "Flat No-A1, Rameshwar Nagar,",
                               "Address2": "Honaji Nagar,",
                               "Place": "Jatwada Road, Near- Harsul Lake,",
                               "City_Id": "92",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4973@kidzee.com",
                               "Mobile_No": "9647761333",
                               "Phone_No1": "9647761333",
                               "Phone_No2": "",
                               "Latitude": "19.91612",
                               "Longitude": "75.34224"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4097",
                               "Franchisee_Name": "Kidzee Kotwalpura",
                               "Operating_Status": "A",
                               "Address1": "Indira 987 Khadkeshwar,",
                               "Address2": "",
                               "Place": "Kotwalpura ,Mill corner,",
                               "City_Id": "92",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4097@kidzee.com",
                               "Mobile_No": "9860350237",
                               "Phone_No1": "024011111",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Barshi"
                      },
                      {
                         "City_Name": "Beed",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3868",
                               "Franchisee_Name": "Kidzee Beed",
                               "Operating_Status": "A",
                               "Address1": "Pingle Nagar",
                               "Address2": "Dhanora road,Asha Cineplex",
                               "Place": "KSK College Road ,Near Asha Cineplex ",
                               "City_Id": "127",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3868@kidzee.com",
                               "Mobile_No": "9420423030",
                               "Phone_No1": "02442-227022",
                               "Phone_No2": "",
                               "Latitude": "18.984934636510804",
                               "Longitude": "75.74324854177246",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1976330/11014/68326de9_92a1_4263_bca3_47c3a0ea1134.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-4143",
                               "Franchisee_Name": "Kidzee Jalna Road Beed",
                               "Operating_Status": "A",
                               "Address1": "D-210246,",
                               "Address2": "Mathura Complex",
                               "Place": "Jalna Road, Beed,",
                               "City_Id": "127",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4143@kidzee.com",
                               "Mobile_No": "7796775577",
                               "Phone_No1": "7796775577",
                               "Phone_No2": "",
                               "Latitude": "19.000706501137223",
                               "Longitude": "75.75570023785872",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1995271/10884/6232ca14_f361_4161_8d66_f74dde229e6a.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-5460",
                               "Franchisee_Name": "Kidzee Ankush Nagar , Beed",
                               "Operating_Status": "A",
                               "Address1": "Near Sai Pandhari Lawns",
                               "Address2": "Old Charatha Road",
                               "Place": "Ankush Nagar , Beed",
                               "City_Id": "127",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "dake.vidya@gmail.com",
                               "Mobile_No": "7387060317",
                               "Phone_No1": "7387060317",
                               "Phone_No2": "",
                               "Latitude": "18.9847955",
                               "Longitude": "75.730789"
                            }
                         ]
                      },
                      {
                         "City_Name": "Pune",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-B-U-569",
                               "Franchisee_Name": "Kidzee - Pimple Saudagar - Pune",
                               "Operating_Status": "A",
                               "Address1": "Kidzee -Pimple Saudagar,",
                               "Address2": "Near-Govind Garden Restaurant,",
                               "Place": "Pimple Saudagar, Near-Govind Garden,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee569@kidzee.com",
                               "Mobile_No": "9881363103",
                               "Phone_No1": "9881363103",
                               "Phone_No2": "",
                               "Latitude": "18.593469",
                               "Longitude": "73.792911"
                            },
                            {
                               "Franchisee_Code": "W-B-U-674",
                               "Franchisee_Name": "KIDZEE-PIMPRI, PUNE",
                               "Operating_Status": "A",
                               "Address1": "Tarangan, 4/A, P.C.M.C Colony,",
                               "Address2": "Ajmer Colony,",
                               "Place": "Near Ajmera School,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee674@kidzee.com",
                               "Mobile_No": "9372104355",
                               "Phone_No1": "9372104355",
                               "Phone_No2": "",
                               "Latitude": "18.629781",
                               "Longitude": "73.799709"
                            },
                            {
                               "Franchisee_Code": "W-B-U-674",
                               "Franchisee_Name": "KIDZEE-PIMPRI, PUNE",
                               "Operating_Status": "A",
                               "Address1": "Tarangan, 4/A, P.C.M.C Colony,",
                               "Address2": "Ajmer Colony,",
                               "Place": "Near Ajmera School,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee674@kidzee.com",
                               "Mobile_No": "9372104355",
                               "Phone_No1": "9372104355",
                               "Phone_No2": "",
                               "Latitude": "18.629781",
                               "Longitude": "73.799709"
                            },
                            {
                               "Franchisee_Code": "W-R-S-2229",
                               "Franchisee_Name": "Kidzee Sadesatra Nali,Hadapsar Pune",
                               "Operating_Status": "A",
                               "Address1": "Survey number 209",
                               "Address2": "Indrayani Nivrutti Pravah, Bhagirathinagar,",
                               "Place": "Sadesatra Nali, Near Sanjeevani Hospital",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2229@kidzee.com",
                               "Mobile_No": "9028033194",
                               "Phone_No1": "9028033194",
                               "Phone_No2": "",
                               "Latitude": "18.52043",
                               "Longitude": "73.856744"
                            },
                            {
                               "Franchisee_Code": "W-R-S-2242",
                               "Franchisee_Name": "Kidzee Handewadi Pune",
                               "Operating_Status": "A",
                               "Address1": "Survey No 63, Near Arv Royale",
                               "Address2": "Near Simplicity Society",
                               "Place": "Handewadi Road, Arv Royale",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2242@kidzee.com",
                               "Mobile_No": "8793300600",
                               "Phone_No1": "9574861058",
                               "Phone_No2": "",
                               "Latitude": "18.47617442308318",
                               "Longitude": "73.93164848975754"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2353",
                               "Franchisee_Name": "Kidzee Charoli",
                               "Operating_Status": "A",
                               "Address1": "Gate no 185,",
                               "Address2": "Opposite Utsav Homes,  Airport road,",
                               "Place": "Charoli ,Opp Utsav Homes",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2353@kidzee.com",
                               "Mobile_No": "8379000055",
                               "Phone_No1": "8379000055",
                               "Phone_No2": "",
                               "Latitude": "18.621868",
                               "Longitude": "73.857008"
                            },
                            {
                               "Franchisee_Code": "W-R-S-2267",
                               "Franchisee_Name": "Kidzee  Sinhagad road  Pune",
                               "Operating_Status": "A",
                               "Address1": "GANGA BUNGLOW,DHANLAXMI SOCIETY,S NO 16/1, 17/3",
                               "Address2": "PLOT NO 13, SUNCITY ROAD",
                               "Place": "SINHAGAD ROAD",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2267@kidzee.com",
                               "Mobile_No": "9822095398",
                               "Phone_No1": "7350022555",
                               "Phone_No2": "",
                               "Latitude": "18.47773",
                               "Longitude": "73.81991",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10579/59174669_0492_4a59_b00f_5aef99848c1e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10579/b0ed8890_e9e9_4ae4_bd69_5a6f91efafb3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10579/c80f5afa_2768_4dbe_8091_794f9cc02af6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10579/bfe6312d_d6ad_428a_ba69_2b3591b6a759.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10579/93eda533_dbc9_424c_b504_16b08d604b89.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/41a4daba_a6ec_4890_ac30_086d63742a4c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/2e1351bd_e7bd_4144_9c2b_87f56c65e33c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/445db10d_ad2d_4b6f_817f_16514fbbf380.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/a202a7b5_35e8_4283_ab70_936d4eacbefe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/d159e285_4fc6_4c32_a992_d7c667370790.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/f543e61e_a637_408c_ac48_992faa6bd90b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/d32e1b89_9ec3_4238_adcc_363be6e1861a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/189cde0e_c11a_4ce5_98e0_8ba25c206747.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/87f324a3_b990_4e69_a3ef_917f49068541.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/2d469c22_fb0d_4313_af0b_7b4e9375d3b1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/a11b2793_9c81_4953_9005_1a798b76db4a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/aa634ccf_715d_4bbf_8092_0d5d19e76435.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/96c338b1_d533_4478_b3c7_d0521b88d893.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/36efd8a4_7953_4bf8_af58_a62b664e7ae6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10581/3d1c28d4_3d0b_4c93_be69_f503c655955b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10582/71df1692_742a_4616_b8aa_5ee39deba20c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10582/2c51b844_656e_4c55_b6f4_ccf872f1b7b2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10582/ceb31605_a152_429b_87c6_364cf87d80c7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10582/933e582d_c3bf_490d_9625_16c6021c7c21.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10582/7df75d57_6533_4a72_9ec5_70021ddf3996.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10582/20ddeeb9_4ead_4043_ae37_5438aa7a3871.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10591/ec6a52f0_7554_4ca4_9893_e9858f1ff9c6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10591/3dffadb7_b45e_4b72_8f32_5b2b35c46940.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10591/a047c0dd_ec6a_48d4_9654_81e1d3950cb2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10591/fb8ccade_8984_4a82_a0d1_3efdfa0f5e8f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10591/3f648c6e_40bb_4a31_a48c_37a57aec8f68.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10591/3c421a93_fece_40e2_81e1_231cae6bb029.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10591/40a80ec7_1849_42bc_8dee_3d3dc37d3634.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10591/a61b54ae_6c10_416a_802b_e0ab6bc1acf8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10592/94ff3a13_8ea1_43ab_a852_0549ce00dd09.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10592/b10c440a_cc57_4844_b294_5c39074661b6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10592/f18db44e_3861_46a2_8bf2_8b3fcd0c79c0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10592/0904d824_3e56_4ae9_bb86_8525395cacca.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10592/68a5365b_42dc_47aa_a690_4ecb49fb74c0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10592/21da6b10_ccd7_40e3_a10a_79e9ad844920.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10592/60ac68cc_e59c_480a_b8be_ca4a68ca940d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10593/2cdce32e_7be7_44de_bf0c_b5ffd9c5bffa.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10593/c1ef9ecf_0d3e_4c99_b66a_62c04b64dc69.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10593/ce0609bd_d763_4776_a1ac_7611a3d061ce.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10593/f9da97cd_b3d0_4f44_ba0e_33490e9f636c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10593/351c8476_8e1a_49aa_a077_567d15a2c8e7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10593/136ecd8b_14d0_40d6_8f22_ecfca48be78a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10593/56fb7dc7_7835_46f8_a87c_56ed67290d1c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10593/c817b3e5_e47d_4c49_900f_aea602654e2e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10593/edad6dc4_d4cb_4168_9c77_a411020fea60.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10598/48faeab9_f97d_4a5b_9a29_6914333c6edc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10598/200a8b0b_d258_416d_a194_724288a0cbe2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10598/2f50617d_8ff1_401d_8eb9_1d256f3d923b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10598/05b94bf1_7801_4de2_9f5c_654b286e2472.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10598/c3cfebaf_4ead_43d0_9473_0045c4cf3d72.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10598/c7ba24ae_41af_4dcf_bece_73b8ac4eab5a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10598/48d08e29_8a12_4040_aa3a_5ae6d6dfae5e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10599/d017b58d_d08e_4b7a_ac16_4f3fc6329071.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10599/0dbde32c_bde4_412a_bbac_4a382f3b2948.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10599/d787f30f_b0ba_444b_aab4_3f9d9abdf4ce.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10599/cad6b1bd_6e33_48ff_b9bc_abbd3ae6498a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10599/9f5a8571_5078_436e_b6e4_a31ca1df7d7a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10599/f29c3291_191e_432f_b7c4_426022aaf6fb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10599/4b42f467_62a9_44bb_b99c_cd787da69a42.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/dd42d67c_4c5e_461d_8402_cd764a329cd4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/faf895b5_c17a_4205_b5e4_653f46ce4102.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/c937a8af_e9a4_41dc_86c8_9d324f61ba74.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/7a954817_90e7_4076_a06d_715d6e9a76a8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/03bffcfa_bac5_4696_8d7f_2d132da1cdd1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/dd0d4876_e579_4574_adb7_1fb75b251be6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/47777f8e_6ae1_47bf_bc5d_f0cf30c521ce.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/be65612f_0897_47fd_8413_cb7f23adcf1d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/6304d791_6615_444f_a77f_f5327d3d0f3f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/0aca5c02_411f_4de1_86b0_124dad5b8c7c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10600/43fd19b3_21d9_4e12_add5_94d65a8cbe8e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/6ac5893d_4b88_4e5e_b3c7_9a4eb7181e2e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/a732a365_cb75_4808_9275_68c2652ac254.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/ff8e3f92_8d96_4710_97f8_346142b0c291.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/ddda3356_9543_426e_9f00_23dd20202fe3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/bd51809b_c2d7_4e4e_b5c4_44c908de6546.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/453a0aaf_e9f7_4895_bef8_41de1f33ad54.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/d85ef6b7_e54d_4054_8a27_8adeffab4554.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/4d4ccbb4_a008_4abc_99be_793cc6ea8bcd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/ab581917_e400_49ef_b726_4737c24e1e04.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/94a0e58b_0301_4e91_a84c_7b78337b3a12.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/3ace934c_ffb4_416b_a75c_6d0060c1a61f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/2b9c754b_9a49_4296_ae72_af13ad6f9364.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/255cc9de_3249_432a_99a1_f947938f65d7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10601/afc2acf8_7ab6_41c8_a7b4_6dc546c71f64.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10602/4caecfee_954a_405a_96f7_90d3da29dc41.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10602/1e70ad0f_7ca3_422c_93ee_3b2c8d6b6b88.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10602/3a0869b9_a4ee_4ea3_ac09_470f38189a65.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10602/05506ed5_c880_4e64_8db8_5545710b3bd7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10602/32c47520_b129_492f_bafb_a7603e25a91f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10602/8d567d39_ea61_4a12_9ba1_80899503262a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/e5e9945f_52f0_4fd4_93f4_0d97813c6404.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/24941ffe_74bf_4a12_913c_fa55dd4543b0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/02de1873_c651_453d_85ac_21ef80c703c6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/2e88d252_6efc_4140_871c_ef34c1fd0b89.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/03e2f612_38db_4b5b_b8fe_e7bc9f069897.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/2e9e439c_7687_4c5d_8476_a6916db3fb72.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/f6a80936_fcba_4467_9b49_ca1c577e3e0b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/79523942_a48c_4600_a04d_c0a1fc2bdc98.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/8e37dc37_6b64_45cc_94c6_c1b478189c2e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/28160b21_4ff4_4be7_aa66_09d840a1653b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/56065f47_ebfd_4c7a_a519_5bed133404cd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10603/744fa697_7f82_4543_9578_2e513ba404b7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/37cc30d6_4256_4f97_8afe_513fd523dc5a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/2fab2bff_df93_4cb0_a1d5_a28d5b9e7d97.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/eaa38b77_ff41_4d9d_abc0_83f5f51b1502.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/e6c11849_389c_4955_85d9_b647300b9382.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/54686e3e_6061_4395_bb01_8abcc78f152d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/eb317029_a682_411f_a1c4_c416d5bca65e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/34ac97b7_e3df_4085_87ca_5b2d89aafd5c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/2c422bf5_b442_4d6a_a0fd_a22a4e834d2f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/ea2b18a8_6e6c_4aa7_86cf_2b537b5655ca.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/f35915ec_ddfa_4cfb_bb7d_0fb122b429ef.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/cef22575_39e5_4679_a409_cc868a7a380c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10604/97f327b7_e85f_4c0a_9390_f7dc8b7cb89c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10605/47586198_624f_452d_8728_355a82127fd1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10605/187f297f_4104_4f2c_9d1a_35431f5f7ab0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10605/59ad1717_9f3b_4a3e_9b93_a6241eff6ccf.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10605/40410c78_a4b9_4fee_81b5_5ab992d51eab.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10605/fb2b4f8f_4dfe_4a5d_aeba_6dadd3201d1b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10605/48023e71_9f86_4d7f_aecb_7ffb9b832d11.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10605/d8fe0c13_6ada_45de_9bb4_16c8e4e72b29.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10605/2aad405f_69d6_4d20_9cb5_aad92aed6f82.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10605/22e3190c_a457_4885_9038_0c77635e6a20.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10605/82c40513_cd4a_474a_adcf_bf8e7c678a37.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10606/c22b60d4_24fe_444a_ad07_b4f22331f47c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10606/9683c34d_07d3_480f_9c31_97fcf99535a9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10606/51e42101_d743_4ede_a432_2b85eecdf700.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10606/766acd9f_f0a9_4f69_80f1_f639b025df43.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10606/7a895519_0c6b_47b3_b529_5d76ade7e8d6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10606/2b4e2ffc_3d9f_4bd3_805a_f057c7d0efce.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/764572/10606/1a8cb576_3b48_4d4a_a94d_6cbc0bc41aeb.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-2247",
                               "Franchisee_Name": "Kidzee Tingre Nagar, Vishrantwadi Road Pune",
                               "Operating_Status": "A",
                               "Address1": "Flat no 01,Ground floor, Bld Guru Meher",
                               "Address2": "Block Sector, Tingre Nagar Pune, Survey no 162/2 no",
                               "Place": "Adarsh Colony lane 5,City : Dhanori",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2247@kidzee.com",
                               "Mobile_No": "9921983423",
                               "Phone_No1": "9921983423",
                               "Phone_No2": "",
                               "Latitude": "18.575457",
                               "Longitude": "73.889567"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2584",
                               "Franchisee_Name": "Kidzee Katraj Pune",
                               "Operating_Status": "A",
                               "Address1": "Row House, Shriram Darshan-2, Opp Sawant Vihar,",
                               "Address2": "In Between Katraj Dairy & Manik Moti,",
                               "Place": "Katraj Ambegaon, Behind KK Travel office,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2584@kidzee.com",
                               "Mobile_No": "8855964004",
                               "Phone_No1": "8855964004 ",
                               "Phone_No2": "",
                               "Latitude": "18.52043",
                               "Longitude": "73.856744"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3175",
                               "Franchisee_Name": "Kidzee Indrayani Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot No-425, Sector-2,",
                               "Address2": "Bhosari, Pradhikaran,",
                               "Place": "Indrayani Nagar, Behind Police Colony",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3175@kidzee.com",
                               "Mobile_No": "7770060480",
                               "Phone_No1": "7770060480",
                               "Phone_No2": "",
                               "Latitude": "18.638387",
                               "Longitude": "73.84578"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3099",
                               "Franchisee_Name": "Kidzee - Dehu Road , Pune",
                               "Operating_Status": "A",
                               "Address1": "H.No.461, Vikash Nagar,",
                               "Address2": "Kiwale, Tal: Haveli",
                               "Place": "Dehu Road, Vikas Nagar",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3099@kidzee.com",
                               "Mobile_No": "9604696986",
                               "Phone_No1": "7040950001",
                               "Phone_No2": "",
                               "Latitude": "18.670063",
                               "Longitude": "73.731604",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/63/237fde2b_cf8e_46ab_a1f1_89a8fe9611fb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/63/24aab643_a129_4193_8965_3f53ec5f2deb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/63/6d3d49eb_5030_4e3c_8d50_d91d97232c14.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/63/8ccd5f37_45dc_4480_b853_1109bbdb4c6b.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/63/a060881d_0223_4770_ada1_7d0ed8bb6f71.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/63/b0007978_8886_4df7_900e_7ffdb07a44b5.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/63/33f52172_0786_4ccf_a898_08405661c18e.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/65/77399c27_2a1d_4d85_aac6_9af508e0e489.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/65/898dd2f3_e543_4bfb_91e4_d3e68c62595c.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/65/5a97e448_7fea_47a3_abd0_5b3baf0edac7.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/65/0d710d89_ac09_4509_ae2c_e0534dd8ce78.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3099/65/2babde1b_8f1a_4f19_b66e_5b94bdd431ea.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-S-3017",
                               "Franchisee_Name": "Kidzee Pashan",
                               "Operating_Status": "A",
                               "Address1": "Row House E 4",
                               "Address2": "Ojas Co-operative Housing Society,No 132/2",
                               "Place": "Baner- Pashan Link Road, Pashan",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3017@kidzee.com",
                               "Mobile_No": "9881255245",
                               "Phone_No1": "9881255245",
                               "Phone_No2": "",
                               "Latitude": "18.54163",
                               "Longitude": "73.80241"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3077",
                               "Franchisee_Name": "Kidzee Wagholi Pune",
                               "Operating_Status": "A",
                               "Address1": "Row House No C- 102,",
                               "Address2": "S.No.741/1+2, Plot No.9 & 10, Tal: Haveli",
                               "Place": "Wagholi ,Near JSPM college",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3077@kidzee.com",
                               "Mobile_No": "9881475852",
                               "Phone_No1": "9923-315587",
                               "Phone_No2": "",
                               "Latitude": "18.592617",
                               "Longitude": "74.003608"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3253",
                               "Franchisee_Name": "Kidzee Chakan",
                               "Operating_Status": "A",
                               "Address1": "C/o-Kakade  Automobiles, Ekta Nagar,",
                               "Address2": "Near-Swayamber Mangal Karyalay Pune Nashik Highway,",
                               "Place": "Chakan, Nr Swaymber Karylay",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3253@kidzee.com",
                               "Mobile_No": "9545631199",
                               "Phone_No1": "9545-631199",
                               "Phone_No2": "",
                               "Latitude": "18.77174582105",
                               "Longitude": "73.8692003488541",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1398609/10751/1b343e5d_ca30_4e0f_ad4c_309af7c01f8a.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-S-S-1709",
                               "Franchisee_Name": "Kidzee-Magarpatta Rd-Pune",
                               "Operating_Status": "A",
                               "Address1": "Kidzee, Bunglow No-3,",
                               "Address2": "",
                               "Place": "Magarpatta City, Accaia Garden-3",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1709@kidzee.com",
                               "Mobile_No": "7262921666",
                               "Phone_No1": "7262921666",
                               "Phone_No2": "",
                               "Latitude": "18.51617889181347",
                               "Longitude": "73.93232927361305",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/544434/10945/dc1bf7d2_a124_4c4c_aeff_a3206b94a963.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/544434/10945/5bab8bd9_a856_40d7_9729_5fd2a356a710.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/544434/10945/a23df8e5_4533_4d53_a350_ae28de45ff8f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/544434/10945/f817c836_8b34_42c0_b4cd_345e5a311a73.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/544434/10945/18557d27_439e_47a8_b2d5_1b90c4944885.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/544434/10945/46964cd4_b17f_4d02_b00b_c66f16eaa6af.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/544434/10945/b2201e04_711f_441d_bda1_ff2bc1c8fa94.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-S-C-1745",
                               "Franchisee_Name": "Kidzee-Bhosari",
                               "Operating_Status": "A",
                               "Address1": "Rajas Enclave, Sr.No-98/2, Plot No-11,",
                               "Address2": "Near-Jay Ganesh Samrajya, Guruvihar Colony, Pune -Nashik Road,",
                               "Place": "Bhosari ,Opp-Panjarpol,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1745@kidzee.com",
                               "Mobile_No": "8623000011",
                               "Phone_No1": "8623000011",
                               "Phone_No2": "",
                               "Latitude": "18.638529",
                               "Longitude": "73.847787"
                            },
                            {
                               "Franchisee_Code": "W-L-S-1839",
                               "Franchisee_Name": "Kidzee Wakad Kaspate Wasti",
                               "Operating_Status": "A",
                               "Address1": "Kidzee-Wakad Kaspate Wasti,",
                               "Address2": "Chilekar Lane-1, B/H Mankar Clinic,",
                               "Place": "Wakad Kaspate Wasti ,Nr-Chatarpati Chowk,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1839@kidzee.com",
                               "Mobile_No": "8668774623",
                               "Phone_No1": "8668774623",
                               "Phone_No2": "",
                               "Latitude": "18.590174",
                               "Longitude": "73.772791"
                            },
                            {
                               "Franchisee_Code": "W-S-S-1848",
                               "Franchisee_Name": "Kidzee-Pimple Gurav - Pune",
                               "Operating_Status": "A",
                               "Address1": "Suryamukhi Datt Mandir Lane, Nandanvan Colony,",
                               "Address2": "Opp. Katepuram Phase-II, Gate, Near Krishna Chowk,",
                               "Place": "Pimple Gurav, Suryamukhi Mandir,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1848@kidzee.com",
                               "Mobile_No": "9049840153",
                               "Phone_No1": "8007446644",
                               "Phone_No2": "",
                               "Latitude": "18.52043",
                               "Longitude": "73.856744"
                            },
                            {
                               "Franchisee_Code": "W-R-S-1857",
                               "Franchisee_Name": "KIDZEE MAITREE COMMERCE PVT LTD",
                               "Operating_Status": "A",
                               "Address1": "17/A ,Manjri Greens, Phase IV,  Opp. Manjri Stud Farm,",
                               "Address2": "Pune-Solapur Road Tal-Haveli, Dist-Pune,",
                               "Place": "Manjari, Near BPCL Petrol Pump,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1857@kidzee.com",
                               "Mobile_No": "7385282888",
                               "Phone_No1": "02066785145",
                               "Phone_No2": "",
                               "Latitude": "18.500183",
                               "Longitude": "73.968313"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2776",
                               "Franchisee_Name": "Kidzee-Dighi, Pune",
                               "Operating_Status": "A",
                               "Address1": "Sr. No.72/5,Sainik Colony,",
                               "Address2": "Sai Park, RD-1, Near To Vishal Nagari,",
                               "Place": "Dighi, Near Mamata Cholk,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2776@kidzee.com",
                               "Mobile_No": "9011497971",
                               "Phone_No1": "9922991992",
                               "Phone_No2": "",
                               "Latitude": "18.621979723311327",
                               "Longitude": "73.87025232275391"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2920",
                               "Franchisee_Name": "Kidzee Talegaon Pune",
                               "Operating_Status": "A",
                               "Address1": "Survey No- 52/87, Panchavati Colony,",
                               "Address2": "Plot No-43,",
                               "Place": "Talegaon, Behind Ganpati Mandir",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2920@kidzee.com",
                               "Mobile_No": "8446666131",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "18.52043",
                               "Longitude": "73.856744"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2944",
                               "Franchisee_Name": "Kidzee Dhanori, Pune",
                               "Operating_Status": "A",
                               "Address1": "Sr No.17/1C/1, Flat No-101,",
                               "Address2": "Lane-2, Janaki Niket,Taluka Haveli,",
                               "Place": "Dhanori,, Siddarth Nagar,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2944@kidzee.com",
                               "Mobile_No": "9158241508",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "18.598553757736216",
                               "Longitude": "73.90620008111"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2987",
                               "Franchisee_Name": "Kidzee Wagholi",
                               "Operating_Status": "A",
                               "Address1": "Kidzee School Building,",
                               "Address2": "Ganesh Nagar, Awalwadi Road,",
                               "Place": "Behind Bazartal Wagholi,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2987@kidzee.com",
                               "Mobile_No": "9665868554",
                               "Phone_No1": "020-1234567",
                               "Phone_No2": "",
                               "Latitude": "18.579305",
                               "Longitude": "73.982345"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3516",
                               "Franchisee_Name": "Kidzee Wadgaonsheri",
                               "Operating_Status": "A",
                               "Address1": "Kidzee-41/3/2, Sagar Industrial Estate,",
                               "Address2": "Tempo chowk, Opp-Anup Hospital,",
                               "Place": "Wadgaonsheri,Tempo Chowk,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3516@kidzee.com",
                               "Mobile_No": "9769975795",
                               "Phone_No1": "7083855100",
                               "Phone_No2": "",
                               "Latitude": "18.55248",
                               "Longitude": "73.92104",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/eb46a1ee_238e_4255_a6ab_a4b24c59eb09.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/bde7c82d_2224_4bf8_8cee_d578871e3737.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/42010cbb_9511_40e2_8ddd_2f2565d8f1ea.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/ddc643b1_1a46_4663_ae23_b8c3d3163f70.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/f2ed4968_f4f4_494e_af08_effe43aede90.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/8a4c9146_a01d_4527_a1eb_e77fbbb989b3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/591b74f4_c4f8_4b39_bfda_400f366a4a9f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/15eaafe7_1c91_497f_9385_f819ffeda838.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/4fcf929d_c397_4a05_b44f_daa340ee1f7b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/173e6e0b_dccf_4737_85af_776076debba3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/50d20ab6_fea4_46ce_89f6_16d1eb4eff39.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/a3bd8737_7662_4dd6_8e01_92b2eede17bb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/e559e701_51d2_402d_8506_af1f9d67fda9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1595913/10965/e182b5d7_aad4_4147_8055_8a2fbc990974.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-S-2086",
                               "Franchisee_Name": "Kidzee Aundh Pune",
                               "Operating_Status": "A",
                               "Address1": "Row House No- 2,",
                               "Address2": "Samar Paradise, D P Road,",
                               "Place": "Aundh, Behind- Subway,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2086@kidzee.com",
                               "Mobile_No": "8055755922",
                               "Phone_No1": "020-65323939",
                               "Phone_No2": "",
                               "Latitude": "18.52043",
                               "Longitude": "73.856744"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2816",
                               "Franchisee_Name": "Kidzee  Undri  Pune",
                               "Operating_Status": "A",
                               "Address1": "Manas Construction Bunglow, Holevasti,",
                               "Address2": "S.No.5/1, Opp Spices Restaurant,",
                               "Place": "Pune Undri, Near-Bishop School ,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2816@kidzee.com",
                               "Mobile_No": "9224389451",
                               "Phone_No1": "9224389451",
                               "Phone_No2": "",
                               "Latitude": "18.456275",
                               "Longitude": "73.910984"
                            },
                            {
                               "Franchisee_Code": "W-R-S-2718",
                               "Franchisee_Name": "Kidzee -Hinjewadi, Pune",
                               "Operating_Status": "A",
                               "Address1": "Phase 1,C1&2,Vasant Utsav Society",
                               "Address2": "Opposite Shell Petrol Pump",
                               "Place": "Hinjewadi, Phase-1, Opp.Shell Petrol Pump,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2718@kidzee.com",
                               "Mobile_No": "9922190298",
                               "Phone_No1": "-9527156521",
                               "Phone_No2": "",
                               "Latitude": "18.606869",
                               "Longitude": "73.723937"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2702",
                               "Franchisee_Name": "Kidzee - Kalepedal Road, Hadapsar",
                               "Operating_Status": "A",
                               "Address1": "Bunglow No. A -36,",
                               "Address2": "S.No.41,",
                               "Place": "Kalepedal Road, Hadapsar ,Nirmal Township,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2702@kidzee.com",
                               "Mobile_No": "9011118201",
                               "Phone_No1": "9923432164",
                               "Phone_No2": "",
                               "Latitude": "18.488722",
                               "Longitude": "73.937326"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3662",
                               "Franchisee_Name": "KIDZEE WAGHOLI, IVY ESTATE",
                               "Operating_Status": "A",
                               "Address1": "IVY Estate Villa, C-1,",
                               "Address2": "",
                               "Place": "WAGHOLI, IVY Estate, Near-Lexicon School,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3662@kidzee.com",
                               "Mobile_No": "9881475852",
                               "Phone_No1": "9881475852",
                               "Phone_No2": "",
                               "Latitude": "18.5832935935983",
                               "Longitude": "74.0050435066223",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3662/306/3ada18c2_3373_4764_8720_03e58e5c958f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3662/306/6e709684_8a0a_405d_89d7_7d3ef2560d96.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3662/306/c1a7a79a_7483_4747_899b_8296ced2c3fe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3662/306/6631da21_6a4c_478b_9810_8edcc4dbcb4f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3662/306/8e5efd9c_b95b_481d_a041_62e53d6621e3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3662/306/81b71019_5189_4daf_b08a_491467f02de4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3662/306/c5f4025b_405c_4445_b695_c4b3e158328f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3662/306/a6d8d10e_d94b_4405_91dd_4ae93a168795.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3662/306/2f669cf0_9183_4bc2_85b1_679e82f3a86d.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-3606",
                               "Franchisee_Name": "Kidzee Talegaon Station",
                               "Operating_Status": "A",
                               "Address1": "Banglow No 4,  Tulja bhawani Society,  Vatan Nagar",
                               "Address2": "Chakan Road,  Near Pratap Memorial Hospital,",
                               "Place": "Talegaon, Dabhade ,Opp Pratap Memorial Hospital",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3606@kidzee.com",
                               "Mobile_No": "9702717655",
                               "Phone_No1": "7057238891",
                               "Phone_No2": "",
                               "Latitude": "18.73394546863768",
                               "Longitude": "73.68712443418508",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3606/337/1e1f2ece_f47b_4c25_9a60_d576e75d707b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3606/337/da47e5c2_8777_4c55_bfdd_eecf3d77d595.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3606/337/c33b18cb_7aa8_42a4_88bf_8a06b5db6e4b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3606/337/f6757c49_787e_4065_9fc5_a119fb930098.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3606/337/ff2082f2_3c18_4211_8063_b1d8c430c0d0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3606/337/910dd3c1_4d29_4b47_9407_e9ce1b276e70.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3606/337/c11fcbaa_25b6_45e5_89e6_b1145b538522.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-S-3701",
                               "Franchisee_Name": "Kidzee Rahatani",
                               "Operating_Status": "A",
                               "Address1": "Poloce Colony, Near Nisarga Heritage,",
                               "Address2": "Opp Datta Mandir,",
                               "Place": "Rahatani ,Near Police colony ",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3701@kidzee.com",
                               "Mobile_No": "8007252450",
                               "Phone_No1": "8408070840",
                               "Phone_No2": "",
                               "Latitude": "18.6011400422479",
                               "Longitude": "73.7873178720474",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3701/344/73fc474b_a47e_4be0_8dfb_42589900c4ed.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3701/344/cdcccc83_b529_4e8d_9963_588ccdc45c69.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3701/344/11fc7f0e_7b39_4324_baba_a2a8bb19a3b8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3701/344/266e0a74_1f40_4d05_91be_794d5e1a9227.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-3784",
                               "Franchisee_Name": "Kidzee  Bibavewadi",
                               "Operating_Status": "A",
                               "Address1": "Sr.No. 669/2A/2B, Suvarna Nagari,",
                               "Address2": "Above Janseva Bank",
                               "Place": "Bibavewadi ,Above Janseva Bank ",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3784@kidzee.com",
                               "Mobile_No": "9049435143",
                               "Phone_No1": "020-65002607",
                               "Phone_No2": "",
                               "Latitude": "18.46632625",
                               "Longitude": "73.86690055",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1711666/10477/51580459_3457_456e_9c6b_e622f4d6c9cd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1711666/10477/43d5cab1_f1e7_4d2a_9c19_fd6883d0df17.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-3910",
                               "Franchisee_Name": "Kidzee Wakad-Chaudhari Park",
                               "Operating_Status": "A",
                               "Address1": "Om Ganesh Bungalow,",
                               "Address2": "B/h- Webster Society, Near Blue Bell Society",
                               "Place": "Wakad, Chaudhari Park.",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3910@kidzee.com",
                               "Mobile_No": "9823810153",
                               "Phone_No1": "9823810153",
                               "Phone_No2": "",
                               "Latitude": "18.606476",
                               "Longitude": "73.759474"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4124",
                               "Franchisee_Name": "Kidzee Karve Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot No-6/2/2, Denali,",
                               "Address2": "Yogiraj Park,",
                               "Place": "Karve Nagar,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4124@kidzee.com",
                               "Mobile_No": "8600639456",
                               "Phone_No1": "020-2324252",
                               "Phone_No2": "",
                               "Latitude": "18.487744270925813",
                               "Longitude": "73.82195860147476",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1976541/10661/31b8e33e_7343_48e6_8cb7_b75ad3e4ee35.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1976541/10661/715a84da_0465_439a_8703_b7f00cde7ff2.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-4114",
                               "Franchisee_Name": "Kidzee Wagholi",
                               "Operating_Status": "A",
                               "Address1": "Gat No.1424, Old Gat No-2410,",
                               "Address2": "Shivarkar Vasti, Lohegaon",
                               "Place": "Waagholi, Opp- Alfa Homes,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4114@kidzee.com",
                               "Mobile_No": "9922992697",
                               "Phone_No1": "020-1111111",
                               "Phone_No2": "",
                               "Latitude": "18.5855675",
                               "Longitude": "73.96974521",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1988762/10671/09af09e5_4adb_4dd5_9796_ede4755f06d9.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-3929",
                               "Franchisee_Name": "Kidzee Nigdi Yamunanagar",
                               "Operating_Status": "A",
                               "Address1": "Shri Krupa, Plot No: 216 and Sector No-21, Road,",
                               "Address2": "Location, Yamunanagar, Of Village,",
                               "Place": "Yamunanagar Nigdi, Near Triveni Nagr Garden,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3929@kidzee.com",
                               "Mobile_No": "9028026511",
                               "Phone_No1": "9028026511",
                               "Phone_No2": "",
                               "Latitude": "18.669612",
                               "Longitude": "73.783631",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1911361/10690/f1b297da_29d6_47d9_9e3f_44c7ec96159a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1911361/10690/e98a26b1_4d61_4ae6_b5c0_08c4425dfebe.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1911361/10690/c1cf1218_8819_4ff3_a2d9_0c309b2c46b8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1911361/10690/98575ce0_1a82_479e_9908_930ac86db2f0.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1911361/10690/2da2a5ee_ba98_4ef4_9749_b0fd06dca745.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-S-4084",
                               "Franchisee_Name": "Kidzee Keshav Nagar",
                               "Operating_Status": "A",
                               "Address1": "Row House Flat No. A2, Building No-A, Scheme-Anant,",
                               "Address2": "S.No- 8/2/8, Mundhwa,",
                               "Place": "Keshav Nagar, Near-Godrej Township,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4084@kidzee.com",
                               "Mobile_No": "9511664630",
                               "Phone_No1": "022111111",
                               "Phone_No2": "",
                               "Latitude": "18.534939",
                               "Longitude": "73.948757"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4228",
                               "Franchisee_Name": "Kidzee Kondwa",
                               "Operating_Status": "A",
                               "Address1": "Madhuram, S.No 65/B/2/28,",
                               "Address2": "Near-Salve Garden, Gangadham, Katraj Road,",
                               "Place": "Kondhwa Budruk, Kondhwa Budruk,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4228@kidzee.com",
                               "Mobile_No": "9665060513",
                               "Phone_No1": "8208-611398",
                               "Phone_No2": "",
                               "Latitude": "18.456380607669228",
                               "Longitude": "73.87897653052437",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2147260/10767/825c3d80_8087_4bcc_9f73_903c239a8119.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2147260/10767/b5c24b5d_86ef_4ff5_a085_7a79a4e16baa.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2147260/10767/393b581a_c1fe_4aed_b741_1816a63f6442.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2147260/10768/d20242ce_6dab_4764_92f3_7203c34ddcef.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-4479",
                               "Franchisee_Name": "Kidzee Kadachiwadi",
                               "Operating_Status": "A",
                               "Address1": "Chakan-Shrirapur Road, Village- Mauje,",
                               "Address2": "Kadachi Wadi, Tal-Khe,",
                               "Place": "Chakan Shikrapur Road,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4479@kidzee.com",
                               "Mobile_No": "9922400757",
                               "Phone_No1": "9922400757",
                               "Phone_No2": "",
                               "Latitude": "18.747622233766776",
                               "Longitude": "73.87778160640823"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4387",
                               "Franchisee_Name": "Kidzee Thergaon",
                               "Operating_Status": "A",
                               "Address1": "G. No.22,H.No-07,Thergaon,",
                               "Address2": "Near-Janwa Medical",
                               "Place": "Thergaon",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4387@kidzee.com",
                               "Mobile_No": "9922411396",
                               "Phone_No1": "9765686955",
                               "Phone_No2": "",
                               "Latitude": "18.613075",
                               "Longitude": "73.772787",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2222530/10855/91950306_7392_49e2_9c5a_22117343ad43.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2222530/10856/b3a874ee_b5e1_4bb3_9221_bc85cb9bc567.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2222530/10857/fdcf1c32_acf1_44c3_9e15_c43201779b0f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2222530/10855/f90ef6e6_0087_4a7a_b262_404458201a8b.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-4354",
                               "Franchisee_Name": "Kidzee Dy-Patil Rd. Lohegaon",
                               "Operating_Status": "A",
                               "Address1": "307, Survey No,Flat No-5,",
                               "Address2": "D.Y Patil Road,",
                               "Place": "Lohegaon, Opp-DY Patil,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4354@kidzee.com",
                               "Mobile_No": "9850211001",
                               "Phone_No1": "9850211001",
                               "Phone_No2": "",
                               "Latitude": "18.6099900",
                               "Longitude": "73.9179500"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4416",
                               "Franchisee_Name": "Kidzee Uruli Kanchan",
                               "Operating_Status": "A",
                               "Address1": "GAT No - 330,  Uruli Kanchan,",
                               "Address2": "Tal- Haveli,",
                               "Place": "Koregaon Mul, Opp to Alankar Mangal Karylay,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4416@kidzee.com",
                               "Mobile_No": "8888807224",
                               "Phone_No1": "9822749789",
                               "Phone_No2": "",
                               "Latitude": "18.490618",
                               "Longitude": "74.118812",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2220878/10860/4ee8c9a7_65d4_4ec8_9c72_ae712f13555f.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-S-4417",
                               "Franchisee_Name": "Kidzee Hingewadi Phase 3",
                               "Operating_Status": "A",
                               "Address1": "Shed No-1, Plot No-41, Road,",
                               "Address2": "Villlage Maan, Tehsil Mulashi,",
                               "Place": "Hingewadi Phase-3, Near Megapolis,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4417@kidzee.com",
                               "Mobile_No": "8600143414",
                               "Phone_No1": "7387-143414",
                               "Phone_No2": "",
                               "Latitude": "11.0",
                               "Longitude": "12.0"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4809",
                               "Franchisee_Name": "Kidzee Bavdhan Budruk",
                               "Operating_Status": "A",
                               "Address1": "Row House No-C1,",
                               "Address2": "Orchard Park, survey no. 269/2A,opp crystal Honda service center,",
                               "Place": "Bavdhan Budruk ,Opp crystal Honda ",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4809@kidzee.com",
                               "Mobile_No": "8788107673",
                               "Phone_No1": "8788107673",
                               "Phone_No2": "",
                               "Latitude": "18.520724",
                               "Longitude": "73.766602"
                            },
                            {
                               "Franchisee_Code": "W-R-S-3995",
                               "Franchisee_Name": "Kidzee Wanwadi",
                               "Operating_Status": "A",
                               "Address1": "Mathew Mansion, Survey no. 53/23, Near SRPF Group II,",
                               "Address2": "Vikasnagar, Near Alankaran Lawn",
                               "Place": "Wanwadi ,Near SRPF ground No 2",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3995@kidzee.com",
                               "Mobile_No": "6377330085",
                               "Phone_No1": "6377330085",
                               "Phone_No2": "",
                               "Latitude": "18.491328583555404",
                               "Longitude": "73.90240564083342"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4667",
                               "Franchisee_Name": "Kidzee G K Royal Hills Ravet",
                               "Operating_Status": "A",
                               "Address1": "Survey No-76, Shop No-1,2,3,4 &5, Hissa No-2,",
                               "Address2": "Building - A, Ground Floor,",
                               "Place": "G.K Royal Hills, Ravet, G K Royal Hills Ravet",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4667@kidzee.com",
                               "Mobile_No": "9022144739",
                               "Phone_No1": "9022144739",
                               "Phone_No2": "",
                               "Latitude": "18.662394",
                               "Longitude": "73.741866"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4606",
                               "Franchisee_Name": "Kidzee Katraj Kondhwa Road",
                               "Operating_Status": "A",
                               "Address1": "Subh Chetana Building,",
                               "Address2": "S.No 33/60, lane No. 1",
                               "Place": "Katraj Kondhwa Road ,Lane NO 1 ",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4606@kidzee.com",
                               "Mobile_No": "9822050197",
                               "Phone_No1": "9822050197",
                               "Phone_No2": "",
                               "Latitude": "18.44897",
                               "Longitude": "73.86981"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4751",
                               "Franchisee_Name": "Kidzee Bibvewadi",
                               "Operating_Status": "A",
                               "Address1": "Plot No.1,",
                               "Address2": "Saraswati sadanand Co-operative Housing Society",
                               "Place": "Bibvewadi, Near Bhagali Hospital",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4751@kidzee.com",
                               "Mobile_No": "8855964004",
                               "Phone_No1": "8855964004",
                               "Phone_No2": "",
                               "Latitude": "18.474622",
                               "Longitude": "73.857887"
                            },
                            {
                               "Franchisee_Code": "W-R-S-5045",
                               "Franchisee_Name": "Kidzee Viman Nagar",
                               "Operating_Status": "A",
                               "Address1": "Row house No.1, Ground Floor, Lunkad Heritage 1, Sy no.199, CTS no 119",
                               "Address2": "Village Lohgaon",
                               "Place": "Viman Nagar ,Lunkad Heritage",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5045@kidzee.com",
                               "Mobile_No": "9552066262",
                               "Phone_No1": "9552066262",
                               "Phone_No2": "",
                               "Latitude": "18.5649826",
                               "Longitude": "73.7791233"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4820",
                               "Franchisee_Name": "Kidzee Sus",
                               "Operating_Status": "A",
                               "Address1": "Survey No 89, Uttam villa,",
                               "Address2": "Near Shanti terrace society, vidhya valley school road, Parkhe vasti,",
                               "Place": "Sus ,Near Vidhya Valley school",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4820@kidzee.com",
                               "Mobile_No": "9766373023",
                               "Phone_No1": "9766373023",
                               "Phone_No2": "",
                               "Latitude": "18.549976",
                               "Longitude": "73.753230",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2710751/11087/44077528_33fe_432f_8123_dbd8fb68fee1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2710751/11087/2f71ce3e_60b9_4785_8cfb_42d1285c9259.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-5133",
                               "Franchisee_Name": "Kidzee Bhusari colony",
                               "Operating_Status": "A",
                               "Address1": "Plot No 37, Survey no-98, Pranav(honap) bungalow, right Bhusari colony,",
                               "Address2": "Bavdhan-Kothrud",
                               "Place": "Kothrud ,Bhusari colony ",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee5133@kidzee.com",
                               "Mobile_No": "8177853145",
                               "Phone_No1": "8177853145",
                               "Phone_No2": "",
                               "Latitude": "18.509632730243883",
                               "Longitude": "73.79070650844359",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2763889/11039/be64713e_4fd1_4c01_9918_e7e5ec975705.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2763889/11039/017d7c90_2656_4f9b_a2b1_1c64f47f5332.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2763889/11039/67e986ff_d11d_441a_89c7_c924e562bd4e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2763889/11039/55e77445_9d31_48b9_b3b6_7cf681e903db.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2763889/11039/d3c54e45_91b3_4188_906f_9de80f7ac468.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2763889/11039/c346a1b2_04cf_49f0_98c1_db2b9bcc560e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2763889/11039/c8957bad_9273_4e69_bfd2_2d9fd7d08f3e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2763889/11039/c3fb7fd9_e5ae_4f2d_aa98_4393b43c6bdf.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2763889/11039/51809012_d657_4b76_818b_ef5bc1a89076.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2763889/11039/537bcba4_5a09_448e_bbc2_a81b5b5ce140.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-5366",
                               "Franchisee_Name": "Kidzee BT Kawade Road",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor , B -5 Nigadenagar, Lane No-2",
                               "Address2": "Ghorpadi Gaon",
                               "Place": "B T Kawade Road",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee5366@kidzee.com",
                               "Mobile_No": "9422003521",
                               "Phone_No1": "9422003521",
                               "Phone_No2": "",
                               "Latitude": "18.5698287",
                               "Longitude": "73.9077593"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4507",
                               "Franchisee_Name": "Kidzee Moshi Pune",
                               "Operating_Status": "A",
                               "Address1": "Plot No-171",
                               "Address2": "Boratewadi, Moshi",
                               "Place": "Moshi ,Boratewadi",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4507@kidzee.com",
                               "Mobile_No": "7841833627",
                               "Phone_No1": "7841833627",
                               "Phone_No2": "",
                               "Latitude": "18.68321",
                               "Longitude": "73.8488"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5429",
                               "Franchisee_Name": "Kidzee Hadpasar",
                               "Operating_Status": "A",
                               "Address1": "Bhosale Garden Shivaji Road,",
                               "Address2": "Bhosale Nagar,",
                               "Place": "Hadapsar",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5429@kidzee.com",
                               "Mobile_No": "9967009218",
                               "Phone_No1": "9967009218",
                               "Phone_No2": "",
                               "Latitude": "18.50755938864728",
                               "Longitude": "73.93066965269723"
                            },
                            {
                               "Franchisee_Code": "A-W-A-1091",
                               "Franchisee_Name": "Ankurum Village Theur,",
                               "Operating_Status": "A",
                               "Address1": "GAT N0. 955, 1st Floor, Taluka Haveli",
                               "Address2": "Pune Zila Parishad",
                               "Place": "Village Theur,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "jinturkarbharat@rediffmail.com",
                               "Mobile_No": "9890266224",
                               "Phone_No1": "9890266224",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5449",
                               "Franchisee_Name": "Kidzee Mahalunge",
                               "Operating_Status": "A",
                               "Address1": "Milkat House No 93, 94, Padle Villa, Near Mahalunge Bus stop",
                               "Address2": "Pimplachi Talim, Near Mahadev Temple",
                               "Place": "Mahalunge",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4820@kidzee.com",
                               "Mobile_No": "9766373023",
                               "Phone_No1": "9766373023",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5278",
                               "Franchisee_Name": "Kidzee Lohagaon",
                               "Operating_Status": "A",
                               "Address1": "Kumar Vista, S. No 66/1/1/1, Lohegaon",
                               "Address2": "Vadgaon Shinde Road",
                               "Place": "Lohegaon",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5278@kidzee.com",
                               "Mobile_No": "7709906566",
                               "Phone_No1": "9766657068",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5461",
                               "Franchisee_Name": "Kidzee Jadhavwadi,Chikhali",
                               "Operating_Status": "A",
                               "Address1": "Near Jambhulkar Kaman",
                               "Address2": "Pimpri  Chinchwad",
                               "Place": "Jadhavwadi,Chikhali",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5461@kidzee.com",
                               "Mobile_No": "8767698788",
                               "Phone_No1": "8767698788",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5044",
                               "Franchisee_Name": "Kidzee Mulashi",
                               "Operating_Status": "A",
                               "Address1": "115/2 , Village  janmbe ,",
                               "Address2": "Near kolte patil life republic",
                               "Place": "Mulashi ,Near Kolte Patil Republic ",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5044@kidzee.com",
                               "Mobile_No": "9022144739",
                               "Phone_No1": "9022144739",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4082",
                               "Franchisee_Name": "Kidzee New Kopre",
                               "Operating_Status": "A",
                               "Address1": "S.N, 33/7, Sonai Park, Awing, New Kopare,",
                               "Address2": "TAL- Haveli,",
                               "Place": "New Kopare, Sonai Park,",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4082@kidzee.com",
                               "Mobile_No": "9730705152",
                               "Phone_No1": "9689727374",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5530",
                               "Franchisee_Name": "Kidzee Pradhikaran",
                               "Operating_Status": "A",
                               "Address1": "Row House no 8, Comfort cushion, sector no 26, next to PMRDA office,",
                               "Address2": "Akrudi Pradhikaran,",
                               "Place": "Nigadi",
                               "City_Id": "161",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5530@kidzee.com",
                               "Mobile_No": "9767672010",
                               "Phone_No1": "9923472010",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Buldana / Buldhana",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-4194",
                               "Franchisee_Name": "Kidzee Nandura",
                               "Operating_Status": "A",
                               "Address1": "Janta Housing Society,",
                               "Address2": "Malkapur Road,",
                               "Place": "Nandura,",
                               "City_Id": "189",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4194@kidzee.com",
                               "Mobile_No": "8275200600",
                               "Phone_No1": "8275200600",
                               "Phone_No2": "",
                               "Latitude": "20.836240",
                               "Longitude": "76.456889"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4745",
                               "Franchisee_Name": "KIDZEE  SHEGAON",
                               "Operating_Status": "A",
                               "Address1": "Nariman Point,",
                               "Address2": "Balapur Road,",
                               "Place": "Shegaon, Nariman Point",
                               "City_Id": "189",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4745@kidzee.com",
                               "Mobile_No": "9767774123",
                               "Phone_No1": "9822666202",
                               "Phone_No2": "",
                               "Latitude": "20.7803",
                               "Longitude": "76.69439"
                            }
                         ]
                      },
                      {
                         "City_Name": "Chalisgaon"
                      },
                      {
                         "City_Name": "Chandrapur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-U-U-749",
                               "Franchisee_Name": "KIDZEE-CHANDRAPUR- SHRI NAKODA MULTIPURPOSE EDUCATIONAL SOCIETY",
                               "Operating_Status": "A",
                               "Address1": "Behind-Adarsh Petrol Pump,",
                               "Address2": "",
                               "Place": "Mul Road, Behind Adarsh Petrol Pump,",
                               "City_Id": "199",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee749@kidzee.com",
                               "Mobile_No": "8149858789",
                               "Phone_No1": "8149858789",
                               "Phone_No2": "",
                               "Latitude": "19.97046",
                               "Longitude": "79.301485"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4185",
                               "Franchisee_Name": "Kidzee Gadchandur",
                               "Operating_Status": "A",
                               "Address1": "Gore Layout, Vidyanagri,",
                               "Address2": "Ward No-5,",
                               "Place": "Gadchandur, Near- DR.Thakre Hospital,",
                               "City_Id": "199",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4185@kidzee.com",
                               "Mobile_No": "7083847857",
                               "Phone_No1": "7522-959066",
                               "Phone_No2": "",
                               "Latitude": "19.736330",
                               "Longitude": "79.172374"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3781",
                               "Franchisee_Name": "KIDZEE RAJURA",
                               "Operating_Status": "A",
                               "Address1": "Karnel Chowk, Plot No-84,",
                               "Address2": "",
                               "Place": "Rajura, Asifabad Highway,",
                               "City_Id": "199",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3781@kidzee.com",
                               "Mobile_No": "9822930164",
                               "Phone_No1": "9822930164",
                               "Phone_No2": "",
                               "Latitude": "19.776975",
                               "Longitude": "79.366011"
                            },
                            {
                               "Franchisee_Code": "A-W-A-1005",
                               "Franchisee_Name": "Ankurum Chimur",
                               "Operating_Status": "A",
                               "Address1": "Moza Wadala (Paiku), Nagar Parishad Property no 1465",
                               "Address2": "Dist Chandrapur",
                               "Place": "Chimur ,Moza Wadala ",
                               "City_Id": "199",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4812@kidzee.com",
                               "Mobile_No": "8983242638",
                               "Phone_No1": "8983242638",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "A-W-A-1047",
                               "Franchisee_Name": "Ankurum Bhadravati",
                               "Operating_Status": "A",
                               "Address1": "Plot No-50, Bhoomi Kramank-68,",
                               "Address2": "Moza Vijasan,",
                               "Place": "Bhadravati ,Savarkar Nagar,",
                               "City_Id": "199",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzeebhadrawati@gmail.com",
                               "Mobile_No": "9404191959",
                               "Phone_No1": "9404191959",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Solapur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-4899",
                               "Franchisee_Name": "Kidzee Akluj",
                               "Operating_Status": "A",
                               "Address1": "Gat No-88/2 Plot No-10,",
                               "Address2": "DNS Plaza Near-DNS Traders,",
                               "Place": "Akluj, Akluj Bypass Road,",
                               "City_Id": "286",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4899@kidzee.com",
                               "Mobile_No": "9096166514",
                               "Phone_No1": "9028-414038",
                               "Phone_No2": "",
                               "Latitude": "17.8832581",
                               "Longitude": "75.0214089"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5082",
                               "Franchisee_Name": "Kidzee Barshi",
                               "Operating_Status": "A",
                               "Address1": "Seth Mulchand Jawaharmal Sadhana Kanya Pathshala,",
                               "Address2": "3835, Kuraduwadi Road,",
                               "Place": "Barshi, Sadhana Kanya Prashala,",
                               "City_Id": "286",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5082@kidzee.com",
                               "Mobile_No": "9673657111",
                               "Phone_No1": "8766503053",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Dombivali",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2472",
                            "Franchisee_Name": "Kidzee Dombivili East",
                            "Operating_Status": "A",
                            "Address1": "Saraswati, Opp Parag Banglow,",
                            "Address2": "Nandiwali Road, Near Nandivili Telephone Exchange, Near Nandivili Road,",
                            "Place": "Dombivili East,",
                            "City_Id": "292",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2472@kidzee.com",
                            "Mobile_No": "7506030742",
                            "Phone_No1": "7506030742",
                            "Phone_No2": "",
                            "Latitude": "19.206654",
                            "Longitude": "73.08924",
                            "Images": [
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/906258/11037/68223e15_cf0d_4135_9680_8993aebc346b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/906258/11037/560ac992_1e76_4e58_a247_94e13cfde8ca.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Gondia",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-U-U-723",
                               "Franchisee_Name": "Kidzee-Gondia- Maharastra- Vasant Vidya Vihar",
                               "Operating_Status": "A",
                               "Address1": "Near-Ramdeo Mandir,",
                               "Address2": "",
                               "Place": "Rail Toli,",
                               "City_Id": "380",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee723@kidzee.com",
                               "Mobile_No": "9881074760",
                               "Phone_No1": "0000000000",
                               "Phone_No2": "",
                               "Latitude": "21.460041",
                               "Longitude": "80.194279"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3579",
                               "Franchisee_Name": "Kidzee Gondia",
                               "Operating_Status": "A",
                               "Address1": "PLOT NO 10",
                               "Address2": "ASHOK COLONY",
                               "Place": "GANESH NAGAR ,Ganesh Nagar ",
                               "City_Id": "380",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3579@kidzee.com",
                               "Mobile_No": "7767883252",
                               "Phone_No1": "7767883252",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Hingoli"
                      },
                      {
                         "City_Name": "Ichalkaranji",
                         "Franchisee": {
                            "Franchisee_Code": "W-A-M-1210",
                            "Franchisee_Name": "Kidzee - Ichalkaranji",
                            "Operating_Status": "A",
                            "Address1": "11 / 461, Prem Sagar,",
                            "Address2": "",
                            "Place": "Ichalkaranji, Near-Cloth Market,",
                            "City_Id": "454",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1210@kidzee.com",
                            "Mobile_No": "9067910680",
                            "Phone_No1": "02302420091",
                            "Phone_No2": "",
                            "Latitude": "16.6968561",
                            "Longitude": "74.4597849"
                         }
                      },
                      {
                         "City_Name": "Jalgaon",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-4348",
                               "Franchisee_Name": "Kidzee Erandol",
                               "Operating_Status": "A",
                               "Address1": "Erandol,",
                               "Address2": "Thasil.",
                               "Place": "Erandol ,Near ST Stand Lane,",
                               "City_Id": "479",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4348@kidzee.com",
                               "Mobile_No": "9823138600",
                               "Phone_No1": "9823138600",
                               "Phone_No2": "",
                               "Latitude": "20.9244713",
                               "Longitude": "75.33421550"
                            },
                            {
                               "Franchisee_Code": "A-W-A-1048",
                               "Franchisee_Name": "Ankurum Pahur",
                               "Operating_Status": "A",
                               "Address1": "Sai Nagar,",
                               "Address2": "Jamner Road,",
                               "Place": "Pahur, Near- Bharat Gas Agency,",
                               "City_Id": "479",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "RUPESHLODHA81@GMAIL.COM",
                               "Mobile_No": "9404049900",
                               "Phone_No1": "9404049900",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5640",
                               "Franchisee_Name": "Kidzee Raver",
                               "Operating_Status": "A",
                               "Address1": "House no -538,539,540,541",
                               "Address2": "Agrasen chouk, main road,",
                               "Place": "Raver",
                               "City_Id": "479",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5640@kidzee.com",
                               "Mobile_No": "7875626200",
                               "Phone_No1": "7875626200",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Jalna"
                      },
                      {
                         "City_Name": "Khamgaon",
                         "Franchisee": {
                            "Franchisee_Code": "W-U-C-494",
                            "Franchisee_Name": "Kidzee-Khamgaon, Sanskar Dnyanpeeth",
                            "Operating_Status": "A",
                            "Address1": "New Sanskar Gyanpith,",
                            "Address2": "Shegaon Road,",
                            "Place": "Adarsh Nagar, Near Bordeaux Colony",
                            "City_Id": "559",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee494@kidzee.com",
                            "Mobile_No": "9225721644",
                            "Phone_No1": "7263258222",
                            "Phone_No2": "",
                            "Latitude": "20.711622",
                            "Longitude": "76.566128"
                         }
                      },
                      {
                         "City_Name": "Kolhapur",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3169",
                            "Franchisee_Name": "Kidzee Pratibha nagar  Kolhapur",
                            "Operating_Status": "A",
                            "Address1": "Plot No-21, Nalawade Colony,",
                            "Address2": "Samrat Nagar, In Front Viswakarma Park,",
                            "Place": "Pratibha Nagar, Near Vishwakarma Park",
                            "City_Id": "580",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3169@kidzee.com",
                            "Mobile_No": "9923940139",
                            "Phone_No1": "9923940139",
                            "Phone_No2": "",
                            "Latitude": "16.6839129",
                            "Longitude": "74.2428592"
                         }
                      },
                      {
                         "City_Name": "Latur",
                         "Franchisee": {
                            "Franchisee_Code": "W-U-U-323",
                            "Franchisee_Name": "Kidzee - Latur / Kids Academy",
                            "Operating_Status": "A",
                            "Address1": "Block No. 16 , Ausa road,",
                            "Address2": "Latur",
                            "Place": "New Adarsh Colony ,Ausa Road ",
                            "City_Id": "616",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee323@kidzee.com",
                            "Mobile_No": "9820252615",
                            "Phone_No1": "2382225500",
                            "Phone_No2": "",
                            "Latitude": "18.389437",
                            "Longitude": "76.561059"
                         }
                      },
                      {
                         "City_Name": "Lonavala"
                      },
                      {
                         "City_Name": "Miraj"
                      },
                      {
                         "City_Name": "Mumbai",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-A-M-157",
                               "Franchisee_Name": "Kidzee - Powai / Kiddies World",
                               "Operating_Status": "A",
                               "Address1": "Plot No.4 , Gambhir Bunglows,",
                               "Address2": "Hiranandani Gardens Powai",
                               "Place": "Powai, Hiranandani Gardens ,Nr Nature's Basket",
                               "City_Id": "685",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee157@kidzee.com",
                               "Mobile_No": "9870280280",
                               "Phone_No1": "8424987281",
                               "Phone_No2": "",
                               "Latitude": "19.12022807312943",
                               "Longitude": "72.9079960145416",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/154/10922/38f4cdb7_7679_4dc5_b8a1_492d3032507b.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-S-3342",
                               "Franchisee_Name": "Kidzee Dahisar east",
                               "Operating_Status": "A",
                               "Address1": "Shop No.4 &5, Ground Floor, A-wing,",
                               "Address2": "Sree Satyam Co-op Housing  Society Ltd, Chatrapati Shivaji Complex Road No.5,",
                               "Place": "Dahisar East, Nr Anand Nagar,",
                               "City_Id": "685",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3342@kidzee.com",
                               "Mobile_No": "9867592376",
                               "Phone_No1": "9867592376",
                               "Phone_No2": "",
                               "Latitude": "19.258953",
                               "Longitude": "72.863108"
                            },
                            {
                               "Franchisee_Code": "W-R-S-4083",
                               "Franchisee_Name": "Kidzee Mulund West",
                               "Operating_Status": "A",
                               "Address1": "Lok Aangan Commercial Building, Mulund Colony,",
                               "Address2": "Next to Mulund Darshan Building,",
                               "Place": "Mulund West, Near Shastri Nagar,",
                               "City_Id": "685",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4083@kidzee.com",
                               "Mobile_No": "9819300781",
                               "Phone_No1": "022111111",
                               "Phone_No2": "",
                               "Latitude": "19.180986",
                               "Longitude": "72.938960"
                            },
                            {
                               "Franchisee_Code": "W-R-S-4655",
                               "Franchisee_Name": "Kidzee Goregaon West",
                               "Operating_Status": "A",
                               "Address1": "B-53/210, Siddha C.H.S Ltd,",
                               "Address2": "Siddharth Nagar,",
                               "Place": "Goregaon West, Nr-Siddhart Nagar Road No-2, Siddha",
                               "City_Id": "685",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4655@kidzee.com",
                               "Mobile_No": "7304784142",
                               "Phone_No1": "7304784142",
                               "Phone_No2": "",
                               "Latitude": "19.157030",
                               "Longitude": "72.841780"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5032",
                               "Franchisee_Name": "Kidzee Mira Road East",
                               "Operating_Status": "A",
                               "Address1": "Unit no 4, J.D Indl Estate, Beside Lodha Aqua, Pandurang Wadi,",
                               "Address2": "Opp Thakur mall, westeren express highway",
                               "Place": "Mira Road East ,Besides Lodha Aqua",
                               "City_Id": "685",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5032@kidzee.com",
                               "Mobile_No": "9967091978",
                               "Phone_No1": "8655758502",
                               "Phone_No2": "",
                               "Latitude": "6.127",
                               "Longitude": "-7.523"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5002",
                               "Franchisee_Name": "Kidzee Kandivali East",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Shivshakti Apartment, Lokhandwala Township, Plot No-15,",
                               "Address2": "Near Shagun Hall, MHADA Road No.15,",
                               "Place": "Kandivali East, Near Lokhandwala Complex",
                               "City_Id": "685",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5002@kidzee.com",
                               "Mobile_No": "9820214027",
                               "Phone_No1": "8976166006",
                               "Phone_No2": "",
                               "Latitude": "19.195514",
                               "Longitude": "72.874528",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2696722/11022/8a14d3e0_8dc7_4ace_b95f_996aef50f969.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2696722/11022/d3407616_4bbf_4385_81f3_214a5617a147.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2696722/11022/d55b65f0_f86a_4c93_9dc4_9902a0ffbaf1.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-5726",
                               "Franchisee_Name": "Kidzee Bhayender East",
                               "Operating_Status": "A",
                               "Address1": "Sarvoday residency, shop no 002, new golden nest road",
                               "Address2": "Behind golden nest police chowky",
                               "Place": "Bhayender East",
                               "City_Id": "685",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5726@kidzee.com",
                               "Mobile_No": "9971268031",
                               "Phone_No1": "9619510536",
                               "Phone_No2": "",
                               "Latitude": "19.296487",
                               "Longitude": "72.859664"
                            },
                            {
                               "Franchisee_Code": "W-R-S-5491",
                               "Franchisee_Name": "Kidzee Dadar West",
                               "Operating_Status": "A",
                               "Address1": "Unit no.1 Cwing, Gulmohar CHS,",
                               "Address2": "P.L Kale Guruji Marg, ",
                               "Place": "Dadar West",
                               "City_Id": "685",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5491@kidzee.com",
                               "Mobile_No": "9819615544",
                               "Phone_No1": "98196 15544",
                               "Phone_No2": "",
                               "Latitude": "19.02135",
                               "Longitude": "72.83896"
                            },
                            {
                               "Franchisee_Code": "W-R-S-5494",
                               "Franchisee_Name": "Kidzee Byculla",
                               "Operating_Status": "A",
                               "Address1": "2nd Floor Deekay Solace, Rambhau Bhogle Marg",
                               "Address2": "Cotton green, ",
                               "Place": "Byculla",
                               "City_Id": "685",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5494@kidzee.com",
                               "Mobile_No": "9082820493",
                               "Phone_No1": "9082820493",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Nagpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3304",
                               "Franchisee_Name": "Kidzee Hingna Road",
                               "Operating_Status": "A",
                               "Address1": "Tenament No-D-1,",
                               "Address2": "Wasudeo Nagar,",
                               "Place": "Hingna Road, Hingna Road,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3304@kidzee.com",
                               "Mobile_No": "9096879024",
                               "Phone_No1": "90968 79024",
                               "Phone_No2": "",
                               "Latitude": "21.120229",
                               "Longitude": "79.020654",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3304/270/a721da09_6ed9_42a7_853b_69b2a1dcfb92.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3304/270/be7fddba_e809_4cf8_979e_ba4d59a9be83.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-3144",
                               "Franchisee_Name": "Kidzee Manewada",
                               "Operating_Status": "A",
                               "Address1": "Plot No-85, Gurukunj Nagar,",
                               "Address2": "Near Vigyaan Nagar Colony",
                               "Place": "Manewada Ring Road",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3144@kidzee.com",
                               "Mobile_No": "7028920229",
                               "Phone_No1": "7028024229",
                               "Phone_No2": "",
                               "Latitude": "21.0997713",
                               "Longitude": "79.1064414",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3144/224/da9d4397_1c95_4f43_bbfc_766c9182ab12.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-3046",
                               "Franchisee_Name": "Kidzee Gurudev Nagar Nagpur",
                               "Operating_Status": "A",
                               "Address1": "Plot No-279,",
                               "Address2": "Nandanvan,",
                               "Place": "Gurudev Nagar,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3046@kidzee.com",
                               "Mobile_No": "7757042765",
                               "Phone_No1": "7757042765 ",
                               "Phone_No2": "",
                               "Latitude": "21.137088",
                               "Longitude": "79.130413"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2999",
                               "Franchisee_Name": "Kidzee Seed Education  Ayodhya Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot No-97, 98,",
                               "Address2": "Sakkardara Adarsh Gruh Nirman Sahakari Sanstha Maryadit,",
                               "Place": "Ayodhya Nagar, Opp Play Ground,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2999@kidzee.com",
                               "Mobile_No": "9860300009",
                               "Phone_No1": "9860-300009",
                               "Phone_No2": "",
                               "Latitude": "21.120143",
                               "Longitude": "79.112513"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4002",
                               "Franchisee_Name": "KIDZEE MOUDA",
                               "Operating_Status": "A",
                               "Address1": "Plot No-6, Ward No-5,",
                               "Address2": "Sahakar Nagar,",
                               "Place": "Mouda,Ward Number-5,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4002@kidzee.com",
                               "Mobile_No": "9503488080",
                               "Phone_No1": "9552-554586",
                               "Phone_No2": "",
                               "Latitude": "21.1461369",
                               "Longitude": "79.3840772",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946785/10959/017c633d_2584_408a_b079_3f8251a71df1.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-4654",
                               "Franchisee_Name": "Kidzee Dighori",
                               "Operating_Status": "A",
                               "Address1": "Hunman Mandir Jawal,",
                               "Address2": "149, Beldar Nagar, Narsala Road, Dighori",
                               "Place": "Umrer Road",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4654@kidzee.com",
                               "Mobile_No": "9158629639",
                               "Phone_No1": "9158-629639",
                               "Phone_No2": "",
                               "Latitude": "21.106253",
                               "Longitude": "79.134474",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481343/10982/bf2672a4_75fe_44ac_bb14_1a2939cae33b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481343/10982/b651e403_2475_4191_9966_81d98f01efec.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481343/10982/b44d4cd8_209b_4b93_8344_e3d7722a37d9.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-5348",
                               "Franchisee_Name": "Kidzee \"Daksh little brains\"",
                               "Operating_Status": "A",
                               "Address1": "Plot No-1 & 2, Ground and 1st Floor,",
                               "Address2": "Netaji Nagar,",
                               "Place": "Katol Road, Friends Colony,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5348@kidzee.com",
                               "Mobile_No": "9579582179",
                               "Phone_No1": "1234-000000",
                               "Phone_No2": "",
                               "Latitude": "21.1801° N",
                               "Longitude": "79.0410° E",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2829412/11064/30bf1f64_ab56_4b3b_9388_a2350ce20a73.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2829412/11064/1a21aa5d_7148_4c73_91a9_b4cc0f4ef6ca.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2829412/11064/82afc4ae_08e2_417c_855e_a01243294666.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-5484",
                               "Franchisee_Name": "KIDZEE KUKDE LAYOUT,",
                               "Operating_Status": "A",
                               "Address1": "PLOT NO 80, GROUND FLOOR",
                               "Address2": "NEAR GAWAI HOSPITAL",
                               "Place": "KUKDE LAYOUT,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5484@kidzee.com",
                               "Mobile_No": "8600549505",
                               "Phone_No1": "1234-000000",
                               "Phone_No2": "",
                               "Latitude": "21.12051611709477",
                               "Longitude": "79.09279606497766",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2947954/11068/8b5ff30b_f082_417a_9986_c7a4288b530a.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-4217",
                               "Franchisee_Name": "Kidzee Narendra Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot A-25,",
                               "Address2": "Arvind Nagar, Behind Vijaya Apt,",
                               "Place": "Narendra Nagar,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4217@kidzee.com",
                               "Mobile_No": "9422110169",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4043",
                               "Franchisee_Name": "Kidzee Godhani",
                               "Operating_Status": "A",
                               "Address1": "M.B.Town-3,",
                               "Address2": "Godhani Road,",
                               "Place": "Godhani, M.B.Town-3,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4043@kidzee.com",
                               "Mobile_No": "9764113005",
                               "Phone_No1": "9011220055",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4593",
                               "Franchisee_Name": "Kidzee Buddha Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot No-1111, Buddha Nagar,",
                               "Address2": "Ashi Nagar Zone No.9,",
                               "Place": "Buddha Nagar, Dr.Ambedkar Marg,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4593@kidzee.com",
                               "Mobile_No": "8105597693",
                               "Phone_No1": "8105597693",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4543",
                               "Franchisee_Name": "KIDZEE KHAMALA",
                               "Operating_Status": "A",
                               "Address1": "PLOT NO 91, GROUND FLOOR",
                               "Address2": "PANDEY LAYOUT,NEAR WATER TANK",
                               "Place": "Khamala ,Pandey Layout near water tank",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4543@kidzee.com",
                               "Mobile_No": "9923645407",
                               "Phone_No1": "9923645407",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4607",
                               "Franchisee_Name": "Kidzee Dixit Nagar",
                               "Operating_Status": "A",
                               "Address1": "House No-39,",
                               "Address2": "",
                               "Place": "Dixit Nagar, Nari Road,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4607@kidzee.com",
                               "Mobile_No": "7020595741",
                               "Phone_No1": "0712-70205957",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5383",
                               "Franchisee_Name": "Kidzee Surya Nagar  Pardi",
                               "Operating_Status": "A",
                               "Address1": "Plot No-29, Surya Nagar,",
                               "Address2": "Kalamana Road,",
                               "Place": "Pardi,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5383@kidzee.com",
                               "Mobile_No": "9764000175",
                               "Phone_No1": "9422106080",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4362",
                               "Franchisee_Name": "Kidzee Saoner",
                               "Operating_Status": "A",
                               "Address1": "Bhongade Layout,",
                               "Address2": "Ward No-3,",
                               "Place": "Saoner, Bhongade Layout,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4362@kidzee.com",
                               "Mobile_No": "9960355447",
                               "Phone_No1": "9960355447",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5585",
                               "Franchisee_Name": "Kidzee Godhani",
                               "Operating_Status": "A",
                               "Address1": "PLOT NO 1 Saket Nagar",
                               "Address2": "Near Bus Stop",
                               "Place": "Godhani",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5585@kidzee.com",
                               "Mobile_No": "9370325567",
                               "Phone_No1": "9370325567",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5293",
                               "Franchisee_Name": "Kidzee Trimurti nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 18",
                               "Address2": "Range Nagar",
                               "Place": "Trimurti Nagar, Plot No. 18 Range Nagar",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5293@kidzee.com",
                               "Mobile_No": "8149019901",
                               "Phone_No1": "8149019901",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5114",
                               "Franchisee_Name": "Kidzee Jaripatka",
                               "Operating_Status": "A",
                               "Address1": "Near- Sunrice Convent,",
                               "Address2": "Mangalwari Bazzar Road, Jaripatka,",
                               "Place": "Mangalwari Bazzar, Near Sunrice convent",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5114@kidzee.com",
                               "Mobile_No": "9975757530",
                               "Phone_No1": "9975757530",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4255",
                               "Franchisee_Name": "Kidzee Ramnagar Nagpur",
                               "Operating_Status": "A",
                               "Address1": "House No-1367,",
                               "Address2": "Near-Baji Prabhu Nagar,",
                               "Place": "Ram Nagar, Nagpur,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4255@kidzee.com",
                               "Mobile_No": "7448086327",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-5255",
                               "Franchisee_Name": "Kidzee Beltarodi Nagpur",
                               "Operating_Status": "A",
                               "Address1": "Plot No 11, Ward No 1",
                               "Address2": "Mauja Beltearodi ",
                               "Place": "Beltearodi",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5255@kidzee.com",
                               "Mobile_No": "9850022279",
                               "Phone_No1": "9850005239",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4440",
                               "Franchisee_Name": "Kidzee Nagpur",
                               "Operating_Status": "A",
                               "Address1": "Plot No 85/A, Rachna Society, Manish Nagar",
                               "Address2": "",
                               "Place": "Somalwada",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4440@kidzee.com",
                               "Mobile_No": "9021937540",
                               "Phone_No1": "0712-744240",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": "",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2702719/11080/fdf7ba67_2036_476a_8aab_b9c755902e7f.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "W-R-C-5199",
                               "Franchisee_Name": "Kidzee Wanadongari",
                               "Operating_Status": "A",
                               "Address1": "Plot No-90,91,",
                               "Address2": "Shriram Nagar,",
                               "Place": "Wanadongri, Badbe Layout Higana Road,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5199@kidzee.com",
                               "Mobile_No": "9860696460",
                               "Phone_No1": "9860696460",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-3671",
                               "Franchisee_Name": "Kidzee Zenda Chowk",
                               "Operating_Status": "A",
                               "Address1": "M/S Shree Balaji Tech, Seeta Sadan,",
                               "Address2": "Old Bagadganj Road,",
                               "Place": "Zenda Chowk,",
                               "City_Id": "701",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3671@kidzee.com",
                               "Mobile_No": "9823978442",
                               "Phone_No1": "9226283284",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Nanded",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3307",
                            "Franchisee_Name": "Kidzee Nanded",
                            "Operating_Status": "A",
                            "Address1": "Plot No-31, Behind-Nana Nani Park,",
                            "Address2": "Industrial Estate,",
                            "Place": "Shivaji Nagar",
                            "City_Id": "711",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3307@kidzee.com",
                            "Mobile_No": "9168400800",
                            "Phone_No1": "8888866011",
                            "Phone_No2": "",
                            "Latitude": "19.1661",
                            "Longitude": "77.3057"
                         }
                      },
                      {
                         "City_Name": "Nashik",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3439",
                               "Franchisee_Name": "Kidzee Jail Road Nashik",
                               "Operating_Status": "A",
                               "Address1": "Asha Bunglow",
                               "Address2": "Behind HP Petrol Pump",
                               "Place": "JKalanagar , Jail Road",
                               "City_Id": "718",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3439@kidzee.com",
                               "Mobile_No": "8237847761",
                               "Phone_No1": "823-37847761",
                               "Phone_No2": "",
                               "Latitude": "19.9742106",
                               "Longitude": "73.8357208",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3439/297/a570d8ee_90fd_435b_b999_961f01f33256.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10454/68411787_564e_46a3_be08_e7d672b44fb5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10454/c5fb5aca_c239_41b7_bd0d_a3db7dd28b9e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10454/4bd59dfd_4414_47df_866e_7cebff72bc3d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10455/c5dce7b0_ad7f_4849_917a_6d082131300c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10455/62f9d413_69d7_464a_9969_837720c662ab.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10455/37e7da7c_2957_4553_ab45_9b8b6872b33f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10590/cc4fe0ca_eb1d_4dad_97d1_349f8b62978d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10590/2c22a6c8_0090_492b_b358_e4f8ca774a33.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10590/cfee43de_aa09_4d24_b50c_2e873e4f914a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10590/7293a575_ffaa_4602_a5b9_b852793d5e78.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1566936/10455/7368a66f_71bd_43c4_be6f_3388ba300331.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-B-U-1021",
                               "Franchisee_Name": "Kidzee-Shivar,Nashik",
                               "Operating_Status": "A",
                               "Address1": "Plot No-518/1/5,",
                               "Address2": "",
                               "Place": "Adgaon, Shivar,",
                               "City_Id": "718",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1021@kidzee.com",
                               "Mobile_No": "9767475651",
                               "Phone_No1": "02532304894",
                               "Phone_No2": "",
                               "Latitude": "19.953553",
                               "Longitude": "73.768452"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5247",
                               "Franchisee_Name": "Kidzee Mhasrul",
                               "Operating_Status": "A",
                               "Address1": "Devshree, Row Bunglow 2",
                               "Address2": "plot no.96, Nisarga Nagar, Mhasrul area",
                               "Place": "Mhasrul",
                               "City_Id": "718",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5247@kidzee.com",
                               "Mobile_No": "7447786266",
                               "Phone_No1": "7447786266",
                               "Phone_No2": "",
                               "Latitude": "20.03718743403784",
                               "Longitude": "73.81121033913384"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5426",
                               "Franchisee_Name": "Kidzee Indra Nagar",
                               "Operating_Status": "A",
                               "Address1": "Gajara Nisarg, phase 2, Parab Nagar road, survey no 109/5/2",
                               "Address2": "Khodemala Parisar",
                               "Place": "Indra Nagar",
                               "City_Id": "718",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5426@kidzee.com",
                               "Mobile_No": "8999797503",
                               "Phone_No1": "8999797503",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Navi mumbai",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-B-S-675",
                               "Franchisee_Name": "Kidzee Kharghar Sec 11",
                               "Operating_Status": "A",
                               "Address1": "Shankeshwar Heights Shop No.7,8,9,10,11,",
                               "Address2": "Plot No-6,",
                               "Place": "Kharghar,Sector-11, Near Hanuman Mandir,",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee675@kidzee.com",
                               "Mobile_No": "9731566510",
                               "Phone_No1": "9731566510",
                               "Phone_No2": "",
                               "Latitude": "19.038716",
                               "Longitude": "73.071518"
                            },
                            {
                               "Franchisee_Code": "W-A-M-1414",
                               "Franchisee_Name": "Kidzee-Kamothe/Kids Club",
                               "Operating_Status": "A",
                               "Address1": "Yash Garden, Shop No.06-10,",
                               "Address2": "Plot No.73/74, Sector - 18,",
                               "Place": "Kamoth, Near-Gokul Dairy,",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1414@kidzee.com",
                               "Mobile_No": "7303144474",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "19.0145",
                               "Longitude": "73.0973",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1094/10534/72a9be05_33ef_4e34_b888_ccecdbc868b3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1094/10534/0174e319_d94e_4926_a026_f0dba04ecaa8.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-S-S-1465",
                               "Franchisee_Name": "KIDZEE - VASHI",
                               "Operating_Status": "A",
                               "Address1": "AMMU, Bungalow no. G-55,",
                               "Address2": "Near Sagar Vihar,",
                               "Place": "Vashi ,AMMU, Bungalow no. G-55, Sector-8,",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1465@kidzee.com",
                               "Mobile_No": "9920420870",
                               "Phone_No1": "27820870",
                               "Phone_No2": "",
                               "Latitude": "19.087247",
                               "Longitude": "73.007707"
                            },
                            {
                               "Franchisee_Code": "W-R-S-1452",
                               "Franchisee_Name": "Kidzee - Seawoods",
                               "Operating_Status": "A",
                               "Address1": "Shop No. 10 & 11, Ground Floor,",
                               "Address2": "Goodwill Averue, Plot No. 1, Sector 40, ",
                               "Place": "Seawoods ,Near Railway Station",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1452@kidzee.com",
                               "Mobile_No": "8452082271",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "19.021155",
                               "Longitude": "73.0147"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2735",
                               "Franchisee_Name": "KIDZEE GHANSOLI NAVIMUMBAI",
                               "Operating_Status": "A",
                               "Address1": "Trishul Gold Coast Ground Floor,",
                               "Address2": "Plot No-13,14,15, Sector-9,",
                               "Place": "Ghansoli, Next to SBI Bank,",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2735@kidzee.com",
                               "Mobile_No": "9820206577",
                               "Phone_No1": "9820206577",
                               "Phone_No2": "",
                               "Latitude": "19.11796308206993",
                               "Longitude": "72.99733686473462"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3678",
                               "Franchisee_Name": "Kidzee CBD Belapur Sector 15",
                               "Operating_Status": "A",
                               "Address1": "Shop No-4 and 5, Plot No-44/45,",
                               "Address2": "Progressive Sea Lounge,",
                               "Place": "CBD Belapur Sector No-15, Next Lane to Reliance Fr",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3678@kidzee.com",
                               "Mobile_No": "9820708300",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "19.006730",
                               "Longitude": "73.032746"
                            },
                            {
                               "Franchisee_Code": "COWSS-4179",
                               "Franchisee_Name": "Nerul",
                               "Operating_Status": "A",
                               "Address1": "Row House No.22C/23C, Nandanvan Co-Operative Housing Society,",
                               "Address2": "Sector-17,",
                               "Place": "Nerul-(East),",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4179@kidzee.com",
                               "Mobile_No": "8291295217",
                               "Phone_No1": "022-27704455",
                               "Phone_No2": "",
                               "Latitude": "19.033472",
                               "Longitude": "73.022848",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2017230/10809/cd854f22_9567_41d2_bb60_7dfd43aa2320.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2017230/10809/8801a6cd_0d61_4837_99c9_82c84c04cd76.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-5540",
                               "Franchisee_Name": "Kidzee Nerul sector 17",
                               "Operating_Status": "A",
                               "Address1": "Park View CHS Ltd,",
                               "Address2": "Plot no. 2 to 11",
                               "Place": "Nerul sector 17",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5540@kidzee.com",
                               "Mobile_No": "8454909495",
                               "Phone_No1": "8454909495",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-S-5440",
                               "Franchisee_Name": "Kidzee Airoli Sector 7",
                               "Operating_Status": "A",
                               "Address1": "Row House no: 1& 2",
                               "Address2": "Shree Ganesh Prem CHS,",
                               "Place": "Airoli Sector 7",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5440@kidzee.com",
                               "Mobile_No": "7710004800",
                               "Phone_No1": "7710004800",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-S-4135",
                               "Franchisee_Name": "Kidzee Kharghar Sec 10",
                               "Operating_Status": "A",
                               "Address1": "Shop No-14-15, Anmol Planet CHS Ltd,",
                               "Address2": "Plot -211,",
                               "Place": "Kharghar Sector-10, Opp. SBI Bank,",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4135@kidzee.com",
                               "Mobile_No": "8828120046",
                               "Phone_No1": "8828-120047",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": "",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2063274/10733/fc5ea6bc_faad_4126_9f11_4f559bf4c9cd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2063274/10733/65322742_433a_4fdd_9a02_d9f50a27a877.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2063274/10733/b3ea18fc_8251_4f61_b566_054b96206a51.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2063274/10733/654b7b6f_6cae_47b3_ba3b_9c6341937eb2.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2063274/10733/ea6bc89b_b8ff_4a5f_b65c_4a805712d7d0.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2063274/10733/a286784b_5e4f_4de6_8672_d96e047eb4be.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2063274/10735/0fe5570c_4b19_4922_aa9c_e5ea5b26ce00.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-4269",
                               "Franchisee_Name": "Kidzee Ulwe  Sector-8",
                               "Operating_Status": "A",
                               "Address1": "Shop No- 1,2,3 &4,",
                               "Address2": "Ishwar Aura, Plot No -A-13, Sector-8,",
                               "Place": "Ulwe, Near Cremation Ground,",
                               "City_Id": "723",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4269@Kidzee.com",
                               "Mobile_No": "7400287474",
                               "Phone_No1": "9930435523",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Pachora"
                      },
                      {
                         "City_Name": "Parbhani",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3354",
                            "Franchisee_Name": "Kidzee Vasmat Road,",
                            "Operating_Status": "A",
                            "Address1": "Plot No.67, Behind Balvidya Mandir School,",
                            "Address2": "Vaibhav Nagar, Karegoan Road,",
                            "Place": "Vasmat Road,",
                            "City_Id": "772",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3354@kidzee.com",
                            "Mobile_No": "8329337830",
                            "Phone_No1": "8329337830",
                            "Phone_No2": "",
                            "Latitude": "19.260996",
                            "Longitude": "76.776665"
                         }
                      },
                      {
                         "City_Name": "Rasayani"
                      },
                      {
                         "City_Name": "Ratnagiri"
                      },
                      {
                         "City_Name": "Sangamner"
                      },
                      {
                         "City_Name": "Sangli",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3151",
                            "Franchisee_Name": "Kidzee  Miraj",
                            "Operating_Status": "A",
                            "Address1": "Plot No.47 & 52, Sr.No. 908/2,",
                            "Address2": "Bhokare Building, Opp Reliance Petrol Pump,",
                            "Place": "Bhokare Colony, Miraj Sangli Road, Miraj Sangli Ro",
                            "City_Id": "872",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3151@kidzee.com",
                            "Mobile_No": "9511686765",
                            "Phone_No1": "1234-000000",
                            "Phone_No2": "",
                            "Latitude": "16.83469560503764",
                            "Longitude": "74.63462652441399",
                            "Images": [
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/fb5e668f_13f1_4801_afe1_b264d51efbbc.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/cae109ac_375f_4755_b9f6_b41657c090db.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/4efd0c26_abf5_4cbe_89c9_f4fff19ed42d.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/e77f0723_5fce_4a09_907d_1f2bcbcd9e72.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/108a8d85_e3fb_4f82_aff1_e86d6c0d4293.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/00d922a0_d020_4620_a681_98b93fe21618.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/87f87ca3_afd6_4557_9dbe_e01cff818d90.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/78cc0a05_7b62_4e34_986a_7340a9399447.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/a845eefb_58ef_46fd_9fa4_5a6e4c801a49.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/9244b094_9ec5_4e48_9a03_f57e273c6fac.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/756677d9_4d3b_4f2e_8e4c_4fa75d130069.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/b4a4d45e_594f_4dbe_9250_bc2e07503444.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1292574/10697/e424646c_4ddb_415a_a25c_e470fdbd2a2a.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Thane",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-U-S-515",
                               "Franchisee_Name": "Kidzee-Mira Road, Yankee Doodles",
                               "Operating_Status": "A",
                               "Address1": "Shop No- 3 to 7,",
                               "Address2": "Vasundhara- 5,",
                               "Place": "Poonam Sagar Complex, Mira Road East",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee515@kidzee.com",
                               "Mobile_No": "9819766546",
                               "Phone_No1": "9820327279",
                               "Phone_No2": "",
                               "Latitude": "19.272503",
                               "Longitude": "72.861148"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2914",
                               "Franchisee_Name": "Kidzee Virar West Kishore Kunj",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Shop No-1-7, Kishore Kunj,",
                               "Address2": "Bldg No-7,",
                               "Place": "Near-Viva College Road, Virar-West",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2914@kidzee.com",
                               "Mobile_No": "7387206158",
                               "Phone_No1": "9096232349",
                               "Phone_No2": "",
                               "Latitude": "19.458803",
                               "Longitude": "72.802911"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3098",
                               "Franchisee_Name": "Kidzee Shahpur Thane",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor Rambaug,",
                               "Address2": "Shahpur Old NH3",
                               "Place": "Shahpur, Behind Petrol Pump, Shahpur Old NH3",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3098@kidzee.com",
                               "Mobile_No": "9422093860",
                               "Phone_No1": "9422093860",
                               "Phone_No2": "",
                               "Latitude": "20.513499",
                               "Longitude": "76.751687"
                            },
                            {
                               "Franchisee_Code": "W-R-C-2601",
                               "Franchisee_Name": "Kidzee Naigaon East",
                               "Operating_Status": "A",
                               "Address1": "H-1 Building,",
                               "Address2": "Pereira Square,",
                               "Place": "Naigaon East, Opp- Don Bosco School, Naigaon-East,",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2601@kidzee.com",
                               "Mobile_No": "9766227933",
                               "Phone_No1": "9833570273",
                               "Phone_No2": "",
                               "Latitude": "19.025309",
                               "Longitude": "72.857289"
                            },
                            {
                               "Franchisee_Code": "W-R-S-3757",
                               "Franchisee_Name": "Kidzee Ghodbunder road",
                               "Operating_Status": "A",
                               "Address1": "Bungalow No. 2, Devdarshan Society",
                               "Address2": "Behind Vijaya Bank, Dongri Pada, Waghbil naka",
                               "Place": "Ghodbunder Road, Thane (West) ,Behind Vijaya Bank,",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3757@kidzee.com",
                               "Mobile_No": "9867977050",
                               "Phone_No1": "9867977050",
                               "Phone_No2": "",
                               "Latitude": "19.250722",
                               "Longitude": "72.972361"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3802",
                               "Franchisee_Name": "Kidzee Virar West-Bolinj",
                               "Operating_Status": "A",
                               "Address1": "S.No 284 B.H No 3A,",
                               "Address2": "Bolinj Sopara Road,",
                               "Place": "Virar west Bolinj Sopara Road,",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3802@kidzee.com",
                               "Mobile_No": "8668622906",
                               "Phone_No1": "8668622906",
                               "Phone_No2": "",
                               "Latitude": "19.437311",
                               "Longitude": "72.796563"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4081",
                               "Franchisee_Name": "Kidzee Khidakali",
                               "Operating_Status": "A",
                               "Address1": "Block Number-174,",
                               "Address2": "At Post Khidkali Padle,",
                               "Place": "Village Khidkali, Khidkali Post Padle Kalyan Shil ",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4081@kidzee.com",
                               "Mobile_No": "9987585748",
                               "Phone_No1": "8879173105",
                               "Phone_No2": "",
                               "Latitude": "19.155931673115056",
                               "Longitude": "73.06097567081451"
                            },
                            {
                               "Franchisee_Code": "COWSS-4180",
                               "Franchisee_Name": "Virar",
                               "Operating_Status": "A",
                               "Address1": "Shop No.J-21/116, Global City, Near-Narangi Bypass,",
                               "Address2": "",
                               "Place": "Near-Narangi Bypass, Global City, Virar West,",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4180@kidzee.com",
                               "Mobile_No": "8291844551",
                               "Phone_No1": "8291844551",
                               "Phone_No2": "",
                               "Latitude": "19.455737",
                               "Longitude": "72.803520"
                            },
                            {
                               "Franchisee_Code": "COWLS-4154",
                               "Franchisee_Name": "Hiranandani Thane",
                               "Operating_Status": "A",
                               "Address1": "Neopolis Building, Opposite-Bhumi Ecres,",
                               "Address2": "",
                               "Place": "Thane-West, Neopolis Building, Hiranandani Estate,",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4154@kidzee.com",
                               "Mobile_No": "8291295216",
                               "Phone_No1": " 8291295216",
                               "Phone_No2": "",
                               "Latitude": "19.261038",
                               "Longitude": "72.977740"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4735",
                               "Franchisee_Name": "Kidzee Badlapur",
                               "Operating_Status": "A",
                               "Address1": "Ayodhya Nagari Apartment, Milkat No-1.2.3.4, Bharat Collage Road,",
                               "Address2": "Hendrepada,",
                               "Place": "Badlapur West, Bharat Collage Road, Hendrepada, Ba",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4735@kidzee.com",
                               "Mobile_No": "9766319123",
                               "Phone_No1": "8850-671180",
                               "Phone_No2": "",
                               "Latitude": "19.172459",
                               "Longitude": "73.244755",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2499062/10949/98085f88_4a48_4117_a136_21355ba8df17.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2499062/10949/785804c2_7d01_402e_815c_a2be3e273565.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2499062/10949/077fb9b3_b8cb_4e49_be74_562889b14a94.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2499062/10949/176e896a_b2dc_4847_adae_0c934e540c30.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2499062/10949/1620a480_7921_4598_bbb6_c4e2ec2dec0e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2499062/10949/fdfdce08_6c9e_47b4_be6e_8ed798adb385.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2499062/10949/53e53761_cdcb_47c3_b59b_bdc42ef1d92d.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "W-R-C-4544",
                               "Franchisee_Name": "Kidzee  Palava Dombivili East",
                               "Operating_Status": "A",
                               "Address1": "Bungalow no 17,  The Green Park CHS,",
                               "Address2": "Lodha Heaven, Nilje,",
                               "Place": "Dombivali East ,Lodha Heaven, Palava City , Dombiv",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4544@kidzee.com",
                               "Mobile_No": "9699372233",
                               "Phone_No1": "9987585748",
                               "Phone_No2": "9987585748",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4715",
                               "Franchisee_Name": "Kidzee Ovale",
                               "Operating_Status": "A",
                               "Address1": "Shop No- 5,6 and 7, Building Horizon Classique,",
                               "Address2": "G.B.Road, Village Ovale,",
                               "Place": "Horizon Classique,G.B.Road, Ovale, Thane West",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4715@kidzee.com",
                               "Mobile_No": "9967461691",
                               "Phone_No1": "9967461691",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "W-R-C-4734",
                               "Franchisee_Name": "Kidzee Titwala East",
                               "Operating_Status": "A",
                               "Address1": "Survey No-44, Hissa No-4/B/1, Shop Gala No- 6,",
                               "Address2": "Ganesh mandir Road, Near Bank Of Baroda,",
                               "Place": "Titwala East ,Ganesh mandir Road, Near Bank Of Baroda,Titwala west",
                               "City_Id": "953",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4734@kidzee.com",
                               "Mobile_No": "9867474435",
                               "Phone_No1": "9867474435",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": "",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2548315/10984/7a2b1170_81d2_42ab_953e_bbb67058c7e6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2548315/10984/9d2a5430_6135_4c2a_a631_bd347165ed18.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2548315/10984/908f4f08_db64_490e_9b00_90300e4e20b5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2548315/10984/cae595e1_1614_4802_a67d_d6a0e2ffb4d4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2548315/10984/35b8c22a_11f8_42cc_83e7_bba7cf4b874a.jpg"
                                  }
                               ]
                            }
                         ]
                      },
                      {
                         "City_Name": "Ulhasnagar"
                      },
                      {
                         "City_Name": "Parli / Parli vaijnath"
                      },
                      {
                         "City_Name": "Wardha"
                      },
                      {
                         "City_Name": "Bhikawadi khurd"
                      },
                      {
                         "City_Name": "Ambajogai",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3256",
                            "Franchisee_Name": "Kidzee Parali Road",
                            "Operating_Status": "A",
                            "Address1": "H.No.9-1261, Survey No.171,",
                            "Address2": "Annabhau Sathe Chouk,",
                            "Place": "Parali Road, Opp. Yogeshwari Vidyalay",
                            "City_Id": "1088",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3256@kidzee.com",
                            "Mobile_No": "8888339284",
                            "Phone_No1": "8888339284",
                            "Phone_No2": "",
                            "Latitude": "18.73159",
                            "Longitude": "76.389423"
                         }
                      },
                      {
                         "City_Name": "Bhadgaon"
                      },
                      {
                         "City_Name": "Panvel"
                      },
                      {
                         "City_Name": "Mahad"
                      },
                      {
                         "City_Name": "Osmanabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-L-C-1583",
                               "Franchisee_Name": "Kidzee-Osmanabad",
                               "Operating_Status": "A",
                               "Address1": "139/2,  Near Swayamwar Mangal Karyalaya,",
                               "Address2": "",
                               "Place": "Barshi Naka",
                               "City_Id": "1127",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1583@kidzee.com",
                               "Mobile_No": "9028597781",
                               "Phone_No1": "02472228881",
                               "Phone_No2": "",
                               "Latitude": "18.186066",
                               "Longitude": "76.041941"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3293",
                               "Franchisee_Name": "Kidzee Kalamb",
                               "Operating_Status": "A",
                               "Address1": "House No-1660, Sr-No-17-B,",
                               "Address2": "Jijau Nagar,",
                               "Place": "Kallamb,",
                               "City_Id": "1127",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3293@kidzee.com",
                               "Mobile_No": "9423342216",
                               "Phone_No1": "7774961207",
                               "Phone_No2": "",
                               "Latitude": "18.572454",
                               "Longitude": "76.029762"
                            },
                            {
                               "Franchisee_Code": "W-R-C-4778",
                               "Franchisee_Name": "Kidzee Omerga",
                               "Operating_Status": "A",
                               "Address1": "New Balaji Nagar, Ganesh Nagar,",
                               "Address2": "Behind- Mugale Hospital,",
                               "Place": "OMERGA ,Behind Mughle Hospital ",
                               "City_Id": "1127",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4778@kidzee.com",
                               "Mobile_No": "9730037593",
                               "Phone_No1": "9730037593",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Durgapur"
                      },
                      {
                         "City_Name": "Malegaon"
                      },
                      {
                         "City_Name": "Ashti"
                      },
                      {
                         "City_Name": "Baramati",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-5476",
                            "Franchisee_Name": "Kidzee Baramati",
                            "Operating_Status": "A",
                            "Address1": "140 / Plot No-1,Malgunde nagar",
                            "Address2": "infront of hotel Abhishek, MIDC Road ,",
                            "Place": "Jalochi",
                            "City_Id": "1260",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5476@kidzee.com",
                            "Mobile_No": "9028414038",
                            "Phone_No1": "9028414038",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Bavdhan"
                      },
                      {
                         "City_Name": "Bhandara",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3104",
                            "Franchisee_Name": "Kidzee Bhandara",
                            "Operating_Status": "A",
                            "Address1": "C/O Sonu Kurenjekar,",
                            "Address2": "Vidya Nagar, Nagpur Road,",
                            "Place": "Near MSEB Office",
                            "City_Id": "1273",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3104@kidzee.com",
                            "Mobile_No": "7184256996",
                            "Phone_No1": "07184-256996",
                            "Phone_No2": "",
                            "Latitude": "21.175011",
                            "Longitude": "79.655824",
                            "Images": {
                               "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1247299/10495/b11d3a48_0550_4ba8_85ed_6b7b1550aec8.jpg"
                            }
                         }
                      },
                      {
                         "City_Name": "Borgaon manju"
                      },
                      {
                         "City_Name": "Ghatanji"
                      },
                      {
                         "City_Name": "Kaij"
                      },
                      {
                         "City_Name": "Lakhandur"
                      },
                      {
                         "City_Name": "Naldurg"
                      },
                      {
                         "City_Name": "Palghar",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4818",
                            "Franchisee_Name": "Kidzee Virar West-Global City",
                            "Operating_Status": "A",
                            "Address1": "Shop no 114,115,116,J Avenue",
                            "Address2": "Evershine Global city,Dongarpada",
                            "Place": "Virar -West,",
                            "City_Id": "1279",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4818@kidzee.com",
                            "Mobile_No": "7420823349",
                            "Phone_No1": "7506185356 ",
                            "Phone_No2": "",
                            "Latitude": "19.473288576394786",
                            "Longitude": "72.80812684446573"
                         }
                      },
                      {
                         "City_Name": "Pali"
                      },
                      {
                         "City_Name": "Palus"
                      },
                      {
                         "City_Name": "Panchgani"
                      },
                      {
                         "City_Name": "Paranda"
                      },
                      {
                         "City_Name": "Phaltan"
                      },
                      {
                         "City_Name": "Satara",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2829",
                               "Franchisee_Name": "Kidzee Phaltan",
                               "Operating_Status": "A",
                               "Address1": "Om Sadguru Bunglow,",
                               "Address2": "Sagunamata Nagar,",
                               "Place": "Malthan, Phaltan, Near- Sriram Bazzar,",
                               "City_Id": "1316",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2829@kidzee.com",
                               "Mobile_No": "9623122111",
                               "Phone_No1": "9623122111",
                               "Phone_No2": "",
                               "Latitude": "17.984451",
                               "Longitude": "74.436042"
                            },
                            {
                               "Franchisee_Code": "A-W-A-1055",
                               "Franchisee_Name": "Ankurum  Shahupuri",
                               "Operating_Status": "A",
                               "Address1": "S.No. 401/3E/1 Azadnagar, ",
                               "Address2": "Shahupuri, Dist- Satara",
                               "Place": "Shahupuri, ,Molcha Odha near Azad Bakery",
                               "City_Id": "1316",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "ankurumsatara1055@gmail.com",
                               "Mobile_No": "9960884870",
                               "Phone_No1": "02162296060",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Udgir"
                      },
                      {
                         "City_Name": "Chiplun"
                      },
                      {
                         "City_Name": "Dhule",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3250",
                            "Franchisee_Name": "Kidzee Dhule",
                            "Operating_Status": "A",
                            "Address1": "7, Samrat Nagar,",
                            "Address2": "B/H Krushna Dairy, 100ft Road,",
                            "Place": "Jamanagiri Road, Dhule, Jamanagiri Road, Dhule,",
                            "City_Id": "1417",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3250@kidzee.com",
                            "Mobile_No": "9822274746",
                            "Phone_No1": "9822274746",
                            "Phone_No2": "",
                            "Latitude": "20.8794",
                            "Longitude": "74.75965"
                         }
                      },
                      {
                         "City_Name": "Hatkanangale"
                      },
                      {
                         "City_Name": "Junnar"
                      },
                      {
                         "City_Name": "Kalyan",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-4882",
                               "Franchisee_Name": "Kidzee Bhoiwada Kalyan",
                               "Operating_Status": "A",
                               "Address1": "Shop no 1,2,3 and 4, Ground floor,",
                               "Address2": "Sarvodaya Garden,",
                               "Place": "Bhoiwada ,Sarvodaya Garden,",
                               "City_Id": "1503",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4882@kidzee.com",
                               "Mobile_No": "9930909669",
                               "Phone_No1": "9930909669",
                               "Phone_No2": "",
                               "Latitude": "19.234592",
                               "Longitude": "73.125845"
                            },
                            {
                               "Franchisee_Code": "W-R-C-5063",
                               "Franchisee_Name": "Kidzee Kalyan Adharwadi Chowk",
                               "Operating_Status": "A",
                               "Address1": "Shop No – 1 to 6, Apoorva Tower, Adharwadi chock,",
                               "Address2": "Near Kalyan sports club,  Kalyan west",
                               "Place": "Adharwadi ,Near Kalyan Sports Club",
                               "City_Id": "1503",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5063@kidzee.com",
                               "Mobile_No": "8411878000",
                               "Phone_No1": "8411868000",
                               "Phone_No2": "",
                               "Latitude": "19.15087",
                               "Longitude": "73.07293"
                            }
                         ]
                      },
                      {
                         "City_Name": "Umred",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-2790",
                            "Franchisee_Name": "Kidzee-Umred, Nagpur",
                            "Operating_Status": "A",
                            "Address1": "AT-Budhwaripeth, Near-Gramin Rugnalay,",
                            "Address2": "Tal- Umred, Dist- Nagpur,",
                            "Place": "Umred, Nagpur, At Budhawari Peth,",
                            "City_Id": "1522",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2790@kidzee.com",
                            "Mobile_No": "9822228151",
                            "Phone_No1": "9822228151",
                            "Phone_No2": "",
                            "Latitude": "20.842072",
                            "Longitude": "79.326108"
                         }
                      },
                      {
                         "City_Name": "Akola",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-2899",
                               "Franchisee_Name": "Kidzee Ram Nagar, Akola",
                               "Operating_Status": "A",
                               "Address1": "H-12, Mahada Colony,",
                               "Address2": "Near- Gupta Hospital,",
                               "Place": "Ram Nagar, Akola ,Mahada Colony",
                               "City_Id": "1542",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2899@kidzee.com",
                               "Mobile_No": "9850616002",
                               "Phone_No1": "9403871777",
                               "Phone_No2": "",
                               "Latitude": "20.799428",
                               "Longitude": "77.011563",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2899/257/8e98f95a_59e1_420f_9f82_6a64036a6705.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2899/257/587e4d28_0c5c_4dd8_841f_def81943dbe9.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "A-W-A-1023",
                               "Franchisee_Name": "Ankurum Murtizapur",
                               "Operating_Status": "A",
                               "Address1": "Plot No-33, Adarsh Colony,",
                               "Address2": "Station Area,",
                               "Place": "Murtujapaur,",
                               "City_Id": "1542",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "ms.spnassociates@gmail.com",
                               "Mobile_No": "9175505999",
                               "Phone_No1": "8149493777",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Washim",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-4854",
                            "Franchisee_Name": "ARUN SHIVAJIRAO KANKAL",
                            "Operating_Status": "A",
                            "Address1": "Plot No-12/East Part,",
                            "Address2": "Ward N-2 Near Shivam Gas Agency,",
                            "Place": "Kidzee-Nagartas Road, Maleaon,",
                            "City_Id": "1543",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4854@kidzee.com",
                            "Mobile_No": "9503465389",
                            "Phone_No1": "9503465389",
                            "Phone_No2": "",
                            "Latitude": "20.243161",
                            "Longitude": "77.003875"
                         }
                      },
                      {
                         "City_Name": "Malegaon"
                      },
                      {
                         "City_Name": "Bhusawal"
                      },
                      {
                         "City_Name": "Bhusawal"
                      },
                      {
                         "City_Name": "Nandurbar"
                      },
                      {
                         "City_Name": "Kallamb"
                      },
                      {
                         "City_Name": "Alibag",
                         "Franchisee": {
                            "Franchisee_Code": "W-R-C-3749",
                            "Franchisee_Name": "Kidzee Varsoli",
                            "Operating_Status": "A",
                            "Address1": "Tantrikvidyalaya, Plot No-1,",
                            "Address2": "House No-1128, S.No.52/3,",
                            "Place": "Varsoli, Near Tantrik Vidyalaya,",
                            "City_Id": "1642",
                            "State_Id": "23",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3749@kidzee.com",
                            "Mobile_No": "8698203837",
                            "Phone_No1": "02141224420",
                            "Phone_No2": "",
                            "Latitude": "18.660355",
                            "Longitude": "72.878179",
                            "Images": [
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10493/06be65ef_bfba_4ef3_91a1_48db39a337b6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10493/bbc1e9a2_d513_46d5_ad55_1206229381ef.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10493/0a3601e6_dec1_470f_bac2_073d3d23f115.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10493/744a8b8b_5418_493f_aca4_82a78c5d1537.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10493/cbb1092c_64e3_4d42_b725_ce4215d085e9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10494/cb28bea8_f40a_4d8a_b081_8ec7e3a89d3a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10494/e9860d2b_d83d_401a_9208_ef3a45cacdc5.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10494/964b1582_aa45_484a_b4fb_d60cd65cd934.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10494/4a9fc082_e36e_4a9f_9af1_7883274bbb1c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10494/2b137d89_2f8f_457a_9b94_aa7a2f07288a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10494/daf2b0c4_a468_4f05_a25c_8d5bad90994a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10494/a1911480_9512_4799_92b3_cd71e7a79e40.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/4bbfe001_3e39_41b8_a13c_7665ab798ffc.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/a17e8382_e45e_4aa5_a568_432dacec2535.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/f9629919_f5bc_414a_ac7d_d901c72e5abc.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/123ee286_f81f_4b28_a571_e42ed9114cc0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/9afb1291_7804_48d3_88c0_193fa7cbac36.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/d5edf5ce_7700_4968_a1b3_3bac233e43f2.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/9e126075_301d_4684_b0d9_c6c47de74b23.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/16ac4637_271f_4b9c_b564_7512e952101c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/911d5a5e_f54c_4bcc_8e3d_5d6e04dbf9ce.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/d4a09780_08c9_4fa7_bff7_7ca7790114be.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10496/6197ac0d_b8ad_46f9_8019_4d5778580964.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10497/fdb0c416_9f6f_412d_a391_36bd676468eb.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10497/0b36156b_d573_428a_ae1d_0df8322468b9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1637197/10497/c0ce49ea_3231_4c37_8d58_f9adda895c91.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "YAVATMAL",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "W-R-C-3964",
                               "Franchisee_Name": "KIDZEE  AARNI",
                               "Operating_Status": "A",
                               "Address1": "Behind- Giri Complex,",
                               "Address2": "Shivneri Chouk,",
                               "Place": "Aarni, Shivneri Cholk,",
                               "City_Id": "1656",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3964@kidzee.com",
                               "Mobile_No": "8983436863",
                               "Phone_No1": "8983436863",
                               "Phone_No2": "",
                               "Latitude": "20.071898",
                               "Longitude": "77.953743"
                            },
                            {
                               "Franchisee_Code": "W-R-C-3966",
                               "Franchisee_Name": "KIDZEE UMERKHED",
                               "Operating_Status": "A",
                               "Address1": "C/o,Sarda's Kidzee, Chowbhara chowk,",
                               "Address2": "Near Ganesh Temple, at Post - Umarkhed",
                               "Place": "Umarkhed, District - Yavatmal,Chobhara cholk",
                               "City_Id": "1656",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3966@kidzee.com",
                               "Mobile_No": "7755904070",
                               "Phone_No1": "9004215021",
                               "Phone_No2": "",
                               "Latitude": "19.597361",
                               "Longitude": "77.69075",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/d0075c26_efbd_46f7_a617_f7dcb548552c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/8bd483eb_d358_425f_9641_d09b7c02895d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/2547ef52_c481_4902_8779_fa79a9511a14.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/fc32dff7_efa0_4809_914e_833fbd66d639.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/75d5d383_8dec_40ee_b2fc_ed5818bffe36.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/629a667e_7247_4425_a433_b6d9ac24a043.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/70034e56_5ed0_4a71_9f75_340a67ed1a96.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/1feedb6d_7dc0_47d1_9d21_1121300e96fa.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/f00bbecd_908d_4bb4_8456_1b312a233d1c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/8b004df8_816d_465d_9df7_a3fea6d7d770.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1937750/10746/19b3304e_2286_48ed_b1f3_2efbf772d4d0.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "A-W-A-1039",
                               "Franchisee_Name": "Ankurum Ghatanji",
                               "Operating_Status": "A",
                               "Address1": "Plot No- 2,3, Soham Arcade,",
                               "Address2": "Yavatmal Road,",
                               "Place": "Ghatanji, Near-State Bank Of India,",
                               "City_Id": "1656",
                               "State_Id": "23",
                               "Country_Id": "1",
                               "Email_Id": "MSAGAR171@GMAIL.COM",
                               "Mobile_No": "9967155551",
                               "Phone_No1": "9967155551",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Tumsar"
                      },
                      {
                         "City_Name": "Sawantwadi"
                      }
                   ]
                },
                {
                   "State_Name": "Manipur",
                   "City": [
                      {
                         "City_Name": "Imphal",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2779",
                               "Franchisee_Name": "Kidzee Imphal Manipur",
                               "Operating_Status": "A",
                               "Address1": "Chingamakha Maisnam Leikai,",
                               "Address2": "Singjamei,",
                               "Place": "Imphal West,",
                               "City_Id": "1167",
                               "State_Id": "24",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2779@kidzee.com",
                               "Mobile_No": "9612567538",
                               "Phone_No1": "9612567538",
                               "Phone_No2": "",
                               "Latitude": "24.779688086043038",
                               "Longitude": "93.93442008863826"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4018",
                               "Franchisee_Name": "Kidzee Chingmeirong Nongpok",
                               "Operating_Status": "A",
                               "Address1": "Paomei Colony, Heingang Road,",
                               "Address2": "Chingmeirong Nongpok,",
                               "Place": "Imphal-East, Paomei Colony,",
                               "City_Id": "1167",
                               "State_Id": "24",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4018@kidzee.com",
                               "Mobile_No": "7640952745",
                               "Phone_No1": "7640952745",
                               "Phone_No2": "",
                               "Latitude": "24.50244",
                               "Longitude": "93.56493"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4551",
                               "Franchisee_Name": "Kidzee Machalema",
                               "Operating_Status": "A",
                               "Address1": "Southern Palace Compound,",
                               "Address2": "Wankeyia,",
                               "Place": "Machalema,",
                               "City_Id": "1167",
                               "State_Id": "24",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4551@kidzee.com",
                               "Mobile_No": "8974634284",
                               "Phone_No1": "8974634284",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "A-E-A-1035",
                               "Franchisee_Name": "Ankurum Bishnupur",
                               "Operating_Status": "A",
                               "Address1": "C/O- Philem Nilamani Singh,",
                               "Address2": "Moirang Phiwangbam Lekiai,",
                               "Place": "Bishnupur, Moirang,",
                               "City_Id": "1167",
                               "State_Id": "24",
                               "Country_Id": "1",
                               "Email_Id": "KSURCHANDRA@GMAIL.COM",
                               "Mobile_No": "8136080854",
                               "Phone_No1": "9862907369",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Moirang",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5347",
                            "Franchisee_Name": "Kidzee Uyungmakhong",
                            "Operating_Status": "A",
                            "Address1": "Lairenjam,",
                            "Address2": "Leirak,",
                            "Place": "Uyungmakhong",
                            "City_Id": "1966",
                            "State_Id": "24",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5347@kidzee.com",
                            "Mobile_No": "8974026641",
                            "Phone_No1": "8974026641",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Meghalaya",
                   "City": [
                      {
                         "City_Name": "Shillong",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-C-928",
                               "Franchisee_Name": "KIDZEE-NONG THYMMAI-SHILLONG, IAHCHAW",
                               "Operating_Status": "A",
                               "Address1": "Lumbasuk-A,",
                               "Address2": "Nr-IIM institute,",
                               "Place": "Nong Thymmai,Near-IIM,",
                               "City_Id": "895",
                               "State_Id": "25",
                               "Country_Id": "1",
                               "Email_Id": "kidzee928@kidzee.com",
                               "Mobile_No": "9856006373",
                               "Phone_No1": "9856007605",
                               "Phone_No2": "",
                               "Latitude": "25.578773",
                               "Longitude": "91.893254"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2369",
                               "Franchisee_Name": "Kidzee Shillong East khasi Hills Dist",
                               "Operating_Status": "A",
                               "Address1": "Ibasuk Opposite longkmie gas agency,",
                               "Address2": "Laban Last Bus Stop",
                               "Place": "East Khasi Hills Dist ,Gas Agency",
                               "City_Id": "895",
                               "State_Id": "25",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2369@kidzee.com",
                               "Mobile_No": "9856804016",
                               "Phone_No1": "9089065432",
                               "Phone_No2": "",
                               "Latitude": "25.578773",
                               "Longitude": "91.893254"
                            }
                         ]
                      },
                      {
                         "City_Name": "Jowai / Jwai"
                      },
                      {
                         "City_Name": "Tura",
                         "Franchisee": {
                            "Franchisee_Code": "E-U-C-870",
                            "Franchisee_Name": "KIDZEE-NEW TURA- Meghalaya-Kidz Nursery",
                            "Operating_Status": "A",
                            "Address1": "Near M.S.E.B Office Hawakhana Tur,",
                            "Address2": "Near- Me.SEB office,",
                            "Place": "M.S.E.B Office, Near- Petrol Pump,",
                            "City_Id": "1121",
                            "State_Id": "25",
                            "Country_Id": "1",
                            "Email_Id": "kidzee870@kidzee.com",
                            "Mobile_No": "9089855919",
                            "Phone_No1": "9089855919",
                            "Phone_No2": "",
                            "Latitude": "25.511203",
                            "Longitude": "90.219721"
                         }
                      },
                      {
                         "City_Name": "Madanrting"
                      }
                   ]
                },
                {
                   "State_Name": "Nagaland",
                   "City": [
                      {
                         "City_Name": "Dimapur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2932",
                               "Franchisee_Name": "Kidzee Dimapur Nagaland",
                               "Operating_Status": "A",
                               "Address1": "Zhimomi Appartment, Ground Floor,",
                               "Address2": "Kalibari Road,",
                               "Place": "Opp-DMC, Behind Ganpati Mandir",
                               "City_Id": "1055",
                               "State_Id": "26",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2932@kidzee.com",
                               "Mobile_No": "9862604484",
                               "Phone_No1": "9862604484",
                               "Phone_No2": "",
                               "Latitude": "25.90368",
                               "Longitude": "93.725678",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2932/194/10060683_3323_4a92_b488_1be1698fd5b2.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-R-C-4694",
                               "Franchisee_Name": "Kidzee Circular Road Dimapur",
                               "Operating_Status": "A",
                               "Address1": "Alpha House,",
                               "Address2": "Middle Point Colony, Near- MH Hospital,",
                               "Place": "Circular Road, Nepali Basti",
                               "City_Id": "1055",
                               "State_Id": "26",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2932@kidzee.com",
                               "Mobile_No": "8787631855",
                               "Phone_No1": "8787631855",
                               "Phone_No2": "",
                               "Latitude": "25.54378",
                               "Longitude": "93.43353"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5014",
                               "Franchisee_Name": "Kidzee Dimapur",
                               "Operating_Status": "A",
                               "Address1": "lhomithi Colony,",
                               "Address2": "Ground Floor,",
                               "Place": "Dimapur,",
                               "City_Id": "1055",
                               "State_Id": "26",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5014@kidzee.com",
                               "Mobile_No": "9521766451",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "25.54158",
                               "Longitude": "93.43333"
                            }
                         ]
                      },
                      {
                         "City_Name": "Wokha"
                      },
                      {
                         "City_Name": "Zunheboto"
                      },
                      {
                         "City_Name": "Kohima"
                      }
                   ]
                },
                {
                   "State_Name": "Odisha",
                   "City": [
                      {
                         "City_Name": "Angul",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4976",
                            "Franchisee_Name": "Kidzee ANGUL",
                            "Operating_Status": "A",
                            "Address1": "ward no 12,near TPM",
                            "Address2": "post:Hakimpara",
                            "Place": "Angul ,near TPMpost",
                            "City_Id": "79",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4976@kidzee.com",
                            "Mobile_No": "9172731188",
                            "Phone_No1": "9172731188",
                            "Phone_No2": "",
                            "Latitude": "20.828700",
                            "Longitude": "85.107122",
                            "Images": [
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11053/e7830f87_45fd_4443_b73a_7a1ba3578b71.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11053/9da7b720_27b3_4166_9d7f_9dcdd2ba5f4e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11076/54c00f38_e5e2_446a_bbe6_0a6110e0ae33.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11076/0f58c469_201e_4e6e_bd94_c3d36fb7f83a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11076/30f0bf8d_fbef_4b70_b355_9a5851b29b7e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11076/fce931a3_29e3_4700_ac37_d8cdf3288176.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11076/f6238a4f_8820_4780_a7d7_d20e286806a1.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11076/7c7fc80b_1f79_4299_a6e7_b614007d1c53.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/1ddb1eea_46e1_4538_9032_8a4854183837.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/7ad01679_1282_4ccb_b2db_f9b17e427fd8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/75dbd243_2411_4c8a_b190_501bd4cb9427.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/04498b2d_b2c5_49c6_bee6_689b64dfcc12.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/a55d28df_08b9_47cf_84b7_b19fd153a342.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/a22ae989_fe4e_4556_a569_03967b671f8d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/86cf188f_cbc1_454f_8416_ddc92a4a9493.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/cd81b736_e70d_4211_a1c1_9120ddbc2be4.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/959cf630_b20f_46b8_8728_484416d4bb37.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/4b988962_05ed_48ef_a666_a1b260e051d3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/6121f707_85b8_4e35_bafb_e4ec31d75a9f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/ae8420db_acbf_4a47_ac6e_57bf567e1262.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/e3ffa4d5_44eb_4698_ad36_8f4ef3d02eff.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/5eadce87_a34e_4f8d_8372_b9492f6aae5a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/3ef5294d_7d12_4565_9381_0717cec1ec8c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2732510/11085/296f6263_3f28_4ba4_921c_a0f1ea6ddeec.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Balasore",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-5802",
                               "Franchisee_Name": "Kidzee Bhaskarganj",
                               "Operating_Status": "A",
                               "Address1": "Gulmohar Building Ground Floor",
                               "Address2": "Bidyut MArg",
                               "Place": "Bhaskarganj",
                               "City_Id": "103",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5802@kidzee.com",
                               "Mobile_No": "8455886666",
                               "Phone_No1": "8455886666",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5318",
                               "Franchisee_Name": "Kidzee Balasore",
                               "Operating_Status": "A",
                               "Address1": "Gopalgaon,",
                               "Address2": "P.O Srikanthapur ,Ps.Town,",
                               "Place": "Balasore",
                               "City_Id": "103",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5318@kidzee.com",
                               "Mobile_No": "9861980908",
                               "Phone_No1": "9861980908",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Brahmapur / Berhampur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-5098",
                               "Franchisee_Name": "Kidzee Hill Patna",
                               "Operating_Status": "A",
                               "Address1": "Near Govt. Girls High School",
                               "Address2": "Back Side of Giri Road",
                               "Place": "Hill Patna ,Near Govt. Girls High School",
                               "City_Id": "136",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5098@kidzee.com",
                               "Mobile_No": "9985447162",
                               "Phone_No1": "9985447162",
                               "Phone_No2": "",
                               "Latitude": "19.303267",
                               "Longitude": "84.797209"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5550",
                               "Franchisee_Name": "Kidzee Ganjam",
                               "Operating_Status": "A",
                               "Address1": "Ghanshyam Mansion, Ps Bada Bazar.",
                               "Address2": "Main Road, Prem Nagar 6th Lane Extension",
                               "Place": "Ganjam",
                               "City_Id": "136",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5550@kidzee.com",
                               "Mobile_No": "9439868996",
                               "Phone_No1": "7008954446",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Bhubaneswar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2275",
                               "Franchisee_Name": "Kidzee BJB Nagar  Bhubneswar",
                               "Operating_Status": "A",
                               "Address1": "C21, BJB nagar,",
                               "Address2": "Kalpana Area,",
                               "Place": "Baragada, Near Arts block, BJB College",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2275@kidzee.com",
                               "Mobile_No": "9658055552",
                               "Phone_No1": "9658055552",
                               "Phone_No2": "",
                               "Latitude": "20.257318",
                               "Longitude": "85.846704",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/744441/10920/40e1b8b6_2f2b_4573_8f08_c49d640b828a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/744441/10920/2a2d2510_426e_4d5b_8d28_ee4d3c7fcb12.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/744441/10920/0591fc47_ec41_4944_a936_2ecf527b4858.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/744441/10920/5710e435_c1f4_48f0_b5a6_46b9e62ad39f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/744441/10920/5ff7ad66_a7fe_49ec_878c_24c168dcb2e8.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3117",
                               "Franchisee_Name": "Kidzee Shahid Nagar Bhubaneshwar",
                               "Operating_Status": "A",
                               "Address1": "Sri Ram Nivas, Plot No-21/P,",
                               "Address2": "",
                               "Place": "Shahid Nagar, Behind Maharshi College",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3117@kidzee.com",
                               "Mobile_No": "8249100287",
                               "Phone_No1": "8249100287",
                               "Phone_No2": "",
                               "Latitude": "20.284562",
                               "Longitude": "85.855581"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2750",
                               "Franchisee_Name": "KIDZEE SUNDERPADA",
                               "Operating_Status": "A",
                               "Address1": "Plot No-258, Raghunath Niwas,",
                               "Address2": "Air Field,",
                               "Place": "Sunderpada ,Opp- Hero Showroom,",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2750@kidzee.com",
                               "Mobile_No": "7978950845",
                               "Phone_No1": "9238125844",
                               "Phone_No2": "",
                               "Latitude": "20.224496",
                               "Longitude": "85.813229"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2433",
                               "Franchisee_Name": "KIDZEE BHUBANESWAR  PALASPALLI",
                               "Operating_Status": "A",
                               "Address1": "A/53, BDA Duplex,",
                               "Address2": "Near Airport,",
                               "Place": "Palaspalli ,Near Punama Gate Flyover,",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2433@kidzee.com",
                               "Mobile_No": "9658852540",
                               "Phone_No1": "9776024624",
                               "Phone_No2": "",
                               "Latitude": "20.295921",
                               "Longitude": "85.824305"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4310",
                               "Franchisee_Name": "Kidzee Hanspal",
                               "Operating_Status": "A",
                               "Address1": "D177, Metro Satellite City,",
                               "Address2": "Puri Canal Road,",
                               "Place": "Hanspal, Metro Satellite City,",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4310@kidzee.com",
                               "Mobile_No": "9348132439",
                               "Phone_No1": "8763-413412",
                               "Phone_No2": "",
                               "Latitude": "20.317310325551826",
                               "Longitude": "85.88972926139832"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4378",
                               "Franchisee_Name": "KidzeeSamantrapur",
                               "Operating_Status": "A",
                               "Address1": "Plot No-104,",
                               "Address2": "Anand vihar, Nuagaon Municipality Road,",
                               "Place": "Samantrapur, Opposite Of Nuagaon Petrol Station,",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4378@Kidzee.com",
                               "Mobile_No": "9853388848",
                               "Phone_No1": "9853388848",
                               "Phone_No2": "",
                               "Latitude": "20.221352",
                               "Longitude": "85.842899"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4536",
                               "Franchisee_Name": "Kidzee Patrapada",
                               "Operating_Status": "A",
                               "Address1": "HIG -383, K9A,",
                               "Address2": "Kalinga Nagar,",
                               "Place": "Patrapada, Near DN Regalia Mall,",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4536@kidzee.com",
                               "Mobile_No": "9348539442",
                               "Phone_No1": "9348539442",
                               "Phone_No2": "",
                               "Latitude": "20.237634643708706",
                               "Longitude": "85.75991315502053"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4539",
                               "Franchisee_Name": "Kidzee Kalinga Nagar",
                               "Operating_Status": "A",
                               "Address1": "K8-1132,",
                               "Address2": "",
                               "Place": "Kalinga Nagar, Near-Sum Hospital,",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4539@kidzee.com",
                               "Mobile_No": "9556149730",
                               "Phone_No1": "0674-272005",
                               "Phone_No2": "",
                               "Latitude": "20.279850",
                               "Longitude": "85.764019",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10953/369ba22c_cb9c_4a84_b71d_947be5240507.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10955/975d5bbf_9f07_4115_b6f5_f8ce49f91b54.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10952/3e53163d_88c5_447a_b2e8_baa9d3efd733.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10954/5be10639_75f4_49ff_be36_9060688c310f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10951/ad5fa457_d6ba_4c72_b04c_dd0c22992c7a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10951/fb6fec6a_a9f9_4c2f_a1af_b20476df3450.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10952/759cb3ce_fbdc_4149_8dc3_c50c9d574972.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10953/dcc35b16_c9f9_41b3_a0a7_b22767cfd9cf.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10954/23852caa_85c0_4579_bfdf_8d7b336b18b6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10953/dbab3cad_93f9_4217_8503_8e2ee5e24205.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10953/b5d4551e_4a6c_4d9f_b225_d95f26bae5fc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10951/e6a8eea6_4e30_4509_9173_e6d2ce03a04c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10951/94a34457_e628_47c4_878b_7cedc670db66.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10956/a58fffb5_8624_4b9b_8bd6_0b64b36cb333.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2449232/10956/8e699fac_49b5_42cd_a4a5_c0f642570506.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4649",
                               "Franchisee_Name": "Kidzee Shrikhetra Vihar",
                               "Operating_Status": "A",
                               "Address1": "AV-25, Khandgiri,",
                               "Address2": "Aiginia,",
                               "Place": "Shreekhetra vihar, Aiginia",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4649@kidzee.com",
                               "Mobile_No": "8144693097",
                               "Phone_No1": "0674-2542280",
                               "Phone_No2": "",
                               "Latitude": "20.24765",
                               "Longitude": "85.77519",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2481673/10970/2390a610_5d3e_4dab_940d_239d43641cf8.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-R-C-3637",
                               "Franchisee_Name": "KIDZEE BOMIKHAL",
                               "Operating_Status": "A",
                               "Address1": "Plot No-394, Bomikhal,",
                               "Address2": "Ward No-12, Khurdha,",
                               "Place": "Bomikhal, Near- Santoshi Aluminium & Fabrication,",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3637@kidzee.com",
                               "Mobile_No": "9658055552",
                               "Phone_No1": "9658055552",
                               "Phone_No2": "",
                               "Latitude": "20.28459",
                               "Longitude": "85.86192"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4535",
                               "Franchisee_Name": "Kidzee Shastri Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot No-1482,",
                               "Address2": "",
                               "Place": "Shastri Nagar, Plot No-1482, Unit -4,",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4535@kidzee.com",
                               "Mobile_No": "8144269369",
                               "Phone_No1": "8144269369",
                               "Phone_No2": "",
                               "Latitude": "20.28503",
                               "Longitude": "85.82541"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5074",
                               "Franchisee_Name": "Kidzee Raghunathpur",
                               "Operating_Status": "A",
                               "Address1": "NO.792/2655,TARINI VIHAR,",
                               "Address2": "P S Nandankanan,Dist Khorda",
                               "Place": "RAGHUNATHPUR ,Near Lalchand Jewellers",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5074@kidzee.com",
                               "Mobile_No": "9556228799",
                               "Phone_No1": "9556228799",
                               "Phone_No2": "",
                               "Latitude": "20.37291",
                               "Longitude": "85.83258"
                            },
                            {
                               "Franchisee_Code": "A-E-A-1087",
                               "Franchisee_Name": "ANKURUM SUNDERPADA",
                               "Operating_Status": "A",
                               "Address1": "PLOT NO.327/3257, ASHIRBAD PLAZA LANE ",
                               "Address2": "ORAKAL, P.O KANTILO, NR. HIGH TECH ANNEX APARTMENT ,DIST.KHURDA",
                               "Place": "SUNDERPADA",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "umeshchandradash90@gmail.com",
                               "Mobile_No": "9583297687",
                               "Phone_No1": "9583297687",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5382",
                               "Franchisee_Name": "KIDZEE MAITRI VIHAR",
                               "Operating_Status": "A",
                               "Address1": "House No 5-89 Near Amruta park Crossing lemon tree",
                               "Address2": "Left lane of police station",
                               "Place": "MAITRI VIHAR",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "kidzee5074@kidzee.com",
                               "Mobile_No": "7978882577",
                               "Phone_No1": "7978882577",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-S-5821",
                               "Franchisee_Name": "Kidzee Pokhariput,",
                               "Operating_Status": "A",
                               "Address1": "MIG - 41, Ground Floor, Ananta Vihar ,",
                               "Address2": "Khordha Dist.",
                               "Place": "Pokhariput,",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5821@kidzee.com",
                               "Mobile_No": "6396864275",
                               "Phone_No1": "6396864275",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5634",
                               "Franchisee_Name": "Kidzee Ghitikia",
                               "Operating_Status": "A",
                               "Address1": "HIG 399. Kalinga Vihar. Mouza",
                               "Address2": "Subudhipur. PO Kalinga Vihar. PS Tamando. Dist Khurda.",
                               "Place": "Ghitikia",
                               "City_Id": "163",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5634@kidzee.com",
                               "Mobile_No": "8637283300",
                               "Phone_No1": "8637283300",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Cuttack"
                      },
                      {
                         "City_Name": "Koraput"
                      },
                      {
                         "City_Name": "Jeypore"
                      },
                      {
                         "City_Name": "Jharsuguda",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-3779",
                               "Franchisee_Name": "Kidzee Brajrajnagar",
                               "Operating_Status": "A",
                               "Address1": "Hari Om Vihar",
                               "Address2": " Plot No-4961/6890",
                               "Place": "Brajrajnagar ,Near Laxmi mandir chowk, Women's col",
                               "City_Id": "498",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3779@kidzee.com",
                               "Mobile_No": "7787826999",
                               "Phone_No1": "1234-000000",
                               "Phone_No2": "",
                               "Latitude": "21.83797",
                               "Longitude": "83.925342"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5531",
                               "Franchisee_Name": "KIDZEE JHARSUGUDA",
                               "Operating_Status": "A",
                               "Address1": "VEDANTA MEADOWS , VEDANTA TOWNSHIP ",
                               "Address2": "VILLAGE- BURKHAMUNDA ",
                               "Place": "JHARSUGUDA ",
                               "City_Id": "498",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3050@kidzee.com",
                               "Mobile_No": "8763410166",
                               "Phone_No1": "8763410166",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Joda",
                         "Franchisee": {
                            "Franchisee_Code": "E-U-C-669",
                            "Franchisee_Name": "KIDZEE-JODA-ORISSA, ABHIGYAN MANDIR",
                            "Operating_Status": "A",
                            "Address1": "Abhigyana Mandir,",
                            "Address2": "",
                            "Place": "Po-Boneikela, Near-Joda Womens College,",
                            "City_Id": "508",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "kidzee669@kidzee.com",
                            "Mobile_No": "9437073700",
                            "Phone_No1": "9178181162",
                            "Phone_No2": "",
                            "Latitude": "21.628933",
                            "Longitude": "85.581685"
                         }
                      },
                      {
                         "City_Name": "Kendujhar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-A-M-1311",
                               "Franchisee_Name": "Kidzee- Keonjhar- Orissa- Sikhyadan",
                               "Operating_Status": "A",
                               "Address1": "Kamaragoda,",
                               "Address2": "Near Ram Mandir, Keonjhar,",
                               "Place": "Opp: Orange Bakery, College Road",
                               "City_Id": "552",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1311@kidzee.com",
                               "Mobile_No": "9937067610",
                               "Phone_No1": "9937067610",
                               "Phone_No2": "",
                               "Latitude": "21.655211",
                               "Longitude": "85.609296"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4529",
                               "Franchisee_Name": "Kidzee Mining Road,The Origin",
                               "Operating_Status": "A",
                               "Address1": "Mining Road, 41/412,",
                               "Address2": "Land Mark, Near-Govt Diploma Engineering College,",
                               "Place": "Mining Road, Keonjhar, Beside-DIC Office,",
                               "City_Id": "552",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4529@kidzee.com",
                               "Mobile_No": "9776060360",
                               "Phone_No1": "9090284848",
                               "Phone_No2": "",
                               "Latitude": "21.636525",
                               "Longitude": "85.622944"
                            }
                         ]
                      },
                      {
                         "City_Name": "Puri"
                      },
                      {
                         "City_Name": "Rourkela",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2386",
                               "Franchisee_Name": "Kidzee Rourkela Orrisa",
                               "Operating_Status": "A",
                               "Address1": "BB- 25,",
                               "Address2": "Near Church, Civil Township",
                               "Place": "Civil Township, Near Church",
                               "City_Id": "849",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2386@kidzee.com",
                               "Mobile_No": "9937418504",
                               "Phone_No1": "9668029027",
                               "Phone_No2": "",
                               "Latitude": "22.224826",
                               "Longitude": "84.822902"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4805",
                               "Franchisee_Name": "Kidzee Chhend -  Rourkela",
                               "Operating_Status": "A",
                               "Address1": "MIICR-14,",
                               "Address2": "Sundargarh Dist, Rourkela PS Chhend",
                               "Place": "Chhend Colony ,Near VSS Market",
                               "City_Id": "849",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4805@kidzee.com",
                               "Mobile_No": "9556039422",
                               "Phone_No1": "9437139422",
                               "Phone_No2": "",
                               "Latitude": "22.239979",
                               "Longitude": "84.826196"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5145",
                               "Franchisee_Name": "KIDZEE KOELNAGAR",
                               "Operating_Status": "A",
                               "Address1": "Qr.No.C/6, Koelnagar",
                               "Address2": "Dist: Sundargarh Rourkela",
                               "Place": "Koelnagar",
                               "City_Id": "849",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5145@kidzee.com",
                               "Mobile_No": "9437139422",
                               "Phone_No1": "9437139422",
                               "Phone_No2": "",
                               "Latitude": "22.262887",
                               "Longitude": "84.892673"
                            }
                         ]
                      },
                      {
                         "City_Name": "Sambalpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-S-C-1477",
                               "Franchisee_Name": "Kidzee-Burla-Tiny Pearl",
                               "Operating_Status": "A",
                               "Address1": "AT/PO - Burla,",
                               "Address2": "Sambalpur Orissa",
                               "Place": "Burla ,Near Block S 45, Sadeipali Road,  Krishna M",
                               "City_Id": "868",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1477@kidzee.com",
                               "Mobile_No": "9437096134",
                               "Phone_No1": "7978598474",
                               "Phone_No2": "",
                               "Latitude": "21.496776",
                               "Longitude": "83.874309"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3050",
                               "Franchisee_Name": "Kidzee Sambalpur",
                               "Operating_Status": "A",
                               "Address1": "Pradhan Pada,",
                               "Address2": "",
                               "Place": "Sambalpur, Near-Jamkani Mandir,",
                               "City_Id": "868",
                               "State_Id": "28",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3050@kidzee.com",
                               "Mobile_No": "8763410166",
                               "Phone_No1": "9777342585",
                               "Phone_No2": "",
                               "Latitude": "21.4873540",
                               "Longitude": "83.9868090"
                            }
                         ]
                      },
                      {
                         "City_Name": "Talcher"
                      },
                      {
                         "City_Name": "Vyasanagar"
                      },
                      {
                         "City_Name": "Jajpur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3049",
                            "Franchisee_Name": "Kidzee  Kanheipur  Jajpur",
                            "Operating_Status": "A",
                            "Address1": "Plot No-1,Near-Shiva Temple,",
                            "Address2": "",
                            "Place": "Kanheipur, Near Shiva Mandir,",
                            "City_Id": "1046",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3049@kidzee.com",
                            "Mobile_No": "9937559217",
                            "Phone_No1": "8895549325",
                            "Phone_No2": "",
                            "Latitude": "20.961912",
                            "Longitude": "86.123015"
                         }
                      },
                      {
                         "City_Name": "Dhenkanal",
                         "Franchisee": {
                            "Franchisee_Code": "E-U-C-1020",
                            "Franchisee_Name": "Kidzee- Dhenkanal- Orissa- Grace",
                            "Operating_Status": "A",
                            "Address1": "Grace Kidzee Pre School,",
                            "Address2": "At/Po/Dist-Dhenkanal,",
                            "Place": "Near-Bus Stand ,Infront Of Rotary Club,",
                            "City_Id": "1153",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1020@kidzee.com",
                            "Mobile_No": "9337410403",
                            "Phone_No1": "7205836358",
                            "Phone_No2": "",
                            "Latitude": "20.6521770",
                            "Longitude": "85.6008830"
                         }
                      },
                      {
                         "City_Name": "Jagatsinghpur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4650",
                            "Franchisee_Name": "Kidzee Jagatsinghpur",
                            "Operating_Status": "A",
                            "Address1": "Jayabada,",
                            "Address2": "P.O-Chatra, Potal",
                            "Place": "Jagatsinghpur, Jayabada",
                            "City_Id": "1186",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4650@kidzee.com",
                            "Mobile_No": "9937176980",
                            "Phone_No1": "9937176980",
                            "Phone_No2": "",
                            "Latitude": "20.25803522804398",
                            "Longitude": "86.17281496524811"
                         }
                      },
                      {
                         "City_Name": "Sundergarh / Sundargarh"
                      },
                      {
                         "City_Name": "Bhadrak",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-1850",
                            "Franchisee_Name": "Kidzee Bhadrak Orissa",
                            "Operating_Status": "A",
                            "Address1": "C/O Ms. Snehalata Mohanty,",
                            "Address2": "Susila Bhawan At Samaraipur Post-Gelpur Via,",
                            "Place": "Madhabhagar, Near-Tarini Temple,",
                            "City_Id": "1286",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1850@kidzee.com",
                            "Mobile_No": "9090944027",
                            "Phone_No1": "7008837023",
                            "Phone_No2": "",
                            "Latitude": "21.058274",
                            "Longitude": "86.49584"
                         }
                      },
                      {
                         "City_Name": "Baripada",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2706",
                            "Franchisee_Name": "KIDZEE BARIPADA DST. MAYURBHANG",
                            "Operating_Status": "A",
                            "Address1": "Plot No.8/1039, Unit No-15, Mouza Darogadahi,",
                            "Address2": "AT-Baghra Road, Ward No-19, Mantri Chhak,",
                            "Place": "Baripada Dist- Mayurbhang, Opp - Kalia Bakery, Bes",
                            "City_Id": "1492",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2706@kidzee.com",
                            "Mobile_No": "9040044222",
                            "Phone_No1": "",
                            "Phone_No2": "",
                            "Latitude": "21.931707",
                            "Longitude": "86.720022"
                         }
                      },
                      {
                         "City_Name": "Tangi"
                      },
                      {
                         "City_Name": "Damonjodi"
                      },
                      {
                         "City_Name": "Balangir",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3334",
                            "Franchisee_Name": "Kidzee Balangir",
                            "Operating_Status": "A",
                            "Address1": "Jagannath Pada,",
                            "Address2": "PO & District:",
                            "Place": "Balangir, Jagannath Pada,",
                            "City_Id": "1594",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3334@kidzee.com",
                            "Mobile_No": "8895588955",
                            "Phone_No1": "06652-234522",
                            "Phone_No2": "",
                            "Latitude": "20.70704476634254",
                            "Longitude": "83.4911598306885",
                            "Images": [
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1563340/10832/8b231a5b_9302_4775_b97e_985422393492.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1563340/10911/6b83c1a2_54e7_4291_8cfb_00a8fdfb9216.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "RAYAGADA"
                      },
                      {
                         "City_Name": "Nayagarh",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5579",
                            "Franchisee_Name": "Kidzee New Town",
                            "Operating_Status": "A",
                            "Address1": "Near Town Police Station",
                            "Address2": "Po/ps/Dist-Nayagarh",
                            "Place": "New Town",
                            "City_Id": "1629",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5579@kidzee.com",
                            "Mobile_No": "9337472516",
                            "Phone_No1": "9337472516",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Damanjodi",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3182",
                            "Franchisee_Name": "Kidzee Damanjodi",
                            "Operating_Status": "A",
                            "Address1": "Nigam Kutira,",
                            "Address2": "",
                            "Place": "Satsang Bhejaput, Nigam Kutira",
                            "City_Id": "1631",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3182@kidzee.com",
                            "Mobile_No": "9437174663",
                            "Phone_No1": "06853253343",
                            "Phone_No2": "",
                            "Latitude": "18.750082178046572",
                            "Longitude": "82.91195690631866"
                         }
                      },
                      {
                         "City_Name": "Bhawanipatna",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3845",
                            "Franchisee_Name": "Kidzee Pramandpur",
                            "Operating_Status": "A",
                            "Address1": "Baid Neebas, Kesinga road  NH -26   Plot No-535,",
                            "Address2": " P.O - Bhwanipatna",
                            "Place": "Pramandpur ,Infront of IDBI Bank",
                            "City_Id": "1652",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3845@kidzee.com",
                            "Mobile_No": "9438418416",
                            "Phone_No1": "07008600277",
                            "Phone_No2": "",
                            "Latitude": "19.92362656578434",
                            "Longitude": "83.17405452761272"
                         }
                      },
                      {
                         "City_Name": "BRAHMAN BATI"
                      },
                      {
                         "City_Name": "Khorda",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4602",
                            "Franchisee_Name": "Kidzee Jatni",
                            "Operating_Status": "A",
                            "Address1": "Satyaaya, Mill,",
                            "Address2": "Ramchandrapur,",
                            "Place": "Jatni, Near Kali RIce Mill,",
                            "City_Id": "1819",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4602@kidzee.com",
                            "Mobile_No": "7788013333",
                            "Phone_No1": "9337369919",
                            "Phone_No2": "",
                            "Latitude": "20.16052",
                            "Longitude": "85.69936"
                         }
                      },
                      {
                         "City_Name": "Patia "
                      },
                      {
                         "City_Name": "Paradip",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4205",
                            "Franchisee_Name": "Kidzee Paradeep",
                            "Operating_Status": "A",
                            "Address1": "Plot No.457, Holding No- 561, Mouza - Bijayachandrapur,",
                            "Address2": "Tarinigada, P.O - Atharabanki, P.S - Paradeep, Dist - Jagatsingpur, City Paradip,",
                            "Place": "Paradeep,Near-Amaron Battery Shop,",
                            "City_Id": "1932",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4205@kidzee.com",
                            "Mobile_No": "6372000071",
                            "Phone_No1": "8018721123",
                            "Phone_No2": "",
                            "Latitude": "20.29739",
                            "Longitude": "86.64351"
                         }
                      },
                      {
                         "City_Name": "Nabarangapur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5551",
                            "Franchisee_Name": "Kidzee Nabarangapur",
                            "Operating_Status": "A",
                            "Address1": "Darubandha street",
                            "Address2": "Ground Floor",
                            "Place": "Nabarangapur",
                            "City_Id": "1974",
                            "State_Id": "28",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5551@kidzee.com",
                            "Mobile_No": "7873434928",
                            "Phone_No1": "7873434928",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Chatrapur"
                      }
                   ]
                },
                {
                   "State_Name": "Puducherry / Pondicherry",
                   "City": [
                      {
                         "City_Name": "White town",
                         "Franchisee": {
                            "Franchisee_Code": "S-B-M-407",
                            "Franchisee_Name": "KIZDEE-PONDICHERRY/BLOOMING BIRDS ",
                            "Operating_Status": "A",
                            "Address1": "#1,Subbiah Salai,",
                            "Address2": "",
                            "Place": "Subbiah Salai, Near Gulls Hotel",
                            "City_Id": "800",
                            "State_Id": "29",
                            "Country_Id": "1",
                            "Email_Id": "kidzee407@kidzee.com",
                            "Mobile_No": "9443268063",
                            "Phone_No1": "9840110880",
                            "Phone_No2": "",
                            "Latitude": "11.925202",
                            "Longitude": "79.828495"
                         }
                      },
                      {
                         "City_Name": "Karaikal",
                         "Franchisee": {
                            "Franchisee_Code": "S-A-M-1218",
                            "Franchisee_Name": "Kidzee - Karaikal ",
                            "Operating_Status": "A",
                            "Address1": "1st Cross, Pasumpon Nagar,",
                            "Address2": "Karaikal",
                            "Place": "Pondicherry ,Near royal mahal",
                            "City_Id": "1208",
                            "State_Id": "29",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1218@kidzee.com",
                            "Mobile_No": "9486841027",
                            "Phone_No1": "4368221493",
                            "Phone_No2": "",
                            "Latitude": "10.923375",
                            "Longitude": "79.837609"
                         }
                      },
                      {
                         "City_Name": "Mahe / Mayyazhi"
                      },
                      {
                         "City_Name": "Yanam / Yanaon"
                      }
                   ]
                },
                {
                   "State_Name": "Punjab",
                   "City": [
                      {
                         "City_Name": "Abohar"
                      },
                      {
                         "City_Name": "Amritsar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-3038",
                               "Franchisee_Name": "Kidzee Amritsar",
                               "Operating_Status": "A",
                               "Address1": "92-A,",
                               "Address2": "The Mall Road,",
                               "Place": "Amritsar, Mall Road,",
                               "City_Id": "72",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3038@kidzee.com",
                               "Mobile_No": "9914102038",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "31.642323",
                               "Longitude": "74.867563",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1247148/10998/414f6740_2437_49c7_8623_0ea9db221cce.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1247148/10998/9e94a94e_67b2_45ee_900d_679daf0b2002.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-S-C-1651",
                               "Franchisee_Name": "Kidzee-Amritsar",
                               "Operating_Status": "A",
                               "Address1": "Amarjit Mittal, GT Road, Chheharta Amritsar,",
                               "Address2": "",
                               "Place": "Chheharta ,Near Indian oil Petrol pump",
                               "City_Id": "72",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1651@kidzee.com",
                               "Mobile_No": "8847493306",
                               "Phone_No1": "9317510092",
                               "Phone_No2": "",
                               "Latitude": "31.626261",
                               "Longitude": "74.789295"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4203",
                               "Franchisee_Name": "Kidzee Sant Avenue Amritsar",
                               "Operating_Status": "A",
                               "Address1": "Plot No.1,",
                               "Address2": "Street No.5, Sant Avenue,",
                               "Place": "Sant Avenue, Opposite Mall of Amritsar,",
                               "City_Id": "72",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4203@kidzee.com",
                               "Mobile_No": "9082300001",
                               "Phone_No1": "8283947878",
                               "Phone_No2": "",
                               "Latitude": "31.613705",
                               "Longitude": "74.905923"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4481",
                               "Franchisee_Name": "Kidzee Jandiala",
                               "Operating_Status": "A",
                               "Address1": "Verowal Road,",
                               "Address2": "Near-Khuee Sahib Gurudwara,",
                               "Place": "Jandiala,",
                               "City_Id": "72",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4481@kidzee.com",
                               "Mobile_No": "9082300001",
                               "Phone_No1": "9082300001",
                               "Phone_No2": "",
                               "Latitude": "31.553518380795996",
                               "Longitude": "75.03331524120779"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5336",
                               "Franchisee_Name": "Kidzee Airport Road Indus Education Society (REGD)",
                               "Operating_Status": "A",
                               "Address1": "Inside Heritage City, Heir Village",
                               "Address2": "opp. Radison Blue,",
                               "Place": "Airport Road",
                               "City_Id": "72",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5336@kidzee.com",
                               "Mobile_No": "9814009060",
                               "Phone_No1": "9814109060",
                               "Phone_No2": "",
                               "Latitude": "31.699824",
                               "Longitude": "74.817323"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5406",
                               "Franchisee_Name": "Kidzee Beas",
                               "Operating_Status": "A",
                               "Address1": "Rajbir Kaur w/o satinder pal singh",
                               "Address2": "Vill- Budha Teh, PO- Beas, Teh-Baba Bakala",
                               "Place": "Beas",
                               "City_Id": "72",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5406@kidzee.com",
                               "Mobile_No": "9876410036",
                               "Phone_No1": "9312810002",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5706",
                               "Franchisee_Name": "Kidzee Gaggar Bhana road",
                               "Operating_Status": "A",
                               "Address1": "Gaggar Bhana road",
                               "Address2": "Ground Floor",
                               "Place": "Butala",
                               "City_Id": "72",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5706@kidzee.com",
                               "Mobile_No": "8283846265",
                               "Phone_No1": "8283846265",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3804",
                               "Franchisee_Name": "Kidzee Mehta Chowk",
                               "Operating_Status": "A",
                               "Address1": "Amritsar Road,",
                               "Address2": "Mehta Chowk, Tehsil Baba Bakala Sahib",
                               "Place": "Near Dana Mandi",
                               "City_Id": "72",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3804@kidzee.com",
                               "Mobile_No": "9814313803",
                               "Phone_No1": "9814313803",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Bagha purana",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3870",
                            "Franchisee_Name": "Kidzee Bagha Purana",
                            "Operating_Status": "A",
                            "Address1": "Nihal Singh Wala Road",
                            "Address2": "",
                            "Place": "Bagha Purana ,HP petrol Pump",
                            "City_Id": "101",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3870@kidzee.com",
                            "Mobile_No": "9814410547",
                            "Phone_No1": "9814410547",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Batala",
                         "Franchisee": {
                            "Franchisee_Code": "N-U-C-568",
                            "Franchisee_Name": "Kidzee-Batala",
                            "Operating_Status": "A",
                            "Address1": "Kahnuwan Road,",
                            "Address2": "Dist-Gurdaspur,",
                            "Place": "Batala Road, Kahnuwan Road,",
                            "City_Id": "123",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "kidzee568@kidzee.com",
                            "Mobile_No": "9872669472",
                            "Phone_No1": "1871657055",
                            "Phone_No2": "",
                            "Latitude": "31.82005",
                            "Longitude": "75.217906"
                         }
                      },
                      {
                         "City_Name": "Bhatinda",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2299",
                               "Franchisee_Name": "Kidzee Civil Station Bathinda",
                               "Operating_Status": "A",
                               "Address1": "Dashmesh Public Sr Sec School,",
                               "Address2": "Civil Station, Bhatinda",
                               "Place": "Near Civil Station",
                               "City_Id": "124",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2299@kidzee.com",
                               "Mobile_No": "9915494444",
                               "Phone_No1": "9417000376",
                               "Phone_No2": "",
                               "Latitude": "30.210994",
                               "Longitude": "74.945475"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3502",
                               "Franchisee_Name": "Kidzee Bhucho Mandi",
                               "Operating_Status": "A",
                               "Address1": "Ward No.2,",
                               "Address2": "Lehra Bega Road,",
                               "Place": "Bhucho Mandi,",
                               "City_Id": "124",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3502@kidzee.com",
                               "Mobile_No": "9041899228",
                               "Phone_No1": "9417560228",
                               "Phone_No2": "",
                               "Latitude": "30.216476",
                               "Longitude": "75.098098"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5123",
                               "Franchisee_Name": "Kidzee Multani Road",
                               "Operating_Status": "A",
                               "Address1": "Plot no. 2992",
                               "Address2": "Street no.14",
                               "Place": "Multani Road",
                               "City_Id": "124",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5123@kidzee.com",
                               "Mobile_No": "8557005123",
                               "Phone_No1": "9803302012",
                               "Phone_No2": "",
                               "Latitude": "30.202022",
                               "Longitude": "74.921503"
                            },
                            {
                               "Franchisee_Code": "CONSS-4636",
                               "Franchisee_Name": "MLZS Junior -Bhatinda",
                               "Operating_Status": "A",
                               "Address1": "Plot No.188,",
                               "Address2": "Phase -4 & 5,",
                               "Place": "Bathinda, BDA Road",
                               "City_Id": "124",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4636@kidzee.com",
                               "Mobile_No": "9988770165",
                               "Phone_No1": "9988770165",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Dharamkot"
                      },
                      {
                         "City_Name": "Dinanagar"
                      },
                      {
                         "City_Name": "Sangrur",
                         "Franchisee": {
                            "Franchisee_Code": "N-U-C-937",
                            "Franchisee_Name": "Kidzee-Sangrur-Punjab",
                            "Operating_Status": "A",
                            "Address1": "#702,Dr.D.P.Singla Road,",
                            "Address2": "Rajwaha Road,",
                            "Place": "D.P.Singla Road, Near-Central Bank of India,",
                            "City_Id": "285",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "kidzee937@kidzee.com",
                            "Mobile_No": "9988944970",
                            "Phone_No1": "1672508567",
                            "Phone_No2": "",
                            "Latitude": "30.245796",
                            "Longitude": "75.842072"
                         }
                      },
                      {
                         "City_Name": "Firozpur / Ferozepur",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5559",
                            "Franchisee_Name": "KIDZEE FEROZPUR",
                            "Operating_Status": "A",
                            "Address1": "VILLAGE - BAGGE KE PIPAL",
                            "Address2": "TEHSIL & DISTT - FEROZPUR",
                            "Place": "FEROZPUR",
                            "City_Id": "318",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5559@kidzee.com",
                            "Mobile_No": "9646100047",
                            "Phone_No1": "9646100047",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Gurdaspur"
                      },
                      {
                         "City_Name": "Hajipur"
                      },
                      {
                         "City_Name": "Hoshiarpur"
                      },
                      {
                         "City_Name": "Jalalabad"
                      },
                      {
                         "City_Name": "Jalandhar",
                         "Franchisee": {
                            "Franchisee_Code": "N-S-C-2128",
                            "Franchisee_Name": "Kidzee Jalandhar Cantt",
                            "Operating_Status": "A",
                            "Address1": "C/O Lt Col Ashok Kumar Sharma, #85-86",
                            "Address2": "The Vedant, Officers Colony Phase 1, (Sofi Pind)",
                            "Place": "Jalandhar Cantt By-Pass Road ,Jalandhar Cantt",
                            "City_Id": "476",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2128@kidzee.com",
                            "Mobile_No": "8054451051",
                            "Phone_No1": "8054351051",
                            "Phone_No2": "",
                            "Latitude": "31.269349434543038",
                            "Longitude": "75.63824659593956",
                            "Images": [
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/698334/10584/37238f48_61b5_45c9_a2c3_f153a355bd34.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/698334/10585/20f5db1d_484a_44f7_89f8_6d33f1a891d8.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/698334/10587/4faa2e53_d989_4ad6_aa47_03714c8cfe78.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/698334/10588/eaa0140a_5896_4913_8848_ade97fcb817f.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Khadoor sahib"
                      },
                      {
                         "City_Name": "Ludhiana",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-3512",
                               "Franchisee_Name": "Kidzee Palm Enclave",
                               "Operating_Status": "A",
                               "Address1": "House No.108,",
                               "Address2": "P.O Jaspal Bangar,",
                               "Place": "Palm Enclave,",
                               "City_Id": "625",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3512@kidzee.com",
                               "Mobile_No": "9872900567",
                               "Phone_No1": "9872900567",
                               "Phone_No2": "",
                               "Latitude": "30.841294",
                               "Longitude": "75.885337"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5107",
                               "Franchisee_Name": "Kidzee Sarabha Nagar",
                               "Operating_Status": "A",
                               "Address1": "10 J- Ground Floor",
                               "Address2": "Convent Road",
                               "Place": "Sarabha Nagar",
                               "City_Id": "625",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5107@kidzee.com",
                               "Mobile_No": "9697350035",
                               "Phone_No1": "0161-7960789",
                               "Phone_No2": "",
                               "Latitude": "30.89040052174678",
                               "Longitude": "75.81549547198068",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2722130/11024/2d596773_3509_4b95_8df5_11b1f3f383b6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2722130/11024/cc7f8ff5_97ba_412c_a501_394b53145d36.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-S-5042",
                               "Franchisee_Name": "Kidzee Haibowal Khurd",
                               "Operating_Status": "A",
                               "Address1": "8096 / B -34",
                               "Address2": "Durga Puri",
                               "Place": "Haibowal Kalan ,Near Durga Puri",
                               "City_Id": "625",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5042@kidzee.com",
                               "Mobile_No": "8054488722",
                               "Phone_No1": "8054488722",
                               "Phone_No2": "",
                               "Latitude": "30.925160",
                               "Longitude": "75.811106"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5407",
                               "Franchisee_Name": "Kidzee Siahar,Rara Sahib",
                               "Operating_Status": "A",
                               "Address1": "Guru Nanak School Public",
                               "Address2": "VPO Siahar, Jhammat Road",
                               "Place": "Siahar,Rara Sahib",
                               "City_Id": "625",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5407@kidzee.com",
                               "Mobile_No": "8528219000",
                               "Phone_No1": "8528219000",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Moga",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5533",
                            "Franchisee_Name": "Kidzee Manuke",
                            "Operating_Status": "A",
                            "Address1": "Raunta Road,",
                            "Address2": "Manuke Teh. Nihal singh Wala Dist.",
                            "Place": "Manuke",
                            "City_Id": "675",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5533@kidzee.com",
                            "Mobile_No": "9888080624",
                            "Phone_No1": "9888080624",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Pathankot",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2673",
                               "Franchisee_Name": "Kidzee Pathankot",
                               "Operating_Status": "A",
                               "Address1": "Golden Avenue, Near Suvidha Palace,",
                               "Address2": "Saili Road",
                               "Place": "Pathankot,",
                               "City_Id": "782",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2673@kidzee.com",
                               "Mobile_No": "9855443822",
                               "Phone_No1": "9855443822",
                               "Phone_No2": "",
                               "Latitude": "32.264338",
                               "Longitude": "75.642112"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4236",
                               "Franchisee_Name": "Kidzee Tharial",
                               "Operating_Status": "A",
                               "Address1": "Tharial,",
                               "Address2": "Ground Floor,",
                               "Place": "Tharyal,",
                               "City_Id": "782",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4236@kidzee.com",
                               "Mobile_No": "9780762143",
                               "Phone_No1": "8264888500",
                               "Phone_No2": "",
                               "Latitude": "32.35555846",
                               "Longitude": "75.612723"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4927",
                               "Franchisee_Name": "Kidzee Pathankot",
                               "Operating_Status": "A",
                               "Address1": "My Dentist,",
                               "Address2": "",
                               "Place": "Mission Link Road, Near- Ram Lilla Ground,",
                               "City_Id": "782",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4927@kidzee.com",
                               "Mobile_No": "9417443545",
                               "Phone_No1": "9417443545",
                               "Phone_No2": "",
                               "Latitude": "32.27373530791999",
                               "Longitude": "75.6578716635704"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5073",
                               "Franchisee_Name": "Kidzee  Bungal",
                               "Operating_Status": "A",
                               "Address1": "Dalhousie Road, NH-154A,",
                               "Address2": "VPO Badhani,",
                               "Place": "Bungal ,Sri Sai Educational Institute ",
                               "City_Id": "782",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5073@kidzee.com",
                               "Mobile_No": "9873546786",
                               "Phone_No1": "7090000010",
                               "Phone_No2": "",
                               "Latitude": "32.3267",
                               "Longitude": "75.7354"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5314",
                               "Franchisee_Name": "Kidzee Jugial",
                               "Operating_Status": "A",
                               "Address1": "Vill Trehty,",
                               "Address2": "T. Dhar Kalan",
                               "Place": "Jugial.",
                               "City_Id": "782",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5314@kidzee.com",
                               "Mobile_No": "6283504097",
                               "Phone_No1": "9872181276",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5027",
                               "Franchisee_Name": "Kidzee Dalhousie road",
                               "Operating_Status": "A",
                               "Address1": "Near A&M Gems Cambridge School",
                               "Address2": "Ground Floor",
                               "Place": "Dalhousie Road,Dalhousie Road",
                               "City_Id": "782",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5027@kidzee.com",
                               "Mobile_No": "9814321749",
                               "Phone_No1": "9814321749",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4198",
                               "Franchisee_Name": "Kidzee Abrol Nagar",
                               "Operating_Status": "A",
                               "Address1": "Greenfield,",
                               "Address2": "Number -2,",
                               "Place": "Abrol Nagar, Abrol Nagar,",
                               "City_Id": "782",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4198@kidzee.com",
                               "Mobile_No": "9646034195",
                               "Phone_No1": "9646034195",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3856",
                               "Franchisee_Name": "Kidzee Mukerian",
                               "Operating_Status": "A",
                               "Address1": "Nehar Colony,",
                               "Address2": "Opp Shastri Colony",
                               "Place": "Mukerian ,Nehar Colony",
                               "City_Id": "782",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3856@kidzee.com",
                               "Mobile_No": "8437463698",
                               "Phone_No1": "8437463698",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4972",
                               "Franchisee_Name": "Kidzee Pathankot",
                               "Operating_Status": "A",
                               "Address1": "V.P.O.Mamun,",
                               "Address2": "",
                               "Place": "Mamun Cantt, Dibber Chowk,",
                               "City_Id": "782",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4972@kidzee.com",
                               "Mobile_No": "9814117809",
                               "Phone_No1": "9814117809",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Patiala",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-B-U-894",
                               "Franchisee_Name": "Kidzee- New Lal Bagh Colony- Patiala",
                               "Operating_Status": "A",
                               "Address1": "#9 bhupindera road,",
                               "Address2": "officers colony near crunch gym",
                               "Place": "Patiala ,Officers Colony",
                               "City_Id": "784",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "kidzee894@kidzee.com",
                               "Mobile_No": "9779020999",
                               "Phone_No1": "9779020999",
                               "Phone_No2": "",
                               "Latitude": "30.324621",
                               "Longitude": "76.387241"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3552",
                               "Franchisee_Name": "Kidzee Shanti Nagar",
                               "Operating_Status": "A",
                               "Address1": "Mahadev Educations Near-CMT College,",
                               "Address2": "Bypass Road,",
                               "Place": "Shanti Nagar, CMT College,",
                               "City_Id": "784",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3552@kidzee.com",
                               "Mobile_No": "9803583596",
                               "Phone_No1": "9803583596",
                               "Phone_No2": "",
                               "Latitude": "30.353708064116777",
                               "Longitude": "76.43109746733859"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5605",
                               "Franchisee_Name": "Kidzee Tripuri",
                               "Operating_Status": "A",
                               "Address1": "263, Harinder Nagar",
                               "Address2": "Tripuri Main Jhill Road",
                               "Place": "Tripuri",
                               "City_Id": "784",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5605@kidzee.com",
                               "Mobile_No": "9463207551",
                               "Phone_No1": "9463207551",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Rajpura",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5763",
                            "Franchisee_Name": "Kidzee Rajpura",
                            "Operating_Status": "A",
                            "Address1": "2632",
                            "Address2": "Back Side Durga Mandir",
                            "Place": "Rajpura",
                            "City_Id": "828",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5763@kidzee.com",
                            "Mobile_No": "9988775611",
                            "Phone_No1": "9988775611",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Ropar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-5041",
                               "Franchisee_Name": "Kidzee Nangal",
                               "Operating_Status": "A",
                               "Address1": "Plot no. 39 B",
                               "Address2": "Focal Point Naya Nangal, Dist Ropar",
                               "Place": "Nangal ,Nangal",
                               "City_Id": "847",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5041@kidzee.com",
                               "Mobile_No": "7589009009",
                               "Phone_No1": "7589009009",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4128",
                               "Franchisee_Name": "Kidzee Nurpur Bedi",
                               "Operating_Status": "A",
                               "Address1": "Ropar Road,",
                               "Address2": "Ground Floor,",
                               "Place": "Nurpur Bedi, Near CM Honda Showroom,",
                               "City_Id": "847",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4128@kidzee.com",
                               "Mobile_No": "9872594802",
                               "Phone_No1": "9872594802",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Sultanpur lodhi"
                      },
                      {
                         "City_Name": "Sunam"
                      },
                      {
                         "City_Name": "Tarn taran sahib",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-C-469",
                               "Franchisee_Name": "Kids Camp/ Taran taran",
                               "Operating_Status": "A",
                               "Address1": "C/o-Kids Camp,",
                               "Address2": "Dist-Taran Taran,",
                               "Place": "Taran-Taran ,Bath Road",
                               "City_Id": "948",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "kidzee469@kidzee.com",
                               "Mobile_No": "8556005455",
                               "Phone_No1": "8699555955",
                               "Phone_No2": "",
                               "Latitude": "31.461883784138955",
                               "Longitude": "74.9400577362519"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3718",
                               "Franchisee_Name": "Kidzee Bhikhiwind",
                               "Operating_Status": "A",
                               "Address1": "Near Evergreen Sen",
                               "Address2": "Sec School Aristar Rad,Tehsil Patti District",
                               "Place": "Bhikhiwind ,Amritsar Road",
                               "City_Id": "948",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3718@kidzee.com",
                               "Mobile_No": "9915100090",
                               "Phone_No1": "1851-272121",
                               "Phone_No2": "",
                               "Latitude": "31.35",
                               "Longitude": "74.69",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1892258/10886/21db275b_d4df_4c2f_b6fb_4bf185f2e536.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1892258/10886/ba3a2eb4_a18f_4015_be91_9ccc00d66726.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-5647",
                               "Franchisee_Name": "Kidzee Makhu",
                               "Operating_Status": "A",
                               "Address1": "Talwandi",
                               "Address2": "Nipalan",
                               "Place": "Makhu",
                               "City_Id": "948",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5647@kidzee.com",
                               "Mobile_No": "9872854321",
                               "Phone_No1": "9872854321",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Zirakpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-S-3259",
                               "Franchisee_Name": "Kidzee Peer Muchalla",
                               "Operating_Status": "A",
                               "Address1": "Shiva Foundation Charitable Trust Bollywood Heights Road,",
                               "Address2": "Opp- Tricity Mall,",
                               "Place": "Peer Muchalla, Opposit -Tricity Mall",
                               "City_Id": "1033",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3259@kidzee.com",
                               "Mobile_No": "9417049418",
                               "Phone_No1": "9888443446",
                               "Phone_No2": "",
                               "Latitude": "30.656454",
                               "Longitude": "76.856864"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5060",
                               "Franchisee_Name": "Kidzee The Learning Tree",
                               "Operating_Status": "A",
                               "Address1": "House no. 980-981,Gurunanak Colony, Dhakoli",
                               "Address2": "Opposite PTC Bazaar, SAS Nagar",
                               "Place": "Opposite PTC Bazaar,",
                               "City_Id": "1033",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5060@kidzee.com",
                               "Mobile_No": "9427599841",
                               "Phone_No1": "9427599841",
                               "Phone_No2": "",
                               "Latitude": "30.653141",
                               "Longitude": "76.845715"
                            }
                         ]
                      },
                      {
                         "City_Name": "Kapurthala"
                      },
                      {
                         "City_Name": "Dera bassi",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-S-3377",
                            "Franchisee_Name": "Kidzee  Saraswati Vihar",
                            "Operating_Status": "A",
                            "Address1": "Street No-1, Saraswati Vihar,",
                            "Address2": "Ward No-12, S A S Nagar,",
                            "Place": "Derabassi, Near-Sukhmani Group of Colleges,",
                            "City_Id": "1107",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3377@kidzee.com",
                            "Mobile_No": "9501484887",
                            "Phone_No1": "8968478877",
                            "Phone_No2": "",
                            "Latitude": "30.589203",
                            "Longitude": "76.839379"
                         }
                      },
                      {
                         "City_Name": "Mansa",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-G-K-697",
                               "Franchisee_Name": "Kidzee-Gramin-Mansa",
                               "Operating_Status": "A",
                               "Address1": "Singhal Complex,",
                               "Address2": "Nehru Memorial College  Road,",
                               "Place": "Mansa, Barnala-Mansa Road,",
                               "City_Id": "1141",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "kidzee697@kidzee.com",
                               "Mobile_No": "9915585084",
                               "Phone_No1": "9915585084",
                               "Phone_No2": "",
                               "Latitude": "29.999423",
                               "Longitude": "75.393409"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4254",
                               "Franchisee_Name": "Kidzee Budhlada",
                               "Operating_Status": "A",
                               "Address1": "Chauri Gali",
                               "Address2": "Naveen School Building",
                               "Place": "Budhlada,",
                               "City_Id": "1141",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4254@kidzee.com",
                               "Mobile_No": "8427984467",
                               "Phone_No1": "8427984467",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3060",
                               "Franchisee_Name": "Kidzee City Center",
                               "Operating_Status": "A",
                               "Address1": "Water Works Road,",
                               "Address2": "Near DAV School,",
                               "Place": "City Center ,Water Works Road",
                               "City_Id": "1141",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3060@kidzee.com",
                               "Mobile_No": "9915585084",
                               "Phone_No1": "9915585084",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3890",
                               "Franchisee_Name": "Kidzee Ralla",
                               "Operating_Status": "A",
                               "Address1": "Punjabi Educational soc ,Village Ralla,",
                               "Address2": "District Mansa",
                               "Place": "Mansa ,Ralla",
                               "City_Id": "1141",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3890@kidzee.com",
                               "Mobile_No": "9914164164",
                               "Phone_No1": "9872543143",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3202",
                               "Franchisee_Name": "Kidzee Bhikhi",
                               "Operating_Status": "A",
                               "Address1": "Budhlada Road,",
                               "Address2": "Ground Floor,",
                               "Place": "Bhikhi ,Budhlada Road",
                               "City_Id": "1141",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3202@kidzee.com",
                               "Mobile_No": "9478600997",
                               "Phone_No1": "9872141999",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Giddarbaha"
                      },
                      {
                         "City_Name": "Barnala"
                      },
                      {
                         "City_Name": "Mohali",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-3955",
                               "Franchisee_Name": "Kidzee Nayagaon",
                               "Operating_Status": "A",
                               "Address1": "H/N 909 Gali No. 5",
                               "Address2": "Vikas Nagar Tehsil Majri",
                               "Place": "Nayagaon ,Near Church",
                               "City_Id": "1247",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3955@kidzee.com",
                               "Mobile_No": "9218170009",
                               "Phone_No1": "01795-222565",
                               "Phone_No2": "",
                               "Latitude": "30.7769077",
                               "Longitude": "76.7856842",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1918603/10649/95e9b411_2155_47e4_b2d0_63d0006aac04.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1918603/10649/a15ff4fe_d80b_4ec1_9511_f3b052524dd3.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-4039",
                               "Franchisee_Name": "Kidzee New Chandigarh",
                               "Operating_Status": "A",
                               "Address1": "D-28A,",
                               "Address2": "Sec-15, New Chandigarh",
                               "Place": "Kidzee New Chandigarh",
                               "City_Id": "1247",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4039@kidzee.com",
                               "Mobile_No": "9466131509",
                               "Phone_No1": " 9592677555",
                               "Phone_No2": "",
                               "Latitude": "30.801480",
                               "Longitude": "76.712504"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4964",
                               "Franchisee_Name": "Kidzee Gillco Valley",
                               "Operating_Status": "A",
                               "Address1": "438, Gillco Valley,",
                               "Address2": "Kharar, Dist-Sas Nagar,",
                               "Place": "Mohali,",
                               "City_Id": "1247",
                               "State_Id": "30",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4964@kidzee.com",
                               "Mobile_No": "7380000848",
                               "Phone_No1": "7380000848",
                               "Phone_No2": "",
                               "Latitude": "30.7389880",
                               "Longitude": "76.6541020"
                            }
                         ]
                      },
                      {
                         "City_Name": "Khem karan"
                      },
                      {
                         "City_Name": "Kurali"
                      },
                      {
                         "City_Name": "Nabha"
                      },
                      {
                         "City_Name": "Dhuri"
                      },
                      {
                         "City_Name": "Khanna"
                      },
                      {
                         "City_Name": "Sujanpur"
                      },
                      {
                         "City_Name": "Kotkapura"
                      },
                      {
                         "City_Name": "Fatehgarh sahib",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3614",
                            "Franchisee_Name": "Kidzee Fatehgarh Sahib",
                            "Operating_Status": "A",
                            "Address1": "Sirhind,",
                            "Address2": "Mata Guruji Colony Ward No-2,",
                            "Place": "Fatehgarh Sahib, Near Jyoti Sarup Gurudwara,",
                            "City_Id": "1357",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3614@kidzee.com",
                            "Mobile_No": "9779111835",
                            "Phone_No1": "9872317666",
                            "Phone_No2": "",
                            "Latitude": "30.6480527741704",
                            "Longitude": "76.3920325040817"
                         }
                      },
                      {
                         "City_Name": "Patti",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2392",
                            "Franchisee_Name": "KIDZEE MATRIX EDUCATION SOCIETY TARANTARAN",
                            "Operating_Status": "A",
                            "Address1": "Khemkaran Road, Patti,",
                            "Address2": "Dis Tarantaran",
                            "Place": "Patti, Khemkaran Road",
                            "City_Id": "1376",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2392@kidzee.com",
                            "Mobile_No": "9815060422",
                            "Phone_No1": "9815060422",
                            "Phone_No2": "",
                            "Latitude": "31.274586",
                            "Longitude": "74.856561"
                         }
                      },
                      {
                         "City_Name": "Faridkot"
                      },
                      {
                         "City_Name": "Mandigobindgarh"
                      },
                      {
                         "City_Name": "Phagwara"
                      },
                      {
                         "City_Name": "Jagraon"
                      },
                      {
                         "City_Name": "Nawanshaher"
                      },
                      {
                         "City_Name": "Patran"
                      },
                      {
                         "City_Name": "Morinda",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3867",
                            "Franchisee_Name": "Kidzee Morinda",
                            "Operating_Status": "A",
                            "Address1": "Global Enclave No 37,",
                            "Address2": " Badi Mandauli, Chandigarh Road",
                            "Place": "Morinda ,Near Verka Plant",
                            "City_Id": "1825",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3867@kidzee.com",
                            "Mobile_No": "9878547500",
                            "Phone_No1": "7002869072",
                            "Phone_No2": "",
                            "Latitude": "30.4723",
                            "Longitude": "76.316"
                         }
                      },
                      {
                         "City_Name": "Adampur",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4289",
                            "Franchisee_Name": "Kidzee Adampur",
                            "Operating_Status": "A",
                            "Address1": "Village Sattowali,",
                            "Address2": "Ground Floor,",
                            "Place": "Adampur, Rainbow Public School,",
                            "City_Id": "1846",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4289@kidzee.com",
                            "Mobile_No": "7814757791",
                            "Phone_No1": "9815242403",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Fazilka",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4637",
                            "Franchisee_Name": "Kidzee Fazilka",
                            "Operating_Status": "A",
                            "Address1": "MES Road,",
                            "Address2": "",
                            "Place": "Fazilka, Near Chanikya School",
                            "City_Id": "1914",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4637@kidzee.com",
                            "Mobile_No": "9876620073",
                            "Phone_No1": "9417176511",
                            "Phone_No2": "",
                            "Latitude": "30.396568",
                            "Longitude": "74.04121"
                         }
                      },
                      {
                         "City_Name": "Zira",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3514",
                            "Franchisee_Name": "Kidzee Zira",
                            "Operating_Status": "A",
                            "Address1": "House No.256/6,",
                            "Address2": "Kot Ise Khan Road,",
                            "Place": "Zira,",
                            "City_Id": "1933",
                            "State_Id": "30",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3514@kidzee.com",
                            "Mobile_No": "9815381919",
                            "Phone_No1": "9914600126",
                            "Phone_No2": "",
                            "Latitude": "74.9956240",
                            "Longitude": "30.9686180"
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Rajasthan",
                   "City": [
                      {
                         "City_Name": "Alwar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-A-S-385",
                               "Franchisee_Name": "Kidzee-Alwar/Kidz Kamp",
                               "Operating_Status": "A",
                               "Address1": "3, Moti doongri,",
                               "Address2": "Alwar,",
                               "Place": "Moti Doongri- Alwar,",
                               "City_Id": "47",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee385@kidzee.com",
                               "Mobile_No": "9672998686",
                               "Phone_No1": "01443291781",
                               "Phone_No2": "",
                               "Latitude": "27.551582",
                               "Longitude": "76.608055",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/385/220/da2b4f5a_ab66_4c60_9f1f_c2b8bca62a25.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/385/222/94491f0d_cf1c_404b_aa62_ed65b4fb7cb4.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-2631",
                               "Franchisee_Name": "Kidzee Khairthal, Alwar",
                               "Operating_Status": "A",
                               "Address1": "C/O- Mr. Ashok Goyal,",
                               "Address2": "Ward No-10,",
                               "Place": "Khairthal, Dist. Alwar, Nr. Laxmi Narayan Mandir, ",
                               "City_Id": "47",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2631@kidzee.com",
                               "Mobile_No": "9887354435",
                               "Phone_No1": "01460-22717",
                               "Phone_No2": "",
                               "Latitude": "27.799625",
                               "Longitude": "76.639466"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3106",
                               "Franchisee_Name": "KIDZE  THD FOUNDATION  ALWAR",
                               "Operating_Status": "A",
                               "Address1": "Apna Ghar Shalimar,",
                               "Address2": "Tijara Road,",
                               "Place": "Apna Ghar, Near- Telco Chowk- Alwar,",
                               "City_Id": "47",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3106@kidzee.com",
                               "Mobile_No": "8875811116",
                               "Phone_No1": "7023000100",
                               "Phone_No2": "",
                               "Latitude": "27.552991",
                               "Longitude": "76.634573"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3706",
                               "Franchisee_Name": "Kidzee Kishangarh Bas",
                               "Operating_Status": "A",
                               "Address1": "Gulab Devi W/O Nandan Kumar, Near Tehsil",
                               "Address2": "Alwar Road, Kishangarh Bas",
                               "Place": "Kishangarh Bas ,Near Tehsil, Alwar Road",
                               "City_Id": "47",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3706@kidzee.com",
                               "Mobile_No": "9413589141",
                               "Phone_No1": "8852-809080",
                               "Phone_No2": "",
                               "Latitude": "27.8159203340565",
                               "Longitude": "76.731938123703",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3706/311/7b2ab181_0d21_467f_9aec_4ed9ee527520.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-3706",
                               "Franchisee_Name": "Kidzee Kishangarh Bas",
                               "Operating_Status": "A",
                               "Address1": "Gulab Devi W/O Nandan Kumar, Near Tehsil",
                               "Address2": "Alwar Road, Kishangarh Bas",
                               "Place": "Kishangarh Bas ,Near Tehsil, Alwar Road",
                               "City_Id": "47",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3706@kidzee.com",
                               "Mobile_No": "9413589141",
                               "Phone_No1": "8852-809080",
                               "Phone_No2": "",
                               "Latitude": "27.8159203340565",
                               "Longitude": "76.731938123703",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3706/311/7b2ab181_0d21_467f_9aec_4ed9ee527520.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-3724",
                               "Franchisee_Name": "Kidzee Nimrana",
                               "Operating_Status": "A",
                               "Address1": "Khasra Number -104, Behind Serval Indai Pvt. Limited",
                               "Address2": "Madho Singh Pura,",
                               "Place": "Nimrana ,Behind Serval Indai Pvt. Limited- Nimrana",
                               "City_Id": "47",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3724@kidzee.com",
                               "Mobile_No": "7300232610",
                               "Phone_No1": "7300232610",
                               "Phone_No2": "",
                               "Latitude": "27.98130320434265",
                               "Longitude": "76.38173763439022"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4317",
                               "Franchisee_Name": "Kidzee Alwar Raj",
                               "Operating_Status": "A",
                               "Address1": "Vijay Mandir Road,",
                               "Address2": "Mevat Nagar,",
                               "Place": "Alwar Raj, Near-Hasankha",
                               "City_Id": "47",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4317@kidzee.com",
                               "Mobile_No": "9610447401",
                               "Phone_No1": "8696655222",
                               "Phone_No2": "",
                               "Latitude": "27.580110",
                               "Longitude": "76.614793"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4260",
                               "Franchisee_Name": "Kidzee Bansur",
                               "Operating_Status": "A",
                               "Address1": "OFF. Gulab Nath,",
                               "Address2": "Narayanpur Road,",
                               "Place": "Shastri Colony- Bansur,",
                               "City_Id": "47",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4260@kidzee.com",
                               "Mobile_No": "9460373837",
                               "Phone_No1": "7073228985",
                               "Phone_No2": "",
                               "Latitude": "27.681591",
                               "Longitude": "76.352076"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4494",
                               "Franchisee_Name": "Kidzee Alwar",
                               "Operating_Status": "A",
                               "Address1": "Kidz Kamp, Plot No. B-3,",
                               "Address2": "Surya Nagar,",
                               "Place": "Near Community Centre, Surya Nagar,",
                               "City_Id": "47",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4494@kidzee.com",
                               "Mobile_No": "9672412222",
                               "Phone_No1": "9672413333",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5160",
                               "Franchisee_Name": "Kidzee Rajgarh",
                               "Operating_Status": "A",
                               "Address1": "35,36, Narayan Vihar",
                               "Address2": "Front of railway station",
                               "Place": "Rajgarh",
                               "City_Id": "47",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5160@kidzee.com",
                               "Mobile_No": "9166529274",
                               "Phone_No1": "01464-294038",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Banswara"
                      },
                      {
                         "City_Name": "Barmer"
                      },
                      {
                         "City_Name": "Bharatpur",
                         "Franchisee": {
                            "Franchisee_Code": "N-G-K-1094",
                            "Franchisee_Name": "Kidzee-Bharatpur,Rajasthan",
                            "Operating_Status": "A",
                            "Address1": "351,",
                            "Address2": "",
                            "Place": "Rajendra Nagar, Bharatpur,",
                            "City_Id": "147",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1094@kidzee.com",
                            "Mobile_No": "9460182293",
                            "Phone_No1": "5644232293",
                            "Phone_No2": "",
                            "Latitude": "27.205637",
                            "Longitude": "77.511061"
                         }
                      },
                      {
                         "City_Name": "Bhilwara",
                         "Franchisee": {
                            "Franchisee_Code": "N-A-M-1199",
                            "Franchisee_Name": "Kidzee-Bhilawara",
                            "Operating_Status": "A",
                            "Address1": "Amrit Lal Khelkha Memorial Education Sansthan,",
                            "Address2": "Pragati Path, Chittorgarh Road,",
                            "Place": "Agarwal Petrol Pump, Near Old Octri- Bhilwara,",
                            "City_Id": "154",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1199@kidzee.com",
                            "Mobile_No": "9009329339",
                            "Phone_No1": "0148-2234251",
                            "Phone_No2": "",
                            "Latitude": "25.35748956870442",
                            "Longitude": "74.63062948958397"
                         }
                      },
                      {
                         "City_Name": "Bhiwadi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-C-955",
                               "Franchisee_Name": "Kidzee-Bhiwadi-Rajasthan",
                               "Operating_Status": "A",
                               "Address1": "A-108, Bhagat Singh Colony,",
                               "Address2": "Bhiwadi,",
                               "Place": "Bhagat Singh Colony, Bhiwadi",
                               "City_Id": "155",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee955@kidzee.com",
                               "Mobile_No": "9983755950",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "28.206537",
                               "Longitude": "76.810333",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/955/177/5fafbd96_9bad_4386_b80a_94c66471faf6.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-4733",
                               "Franchisee_Name": "Kidzee khijuriwas",
                               "Operating_Status": "A",
                               "Address1": "Alwar Bypass,",
                               "Address2": "Tijara,",
                               "Place": "Khijuriwas, Near Power Grid,VPO Tijara",
                               "City_Id": "155",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4733@kidzee.com",
                               "Mobile_No": "9996517791",
                               "Phone_No1": "9416577791",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Bikaner",
                         "Franchisee": {
                            "Franchisee_Code": "N-S-C-2217",
                            "Franchisee_Name": "Kidzee Bikaner Rajasthan",
                            "Operating_Status": "A",
                            "Address1": "D-38,",
                            "Address2": " Karni Nagar",
                            "Place": "Karni Nagar, Near BPS School- Bikaner",
                            "City_Id": "173",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2217@kidzee.com",
                            "Mobile_No": "9460505025",
                            "Phone_No1": "9460505025",
                            "Phone_No2": "",
                            "Latitude": "27.996612",
                            "Longitude": "73.33849"
                         }
                      },
                      {
                         "City_Name": "Chittorgarh",
                         "Franchisee": {
                            "Franchisee_Code": "N-G-K-938",
                            "Franchisee_Name": "Kidzee-Chittorgarh-Rajasthan, Kidzee Little Champ's",
                            "Operating_Status": "A",
                            "Address1": "Chittori,",
                            "Address2": "",
                            "Place": "Pratap Nagar, Near-Mohar Mangri, Chittorgarh,",
                            "City_Id": "215",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "kidzee938@kidzee.com",
                            "Mobile_No": "9829260315",
                            "Phone_No1": "9314021118",
                            "Phone_No2": "",
                            "Latitude": "24.875102",
                            "Longitude": "74.618083"
                         }
                      },
                      {
                         "City_Name": "Dausa"
                      },
                      {
                         "City_Name": "Dholpur / Dhaulpur",
                         "Franchisee": {
                            "Franchisee_Code": "A-N-A-1016",
                            "Franchisee_Name": "Ankurum Agarsen  Vihar Colony",
                            "Operating_Status": "A",
                            "Address1": "OLD BANK OF BARODA BANK",
                            "Address2": "IN FRONT OF MOTE HANUMANJI",
                            "Place": "DHOLPUR ,IN FRONT OF MOTE HANUMANJI- Dholpur",
                            "City_Id": "268",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4876@kidzee.com",
                            "Mobile_No": "7014186759",
                            "Phone_No1": "9309011959",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Jaipur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2476",
                               "Franchisee_Name": "Kidzee Gandhi Path West -23",
                               "Operating_Status": "A",
                               "Address1": "23, Maa Hinglaj Nagar ( C )",
                               "Address2": "",
                               "Place": "Gandhi Path West- Jaipur,",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2476@kidzee.com",
                               "Mobile_No": "7300063533",
                               "Phone_No1": "9214366633",
                               "Phone_No2": "",
                               "Latitude": "26.916091",
                               "Longitude": "75.723645",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/e31b4c8c_fade_4114_b61d_0d8085fe8825.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/1c125bc0_fb3a_4344_8783_3b16ec6f3f5f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/f1ce502a_1f5e_4573_bfe9_8a6b17e318fe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/0cec48ca_481e_4c73_b5c8_0b4154706623.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/225a7742_e4ef_4fa5_9bff_0ff875cb2aaf.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/a66ba111_dca2_46bd_a822_c0545a67e01b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/ef4c96ac_543d_4f94_8337_06c5609de49c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/249e2d89_a6a3_4be3_a5d9_9f061803e72b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/66a4113d_ca66_4f7b_9ad0_389ae0308a92.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/a64834f1_d884_44e7_b131_94b103703a05.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/946d1c00_6fda_40aa_842b_cd2146b2e38f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/9b9e0c41_2422_4a8d_a73f_f7969ee280a6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/211dfc7f_bf27_4d87_a1e9_5ff3d7620fa5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/28d84ebe_e30c_4481_9833_f3a0a132e47c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/805214/10966/f5f7c540_570a_4d75_be39_71b5bb91b5a8.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-S-C-1918",
                               "Franchisee_Name": "Kidzee Ambabari Jaipur",
                               "Operating_Status": "A",
                               "Address1": "H.No.52,",
                               "Address2": "Jagdamba Colony,",
                               "Place": "Ambabari, Near-Mall Road, Ambabari,",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1918@kidzee.com",
                               "Mobile_No": "9887264148",
                               "Phone_No1": "9887264148",
                               "Phone_No2": "",
                               "Latitude": "26.954809",
                               "Longitude": "75.778806"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2952",
                               "Franchisee_Name": "Kidzee Sirsi Road  Jaipur",
                               "Operating_Status": "A",
                               "Address1": "35, Vinayak Vihar,",
                               "Address2": "",
                               "Place": "Kanakpura, Opposite Rosewood Apartment- Sirsi Road",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2952@kidzee.com",
                               "Mobile_No": "7568777127",
                               "Phone_No1": "7568777127",
                               "Phone_No2": "",
                               "Latitude": "26.920157581543013",
                               "Longitude": "75.69885849952693",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1209445/10702/52255fb2_8a5a_4ec7_9762_09be647b71dc.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1209445/10702/cdf135cc_35e8_42bf_ad04_91173db901c9.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1209445/10702/b9802e4a_7ee3_4d07_9a4b_34e20dcb73ac.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-B-U-1009",
                               "Franchisee_Name": "Kidzee-Vaishali Nagar/Penguins",
                               "Operating_Status": "A",
                               "Address1": "282,Guru Jambeshwar Nagar,",
                               "Address2": "",
                               "Place": "Lane No.9, Near-Vijay Dwar -Vaishali Nagar",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1009@kidzee.com",
                               "Mobile_No": "9214366635",
                               "Phone_No1": "1414020020",
                               "Phone_No2": "",
                               "Latitude": "26.907289",
                               "Longitude": "75.751425"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2736",
                               "Franchisee_Name": "Kidzee Jhotwara Jaipur",
                               "Operating_Status": "A",
                               "Address1": "Plot No.16,",
                               "Address2": "Anand Purikalwad Road,",
                               "Place": "Jhotwara, Near-Bank Of India, Jhotwara,",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2736@kidzee.com",
                               "Mobile_No": "9694090779",
                               "Phone_No1": "0-9694090779",
                               "Phone_No2": "",
                               "Latitude": "26.942604",
                               "Longitude": "75.752226"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3705",
                               "Franchisee_Name": "Kidzee Niwaru road",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 25, 21-South Colony",
                               "Address2": "Niwaru Road, Laxmi Nagar,Ganesh Nagar 21 South Block",
                               "Place": "Rim Niwaru road",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3705@kidzee.com",
                               "Mobile_No": "7791011666",
                               "Phone_No1": "0141-2348893",
                               "Phone_No2": "",
                               "Latitude": "26.95806566395159",
                               "Longitude": "75.7373973791332"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3924",
                               "Franchisee_Name": "Kidzee Jagatpura",
                               "Operating_Status": "A",
                               "Address1": "A-144, Main Ramnagariya,",
                               "Address2": "Near SKIT College, JDA Scheme",
                               "Place": "Jagatpura ,Near SKIT College- Jagatpura",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3924@kidzee.com",
                               "Mobile_No": "7891618103",
                               "Phone_No1": "0141-2506711",
                               "Phone_No2": "",
                               "Latitude": "26.822560431635374",
                               "Longitude": "75.86338776080288",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/c39a9c2a_c134_4ce1_afa7_7143547c8a40.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/a13701b3_1dc5_4d33_927e_635cc6b2e69a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/e1b97ee2_0309_4e4a_8930_bd8ce04baca6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/65e6ecc9_be6e_4ea6_b512_de5eda539838.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/a146a3dc_37d9_4e11_9373_76285213d154.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/e0d3fef9_c1cd_47a4_8332_e33c8a31045a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/22ab7f55_71c4_41c1_89e8_1adc0898d56b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/c242847d_0e91_4f7e_949f_f8039b8c123f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/885ddd70_8c30_4d86_9efc_28b3fd724d32.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/1e5bbb9f_be09_4c3b_ad34_a63bd4f8b839.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/4acd4939_0ee6_4e43_8d57_0363bbb98de1.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/832f6899_5e84_4b81_8883_179fcdb717ba.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1915076/10852/57f6df6a_76ba_4b53_a1ca_9700347e3262.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-4509",
                               "Franchisee_Name": "Kidzee Shreepuram Colony",
                               "Operating_Status": "A",
                               "Address1": "No-168, Shreepuram Colony,",
                               "Address2": "Near Gurjar Ki Thadi,",
                               "Place": "Shreepuram Colony ,Near Gurjar Ki Thadi- Jaipur",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4509@kidzee.com",
                               "Mobile_No": "9414800341",
                               "Phone_No1": "9414800341",
                               "Phone_No2": "",
                               "Latitude": "26.87555° N",
                               "Longitude": "75.76996° E",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2270174/10992/66744d40_376a_4e17_9227_ed8eb17af5c0.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-4538",
                               "Franchisee_Name": "Kidzee Vatika Infotech City",
                               "Operating_Status": "A",
                               "Address1": "Central Park, North Avenue-C,",
                               "Address2": "7th Street 26, Village Theekariya,",
                               "Place": "Vatika Infotech City, Near-GVK Toll Plaza- Jaipur,",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4538@kidzee.com",
                               "Mobile_No": "9414454145",
                               "Phone_No1": "9414454145",
                               "Phone_No2": "",
                               "Latitude": "26.858802",
                               "Longitude": "75.604327"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4518",
                               "Franchisee_Name": "Kidzee Mansarovar",
                               "Operating_Status": "A",
                               "Address1": "H. No. 69/96,",
                               "Address2": "V.T Road,",
                               "Place": "Mansarovar, VT Road,",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4518@kidzee.com",
                               "Mobile_No": "9829217788",
                               "Phone_No1": "0141-2783788",
                               "Phone_No2": "",
                               "Latitude": "26.85845",
                               "Longitude": "75.76025"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4731",
                               "Franchisee_Name": "Kidzee Agra Road Jaipur",
                               "Operating_Status": "A",
                               "Address1": "67-A,",
                               "Address2": "Gopal Vihar, Prem Nagar,",
                               "Place": "Near Shri Radha Krishna Mandir Gopal Vihar- Agra R",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4731@kidzee.com",
                               "Mobile_No": "9314149990",
                               "Phone_No1": "0141-2680551",
                               "Phone_No2": "",
                               "Latitude": "26.885655",
                               "Longitude": "75.885096"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4465",
                               "Franchisee_Name": "Kidzee Ranisati Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot No-806,",
                               "Address2": "Ajmer Road,",
                               "Place": "Rani Sati Nagar,",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4465@kidzee.com",
                               "Mobile_No": "9001012340",
                               "Phone_No1": "8769095684",
                               "Phone_No2": "",
                               "Latitude": "26.885102396042623",
                               "Longitude": "75.74344666501236"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4935",
                               "Franchisee_Name": "Kidzee Shiv Officers Colony",
                               "Operating_Status": "A",
                               "Address1": "F-47A, Shiv Officers Colony,",
                               "Address2": "Jagatpura",
                               "Place": "Shiv Officers Colony, Jagatpura",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4935@kidzee.com",
                               "Mobile_No": "7239992424",
                               "Phone_No1": "0141-665712",
                               "Phone_No2": "",
                               "Latitude": "26.8334042",
                               "Longitude": "75.8731920"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5578",
                               "Franchisee_Name": "Kidzee Tilak Nagar",
                               "Operating_Status": "A",
                               "Address1": "C-232,",
                               "Address2": "Dayanand Marg,",
                               "Place": "Tilak Nagar",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5578@kidzee.com",
                               "Mobile_No": "9929188883",
                               "Phone_No1": "0141-4029691",
                               "Phone_No2": "",
                               "Latitude": "26.890481292559507",
                               "Longitude": "75.82932168880022"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4382",
                               "Franchisee_Name": "Kidzee Tonk Road",
                               "Operating_Status": "A",
                               "Address1": "Plot No.199,",
                               "Address2": "",
                               "Place": "Tonk Road, ,Taru Chaaya Nagar- Tonk Road, Jaipur,",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4382@kidzee.com",
                               "Mobile_No": "9024902339",
                               "Phone_No1": "7014260191",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5812",
                               "Franchisee_Name": "Kidzee Sikar Road,",
                               "Operating_Status": "A",
                               "Address1": "Sunrise School, Plot No 71,",
                               "Address2": "Vijaywadi, Path No. 7,",
                               "Place": "Sikar Road,",
                               "City_Id": "473",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5812@kidzee.com",
                               "Mobile_No": "8003645869",
                               "Phone_No1": "8003645869",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Jhalawar"
                      },
                      {
                         "City_Name": "Jodhpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-B-U-219",
                               "Franchisee_Name": "KIDZEE-JODHPUR-RAJASTHAN/KUDOS KIDS",
                               "Operating_Status": "A",
                               "Address1": "A-18, Shastri Nagar, Jodhpur,",
                               "Address2": "",
                               "Place": "Near SBI Bank- Shastri Nagar",
                               "City_Id": "509",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee219@kidzee.com",
                               "Mobile_No": "9261728572",
                               "Phone_No1": "6350-355217",
                               "Phone_No2": "",
                               "Latitude": "26.269206935880234",
                               "Longitude": "72.99730517456055",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/f0602877_a27d_401c_98a4_c282784f077b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/5b471062_d1d6_49a8_838e_3521d2599375.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/346f259e_3163_48d9_8cd9_31066e84d9d5.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/95910fe5_aa1a_47a5_8194_06df09c5c063.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/a2528c12_7987_4b0b_8d01_45f6eb98c10c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/d43783a6_39f3_41a3_be9f_f54ed92e73dd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/abb2d1b8_837a_4e16_803d_b8ddf81622bf.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/7c4566b6_262c_42e7_983a_a38951ec442c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/4efb969c_532e_40fe_bbe9_772f6d6a965f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/01734cc9_5e5a_4329_bebf_31a4c3117382.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/ab9cc34a_2a3e_4d09_806b_898b3a66b16b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/66821a9d_852c_4332_bef4_0575369e3330.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/0f58f205_686e_4131_be55_fd622bf7f876.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/f7eb827f_6686_4409_a69b_caec70615177.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/21ed00da_42b9_4e23_90e4_716f4966860f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10890/0c6e1ecf_3839_4db8_ac88_fad36ef29f8f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/93389659_4f7c_419e_a7b6_eb6aa11bd55f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/6d50c692_f166_4534_8308_bae6ff8f9440.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/3ca0447a_8045_4a88_ba83_2ebbc6f397af.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10890/53ef1076_a9c5_4efb_a6f7_466641e7e900.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10890/03d4f2c4_cb5c_4b34_8e44_41e37cec67a1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10890/63793893_fe7d_4f29_af53_4fea1c72cbe0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/c188a04a_e1e4_4706_b306_3c97d5c60418.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/5ddd5db0_9c44_46b4_8bf8_98d275d333e6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/8a204509_9137_4dfd_99dd_279dcac0905b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/5011c0ac_3d2b_4860_a43d_b9a398cd6b3a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/c14ce94d_b357_4381_b581_126c79497daa.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/8ca0f0f2_4fe7_4beb_8959_607fbde8273a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/2c6ccb40_38d6_4baa_bb3f_c4a195414737.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/cd24edd8_4a14_413c_9f18_0f124c66f158.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/d75fcfb3_1a0f_4577_af79_ec5be7b7c5a3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/64569866_8cf4_45bb_9055_0bf90127c1e4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/1d0100ad_8ddf_4bd3_b444_95180b195d49.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/9c424ae3_1b52_4f3a_bc45_aacbc0a6ed9f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/7dffb7f8_4dd3_4842_b046_2cb4194484e2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10890/853cd997_abf0_41e7_bc09_849944ca2f8c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/1560a427_1bbc_46e2_b6be_dd101901e1ec.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/3fc51c3b_5f06_4308_92aa_736c984d77ed.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/fe0118b7_7c0a_437d_9d5f_876de1a6afd7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/ee2ad520_eb51_4bf9_84f9_85c7be9a51e3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/58f4fe46_1c75_46e8_a496_2ffd87d388d2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/71b52848_eb18_498a_bdc4_a7a8d7a9ef33.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10890/fa27d660_9f9c_4360_b33b_aa28efb1b87b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/3e9989ec_f3a5_4e36_abf5_8f1e5eb3f1ba.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/7339e4d7_8eda_4a16_acc6_7263ce577090.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/68753426_8f26_492a_8d86_45b130bac045.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/f7cfebb1_e825_4f59_ae47_8d7bf12ab577.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/27af17a2_ab85_43dc_b75c_56e0c35bbd7e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/2234e6ef_03a1_4023_a50b_68e6bd579ecd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10615/f31c27fa_752a_4192_a5c3_e55d85be71dc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/10890/8dfdabc4_8d22_466f_b9cb_789a4196dc88.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/cc3a493c_6f6e_44aa_8505_4eda12019c6b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/2737cba4_852f_4d52_abb7_e8f9377ed493.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/de37e1e0_484b_46b7_b992_e1ba192073d7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/b0717ee5_4c6e_493f_b24c_829867bfe1da.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/a9df07c7_35b7_4745_92fb_4374e9341922.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/ef11853d_043d_42cf_a66e_a41a420c7cc0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/9c933d89_03ae_4635_ad4a_e5031eb2ddd9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/7de27a26_c986_4491_b27d_d6665a7409d9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/66670658_014d_406d_8e14_a7b18199343f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/1966c06b_223f_4fc0_9042_328c625d94af.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/a270b300_f63b_4ab3_b905_886c3f8365a6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/314a1d65_fe52_4a53_a197_d51bfd9f9e83.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/62b6c843_08e2_491d_9516_5393b0f90703.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/327679a3_dae5_4282_b2db_3ee08ed7a962.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/6bfe64c2_2042_4acc_bf49_8105381e6d24.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/9e7d095d_73cd_4b18_a199_8a60f7267134.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/f9cd9a13_ceff_4afc_a089_f28ab1f70e54.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/b5b9adda_d2af_457f_ab73_d4aac285cb51.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/7515e363_cfeb_4c8b_8be5_7dc8a990b387.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/09e485b2_67d4_45f6_8745_2f20b7d81866.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/e144a43d_7f62_4bb9_87c8_fe402f25fc5b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/51513057_63f6_4f67_bbe2_1026922cb761.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/4df33854_2124_4ff7_97d2_f4c3489d3a9c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/551090b7_d1ac_4fef_8ab2_12fd96e2b057.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/a6cbf9e8_8d50_473e_8cda_a18513bb0b7b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/6d208b61_2cf1_442a_84b6_fad9b6d3f1b6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/929bcd14_1214_4cf9_8db8_e3be184a4a23.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/203/11046/cae52b3c_3c0f_42cc_988a_a7b8ea529bd1.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-3887",
                               "Franchisee_Name": "Kidzee Nayapura,",
                               "Operating_Status": "A",
                               "Address1": "House No. 11, Bansi Vihar,",
                               "Address2": " Lalsagar,  Opp. Satellite Hospital,",
                               "Place": "Nayapura.",
                               "City_Id": "509",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3887@kidzee.com",
                               "Mobile_No": "7412000500",
                               "Phone_No1": "0291-2573808",
                               "Phone_No2": "",
                               "Latitude": "26.339582",
                               "Longitude": "73.054821",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1885457/10511/a8c56e0f_6cba_48e2_95f6_891fff6ff494.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-4028",
                               "Franchisee_Name": "Kidzee Pal Road Jodhpur",
                               "Operating_Status": "A",
                               "Address1": "45, Ashapuran City,",
                               "Address2": "Pal Village,",
                               "Place": "Ashapuran City- Pal Road",
                               "City_Id": "509",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4028@kidzee.com",
                               "Mobile_No": "9829798333",
                               "Phone_No1": "0291-2980946",
                               "Phone_No2": "",
                               "Latitude": "26.211121",
                               "Longitude": "72.960006",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10941/2a894a6d_581d_4da5_a7be_d06721a38122.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10941/8f3f658b_ca60_4a47_a171_f74aff8a7d67.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10941/cbac4fb6_1144_48ad_ac55_1c7ce32cdd19.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10942/ba95eeaa_102b_491d_b538_604bed501c3e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10942/0bdcd1bc_abdb_420b_a905_1da6bda1a6b9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10942/532f798f_296e_405a_b108_e9a3139e330b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10942/fec71d04_98bb_4440_9f8e_64e58eb53149.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10942/a71c9f81_818e_4043_b123_97373054c0e5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10942/b7ccd81d_28ea_4397_b5cb_b1df41ba1cfe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10942/1ac614a4_7e88_4aa4_815e_9baacce788dc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1946863/10942/41cca606_f459_4fd3_8fe0_69b122338a29.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-4937",
                               "Franchisee_Name": "Kidzee Central School Scheme Jodhpur",
                               "Operating_Status": "A",
                               "Address1": "H.No.50, Central School Scheme,",
                               "Address2": "Opposite- Central Park,",
                               "Place": "Airforce Area, Opposite Central Park- Airforce Roa",
                               "City_Id": "509",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4937@kidzee.com",
                               "Mobile_No": "7742423203",
                               "Phone_No1": "9828286728",
                               "Phone_No2": "",
                               "Latitude": "26.257193",
                               "Longitude": "73.038776"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5292",
                               "Franchisee_Name": "Kidzee Jhalamand",
                               "Operating_Status": "A",
                               "Address1": "Plot No-212223, Indra Nagar,",
                               "Address2": "",
                               "Place": "Jhalamand, Bapu Nagar Ke Paas- Jodhpur,",
                               "City_Id": "509",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5292@kidzee.com",
                               "Mobile_No": "9024884808",
                               "Phone_No1": "9024884808",
                               "Phone_No2": "",
                               "Latitude": "26.23106605740057",
                               "Longitude": "73.05160976599089"
                            }
                         ]
                      },
                      {
                         "City_Name": "Karauli / Karoli / Kerowlee"
                      },
                      {
                         "City_Name": "Kota"
                      },
                      {
                         "City_Name": "Nagaur",
                         "Franchisee": {
                            "Franchisee_Code": "A-N-A-1081",
                            "Franchisee_Name": "Ankurum Kuchaman",
                            "Operating_Status": "A",
                            "Address1": "Opp. Kuchaman College",
                            "Address2": "Station Road Kuchaman College",
                            "Place": "Kuchaman",
                            "City_Id": "700",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "eklavyakuchaman@gmail.com",
                            "Mobile_No": "9462649888",
                            "Phone_No1": "9462649888",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Pachpadra"
                      },
                      {
                         "City_Name": "Sikar",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4420",
                            "Franchisee_Name": "Kidzee Sikar",
                            "Operating_Status": "A",
                            "Address1": "Ward No-22,",
                            "Address2": "",
                            "Place": "Rani Sati Road,Near Ashok Nagar- Sikar,",
                            "City_Id": "901",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4420@kidzee.com",
                            "Mobile_No": "9571845547",
                            "Phone_No1": "9571845547",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Sri ganga nagar / Ganga nagar",
                         "Franchisee": {
                            "Franchisee_Code": "A-N-A-1027",
                            "Franchisee_Name": "Ankurum Padampur",
                            "Operating_Status": "A",
                            "Address1": "Near Doctor Pad Store",
                            "Address2": "SBI Bank Opp.Preet Vihar",
                            "Place": "Padampur ,SBI Bank Opp.Preet Vihar",
                            "City_Id": "927",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "punitarora10@gmail.com",
                            "Mobile_No": "7742944577",
                            "Phone_No1": "7742944577",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Udaipur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-1998",
                               "Franchisee_Name": "Kidzee -Ambamata udaipur",
                               "Operating_Status": "A",
                               "Address1": "319-A, Harsh Nagar Rampura Road",
                               "Address2": "Udaipur",
                               "Place": "Rampura Road",
                               "City_Id": "969",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1998@kidzee.com",
                               "Mobile_No": "9414757809",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "24.5857",
                               "Longitude": "73.675695"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2079",
                               "Franchisee_Name": "Kidzee Udaipur",
                               "Operating_Status": "A",
                               "Address1": "H.No.7, New Navratan Complex,",
                               "Address2": "",
                               "Place": "Navratan, New Navratan Complex, Bhuwana,",
                               "City_Id": "969",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2079@kidzee.com",
                               "Mobile_No": "9414010087",
                               "Phone_No1": "9462891506 ",
                               "Phone_No2": "",
                               "Latitude": "24.621468",
                               "Longitude": "73.700387"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2977",
                               "Franchisee_Name": "Kidzee Shobhagpura",
                               "Operating_Status": "A",
                               "Address1": "3-4 ,Sangam Nagar,",
                               "Address2": "Near-Ceremony, DPS Road,",
                               "Place": "Shobhagpura, Near Ceremony Resort- Shobhagpura,",
                               "City_Id": "969",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2977@kidzee.com",
                               "Mobile_No": "9214444706",
                               "Phone_No1": "7597202220",
                               "Phone_No2": "",
                               "Latitude": "24.6071302",
                               "Longitude": "73.7191614"
                            },
                            {
                               "Franchisee_Code": "N-L-C-1947",
                               "Franchisee_Name": "Kidzee Bhupalpura Udaipur",
                               "Operating_Status": "A",
                               "Address1": "317, L1 Road",
                               "Address2": "Ahead of Bhandari Hospital",
                               "Place": "Bhupalpura,Udaipur,",
                               "City_Id": "969",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1947@kidzee.com",
                               "Mobile_No": "9828127277",
                               "Phone_No1": "8696999904",
                               "Phone_No2": "",
                               "Latitude": "24.585812",
                               "Longitude": "73.712693"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3761",
                               "Franchisee_Name": "Kidzee Hiran Mangari",
                               "Operating_Status": "A",
                               "Address1": "1-R-17, Gayatri Nagar",
                               "Address2": "Sec.5,",
                               "Place": "Hiran Mangari, ,Dungla House road- Udaipur",
                               "City_Id": "969",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3761@kidzee.com",
                               "Mobile_No": "9460803619",
                               "Phone_No1": "9887413619",
                               "Phone_No2": "",
                               "Latitude": "24.560764",
                               "Longitude": "73.726322"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4819",
                               "Franchisee_Name": "Kidzee Udaipur",
                               "Operating_Status": "A",
                               "Address1": "Plot No - 95, Jeevan Tara Road,",
                               "Address2": "Hiran mangri Sec - 14, Goverdhan Vilas,",
                               "Place": "Jeevantara Road, Goverdhan Villas",
                               "City_Id": "969",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4819@kidzee.com",
                               "Mobile_No": "9784289586",
                               "Phone_No1": "9001575827",
                               "Phone_No2": "",
                               "Latitude": "24.531132",
                               "Longitude": "73.677373"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4936",
                               "Franchisee_Name": "Kidzee Pratap Nagar",
                               "Operating_Status": "A",
                               "Address1": "House No-98, Meera Nagar,",
                               "Address2": "(Old RTO)",
                               "Place": "Pratap Nagar, Dhikili Road- Udaipur",
                               "City_Id": "969",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4936@kidzee.com",
                               "Mobile_No": "8619518890",
                               "Phone_No1": "7597262614",
                               "Phone_No2": "",
                               "Latitude": "24.599549416150843",
                               "Longitude": "73.75558029860258"
                            }
                         ]
                      },
                      {
                         "City_Name": "Balotra",
                         "Franchisee": {
                            "Franchisee_Code": "N-G-K-1081",
                            "Franchisee_Name": "Kidzee-Navkar",
                            "Operating_Status": "A",
                            "Address1": "C/o-Kidzee Navkar,",
                            "Address2": "",
                            "Place": "Navkar ,Near Rotary Garden, 2nd Railway Crossing,",
                            "City_Id": "1037",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1081@kidzee.com",
                            "Mobile_No": "9461523100",
                            "Phone_No1": "02988226451",
                            "Phone_No2": "",
                            "Latitude": "25.824657",
                            "Longitude": "72.247667"
                         }
                      },
                      {
                         "City_Name": "Bundi",
                         "Franchisee": {
                            "Franchisee_Code": "N-S-C-1652",
                            "Franchisee_Name": "Kidzee-Bundi-Rajasthan",
                            "Operating_Status": "A",
                            "Address1": "Balchand Para Gurunanak Colony, New Colony,",
                            "Address2": "Vikas Nagar Gayatri Nagar, Balchand Para",
                            "Place": "Vikas Nagar Gayatri Nagar- Bundi",
                            "City_Id": "1118",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1652@kidzee.com",
                            "Mobile_No": "9694435000",
                            "Phone_No1": "9694435000",
                            "Phone_No2": "",
                            "Latitude": "25.4305603",
                            "Longitude": "75.64455694444445"
                         }
                      },
                      {
                         "City_Name": "Ajmer",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2625",
                            "Franchisee_Name": "Kidzee Alwar Gate Ajmer",
                            "Operating_Status": "A",
                            "Address1": "Govindam House, Behind-Old Temple,",
                            "Address2": "Near-Mayo Girls College,",
                            "Place": "Mayo Link Road- Ajmer,",
                            "City_Id": "1136",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2625@kidzee.com",
                            "Mobile_No": "9166829982",
                            "Phone_No1": "",
                            "Phone_No2": "",
                            "Latitude": "26.439572",
                            "Longitude": "74.653653"
                         }
                      },
                      {
                         "City_Name": "Churu"
                      },
                      {
                         "City_Name": "Bayana"
                      },
                      {
                         "City_Name": "Bhusawar"
                      },
                      {
                         "City_Name": "Tonk"
                      },
                      {
                         "City_Name": "Fatehpur"
                      },
                      {
                         "City_Name": "Pali",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-3941",
                               "Franchisee_Name": "Kidzee Pali",
                               "Operating_Status": "A",
                               "Address1": "12, Friends Colony,",
                               "Address2": "",
                               "Place": "Pali ,Near Green Park- Friends Colony",
                               "City_Id": "1320",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3941@kidzee.com",
                               "Mobile_No": "9571233103",
                               "Phone_No1": "9571233103",
                               "Phone_No2": "",
                               "Latitude": "25.77506269299016",
                               "Longitude": "73.3357741398811"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4165",
                               "Franchisee_Name": "Kidzee Pali",
                               "Operating_Status": "A",
                               "Address1": "Number-43,",
                               "Address2": "Pali,",
                               "Place": "TilakNagar, Near Jain Temple,",
                               "City_Id": "1320",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4165@kidzee.com",
                               "Mobile_No": "9571233103",
                               "Phone_No1": "9571233103",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Marwar junction"
                      },
                      {
                         "City_Name": "Sawai madhopur"
                      },
                      {
                         "City_Name": "Baran"
                      },
                      {
                         "City_Name": "Hindaun"
                      },
                      {
                         "City_Name": "Rajsamand",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2297",
                            "Franchisee_Name": "Kidzee Kankroli Rajsamand",
                            "Operating_Status": "A",
                            "Address1": "Road No - 1, Sajjan Nagar,",
                            "Address2": "J.K Road, Kankroli,",
                            "Place": "Kankroli, Rajsamand, J.K Road,",
                            "City_Id": "1368",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2297@kidzee.com",
                            "Mobile_No": "9887671050",
                            "Phone_No1": "7665928556",
                            "Phone_No2": "",
                            "Latitude": "25.055775",
                            "Longitude": "73.889397"
                         }
                      },
                      {
                         "City_Name": "Ajmer",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2361",
                               "Franchisee_Name": "Kidzee Kishangarh",
                               "Operating_Status": "A",
                               "Address1": "H.No. 13-14, Basant Vihar Colony,",
                               "Address2": "Opp. Housing Board, Nr. Agrsen Nagar, Ajmer Road",
                               "Place": "New Housing Board- Kishangarh",
                               "City_Id": "1369",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2361@kidzee.com",
                               "Mobile_No": "9252678910",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "26.587034",
                               "Longitude": "74.856397"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2624",
                               "Franchisee_Name": "Kidzee Ramganj Near DAV college",
                               "Operating_Status": "A",
                               "Address1": "New Basti,",
                               "Address2": "Ground Floor,",
                               "Place": "Ramganj, Near DAV College,",
                               "City_Id": "1369",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee2624@kidzee.com",
                               "Mobile_No": "9166829982",
                               "Phone_No1": "8003042453",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Nimbahera"
                      },
                      {
                         "City_Name": "Chomu"
                      },
                      {
                         "City_Name": "Bandikui",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3163",
                            "Franchisee_Name": "NEHA JHALANI",
                            "Operating_Status": "A",
                            "Address1": "Ward No.29, Near Agra Railway Line,",
                            "Address2": "Behind Saini AVM School,",
                            "Place": "Bandikui (Dausa) Raj, Behind Saini AVM School Sika",
                            "City_Id": "1408",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3163@kidzee.com",
                            "Mobile_No": "9251565600",
                            "Phone_No1": "142011111",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Bijainagar"
                      },
                      {
                         "City_Name": "Beawar"
                      },
                      {
                         "City_Name": "Jaisalmer",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2626",
                               "Franchisee_Name": "Kidzee Jaisalmer Rajasthan",
                               "Operating_Status": "A",
                               "Address1": "912-14,",
                               "Address2": "",
                               "Place": "Indira Colony, Jaisalmer,",
                               "City_Id": "1470",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2626@kidzee.com",
                               "Mobile_No": "9414136016",
                               "Phone_No1": "02992-250467",
                               "Phone_No2": "",
                               "Latitude": "26.913889",
                               "Longitude": "70.938333",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2626/227/908a65c7_dd88_428a_80c0_a921acd3b2f4.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/883172/10853/5d4041e9_96f1_45e0_8c66_6ac838120974.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/883172/10853/bb9c6e3b_3564_4268_bf90_f0bfd9473ba1.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/883172/10853/c2652cdd_6ac7_451f_a7ba_ce4d993a9b31.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-2626",
                               "Franchisee_Name": "Kidzee Jaisalmer Rajasthan",
                               "Operating_Status": "A",
                               "Address1": "912-14,",
                               "Address2": "",
                               "Place": "Indira Colony, Jaisalmer,",
                               "City_Id": "1470",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2626@kidzee.com",
                               "Mobile_No": "9414136016",
                               "Phone_No1": "02992-250467",
                               "Phone_No2": "",
                               "Latitude": "26.913889",
                               "Longitude": "70.938333",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2626/227/908a65c7_dd88_428a_80c0_a921acd3b2f4.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/883172/10853/5d4041e9_96f1_45e0_8c66_6ac838120974.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/883172/10853/bb9c6e3b_3564_4268_bf90_f0bfd9473ba1.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/883172/10853/c2652cdd_6ac7_451f_a7ba_ce4d993a9b31.jpg"
                                  }
                               ]
                            }
                         ]
                      },
                      {
                         "City_Name": "Nathdwara"
                      },
                      {
                         "City_Name": "Rishabhdev"
                      },
                      {
                         "City_Name": "Nimbahera"
                      },
                      {
                         "City_Name": "Mandalgarh"
                      },
                      {
                         "City_Name": "Shahpura"
                      },
                      {
                         "City_Name": "Bijayanagar"
                      },
                      {
                         "City_Name": "Gulabpura"
                      },
                      {
                         "City_Name": "Mavli"
                      },
                      {
                         "City_Name": "Bijoliya"
                      },
                      {
                         "City_Name": "Bilara"
                      },
                      {
                         "City_Name": "Behror",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2819",
                               "Franchisee_Name": "Kidzee Behror",
                               "Operating_Status": "A",
                               "Address1": "Guru Kripa, Hamindpur Road,",
                               "Address2": "",
                               "Place": "Behror, Nr-Maranatha School- Behror,",
                               "City_Id": "1517",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2819@kidzee.com",
                               "Mobile_No": "9784320119",
                               "Phone_No1": "9784320119",
                               "Phone_No2": "",
                               "Latitude": "27.879994",
                               "Longitude": "76.279998"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4140",
                               "Franchisee_Name": "Kidzee Gokulpur",
                               "Operating_Status": "A",
                               "Address1": "Near new Court Behror",
                               "Address2": "village-Gokulpur, Post- Sherpur",
                               "Place": "Gokulpur, ,Near new Court Behror village- Behror",
                               "City_Id": "1517",
                               "State_Id": "31",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4140@kidzee.com",
                               "Mobile_No": "9636344444",
                               "Phone_No1": "014411111",
                               "Phone_No2": "",
                               "Latitude": "27.8643460",
                               "Longitude": "76.29243800"
                            }
                         ]
                      },
                      {
                         "City_Name": "Jhunjhunu",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5035",
                            "Franchisee_Name": "Kidzee Jhunjhunu",
                            "Operating_Status": "A",
                            "Address1": "A-21, Near Police Line Phatak, ",
                            "Address2": "Ashoka Leyland Road, Behind Ravi Defence Academy",
                            "Place": "Jhunjunu ,Behind Ravi Defence Academy",
                            "City_Id": "1528",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5035@kidzee.com",
                            "Mobile_No": "8058888008",
                            "Phone_No1": "9712998939",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Ramganj mandi"
                      },
                      {
                         "City_Name": "Neemrana"
                      },
                      {
                         "City_Name": "Phulera"
                      },
                      {
                         "City_Name": "Sangaria"
                      },
                      {
                         "City_Name": "Sagwara",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4548",
                            "Franchisee_Name": "Kidzee Swagara",
                            "Operating_Status": "A",
                            "Address1": "Sutharo Ka Nohra,",
                            "Address2": "Sutharwada,",
                            "Place": "Kidzee Sagwara,",
                            "City_Id": "1875",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4548@kidzee.com",
                            "Mobile_No": "9950688007",
                            "Phone_No1": "2966-252722",
                            "Phone_No2": "",
                            "Latitude": "23.6708629",
                            "Longitude": "74.0182495"
                         }
                      },
                      {
                         "City_Name": "Dungarpur"
                      },
                      {
                         "City_Name": "Dungarpur"
                      },
                      {
                         "City_Name": "Pali"
                      },
                      {
                         "City_Name": "Chotikhatu",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5076",
                            "Franchisee_Name": "Kidzee Chotikhatu",
                            "Operating_Status": "A",
                            "Address1": "Dipeshwar Palace,",
                            "Address2": "Bhatta Chauraha",
                            "Place": "Deedwana ,Bhatta Chauraha- Chotikhatu",
                            "City_Id": "1936",
                            "State_Id": "31",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5076@kidzee.com",
                            "Mobile_No": "9983646583",
                            "Phone_No1": "9983646583",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Sikkim",
                   "City": [
                      {
                         "City_Name": "Gangtok",
                         "Franchisee": {
                            "Franchisee_Code": "E-U-U-776",
                            "Franchisee_Name": "KIDZEE-Tadong- Gangtok- Zion Education Trust",
                            "Operating_Status": "A",
                            "Address1": "M.P.Golai, Tadong,",
                            "Address2": "",
                            "Place": "Metro Point Tadong,",
                            "City_Id": "345",
                            "State_Id": "32",
                            "Country_Id": "1",
                            "Email_Id": "kidzee776@kidzee.com",
                            "Mobile_No": "7430907976",
                            "Phone_No1": "",
                            "Phone_No2": "",
                            "Latitude": "27.310402",
                            "Longitude": "88.597795"
                         }
                      },
                      {
                         "City_Name": "Namchi"
                      },
                      {
                         "City_Name": "Singtam",
                         "Franchisee": {
                            "Franchisee_Code": "E-U-C-770",
                            "Franchisee_Name": "KIDZEE-SINGTHAM- Sikkim- FIRST STEP (SINGHTAM)",
                            "Operating_Status": "A",
                            "Address1": "Bright Stare Digital Inlighting,",
                            "Address2": "1st Floor,",
                            "Place": "Singtam, Bright Stare Digital Inlighting",
                            "City_Id": "915",
                            "State_Id": "32",
                            "Country_Id": "1",
                            "Email_Id": "kidzee770@kidzee.com",
                            "Mobile_No": "9733445566",
                            "Phone_No1": "2251439",
                            "Phone_No2": "",
                            "Latitude": "27.231929",
                            "Longitude": "88.498737"
                         }
                      },
                      {
                         "City_Name": "Jorethang"
                      },
                      {
                         "City_Name": "Geyzing",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-3883",
                               "Franchisee_Name": "Kidzee Gayzing",
                               "Operating_Status": "A",
                               "Address1": "Near State Bank Of India,",
                               "Address2": "Hospital Road",
                               "Place": "Gayzing Bazar ,Below union Bank of India Gyalshing",
                               "City_Id": "1657",
                               "State_Id": "32",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3883@kidzee.com",
                               "Mobile_No": "7547997079",
                               "Phone_No1": "7547997079",
                               "Phone_No2": "",
                               "Latitude": "27.2644° N",
                               "Longitude": "88.2765° E",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1913451/10669/6140ad9f_c754_49a5_8f3e_5fae26027023.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1913451/10669/03cf884b_fce0_4be3_8fe1_e3b4e2df8741.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4687",
                               "Franchisee_Name": "Kidzee Tashiding",
                               "Operating_Status": "A",
                               "Address1": "PO- SINEK, Upper Lasso",
                               "Address2": "PS- TIKJUK",
                               "Place": "Tashiding ,Upper Lasso",
                               "City_Id": "1657",
                               "State_Id": "32",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4687@kidzee.com",
                               "Mobile_No": "9732541617",
                               "Phone_No1": "9732541617",
                               "Phone_No2": "",
                               "Latitude": "27.331160",
                               "Longitude": "88.288421"
                            }
                         ]
                      },
                      {
                         "City_Name": "Namthang",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4372",
                            "Franchisee_Name": "Kidzee Namthang",
                            "Operating_Status": "A",
                            "Address1": "Nalam Kolbong",
                            "Address2": "Namthang illaka Sub division Namchi",
                            "Place": "Namthang Illaka, Sub Division Namchi South Sikkim",
                            "City_Id": "1972",
                            "State_Id": "32",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4372@kidzee.com",
                            "Mobile_No": "7076728443",
                            "Phone_No1": "7076728443",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Tamil Nadu",
                   "City": [
                      {
                         "City_Name": "Chennai",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-A-M-1395",
                               "Franchisee_Name": "Kidzee Velachery Vijay Nagar",
                               "Operating_Status": "A",
                               "Address1": "No:368 & 369,",
                               "Address2": "3rd Main Road,",
                               "Place": "Velachery, Vijay Nagar, Near Vijaya Nagar Bus Stop",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1395@kidzee.com",
                               "Mobile_No": "9941982444",
                               "Phone_No1": "9941982444",
                               "Phone_No2": "",
                               "Latitude": "12.974778",
                               "Longitude": "80.217796"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1365",
                               "Franchisee_Name": "Kidzee- Porur",
                               "Operating_Status": "A",
                               "Address1": "110, Devadoss Street,",
                               "Address2": "Guruswamy Nagar, Madanandapuram, ",
                               "Place": "Porur, Near Amma Canteen,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1365@kidzee.com",
                               "Mobile_No": "9962072800",
                               "Phone_No1": "9962072800",
                               "Phone_No2": "",
                               "Latitude": "13.022007821707193",
                               "Longitude": "80.15628878965754"
                            },
                            {
                               "Franchisee_Code": "S-B-U-1155",
                               "Franchisee_Name": "Kidzee-Pozhichalur",
                               "Operating_Status": "A",
                               "Address1": "5/339, First Cross Street,",
                               "Address2": "Agasthee Swara Nagar,",
                               "Place": "Pozhichalur, Nea- Anjineyar Temple,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1155@kidzee.com",
                               "Mobile_No": "9443370386",
                               "Phone_No1": "9789144204",
                               "Phone_No2": "0",
                               "Latitude": "12.978595",
                               "Longitude": "80.144434"
                            },
                            {
                               "Franchisee_Code": "S-L-S-1546",
                               "Franchisee_Name": "Kidzee-Camp Road",
                               "Operating_Status": "A",
                               "Address1": "No. 35, 10th Street,",
                               "Address2": " Padmavathy Nagar Extn, Madambakkam Main Road",
                               "Place": "Camp Road ,Near Madambakkam Main Road",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1546@kidzee.com",
                               "Mobile_No": "8939737715",
                               "Phone_No1": "8939737715",
                               "Phone_No2": "",
                               "Latitude": "12.915481",
                               "Longitude": "80.144143"
                            },
                            {
                               "Franchisee_Code": "S-L-S-1576",
                               "Franchisee_Name": "Kidzee-Thoraipakkam",
                               "Operating_Status": "A",
                               "Address1": "No.1, Netaji Avenue,",
                               "Address2": "",
                               "Place": "Thoraipakkam",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1576@kidzee.com",
                               "Mobile_No": "9962036835",
                               "Phone_No1": "9962036865",
                               "Phone_No2": "",
                               "Latitude": "12.94311",
                               "Longitude": "80.234917"
                            },
                            {
                               "Franchisee_Code": "S-S-S-1459",
                               "Franchisee_Name": "Kidzee - Teynampet",
                               "Operating_Status": "A",
                               "Address1": "No-1, 2nd Cross Street,",
                               "Address2": "Seethammal Extension,",
                               "Place": "Teynampet, Near- S.I.E.T. College,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1459@kidzee.com",
                               "Mobile_No": "9500055277",
                               "Phone_No1": "044-42031964",
                               "Phone_No2": "",
                               "Latitude": "13.034816",
                               "Longitude": "80.249102"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2123",
                               "Franchisee_Name": "Kidzee Kavangarai, Chennai",
                               "Operating_Status": "A",
                               "Address1": "No 13 - 14  gurushanthi nagar",
                               "Address2": "Kavangarai,",
                               "Place": "Puzhal, opp to medical shop",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2123@kidzee.com",
                               "Mobile_No": "9840834350",
                               "Phone_No1": "9840834350",
                               "Phone_No2": "",
                               "Latitude": "13.170548",
                               "Longitude": "80.195405"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2356",
                               "Franchisee_Name": "Kidzee Anna Nagar Chennai",
                               "Operating_Status": "A",
                               "Address1": "No 1388, Golden Colony,",
                               "Address2": "Anna Nagar West Extension",
                               "Place": "Anna Nagar, Behind golden flats",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2356@kidzee.com",
                               "Mobile_No": "7708815730",
                               "Phone_No1": "7708815710",
                               "Phone_No2": "",
                               "Latitude": "13.090157",
                               "Longitude": "80.180256"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2546",
                               "Franchisee_Name": "Kidzee Madipakkam Chennai",
                               "Operating_Status": "A",
                               "Address1": "Plot No 31-B, Door no 45A/5",
                               "Address2": "Sankaran Street",
                               "Place": "Madipakkam, Near ponniamman koil",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2546@kidzee.com",
                               "Mobile_No": "9962900022",
                               "Phone_No1": "9962900022",
                               "Phone_No2": "",
                               "Latitude": "12.970341",
                               "Longitude": "80.193783"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2533",
                               "Franchisee_Name": "Kidzee MKB Nagar Chennai",
                               "Operating_Status": "A",
                               "Address1": "No-320, West Avenue,",
                               "Address2": "",
                               "Place": "MKB Nagar, Near Marriage Hall,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2533@kidzee.com",
                               "Mobile_No": "9884929501",
                               "Phone_No1": "9884929501",
                               "Phone_No2": "",
                               "Latitude": "12.969257",
                               "Longitude": "80.148989"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2636",
                               "Franchisee_Name": "Kidzee Chrompet",
                               "Operating_Status": "A",
                               "Address1": "No.201, 6th Street,",
                               "Address2": "",
                               "Place": "Chrompet, Nagappa Nagar,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2636@kidzee.com",
                               "Mobile_No": "9841658196",
                               "Phone_No1": "9894884888",
                               "Phone_No2": "",
                               "Latitude": "12.951848440281372",
                               "Longitude": "80.13534056315996"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2647",
                               "Franchisee_Name": "Kidzee Soonambukolathur, Kovilambakkam",
                               "Operating_Status": "A",
                               "Address1": "House No.261, Viduthalai Nagar,",
                               "Address2": "2nd Main Road (Near-Maxworth Nagar),",
                               "Place": "Soonambukolathur, Kovilambakkam,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2647@kidzee.com",
                               "Mobile_No": "9884223331",
                               "Phone_No1": "9884223331",
                               "Phone_No2": "",
                               "Latitude": "12.947685",
                               "Longitude": "80.196573"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2345",
                               "Franchisee_Name": "Kidzee  Villivakkam  Chennai",
                               "Operating_Status": "A",
                               "Address1": "No.37, Kumarasamy Nagar,",
                               "Address2": "Villivakkam",
                               "Place": "Villivakkam ",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2345@kidzee.com",
                               "Mobile_No": "7639542619",
                               "Phone_No1": "9962828109",
                               "Phone_No2": "",
                               "Latitude": "13.101377113215227",
                               "Longitude": "80.20201196099092"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2580",
                               "Franchisee_Name": "Kidzee Perumbakkam Chennai",
                               "Operating_Status": "A",
                               "Address1": "Bollineni Hillside, Block No.1 2,",
                               "Address2": "Unit No.1, Perumbakkam Road, Nookampalayam,",
                               "Place": "Perumbakkam, Opp To Nilgiri Super Market,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2580@kidzee.com",
                               "Mobile_No": "9600125819",
                               "Phone_No1": "8056436880",
                               "Phone_No2": "",
                               "Latitude": "12.887712550366615",
                               "Longitude": "80.20534374078369"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3241",
                               "Franchisee_Name": "Kidzee Madipakkam South",
                               "Operating_Status": "A",
                               "Address1": "1438, Sundararajan Street,",
                               "Address2": "Srinivasan Nagar, ",
                               "Place": "Madipakkam, Sundararajan Street, Srinivasan Nagar",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3241@kidzee.com",
                               "Mobile_No": "7871444488",
                               "Phone_No1": "7871444488",
                               "Phone_No2": "",
                               "Latitude": "12.966376945344452",
                               "Longitude": "80.2116459520264",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3241/96/430302a2_2800_4e73_a006_c86a34c8bd08.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-R-C-3021",
                               "Franchisee_Name": "Kidzee Maangadu",
                               "Operating_Status": "A",
                               "Address1": "Plot No 11, 1st main road, Sri Chakra Nagar",
                               "Address2": "Sri Chakra Nagar",
                               "Place": "Mangadu,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3021@kidzee.com",
                               "Mobile_No": "9962022326",
                               "Phone_No1": "9962022326",
                               "Phone_No2": "",
                               "Latitude": "13.08268",
                               "Longitude": "80.270718"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3023",
                               "Franchisee_Name": "Kidzee Kodambakkam",
                               "Operating_Status": "A",
                               "Address1": "Door-No 8,",
                               "Address2": "Chakrapani Street ,West-Mambalam,",
                               "Place": "Kodambakkam, Near-5, Lights,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3023@kidzee.com",
                               "Mobile_No": "9962045551",
                               "Phone_No1": "9962045551",
                               "Phone_No2": "",
                               "Latitude": "13.045653",
                               "Longitude": "80.19828"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3214",
                               "Franchisee_Name": "Kidzee vadakkupattu, Medavakkam",
                               "Operating_Status": "A",
                               "Address1": "Plot.No.2B/2, 5th Street,",
                               "Address2": "Dharmaboopathy Nagar, Madura",
                               "Place": "Vadakkupattu, Medavakkam, Near Gangai Amman Temple",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3214@kidzee.com",
                               "Mobile_No": "9944000679",
                               "Phone_No1": "9944000679",
                               "Phone_No2": "",
                               "Latitude": "12.917143",
                               "Longitude": "80.192349"
                            },
                            {
                               "Franchisee_Code": "S-S-S-1621",
                               "Franchisee_Name": "Kidzee - Pallikaranai",
                               "Operating_Status": "A",
                               "Address1": "Tranquill Nest , No 1, 3rd Main Road,",
                               "Address2": "Kamakoti nagar",
                               "Place": "Pallikaranai ,Near natesan super market",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1621@kidzee.com",
                               "Mobile_No": "9940480077",
                               "Phone_No1": "9940480077",
                               "Phone_No2": "",
                               "Latitude": "12.934797",
                               "Longitude": "80.210763"
                            },
                            {
                               "Franchisee_Code": "S-S-S-1678",
                               "Franchisee_Name": "Kidzee-Valasaravakkam",
                               "Operating_Status": "A",
                               "Address1": "Plot No.38, 6th street,",
                               "Address2": "Jai Nagar,",
                               "Place": "Valsaravakkam, Near-Chinna Porur Govt Hospital,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1678@kidzee.com",
                               "Mobile_No": "9176273977",
                               "Phone_No1": "9176273977",
                               "Phone_No2": "",
                               "Latitude": "13.037917",
                               "Longitude": "80.170917",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/528766/10708/d078b8e3_b9ad_4fd4_ab5b_5846b9cb8124.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/528766/10708/9d40f161_d7fb_48c5_8550_698ef57b57d7.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-C-1871",
                               "Franchisee_Name": "Kidzee- Thirupathinagar / Kolathur",
                               "Operating_Status": "A",
                               "Address1": "No 6/29,80ft Main Road,",
                               "Address2": "",
                               "Place": "Kumaran Nagar, Near- Kolathur,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1871@kidzee.com",
                               "Mobile_No": "9884929501",
                               "Phone_No1": "9884929501",
                               "Phone_No2": "",
                               "Latitude": "13.094256",
                               "Longitude": "80.18973"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2739",
                               "Franchisee_Name": "Kidzee Nanganallur Chennai",
                               "Operating_Status": "A",
                               "Address1": "Old No.9, New No.16,",
                               "Address2": "Sri Ram Colony,",
                               "Place": "Nanganallur, Near-Park,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2739@kidzee.com",
                               "Mobile_No": "9962232098",
                               "Phone_No1": "9962232098",
                               "Phone_No2": "",
                               "Latitude": "12.982721",
                               "Longitude": "80.193486"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2763",
                               "Franchisee_Name": "GUDUVANCHERI KIDZEE",
                               "Operating_Status": "A",
                               "Address1": "No.6, Lakshmi Nagar,",
                               "Address2": "Kanchipuram, District,",
                               "Place": "Guduvancheri, Nellikuppam Road, Chengalpattu-Taluk",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2763@kidzee.com",
                               "Mobile_No": "9841829799",
                               "Phone_No1": "9841829799",
                               "Phone_No2": "",
                               "Latitude": "12.831425",
                               "Longitude": "80.073866"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2793",
                               "Franchisee_Name": "Kidzee Avadi Chennai",
                               "Operating_Status": "A",
                               "Address1": "35, 3rd Cross Street,",
                               "Address2": "Sriram Nagar ,Paruthipattu",
                               "Place": "Avadi ,Sriram Nagar",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2793@kidzee.com",
                               "Mobile_No": "9884031438",
                               "Phone_No1": "9884031438",
                               "Phone_No2": "",
                               "Latitude": "13.036781",
                               "Longitude": "80.121997"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2866",
                               "Franchisee_Name": "Kidzee Virugambakkam Chennai",
                               "Operating_Status": "A",
                               "Address1": "1, (Old No-12), Sri Sarasvathi Nivas,",
                               "Address2": "AVM Avenue, 2nd Main Road, Nr.St.Johns School, Alwarthirunagar,",
                               "Place": "Virugambakkam, Near AVM Studio",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2866@kidzee.com",
                               "Mobile_No": "9884899764",
                               "Phone_No1": "9884899764",
                               "Phone_No2": "",
                               "Latitude": "13.053078",
                               "Longitude": "80.193101"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2867",
                               "Franchisee_Name": "Kidzee Porur karambakkam",
                               "Operating_Status": "A",
                               "Address1": "Door No-6/85, Plot No- 25,",
                               "Address2": "Samamyapuram Main Road,",
                               "Place": "Karambakkam, Near- Porur Lake",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2867@kidzee.com",
                               "Mobile_No": "9790705753",
                               "Phone_No1": "9790705753",
                               "Phone_No2": "",
                               "Latitude": "13.038834073382201",
                               "Longitude": "80.15085547815704",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1192599/10441/ad802b21_6277_409d_a35e_2f9f0e68f469.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1192599/10441/88d865d4_ed81_483d_b7ac_fa5b649d754b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1192599/10441/ddc84fc1_a764_47ef_b843_c5bb2b96e5ba.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1192599/10441/4ae3ce0b_5d3e_4d60_a961_bb137d5f07d3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1192599/10441/85b78ebe_60f9_48b9_abaa_27a276e4e898.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1192599/10441/fa2041aa_ecd7_4176_8263_3d57ab6264d4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1192599/10441/f439637b_7c4a_4fcd_8722_a167d6094a04.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1192599/10441/5f87123e_edf0_4179_be25_d2d2355dd1ec.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-2895",
                               "Franchisee_Name": "Kidzee Ayanavaram Chennai",
                               "Operating_Status": "A",
                               "Address1": "16/11, Officers Colony,",
                               "Address2": "Joint Office,",
                               "Place": "Ayanavaram, Water Tank",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2895@kidzee.com",
                               "Mobile_No": "9551600023",
                               "Phone_No1": "04445566772",
                               "Phone_No2": "",
                               "Latitude": "13.073727",
                               "Longitude": "80.214658"
                            },
                            {
                               "Franchisee_Code": "S-S-C-2038",
                               "Franchisee_Name": "Kidzee West Tambaram",
                               "Operating_Status": "A",
                               "Address1": "No.5, Deiva Nagar,",
                               "Address2": "Second Street,",
                               "Place": "West-Tambaram, Near- Brige,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2038@kidzee.com",
                               "Mobile_No": "9884960240",
                               "Phone_No1": "04422265171",
                               "Phone_No2": "",
                               "Latitude": "12.92264",
                               "Longitude": "80.109992"
                            },
                            {
                               "Franchisee_Code": "S-S-C-1489",
                               "Franchisee_Name": "Kidzee Medavakkam",
                               "Operating_Status": "A",
                               "Address1": "Plot No: 7, 16th Street,",
                               "Address2": "Soumya Nagar, Perumbakkam Main road, ",
                               "Place": "Medavakkam ,Near Perumbakkam Main road,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1489@kidzee.com",
                               "Mobile_No": "8825952577",
                               "Phone_No1": "9176145149",
                               "Phone_No2": "",
                               "Latitude": "12.907120515263633",
                               "Longitude": "80.1949058201294",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/441717/10830/4f86f935_b52e_4ca5_b73b_e75ce7df0ae2.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/441717/10831/24c95f8c_7df7_468f_8c07_7ce326447cab.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/441717/10831/da939d73_6cb5_4d85_8088_200234b61d60.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-2156",
                               "Franchisee_Name": "Kidzee Perungudi Chennai",
                               "Operating_Status": "A",
                               "Address1": "P.No 37, Door No 13/7",
                               "Address2": "Rampa Nagar main road",
                               "Place": "Perungudi, Near church",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2156@kidzee.com",
                               "Mobile_No": "9962036835",
                               "Phone_No1": "9962036835",
                               "Phone_No2": "",
                               "Latitude": "12.962881",
                               "Longitude": "80.242212"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1323",
                               "Franchisee_Name": "Kidzee- Ambattur",
                               "Operating_Status": "A",
                               "Address1": "No - 12A, Old Township Road,",
                               "Address2": "",
                               "Place": "Ambattur, Backside of Ponnu Super Market, Near to ",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1323@kidzee.com",
                               "Mobile_No": "9841741327",
                               "Phone_No1": "044-26571181",
                               "Phone_No2": "",
                               "Latitude": "13.121047",
                               "Longitude": "80.149695"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2722",
                               "Franchisee_Name": "Kidzee Padur, Old Mahabalipuram Road",
                               "Operating_Status": "A",
                               "Address1": "No.6, Annai Terasa Street,",
                               "Address2": "",
                               "Place": "Padur, Old Mahabalipuram Road, Annai Terasa Street",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2722@kidzee.com",
                               "Mobile_No": "8754670166",
                               "Phone_No1": "9600116644",
                               "Phone_No2": "",
                               "Latitude": "13.08268",
                               "Longitude": "80.270718"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2723",
                               "Franchisee_Name": "Kidzee Chengalpet",
                               "Operating_Status": "A",
                               "Address1": "No.1E, Devarajanar Street,",
                               "Address2": "Vedhachalam Nagar,",
                               "Place": "Chengalpet, Near-Depo,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2723@kidzee.com",
                               "Mobile_No": "9884929501",
                               "Phone_No1": "9884929501",
                               "Phone_No2": "",
                               "Latitude": "13.08268",
                               "Longitude": "80.270718"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2713",
                               "Franchisee_Name": "Kidzee Pammal Chennai",
                               "Operating_Status": "A",
                               "Address1": "Door No-10, Ground Floor & First Floor,",
                               "Address2": "25th Street,",
                               "Place": "Pammal, Shankar Nagar,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2713@kidzee.com",
                               "Mobile_No": "9965449494",
                               "Phone_No1": " 9976449494",
                               "Phone_No2": "",
                               "Latitude": "12.970127",
                               "Longitude": "80.126216"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2724",
                               "Franchisee_Name": "Kidzee Perambur",
                               "Operating_Status": "A",
                               "Address1": "No. 20/13,",
                               "Address2": "",
                               "Place": "Perambur, Meenakshi Street,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2724@kidzee.com",
                               "Mobile_No": "9884929501",
                               "Phone_No1": "9884929501",
                               "Phone_No2": "",
                               "Latitude": "13.118716",
                               "Longitude": "80.166104"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1323",
                               "Franchisee_Name": "Kidzee- Ambattur",
                               "Operating_Status": "A",
                               "Address1": "No - 12A, Old Township Road,",
                               "Address2": "",
                               "Place": "Ambattur, Backside of Ponnu Super Market, Near to ",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1323@kidzee.com",
                               "Mobile_No": "9841741327",
                               "Phone_No1": "044-26571181",
                               "Phone_No2": "",
                               "Latitude": "13.121047",
                               "Longitude": "80.149695"
                            },
                            {
                               "Franchisee_Code": "S-R-C-3387",
                               "Franchisee_Name": "Kidzee New Perungalathur",
                               "Operating_Status": "A",
                               "Address1": "No.12, Thiruvallur,",
                               "Address2": "3rd Cross Street,",
                               "Place": "New-Perungalathur,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3387@kidzee.com",
                               "Mobile_No": "9790842032",
                               "Phone_No1": "9790842032",
                               "Phone_No2": "",
                               "Latitude": "12.9070723",
                               "Longitude": "80.08939829999997"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3441",
                               "Franchisee_Name": "Kidzee Doctors Colony Kolathur",
                               "Operating_Status": "A",
                               "Address1": "No.87,",
                               "Address2": "Jayanthi Nagar,",
                               "Place": "Doctors Colony, Kolathur-99, (Near-Teachers Colony",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3441@kidzee.com",
                               "Mobile_No": "8610567463",
                               "Phone_No1": "9790999536",
                               "Phone_No2": "",
                               "Latitude": "13.126261",
                               "Longitude": "80.203895"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4250",
                               "Franchisee_Name": "Kidzee Gerugambakkam",
                               "Operating_Status": "A",
                               "Address1": "Plot No:19A,",
                               "Address2": "Raniammai Street,",
                               "Place": "Gerugambakkam, (Next to Arjun Garden), Sri Pachamm",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4250@kidzee.com",
                               "Mobile_No": "8328517764",
                               "Phone_No1": "8328517764",
                               "Phone_No2": "",
                               "Latitude": "13.009083",
                               "Longitude": "80.134155"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4407",
                               "Franchisee_Name": "Kidzee-Koot Road-Medavakkam",
                               "Operating_Status": "A",
                               "Address1": "Plot-14B, Swam Illam, Santhanam Street,",
                               "Address2": "VGP Pushpa Nagar, Velachery, Tambaram Road,",
                               "Place": "Medavakkam, Near Koot Road,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4407@kidzee.com",
                               "Mobile_No": "9884468750",
                               "Phone_No1": "9962788444",
                               "Phone_No2": "",
                               "Latitude": "12.920934",
                               "Longitude": "80.186261"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4256",
                               "Franchisee_Name": "Kidzee Thiruvanmiyur",
                               "Operating_Status": "A",
                               "Address1": "No 2/396, Najma Avenue, 2nd Street,",
                               "Address2": "Srinivasapuram,",
                               "Place": "Thiruvanmiyur, Najma Avenue,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4256@kidzee.com",
                               "Mobile_No": "9884300080",
                               "Phone_No1": "6383-494235",
                               "Phone_No2": "",
                               "Latitude": "12.975773944740562",
                               "Longitude": "80.25510949864197"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4743",
                               "Franchisee_Name": "Kidzee Kattupakam",
                               "Operating_Status": "A",
                               "Address1": "Plot No-22, 2nd Main Road, Royal Garden,",
                               "Address2": "Goparasanallur,",
                               "Place": "Kaatupaakam, Near Masque",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4743@kidzee.com",
                               "Mobile_No": "9443484328",
                               "Phone_No1": "9443484328",
                               "Phone_No2": "",
                               "Latitude": "13.047482",
                               "Longitude": "80.125248"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4579",
                               "Franchisee_Name": "Kidzee Gunduvancherry",
                               "Operating_Status": "A",
                               "Address1": "Plot-55,",
                               "Address2": "Viswanathapuram Main Street,",
                               "Place": "Guduvancheri, Near-St John School,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4579@kidzee.com",
                               "Mobile_No": "9941324207",
                               "Phone_No1": "9941324207",
                               "Phone_No2": "",
                               "Latitude": "12.850303",
                               "Longitude": "80.062658"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5053",
                               "Franchisee_Name": "Kidzee Kundrathur",
                               "Operating_Status": "A",
                               "Address1": "Plot no. 95, New Sr No- 85/1A2",
                               "Address2": " Devaki Nagar, Kundrathur",
                               "Place": "Kundrathur, near Dr. Abdul kalam park",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5053@kidzee.com",
                               "Mobile_No": "8328517764",
                               "Phone_No1": "8328517764",
                               "Phone_No2": "",
                               "Latitude": "12.995423",
                               "Longitude": "80.100635"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5088",
                               "Franchisee_Name": "Kidzee Thazhambur",
                               "Operating_Status": "A",
                               "Address1": "Plot 50 & 51, D.no 1/273,  Vee S Vee 3rd main road, Sri Krishna Nagar, Nathan Road, ",
                               "Address2": "Navalur,",
                               "Place": "Thazhambur ,Nathan Road,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5088@kidzee.com",
                               "Mobile_No": "8754670166",
                               "Phone_No1": "8754670166",
                               "Phone_No2": "",
                               "Latitude": "12.845330143021195",
                               "Longitude": "80.2115345423279"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4600",
                               "Franchisee_Name": "Kidzee West Tambaram",
                               "Operating_Status": "A",
                               "Address1": "Door No-72/4,",
                               "Address2": "Old State Bank Colony Road,",
                               "Place": "Tambaram West, Near-Bus Stop,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4600@kidzee.com",
                               "Mobile_No": "9445699902",
                               "Phone_No1": "9445699902",
                               "Phone_No2": "",
                               "Latitude": "12.93370",
                               "Longitude": "80.10310"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4640",
                               "Franchisee_Name": "Kidzee Chitlapakkam",
                               "Operating_Status": "A",
                               "Address1": "No.3, Ragavendra Street,",
                               "Address2": "Kanchipuram Dist,",
                               "Place": "Chitlapakkam, Near Shop",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4640@kidzee.com",
                               "Mobile_No": "9500451778",
                               "Phone_No1": "9500451778",
                               "Phone_No2": "",
                               "Latitude": "12.93126",
                               "Longitude": "80.14383"
                            },
                            {
                               "Franchisee_Code": "S-R-C-3242",
                               "Franchisee_Name": "Kidzee Anakaputhur",
                               "Operating_Status": "A",
                               "Address1": "No-13, 4th Street,",
                               "Address2": "",
                               "Place": "Anakaputhur, Gurusamy Nagar",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3242@kidzee.com",
                               "Mobile_No": "9976449494",
                               "Phone_No1": "9976449494",
                               "Phone_No2": "",
                               "Latitude": "12.98535",
                               "Longitude": "80.13189"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4949",
                               "Franchisee_Name": "Kidzee Kumbakonam",
                               "Operating_Status": "A",
                               "Address1": "Plot No -3, 3rd Cross Extension,",
                               "Address2": "Srinagar colony,",
                               "Place": "Kumbakonam,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4949@kidzee.com",
                               "Mobile_No": "8778253632",
                               "Phone_No1": "8778253632",
                               "Phone_No2": "",
                               "Latitude": "10.96956",
                               "Longitude": "79.38851"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3065",
                               "Franchisee_Name": "Kidzee Kelambakkam",
                               "Operating_Status": "A",
                               "Address1": "Plot No.2/24, Ground Floor,",
                               "Address2": " Suseela Nagar",
                               "Place": "Kelambakkam, IT Park",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3065@kidzee.com",
                               "Mobile_No": "8754670166",
                               "Phone_No1": "8754670166",
                               "Phone_No2": "",
                               "Latitude": "12.78994",
                               "Longitude": "80.22201"
                            },
                            {
                               "Franchisee_Code": "S-R-C-3601",
                               "Franchisee_Name": "Kidzee kolathur",
                               "Operating_Status": "A",
                               "Address1": "7/16, 4th Main Road,",
                               "Address2": "",
                               "Place": "kolathur, Near- Thirupathi Nagar,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3601@kidzee.com",
                               "Mobile_No": "9884929501",
                               "Phone_No1": "9884929501",
                               "Phone_No2": "",
                               "Latitude": "13.12160",
                               "Longitude": "80.21628"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3731",
                               "Franchisee_Name": "Kidzee Pudur,Ambattur",
                               "Operating_Status": "A",
                               "Address1": "Plot No.2, 6th Avenue",
                               "Address2": "1st Main Road, Banu Nagar",
                               "Place": "Pudur,Ambattur ,1st Main Road, Banu Nagar",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3731@kidzee.com",
                               "Mobile_No": "9840609501",
                               "Phone_No1": "9840609501",
                               "Phone_No2": "",
                               "Latitude": "13.13380",
                               "Longitude": "80.15864"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5054",
                               "Franchisee_Name": "Kidzee Virugambakkam",
                               "Operating_Status": "A",
                               "Address1": "No-90, Sai Nagar,",
                               "Address2": "6th street,",
                               "Place": "Virugambakkam",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5054@kidzee.com",
                               "Mobile_No": "6385056111",
                               "Phone_No1": "8220017695",
                               "Phone_No2": "",
                               "Latitude": "13.04745",
                               "Longitude": "80.18879"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5124",
                               "Franchisee_Name": "Kidzee Jawahar nagar",
                               "Operating_Status": "A",
                               "Address1": "No. 3",
                               "Address2": "Loco Scheme 1st Street",
                               "Place": "Jawahar Nagar",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5124@kidzee.com",
                               "Mobile_No": "9994741974",
                               "Phone_No1": "9996741974",
                               "Phone_No2": "",
                               "Latitude": "13.110877",
                               "Longitude": "80.22790"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5181",
                               "Franchisee_Name": "Kidzee Maraimalai Nagar",
                               "Operating_Status": "A",
                               "Address1": "House No :2 / 101 HIG ,",
                               "Address2": "Anna Salai road",
                               "Place": "Maraimalai Nagar ,Anna Salai road",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5181@kidzee.com",
                               "Mobile_No": "9788511233",
                               "Phone_No1": "9788511233",
                               "Phone_No2": "",
                               "Latitude": "12.786827787840116",
                               "Longitude": "80.03615349933624"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5220",
                               "Franchisee_Name": "Kidzee Karapakkam",
                               "Operating_Status": "A",
                               "Address1": "No 4 / A Sowdeeshwari Nagar ,",
                               "Address2": "First Main Road",
                               "Place": "Karapakkam ,Near dindugul thalapakathi ",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5220@kidzee.com",
                               "Mobile_No": "9840574101",
                               "Phone_No1": "9884723763",
                               "Phone_No2": "",
                               "Latitude": "12.930380",
                               "Longitude": "80.22911"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5240",
                               "Franchisee_Name": "KIDZEE KUMBAKONAM",
                               "Operating_Status": "A",
                               "Address1": "Plot.no.1 Sabari Nagar,",
                               "Address2": "Dr.Moorthi Road,Thanjavur",
                               "Place": "Kumbakonam Dr.Moorthi Road",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5240@kidzee.com",
                               "Mobile_No": "9994044195",
                               "Phone_No1": "9994044195",
                               "Phone_No2": "",
                               "Latitude": "10.97058",
                               "Longitude": "79.38901"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4159",
                               "Franchisee_Name": "Kidzee Nolambur",
                               "Operating_Status": "A",
                               "Address1": "Sona Villas, A16/11, HIG, Type-1,",
                               "Address2": "TNHB Scheme, 3rd Cross Street,",
                               "Place": "Nolambur, Behind Velammal Mandapam,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4159@kidzee.com",
                               "Mobile_No": "9176367707",
                               "Phone_No1": "461511111",
                               "Phone_No2": "",
                               "Latitude": "13.08127",
                               "Longitude": "80.16239"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4542",
                               "Franchisee_Name": "Kidzee Chrompet",
                               "Operating_Status": "A",
                               "Address1": "No:15,",
                               "Address2": "1st Cross Street,",
                               "Place": "Chromepet, Krishna Nagar, Hasthinapuram,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4542@kidzee.com",
                               "Mobile_No": "9841658196",
                               "Phone_No1": "9841658196",
                               "Phone_No2": "",
                               "Latitude": "12.95214",
                               "Longitude": "80.13528"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5196",
                               "Franchisee_Name": "Kidzee Sholinganallur",
                               "Operating_Status": "A",
                               "Address1": "no. 84, Kumarasamy Nagar,",
                               "Address2": "3rd Street,",
                               "Place": "Sholinganalur",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee5196@kidzee.com",
                               "Mobile_No": "6374071862",
                               "Phone_No1": "6374071862",
                               "Phone_No2": "",
                               "Latitude": "12.895353",
                               "Longitude": "80.222107"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5642",
                               "Franchisee_Name": "KIDZEE IYYAPANTHANGAL",
                               "Operating_Status": "A",
                               "Address1": "PLOT NO 14A, SUBBIAH NAGAR, ANNEXE CMDA KUNDRATHUR PANCHAYAT UNION VIDE PERMIT NO 1160/2005",
                               "Address2": "SRIPERUMBUDUR TALUK KANCHEEPURAM DISTRICT ",
                               "Place": "IYYAPANTHANGAL VILLAGE",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5642@kidzee.com",
                               "Mobile_No": "9841375956",
                               "Phone_No1": "9841375956",
                               "Phone_No2": "",
                               "Latitude": "13.04386111",
                               "Longitude": "80.13405556"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5553",
                               "Franchisee_Name": "Kidzee Vinayagapuram",
                               "Operating_Status": "A",
                               "Address1": "91/2 Sri Sai Nagar Puthugaram Village",
                               "Address2": "Surapet Main Road Vinayagapuram",
                               "Place": "Kolathur",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5553@kidzee.com",
                               "Mobile_No": "9791345774",
                               "Phone_No1": "9042742988",
                               "Phone_No2": "",
                               "Latitude": "13.140354",
                               "Longitude": "80.202342"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5641",
                               "Franchisee_Name": "Kidzee Mahindra world city",
                               "Operating_Status": "A",
                               "Address1": " Plot no-P19/1,Second Avenue Mahindra world city ",
                               "Address2": "Paranur village landmark - Paranur Station) ",
                               "Place": "Chengalpattu ",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee5641@kidzee.com",
                               "Mobile_No": "9962005531",
                               "Phone_No1": "9962005531",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-3462",
                               "Franchisee_Name": "Kidzee Sithalapakkam",
                               "Operating_Status": "A",
                               "Address1": "No.32, 2nd Street, Venus Colony,",
                               "Address2": "Near-Venus Colony Park",
                               "Place": "Sithalapakkam,",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3462@kidzee.com",
                               "Mobile_No": "8056436880",
                               "Phone_No1": "0000-000000",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-C-5502",
                               "Franchisee_Name": "Kidzee Korattur",
                               "Operating_Status": "A",
                               "Address1": "Door No: 458, 17th Street, TNHB Colony,",
                               "Address2": "Periyar Nagar,",
                               "Place": "Korattur",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5502@kidzee.com",
                               "Mobile_No": "8056925666",
                               "Phone_No1": "8056925666",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-S-3824",
                               "Franchisee_Name": "Kidzee kilpauk",
                               "Operating_Status": "A",
                               "Address1": "Old No-3 New No-7,",
                               "Address2": "New Avadi Road,",
                               "Place": "Kilpauk ",
                               "City_Id": "205",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3824@kidzee.com",
                               "Mobile_No": "9600123355",
                               "Phone_No1": "9600123355",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Coimbatore(kovai)",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-2515",
                               "Franchisee_Name": "Kidzee- COIMBATORE",
                               "Operating_Status": "A",
                               "Address1": "No-3, Kondasamy Nagar, No.4,",
                               "Address2": "Veerapandi Road, Press Colony Post,",
                               "Place": "Kondasamy Nagar,",
                               "City_Id": "220",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2515@kidzee.com",
                               "Mobile_No": "9944642233",
                               "Phone_No1": "9944451563",
                               "Phone_No2": "",
                               "Latitude": "11.022743",
                               "Longitude": "77.017239"
                            },
                            {
                               "Franchisee_Code": "S-R-C-3825",
                               "Franchisee_Name": "Kidzee Singanallur",
                               "Operating_Status": "A",
                               "Address1": "86/12 A, Vasantha Nagar,",
                               "Address2": "Singanallur, ",
                               "Place": "near shanthi theator",
                               "City_Id": "220",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3825@kidzee.com",
                               "Mobile_No": "7539907380",
                               "Phone_No1": "07539-907380",
                               "Phone_No2": "",
                               "Latitude": "11.007767",
                               "Longitude": "77.040330",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1880935/10502/b746ecac_fa06_4625_a25b_13c2f1317309.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1880935/10502/45cb6c1e_64ae_4b83_bf57_b9f35279a9ff.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-C-4914",
                               "Franchisee_Name": "Kidzee Vellakinar",
                               "Operating_Status": "A",
                               "Address1": "No.9, Masaniamman Nagar,",
                               "Address2": "Near TNHB Phase -1,",
                               "Place": "Vellakinar, Near Amman Temple,",
                               "City_Id": "220",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4914@kidzee.com",
                               "Mobile_No": "7092992591",
                               "Phone_No1": "7092992591",
                               "Phone_No2": "",
                               "Latitude": "11.8186",
                               "Longitude": "76.9543"
                            },
                            {
                               "Franchisee_Code": "S-R-C-3544",
                               "Franchisee_Name": "Kidzee Annur",
                               "Operating_Status": "A",
                               "Address1": "1/22-Kasbha,",
                               "Address2": "Azath Street,",
                               "Place": "Annur, Near- Police Station,",
                               "City_Id": "220",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3544@kidzee.com",
                               "Mobile_No": "8754636960",
                               "Phone_No1": "8754636960",
                               "Phone_No2": "",
                               "Latitude": "11.23473",
                               "Longitude": "77.10543"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5000",
                               "Franchisee_Name": "Kidzee Saravanampatti",
                               "Operating_Status": "A",
                               "Address1": "21 Postal & Telegraph (P & T ) Colony,",
                               "Address2": "4th Street",
                               "Place": "Saravanampatti ,near shriram sai shreyas apartment",
                               "City_Id": "220",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5000@kidzee.com",
                               "Mobile_No": "8111084999",
                               "Phone_No1": "848-9396576",
                               "Phone_No2": "",
                               "Latitude": "11.08587",
                               "Longitude": "76.98996"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4490",
                               "Franchisee_Name": "Kidzee Ondipudur",
                               "Operating_Status": "A",
                               "Address1": "26/2 ,Nehru nagar Ist street,",
                               "Address2": "Irugur Road,sulur",
                               "Place": "Ondipudur ,near vinayagar temple",
                               "City_Id": "220",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4490@kidzee.com",
                               "Mobile_No": "9655206071",
                               "Phone_No1": "9655206071",
                               "Phone_No2": "",
                               "Latitude": "11.01019",
                               "Longitude": "77.05222"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5546",
                               "Franchisee_Name": "Kidzee KovaiPudur",
                               "Operating_Status": "A",
                               "Address1": "U-44 RKV School Road",
                               "Address2": "Near Aanjaneya Pazhamudhir Nilayam Next to RKV School ",
                               "Place": "KovaiPudur",
                               "City_Id": "220",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5546@kidzee.com",
                               "Mobile_No": "6380051813",
                               "Phone_No1": "8680826530",
                               "Phone_No2": "",
                               "Latitude": "10.947849368215028",
                               "Longitude": "76.93495776758162"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5276",
                               "Franchisee_Name": "Kidzee Vilankurichi",
                               "Operating_Status": "A",
                               "Address1": "Door No. 34, PRK Gardens",
                               "Address2": "Kumaran Nagar",
                               "Place": "Vilankurichi",
                               "City_Id": "220",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5276@kidzee.com",
                               "Mobile_No": "6369973509",
                               "Phone_No1": "6369973509",
                               "Phone_No2": "",
                               "Latitude": "11.058438762468095",
                               "Longitude": "77.01312968309168",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/3047082/11086/1f312ad7_c3a7_4795_a51e_e7b0fd35e0c3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/3047082/11086/2fee513d_8fa0_457c_923d_d2db97444c34.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/3047082/11086/2f9f50d3_0910_4cdc_b749_7bf72b1c4a5b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/3047082/11086/3749bb87_5b22_4c35_ab6e_8fb19d2322fe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/3047082/11086/64faa619_eb82_4b16_8a7c_6e9fceb1113e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/3047082/11086/62344e96_b1f5_4177_b598_6463d548983e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/3047082/11086/b47cd1fb_f5a8_48bf_8b90_404443989c90.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/3047082/11086/b30a5b4f_58ed_4ec4_aea6_9f4d0a9051c1.jpg"
                                  }
                               ]
                            }
                         ]
                      },
                      {
                         "City_Name": "Dharmapuri",
                         "Franchisee": {
                            "Franchisee_Code": "S-U-S-956",
                            "Franchisee_Name": "Kidzee- Hosur- Tamil Nadu",
                            "Operating_Status": "A",
                            "Address1": "DNC Vijay Lodge, Nethaji Byepass Road,",
                            "Address2": "",
                            "Place": "Dharmapuri, Opp to Thangam Hospital,",
                            "City_Id": "256",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee956@kidzee.com",
                            "Mobile_No": "9786222042",
                            "Phone_No1": "9789179192",
                            "Phone_No2": "",
                            "Latitude": "12.736718",
                            "Longitude": "77.818502"
                         }
                      },
                      {
                         "City_Name": "Erode"
                      },
                      {
                         "City_Name": "Hosur",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-1899",
                            "Franchisee_Name": "Kidzee-Hosur",
                            "Operating_Status": "A",
                            "Address1": "2/903, NGGO'S Colony,",
                            "Address2": "",
                            "Place": "Near Bagalur Road,",
                            "City_Id": "442",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1899@kidzee.com",
                            "Mobile_No": "9884484993",
                            "Phone_No1": "1234-000000",
                            "Phone_No2": "",
                            "Latitude": "12.749473",
                            "Longitude": "77.833446",
                            "Images": {
                               "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/589383/11026/ab943002_6712_4a36_9010_f4f05b648dc8.jpg"
                            }
                         }
                      },
                      {
                         "City_Name": "Salem",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-A-C-1446",
                               "Franchisee_Name": "Kidzee - Attur",
                               "Operating_Status": "A",
                               "Address1": "D. No 220,",
                               "Address2": "Attur Town,",
                               "Place": "Attur ,Gandhinagar",
                               "City_Id": "865",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1446@kidzee.com",
                               "Mobile_No": "9894341757",
                               "Phone_No1": "4282240217",
                               "Phone_No2": "",
                               "Latitude": "11.59695",
                               "Longitude": "78.60139"
                            },
                            {
                               "Franchisee_Code": "S-A-C-1446",
                               "Franchisee_Name": "Kidzee - Attur",
                               "Operating_Status": "A",
                               "Address1": "D. No 220,",
                               "Address2": "Attur Town,",
                               "Place": "Attur ,Gandhinagar",
                               "City_Id": "865",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1446@kidzee.com",
                               "Mobile_No": "9894341757",
                               "Phone_No1": "4282240217",
                               "Phone_No2": "",
                               "Latitude": "11.59695",
                               "Longitude": "78.60139"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4900",
                               "Franchisee_Name": "Kidzee Fairlands Center",
                               "Operating_Status": "A",
                               "Address1": "No. 3, Greenways Road,",
                               "Address2": "Near Cream & Fudge",
                               "Place": "Fairlands ,Near cream shop",
                               "City_Id": "865",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4900@kidzee.com",
                               "Mobile_No": "9043238201",
                               "Phone_No1": "9043238201",
                               "Phone_No2": "",
                               "Latitude": "11.67580",
                               "Longitude": "78.14694"
                            }
                         ]
                      },
                      {
                         "City_Name": "Thanjavur / Tanjore",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4528",
                            "Franchisee_Name": "Kidzee Tanjavur",
                            "Operating_Status": "A",
                            "Address1": "B-81,3rd Cross Street, Municipal Colony,",
                            "Address2": "Medical College Road,",
                            "Place": "Tanjavur, Near-Sivan Temple,",
                            "City_Id": "947",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4528@kidzee.com",
                            "Mobile_No": "8220638131",
                            "Phone_No1": "8220638131",
                            "Phone_No2": "",
                            "Latitude": "10.76525",
                            "Longitude": "79.113778"
                         }
                      },
                      {
                         "City_Name": "Tirupur",
                         "Franchisee": {
                            "Franchisee_Code": "S-S-C-1980",
                            "Franchisee_Name": "Kidzee Dharapuram",
                            "Operating_Status": "A",
                            "Address1": "No-64, Kottapulipalyam Road,",
                            "Address2": "",
                            "Place": "Dharapuram, Janani Hospital Opposite,",
                            "City_Id": "960",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1980@kidzee.com",
                            "Mobile_No": "9442222450",
                            "Phone_No1": "04258-227150",
                            "Phone_No2": "",
                            "Latitude": "10.732935",
                            "Longitude": "77.521813",
                            "Images": [
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/2c3b5479_dadd_4b32_b388_793982ab0a9c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/361c5270_15c7_4a27_8647_73cbee4e90b0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/513b34a3_4bc4_46f0_b5cb_25474c63fa35.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/c2bfab19_b07c_4190_bb8c_00cea3436e9a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/56ab7064_e452_4e9d_b67c_44164816fa3c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/781ae186_457d_481a_9139_400baf344fc0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/e738cb3b_0505_46e5_9b35_f528da08f0fd.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/2956a721_c8e5_408b_bf65_58ebdae9181a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/5cd70f50_eb4d_48a8_9441_ed008bd62f93.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/546a8f60_38a4_4502_aa0d_fb98a2531107.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/83867311_3b18_4e8c_bf5b_8453a9bb97c6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/4a06398f_a23a_41b7_8e59_46cfad36f2b0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/9b53cf87_e24d_454e_8ce9_9891fa3923e9.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/48359997_d959_4834_800c_b5c08d36eba2.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/eb2723f0_07f8_47a3_8aa6_a5a457c90e4a.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/1b1fce4a_1b7c_40ab_91a2_ed0ae8451e38.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/da899d4d_c2f0_439d_bd0b_44f7e9fa4d9f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/5aa97ca4_cbcf_4892_9411_f8d33f72f393.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/91d9ca2a_0b14_4941_8bb0_78bb32279aeb.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/485f7dba_7526_4302_af8b_e548a283f13e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/58ec462f_834b_4597_b436_142a9fefa44d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/9b513dc7_690e_4009_a8c5_617ab2365683.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/052ca584_e151_4351_b64f_3767dcdfbd87.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/c6641ee5_fbdf_4d53_815c_4c093af96e83.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/7622ce97_3939_41df_89ef_af33bc13115b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/50f9ad42_1477_4eee_a500_b212280e21ca.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/604af6fd_f560_45ff_bcc4_5b4e08631960.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/39bd6779_2d2e_44e8_9f9b_4062bdb90804.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/0cb35647_f31d_4d94_ac10_fe9c87da5222.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/aa97372d_b4ea_4c59_b2e7_6c1b62bfeb98.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/751cdf48_1d73_4074_8d4a_e91f0e45374f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/616b21fd_e141_4db7_9255_59c91567cdda.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/8e5e5d30_99ba_494a_bbd0_4be4f360988f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/9a1b73d4_8d06_4329_a443_b43fd2cca43d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/c2d53aeb_e99f_4d34_bba0_56cd71ca8588.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10639/731fffb3_1122_4543_8a36_0eb9163a632c.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/4305a8f5_79bc_4314_bcfa_3ff1922e0459.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/883104c5_2a1c_4011_bd9a_b107199541ec.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/018d52f4_480b_4624_8e89_7fea1a70361f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/2ca6bdbf_a160_4e84_a67f_d6cd4c2b8689.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/2872e8c5_b010_41ab_964e_2f694f193c11.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/321c8300_632a_4f6d_80a9_16c5b35171b7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/5e8764d2_6162_4212_a517_758d94140b4b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/7ad470f8_08d8_4172_a765_953b3c5ea4ea.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/ab31d382_c1e9_435e_aa0c_46a5ccdc4c1f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/77eec110_eff8_4c5e_acc0_fb286c20c5e6.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/7169152b_7343_48c9_a488_c823e9f3ef1d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/602855/10641/7c2aaed8_956f_4807_89bf_39cf8782c0e8.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10738/b30089af_8f5e_420b_886d_9eacf6018bb0.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10738/bc60d004_f21f_46e3_a53f_71fb29464fa1.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10738/49351073_db89_45fa_b23e_6da4bbe8a37e.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10738/dc78003b_bc1a_44f4_8599_a4eb8f62d651.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10738/5d6a5fb5_1125_4775_bfdb_af455c2e121b.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10740/7275413a_0759_483f_aab1_c028d4d5787c.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10740/c373ee8c_334e_4aa1_a6a9_cd272a1b01f5.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10740/72eab0f3_12cc_4575_a93f_0f571098d1a8.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10740/1793ab26_7277_416d_8d93_e1faee38a1cd.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10740/2c69c8f9_74c6_47ff_9833_ea6fdb4fab6e.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10740/39c9b463_34e1_4f97_bc83_5ef7fe19d2c9.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10741/2f02f744_3cdc_4676_969f_3e1dfa8f9855.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10741/6eec2b23_ff9f_48ce_9083_67b8e12e1f4f.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10741/626685de_4512_4756_8acd_df7ca10f47ed.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10741/a02f9943_e10c_4adc_bbd8_373632e4cc47.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10742/d3657fad_f031_47d5_a4f3_43797cc1085b.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10742/21b11296_c2ea_4c9f_8e6a_f194a8afe340.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10742/27d44fdc_f2ad_4517_ba70_4981dc1bce92.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10742/d5241c37_3da0_42aa_b7ee_a407dcb68b7e.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10741/92b6d1ed_0c98_4031_9f74_c39450af8f00.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/d375bbe3_ca83_4085_990c_c19150f33867.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/0ce38a3c_552c_4eb8_a56b_5872774ed21a.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/2871b1b9_6986_4f65_a6d7_3222787e0ada.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/17b06bf0_cce7_4805_83bc_2e9bed2ceb81.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/b40f2be1_8769_4921_8ac3_331ce52e6ed0.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/e67e8bd9_2d0a_456c_8929_651a0b31a9d5.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/bafbda0f_9d5a_4d85_88ff_f443ea6ea174.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/40a0fab3_e600_42cb_a87e_d115c3ae5254.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/51a5cb58_34ad_4b30_862b_acbed303505e.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/69559057_18a9_4fa2_9450_5e4f27722c5c.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/f8b35993_abef_48ef_81ec_295d48165641.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/de0a6c13_e300_43b7_9ff7_50f8b10cb157.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/56016746_af35_4f7e_ad90_8fdbdf05e2d2.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/fc3365f7_adc6_4ced_b683_d589189329dc.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/fec0ac99_7b71_458e_9ff1_579157cedd86.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/d0fdc9ff_693d_4c35_b7bd_da40e08e56ae.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10754/59cbfc2e_8f7b_40ff_886d_ea67b91d8fe5.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/acbb39ca_269f_43c5_a32f_b1c7f395ebb3.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/0f7fce44_0de7_4b73_a94f_2fd43197b70f.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/8bf52a76_5d71_4321_aaa5_6bd03e150a53.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/a7c0a415_1ca6_4154_904d_c142ca6d3e8c.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/b2e19397_d829_4fb6_8974_afa5426dfe30.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/7be28e6b_fb80_4ec8_b4b0_37c488a96d42.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/3092f60c_733e_44d8_a62b_d025cda653ab.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/f1356318_8d9e_429c_8016_76b3976bbacd.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/45e6b3c2_6c32_43c1_87c9_71e088627b6f.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/69b7b0f0_6b07_4a3f_b4cd_94101aba3ad8.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/5682c1a1_1740_424e_8bef_980b22ad26c8.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/61d264c0_d2d4_40ea_94ce_844b3c8a6c81.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/beda36e4_7309_4197_9553_f0aa5abbe062.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/30a5b4db_ece5_4e6f_8585_e6a02fba76ca.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/f3bcf5a3_b9df_4479_be66_1f40a7a8fe9d.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10755/05bd0d6e_8a37_4f44_bb3f_068e4c7ee341.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/f4dc5abb_ccf2_4066_ae3c_d9db29cdf470.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/a3ab7409_e422_4d8a_b152_5ca6c0b5ce71.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/cb37ee95_c5c8_4a55_a865_0a9ef683a38b.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/3aaf6779_ea0f_466d_bc30_b1768ba3aef9.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/cda8a52d_0a69_4d53_990b_df6cc6f16254.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/4c4cc65b_d864_4d20_a026_4e11ea0502c9.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/54137d47_72fc_4feb_bc11_44bf46d95501.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/3f29ce11_c8b8_43b4_a090_bc0c40a201f2.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/af8f242f_cf41_45df_b84b_04c04764891d.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/d41feddf_bd8f_4858_96ce_6b312f610919.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/99bae038_9de2_4ee5_a82e_201d7350e49e.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/a7d4b7d7_4866_40f9_8eaa_39bb6f9ec314.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10822/6cb181bb_c60b_4e07_bef6_7d014715992f.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10639/aeeb8404_b290_4465_b743_676dd3d4debd.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10639/af3e82db_e892_40aa_bbd0_adc9c5b49bd5.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10639/0206d81a_7f04_402a_a811_a5f3c0037ee3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10639/77a5dca9_7cd3_44ae_885b_514c29be661b.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10639/8015c2e7_4bbe_422a_8ea6_be26f4e9a8a3.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10639/08642e2d_e4e8_438c_8509_fe6992b14dfd.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10639/ccdd33d5_4ec7_4b1f_b1a1_5f4919520fb1.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10639/2aedadef_760a_436d_9010_8bf0c830b812.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10639/ad8f3ba3_2991_4d3d_b014_0e79980ab87e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10639/86f23edb_85f1_4d0a_897f_fc7cefdb65ba.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10739/238d4468_6846_4144_9e58_70a45bdeff40.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10739/4a051a17_2411_4ff2_b3a7_d6da5c84ce15.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10739/5cc26b1d_600d_4acc_bc15_e5fa7d1cfff0.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10739/b6178e25_9406_4dfb_ad64_cdb1d54321b5.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10739/b3c0b08d_b662_4137_9908_44a56f3a6c02.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10739/98e75e28_85a7_45dc_adec_ccfed3ee72df.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10739/9f048894_cafe_446e_a954_a3cf00c5bfc7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10739/2293a77c_cfc1_4d67_ab70_5a4941e0f5e7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/602855/10739/d66f739e_6170_487a_96d0_5aa389760724.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Tiruchengode"
                      },
                      {
                         "City_Name": "Karur"
                      },
                      {
                         "City_Name": "Virudhunagar"
                      },
                      {
                         "City_Name": "Trichy"
                      },
                      {
                         "City_Name": "Madurai",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-4827",
                               "Franchisee_Name": "Kidzee Thirunagar Madurai",
                               "Operating_Status": "A",
                               "Address1": "9-9/5, meenakshi street",
                               "Address2": "thirunagar, 3rdstop",
                               "Place": "Thirunagar ,backside to SM mahal",
                               "City_Id": "1096",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4827@kidzee.com",
                               "Mobile_No": "9500781722",
                               "Phone_No1": "9500781722",
                               "Phone_No2": "",
                               "Latitude": "9.883387477743312",
                               "Longitude": "78.05221759507185"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5468",
                               "Franchisee_Name": "Kidzee Madurai",
                               "Operating_Status": "A",
                               "Address1": "Door no 5/,2nd Street,",
                               "Address2": "Koodalnagar",
                               "Place": "Anjal Nagar",
                               "City_Id": "1096",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5468@kidzee.com",
                               "Mobile_No": "9500749977",
                               "Phone_No1": "9500749977",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Neyyoor"
                      },
                      {
                         "City_Name": "Nagercoil"
                      },
                      {
                         "City_Name": "Kanyakumari",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-2684",
                               "Franchisee_Name": "Kidzee Azhagappapuram Kanyakumari",
                               "Operating_Status": "A",
                               "Address1": "5 /284 A / 290A,",
                               "Address2": "Guru Palace M.J.Nagar,",
                               "Place": "Azhagappapuram, Near to Church,",
                               "City_Id": "1100",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2684@kidzee.com",
                               "Mobile_No": "9865877886",
                               "Phone_No1": "04652266888",
                               "Phone_No2": "",
                               "Latitude": "8.146572",
                               "Longitude": "77.540446"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4262",
                               "Franchisee_Name": "Kidzee Pudukadai",
                               "Operating_Status": "A",
                               "Address1": "SY No.100/13, Pilanthoppu, Kaisoondi,",
                               "Address2": "Kanyakumari,",
                               "Place": "Pilanthoppu, Kaisoondi, Pudukadai",
                               "City_Id": "1100",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4262@kidzee.com",
                               "Mobile_No": "9999924293",
                               "Phone_No1": "04651-233293",
                               "Phone_No2": "",
                               "Latitude": "8.273149126196207",
                               "Longitude": "77.18193769454956",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184835/10817/9def922f_c2e9_4f19_a7dc_6899eb5e5578.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2184835/10817/d50055cf_9447_4bc8_85b1_70d293c1805f.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-C-4787",
                               "Franchisee_Name": "Kidzee Kappukadu",
                               "Operating_Status": "A",
                               "Address1": "Door No: 13/216,  Ground Floor",
                               "Address2": "Pinpathiri Vilai  ,Vellathurai Desom",
                               "Place": "Kappukadu Post ,Pinpathiri Vilai ,Vellathurai Deso",
                               "City_Id": "1100",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4787@kidzee.com",
                               "Mobile_No": "9999924293",
                               "Phone_No1": "4651-233293",
                               "Phone_No2": "",
                               "Latitude": "8.304373027438206",
                               "Longitude": "77.20479011535645"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4166",
                               "Franchisee_Name": "Kidzee Colachel",
                               "Operating_Status": "A",
                               "Address1": "Irumpily Junction, Ritapuram Vllage,",
                               "Address2": "Pannaviali Road, Kalkulam Tk,Irvi Puram,",
                               "Place": "Colachel, Irumpily Junction,",
                               "City_Id": "1100",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4166@kidzee.com",
                               "Mobile_No": "9443384941",
                               "Phone_No1": "9443326374",
                               "Phone_No2": "",
                               "Latitude": "8.184091",
                               "Longitude": "77.24938"
                            }
                         ]
                      },
                      {
                         "City_Name": "Namakkal"
                      },
                      {
                         "City_Name": "Dindigul",
                         "Franchisee": {
                            "Franchisee_Code": "S-S-C-1630",
                            "Franchisee_Name": "Kidzee - Dindigul",
                            "Operating_Status": "A",
                            "Address1": "OLD NO-H17/ NEW NO-22,",
                            "Address2": "",
                            "Place": "R.M.COLONY",
                            "City_Id": "1131",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1630@kidzee.com",
                            "Mobile_No": "9790087800",
                            "Phone_No1": "04512460149",
                            "Phone_No2": "",
                            "Latitude": "10.377212",
                            "Longitude": "77.973151"
                         }
                      },
                      {
                         "City_Name": "Pudukkottai",
                         "Franchisee": {
                            "Franchisee_Code": "S-S-C-1655",
                            "Franchisee_Name": "Kidzee - Pudukkottai",
                            "Operating_Status": "A",
                            "Address1": "NO 111-Rajagopalapuram,",
                            "Address2": "Near Valli Flats ",
                            "Place": "Rajagopalapuram ",
                            "City_Id": "1133",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1655@kidzee.com",
                            "Mobile_No": "9840539558",
                            "Phone_No1": "9442012120",
                            "Phone_No2": "",
                            "Latitude": "10.379663",
                            "Longitude": "78.820845"
                         }
                      },
                      {
                         "City_Name": "Palayamkotti"
                      },
                      {
                         "City_Name": "Tirunelveli / Nellai / Tinnevelly",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-4049",
                               "Franchisee_Name": "Kidzee Vadakkankulam",
                               "Operating_Status": "A",
                               "Address1": "12/393 &12/393 A to D,",
                               "Address2": "Perungudi Road,",
                               "Place": "Vadakankulam, Near Aawin Store,",
                               "City_Id": "1175",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4049@kidzee.com",
                               "Mobile_No": "9489492511",
                               "Phone_No1": "04637-230311",
                               "Phone_No2": "",
                               "Latitude": "8.2623788",
                               "Longitude": "77.66166249"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4698",
                               "Franchisee_Name": "Kidzee Tirunelveli",
                               "Operating_Status": "A",
                               "Address1": "19 N.G.O. A- Colony,",
                               "Address2": "North Main Road,",
                               "Place": "Palayamkottai ,Back Side of Rock Hospital",
                               "City_Id": "1175",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4698@kidzee.com",
                               "Mobile_No": "9655080201",
                               "Phone_No1": "0462-4953442",
                               "Phone_No2": "",
                               "Latitude": "8.70180",
                               "Longitude": "77.72959",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2722172/11051/d4b0c213_2c8c_4f9e_ae3d_6866ed0637e3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2722172/11051/887c1fcb_5010_4296_bed5_f51e443e2e73.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2722172/11051/980b4daa_bc0a_43bc_9b59_7ee503cf15bf.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2722172/11051/ff2a0144_f765_48f3_a285_19b683fd2a21.jpg"
                                  }
                               ]
                            }
                         ]
                      },
                      {
                         "City_Name": "Kanchipuram",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-2641",
                               "Franchisee_Name": "Kidzee Vidhai preschool Kancheepuram",
                               "Operating_Status": "A",
                               "Address1": "73/A, Pallikoodathan Street,",
                               "Address2": "M.M.Avenue, Nea- A.K.G. Marriage Mall,",
                               "Place": "Kanchipuram Main Road,",
                               "City_Id": "1176",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2641@kidzee.com",
                               "Mobile_No": "9791760603",
                               "Phone_No1": "9791760603",
                               "Phone_No2": "",
                               "Latitude": "12.834283",
                               "Longitude": "79.703547"
                            },
                            {
                               "Franchisee_Code": "S-R-C-2868",
                               "Franchisee_Name": "Kidzee Urapakkam",
                               "Operating_Status": "A",
                               "Address1": "No-55, Yamunai Street,",
                               "Address2": "Ram Nagar Extn,",
                               "Place": "Urapakkam, Near- Abdul Kalam Park",
                               "City_Id": "1176",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee2868@kidzee.com",
                               "Mobile_No": "9489232860",
                               "Phone_No1": "944-4523921",
                               "Phone_No2": "",
                               "Latitude": "12.869163939349956",
                               "Longitude": "80.06788727782123"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4067",
                               "Franchisee_Name": "Kidzee Mahindra World City",
                               "Operating_Status": "A",
                               "Address1": "Mahindra World City, Plot-18, Cosmo's Square, Anumandhai Village and Panchayat,",
                               "Address2": "Chengalpattu Taluk, Kancheepuram Dist-",
                               "Place": "Chengalpattu,",
                               "City_Id": "1176",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4067@kidzee.com",
                               "Mobile_No": "9788511233",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "12.75172",
                               "Longitude": "80.00982"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4815",
                               "Franchisee_Name": "Kidzee Thirupporur",
                               "Operating_Status": "A",
                               "Address1": "Door No.41,",
                               "Address2": "Thirporur Village,",
                               "Place": "Thiruprur Town Panchayat, Thiruvanchavadi Street,",
                               "City_Id": "1176",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4815@kidzee.com",
                               "Mobile_No": "9884846827",
                               "Phone_No1": "9884846827",
                               "Phone_No2": "",
                               "Latitude": "12.727866",
                               "Longitude": "80.190629"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4541",
                               "Franchisee_Name": "Kidzee Mamallan Nagar",
                               "Operating_Status": "A",
                               "Address1": "No-351, Bharadidasanat,",
                               "Address2": "Near-Mamallan Matriculation School,",
                               "Place": "Mamallan Nagar, Near Mamallan School,",
                               "City_Id": "1176",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4541@kidzee.com",
                               "Mobile_No": "9840609501",
                               "Phone_No1": "9840609501",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Karaikudi"
                      },
                      {
                         "City_Name": "Kumbakonam / Coombaconum"
                      },
                      {
                         "City_Name": "Thiruvannamalai",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-3864",
                               "Franchisee_Name": "Kidzee Tiruvannamalai",
                               "Operating_Status": "A",
                               "Address1": "Ground floor,No.4 A,",
                               "Address2": "6th Cross street,",
                               "Place": "Gandhi Nagar, Near vinayaga temple",
                               "City_Id": "1206",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3864@kidzee.com",
                               "Mobile_No": "9442798262",
                               "Phone_No1": "9442798262",
                               "Phone_No2": "",
                               "Latitude": "12.21642",
                               "Longitude": "79.06178",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/21e12d6e_c299_4441_af63_279d41be5ed4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/114d2378_e608_4028_b913_b9ec5a6c3f5b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/a85f321d_8e0b_4946_900c_bc2ea5967812.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/3d41e1b3_f509_4600_b969_a1039f513bca.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/dff71ca8_c746_40eb_91f2_31cc48168aa4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/688108b5_6732_4e49_a42a_f6db73528689.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/b9243773_6a64_47e3_b8d0_a4d00cb6c3bc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/51ec458e_1a59_4928_b562_47bef85572c3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/7deb6b04_d313_49d2_bc7e_f87eccb7c02b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/854eabd9_1c5d_4a47_a642_e47c3cbe0186.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/f75a2f34_a1c6_4e12_96bc_8e30bc1fcabe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/8e49b8f9_a935_4584_934c_168781862938.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/cc4b6d42_f5e1_4fa3_b9ea_b2ab985627d5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/5beb1f35_8ee0_4289_8e08_7f26d814e053.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/6eb21b82_e693_44cf_9f1b_4a246ac3d44b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/1a7cd1ec_9531_4f4a_bba6_33305441f511.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/c5a36806_4a62_4c2d_b675_3722df780792.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/9f3c0a53_b070_423b_82c4_ef910a795d18.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/8c512dda_cac7_49a9_b896_55ed5d06ab64.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/aec83244_2d83_4ec1_a159_30fc9104b501.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/de29acb9_65e2_4031_994c_cb3ec248f74f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/c18ed913_64cd_4291_a9a8_06cf6e380192.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/4cf5487b_373e_4e31_a1dc_e073213e6caf.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/5284dd0b_701f_40e2_87b3_37a62bf95724.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/2c45614e_6e2e_4c7c_a3ad_5911ae41bb0d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/15806c51_adaa_484b_96a7_9b74e31bbe17.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/ed2c5c26_dea4_46de_9050_8ff5a08d3405.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/eb46c0ac_0954_4841_8b23_dd8e27dc5339.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/617d37f9_f9f4_4d31_bb27_b264f9a465aa.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/4e9332c1_c21f_492c_88c9_975e07d37f8b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/8ace7281_f476_41b9_9c1f_e4ae1570cec0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/cf796fab_ccca_4e9d_9928_2b8df15ca24e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/07cf6530_4daf_432f_b3ee_e61218e4f94f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/dea63dba_d1eb_44bb_a68c_06326cffa457.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/a5391ddd_75d7_46ed_b8b5_2789b8832687.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/fb178c82_a3a4_4aad_9e62_ea3b85335d47.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/9b635ebc_6b42_4c94_a31a_14e7453d75f2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/0cd2e4fd_5c35_4b8f_ba3e_ffad8bf8b399.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/8e135f0b_6fac_4806_a24c_8a663aac68d3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/399b9384_6516_4422_b9f5_5f489881b843.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/c775c6fc_5ac4_4d6b_b929_1110fd5330ba.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/e58f7295_c58e_45ec_855b_1998b91fbc1a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/16118e9b_dc25_4ffc_b22b_a1f88b07e0e7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/a7f79fd9_c23c_462a_8517_70485c2877ff.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2213320/10864/8930b810_cac7_4b56_8dae_6f2fef983037.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-C-5279",
                               "Franchisee_Name": "Kidzee Tiruvannamalai",
                               "Operating_Status": "A",
                               "Address1": "Thiruvalluvar Educational Trust",
                               "Address2": "Thenpallipattu Village",
                               "Place": "Kalasapakkam ,Thenpallipattu Village",
                               "City_Id": "1206",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5279@kidzee.com",
                               "Mobile_No": "9994979968",
                               "Phone_No1": "9994979968",
                               "Phone_No2": "",
                               "Latitude": "12.21642",
                               "Longitude": "79.06178"
                            }
                         ]
                      },
                      {
                         "City_Name": "Vellore",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-S-C-2180",
                               "Franchisee_Name": "Kidzee Katpadi Vellore Chennai",
                               "Operating_Status": "A",
                               "Address1": "No 30 MARRIAMMAN KOIL STREET",
                               "Address2": "SENGUTAI",
                               "Place": "Katpadi ,vellore",
                               "City_Id": "1207",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2180@kidzee.com",
                               "Mobile_No": "9367611169",
                               "Phone_No1": "9367611169",
                               "Phone_No2": "",
                               "Latitude": "12.98012928048833",
                               "Longitude": "79.13776230555732"
                            },
                            {
                               "Franchisee_Code": "S-L-C-2224",
                               "Franchisee_Name": "Kidzee  Jolarpet Vellore",
                               "Operating_Status": "A",
                               "Address1": "No.237, Junction Bus Stand,",
                               "Address2": "Jolarpet,",
                               "Place": "Near-Jolarpet Bus Stop,",
                               "City_Id": "1207",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2224@kidzee.com",
                               "Mobile_No": "9600325200",
                               "Phone_No1": "9894200692",
                               "Phone_No2": "",
                               "Latitude": "12.560507",
                               "Longitude": "78.578671"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4160",
                               "Franchisee_Name": "Kidzee Gudiyattam",
                               "Operating_Status": "A",
                               "Address1": "Old TNEB EE Office, 1st Floor, 53,",
                               "Address2": "V.O.C.Street, Nadupet,",
                               "Place": "Gudiyatham,Old- TNEB EE Office,",
                               "City_Id": "1207",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4160@kidzee.com",
                               "Mobile_No": "8754424246",
                               "Phone_No1": "04171-228810",
                               "Phone_No2": "",
                               "Latitude": "12.943840671891428",
                               "Longitude": "78.86834843558199"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5130",
                               "Franchisee_Name": "Kidzee Tirupattur",
                               "Operating_Status": "A",
                               "Address1": "no 3 bharathi road",
                               "Address2": "Ground Floor",
                               "Place": "tirupattur ,bharathi road",
                               "City_Id": "1207",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5130@kidzee.com",
                               "Mobile_No": "9843799416",
                               "Phone_No1": "4179-228122",
                               "Phone_No2": "",
                               "Latitude": "12.490770",
                               "Longitude": "78.570160"
                            }
                         ]
                      },
                      {
                         "City_Name": "Ammainaickanur"
                      },
                      {
                         "City_Name": "Chinnalapatti"
                      },
                      {
                         "City_Name": "Dharapuram / Rajarajapuram"
                      },
                      {
                         "City_Name": "Edappadi / Idappadi / Edapadi"
                      },
                      {
                         "City_Name": "Gobi"
                      },
                      {
                         "City_Name": "Kallakurichi"
                      },
                      {
                         "City_Name": "Kalpakkam",
                         "Franchisee": {
                            "Franchisee_Code": "S-S-C-1958",
                            "Franchisee_Name": "Kidzee Kalpakkam",
                            "Operating_Status": "A",
                            "Address1": "No-514, Rice Mill Street,",
                            "Address2": "",
                            "Place": "R.M.I Nagar, Pudupatinam,",
                            "City_Id": "1298",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1958@kidzee.com",
                            "Mobile_No": "9994291707",
                            "Phone_No1": "04427487717",
                            "Phone_No2": "",
                            "Latitude": "12.523812",
                            "Longitude": "80.156813"
                         }
                      },
                      {
                         "City_Name": "Lalgudi"
                      },
                      {
                         "City_Name": "Paramathi"
                      },
                      {
                         "City_Name": "Puzhal"
                      },
                      {
                         "City_Name": "Sembakkam"
                      },
                      {
                         "City_Name": "Mettupalayam"
                      },
                      {
                         "City_Name": "Cuddalore",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-4999",
                               "Franchisee_Name": "Kidzee Virudhachalam",
                               "Operating_Status": "A",
                               "Address1": "No.5, Kamal Basha Street,",
                               "Address2": "VNR Nagar,",
                               "Place": "Virudhachalam, Near-Fathima Matric School,",
                               "City_Id": "1315",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4999@kidzee.com",
                               "Mobile_No": "9786822123",
                               "Phone_No1": "04143-355191",
                               "Phone_No2": "",
                               "Latitude": "11.518928363721649",
                               "Longitude": "79.34119010791775",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2702461/11048/a92e0efc_d60a_48f0_b43b_deb67f34eaa4.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2702461/11048/90ca8524_503c_433d_8215_f1fd3248f8c6.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-C-5165",
                               "Franchisee_Name": "Kidzee Pennadam",
                               "Operating_Status": "A",
                               "Address1": "Tittakudi Taluka, Pennadam town, Door no 67",
                               "Address2": "Kamaraja street",
                               "Place": "Pennadam Town",
                               "City_Id": "1315",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5165@kidzee.com",
                               "Mobile_No": "9940151129",
                               "Phone_No1": "9940151129",
                               "Phone_No2": "",
                               "Latitude": "11.33862",
                               "Longitude": "79.21204"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4191",
                               "Franchisee_Name": "Kidzee Kattumannarkoil",
                               "Operating_Status": "A",
                               "Address1": "10, Valayalkara Street,",
                               "Address2": "Cuddalore-District,",
                               "Place": "Kattumannarkoil, Near Kalaimagal HR Sec School,",
                               "City_Id": "1315",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4191@kidzee.com",
                               "Mobile_No": "9842415843",
                               "Phone_No1": "9842415843",
                               "Phone_No2": "",
                               "Latitude": "11.27483",
                               "Longitude": "79.54878"
                            }
                         ]
                      },
                      {
                         "City_Name": "Thiruverkadu / Tiruverkadu"
                      },
                      {
                         "City_Name": "Krishnagiri"
                      },
                      {
                         "City_Name": "Korattur",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2194",
                            "Franchisee_Name": "Kidzee Korattur Chennai",
                            "Operating_Status": "A",
                            "Address1": "No 10, Shanthi Nagar",
                            "Address2": "Korattur",
                            "Place": "Korattur ,Shanthi Nagar",
                            "City_Id": "1352",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2194@kidzee.com",
                            "Mobile_No": "9841317604",
                            "Phone_No1": "9841317604",
                            "Phone_No2": "",
                            "Latitude": "13.120885",
                            "Longitude": "80.221675"
                         }
                      },
                      {
                         "City_Name": "Uthamapalayam",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2322",
                            "Franchisee_Name": "Kidzee Uthamapalayam Tamil Nadu",
                            "Operating_Status": "A",
                            "Address1": "Door No 6-1-62 /8, Uthamapalyam Town,",
                            "Address2": "Katchey Road, Uthamapalayam, Bye Pass Junction",
                            "Place": "Katchey Road, Bye Pass Junction",
                            "City_Id": "1354",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2322@kidzee.com",
                            "Mobile_No": "9489265558",
                            "Phone_No1": "04554267001",
                            "Phone_No2": "",
                            "Latitude": "9.808606",
                            "Longitude": "77.328135"
                         }
                      },
                      {
                         "City_Name": "Tindivanam"
                      },
                      {
                         "City_Name": "Somanur"
                      },
                      {
                         "City_Name": "Tuticorin"
                      },
                      {
                         "City_Name": "Niligiris"
                      },
                      {
                         "City_Name": "Kangayam"
                      },
                      {
                         "City_Name": "Perundurai"
                      },
                      {
                         "City_Name": "Rajapalayam"
                      },
                      {
                         "City_Name": "Tiruvallur",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4495",
                            "Franchisee_Name": "Kidzee Thiruninravur",
                            "Operating_Status": "A",
                            "Address1": "No-3, 2nd Cross street,",
                            "Address2": "Krishnapuram,",
                            "Place": "Thiruninravur, Near Canara Bank,",
                            "City_Id": "1491",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4495@kidzee.com",
                            "Mobile_No": "9940521234",
                            "Phone_No1": "9940-272079",
                            "Phone_No2": "",
                            "Latitude": "13.12566",
                            "Longitude": "80.02905",
                            "Images": {
                               "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2234030/10869/f4000f75_552e_4a70_84a2_a46a92ac4ecb.jpg"
                            }
                         }
                      },
                      {
                         "City_Name": "Nagapatinam",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-3177",
                               "Franchisee_Name": "Kidzee - Kamambadi , Nagappattinam",
                               "Operating_Status": "A",
                               "Address1": "5/802, at  Bungalow Club Road,",
                               "Address2": "New Beach Road,",
                               "Place": "Kamambadi,  Nagappattinam, New Beach Road",
                               "City_Id": "1518",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3177@kidzee.com",
                               "Mobile_No": "8903437903",
                               "Phone_No1": "04365-247901",
                               "Phone_No2": "",
                               "Latitude": "10.78566599404439",
                               "Longitude": "79.84350805678275",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10785/e8272c07_2f00_4e6c_8fb7_57d3eac6f559.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10785/1637f3b7_cf25_4a98_a9b1_8600913926fd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10786/4c7b07ce_5db0_41e5_88da_28e61a514b05.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10786/03ec3c20_8690_4733_91a5_2aea22f66c4c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10786/674dc335_ffaa_45e3_b5d7_de2eef507ee6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10790/c345187e_5d93_4ad0_b45a_5ff8b2965b9d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10790/c98d1eb9_615e_432e_9ec3_22b38773f16f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10786/2fffa050_8e23_4c15_bb7f_c3fe7f2b3c43.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10784/c5dde5fd_6bf5_4102_bf93_ea8816cd7ed3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10784/ad9d8409_f764_417f_a057_a68598c005a6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10791/7ad7994f_003e_442e_ab9a_1c4d3b7d0a61.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10791/e3e51389_2a21_4516_b8ac_02653290aa14.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10791/63c4c560_d44c_4861_bbe9_771fbc74ab11.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10793/98cc1830_13c9_4553_a5ed_7ef0c831d7be.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10793/bc46dbad_4e59_44d7_9d73_dc2a9cb9d1f8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10793/2206e016_7854_4683_99ba_835d30fa552e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10793/3922a644_3981_4031_bffd_782fe9c69e49.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10793/5223f3a8_b9f4_4b89_a42c_ccc8a5222e7b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10794/65103eac_74ea_452a_a701_926335965aa8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10794/b5bac689_01fb_4b5a_b97c_11d03ca6824a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10794/9cd86c46_6e5d_49ec_b817_e565b6558ad6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10794/d5e18a3b_3428_43cc_b36f_38f4b1f409c8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10794/38c57e76_c66a_421e_a03a_b9f60fba9896.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10797/655024fe_34cc_4161_b0a0_8bd7928aa206.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10797/73f98b38_c2ed_4f27_afee_f3acd6e5df45.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10797/a8083245_1967_481b_b5a7_78bfb73c758c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10802/e9a5c051_3dd5_4570_a8c7_e96e0aa0efd1.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10802/f61515f8_a17c_4830_919a_08c3dbd59bfb.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10802/7a83bbe5_5b1d_40c6_9d43_99d6d73f3527.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10802/576e80a0_bb3e_4f45_89c0_ec347217d278.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/cabfe37b_73be_4314_afaa_5b7649680fde.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/4d8897ea_35f7_431d_af58_0261d8bb2a33.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/22f71763_957d_4200_89b2_a75ef8c84aca.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/e65b0041_6d0f_4d21_bd07_5d2a043205e5.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/3fd8d3db_5b46_4192_b704_ef9dfb15a268.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/24440eec_da05_4bc6_a94b_5559966a7bb9.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/80d35cb6_462c_49c8_a7f7_af69cc508b6d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10808/8c33f2fa_da45_43a1_8216_b7fa47ec107f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10808/833a1ed2_2174_4f9a_85b3_5711d4bf565a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10808/d4a5ecd4_f7db_48f2_9bde_bd45d0b0ead9.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10812/392adf80_7927_4083_ae81_ff3b342c8d50.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10812/9d1b59e6_5352_4905_92d0_88ff5b978c6d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10812/d10b96bf_aeab_411c_a091_bb88e96f30f1.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10812/fc44c81e_c545_444a_89b0_bc4270aa69d1.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10813/9036fe94_9213_49af_ae2c_2547b06d551d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10813/7e564d64_11e8_45e6_a5e9_981c6f77819e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10813/419c5279_1e4a_41b1_b751_e579f223c2cd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10818/734e4630_09e0_437d_bf90_1bc39e12ab6c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10818/06ad8832_a4ea_4f65_82a0_e3a2a56c8607.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10818/6978d53e_87c5_4d0a_8e17_f4d3864cea98.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10818/9ab5c739_3e80_40e6_8a59_29650a2d5a59.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10818/a42ae812_7d5a_4115_9cba_9d83dde6804c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10819/f6ba58d7_f231_4586_89dc_79ad4f29f587.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10819/6c121b2a_d31e_4a30_9859_2bf634fd26de.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10819/d70c9c4e_99df_42d0_8071_48fbfa63c4cc.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10819/4f241a9f_4992_44c9_8bb3_3784bb6efd9a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10819/f4bce92f_d557_4000_87d8_516a3f06d4e6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10820/56f0af6c_5ef7_46d2_b032_28a1a20400b6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10820/bbd5def4_e78a_4242_be7f_a382510d66f0.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10820/4cd1ff65_2fee_4308_a338_37889e236f2c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10820/42e5f4eb_3f2f_4755_9c10_26472bcbbf90.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10820/2960fcba_ce49_4400_a0cc_4a9fb2e5b85a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10829/0196b12a_4e15_49c5_a8d3_784a5cea7a8e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10829/f1505279_e953_4949_a36c_049e5975ba95.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10829/8c494486_8f09_458e_8351_bc91dc8cf017.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10826/ce00a054_5fff_47e4_804d_82e065303477.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10826/849c9667_8c92_424d_82c7_5fb6a9ed2a61.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10826/3c6fdb9f_4e8b_4ec7_9512_032606f0481f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10835/e51de2e7_4e0c_4e37_8cba_63cc3ceb8693.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10835/34ef8e4d_355a_40a0_90d6_e596e1ab3361.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10835/48380cd4_b5ac_431a_afe9_fa0b565d3d00.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10837/3e7da80e_0d15_44e8_a909_4fd4ab6dc8e4.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10837/811b696f_15fd_4150_af81_d504c385992c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10837/25202b38_5313_42bc_ac9d_fd1ef1bce370.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10838/49304c11_2725_48d7_be63_9e39b0ee13c2.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10838/9293a28f_64fa_47c7_964b_abf42136e96d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10838/f07880a4_4f02_4e79_b334_b2acd887b731.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10838/685a601f_b2f2_46d5_a63f_489b40f5b7e8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10839/4bf19d81_fd4e_48ca_a0f4_0e6333ba662b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10839/7ff7be5b_9e94_4c26_98aa_7ff94c5ba1e7.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10839/9a084b87_b1d3_4766_949b_17022bbe988f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10840/8d35f437_7594_42d1_8a51_7c4916a048dd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10840/2d389fd6_3ead_4c75_9c0b_4463a4156b36.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10840/29caf693_4ac4_4e0c_a317_c8d386e49268.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10840/63f57e13_cfff_43ac_ac72_d8e2963fba42.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10840/aa3138e3_3e74_42a2_a9e3_118bf1c5a7fe.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10842/48f23104_5c23_4a39_82bc_b746b391f93d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10843/69924812_3dfd_4d34_957b_be08f9b3ab76.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10843/8643ea10_2273_470a_93d8_42f28d5eba71.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10843/368f7d3b_9110_45b1_bed5_fe80076dcf47.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10843/17b4a5ca_a13d_4cc5_bfd7_26c4153d79ea.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10845/c4f8b9f1_9717_4b01_ba79_25283a41b1e6.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-C-3177",
                               "Franchisee_Name": "Kidzee - Kamambadi , Nagappattinam",
                               "Operating_Status": "A",
                               "Address1": "5/802, at  Bungalow Club Road,",
                               "Address2": "New Beach Road,",
                               "Place": "Kamambadi,  Nagappattinam, New Beach Road",
                               "City_Id": "1518",
                               "State_Id": "33",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3177@kidzee.com",
                               "Mobile_No": "8903437903",
                               "Phone_No1": "04365-247901",
                               "Phone_No2": "",
                               "Latitude": "10.7855",
                               "Longitude": "79.845224",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10785/e8272c07_2f00_4e6c_8fb7_57d3eac6f559.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10785/1637f3b7_cf25_4a98_a9b1_8600913926fd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10786/4c7b07ce_5db0_41e5_88da_28e61a514b05.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10786/03ec3c20_8690_4733_91a5_2aea22f66c4c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10786/674dc335_ffaa_45e3_b5d7_de2eef507ee6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10790/c345187e_5d93_4ad0_b45a_5ff8b2965b9d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10790/c98d1eb9_615e_432e_9ec3_22b38773f16f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10786/2fffa050_8e23_4c15_bb7f_c3fe7f2b3c43.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10784/c5dde5fd_6bf5_4102_bf93_ea8816cd7ed3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10784/ad9d8409_f764_417f_a057_a68598c005a6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10791/7ad7994f_003e_442e_ab9a_1c4d3b7d0a61.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10791/e3e51389_2a21_4516_b8ac_02653290aa14.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10791/63c4c560_d44c_4861_bbe9_771fbc74ab11.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10793/98cc1830_13c9_4553_a5ed_7ef0c831d7be.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10793/bc46dbad_4e59_44d7_9d73_dc2a9cb9d1f8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10793/2206e016_7854_4683_99ba_835d30fa552e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10793/3922a644_3981_4031_bffd_782fe9c69e49.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10793/5223f3a8_b9f4_4b89_a42c_ccc8a5222e7b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10794/65103eac_74ea_452a_a701_926335965aa8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10794/b5bac689_01fb_4b5a_b97c_11d03ca6824a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10794/9cd86c46_6e5d_49ec_b817_e565b6558ad6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10794/d5e18a3b_3428_43cc_b36f_38f4b1f409c8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10794/38c57e76_c66a_421e_a03a_b9f60fba9896.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10797/655024fe_34cc_4161_b0a0_8bd7928aa206.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10797/73f98b38_c2ed_4f27_afee_f3acd6e5df45.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10797/a8083245_1967_481b_b5a7_78bfb73c758c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10802/e9a5c051_3dd5_4570_a8c7_e96e0aa0efd1.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10802/f61515f8_a17c_4830_919a_08c3dbd59bfb.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10802/7a83bbe5_5b1d_40c6_9d43_99d6d73f3527.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10802/576e80a0_bb3e_4f45_89c0_ec347217d278.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/cabfe37b_73be_4314_afaa_5b7649680fde.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/4d8897ea_35f7_431d_af58_0261d8bb2a33.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/22f71763_957d_4200_89b2_a75ef8c84aca.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/e65b0041_6d0f_4d21_bd07_5d2a043205e5.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/3fd8d3db_5b46_4192_b704_ef9dfb15a268.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/24440eec_da05_4bc6_a94b_5559966a7bb9.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10803/80d35cb6_462c_49c8_a7f7_af69cc508b6d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10808/8c33f2fa_da45_43a1_8216_b7fa47ec107f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10808/833a1ed2_2174_4f9a_85b3_5711d4bf565a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10808/d4a5ecd4_f7db_48f2_9bde_bd45d0b0ead9.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10812/392adf80_7927_4083_ae81_ff3b342c8d50.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10812/9d1b59e6_5352_4905_92d0_88ff5b978c6d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10812/d10b96bf_aeab_411c_a091_bb88e96f30f1.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10812/fc44c81e_c545_444a_89b0_bc4270aa69d1.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10813/9036fe94_9213_49af_ae2c_2547b06d551d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10813/7e564d64_11e8_45e6_a5e9_981c6f77819e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10813/419c5279_1e4a_41b1_b751_e579f223c2cd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10818/734e4630_09e0_437d_bf90_1bc39e12ab6c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10818/06ad8832_a4ea_4f65_82a0_e3a2a56c8607.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10818/6978d53e_87c5_4d0a_8e17_f4d3864cea98.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10818/9ab5c739_3e80_40e6_8a59_29650a2d5a59.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10818/a42ae812_7d5a_4115_9cba_9d83dde6804c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10819/f6ba58d7_f231_4586_89dc_79ad4f29f587.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10819/6c121b2a_d31e_4a30_9859_2bf634fd26de.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10819/d70c9c4e_99df_42d0_8071_48fbfa63c4cc.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10819/4f241a9f_4992_44c9_8bb3_3784bb6efd9a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10819/f4bce92f_d557_4000_87d8_516a3f06d4e6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10820/56f0af6c_5ef7_46d2_b032_28a1a20400b6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10820/bbd5def4_e78a_4242_be7f_a382510d66f0.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10820/4cd1ff65_2fee_4308_a338_37889e236f2c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10820/42e5f4eb_3f2f_4755_9c10_26472bcbbf90.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10820/2960fcba_ce49_4400_a0cc_4a9fb2e5b85a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10829/0196b12a_4e15_49c5_a8d3_784a5cea7a8e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10829/f1505279_e953_4949_a36c_049e5975ba95.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10829/8c494486_8f09_458e_8351_bc91dc8cf017.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10826/ce00a054_5fff_47e4_804d_82e065303477.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10826/849c9667_8c92_424d_82c7_5fb6a9ed2a61.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10826/3c6fdb9f_4e8b_4ec7_9512_032606f0481f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10835/e51de2e7_4e0c_4e37_8cba_63cc3ceb8693.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10835/34ef8e4d_355a_40a0_90d6_e596e1ab3361.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10835/48380cd4_b5ac_431a_afe9_fa0b565d3d00.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10837/3e7da80e_0d15_44e8_a909_4fd4ab6dc8e4.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10837/811b696f_15fd_4150_af81_d504c385992c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10837/25202b38_5313_42bc_ac9d_fd1ef1bce370.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10838/49304c11_2725_48d7_be63_9e39b0ee13c2.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10838/9293a28f_64fa_47c7_964b_abf42136e96d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10838/f07880a4_4f02_4e79_b334_b2acd887b731.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10838/685a601f_b2f2_46d5_a63f_489b40f5b7e8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10839/4bf19d81_fd4e_48ca_a0f4_0e6333ba662b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10839/7ff7be5b_9e94_4c26_98aa_7ff94c5ba1e7.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10839/9a084b87_b1d3_4766_949b_17022bbe988f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10840/8d35f437_7594_42d1_8a51_7c4916a048dd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10840/2d389fd6_3ead_4c75_9c0b_4463a4156b36.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10840/29caf693_4ac4_4e0c_a317_c8d386e49268.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10840/63f57e13_cfff_43ac_ac72_d8e2963fba42.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10840/aa3138e3_3e74_42a2_a9e3_118bf1c5a7fe.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10842/48f23104_5c23_4a39_82bc_b746b391f93d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10843/69924812_3dfd_4d34_957b_be08f9b3ab76.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10843/8643ea10_2273_470a_93d8_42f28d5eba71.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10843/368f7d3b_9110_45b1_bed5_fe80076dcf47.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10843/17b4a5ca_a13d_4cc5_bfd7_26c4153d79ea.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1303389/10845/c4f8b9f1_9717_4b01_ba79_25283a41b1e6.jpg"
                                  }
                               ]
                            }
                         ]
                      },
                      {
                         "City_Name": "Mayiladuthurai"
                      },
                      {
                         "City_Name": "Karuvazhakarai"
                      },
                      {
                         "City_Name": "Paramakudi",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2865",
                            "Franchisee_Name": "Kidzee Lotus Educational and Social Welfare Trust-Paramakudi",
                            "Operating_Status": "A",
                            "Address1": "2/486 -E, Vasanthapuram,",
                            "Address2": "Madurai - Rameswaram Main Road,",
                            "Place": "Paramakudi, Near, Bus Stop",
                            "City_Id": "1526",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2865@kidzee.com",
                            "Mobile_No": "9944174736",
                            "Phone_No1": "9944174736",
                            "Phone_No2": "",
                            "Latitude": "9.548538",
                            "Longitude": "78.585602"
                         }
                      },
                      {
                         "City_Name": "Ramanathapuram",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4856",
                            "Franchisee_Name": "Kidzee Pattinamkhathan",
                            "Operating_Status": "A",
                            "Address1": "1/1227 Om sakthi Nagar",
                            "Address2": "2nd street, Collectorate post ",
                            "Place": "Pattinamkhathan ,Om sakthi Nagar",
                            "City_Id": "1527",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee4856@kidzee.com",
                            "Mobile_No": "9176826567",
                            "Phone_No1": "9176826567",
                            "Phone_No2": "",
                            "Latitude": "9.45078",
                            "Longitude": "78.84313"
                         }
                      },
                      {
                         "City_Name": "Villupuram"
                      },
                      {
                         "City_Name": "Sriviliputtur"
                      },
                      {
                         "City_Name": "Mathandam",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2967",
                            "Franchisee_Name": "Kidzee K.J.S. Educational Charitable Trust - Marthandam",
                            "Operating_Status": "A",
                            "Address1": "Door Number -27/17-B,",
                            "Address2": "Snclair Street,",
                            "Place": "Marthandam -Kanyakumari Dist-Near LMS Girls High S",
                            "City_Id": "1552",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2967@kidzee.com",
                            "Mobile_No": "9976581681",
                            "Phone_No1": "04651271681",
                            "Phone_No2": "",
                            "Latitude": "8.307531",
                            "Longitude": "77.221836"
                         }
                      },
                      {
                         "City_Name": "Theni",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2971",
                            "Franchisee_Name": "Kidzee Theni  TN",
                            "Operating_Status": "A",
                            "Address1": "29- Jawahar Main Road,",
                            "Address2": "NRT Nagar,",
                            "Place": "Theni,",
                            "City_Id": "1553",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2971@kidzee.com",
                            "Mobile_No": "9025656518",
                            "Phone_No1": "04546-262518",
                            "Phone_No2": "",
                            "Latitude": "10.013821732549891",
                            "Longitude": "77.48687270608139",
                            "Images": [
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1227136/10459/47bf8ee0_4279_445b_8288_a45a73ac2626.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1227136/10459/ded9bbb9_ce2c_4edb_a9f3_1d81f9bd28f7.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Cumbum"
                      },
                      {
                         "City_Name": "Periyakulam"
                      },
                      {
                         "City_Name": "Manarkudi"
                      },
                      {
                         "City_Name": "Perbalur"
                      },
                      {
                         "City_Name": "Kodaikanal",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-3008",
                            "Franchisee_Name": "Kidzee Kodaikanal Tamilnadu",
                            "Operating_Status": "A",
                            "Address1": "25/185, Anandhagiri,",
                            "Address2": "3rd street, ",
                            "Place": "Anandhagiri,",
                            "City_Id": "1558",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3008@kidzee.com",
                            "Mobile_No": "9842348732",
                            "Phone_No1": "9842348732",
                            "Phone_No2": "",
                            "Latitude": "10.2395031919537",
                            "Longitude": "77.5000691413879",
                            "Images": {
                               "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3008/268/8a3dbbe9_e158_483c_afe3_75e6cfbd8755.jpg"
                            }
                         }
                      },
                      {
                         "City_Name": "Ramanathapuram"
                      },
                      {
                         "City_Name": "Kollachel"
                      },
                      {
                         "City_Name": "Kollencode",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-3279",
                            "Franchisee_Name": "Kidzee Kirathoor",
                            "Operating_Status": "A",
                            "Address1": "J.D.A Nikethan, Joshua Street,",
                            "Address2": "Kanyakumari District,",
                            "Place": "Kirathoor, Near to church",
                            "City_Id": "1583",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3279@kidzee.com",
                            "Mobile_No": "9884622211",
                            "Phone_No1": "04651241069",
                            "Phone_No2": "",
                            "Latitude": "8.28452096210736",
                            "Longitude": "77.130326628685"
                         }
                      },
                      {
                         "City_Name": "Kovilpatti"
                      },
                      {
                         "City_Name": "Kalakad"
                      },
                      {
                         "City_Name": "Palani"
                      },
                      {
                         "City_Name": "Aruppukkottai"
                      },
                      {
                         "City_Name": "Perambalur"
                      },
                      {
                         "City_Name": "Oddanchatram"
                      },
                      {
                         "City_Name": "Sivagangai"
                      },
                      {
                         "City_Name": "Vadakankulam "
                      },
                      {
                         "City_Name": "Mandaikadu"
                      },
                      {
                         "City_Name": "KARUPPUR",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-4030",
                            "Franchisee_Name": "Kidzee Karuppur",
                            "Operating_Status": "A",
                            "Address1": "Sri Jhothi Vidyalaya Hi Tech Mat Hr Sec School,",
                            "Address2": "Omalur,",
                            "Place": "Salem, Salem Temple,",
                            "City_Id": "1838",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4030@kidzee.com",
                            "Mobile_No": "9894579100",
                            "Phone_No1": "",
                            "Phone_No2": "",
                            "Latitude": "11.71500",
                            "Longitude": "78.09332"
                         }
                      },
                      {
                         "City_Name": "Attur"
                      },
                      {
                         "City_Name": "Vallioor",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-3977",
                            "Franchisee_Name": "Kidzee Vallioor",
                            "Operating_Status": "A",
                            "Address1": "32(O) G , TB Road",
                            "Address2": "Near Vasanth & Co Building,Tirunelveli Dist",
                            "Place": "Vallioor ,near to court",
                            "City_Id": "1894",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3977@kidzee.com",
                            "Mobile_No": "9840288757",
                            "Phone_No1": "9840288757",
                            "Phone_No2": "",
                            "Latitude": "8.3777",
                            "Longitude": "77.6085"
                         }
                      },
                      {
                         "City_Name": "Panruti",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-5001",
                            "Franchisee_Name": "Kidzee Panruti",
                            "Operating_Status": "A",
                            "Address1": "1222 / J , Bharathiyar Street, Ramasamy Nagar",
                            "Address2": "Lakshminarayanapuram, ",
                            "Place": "Panruti ,near park",
                            "City_Id": "1964",
                            "State_Id": "33",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5001@kidzee.com",
                            "Mobile_No": "9944775426",
                            "Phone_No1": "8500673724",
                            "Phone_No2": "",
                            "Latitude": "11.77826",
                            "Longitude": "79.55838"
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Tripura",
                   "City": [
                      {
                         "City_Name": "Agartala",
                         "Franchisee": {
                            "Franchisee_Code": "E-U-U-462",
                            "Franchisee_Name": "Kidzee- Agartala- Tripura- LITTLE STAR",
                            "Operating_Status": "A",
                            "Address1": "Mira Vill, Bhattapukur,",
                            "Address2": "",
                            "Place": "Near-Modern Club",
                            "City_Id": "35",
                            "State_Id": "34",
                            "Country_Id": "1",
                            "Email_Id": "kidzee462@kidzee.com",
                            "Mobile_No": "8837486102",
                            "Phone_No1": "9774665165",
                            "Phone_No2": "",
                            "Latitude": "23.831457",
                            "Longitude": "91.286778"
                         }
                      },
                      {
                         "City_Name": "Udaipur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2682",
                            "Franchisee_Name": "Kidzee Udaipur",
                            "Operating_Status": "A",
                            "Address1": "South Bank Of Jaganath Dighi,",
                            "Address2": "Gomati Tripura,",
                            "Place": "Udaipur, Rabindra Sangha,",
                            "City_Id": "1485",
                            "State_Id": "34",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2682@kidzee.com",
                            "Mobile_No": "9774187273",
                            "Phone_No1": "",
                            "Phone_No2": "",
                            "Latitude": "23.5397654",
                            "Longitude": "91.4895896"
                         }
                      },
                      {
                         "City_Name": "Dharmanagar",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2791",
                            "Franchisee_Name": "KIDZEE GURUKULAM DHARMANAGAR",
                            "Operating_Status": "A",
                            "Address1": "Madhubari Road,",
                            "Address2": "Padmapur,",
                            "Place": "Dharmanagar, North Tripura, Satsang Ashram,",
                            "City_Id": "1504",
                            "State_Id": "34",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2791@kidzee.com",
                            "Mobile_No": "8974736840",
                            "Phone_No1": "8974736840",
                            "Phone_No2": "",
                            "Latitude": "24.371551",
                            "Longitude": "92.157401"
                         }
                      },
                      {
                         "City_Name": "Khowai",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3201",
                            "Franchisee_Name": "Kidzee Khowai Tripura",
                            "Operating_Status": "A",
                            "Address1": "Subhash Park, Lalchara,",
                            "Address2": "Near Sonar College Road Tori,",
                            "Place": "Khowai, Sonar Tori",
                            "City_Id": "1581",
                            "State_Id": "34",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3201@kidzee.com",
                            "Mobile_No": "9436492234",
                            "Phone_No1": "-",
                            "Phone_No2": "",
                            "Latitude": "24.06718",
                            "Longitude": "91.605672"
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Uttar Pradesh",
                   "City": [
                      {
                         "City_Name": "Agra",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-A-M-1216",
                               "Franchisee_Name": "Kidzee-Agra/Popense",
                               "Operating_Status": "A",
                               "Address1": "KE-46, Kavire Kunj,",
                               "Address2": "",
                               "Place": "Kamla Nagar, Near Mahaveer Dham,",
                               "City_Id": "36",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1216@kidzee.com",
                               "Mobile_No": "9760044505",
                               "Phone_No1": "7906035407",
                               "Phone_No2": "",
                               "Latitude": "27.214898",
                               "Longitude": "78.022354"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2408",
                               "Franchisee_Name": "KIDZEE DAYAL BAGH, AGRA",
                               "Operating_Status": "A",
                               "Address1": "220 -A, Ellora Enclave,",
                               "Address2": "100 Feet Road, Near DEI Information Center",
                               "Place": "Dayal Bagh, Near DEI Information Center",
                               "City_Id": "36",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee2408@kidzee.com",
                               "Mobile_No": "9568721801",
                               "Phone_No1": "9457277655",
                               "Phone_No2": "",
                               "Latitude": "27.229936",
                               "Longitude": "78.006245"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3198",
                               "Franchisee_Name": "KIDZEE SIKANDRA AGRA",
                               "Operating_Status": "A",
                               "Address1": "H.N.117, Sector-9, Awas Vikas Yojna,",
                               "Address2": "",
                               "Place": "Sikandra Agra, Behind- Kargil Petrol Pump,",
                               "City_Id": "36",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3198@kidzee.com",
                               "Mobile_No": "8958409999",
                               "Phone_No1": "8958409999",
                               "Phone_No2": "",
                               "Latitude": "27.212848",
                               "Longitude": "77.955592"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3875",
                               "Franchisee_Name": "Kidzee Lawyers colony Agra",
                               "Operating_Status": "A",
                               "Address1": "House no-34 , Subash Nagar,",
                               "Address2": "Near Vaishno Mata Mandir, Kendriya Hindi Sansthan Road",
                               "Place": "Lawyers colony.",
                               "City_Id": "36",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3875@kidzee.com",
                               "Mobile_No": "9412723305",
                               "Phone_No1": "09412-723305",
                               "Phone_No2": "",
                               "Latitude": "27.213078",
                               "Longitude": "77.991676",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/638e8cc0_c89f_4bdd_afbf_f6bc0b2ae1f1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/cc8be67c_4e17_4243_9d9a_d5c089b882f3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/b7508b83_d84c_4fae_a45a_0aa55ae21c2d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/b8e5fc3c_805a_4e08_abee_77e8de1ef03f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/49218e00_5ef7_40bd_9ee5_e0401b423778.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/db70412a_68b6_4a1e_82fd_db897ed2617f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/4ef375d3_7b4f_41f5_9088_8bd8d500507a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/be9c1054_7079_46a8_aae7_d04b680d9b09.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/3e147fd1_e60d_44e6_8d76_5771db47b933.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/5428fd36_27be_48f6_a876_be64dd72e8e1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/a40209e5_aa94_48fc_bb80_d7baa5302607.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/7be1c779_e79e_4db4_af72_622169e1ddd1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/b0a4b59e_115b_4fea_89a1_cdb2e9ae98b6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1886333/10556/3e9b9afd_3bc4_4af0_a917_afc8731787fb.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-4247",
                               "Franchisee_Name": "Kidzee Kamla Nagar Agra",
                               "Operating_Status": "A",
                               "Address1": "F-29,",
                               "Address2": "Pani Tanki Road,",
                               "Place": "Kamla Nagar, Behind Amul Parlour",
                               "City_Id": "36",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4247@kidzee.com",
                               "Mobile_No": "9412723305",
                               "Phone_No1": "09412-723305",
                               "Phone_No2": "",
                               "Latitude": "27.212677",
                               "Longitude": "78.017416"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3991",
                               "Franchisee_Name": "Kidzee Rajrai Road",
                               "Operating_Status": "A",
                               "Address1": "10 Madhav Vihar,",
                               "Address2": "Ground Floor",
                               "Place": "Rajrai Road ,Opp St.Mary School",
                               "City_Id": "36",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3991@kidzee.com",
                               "Mobile_No": "8077258484",
                               "Phone_No1": "8909701088",
                               "Phone_No2": "",
                               "Latitude": "27.127885",
                               "Longitude": "78.049776"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4618",
                               "Franchisee_Name": "Kidzee Bichpuri",
                               "Operating_Status": "A",
                               "Address1": "233, Narsi Village, Near Narsi Park",
                               "Address2": "Mouja Maghtai ",
                               "Place": "Bichpuri ,Near Narsi Park",
                               "City_Id": "36",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4618@kidzee.com",
                               "Mobile_No": "9219148947",
                               "Phone_No1": "9219148947",
                               "Phone_No2": "",
                               "Latitude": "27.1824068",
                               "Longitude": "77.9193303"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4644",
                               "Franchisee_Name": "Kidzee Shastri Puram",
                               "Operating_Status": "A",
                               "Address1": "27- A, Type,",
                               "Address2": "C-2,",
                               "Place": "Shatsripuram, Near Charchit Chauraha",
                               "City_Id": "36",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4644@kidzee.com",
                               "Mobile_No": "8460950168",
                               "Phone_No1": "9458076647,",
                               "Phone_No2": "",
                               "Latitude": "27.19782231388421",
                               "Longitude": "77.93119668220902",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2493374/11001/3fb04d59_6d30_4523_8fd5_b7b63cd56b09.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-4986",
                               "Franchisee_Name": "KIDZEE AGRA",
                               "Operating_Status": "A",
                               "Address1": "House No.226,Neerav Nikunj,",
                               "Address2": "Phase-1,",
                               "Place": "Sikandara",
                               "City_Id": "36",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4986@kidzee.com",
                               "Mobile_No": "9711432284",
                               "Phone_No1": "9711432284",
                               "Phone_No2": "",
                               "Latitude": "27.213774",
                               "Longitude": "77.9699657"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5499",
                               "Franchisee_Name": "Kidzee Manas Nagar",
                               "Operating_Status": "A",
                               "Address1": "132,",
                               "Address2": "Manas Nagar",
                               "Place": "Shahganj",
                               "City_Id": "36",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5499@kidzee.com",
                               "Mobile_No": "9719646006",
                               "Phone_No1": "9719646006",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Aligarh",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-4241",
                               "Franchisee_Name": "Kidzee Swaran Jyanti Nagar",
                               "Operating_Status": "A",
                               "Address1": "HIG-63,",
                               "Address2": "Ramghat Road,",
                               "Place": "Swaranjyanti Nagar, Near-SBI Bank,",
                               "City_Id": "43",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4241@kidzee.com",
                               "Mobile_No": "8006159555",
                               "Phone_No1": "9759665546",
                               "Phone_No2": "",
                               "Latitude": "27.89327",
                               "Longitude": "78.09976"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4533",
                               "Franchisee_Name": "Kidzee  Lal Diggi",
                               "Operating_Status": "A",
                               "Address1": "Flat No-G-1, Ground Floor,",
                               "Address2": "Kaswa Cole, Diggi Road,",
                               "Place": "Dodpur, Near IG Hall,",
                               "City_Id": "43",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4533@kidzee.com",
                               "Mobile_No": "9958367238",
                               "Phone_No1": "0571-2703194",
                               "Phone_No2": "",
                               "Latitude": "27.900854",
                               "Longitude": "78.081179",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2342740/10878/6662abbb_ad25_4c2d_93cd_55e22efffcc1.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-4389",
                               "Franchisee_Name": "Kidzee Iglas",
                               "Operating_Status": "A",
                               "Address1": "Near Pathwari Temple,",
                               "Address2": "Gonda Road,",
                               "Place": "Iglas, Near Pathwari Temple,",
                               "City_Id": "43",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4389@kidzee.com",
                               "Mobile_No": "9927000397",
                               "Phone_No1": "7668602182",
                               "Phone_No2": "",
                               "Latitude": "27.7081° N",
                               "Longitude": "77.9367° E"
                            }
                         ]
                      },
                      {
                         "City_Name": "Allahabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2916",
                               "Franchisee_Name": "Kidzee-Lukarganj, Allahabad",
                               "Operating_Status": "A",
                               "Address1": "143 / 93,",
                               "Address2": "",
                               "Place": "Lukarganj,  Beside Dr. Nigam",
                               "City_Id": "45",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2916@kidzee.com",
                               "Mobile_No": "9795151111",
                               "Phone_No1": "9795151111",
                               "Phone_No2": "",
                               "Latitude": "25.4433970",
                               "Longitude": "81.8161970"
                            },
                            {
                               "Franchisee_Code": "N-U-S-882",
                               "Franchisee_Name": "Kidzee-KalyaniDevi-Allahabad",
                               "Operating_Status": "A",
                               "Address1": "H.No-1359/1133,",
                               "Address2": "Kalyani Devi,",
                               "Place": "Kalyani Devi, OPP-Mini Chaudhary Garden,",
                               "City_Id": "45",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee882@kidzee.com",
                               "Mobile_No": "9653023427",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "25.430832",
                               "Longitude": "81.833707"
                            },
                            {
                               "Franchisee_Code": "N-B-S-1028",
                               "Franchisee_Name": "Kidzee-Tagore Town/Allahabad",
                               "Operating_Status": "A",
                               "Address1": "164/4-Manish Villa,",
                               "Address2": "",
                               "Place": "Tagore Town,",
                               "City_Id": "45",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1028@kidzee.com",
                               "Mobile_No": "9044405031",
                               "Phone_No1": "9307430013",
                               "Phone_No2": "",
                               "Latitude": "25.45582",
                               "Longitude": "81.862231"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2729",
                               "Franchisee_Name": "Kidzee Allahabad",
                               "Operating_Status": "A",
                               "Address1": "Plot No:16,",
                               "Address2": "ADA Colony,",
                               "Place": "Naini, Btw Mother Teresa Chauraha & Maharshi Vidya",
                               "City_Id": "45",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2729@kidzee.com",
                               "Mobile_No": "9565589681",
                               "Phone_No1": "9565589681",
                               "Phone_No2": "",
                               "Latitude": "25.387282",
                               "Longitude": "81.878148"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3734",
                               "Franchisee_Name": "Kidzee Preetam Nagar",
                               "Operating_Status": "A",
                               "Address1": "117, HIG Sulem Sarai",
                               "Address2": "Pargana and Tensil Sadar, ",
                               "Place": "Preetam Nagar ,Near Shree Ram Misthan Bhandar",
                               "City_Id": "45",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3734@kidzee.com",
                               "Mobile_No": "8004168634",
                               "Phone_No1": "8004168634",
                               "Phone_No2": "",
                               "Latitude": "25.452152",
                               "Longitude": "81.781097"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3758",
                               "Franchisee_Name": "KIDZEE KALINDIPURAM",
                               "Operating_Status": "A",
                               "Address1": "22A,",
                               "Address2": "Barsana Sector,",
                               "Place": "Kalindipuram ,IIIT Road,",
                               "City_Id": "45",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3758@kidzee.com",
                               "Mobile_No": "8182832295",
                               "Phone_No1": "9721-275228",
                               "Phone_No2": "",
                               "Latitude": "25.426123",
                               "Longitude": "81.783956",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1642287/10651/8f81ca2a_80e9_4cb4_81f2_e981b463743e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1642287/10651/f8a4c433_57cc_487b_8bd8_865d1de699b4.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-4613",
                               "Franchisee_Name": "Kidzee Teliyarganj",
                               "Operating_Status": "A",
                               "Address1": "145-B / 13 F, Chandpur Salori,",
                               "Address2": "(Near Katju Colony),",
                               "Place": "Teliyarganj, Madhuri Sadan",
                               "City_Id": "45",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4613@kidzee.com",
                               "Mobile_No": "7905090993",
                               "Phone_No1": "0532-2544520",
                               "Phone_No2": "",
                               "Latitude": "25.485592880026292",
                               "Longitude": "81.87177851140746",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2470684/10940/677c0b0d_992e_4807_86bd_bd3ee108b533.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2470684/10940/37a8e3f5_d798_4c9c_903a_9f1c45efb598.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-4985",
                               "Franchisee_Name": "Kidzee Bethany Naini",
                               "Operating_Status": "A",
                               "Address1": "53D-Pura Fateh Mohammad,",
                               "Address2": "Naini Adjecent To Bethany Convent School,",
                               "Place": "Naini, Near-Mab Institute Of Juridical Science (La",
                               "City_Id": "45",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4985@kidzee.com",
                               "Mobile_No": "9336278512",
                               "Phone_No1": "9336278512",
                               "Phone_No2": "",
                               "Latitude": "25.405079999999998",
                               "Longitude": "81.870615"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4576",
                               "Franchisee_Name": "Kidzee Kareli",
                               "Operating_Status": "A",
                               "Address1": "A - 722,",
                               "Address2": "G.T.B. Nagar,",
                               "Place": "Kareli, Near Unity Public School & College,",
                               "City_Id": "45",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4576@kidzee.com",
                               "Mobile_No": "9839211117",
                               "Phone_No1": "9839211117",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Azamgarh",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4173",
                            "Franchisee_Name": "Kidzee Azamgarh",
                            "Operating_Status": "A",
                            "Address1": "Gata No.78,",
                            "Address2": "Bhawarnath,",
                            "Place": "Darawara Colony,",
                            "City_Id": "95",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4173@kidzee.com",
                            "Mobile_No": "9005114334",
                            "Phone_No1": "05462-232214",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Ballia",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-C-551",
                               "Franchisee_Name": "KIDZEE-LITTLE WONDERS",
                               "Operating_Status": "A",
                               "Address1": "Professors Colony,",
                               "Address2": "Civil Lines",
                               "Place": "Ballia,UP, Professor'S Colony Ballia",
                               "City_Id": "104",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee551@kidzee.com",
                               "Mobile_No": "9415658346",
                               "Phone_No1": "05498-222825",
                               "Phone_No2": "",
                               "Latitude": "25.758438",
                               "Longitude": "84.148732",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/378/10717/7493666e_09b7_427e_8fd6_823a4e399cfa.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-4906",
                               "Franchisee_Name": "Kidzee Ballia",
                               "Operating_Status": "A",
                               "Address1": "Avas Vikas Colony,",
                               "Address2": "",
                               "Place": "Ballia, Near Hanuman Mandir,",
                               "City_Id": "104",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4906@kidzee.com",
                               "Mobile_No": "7521049796",
                               "Phone_No1": "9721150593",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Baraut",
                         "Franchisee": {
                            "Franchisee_Code": "N-G-K-883",
                            "Franchisee_Name": "Kidzee-Baraut-U.P",
                            "Operating_Status": "A",
                            "Address1": "15/280,",
                            "Address2": "Teacher Colony,",
                            "Place": "Nehru Nagar,",
                            "City_Id": "116",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "kidzee883@kidzee.com",
                            "Mobile_No": "9412663700",
                            "Phone_No1": "9412663700",
                            "Phone_No2": "",
                            "Latitude": "29.098353",
                            "Longitude": "77.266517"
                         }
                      },
                      {
                         "City_Name": "Bareilly",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-4470",
                               "Franchisee_Name": "Kidzee Izzatnagar",
                               "Operating_Status": "A",
                               "Address1": "H No- 5, Azad Vihar Colony, 100 Futa Road,",
                               "Address2": "Vill - Sherpur,",
                               "Place": "Izzatnagar,100 Futa Road,",
                               "City_Id": "117",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4470@Kidzee.com",
                               "Mobile_No": "8218139658",
                               "Phone_No1": "8218139658",
                               "Phone_No2": "",
                               "Latitude": "28.4026° N",
                               "Longitude": "79.4245° E"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5466",
                               "Franchisee_Name": "Kidzee Aonla",
                               "Operating_Status": "A",
                               "Address1": "Near Prakash Talkies",
                               "Address2": "Opp Swarna Palace",
                               "Place": "Aonla",
                               "City_Id": "117",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5466@kidzee.com",
                               "Mobile_No": "9720686363",
                               "Phone_No1": "9720686363",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Bulandshahr",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-C-305",
                               "Franchisee_Name": "Kidzee-Buland Shahar /Scholars World",
                               "Operating_Status": "A",
                               "Address1": "355, D. M. Colony.",
                               "Address2": "",
                               "Place": "D.M Colony",
                               "City_Id": "185",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee305@kidzee.com",
                               "Mobile_No": "9837316100",
                               "Phone_No1": "9837316100",
                               "Phone_No2": "",
                               "Latitude": "28.651305",
                               "Longitude": "77.429318"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5584",
                               "Franchisee_Name": "Kidzee Dibai",
                               "Operating_Status": "A",
                               "Address1": "Moh Mahadev",
                               "Address2": "Netu Clinic Gali",
                               "Place": "Dibai",
                               "City_Id": "185",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5584@kidzee.com",
                               "Mobile_No": "9758646767",
                               "Phone_No1": "8937083723",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Chandausi"
                      },
                      {
                         "City_Name": "Faizabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-C-C-1117",
                               "Franchisee_Name": "Kidzee-Faizabad,UP",
                               "Operating_Status": "A",
                               "Address1": "5/11/143, Mukharjee Niwas,",
                               "Address2": "Reidgh Road, Near-Saket Printing Press,",
                               "Place": "Reldganj, Reidganj Faizabad,",
                               "City_Id": "314",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1117@kidzee.com",
                               "Mobile_No": "9554005518",
                               "Phone_No1": "9889983374",
                               "Phone_No2": "",
                               "Latitude": "28.737191",
                               "Longitude": "77.282773"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3729",
                               "Franchisee_Name": "KIDZEE FAIZABAD",
                               "Operating_Status": "A",
                               "Address1": "DURGAPURI COLONY",
                               "Address2": "NEAR DISHA COACHING",
                               "Place": "FAIZABAD ,Near Disha Coaching Centre",
                               "City_Id": "314",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3729@kidzee.com",
                               "Mobile_No": "9415458915",
                               "Phone_No1": "9415458915",
                               "Phone_No2": "",
                               "Latitude": "26.78182224953545",
                               "Longitude": "83.72827010185456"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5418",
                               "Franchisee_Name": "Kidzee Ayodhya",
                               "Operating_Status": "A",
                               "Address1": "T.P convent school Campus Raibareilly Road",
                               "Address2": "Dev Nagar Colony Paharganj Near SBI Ayodhya ",
                               "Place": "Ayodhya",
                               "City_Id": "314",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5418@kidzee.com",
                               "Mobile_No": "9305055685",
                               "Phone_No1": "7607-612233",
                               "Phone_No2": "",
                               "Latitude": "26.758345711700898",
                               "Longitude": "82.13834207882739",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2876629/11066/169ca827_3969_4e8e_bcf5_dff5bf146a0f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2876629/11066/0ccf85c0_68ac_4f22_8a32_3825822bd890.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2876629/11066/6c761abf_5307_4629_935a_daa952c4407c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2876629/11066/68c523ad_2cd8_4089_b592_b30c76ce323c.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-5313",
                               "Franchisee_Name": "Kidzee Deokali",
                               "Operating_Status": "A",
                               "Address1": "Near- Shashi Gas Agency,",
                               "Address2": "Patwari Ka Purwa ,Near-Shashi Gas Agency,",
                               "Place": "Deokali Bypass",
                               "City_Id": "314",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5313@kidzee.com",
                               "Mobile_No": "8427246215",
                               "Phone_No1": "8427246215",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5607",
                               "Franchisee_Name": "Kidzee Tanda",
                               "Operating_Status": "A",
                               "Address1": "SAKRAWAL",
                               "Address2": "AMBEDKAR NAGAR",
                               "Place": "TANDA",
                               "City_Id": "314",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5607@kidzee.com",
                               "Mobile_No": "9984590000",
                               "Phone_No1": "9984590000",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Firozabad"
                      },
                      {
                         "City_Name": "Gonda"
                      },
                      {
                         "City_Name": "Gorakhpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-S-C-2148",
                               "Franchisee_Name": "Kidzee Divya Nagar UP",
                               "Operating_Status": "A",
                               "Address1": "C-192, DIVYA NAGAR EXTENSION",
                               "Address2": "POST: KHORABAR",
                               "Place": "Divya Nagar, Near ITI College",
                               "City_Id": "384",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2148@kidzee.com",
                               "Mobile_No": "6387679447",
                               "Phone_No1": "9415640618",
                               "Phone_No2": "",
                               "Latitude": "26.724365",
                               "Longitude": "83.429669"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2603",
                               "Franchisee_Name": "KIDZEE ARYA NAGAR GORAKHPUR",
                               "Operating_Status": "A",
                               "Address1": "38/136, Arya Nagar, Near- Tarang Over Bridge, Hotel Ganges,",
                               "Address2": "Near- Amar Singh Public School,",
                               "Place": "Arya Nagar, Near-Hotel Ganges,",
                               "City_Id": "384",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2603@kidzee.com",
                               "Mobile_No": "9651072561",
                               "Phone_No1": "0-9651072561",
                               "Phone_No2": "",
                               "Latitude": "26.762007",
                               "Longitude": "83.361157"
                            },
                            {
                               "Franchisee_Code": "N-S-C-2018",
                               "Franchisee_Name": "KIDZEE GORAKHNATH,GORAKHPUR",
                               "Operating_Status": "A",
                               "Address1": "21-A, Nathmalpur,",
                               "Address2": "Near-Mahaana Pratap Polytechnic,",
                               "Place": "Nathmalpur, Near- Maharana Pratap Polytechnic,",
                               "City_Id": "384",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2018@kidzee.com",
                               "Mobile_No": "9838784488",
                               "Phone_No1": "9838784488",
                               "Phone_No2": "",
                               "Latitude": "26.781495",
                               "Longitude": "83.356733"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4616",
                               "Franchisee_Name": "Kidzee Khajanchi  Chowk",
                               "Operating_Status": "A",
                               "Address1": "Bajrang Dhaam Colony Khajanchi Chowk",
                               "Address2": "Behind RAO MRI Centre",
                               "Place": "Khajanchi Chowk ,FATIMA BY PASS ROAD RAPTINAGAR",
                               "City_Id": "384",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4616@kidzee.com",
                               "Mobile_No": "9721772222",
                               "Phone_No1": "8433382222",
                               "Phone_No2": "",
                               "Latitude": "26.7962235",
                               "Longitude": "83.3928597"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4406",
                               "Franchisee_Name": "Paidleyganj Kaleypur Gorakhpur",
                               "Operating_Status": "A",
                               "Address1": "House No-12, Kasia Road,",
                               "Address2": "Kaleypur,",
                               "Place": "Paidleganj, Near-Bus Stop,",
                               "City_Id": "384",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4406@kidzee.com",
                               "Mobile_No": "9431266328",
                               "Phone_No1": "1234-000000",
                               "Phone_No2": "",
                               "Latitude": "26.760760",
                               "Longitude": "83.373700",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2198519/10961/5af2f422_325a_4f37_afa6_4b6cb5da33a1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2198519/10961/2b96b538_1a87_45d7_84bf_089d55563367.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2198519/10961/2b87da10_b6f3_459a_8d91_40ccf7810e12.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2198519/10961/42a77c63_2f09_4f94_bd34_cdc2ec58e682.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2198519/10961/e5f4a908_127e_4a4d_ad5b_508f7936d6f8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2198519/10961/e0cdedb6_5794_4c7c_9d9d_d52da2a80882.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2198519/10961/614455db_936e_47e2_b2f3_d8a93fc2927b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2198519/10961/458934b8_6b3b_4333_b2e2_c6f4102ce631.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2198519/10961/208121ec_81f3_4447_af67_5fc384faa243.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2198519/10961/69404b9d_e35e_459d_95cc_48a3334beb66.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-4865",
                               "Franchisee_Name": "Kidzee Gita Vatika",
                               "Operating_Status": "A",
                               "Address1": "192,",
                               "Address2": "Jail Road",
                               "Place": "Gita Vatika ,Jail Road",
                               "City_Id": "384",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4865@kidzee.com",
                               "Mobile_No": "9096349996",
                               "Phone_No1": "9916102770",
                               "Phone_No2": "",
                               "Latitude": "26.770011",
                               "Longitude": "83.387090"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5675",
                               "Franchisee_Name": "Kidzee Rustampur",
                               "Operating_Status": "A",
                               "Address1": "481-A PRAYAGPURAM COLONY",
                               "Address2": "NEAR DURGA CHAUK, BARGO ROAD,NEW SHIVPURI COLONY,",
                               "Place": "RUSTAMPUR",
                               "City_Id": "384",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5675@kidzee.com",
                               "Mobile_No": "9508169626",
                               "Phone_No1": "9508169626",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5759",
                               "Franchisee_Name": "Kidzee Pawanpuram Colony",
                               "Operating_Status": "A",
                               "Address1": "PADRI BAZAR",
                               "Address2": "JUNGLE DHUSAN",
                               "Place": "PAWANPURAM COLONY",
                               "City_Id": "384",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5759@kidzee.com",
                               "Mobile_No": "7376580538",
                               "Phone_No1": "7376580538",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5669",
                               "Franchisee_Name": "Kidzee Padri Bazar",
                               "Operating_Status": "A",
                               "Address1": "0059 Q",
                               "Address2": "Jangle Salik Ram",
                               "Place": "Padri Bazar",
                               "City_Id": "384",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5669@kidzee.com",
                               "Mobile_No": "9140793045",
                               "Phone_No1": "9140793045",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5547",
                               "Franchisee_Name": "Kidzee Gorkhpur",
                               "Operating_Status": "A",
                               "Address1": "HIG – C 313",
                               "Address2": "Ground Floor",
                               "Place": "Siddhartha Enclave",
                               "City_Id": "384",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5547@kidzee.com",
                               "Mobile_No": "9140900159",
                               "Phone_No1": "9140900159",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Hapur",
                         "Franchisee": {
                            "Franchisee_Code": "N-U-C-390",
                            "Franchisee_Name": "Kidzee-Sanskriti Educational Institute",
                            "Operating_Status": "A",
                            "Address1": "GangaPura (Vivek Vihar),",
                            "Address2": "Free Ganj Road,",
                            "Place": "Free Ganj Road, Vivek Vihar ,Near Railway Park",
                            "City_Id": "422",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "kidzee390@kidzee.com",
                            "Mobile_No": "9897857875",
                            "Phone_No1": "01222306840",
                            "Phone_No2": "",
                            "Latitude": "28.735177",
                            "Longitude": "77.775680"
                         }
                      },
                      {
                         "City_Name": "Hathras",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-5236",
                               "Franchisee_Name": "Kidzee awas Vikas colony Agra road",
                               "Operating_Status": "A",
                               "Address1": "5/13, Awas Vikas Colony,",
                               "Address2": "Agra Road,",
                               "Place": "Hathras, Behind-ICICI Bank,",
                               "City_Id": "426",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5236@kidzee.com",
                               "Mobile_No": "7906391817",
                               "Phone_No1": "7906391817",
                               "Phone_No2": "",
                               "Latitude": "27.578765",
                               "Longitude": "78.054878"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4673",
                               "Franchisee_Name": "KIDZEE HATHRAS",
                               "Operating_Status": "A",
                               "Address1": "Khandari Gari",
                               "Address2": "Near Kailash Mandir",
                               "Place": "Vivekanand Nagar ,NEAR KAILASH MANDIR",
                               "City_Id": "426",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4673@kidzee.com",
                               "Mobile_No": "9012575587",
                               "Phone_No1": "9012575587",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Jhansi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-S-437",
                               "Franchisee_Name": "M L Pandey Academy",
                               "Operating_Status": "A",
                               "Address1": "School Site, Nandanpura Yojna,",
                               "Address2": "",
                               "Place": "Awas Vikas Colony, Near- Arogya Sadan",
                               "City_Id": "495",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee437@kidzee.com",
                               "Mobile_No": "9415073297",
                               "Phone_No1": "8299039064",
                               "Phone_No2": "",
                               "Latitude": "25.453613",
                               "Longitude": "78.534141"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2172",
                               "Franchisee_Name": "Kidzee Bajrang Colony Jhansi",
                               "Operating_Status": "A",
                               "Address1": "172, Bajrang Colony, Ground Floor",
                               "Address2": "Behind MLB Medical college",
                               "Place": "Bajrang Colony, BEHIND MEDICAL COLLEGE",
                               "City_Id": "495",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2172@kidzee.com",
                               "Mobile_No": "9695598603",
                               "Phone_No1": "05102321859",
                               "Phone_No2": "",
                               "Latitude": "25.468163",
                               "Longitude": "78.61059",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/724493/10825/cf49ef94_d98d_4311_b9fd_3a8ff658f792.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/724493/10825/f2323de0_a6e9_4053_98d0_2b60dfd15faa.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/724493/10825/35ccf9d0_1438_4a79_94a7_08b63f152ab9.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-2381",
                               "Franchisee_Name": "KIDZEE JHANSI UP",
                               "Operating_Status": "A",
                               "Address1": "Behind Jain Mandir,",
                               "Address2": "Tilak Nagar, Bhel",
                               "Place": "Tilak Nagar Bhel, Near Jain Mandir",
                               "City_Id": "495",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2381@kidzee.com",
                               "Mobile_No": "7379777727",
                               "Phone_No1": "9473582427",
                               "Phone_No2": "",
                               "Latitude": "25.448426",
                               "Longitude": "78.568459"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3821",
                               "Franchisee_Name": "Kidzee Talbahet",
                               "Operating_Status": "A",
                               "Address1": "Matatila Road, Before Railway Crossing,",
                               "Address2": "Gramshabha ,Distt. Lalitpur",
                               "Place": "Talbahet, BEFORE RAILWAY CROSSING",
                               "City_Id": "495",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3821@kidzee.com",
                               "Mobile_No": "9473583211",
                               "Phone_No1": "051761111",
                               "Phone_No2": "",
                               "Latitude": "25.052850",
                               "Longitude": "78.415498",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2006152/10800/c8bc52f5_fe31_48c6_a987_4ac3ed06ae4b.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-4309",
                               "Franchisee_Name": "Kidzee Civil Lines",
                               "Operating_Status": "A",
                               "Address1": "House Number-1446,",
                               "Address2": "Ground Floor,",
                               "Place": "Civil Line, Opp- To Nigam Hospital,",
                               "City_Id": "495",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4309@kidzee.com",
                               "Mobile_No": "9794849731",
                               "Phone_No1": "8087302371",
                               "Phone_No2": "",
                               "Latitude": "25.45",
                               "Longitude": "78.55"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4060",
                               "Franchisee_Name": "Kidzee Veerangna Nagar",
                               "Operating_Status": "A",
                               "Address1": "DHL-13,",
                               "Address2": "Ground Floor,",
                               "Place": "Veerangna Nagar, Behind-MLB Medical College,",
                               "City_Id": "495",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4060@Kidzee.com",
                               "Mobile_No": "9984019779",
                               "Phone_No1": "051741111",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3981",
                               "Franchisee_Name": "Kidzee Rajgarh",
                               "Operating_Status": "A",
                               "Address1": "Near-Eldeco County,",
                               "Address2": "Main Road,",
                               "Place": "Rajgarh Jhansi, Near-Eldeco County,",
                               "City_Id": "495",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3981@kidzee.com",
                               "Mobile_No": "7238851784",
                               "Phone_No1": "8423459025",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Kanpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-B-N-165",
                               "Franchisee_Name": "Kidzee- Kidwai Nagar- Kanpur-SUDHA MEMORIAL",
                               "Operating_Status": "A",
                               "Address1": "128/49, Block- K,",
                               "Address2": "",
                               "Place": "Kidwai Nagar,",
                               "City_Id": "534",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee165@kidzee.com",
                               "Mobile_No": "9044050374",
                               "Phone_No1": "05122611347",
                               "Phone_No2": "",
                               "Latitude": "26.422127",
                               "Longitude": "80.323052"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2251",
                               "Franchisee_Name": "Kidzee P H Global Academy Kanpur",
                               "Operating_Status": "A",
                               "Address1": "E-153,",
                               "Address2": "Shyam Nagar",
                               "Place": "Shyam Nagar, Kanpur",
                               "City_Id": "534",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2251@kidzee.com",
                               "Mobile_No": "9651064854",
                               "Phone_No1": "9198389292",
                               "Phone_No2": "",
                               "Latitude": "26.408409267342",
                               "Longitude": "80.3665298223495"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3325",
                               "Franchisee_Name": "Kidzee Sarvodya Nagar",
                               "Operating_Status": "A",
                               "Address1": "117 / K / -99,",
                               "Address2": "RS Puram ,",
                               "Place": "Sarvodya Nagar, OPP -To Kulwanti Hospital,",
                               "City_Id": "534",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3325@kidzee.com",
                               "Mobile_No": "8172841111",
                               "Phone_No1": "8172813333",
                               "Phone_No2": "",
                               "Latitude": "26.481614",
                               "Longitude": "80.294608"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3254",
                               "Franchisee_Name": "KIDZEE DAMODAR NAGAR",
                               "Operating_Status": "A",
                               "Address1": "1364-A,",
                               "Address2": "",
                               "Place": "Damodar Nagar, Ward No-2,",
                               "City_Id": "534",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3254@kidzee.com",
                               "Mobile_No": "8130497045",
                               "Phone_No1": "8130497045",
                               "Phone_No2": "",
                               "Latitude": "26.423991",
                               "Longitude": "80.309725"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2539",
                               "Franchisee_Name": "KIDZEE  SHUKLAGANJ",
                               "Operating_Status": "A",
                               "Address1": "6/707, Rahul Bhawan,",
                               "Address2": "Rajdhani Road,",
                               "Place": "Shuklaganj, Gopinathpuram Road, Kanpur,",
                               "City_Id": "534",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2539@kidzee.com",
                               "Mobile_No": "7786929008",
                               "Phone_No1": "0515-2835553",
                               "Phone_No2": "",
                               "Latitude": "26.485306363334452",
                               "Longitude": "80.39142483103558"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5267",
                               "Franchisee_Name": "Kidzee Panki",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 273, B Block Panki",
                               "Address2": "Opposite Saraswati Vidalaya Inter College",
                               "Place": "Panki",
                               "City_Id": "534",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5267@kidzee.com",
                               "Mobile_No": "7524807218",
                               "Phone_No1": "9651-689753",
                               "Phone_No2": "",
                               "Latitude": "26.463347",
                               "Longitude": "80.247078"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5575",
                               "Franchisee_Name": "Kidzee Kanpur Nagar",
                               "Operating_Status": "A",
                               "Address1": "House Number – 185, Swarn Jayanti Vihar Road",
                               "Address2": "Koyla Nagar C.O.D.",
                               "Place": "Kanpur Nagar",
                               "City_Id": "534",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5575@kidzee.com",
                               "Mobile_No": "7668090908",
                               "Phone_No1": "7668090908",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5772",
                               "Franchisee_Name": "Kidzee Ramadevi",
                               "Operating_Status": "A",
                               "Address1": "77/7,Jamuna Farm",
                               "Address2": "Gandhi Gram ,Harjinder Nagar",
                               "Place": "Ramadevi",
                               "City_Id": "534",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5772@kidzee.com",
                               "Mobile_No": "8765190133",
                               "Phone_No1": "8765190133",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4224",
                               "Franchisee_Name": "Kidzee Govind Nagar",
                               "Operating_Status": "A",
                               "Address1": "126 / J / 6,",
                               "Address2": "Ground Floor,",
                               "Place": "Govind Nagar,J-Block Kanpur,",
                               "City_Id": "534",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4224@kidzee.com",
                               "Mobile_No": "9935603838",
                               "Phone_No1": "9935603838",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5083",
                               "Franchisee_Name": "Kidzee Lajpat Nagar",
                               "Operating_Status": "A",
                               "Address1": "120/286,",
                               "Address2": "Near-Allahabad Bank,",
                               "Place": "Lajpat Nagar, Near Allahabad Bank",
                               "City_Id": "534",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5083@kidzee.com",
                               "Mobile_No": "9889251180",
                               "Phone_No1": "9889251180",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Kasganj"
                      },
                      {
                         "City_Name": "Kheri"
                      },
                      {
                         "City_Name": "Lakhimpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-C-601",
                               "Franchisee_Name": "Kidzee-Lakhimpur-U.P",
                               "Operating_Status": "A",
                               "Address1": "Lal Kothi,",
                               "Address2": "Lakhimpur,",
                               "Place": "Hidayat Nagar,",
                               "City_Id": "614",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee601@kidzee.com",
                               "Mobile_No": "9839542032",
                               "Phone_No1": "05872279699",
                               "Phone_No2": "",
                               "Latitude": "27.949079",
                               "Longitude": "80.782401"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2781",
                               "Franchisee_Name": "P.S. AJMANI EDUCATIONAL TRUST  Lakhampur Kheri",
                               "Operating_Status": "A",
                               "Address1": "Ajmani International School,",
                               "Address2": "",
                               "Place": "Lakhampur Kheri, Lucknow Bypass Road,",
                               "City_Id": "614",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2781@kidzee.com",
                               "Mobile_No": "9839243333",
                               "Phone_No1": "9839243333",
                               "Phone_No2": "",
                               "Latitude": "27.949079",
                               "Longitude": "80.782401"
                            }
                         ]
                      },
                      {
                         "City_Name": "Lucknow",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-A-N-69",
                               "Franchisee_Name": "KIDZEE-BHASKAR EDUCATION-Indira nagar",
                               "Operating_Status": "A",
                               "Address1": "B Block-2002,",
                               "Address2": "",
                               "Place": "Indira Nagar ,B- Block, Near Eram College",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee69@kidzee.com",
                               "Mobile_No": "9415106117",
                               "Phone_No1": "5222342155",
                               "Phone_No2": "",
                               "Latitude": "26.879893",
                               "Longitude": "80.992633"
                            },
                            {
                               "Franchisee_Code": "N-B-S-426",
                               "Franchisee_Name": "Kidzee-Royal Kids",
                               "Operating_Status": "A",
                               "Address1": "House No.1564, Sector K-1,",
                               "Address2": "Ashiyana Colony,",
                               "Place": "Ashiyana ,Near Chancellors Club",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee426@kidzee.com",
                               "Mobile_No": "8601227666",
                               "Phone_No1": "8127302020",
                               "Phone_No2": "",
                               "Latitude": "26.783495",
                               "Longitude": "80.910668"
                            },
                            {
                               "Franchisee_Code": "N-B-S-682",
                               "Franchisee_Name": "Kidzee-Hazratganj-Lucknow/Galaxy Education",
                               "Operating_Status": "A",
                               "Address1": "21/11, GHA-1,",
                               "Address2": "Tilak Marg, Dalibagh,",
                               "Place": "Hazratganj, Near-VIP Guest House,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee682@kidzee.com",
                               "Mobile_No": "9336844988",
                               "Phone_No1": "9336850263",
                               "Phone_No2": "",
                               "Latitude": "26.856447",
                               "Longitude": "80.945655"
                            },
                            {
                               "Franchisee_Code": "N-A-M-1169",
                               "Franchisee_Name": "Kidzee-RajajiPuram,Lucknow",
                               "Operating_Status": "A",
                               "Address1": "13/7065, C-Block,",
                               "Address2": "Near-Police Boot,",
                               "Place": "Rajajipuram, Near-MIS Chauraha,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1169@kidzee.com",
                               "Mobile_No": "9305719249",
                               "Phone_No1": "9839064098",
                               "Phone_No2": "",
                               "Latitude": "26.848558",
                               "Longitude": "80.878736"
                            },
                            {
                               "Franchisee_Code": "N-S-S-1511",
                               "Franchisee_Name": "Kidzee Gomtinagarphase2",
                               "Operating_Status": "A",
                               "Address1": "2/116,",
                               "Address2": "Vijayant Khand, Gomti nagar phase 2,",
                               "Place": "Vijayant Khand ,Near MJ Grand",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1511@kidzee.com",
                               "Mobile_No": "8853529357",
                               "Phone_No1": "8853529357",
                               "Phone_No2": "",
                               "Latitude": "26.868606",
                               "Longitude": "81.021634"
                            },
                            {
                               "Franchisee_Code": "N-S-S-1624",
                               "Franchisee_Name": "Kidzee-Lucknow",
                               "Operating_Status": "A",
                               "Address1": "1 /184, Sector 1, Vikas nagar,",
                               "Address2": "Near Lekhraj Panna,",
                               "Place": "Vikas Nagar ,Sector-1",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1781@kidzee.com",
                               "Mobile_No": "9335924950",
                               "Phone_No1": "8896005000",
                               "Phone_No2": "",
                               "Latitude": "26.896833",
                               "Longitude": "80.960857"
                            },
                            {
                               "Franchisee_Code": "N-R-S-2869",
                               "Franchisee_Name": "Kidzee Vinay Khand Gomti Nagar",
                               "Operating_Status": "A",
                               "Address1": "5/14 Vinay khand",
                               "Address2": "Gomti Nagar,",
                               "Place": "Vinay Khand",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee2869@kidzee.com",
                               "Mobile_No": "9792188847",
                               "Phone_No1": "9415403306",
                               "Phone_No2": "",
                               "Latitude": "26.8590580",
                               "Longitude": "81.0042340"
                            },
                            {
                               "Franchisee_Code": "N-B-S-978",
                               "Franchisee_Name": "Kidzee -Chowk-Lucknow",
                               "Operating_Status": "A",
                               "Address1": "310/2, Azizunnisa Hall,",
                               "Address2": "Gole Darwaza Road,",
                               "Place": "Near Chowk Thana,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee978@kidzee.com",
                               "Mobile_No": "9792653555",
                               "Phone_No1": "5222253536",
                               "Phone_No2": "",
                               "Latitude": "26.863905",
                               "Longitude": "80.902799"
                            },
                            {
                               "Franchisee_Code": "N-R-S-2666",
                               "Franchisee_Name": "KIDZEE  VRINDAVAN YOJNA - II LUCKNOW",
                               "Operating_Status": "A",
                               "Address1": "H-No-9B/77,",
                               "Address2": "Sector-9-B,",
                               "Place": "Vrindavan Yojna - II, Sector-9-B,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2666@kidzee.com",
                               "Mobile_No": "9839636446",
                               "Phone_No1": "0-9936056294",
                               "Phone_No2": "",
                               "Latitude": "26.762673",
                               "Longitude": "80.951875"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3888",
                               "Franchisee_Name": "Kidzee Kalyanpur",
                               "Operating_Status": "A",
                               "Address1": "17, Kalhansh Bhawan",
                               "Address2": "Kanchana Beehari Marg, ",
                               "Place": "Kalyanpur.",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3888@kidzee.com",
                               "Mobile_No": "9670777781",
                               "Phone_No1": "9670777781",
                               "Phone_No2": "",
                               "Latitude": "26.90087232128464",
                               "Longitude": "80.97124527976234"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3889",
                               "Franchisee_Name": "Kidzee Rai Bareli Road",
                               "Operating_Status": "A",
                               "Address1": "Plot No. J - 196, South City,",
                               "Address2": "Ratnakar  Khand,Ward Shardnagar",
                               "Place": "Rai Bareli Road ,South City",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3889@kidzee.com",
                               "Mobile_No": "8005100500",
                               "Phone_No1": "8005100500",
                               "Phone_No2": "",
                               "Latitude": "26.769263026782536",
                               "Longitude": "80.9363044024467"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3888",
                               "Franchisee_Name": "Kidzee Kalyanpur",
                               "Operating_Status": "A",
                               "Address1": "17, Kalhansh Bhawan",
                               "Address2": "Kanchana Beehari Marg, ",
                               "Place": "Kalyanpur.",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3888@kidzee.com",
                               "Mobile_No": "9670777781",
                               "Phone_No1": "9670777781",
                               "Phone_No2": "",
                               "Latitude": "26.900968",
                               "Longitude": "80.971197"
                            },
                            {
                               "Franchisee_Code": "N-R-S-4172",
                               "Franchisee_Name": "KIDZEE IIM MUBARAKPUR",
                               "Operating_Status": "A",
                               "Address1": "House Number HS-1/54,",
                               "Address2": "Sector-A,",
                               "Place": "Sitapur Road Yojna, Near-IIM Campus,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4172@kidzee.com",
                               "Mobile_No": "9415587242",
                               "Phone_No1": "0522-4242162",
                               "Phone_No2": "",
                               "Latitude": "26.905407",
                               "Longitude": "80.942109",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2054247/10873/1402a341_bdb5_475a_87c6_cf031d4db7e9.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-S-4292",
                               "Franchisee_Name": "Kidzee Ramkrishna Marg",
                               "Operating_Status": "A",
                               "Address1": "4, Ram Krishna Marg,",
                               "Address2": "",
                               "Place": "Vivekanand Puri, Near.I.T. Chauraha,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4292@kidzee.com",
                               "Mobile_No": "9793620000",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "26.8718120",
                               "Longitude": "80.9467570",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2166918/10824/b489f8a3_1f1c_4920_804e_5e2d7b57ceb9.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2166918/10824/32f8447b_9bea_4b84_b585_b0ba8edf4973.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2166918/10824/1bc1f8ad_02dc_468b_a395_344399c454f8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2166918/10824/788c13c0_cca3_46a4_8c43_52047c101440.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-S-4318",
                               "Franchisee_Name": "Kidzee Ratan Khand",
                               "Operating_Status": "A",
                               "Address1": "1/661, Ground Floor,",
                               "Address2": "Sharda Nagar Yojna,Ratan Khand,",
                               "Place": "Ratankhand,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4318@kidzee.com",
                               "Mobile_No": "9415002691",
                               "Phone_No1": "9919319777",
                               "Phone_No2": "",
                               "Latitude": "26.778695",
                               "Longitude": "80.927743"
                            },
                            {
                               "Franchisee_Code": "N-R-S-4773",
                               "Franchisee_Name": "Kidzee III M Road",
                               "Operating_Status": "A",
                               "Address1": "112, Hhaivans Vihar Colony,",
                               "Address2": "Near-Ramkunj Guest House,",
                               "Place": "I I M Road, Ner Ramkunj Guest House",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4773@kidzee.com",
                               "Mobile_No": "8707593183",
                               "Phone_No1": "8707593183",
                               "Phone_No2": "",
                               "Latitude": "26.929684994127964",
                               "Longitude": "80.92572537301635"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3972",
                               "Franchisee_Name": "Kidzee Janikipuram",
                               "Operating_Status": "A",
                               "Address1": "B-1/270, Sector G,",
                               "Address2": "Near Vishal Hospital opposite Janki Vatika",
                               "Place": "Jankipuram ,Near Vishal Hospital",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3972@kidzee.com",
                               "Mobile_No": "8756700500",
                               "Phone_No1": "9519906799",
                               "Phone_No2": "",
                               "Latitude": "26.917270",
                               "Longitude": "80.950920"
                            },
                            {
                               "Franchisee_Code": "N-R-S-4554",
                               "Franchisee_Name": "Kidzee Janakipuram,",
                               "Operating_Status": "A",
                               "Address1": "Plot No-177,",
                               "Address2": "",
                               "Place": "Jankipuram,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4554@kidzee.com",
                               "Mobile_No": "9235318515",
                               "Phone_No1": "9235318515",
                               "Phone_No2": "",
                               "Latitude": "26.926531",
                               "Longitude": "80.939808"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5116",
                               "Franchisee_Name": "Kidzee Viraj Khand",
                               "Operating_Status": "A",
                               "Address1": "2/153, Viraj Khand",
                               "Address2": "Gomatinagar, Near Spring dale school",
                               "Place": "Lucknow ,viraj Khand",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5116@kidzee.com",
                               "Mobile_No": "9919947669",
                               "Phone_No1": "0522-79640",
                               "Phone_No2": "",
                               "Latitude": "26.851819",
                               "Longitude": "81.033493"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5113",
                               "Franchisee_Name": "Kidzee Ansal API",
                               "Operating_Status": "A",
                               "Address1": "Sector-C, Pocket-6, Ansal Apikhasra No-154,",
                               "Address2": "Yusuf Nagar,  Bagia Mau Bijnaur,",
                               "Place": "Ansal API, Sushant Golf City,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5113@kidzee.com",
                               "Mobile_No": "8400697777",
                               "Phone_No1": "872638888",
                               "Phone_No2": "",
                               "Latitude": "26.782416",
                               "Longitude": "81.004679"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5286",
                               "Franchisee_Name": "Kidzee Kanchanpur Matiyari",
                               "Operating_Status": "A",
                               "Address1": "House No-98, Himcity Part-2,",
                               "Address2": "Chinhut,",
                               "Place": "Kanchanpur Matiyari, Deva Road,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5286@kidzee.com",
                               "Mobile_No": "8318502366",
                               "Phone_No1": "8318502366",
                               "Phone_No2": "",
                               "Latitude": "26.89186337240127",
                               "Longitude": "81.0361647605896"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4997",
                               "Franchisee_Name": "Kidzee Saraswatipuram",
                               "Operating_Status": "A",
                               "Address1": "House no- 58 Saraswati Puram",
                               "Address2": "Tirath Kunj, Near SPGI",
                               "Place": "Saraswati Puram ,Near SPGI",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4997@kidzee.com",
                               "Mobile_No": "9532340344",
                               "Phone_No1": "9532-340344",
                               "Phone_No2": "",
                               "Latitude": "26.751194834132527",
                               "Longitude": "80.94723288951047",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2692416/11056/7bd3c400_1e7a_4769_a765_c5e44e9cfc98.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2692416/11056/838b1ef8_29a4_4e72_baa0_14fa26243f0d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2692416/11056/084e82e5_f7da_41d3_ab11_4c52a7ba2215.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2692416/11056/12b8116c_b152_4ffc_8f97_860ea56308a3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2692416/11056/7b397a7e_7e37_4cb6_a508_1e403b584820.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2692416/11057/d989d519_4a9a_413f_b973_cd0ba9e6466a.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-5365",
                               "Franchisee_Name": "Kidzee Khurshed Bagh",
                               "Operating_Status": "A",
                               "Address1": "73 Vishnu Nagar,",
                               "Address2": "",
                               "Place": "Khurshed Bagh Colony",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5365@kidzee.com",
                               "Mobile_No": "9839178449",
                               "Phone_No1": "0522-4326738",
                               "Phone_No2": "",
                               "Latitude": "26.8439 N",
                               "Longitude": "80.9221 E",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/bc49fbdd_db35_49d3_8916_8504d433dee1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/6e03481e_e70c_4ce0_a306_2bde5d864c0b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/f5c9a6e8_9e18_4024_a8a5_aeb4a8bdeffb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/7e88e590_5276_4adf_bde1_1bee193e5973.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/ad9138b6_0104_4aa4_9310_76ad1826cdb5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/88a8f6b7_06f3_482b_8ab9_3b08042bc624.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/b8dc8320_e1ca_4770_af97_604dd95ea2ad.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/8d074448_5e7a_4afe_85b7_a74033ff5735.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/39aa5207_48e6_4cfd_b9c1_9a139a35f03e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/e27d6c60_5e51_4f8e_8b2b_4f551376f392.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/ea73d78e_2cb2_42eb_81b7_7360d1595577.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/05e204d5_609d_4fe8_b492_740c16927057.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2822597/11058/d90010d5_6c1f_4c55_b61e_22d32711ac4e.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-S-4811",
                               "Franchisee_Name": "Kidzee Sai Vihar colony",
                               "Operating_Status": "A",
                               "Address1": "226021, Plot No 61,",
                               "Address2": "Jankipuram Extension, Near Abdul Kalam Technical University",
                               "Place": "Sai Vihar Colony ,Near AKTU",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4811@kidzee.com",
                               "Mobile_No": "9161190003",
                               "Phone_No1": "9161190003",
                               "Phone_No2": "",
                               "Latitude": "26.94725",
                               "Longitude": "80.93988888888889"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5420",
                               "Franchisee_Name": "Kidzee Adil Nagar",
                               "Operating_Status": "A",
                               "Address1": "The Presidency Public School ",
                               "Address2": "Adil Nagar, Kalyanpur West Lucknow",
                               "Place": "Adil Nagar",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5420@kidzee.com",
                               "Mobile_No": "9456011223",
                               "Phone_No1": "9456011223",
                               "Phone_No2": "",
                               "Latitude": "26.908393",
                               "Longitude": "80.968361"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5401",
                               "Franchisee_Name": "Kidzee Vikas Khand",
                               "Operating_Status": "A",
                               "Address1": "2/210, Vikas Khand,",
                               "Address2": "Gomti Nagar,",
                               "Place": "Vikas Khand",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5401@kidzee.com",
                               "Mobile_No": "9305055685",
                               "Phone_No1": "0522-3593120",
                               "Phone_No2": "",
                               "Latitude": "26.850048141734266",
                               "Longitude": "80.99603191257431",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2857596/11065/0a98f233_a28e_4785_980d_f0825bd2b713.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2857596/11065/a6aab390_7bf7_4f04_b9c9_8adbc5e567ff.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2857596/11065/cfba259f_895a_400f_a6f6_ba963a19847b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2857596/11065/1e0676a3_e9ae_4a73_96aa_7f27ab09584a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2857596/11065/c62f2967_57bc_4574_964c_6da204249bd1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2857596/11065/07f46bb4_0810_4c4e_8431_3c68e2b906d7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2857596/11065/e03f8e1b_222e_41cf_a995_410a49b93494.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2857596/11065/9a1387c2_e83d_4e35_abce_f512c8cb18ee.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2857596/11065/d8646150_6b79_47a8_8aa2_2dbf57a20134.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-5201",
                               "Franchisee_Name": "Kidzee Amrai Gaon",
                               "Operating_Status": "A",
                               "Address1": "267/A AMRAI GAON, HIMESTED-1, COLONY",
                               "Address2": "SAHEED BHAGAT SINGH WARD, NAUBASTA ROAD, NEAR-WOODS APARTMENT ",
                               "Place": "AMRAI GAON",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5201@kidzee.com",
                               "Mobile_No": "7081472395",
                               "Phone_No1": "7081472395",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4295",
                               "Franchisee_Name": "Kidzee Gomti Vistar Nagar",
                               "Operating_Status": "A",
                               "Address1": "19, Bhaisora,",
                               "Address2": "Ground Floor,",
                               "Place": "Gomti Nagar Vistar, Bhaisora,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4295@kidzee.com",
                               "Mobile_No": "9473939317",
                               "Phone_No1": "9473939317",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5330",
                               "Franchisee_Name": "KIDZEE KHASARWARA",
                               "Operating_Status": "A",
                               "Address1": "371, DREAM GREEN CITY,",
                               "Address2": "DHAVAPUR,PARGANA BIJNOR,SAROJINI NAGAR",
                               "Place": "KHASARWARA",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5330@kidzee.com",
                               "Mobile_No": "8840932696",
                               "Phone_No1": "8840932696",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5218",
                               "Franchisee_Name": "Kidzee Manakpur",
                               "Operating_Status": "A",
                               "Address1": "New Shubham singh,",
                               "Address2": "Pandit Khera",
                               "Place": "Manakpur ,Pandit Khera",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5218@kidzee.com",
                               "Mobile_No": "7754072362",
                               "Phone_No1": "7754072362",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5157",
                               "Franchisee_Name": "Kidzee Faizabad Road",
                               "Operating_Status": "A",
                               "Address1": "Ghar Enclave Ph-3, Kh.no-63",
                               "Address2": "Tiwari Ganj, Utthardahuna,",
                               "Place": "Faizabad Road",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5157@kidzee.com",
                               "Mobile_No": "9581288800",
                               "Phone_No1": "9581288800",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-S-5419",
                               "Franchisee_Name": "Kidzee Gomtinagar Ext. Sector 4",
                               "Operating_Status": "A",
                               "Address1": "House No-4/103-D,",
                               "Address2": "",
                               "Place": "Gomtinagar Extension Sector 4",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5419@kidzee.com",
                               "Mobile_No": "7607612233",
                               "Phone_No1": "7607612233",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-S-3993",
                               "Franchisee_Name": "Kidzee Kanpur Road",
                               "Operating_Status": "A",
                               "Address1": "59 A, Krishna Nagar,",
                               "Address2": "Kanpur,",
                               "Place": "Kanpur Road, Krishna Nagar,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3993@kidzee.com",
                               "Mobile_No": "9450932148",
                               "Phone_No1": "052211111",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-S-4885",
                               "Franchisee_Name": "Kidzee Lolai Malhour",
                               "Operating_Status": "A",
                               "Address1": "Plot no 09",
                               "Address2": "Village Lolai Naizampur Malhour",
                               "Place": "Gomtinagar ,Lohali Village",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4885@kidzee.com",
                               "Mobile_No": "9936136666",
                               "Phone_No1": "9936136666",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-S-5676",
                               "Franchisee_Name": "Kidzee Aliganj",
                               "Operating_Status": "A",
                               "Address1": "House no -A-1/3,",
                               "Address2": "Sector-B",
                               "Place": "Aliganj",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5676@kidzee.com",
                               "Mobile_No": "8090090008",
                               "Phone_No1": "8090090008",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5386",
                               "Franchisee_Name": "Shaheed Path- Friends Colony,",
                               "Operating_Status": "A",
                               "Address1": "D-158",
                               "Address2": "Friends Colony",
                               "Place": "Shaheed Path- Friends Colony,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5386@kidzee.com",
                               "Mobile_No": "6386122946",
                               "Phone_No1": "9721152353",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5321",
                               "Franchisee_Name": "Kidzee Dayal estate",
                               "Operating_Status": "A",
                               "Address1": "A-13, DAYAL ESTATE,",
                               "Address2": "FAIZABAD ROAD",
                               "Place": "CHINHAT",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5321@kidzee.com",
                               "Mobile_No": "7307987403",
                               "Phone_No1": "9918521632",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-S-4054",
                               "Franchisee_Name": "Kidzee Gomtii  Nagar",
                               "Operating_Status": "A",
                               "Address1": "2/374,",
                               "Address2": "Vikalp Khand",
                               "Place": "Gomtii Nagar ,Near Katutha Jheel",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4054@kidzee.com",
                               "Mobile_No": "9410222119",
                               "Phone_No1": "09454226064",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5725",
                               "Franchisee_Name": "Kidzee Gomti Nagar Extension",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 4/C - 1091,",
                               "Address2": "Sector - 4",
                               "Place": "Gomti Nagar Extension",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5725@kidzee.com",
                               "Mobile_No": "9451908999",
                               "Phone_No1": "9451908999",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-S-4925",
                               "Franchisee_Name": "Kidzee Sita Vihar",
                               "Operating_Status": "A",
                               "Address1": "Plot 27, Murli Vihar, Opposite Santi Nagar,",
                               "Address2": "Kanpur Road,",
                               "Place": "Sarojani Nagar, Opp Shantinagar,",
                               "City_Id": "624",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4925@kidzee.com",
                               "Mobile_No": "7309901403",
                               "Phone_No1": "7309901403",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Mathura",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-3337",
                               "Franchisee_Name": "Kidzee Mathura",
                               "Operating_Status": "A",
                               "Address1": "55 Mayur Vihar,",
                               "Address2": "",
                               "Place": "Mathura, Near Maharana Pratap Park,",
                               "City_Id": "656",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3337@kidzee.com",
                               "Mobile_No": "8909268936",
                               "Phone_No1": "0789-5222901",
                               "Phone_No2": "",
                               "Latitude": "27.48189382443952",
                               "Longitude": "77.67914090080262"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5363",
                               "Franchisee_Name": "Kidzee Madhuvan Enclave",
                               "Operating_Status": "A",
                               "Address1": "H-No.8, Madhuvan Enclave ,Gopi Krishna Charitable Trust",
                               "Address2": "Gowardhan Chowk",
                               "Place": "Madhuvan Enclave",
                               "City_Id": "656",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5363@kidzee.com",
                               "Mobile_No": "9761055000",
                               "Phone_No1": "9761055000",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3996",
                               "Franchisee_Name": "Kidzee Rounch Bangar",
                               "Operating_Status": "A",
                               "Address1": "House no. 242,",
                               "Address2": "Narsipuram,",
                               "Place": "Ronchit Bangar ,Near new ARTO office",
                               "City_Id": "656",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3996@kidzee.com",
                               "Mobile_No": "6396419114",
                               "Phone_No1": "392111111",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Mau"
                      },
                      {
                         "City_Name": "Meerut",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2704",
                               "Franchisee_Name": "KIDZEE JAGRATI VIHAR MEERUT",
                               "Operating_Status": "A",
                               "Address1": "221/8,",
                               "Address2": "",
                               "Place": "Meerut, Jagrati Vihar,",
                               "City_Id": "665",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2704@kidzee.com",
                               "Mobile_No": "9837340666",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "28.984644",
                               "Longitude": "77.705956"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4297",
                               "Franchisee_Name": "Kidzee Modipuram",
                               "Operating_Status": "A",
                               "Address1": "Plot No.78, Phase-1,",
                               "Address2": "",
                               "Place": "Modipuram, Ansal Town,",
                               "City_Id": "665",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4297@kidzee.com",
                               "Mobile_No": "8392913744",
                               "Phone_No1": "7249900757",
                               "Phone_No2": "",
                               "Latitude": "29.048671",
                               "Longitude": "77.697996"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4315",
                               "Franchisee_Name": "Kidzee Rohta Road",
                               "Operating_Status": "A",
                               "Address1": "E -56, Ground Floor,",
                               "Address2": "Gold Coast,",
                               "Place": "Rohta Road, Gold Coast Society,",
                               "City_Id": "665",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4315@kidzee.com",
                               "Mobile_No": "7500825242",
                               "Phone_No1": "7500825242",
                               "Phone_No2": "",
                               "Latitude": "28.9967939",
                               "Longitude": "77.6567261"
                            }
                         ]
                      },
                      {
                         "City_Name": "Moradabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-U-947",
                               "Franchisee_Name": "Kidzee-Awantika Colony-Moradabad",
                               "Operating_Status": "A",
                               "Address1": "House No.A-42,",
                               "Address2": "MDA Awantika Colony, Infront of Water Tank,",
                               "Place": "Awantika Colony, Near Water Tank,",
                               "City_Id": "678",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee947@kidzee.com",
                               "Mobile_No": "9997960571",
                               "Phone_No1": "9410400544",
                               "Phone_No2": "",
                               "Latitude": "28.838648",
                               "Longitude": "78.773329"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4251",
                               "Franchisee_Name": "Harsh Playshool Moradabad",
                               "Operating_Status": "A",
                               "Address1": "SP-A/C-000,",
                               "Address2": "Kashiram G Nagar,",
                               "Place": "Moradaabad, Near-Gautam Buddha Park,",
                               "City_Id": "678",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4251@kidzee.com",
                               "Mobile_No": "8923054547",
                               "Phone_No1": "9410893678",
                               "Phone_No2": "",
                               "Latitude": "28.8386° N",
                               "Longitude": "78.7733° E"
                            }
                         ]
                      },
                      {
                         "City_Name": "Muzaffarnagar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-M-645",
                               "Franchisee_Name": "KIDZEE- NEW MANDI- MUZAFFARNAGAR",
                               "Operating_Status": "A",
                               "Address1": "85-A,",
                               "Address2": "South Bhopa Road, New Mandi,",
                               "Place": "Bhopa Road,",
                               "City_Id": "688",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee645@kidzee.com",
                               "Mobile_No": "9897008660",
                               "Phone_No1": "9897008660",
                               "Phone_No2": "",
                               "Latitude": "29.472486",
                               "Longitude": "77.717782"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3610",
                               "Franchisee_Name": "Kidzee Jansath Road",
                               "Operating_Status": "A",
                               "Address1": "Sree Sidhvnayak Educational Trust Jansath Road,",
                               "Address2": "Gulshan Vihar,",
                               "Place": "Jansath Road,",
                               "City_Id": "688",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3610@kidzee.com",
                               "Mobile_No": "9897621000",
                               "Phone_No1": "09358-911753",
                               "Phone_No2": "",
                               "Latitude": "29.453900",
                               "Longitude": "77.720755"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5601",
                               "Franchisee_Name": "Kidzee Muzaffarnagar",
                               "Operating_Status": "A",
                               "Address1": "188 , Bagh Jankidas city branch",
                               "Address2": "",
                               "Place": "Muzaffarnagar",
                               "City_Id": "688",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5601@kidzee.com",
                               "Mobile_No": "9837030666",
                               "Phone_No1": "9837030666",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Pilibhit",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4595",
                            "Franchisee_Name": "Kidzee Puranpur",
                            "Operating_Status": "A",
                            "Address1": "Ward No- 6, Punjabi Colony,",
                            "Address2": "Ganesh Ganj Poorvi, Block Road,",
                            "Place": "Puranpur, Punjabi colony,",
                            "City_Id": "792",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4595@kidzee.com",
                            "Mobile_No": "9719119203",
                            "Phone_No1": "9719119203",
                            "Phone_No2": "",
                            "Latitude": "28.507057",
                            "Longitude": "80.147697"
                         }
                      },
                      {
                         "City_Name": "Rampur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-U-C-459",
                               "Franchisee_Name": "KIDZEE-KHANDELWAL PUBLIC SCHOOL",
                               "Operating_Status": "A",
                               "Address1": "Opp.PWD Office,",
                               "Address2": "Diamond Cinema Road,",
                               "Place": "Rampur, Opp- PWD Office",
                               "City_Id": "830",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee459@kidzee.com",
                               "Mobile_No": "9837025205",
                               "Phone_No1": "9219944249",
                               "Phone_No2": "",
                               "Latitude": "28.789304",
                               "Longitude": "79.024952"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4391",
                               "Franchisee_Name": "Kidzee Rampur",
                               "Operating_Status": "A",
                               "Address1": "Agapur Road, Laxmi Nagar,",
                               "Address2": "Ground Floor,",
                               "Place": "Jwala Nagar, Near Khadi Bhandar,",
                               "City_Id": "830",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4391@kidzee.com",
                               "Mobile_No": "9837025025",
                               "Phone_No1": "9837323460",
                               "Phone_No2": "",
                               "Latitude": "28.7983° N",
                               "Longitude": "79.0220° E"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5566",
                               "Franchisee_Name": "Kidzee Rasra",
                               "Operating_Status": "A",
                               "Address1": "RAMPUR VIA RASRA",
                               "Address2": "Dist Ballia",
                               "Place": "RASRA",
                               "City_Id": "830",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5566@kidzee.com",
                               "Mobile_No": "8181871676",
                               "Phone_No1": "8181871676",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Saharanpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2668",
                               "Franchisee_Name": "KIDZEE SAHARANPUR",
                               "Operating_Status": "A",
                               "Address1": "House No- 4/1517,",
                               "Address2": "Nawab Ganj, Near- Vijay Talkies,",
                               "Place": "Saharanpur, Near-Vijay Talkies,",
                               "City_Id": "855",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2668@kidzee.com",
                               "Mobile_No": "9720151505",
                               "Phone_No1": "9458046046",
                               "Phone_No2": "",
                               "Latitude": "29.96975",
                               "Longitude": "77.550139"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4455",
                               "Franchisee_Name": "Kidzee Saharanpur",
                               "Operating_Status": "A",
                               "Address1": "B-63,",
                               "Address2": "Opp-Officers Colony,",
                               "Place": "Awass Vikas ,",
                               "City_Id": "855",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4455@kidzee.com",
                               "Mobile_No": "9675550657",
                               "Phone_No1": "0132-7961772",
                               "Phone_No2": "",
                               "Latitude": "29.8928018726162",
                               "Longitude": "77.61009367773431"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5389",
                               "Franchisee_Name": "Kidzee Mandi Samiti Road",
                               "Operating_Status": "A",
                               "Address1": "8/4653, Plot No-4,",
                               "Address2": "West Krishna Nagar,",
                               "Place": "Mandi Samiti Road,",
                               "City_Id": "855",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5389@kidzee.com",
                               "Mobile_No": "9897057948",
                               "Phone_No1": "9897057948",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Sahibabad",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3982",
                            "Franchisee_Name": "Kidzee Rajendran Nagar",
                            "Operating_Status": "A",
                            "Address1": "House No-7/22,Sec-2,",
                            "Address2": "Ghaziabad",
                            "Place": "Rajendran Nagar, RAJENDRA NAGAR, SAHIBABAD",
                            "City_Id": "857",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3982@kidzee.com",
                            "Mobile_No": "8076828113",
                            "Phone_No1": "012011111",
                            "Phone_No2": "",
                            "Latitude": "28.687446",
                            "Longitude": "77.358424"
                         }
                      },
                      {
                         "City_Name": "Sirsa"
                      },
                      {
                         "City_Name": "Sultanpur",
                         "Franchisee": {
                            "Franchisee_Code": "N-C-C-1150",
                            "Franchisee_Name": "Kidzee-Sultanpur,UP",
                            "Operating_Status": "A",
                            "Address1": "777/1,",
                            "Address2": "PWD Road,",
                            "Place": "Civil Line, Near-Kacheri,",
                            "City_Id": "934",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1150@kidzee.com",
                            "Mobile_No": "9415091991",
                            "Phone_No1": "05362226248",
                            "Phone_No2": "",
                            "Latitude": "26.264808",
                            "Longitude": "82.079865"
                         }
                      },
                      {
                         "City_Name": "Tundla"
                      },
                      {
                         "City_Name": "Varanasi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-B-U-744",
                               "Franchisee_Name": "kidzee- Varanasi-U.P",
                               "Operating_Status": "A",
                               "Address1": "S-18/38, Nadesar,",
                               "Address2": "Opp I G Police Office,",
                               "Place": "Nadesar, Near Air Force Station,",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee744@kidzee.com",
                               "Mobile_No": "9335416885",
                               "Phone_No1": "7001253395",
                               "Phone_No2": "",
                               "Latitude": "25.334786",
                               "Longitude": "82.98913"
                            },
                            {
                               "Franchisee_Code": "N-L-C-1487",
                               "Franchisee_Name": "Kidzee-Central",
                               "Operating_Status": "A",
                               "Address1": "Raghuvansh\" H.No J12/6D1,",
                               "Address2": "Piplani Katra,",
                               "Place": "Nati Imli Road ,Piplani Katra",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1487@kidzee.com",
                               "Mobile_No": "9044269288",
                               "Phone_No1": "9118410560",
                               "Phone_No2": "",
                               "Latitude": "25.324244",
                               "Longitude": "83.005283"
                            },
                            {
                               "Franchisee_Code": "N-S-C-2132",
                               "Franchisee_Name": "KINDERENJOY EDUCARE VARANASI",
                               "Operating_Status": "A",
                               "Address1": "Plot no: B1/87-7-1K-N",
                               "Address2": "Ravindrapuri Extension, Goenka Gali",
                               "Place": "Ravindrapuri, Opp Oriana Hospital",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2132@kidzee.com",
                               "Mobile_No": "9335269925",
                               "Phone_No1": "9335269925",
                               "Phone_No2": "",
                               "Latitude": "25.290171",
                               "Longitude": "83.001505"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4249",
                               "Franchisee_Name": "Kidzee Varanasi",
                               "Operating_Status": "A",
                               "Address1": "Samne Ghat,",
                               "Address2": "Lanka,",
                               "Place": "Maruti Nagar, Samne Ghat,",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4249@kidzee.com",
                               "Mobile_No": "7544000970",
                               "Phone_No1": "7839367935",
                               "Phone_No2": "",
                               "Latitude": "82.973",
                               "Longitude": "25.37"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4076",
                               "Franchisee_Name": "Kidzee Varanasi",
                               "Operating_Status": "A",
                               "Address1": "Village - Kanchanpur,",
                               "Address2": "Post -",
                               "Place": "Kandawa (Gate), Near- DLW,",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4076@kidzee.com",
                               "Mobile_No": "9792012344",
                               "Phone_No1": "8840564593",
                               "Phone_No2": "",
                               "Latitude": "25.27581587289076",
                               "Longitude": "82.95781096560665"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3968",
                               "Franchisee_Name": "Kidzee Vaishnavi Vihar Colony",
                               "Operating_Status": "A",
                               "Address1": "Main road,",
                               "Address2": "Susuwahi Chitaipur",
                               "Place": "Vaishnavi Vihar Colony ,Gautam Nagar Colony",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3968@kidzee.com",
                               "Mobile_No": "7081233337",
                               "Phone_No1": "7081233336",
                               "Phone_No2": "",
                               "Latitude": "25.260458329029998",
                               "Longitude": "82.97103291534427",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1953897/10969/e0562d8b_0ee6_4cae_883c_a59b969b3ae0.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-5081",
                               "Franchisee_Name": "Kidzee Amra Bypass Road",
                               "Operating_Status": "A",
                               "Address1": "House no. L-4/25,",
                               "Address2": "Shivdham Nagar Colony, Amra Bypass Road",
                               "Place": "Chitaipur ,Near Amra By pass Opp Petrol Pump",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5081@kidzee.com",
                               "Mobile_No": "8008823246",
                               "Phone_No1": "8008823246",
                               "Phone_No2": "",
                               "Latitude": "26.480662384397466",
                               "Longitude": "83.78579020633545"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4014",
                               "Franchisee_Name": "Kidzee Kakarmatta",
                               "Operating_Status": "A",
                               "Address1": "N-10 / 79, F-1 B,",
                               "Address2": "DLW - Bhu Road,",
                               "Place": "Kakarmatta, Opp Indian Oil Petrol Pump,",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4014@kidzee.com",
                               "Mobile_No": "9415221675",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "25.2842570",
                               "Longitude": "82.9720860"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5648",
                               "Franchisee_Name": "Kidzee Badalalpur",
                               "Operating_Status": "A",
                               "Address1": "A-125,VDA COLONY,",
                               "Address2": "PHASE-1,OPP.TRADE FACILITATION CENTER,CHANDMARI",
                               "Place": "BADALALPUR",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5648@kidzee.com",
                               "Mobile_No": "9198360056",
                               "Phone_No1": "9198360056",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5775",
                               "Franchisee_Name": "KIDZEE SARNATH",
                               "Operating_Status": "A",
                               "Address1": "AARAZI NO.6 & 272",
                               "Address2": "MAUJA -PARSHURAMPUR, BUDDHA NAGAR COLONY ",
                               "Place": "SARNATH",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5775@kidzee.com",
                               "Mobile_No": "9650073207",
                               "Phone_No1": "9650073207",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5740",
                               "Franchisee_Name": "KIDZEE  MAHMOORGANJ",
                               "Operating_Status": "A",
                               "Address1": "B 38/13,2K,PLOT NO.4",
                               "Address2": "NAVODIT NAGAR COLONY,TULSIPUR",
                               "Place": "MAHMOORGANJ",
                               "City_Id": "998",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5740@kidzee.com",
                               "Mobile_No": "9305444044",
                               "Phone_No1": "9305444044",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Najibabad"
                      },
                      {
                         "City_Name": "Hardoi",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5050",
                            "Franchisee_Name": "KIDZEE HARDOI",
                            "Operating_Status": "A",
                            "Address1": "NEW CIVIL LINE PIHANI CHUNGI",
                            "Address2": "NEAR SAINIK PETROL PUMP",
                            "Place": "HARDOI",
                            "City_Id": "1065",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5050@kidzee.com",
                            "Mobile_No": "8840059598",
                            "Phone_No1": "8840059598",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Chandauli"
                      },
                      {
                         "City_Name": "Banda",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-S-C-1588",
                               "Franchisee_Name": "Kidzee-Naraini Bazar-Banda",
                               "Operating_Status": "A",
                               "Address1": "Naraini Bazar,",
                               "Address2": "Banda Uttar Pradesh",
                               "Place": "Naraini Road ,Naraini Bazar",
                               "City_Id": "1099",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1588@kidzee.com",
                               "Mobile_No": "9415129400",
                               "Phone_No1": "9415129400",
                               "Phone_No2": "",
                               "Latitude": "25.18759",
                               "Longitude": "80.468846",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1588/217/4c09a50e_a7be_42a4_8a21_d0ce7921f48d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1588/217/c7cae057_888e_4f7e_aed2_993555b59954.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1588/217/2fffad65_85a2_48f0_a72b_c0650be96fe9.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-4941",
                               "Franchisee_Name": "Kidzee Atarra",
                               "Operating_Status": "A",
                               "Address1": "01 Badausa Road Atarra Pitrol Pump Ke Samne ",
                               "Address2": "Krishna Nagar Oran Road",
                               "Place": "Atarra ,Near Atarra petrol pump",
                               "City_Id": "1099",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4941@kidzee.com",
                               "Mobile_No": "7985687156",
                               "Phone_No1": "7985687156",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Deoria",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-5541",
                               "Franchisee_Name": "Kidzee Deoria",
                               "Operating_Status": "A",
                               "Address1": "1-A & 1-B, Ground Floor, The Admin Building",
                               "Address2": "Gadpurvan Near Government Polytechnic",
                               "Place": "Deoria",
                               "City_Id": "1102",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5541@kidzee.com",
                               "Mobile_No": "9920288652",
                               "Phone_No1": "-",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5048",
                               "Franchisee_Name": "Kidzee Salempur Majhavli",
                               "Operating_Status": "A",
                               "Address1": "Near- ITI School, Sonda,",
                               "Address2": "",
                               "Place": "Salempur Majhavli, Near- ITI School Sonda, Tapa- S",
                               "City_Id": "1102",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5048@kidzee.com",
                               "Mobile_No": "8299395734",
                               "Phone_No1": "8299395734",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4614",
                               "Franchisee_Name": "Kidzee Deoria Khas",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor",
                               "Address2": "",
                               "Place": "Deoria Khas, Swami Vivekanand Colony",
                               "City_Id": "1102",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4614@kidzee.com",
                               "Mobile_No": "8299139695",
                               "Phone_No1": "8299139695",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Pilkhuwa"
                      },
                      {
                         "City_Name": "Shahjahanpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-L-C-2030",
                               "Franchisee_Name": "Kidzee  Shahjahanpur  UP",
                               "Operating_Status": "A",
                               "Address1": "Infront Of Kachehari Western Gate,",
                               "Address2": "OPP. Laxmi Photo State,",
                               "Place": "Khirnibagh, Nea- West Gate Kachehari,",
                               "City_Id": "1110",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2030@kidzee.com",
                               "Mobile_No": "9648939480",
                               "Phone_No1": "05842-356641",
                               "Phone_No2": "",
                               "Latitude": "27.883744",
                               "Longitude": "79.912245"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4171",
                               "Franchisee_Name": "Kidzee Khutar Road",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor,",
                               "Address2": "Powayan,",
                               "Place": "Khutar Road, Near-Rana Public School,",
                               "City_Id": "1110",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4171@kidzee.com",
                               "Mobile_No": "9670012912",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Rae bareli",
                         "Franchisee": {
                            "Franchisee_Code": "N-S-C-1757",
                            "Franchisee_Name": "Kidzee-Raibareily",
                            "Operating_Status": "A",
                            "Address1": "M-9, Amrishpuri Colcony,",
                            "Address2": "",
                            "Place": "Amrishpuri Colony,",
                            "City_Id": "1135",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1757@kidzee.com",
                            "Mobile_No": "9415155393",
                            "Phone_No1": "",
                            "Phone_No2": "",
                            "Latitude": "26.220423",
                            "Longitude": "81.225722"
                         }
                      },
                      {
                         "City_Name": "Khanpur"
                      },
                      {
                         "City_Name": "Bharwari",
                         "Franchisee": {
                            "Franchisee_Code": "N-A-M-1227",
                            "Franchisee_Name": "Kidzee-Kaushambhi",
                            "Operating_Status": "A",
                            "Address1": "Kaushambhi,",
                            "Address2": "",
                            "Place": "Parsara, Bharwari",
                            "City_Id": "1179",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1227@kidzee.com",
                            "Mobile_No": "9839417585",
                            "Phone_No1": "9956044619",
                            "Phone_No2": "",
                            "Latitude": "25.559797",
                            "Longitude": "81.485565"
                         }
                      },
                      {
                         "City_Name": "Palia / Palia kalan"
                      },
                      {
                         "City_Name": "Dhanaura / Mandi dhanaura"
                      },
                      {
                         "City_Name": "Modinagar",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5182",
                            "Franchisee_Name": "Kidzee Modinagar",
                            "Operating_Status": "A",
                            "Address1": "Sikri,",
                            "Address2": "",
                            "Place": "Ghaziabad, Modinagar,",
                            "City_Id": "1198",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "ANMOLBANSAL.DEC94@GMAIL.COM",
                            "Mobile_No": "9719807737",
                            "Phone_No1": "01232-121212",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Orai"
                      },
                      {
                         "City_Name": "Salon"
                      },
                      {
                         "City_Name": "Sirsi"
                      },
                      {
                         "City_Name": "Garhmukteshwar"
                      },
                      {
                         "City_Name": "Mainpuri"
                      },
                      {
                         "City_Name": "Shikohabad"
                      },
                      {
                         "City_Name": "Etah",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4058",
                            "Franchisee_Name": "Kidzee Etah",
                            "Operating_Status": "A",
                            "Address1": "Devi Niwas,",
                            "Address2": "Near-Jwala Takies,",
                            "Place": "Etah,Near-Cinema Hall,",
                            "City_Id": "1235",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4058@kidzee.com",
                            "Mobile_No": "9837173764",
                            "Phone_No1": "9927006038 ",
                            "Phone_No2": "",
                            "Latitude": "27.5596",
                            "Longitude": "78.6526"
                         }
                      },
                      {
                         "City_Name": "Chitrakoot"
                      },
                      {
                         "City_Name": "Sirathu"
                      },
                      {
                         "City_Name": "Ghazipur / Ghazeepore",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5072",
                            "Franchisee_Name": "kidzee Jamal Nagar Colony",
                            "Operating_Status": "A",
                            "Address1": "Jamal Nagar Colony,",
                            "Address2": "Khovamandi Rajdepur, Rauza Dist.",
                            "Place": "Rajdepur, Jamal Nagar Colony,",
                            "City_Id": "1303",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5072@kidzee.com",
                            "Mobile_No": "7651984184",
                            "Phone_No1": "9889796786",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Kiraoli"
                      },
                      {
                         "City_Name": "Pratapgarh / Bela / Bela pratapgarh / Partapgarh / Partabgarh",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3974",
                            "Franchisee_Name": "Kidzee Balipur",
                            "Operating_Status": "A",
                            "Address1": "H.No.91,",
                            "Address2": "",
                            "Place": "Balipur, Gayatri Nagar, Pratapgarh",
                            "City_Id": "1305",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3974@kidzee.com",
                            "Mobile_No": "9936218039",
                            "Phone_No1": "9936-218039",
                            "Phone_No2": "",
                            "Latitude": "25.9152377",
                            "Longitude": "81.9909822"
                         }
                      },
                      {
                         "City_Name": "Sitapur",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5493",
                            "Franchisee_Name": "Kidzee Sitapur",
                            "Operating_Status": "A",
                            "Address1": "Agarwal Public inter collage",
                            "Address2": "Sitapur Sadar",
                            "Place": "Vijay Laxmi Nagar",
                            "City_Id": "1325",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5493@kidzee.com",
                            "Mobile_No": "8010636955",
                            "Phone_No1": "9889854341",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Jaunpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-4350",
                               "Franchisee_Name": "Kidzee Dhannupur Colony  Jaunpur",
                               "Operating_Status": "A",
                               "Address1": "Dhannupur Colony,",
                               "Address2": "Near Kuttupur Tiraha",
                               "Place": "Dhannupur Colony,",
                               "City_Id": "1331",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4350@kidzee.com",
                               "Mobile_No": "8275783005",
                               "Phone_No1": "8275783005",
                               "Phone_No2": "",
                               "Latitude": "25.763760673925855",
                               "Longitude": "82.69052261745392"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3619",
                               "Franchisee_Name": "Kidzee Jaunpur",
                               "Operating_Status": "A",
                               "Address1": "337, Singh Bhawan",
                               "Address2": "Civil Lines",
                               "Place": "Jaunpur ,Near Kachehri",
                               "City_Id": "1331",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3619@kidzee.com",
                               "Mobile_No": "9838443355",
                               "Phone_No1": "9415287775",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Mughalsarai"
                      },
                      {
                         "City_Name": "Obra"
                      },
                      {
                         "City_Name": "Barabanki"
                      },
                      {
                         "City_Name": "Badaun",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4056",
                            "Franchisee_Name": "Kidzee Badaun",
                            "Operating_Status": "A",
                            "Address1": "Nagla Sharki,",
                            "Address2": "Ground Floor,",
                            "Place": "Badaun Civil Lines,",
                            "City_Id": "1362",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4056@kidzee.com",
                            "Mobile_No": "8126362736",
                            "Phone_No1": "058321111",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Basti",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4476",
                            "Franchisee_Name": "Kidzee Devraon",
                            "Operating_Status": "A",
                            "Address1": "Shiv Nagar, Sakin Mauja, Turkahiya,",
                            "Address2": "Tappa - Devraon,",
                            "Place": "Turkahiyan Gandhi Nagar, Shiv Nagar,",
                            "City_Id": "1370",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4476@kidzee.com",
                            "Mobile_No": "9838775555",
                            "Phone_No1": "9838775555",
                            "Phone_No2": "",
                            "Latitude": "26.7900° N",
                            "Longitude": "82.7322° E"
                         }
                      },
                      {
                         "City_Name": "Unnao"
                      },
                      {
                         "City_Name": "Kannauj",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2405",
                            "Franchisee_Name": "KIDZEE R S LAL DEVKI SHIKSHA PRASHAR SAMITI",
                            "Operating_Status": "A",
                            "Address1": "Near Nirankari Satsang Bhawan,",
                            "Address2": "Village. Bushunpur, Hasilpur, Pargana,",
                            "Place": "Chibramau, Near Nirankari Satsang Bhawan,",
                            "City_Id": "1380",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2405@kidzee.com",
                            "Mobile_No": "9794541381",
                            "Phone_No1": "9794541321",
                            "Phone_No2": "",
                            "Latitude": "27.051281",
                            "Longitude": "79.913853"
                         }
                      },
                      {
                         "City_Name": "Bahraich",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4189",
                            "Franchisee_Name": "Kidzee Bahraich",
                            "Operating_Status": "A",
                            "Address1": "Sufipura,",
                            "Address2": "Civil Lines,",
                            "Place": "Bahraich, Behind- Vikas Bhawan,",
                            "City_Id": "1381",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4189@kidzee.com",
                            "Mobile_No": "9455779911",
                            "Phone_No1": "9455-779911",
                            "Phone_No2": "",
                            "Latitude": "27.562603168801658",
                            "Longitude": "81.5953516960144",
                            "Images": [
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2068612/10718/34a63c7a_53b2_4711_8d14_c60190eebc99.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2068612/10718/7b7999ec_8fd8_4cfa_a867_934eb4d48d59.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2068612/10718/cbfe36d7_d070_48b2_bea8_36c0ccda5133.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2068612/10718/300b010c_e6a9_42e3_83af_25bccb07bbb6.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2068612/10718/a7e2a396_9b3f_4196_84ac_24c29bbf31db.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2068612/10718/8a9050e4_e912_41cd_871d_c9983d7a78ff.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2068612/10718/571ba928_ba76_42dc_9723_1e235846304f.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2068612/10718/f38647d0_9960_4cae_b6f3_073dfc7b0944.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2068612/10718/bdcdb94d_a4d9_4102_b24c_5170bc3d1b4f.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2068612/10718/451793e0_b246_42e4_8d89_a4449e937569.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Mauranipur"
                      },
                      {
                         "City_Name": "Amethi",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5134",
                            "Franchisee_Name": "Kidzee RGIPT Amethi",
                            "Operating_Status": "A",
                            "Address1": "RGIPT Jais Campus,",
                            "Address2": "",
                            "Place": "Amethi, RGIPT Jais Campus,",
                            "City_Id": "1394",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5134@kidzee.com",
                            "Mobile_No": "7388494180",
                            "Phone_No1": "7388494180",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Gauriganj"
                      },
                      {
                         "City_Name": "Fatehgarh",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2461",
                            "Franchisee_Name": "KIDZEE FATEHGARH UP",
                            "Operating_Status": "A",
                            "Address1": "Bholepur Near Reset Gym",
                            "Address2": "Fatehgarh",
                            "Place": "Nagladeena ,Near Reset GYM",
                            "City_Id": "1396",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2461@kidzee.com",
                            "Mobile_No": "8765253396",
                            "Phone_No1": "8765253396",
                            "Phone_No2": "",
                            "Latitude": "27.37014",
                            "Longitude": "79.62387"
                         }
                      },
                      {
                         "City_Name": "Farrukhabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-3235",
                               "Franchisee_Name": "Kidzee Lohiyapuram",
                               "Operating_Status": "A",
                               "Address1": "1/318, Awas vikas colony,",
                               "Address2": "",
                               "Place": "Lohiyapuram, Farrukhabad, Awas Vikas Colony",
                               "City_Id": "1397",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3235@kidzee.com",
                               "Mobile_No": "9839576765",
                               "Phone_No1": "9839576765",
                               "Phone_No2": "",
                               "Latitude": "27.382613",
                               "Longitude": "79.594054"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5583",
                               "Franchisee_Name": "Kidzee Farrukhabad",
                               "Operating_Status": "A",
                               "Address1": "plot no 2",
                               "Address2": "State Bank Road",
                               "Place": "Gohai Street",
                               "City_Id": "1397",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5583@kidzee.com",
                               "Mobile_No": "9454616263",
                               "Phone_No1": "9454616263",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5608",
                               "Franchisee_Name": "Kidzee  Mohamadabad",
                               "Operating_Status": "A",
                               "Address1": "Azad Nagar,Near hero agency",
                               "Address2": "Sakisa Road",
                               "Place": " Mohamadabad",
                               "City_Id": "1397",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5608@kidzee.com",
                               "Mobile_No": "9838959124",
                               "Phone_No1": "9838959124",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Jalaun"
                      },
                      {
                         "City_Name": "Kushinagar",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4394",
                            "Franchisee_Name": "Kidzee Kushinagar",
                            "Operating_Status": "A",
                            "Address1": "Jatahan Road,",
                            "Address2": "",
                            "Place": "Padrauna, Jatahan Road,",
                            "City_Id": "1399",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4394@kidzee.com",
                            "Mobile_No": "9450469091",
                            "Phone_No1": "8840143255",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Khurja"
                      },
                      {
                         "City_Name": "Sant kabir nagar"
                      },
                      {
                         "City_Name": "Amroha",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2442",
                               "Franchisee_Name": "Kidzee J.P. Nagar  Amroha",
                               "Operating_Status": "A",
                               "Address1": "Bye Pass Road,",
                               "Address2": "Punjabi Colony,",
                               "Place": "J.P Nagar,Near-Axis Bank ATM,",
                               "City_Id": "1404",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2442@kidzee.com",
                               "Mobile_No": "9058702870",
                               "Phone_No1": "9368580877",
                               "Phone_No2": "",
                               "Latitude": "28.903390",
                               "Longitude": "78.481140"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4361",
                               "Franchisee_Name": "Kidzee Joya",
                               "Operating_Status": "A",
                               "Address1": "NH-24,",
                               "Address2": "",
                               "Place": "Joya, Near-PWD Office,",
                               "City_Id": "1404",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4361@kidzee.com",
                               "Mobile_No": "9897674977",
                               "Phone_No1": "7310-719107",
                               "Phone_No2": "",
                               "Latitude": "28.83528023776412",
                               "Longitude": "78.47202524542809"
                            }
                         ]
                      },
                      {
                         "City_Name": "Lalitpur",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4855",
                            "Franchisee_Name": "Kidzee Lalitpur",
                            "Operating_Status": "A",
                            "Address1": "Abhilasha House, Opp. Choka Bagh,",
                            "Address2": "Old Sagar Road",
                            "Place": "Mabeshi Bazar ,ABHILASHA HOUSE LALITPUR",
                            "City_Id": "1463",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4855@kidzee.com",
                            "Mobile_No": "9889072900",
                            "Phone_No1": "9889072900",
                            "Phone_No2": "",
                            "Latitude": "24.6841726",
                            "Longitude": "78.4310387"
                         }
                      },
                      {
                         "City_Name": "Mirzapur"
                      },
                      {
                         "City_Name": "Fatehpur"
                      },
                      {
                         "City_Name": "Sambhal"
                      },
                      {
                         "City_Name": "Bhadohi",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-4578",
                               "Franchisee_Name": "Kidzee Bhadohi",
                               "Operating_Status": "A",
                               "Address1": "128 / 129,",
                               "Address2": "",
                               "Place": "Bhadohi, 128/129. Jamunipur, (Behind Bhadohi Block",
                               "City_Id": "1512",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4578@kidzee.com",
                               "Mobile_No": "9839225493",
                               "Phone_No1": "9839225493",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-3591",
                               "Franchisee_Name": "KIDZEE GOPIGANJ",
                               "Operating_Status": "A",
                               "Address1": "Bade Shiv Mandir Marg,",
                               "Address2": "Mirzapur Road,",
                               "Place": "Gopiganj, Shiv Mandir Marg,",
                               "City_Id": "1512",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3591@kidzee.com",
                               "Mobile_No": "9839767316",
                               "Phone_No1": "9839767316",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Anpara",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2856",
                            "Franchisee_Name": "Kidzee Anpara",
                            "Operating_Status": "A",
                            "Address1": "Lanco Town Ship Bina Road,",
                            "Address2": "Dist-Sonebhadra,",
                            "Place": "Anpara, Lanco Town Ship",
                            "City_Id": "1534",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee2856@kidzee.com",
                            "Mobile_No": "8839381575",
                            "Phone_No1": "",
                            "Phone_No2": "",
                            "Latitude": "24.2064844426221",
                            "Longitude": "82.7744346857071"
                         }
                      },
                      {
                         "City_Name": "Bijnor"
                      },
                      {
                         "City_Name": "Bilaspur"
                      },
                      {
                         "City_Name": "Balrampur",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3244",
                            "Franchisee_Name": "KIDZEE BALRAMPUR",
                            "Operating_Status": "A",
                            "Address1": "Tulsi Park,",
                            "Address2": "",
                            "Place": "Balrampur, Tulsi Park Motisagar",
                            "City_Id": "1579",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3244@kidzee.com",
                            "Mobile_No": "9235518917",
                            "Phone_No1": "9235518917",
                            "Phone_No2": "",
                            "Latitude": "27.432526",
                            "Longitude": "82.183174"
                         }
                      },
                      {
                         "City_Name": "Hamirpur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-3321",
                               "Franchisee_Name": "Kidzee Rath Hamipur",
                               "Operating_Status": "A",
                               "Address1": "Near LIC Building,Rath,",
                               "Address2": "Sikanderpura,",
                               "Place": "Rath, Near LIC Building,",
                               "City_Id": "1592",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3321@kidzee.com",
                               "Mobile_No": "9451840000",
                               "Phone_No1": "05280223264",
                               "Phone_No2": "",
                               "Latitude": "25.591340219620093",
                               "Longitude": "79.57006875659181"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4681",
                               "Franchisee_Name": "Kidzee Hamirpur",
                               "Operating_Status": "A",
                               "Address1": "Jail Talab Road,",
                               "Address2": "Meher Puri,",
                               "Place": "Hamirpur, U.P. Sayed Wara Majhoopur Danda",
                               "City_Id": "1592",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4681@kidzee.com",
                               "Mobile_No": "9198462599",
                               "Phone_No1": "9198462599",
                               "Phone_No2": "",
                               "Latitude": "25.953366",
                               "Longitude": "80.152924"
                            }
                         ]
                      },
                      {
                         "City_Name": "Mahoba"
                      },
                      {
                         "City_Name": "Shamli"
                      },
                      {
                         "City_Name": "Baghpat "
                      },
                      {
                         "City_Name": "Etawah"
                      },
                      {
                         "City_Name": "Auraiya",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4658",
                            "Franchisee_Name": "Kidzee Auraiya",
                            "Operating_Status": "A",
                            "Address1": "Village - Kiratpur,",
                            "Address2": "Bela Road,",
                            "Place": "Bidhuna, Dist-Bidhuna",
                            "City_Id": "1827",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4658@kidzee.com",
                            "Mobile_No": "9045244079",
                            "Phone_No1": "9045244079",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Maharajganj",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5739",
                            "Franchisee_Name": "KIDZEE MAHRAJGANJ",
                            "Operating_Status": "A",
                            "Address1": "NICHLAUL ROAD",
                            "Address2": "DHANEWA -DHANEI",
                            "Place": "MAHRAJGANJ",
                            "City_Id": "1831",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5739@kidzee.com",
                            "Mobile_No": "9935902979",
                            "Phone_No1": "9935902979",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Kanpur Dehat"
                      },
                      {
                         "City_Name": "Kaushambi",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4376",
                            "Franchisee_Name": "Ramdayalpur Gauhani Kaju",
                            "Operating_Status": "A",
                            "Address1": "Pt. JNVNIC,",
                            "Address2": "Ramdayalpur,",
                            "Place": "Kaushambi, Gauhani Kaju,",
                            "City_Id": "1850",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4376@kidzee.com",
                            "Mobile_No": "8004200043",
                            "Phone_No1": "9451018877",
                            "Phone_No2": "",
                            "Latitude": "28.648382363596674",
                            "Longitude": "77.32398893965627"
                         }
                      },
                      {
                         "City_Name": "Jaunpur"
                      },
                      {
                         "City_Name": "Puranpur"
                      },
                      {
                         "City_Name": "Dhampur",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4195",
                            "Franchisee_Name": "Kidzee Dhampur",
                            "Operating_Status": "A",
                            "Address1": "Durga Public School,",
                            "Address2": "Durga Vihar Colony, Nagina Road,",
                            "Place": "Dhampur, Near- Durga Public School,",
                            "City_Id": "1920",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4195@kidzee.com",
                            "Mobile_No": "9927652789",
                            "Phone_No1": "9927652789",
                            "Phone_No2": "",
                            "Latitude": "29.30978",
                            "Longitude": "78.50620"
                         }
                      },
                      {
                         "City_Name": "Gajraula",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4061",
                            "Franchisee_Name": "Kidzee Gajraula",
                            "Operating_Status": "A",
                            "Address1": "Indra Chowk,",
                            "Address2": "Teachers Colony,",
                            "Place": "Gajraula, Near-Government Hospital,",
                            "City_Id": "1947",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4061@kidzee.com",
                            "Mobile_No": "8755191365",
                            "Phone_No1": "8755191365",
                            "Phone_No2": "",
                            "Latitude": "28.8351876",
                            "Longitude": "78.24475870"
                         }
                      },
                      {
                         "City_Name": "Khalilabad",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5564",
                            "Franchisee_Name": "Kidzee Khalilbad",
                            "Operating_Status": "A",
                            "Address1": "WARD-15, KHALILABAD",
                            "Address2": "BANJARIYA WEST,",
                            "Place": "SANT KABIR NAGAR ",
                            "City_Id": "1990",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5564@kidzee.com",
                            "Mobile_No": "9918800166",
                            "Phone_No1": "9918800166",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Prayagraj",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5580",
                            "Franchisee_Name": "KIDZEE CHAK MUNDERA",
                            "Operating_Status": "A",
                            "Address1": "32 /B /1,",
                            "Address2": "",
                            "Place": "CHAK MUNDERA",
                            "City_Id": "1991",
                            "State_Id": "35",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5580@kidzee.com",
                            "Mobile_No": "9140251240",
                            "Phone_No1": "9140251240",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      }
                   ]
                },
                {
                   "State_Name": "Uttarakhand / Uttaranchal",
                   "City": [
                      {
                         "City_Name": "Dehradun",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2554",
                               "Franchisee_Name": "Kidzee The Knowledge Tree Group Dheradun",
                               "Operating_Status": "A",
                               "Address1": "UNV Plaza,",
                               "Address2": "Thakurpur Road,",
                               "Place": "Premnagar, UNV Plaza,",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2554@kidzee.com",
                               "Mobile_No": "8899775877",
                               "Phone_No1": "9897741300",
                               "Phone_No2": "",
                               "Latitude": "30.335694",
                               "Longitude": "77.957307"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3541",
                               "Franchisee_Name": "Kidzee Shastri Nagar",
                               "Operating_Status": "A",
                               "Address1": "Lane No.1,",
                               "Address2": "",
                               "Place": "Shastri Nagar, Dehradun, Near-Ananda Hospital,",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3541@kidzee.com",
                               "Mobile_No": "9811821195",
                               "Phone_No1": "9811821195",
                               "Phone_No2": "",
                               "Latitude": "30.291158233622784",
                               "Longitude": "78.05868914047547",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1596007/11045/45b78b88_5c5f_485b_92c9_dc3c29e7877b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1596007/11045/acd92092_7ece_415a_ad45_a95158da406c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1596007/11045/5b432e4f_3fa9_4384_82bb_a33690cf2dd0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1596007/11045/ba2fec9a_a5e3_4a24_9f58_95986748aa24.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1596007/11045/b6aa9751_d7e1_4c0c_adfe_314d8fba2ad8.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-2621",
                               "Franchisee_Name": "KIDZEE VIKASNAGAR DEHRADUN",
                               "Operating_Status": "A",
                               "Address1": "NR. Militery Canteen,",
                               "Address2": "Kalsi Road,",
                               "Place": "Vikasnagar, Near Militery Canteen,",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2621@kidzee.com",
                               "Mobile_No": "8191000011",
                               "Phone_No1": "1360251333",
                               "Phone_No2": "",
                               "Latitude": "30.517767",
                               "Longitude": "77.841276"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4242",
                               "Franchisee_Name": "Kidzee Turner Road",
                               "Operating_Status": "A",
                               "Address1": "Turner Road,",
                               "Address2": "Lane No.C-20,",
                               "Place": "Turner Road, Near-Ram Mandir,",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4242@kidzee.com",
                               "Mobile_No": "9808152948",
                               "Phone_No1": "6398565600",
                               "Phone_No2": "",
                               "Latitude": "30.1641.8",
                               "Longitude": "78.0006.4"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4202",
                               "Franchisee_Name": "Kidzee Race Course Road",
                               "Operating_Status": "A",
                               "Address1": "290/217,",
                               "Address2": "Suri Chowck,",
                               "Place": "Race Course Road, Guru Gobind Sing Chowk,",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4202@kidzee.com",
                               "Mobile_No": "9105999906",
                               "Phone_No1": "9105999906",
                               "Phone_No2": "",
                               "Latitude": "30.309271",
                               "Longitude": "78.043182",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2176867/10908/882f128e_11d2_4d21_87b2_3415377973a1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2176867/10908/3637911c_5216_4d76_8be5_e437b61b60f6.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-4742",
                               "Franchisee_Name": "Kidzee Banjarawala Dehradun",
                               "Operating_Status": "A",
                               "Address1": "Madhur Vihar, Lane no.5",
                               "Address2": "Phase 1, Near Bangali Kothi, Banjarawala",
                               "Place": "Banjarawala ,Near Bangali Kothi",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4742@kidzee.com",
                               "Mobile_No": "9997380844",
                               "Phone_No1": "0135-265265",
                               "Phone_No2": "",
                               "Latitude": "30.2840910",
                               "Longitude": "78.0363200"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5025",
                               "Franchisee_Name": "Kidzee Bhaniyawalla",
                               "Operating_Status": "A",
                               "Address1": "Lane No-6, Preet Vihar,",
                               "Address2": "Kanharwala,",
                               "Place": "Bhaniyawala, Near- PNB Bank,",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5025@kidzee.com",
                               "Mobile_No": "9761147007",
                               "Phone_No1": "9761147007",
                               "Phone_No2": "",
                               "Latitude": "30.1841750",
                               "Longitude": "78.1500580"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5149",
                               "Franchisee_Name": "Kidzee Kidzee Durga Enclave",
                               "Operating_Status": "A",
                               "Address1": "Durga enclave, sahastradhara road",
                               "Address2": "opposite to Shri guru ram rai school",
                               "Place": "dehradun",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5149@kidzee.com",
                               "Mobile_No": "9917171701",
                               "Phone_No1": "9917171701",
                               "Phone_No2": "",
                               "Latitude": "30.342754",
                               "Longitude": "78.075867"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5786",
                               "Franchisee_Name": "KIDZEE LOWER NEHRUGRAM",
                               "Operating_Status": "A",
                               "Address1": "INDIAN INHABITANT",
                               "Address2": "RAJA JI KOTHI, NEAR CHURCH",
                               "Place": "LOWER NEHRUGRAM,",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5786@kidzee.com",
                               "Mobile_No": "9997775821",
                               "Phone_No1": "9997775821",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5718",
                               "Franchisee_Name": "Kidzee Doiwala",
                               "Operating_Status": "A",
                               "Address1": "Panchwati Colony, Near Sarda Wedding Point",
                               "Address2": "Hanshuwala,",
                               "Place": "Doiwala",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5718@kidzee.com",
                               "Mobile_No": "8868819280",
                               "Phone_No1": "7800659115",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5355",
                               "Franchisee_Name": "Kidzee Harrawala Nakroonda",
                               "Operating_Status": "A",
                               "Address1": "Shri Enclave ,Nakronda Road",
                               "Address2": "Khasra No.610, Balawallan",
                               "Place": "Harrawala  Nakronda Road",
                               "City_Id": "244",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5355@kidzee.com",
                               "Mobile_No": "8171152145",
                               "Phone_No1": "8171152145",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Gadarpur"
                      },
                      {
                         "City_Name": "Haridwar",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-3680",
                            "Franchisee_Name": "KIDZEE Kankhal",
                            "Operating_Status": "A",
                            "Address1": "71, 71 A, SHRI CHANDRANAGAR",
                            "Address2": "Behind Swayamwar Palace, Laksar Road, ",
                            "Place": "Kankhal , JAGJEETPUR ,Behind Swayamwar Palace",
                            "City_Id": "423",
                            "State_Id": "36",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3680@kidzee.com",
                            "Mobile_No": "9997682355",
                            "Phone_No1": "9997682355",
                            "Phone_No2": "",
                            "Latitude": "29.9196290",
                            "Longitude": "78.1401640"
                         }
                      },
                      {
                         "City_Name": "Kashipur"
                      },
                      {
                         "City_Name": "Kathgodam"
                      },
                      {
                         "City_Name": "Nainital"
                      },
                      {
                         "City_Name": "Rishikesh / Hrishikesh / Rushikesh / Hrushikesh",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-2596",
                            "Franchisee_Name": "KIDZEE RISHIKESH",
                            "Operating_Status": "A",
                            "Address1": "Veerpur Khurd,",
                            "Address2": "",
                            "Place": "Rishikesh, Near Seema Dental Collage,",
                            "City_Id": "841",
                            "State_Id": "36",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2596@kidzee.com",
                            "Mobile_No": "9760374237",
                            "Phone_No1": "01352450239",
                            "Phone_No2": "",
                            "Latitude": "30.06961",
                            "Longitude": "78.27776"
                         }
                      },
                      {
                         "City_Name": "Rudrapur"
                      },
                      {
                         "City_Name": "Kotdwar"
                      },
                      {
                         "City_Name": "Haldwani",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4921",
                            "Franchisee_Name": "KIDZEE MUKHANI",
                            "Operating_Status": "A",
                            "Address1": "Near Tubewell,",
                            "Address2": "Chhoti Mukhani,",
                            "Place": "Haldwani, Near Tubewell,",
                            "City_Id": "1162",
                            "State_Id": "36",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4921@kidzee.com",
                            "Mobile_No": "9119755976",
                            "Phone_No1": "9119755976",
                            "Phone_No2": "",
                            "Latitude": "29.212708",
                            "Longitude": "79.515202"
                         }
                      },
                      {
                         "City_Name": "Sitarganj",
                         "Franchisee": {
                            "Franchisee_Code": "N-A-M-1236",
                            "Franchisee_Name": "Kidzee-Sitarganj",
                            "Operating_Status": "A",
                            "Address1": "Opp.Reliance Petrol Pump, Khatima Road,",
                            "Address2": "",
                            "Place": "Udham Singh Nagar, Near-Sitar Hotel,",
                            "City_Id": "1177",
                            "State_Id": "36",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1236@kidzee.com",
                            "Mobile_No": "9012564207",
                            "Phone_No1": "8958005635",
                            "Phone_No2": "",
                            "Latitude": "28.923548",
                            "Longitude": "79.700721"
                         }
                      },
                      {
                         "City_Name": "Roorkee",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-C-2534",
                               "Franchisee_Name": "KIDZEE ROORKEE DHANDERA",
                               "Operating_Status": "A",
                               "Address1": "353. Village, Dhandera,",
                               "Address2": "Haridwar,",
                               "Place": "Roorkee, Dhandera, Defence Colony,",
                               "City_Id": "1181",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2534@kidzee.com",
                               "Mobile_No": "9990358183",
                               "Phone_No1": "9990358183",
                               "Phone_No2": "08923161486,09990358183",
                               "Latitude": "29.835141",
                               "Longitude": "77.898175"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4955",
                               "Franchisee_Name": "KIDZEE ROORKEE",
                               "Operating_Status": "A",
                               "Address1": "15/7, Adarsh Nagar, Near- Greenway School,",
                               "Address2": "",
                               "Place": "Roorkee, Opposite-SBI Bank,",
                               "City_Id": "1181",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4955@kidzee.com",
                               "Mobile_No": "7310646405",
                               "Phone_No1": "7310646405",
                               "Phone_No2": "",
                               "Latitude": "29.87749",
                               "Longitude": "77.89700"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5422",
                               "Franchisee_Name": "Kidzee Ramnagar",
                               "Operating_Status": "A",
                               "Address1": "HOUSE NUMBER- 311,",
                               "Address2": "LANE NUMBER 4,",
                               "Place": "RAMNAGAR",
                               "City_Id": "1181",
                               "State_Id": "36",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5422@kidzee.com",
                               "Mobile_No": "9927003007",
                               "Phone_No1": "9927003007",
                               "Phone_No2": "",
                               "Latitude": "29.873194",
                               "Longitude": "77.874111"
                            }
                         ]
                      },
                      {
                         "City_Name": "Mukteshwar"
                      },
                      {
                         "City_Name": "Bazpur",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4605",
                            "Franchisee_Name": "Kidzee Bazpur",
                            "Operating_Status": "A",
                            "Address1": "Vill- Bhonaislam Nagar,",
                            "Address2": "Tehsil- Bazpur,",
                            "Place": "Bhonaislam Nagar,",
                            "City_Id": "1367",
                            "State_Id": "36",
                            "Country_Id": "1",
                            "Email_Id": "kidzee4605@kidzee.com",
                            "Mobile_No": "7838220241",
                            "Phone_No1": "7838330896",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Pithoragarh",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-5011",
                            "Franchisee_Name": "Kidzee Pithoragarh",
                            "Operating_Status": "A",
                            "Address1": "Basanti Mahra,",
                            "Address2": "Jakhni Gupod,",
                            "Place": "Pithoragarh, Near-Guphor Mandir,",
                            "City_Id": "1569",
                            "State_Id": "36",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5011@kidzee.com",
                            "Mobile_No": "9560917858",
                            "Phone_No1": "9560-917858",
                            "Phone_No2": "",
                            "Latitude": "29.584186",
                            "Longitude": "80.220515",
                            "Images": [
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2706816/11041/b1dddc7d_ed76_497f_903f_4af7f91c418e.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2706816/11041/b9d0ffcb_8555_4f1c_929b_72a55e6ef1b4.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2706816/11041/ef6dc5b8_486b_426c_99fc_4873c377789d.jpg"
                               },
                               {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2706816/11041/8ff55c1d_39d8_4dd2_9ae6_aa7aa5dd8a44.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Udham Singh Nagar",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4577",
                            "Franchisee_Name": "Rashmi Bora",
                            "Operating_Status": "A",
                            "Address1": "Adarsh Colony,",
                            "Address2": "Bhood Mahdiya,",
                            "Place": "Khatima, Adarsh Colony,",
                            "City_Id": "1897",
                            "State_Id": "36",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4577@kidzee.com",
                            "Mobile_No": "8279951112",
                            "Phone_No1": "7830692472",
                            "Phone_No2": "",
                            "Latitude": "28.9215420",
                            "Longitude": "79.9582990"
                         }
                      },
                      {
                         "City_Name": "Ram nagar",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-C-4096",
                            "Franchisee_Name": "Kidzee Laxmi Vihar",
                            "Operating_Status": "A",
                            "Address1": "Ram Nagar,",
                            "Address2": "Raniket Road, Nainital,",
                            "Place": "Laxmi Vihar, Near Agarwal Pathology Lab,",
                            "City_Id": "1908",
                            "State_Id": "36",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4096@kidzee.com",
                            "Mobile_No": "7906162108",
                            "Phone_No1": "05947-251205",
                            "Phone_No2": "",
                            "Latitude": "29.392251",
                            "Longitude": "79.122257"
                         }
                      }
                   ]
                },
                {
                   "State_Name": "West Bengal",
                   "City": [
                      {
                         "City_Name": "Alipurduar"
                      },
                      {
                         "City_Name": "Asansol",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5226",
                            "Franchisee_Name": "Kidzee Asansol",
                            "Operating_Status": "A",
                            "Address1": "62, Nuruddin Road ",
                            "Address2": "Nuruddin Road, Burdawan Dist",
                            "Place": "Asansol ,Opp Nuruddin House",
                            "City_Id": "86",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5226@kidzee.com",
                            "Mobile_No": "9933093910",
                            "Phone_No1": "9933093910",
                            "Phone_No2": "",
                            "Latitude": "23.68138",
                            "Longitude": "86.98105"
                         }
                      },
                      {
                         "City_Name": "Bandel"
                      },
                      {
                         "City_Name": "Bankura",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-3892",
                               "Franchisee_Name": "Kidzee Pratapbagan",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor,",
                               "Address2": "Bankura P.O.P.S",
                               "Place": "Pratap bagan,Near AT Press",
                               "City_Id": "112",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3892@kidzee.com",
                               "Mobile_No": "8918665531",
                               "Phone_No1": "8918665531",
                               "Phone_No2": "",
                               "Latitude": "23.242436",
                               "Longitude": "87.063409"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5208",
                               "Franchisee_Name": "Kidzee Bankura",
                               "Operating_Status": "A",
                               "Address1": "Rajpara, Taragati Samanta Road,",
                               "Address2": "Ward No-22, Kenduadihi,",
                               "Place": "Bankura,",
                               "City_Id": "112",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5208@kidzee.com",
                               "Mobile_No": "9836008950",
                               "Phone_No1": "9836-008950",
                               "Phone_No2": "",
                               "Latitude": "23.24232",
                               "Longitude": "87.06345"
                            }
                         ]
                      },
                      {
                         "City_Name": "Barasat",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2935",
                               "Franchisee_Name": "Kidzee Barasat Kolkata",
                               "Operating_Status": "A",
                               "Address1": "Panchantra Housing Complex,132-Masidhati Road,",
                               "Address2": "Raja Ram Mohan Pally,",
                               "Place": "Taki Road ,Near Citizen Forum and Spencer's Shoppi",
                               "City_Id": "115",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2935@kidzee.com",
                               "Mobile_No": "9433196621",
                               "Phone_No1": "03325840667",
                               "Phone_No2": "",
                               "Latitude": "22.7145701",
                               "Longitude": "88.4933070"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4717",
                               "Franchisee_Name": "Kidzee Barrackpore Road",
                               "Operating_Status": "A",
                               "Address1": "Archana Apartment, Ronit Plaza,",
                               "Address2": "Barackpore Main Road, Chakravartipara,Barasat-P.O-",
                               "Place": "Nabapally, (Barrackpore Road), Near Tree House Pre",
                               "City_Id": "115",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4717@kidzee.com",
                               "Mobile_No": "6291998904",
                               "Phone_No1": "6291998904",
                               "Phone_No2": "",
                               "Latitude": "22.73141",
                               "Longitude": "88.47135"
                            }
                         ]
                      },
                      {
                         "City_Name": "Baharampur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-G-K-1135",
                               "Franchisee_Name": "Kidzee- Berhampore- Murshidabad-WB-Swarna Pradeep Academy",
                               "Operating_Status": "A",
                               "Address1": "Swarna Pradeep Academy,",
                               "Address2": "Beside-Yamaha Showroom,",
                               "Place": "Murshidabad, Kidzee Gorabazar,",
                               "City_Id": "135",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1135@kidzee.com",
                               "Mobile_No": "9933004920",
                               "Phone_No1": "3482256466",
                               "Phone_No2": "",
                               "Latitude": "24.095696882841782",
                               "Longitude": "88.28017899999998"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4248",
                               "Franchisee_Name": "Kidzee Indraprastha, Berhampore",
                               "Operating_Status": "A",
                               "Address1": "102, Amar Chakraborty Road,",
                               "Address2": "",
                               "Place": "Indraprastha, P.O-Berhampore, Beside Bagha Jatin C",
                               "City_Id": "135",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4248@kidzee.com",
                               "Mobile_No": "8768754155",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "24.1058274",
                               "Longitude": "88.2594835"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5200",
                               "Franchisee_Name": "Kidzee Chuanpore Berhampore",
                               "Operating_Status": "A",
                               "Address1": "Chuanpore, opposite Vidyaniketan Girls High School ",
                               "Address2": "Dist-Murshidabad",
                               "Place": "Chuanpore, near Vidyaniketan Girls High School",
                               "City_Id": "135",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5200@kidzee.com",
                               "Mobile_No": "8670866394",
                               "Phone_No1": "8670866394",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Bardhaman",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-3288",
                               "Franchisee_Name": "KIDZEE ULLASH",
                               "Operating_Status": "A",
                               "Address1": "Purbasha Bus Stand,",
                               "Address2": "Ullas Alisha,",
                               "Place": "Burdwan, Near Purbasha Bus Stand",
                               "City_Id": "190",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3288@kidzee.com",
                               "Mobile_No": "9647857999",
                               "Phone_No1": "9647857999",
                               "Phone_No2": "",
                               "Latitude": "23.217591",
                               "Longitude": "87.892680"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2768",
                               "Franchisee_Name": "KIDZEE PANAGARH",
                               "Operating_Status": "A",
                               "Address1": "Near-Nera Shibtala, J.L.No-85, Khaitan No.1408, Dag-No.3091,",
                               "Address2": "P.O.Panagarh Bazar,",
                               "Place": "Panagarh, Near-Nera Shibtala,",
                               "City_Id": "190",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2768@kidzee.com",
                               "Mobile_No": "7908784470",
                               "Phone_No1": "9333232788",
                               "Phone_No2": "",
                               "Latitude": "22.826988",
                               "Longitude": "88.379015"
                            },
                            {
                               "Franchisee_Code": "E-U-C-899",
                               "Franchisee_Name": "KIDZEE-Punjabi Para/- Jack and Jill II",
                               "Operating_Status": "A",
                               "Address1": "Kalibazar, Near-Birhata Kalitala,",
                               "Address2": "Opp-Netaji Sangha Club,",
                               "Place": "Punjabi Para, Near-Kachari Road,",
                               "City_Id": "190",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee899@kidzee.com",
                               "Mobile_No": "9775301150",
                               "Phone_No1": "9735111055",
                               "Phone_No2": "",
                               "Latitude": "23.232421",
                               "Longitude": "87.861479"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2720",
                               "Franchisee_Name": "Kidzee Golapbag Bardhaman",
                               "Operating_Status": "A",
                               "Address1": "Library Building of Zoological Association,",
                               "Address2": "Blue Bubbles Saratpally, PO: Rajbati Barhadman,",
                               "Place": "Golapbag, Near-Golapbag,",
                               "City_Id": "190",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2720@kidzee.com",
                               "Mobile_No": "9564455000",
                               "Phone_No1": "9609501345",
                               "Phone_No2": "",
                               "Latitude": "23.232421",
                               "Longitude": "87.861479",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2720/31/47b5bf4d_4b6b_46ad_b6c1_4c0f253dbdef.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-R-C-4917",
                               "Franchisee_Name": "Kidzee Boronilpur",
                               "Operating_Status": "A",
                               "Address1": "Intvata Road,",
                               "Address2": "Ground Floor,",
                               "Place": "Boronilpur, Nilpur,",
                               "City_Id": "190",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4917@kidzee.com",
                               "Mobile_No": "9732374015",
                               "Phone_No1": "9732374015",
                               "Phone_No2": "",
                               "Latitude": "23.224243",
                               "Longitude": "87.863225"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5458",
                               "Franchisee_Name": "Kidzee Burdwan",
                               "Operating_Status": "A",
                               "Address1": "Bajepratappur , ",
                               "Address2": "SBI building ",
                               "Place": "Burdwan",
                               "City_Id": "190",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5458@kidzee.com",
                               "Mobile_No": "9564474554",
                               "Phone_No1": "9564474554",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5771",
                               "Franchisee_Name": "Kidzee Kalna",
                               "Operating_Status": "A",
                               "Address1": "Annam Bhaban,",
                               "Address2": "Baidyapur More, STKK Road,",
                               "Place": "Kalan",
                               "City_Id": "190",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5771@kidzee.com",
                               "Mobile_No": "9088380907",
                               "Phone_No1": "9088380907",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Chandannagar",
                         "Franchisee": {
                            "Franchisee_Code": "E-S-C-1454",
                            "Franchisee_Name": "Kids Paradise",
                            "Operating_Status": "A",
                            "Address1": "Kabi Bharat Chandra Road Hatkhola,",
                            "Address2": " Chandannagar Kidzee",
                            "Place": "Hatkhola,",
                            "City_Id": "196",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1454@kidzee.com",
                            "Mobile_No": "9874624520",
                            "Phone_No1": "26838080",
                            "Phone_No2": "",
                            "Latitude": "22.599653",
                            "Longitude": "88.360853"
                         }
                      },
                      {
                         "City_Name": "Chinsurah",
                         "Franchisee": {
                            "Franchisee_Code": "E-S-C-2088",
                            "Franchisee_Name": "Kidzee Chinsurah",
                            "Operating_Status": "A",
                            "Address1": "Ram mandir Chinsurah Station Road",
                            "Address2": "P O Chinsurah Dist  Hooghly",
                            "Place": "Dist Hooghly, Near Rammandir",
                            "City_Id": "212",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2088@kidzee.com",
                            "Mobile_No": "9339142264",
                            "Phone_No1": "9339142264",
                            "Phone_No2": "",
                            "Latitude": "22.890045",
                            "Longitude": "88.375851"
                         }
                      },
                      {
                         "City_Name": "Cooch behar"
                      },
                      {
                         "City_Name": "Darjeeling",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-G-K-890",
                               "Franchisee_Name": "KIDZEE-BAGDOGRA- Darjelling- Little,s, World",
                               "Operating_Status": "A",
                               "Address1": "C/O-Arun Kanti Majumdar,",
                               "Address2": "",
                               "Place": "Bagdogra, Sukanta Pally,",
                               "City_Id": "236",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee890@kidzee.com",
                               "Mobile_No": "9933983699",
                               "Phone_No1": "9434352092",
                               "Phone_No2": "",
                               "Latitude": "26.699424",
                               "Longitude": "88.314328"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2741",
                               "Franchisee_Name": "Kidzee Salbari  Darjeeling",
                               "Operating_Status": "A",
                               "Address1": "Darjeeling Road,",
                               "Address2": "P.O-Sukna, PS Pradhannagar,",
                               "Place": "Salbari Metahebari, Sukna,",
                               "City_Id": "236",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2741@kidzee.com",
                               "Mobile_No": "9749780172",
                               "Phone_No1": "9749780172",
                               "Phone_No2": "",
                               "Latitude": "27.036007",
                               "Longitude": "88.262675"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4268",
                               "Franchisee_Name": "Kidzee Debidanga",
                               "Operating_Status": "A",
                               "Address1": "Debidanga Bazar, Ps.Pradhan Nagar,",
                               "Address2": "Kalabari, Siliguri,",
                               "Place": "Debidanga Opp-Debidanga Auto Stand,",
                               "City_Id": "236",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4268@Kidzee.com",
                               "Mobile_No": "9749780172",
                               "Phone_No1": "8250279077",
                               "Phone_No2": "",
                               "Latitude": "26.76320",
                               "Longitude": "88.42708"
                            }
                         ]
                      },
                      {
                         "City_Name": "Hooghly",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2482",
                               "Franchisee_Name": "Kidzee Rishra",
                               "Operating_Status": "A",
                               "Address1": "Bangur Park, Ganpati Appartment,",
                               "Address2": "30/A/31, Dr. P.T.Laha Street,",
                               "Place": "Rishra, Near-Bangur Park,",
                               "City_Id": "283",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2482@kidzee.com",
                               "Mobile_No": "9681119724",
                               "Phone_No1": "9681286947",
                               "Phone_No2": "",
                               "Latitude": "22.723672669444014",
                               "Longitude": "88.27354512918089"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2483",
                               "Franchisee_Name": "Kidzee Bhadreswar",
                               "Operating_Status": "A",
                               "Address1": "27, G.T. Road,",
                               "Address2": "P.O. & P.S.",
                               "Place": "Bhadreswar, G.T. Road,",
                               "City_Id": "283",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2483@kidzee.com",
                               "Mobile_No": "9874624520",
                               "Phone_No1": "033-26333389",
                               "Phone_No2": "",
                               "Latitude": "22.8202° N, 88.3530° E",
                               "Longitude": "88.3530° E",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/878897/10640/5f3666cf_8c34_4d20_a0d2_42b717effa44.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/878897/10640/5af02fe2_a6d0_46ca_856a_88728f180386.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4904",
                               "Franchisee_Name": "Kidzee Kalipur",
                               "Operating_Status": "A",
                               "Address1": "Kalipur Das Para",
                               "Address2": "Garalgachha",
                               "Place": "Garalgachha ,Kalipur sporting club",
                               "City_Id": "283",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4904@kidzee.com",
                               "Mobile_No": "9062619266",
                               "Phone_No1": "9062619266",
                               "Phone_No2": "",
                               "Latitude": "22.6841490",
                               "Longitude": "88.27666310"
                            }
                         ]
                      },
                      {
                         "City_Name": "Durgachak"
                      },
                      {
                         "City_Name": "Durgapur",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2644",
                               "Franchisee_Name": "Kidzee Durgapur",
                               "Operating_Status": "A",
                               "Address1": "A-102, Sahid Sukumar Banerjee Sarani,",
                               "Address2": "Bidhannagar,",
                               "Place": "Durgapur, Near- Sector-2- C,",
                               "City_Id": "305",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2644@kidzee.com",
                               "Mobile_No": "9804121213",
                               "Phone_No1": "9804121213",
                               "Phone_No2": "",
                               "Latitude": "23.520444",
                               "Longitude": "87.311923"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2803",
                               "Franchisee_Name": "Kidzee Srinagar Pally Durgapur",
                               "Operating_Status": "A",
                               "Address1": "Srinagar Pally, Plot No-1657,",
                               "Address2": "54, Foot, D.G.P-13,",
                               "Place": "Near-Srinagar Pally",
                               "City_Id": "305",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2803@kidzee.com",
                               "Mobile_No": "8759023335",
                               "Phone_No1": "7029539704",
                               "Phone_No2": "",
                               "Latitude": "23.558423",
                               "Longitude": "87.289663"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5428",
                               "Franchisee_Name": "Kidzee Durgapur",
                               "Operating_Status": "A",
                               "Address1": "6/21, Alauddin Khan Bithi,",
                               "Address2": "Non -Company housing Soc, City Center, Landmark: SBI",
                               "Place": "Durgapur ",
                               "City_Id": "305",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5428@kidzee.com",
                               "Mobile_No": "9002807807",
                               "Phone_No1": "9903313029",
                               "Phone_No2": "",
                               "Latitude": "23.545694",
                               "Longitude": "87.299750"
                            }
                         ]
                      },
                      {
                         "City_Name": "Phulbari / Fulbari"
                      },
                      {
                         "City_Name": "Haldia",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-U-819",
                               "Franchisee_Name": "KIDZEE-HALDIA TOWNSHIP- Nestling",
                               "Operating_Status": "A",
                               "Address1": "Plot No-59,",
                               "Address2": "Vill-",
                               "Place": "Brajanath Chak, Near Aheli Market Complex,",
                               "City_Id": "418",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee819@kidzee.com",
                               "Mobile_No": "9732768662",
                               "Phone_No1": "03224267539",
                               "Phone_No2": "",
                               "Latitude": "22.066674",
                               "Longitude": "88.069812"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3006",
                               "Franchisee_Name": "Kidzee Milan Haldia",
                               "Operating_Status": "A",
                               "Address1": "Milan, Nibedita Colony Bhabanipur Colony,",
                               "Address2": "Sector-9, Plot No-310, Debhog, Purba Medinipur,",
                               "Place": "S.A. Roadlines Milan, Near S.A. Roadlines,",
                               "City_Id": "418",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3006@kidzee.com",
                               "Mobile_No": "9475886727",
                               "Phone_No1": "9474759402",
                               "Phone_No2": "",
                               "Latitude": "22.046604",
                               "Longitude": "88.05709"
                            }
                         ]
                      },
                      {
                         "City_Name": "Howrah",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-B-S-261",
                               "Franchisee_Name": "Rainbow Academy",
                               "Operating_Status": "A",
                               "Address1": "38/2 Kalabagan Lane,",
                               "Address2": "Dumurjala Howrah Indoor Stadium",
                               "Place": "Santragachi Natunrasta More ,Opp : Howrah Dumurjal",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee261@kidzee.com",
                               "Mobile_No": "9051472572",
                               "Phone_No1": "9051472571",
                               "Phone_No2": "",
                               "Latitude": "22.595769",
                               "Longitude": "88.263639"
                            },
                            {
                               "Franchisee_Code": "E-U-M-508",
                               "Franchisee_Name": "Kidzee- G.T. Road- Howrah-VINEET INFOTECH",
                               "Operating_Status": "A",
                               "Address1": "506/1,1st Floor,",
                               "Address2": "",
                               "Place": "G T Road, Above Hero Honda Showroom",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee508@kidzee.com",
                               "Mobile_No": "9836789497",
                               "Phone_No1": "033-26382800",
                               "Phone_No2": "",
                               "Latitude": "22.576117",
                               "Longitude": "88.329954",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/353/11023/5163d7ef_ddd3_4725_ad21_0f1d0b2f50a5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/353/11023/5bdf5c00_fd7e_4de1_98a2_a7f11dfa6291.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/353/11023/b041688b_ff42_4c2d_8dfb_e9a733a44a49.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/353/11023/2cc1a017_f4ff_4104_9f8f_3347a0e5e281.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/353/11023/cd613020_6ff3_4b23_9e01_6dd699913de1.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3419",
                               "Franchisee_Name": "Kidzee Liluah",
                               "Operating_Status": "A",
                               "Address1": "266/E/1, G T Road,",
                               "Address2": "",
                               "Place": "Liluah, Near-MCKV School,",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3419@kidzee.com",
                               "Mobile_No": "9831118255",
                               "Phone_No1": "9831031436",
                               "Phone_No2": "",
                               "Latitude": "22.608988",
                               "Longitude": "88.322091"
                            },
                            {
                               "Franchisee_Code": "E-B-U-932",
                               "Franchisee_Name": "KIDZEE-BELILIOUS ROAD-HOWRAH- Angels Kid",
                               "Operating_Status": "A",
                               "Address1": "Sangita Das, 206/3/1 - Kidzee  Belilus Road,",
                               "Address2": "",
                               "Place": "Belilious Road, Belilus Road,",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee932@kidzee.com",
                               "Mobile_No": "9830171836",
                               "Phone_No1": "1234-00000000",
                               "Phone_No2": "",
                               "Latitude": "22.592284949743455",
                               "Longitude": "88.32696841265874"
                            },
                            {
                               "Franchisee_Code": "E-U-U-1012",
                               "Franchisee_Name": "Kidzee- Andul-Wonder Kids",
                               "Operating_Status": "A",
                               "Address1": "Pramila Bhavan, P.C.Roy Road,",
                               "Address2": "Duillya, P.S. Sankrail,",
                               "Place": "Andul, Pramila Bhavan, Andul Station Road",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1012@kidzee.com",
                               "Mobile_No": "8017708981",
                               "Phone_No1": "03335594291",
                               "Phone_No2": "",
                               "Latitude": "22.586373",
                               "Longitude": "88.249872"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2871",
                               "Franchisee_Name": "Kidzee Ranihati, Howrah",
                               "Operating_Status": "A",
                               "Address1": "Ananya Apartment  Village & Post-Nabagharaati,",
                               "Address2": "P.S.Sankrail,",
                               "Place": "Ranihati, Opp- Mahakali Mandir, Nabghara, OR Adjac",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2871@kidzee.com",
                               "Mobile_No": "9836538404",
                               "Phone_No1": "03326616202",
                               "Phone_No2": "",
                               "Latitude": "22.560772",
                               "Longitude": "88.159523",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1215723/10453/aa0eaa10_33c5_493b_9891_ecd40eef1377.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1215723/10453/06cf91e3_30dd_46dc_9156_39aeda6a8b98.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1215723/10453/8241ed22_603d_49ea_bd73_a05b2dd44dd9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1215723/10453/e68407fe_5513_4b69_874e_fd747d2ee129.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-G-K-1106",
                               "Franchisee_Name": "Kidzee- Bally-Howrah-West Bengal- Modern Kids House",
                               "Operating_Status": "A",
                               "Address1": "83-G T Road,",
                               "Address2": "Opp.Of Bally Muncipality,",
                               "Place": "Bally, Near-Sandipani Book Store,",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1106@kidzee.com",
                               "Mobile_No": "9831433676",
                               "Phone_No1": "03326466969",
                               "Phone_No2": "",
                               "Latitude": "22.636187",
                               "Longitude": "88.35146"
                            },
                            {
                               "Franchisee_Code": "E-G-K-1136",
                               "Franchisee_Name": "Kidzee- Jagacha- WB- Kids Planet",
                               "Operating_Status": "A",
                               "Address1": "92, Sundarpara Road",
                               "Address2": "P.O. GIP Colony,",
                               "Place": "Police station -Jagacha,",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1136@kidzee.com",
                               "Mobile_No": "9674116477",
                               "Phone_No1": "8017708982",
                               "Phone_No2": "",
                               "Latitude": "22.58694765734746",
                               "Longitude": "88.28225675267026"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2719",
                               "Franchisee_Name": "Kidzee Benaras Road, Belgachia",
                               "Operating_Status": "A",
                               "Address1": "P-261/1, Benaras Road,",
                               "Address2": "Belgachia PS-Liluah,",
                               "Place": "Benaras Road, Netajigarh Post Office,",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2719@kidzee.com",
                               "Mobile_No": "9830008584",
                               "Phone_No1": "9830008584",
                               "Phone_No2": "",
                               "Latitude": "22.604747",
                               "Longitude": "88.320878"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3631",
                               "Franchisee_Name": "Kidzee Danesh Sheikh lane",
                               "Operating_Status": "A",
                               "Address1": "Andul Road Ground Floor",
                               "Address2": "P S-A.J.C.Bose B Garden",
                               "Place": "Danesh Sheikh lane ,Near Danesh Sheikh Lane Bus st",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3631@kidzee.com",
                               "Mobile_No": "9051627262",
                               "Phone_No1": "9038027262",
                               "Phone_No2": "",
                               "Latitude": "22.564082",
                               "Longitude": "88.296133"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3649",
                               "Franchisee_Name": "Kidzee Bagnan",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Plot No-64,",
                               "Address2": "Mouza-Khalore,",
                               "Place": "Bagnan, N.D.Block, Bagnan",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3649@kidzee.com",
                               "Mobile_No": "9735221061",
                               "Phone_No1": "09875-356471",
                               "Phone_No2": "",
                               "Latitude": "22.4671N",
                               "Longitude": "87.9702E"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3649",
                               "Franchisee_Name": "Kidzee Bagnan",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Plot No-64,",
                               "Address2": "Mouza-Khalore,",
                               "Place": "Bagnan, N.D.Block, Bagnan",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3649@kidzee.com",
                               "Mobile_No": "9735221061",
                               "Phone_No1": "09875-356471",
                               "Phone_No2": "",
                               "Latitude": "22.4671 N",
                               "Longitude": "87.9702 E"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4384",
                               "Franchisee_Name": "Kidzee Mandirtala",
                               "Operating_Status": "A",
                               "Address1": "90/3/1,",
                               "Address2": "Kshetra Banerjee Lane,",
                               "Place": "Mandirtala ,Near Nabanna back gate",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4384@Kidzee.com",
                               "Mobile_No": "9051627262",
                               "Phone_No1": "6291671714",
                               "Phone_No2": "",
                               "Latitude": "22.566667",
                               "Longitude": "88.315833"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3648",
                               "Franchisee_Name": "Kidzee Jagadishpur",
                               "Operating_Status": "A",
                               "Address1": "Jagadishpur",
                               "Address2": "Near Manoram Cinema, Post Jagadishpurhat",
                               "Place": "Liluah Dist ,Near Manorama Cinema Hall",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3648@kidzee.com",
                               "Mobile_No": "8017005515",
                               "Phone_No1": "8583960277",
                               "Phone_No2": "",
                               "Latitude": "22.64552",
                               "Longitude": "88.28407"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5356",
                               "Franchisee_Name": "Kidzee Belur",
                               "Operating_Status": "A",
                               "Address1": "6/6 Shyam Sundar Ghosh lane ",
                               "Address2": "Besides APC Towers , Dist Howrah",
                               "Place": "Belur",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1136@kidzee.com",
                               "Mobile_No": "9674116477",
                               "Phone_No1": "8100303461",
                               "Phone_No2": "",
                               "Latitude": "22.638680",
                               "Longitude": "88.348360"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5295",
                               "Franchisee_Name": "Kidzee Shyampur",
                               "Operating_Status": "A",
                               "Address1": "Vill- Shrikole;Po- Deoly",
                               "Address2": "Plot No-1209,",
                               "Place": "Shyampur ",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5295@kidzee.com",
                               "Mobile_No": "9083193084",
                               "Phone_No1": "9083193084",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5143",
                               "Franchisee_Name": "Kidzee Domjur",
                               "Operating_Status": "A",
                               "Address1": "Kantila,",
                               "Address2": "Nibra,",
                               "Place": "Domjur,",
                               "City_Id": "431",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5143@kidzee.com",
                               "Mobile_No": "9875514900",
                               "Phone_No1": "9875514900",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Jalpaiguri",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-U-429",
                               "Franchisee_Name": "Kidzee- Jalpaiguri- WB-S.N. FOUNDATION",
                               "Operating_Status": "A",
                               "Address1": "A.P.D.COURT,",
                               "Address2": "Jalpaiguri,",
                               "Place": "Alipurdwar, Bidhan Pally, Near Dooars Furniture",
                               "City_Id": "481",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee429@kidzee.com",
                               "Mobile_No": "9474873727",
                               "Phone_No1": "9474873727",
                               "Phone_No2": "",
                               "Latitude": "26.491889",
                               "Longitude": "89.527103"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3969",
                               "Franchisee_Name": "Kidzee Dhupguri",
                               "Operating_Status": "A",
                               "Address1": "Ward No. 13,",
                               "Address2": "Rabindra nagar,  (Near Dakhinarayan Club)",
                               "Place": "Dhupguri ,Near Dakhinarayan Club",
                               "City_Id": "481",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3969@kidzee.com",
                               "Mobile_No": "9647092930",
                               "Phone_No1": "9647092930",
                               "Phone_No2": "",
                               "Latitude": "26.581754",
                               "Longitude": "89.006783"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5600",
                               "Franchisee_Name": "Kidzee Dhapganj",
                               "Operating_Status": "A",
                               "Address1": "VILL. KADAMTALA, POST. DHAPGANJ, P.S. KOTWALI, BLOCK. SADAR, MOUZA. MANDALGHAT",
                               "Address2": "Sadar Block, Mouza-Mandalghat",
                               "Place": "Dhapganj",
                               "City_Id": "481",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5600@kidzee.com",
                               "Mobile_No": "9064159033",
                               "Phone_No1": "9064159033",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Kalimpong"
                      },
                      {
                         "City_Name": "Kalyani",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-C-U-1124",
                               "Franchisee_Name": "Kidzee- Kalyani (Block A)- West Bengal- Pine Mount Institution",
                               "Operating_Status": "A",
                               "Address1": "A- 9/51, Kalyani (A-Block),",
                               "Address2": "Goal Park On The Main Road,",
                               "Place": "Dist. Nadia, Opp.KDMA Flat, Goal Park,",
                               "City_Id": "531",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1124@kidzee.com",
                               "Mobile_No": "9831411108",
                               "Phone_No1": "9831411108",
                               "Phone_No2": "",
                               "Latitude": "22.967726",
                               "Longitude": "88.462794"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5399",
                               "Franchisee_Name": "Kidzee Kalyani B Block",
                               "Operating_Status": "A",
                               "Address1": "B -11/12 , Beside Kalyani  Municipality ,",
                               "Address2": "Nadia",
                               "Place": "Kalyani B Block",
                               "City_Id": "531",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2348@kidzee.com",
                               "Mobile_No": "9830467127",
                               "Phone_No1": "9830467127",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Kanchrapara",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4979",
                            "Franchisee_Name": "CLEAR SIGHT",
                            "Operating_Status": "A",
                            "Address1": "104/G-Bagh Station Road,",
                            "Address2": "Ward No-1,",
                            "Place": "Halisahar, Near-Bagh More,",
                            "City_Id": "533",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4979@kidzee.com",
                            "Mobile_No": "9836840525",
                            "Phone_No1": "9836840525",
                            "Phone_No2": "",
                            "Latitude": "22.95214",
                            "Longitude": "88.42340"
                         }
                      },
                      {
                         "City_Name": "Kolkata",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-A-M-53",
                               "Franchisee_Name": "Kidzee- Tollygunge- Kolkata-One Step Up",
                               "Operating_Status": "A",
                               "Address1": "111/1, N.S.C. Bose Road,",
                               "Address2": "(Near Ranikuthi)",
                               "Place": "Tollygunge ,Near Ranikuthi",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee53@kidzee.com",
                               "Mobile_No": "9903307939",
                               "Phone_No1": "9903307939",
                               "Phone_No2": "09748740282",
                               "Latitude": "22.4920896332845",
                               "Longitude": "88.3483225107193",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/281cc286_f557_4b36_976d_ac9de4e8cd51.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/4478b2b4_d9e9_472e_927d_1c263cd46fbd.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/8be79d39_dad2_4bef_85e8_4664854ca90e.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/cc36b497_7b25_486b_9f11_49d216c8c2f5.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/53e08624_4f56_43be_ad4a_dde9c8e88191.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/01df361a_674c_44ab_b932_33d5cbf8c064.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/cd5a7277_7cf2_463f_91c8_cdf61534e7e3.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/cec2907a_f423_41d7_832a_bb6a38c39815.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/42b5aeb9_31d9_427c_be22_ce9590065798.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/7d0e2489_7080_4b1d_ad69_ba02ac45339f.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/17/e8b14a5d_6e3c_4072_87ce_192efbda60a1.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/e1033838_53c2_41da_b353_ff165c4e564c.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/63cffb55_3aac_4c87_bc6d_31258e2740a5.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/ff505a96_d0eb_44e0_9d2d_dbbdbba8e4d2.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/54c009f4_d4fb_43f8_9199_2593cfeec3da.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/679c2e7f_a16e_4973_8d7a_3930c4128284.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/d38fac69_a32b_4d86_8438_eef7bfd4e946.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/589754bf_3d2a_4967_b913_e6e0b97bb3ba.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/1e854acc_09bb_405f_853e_59e6da869381.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/12385147_92c0_4081_8776_07b847db5273.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/cca9767c_9895_40c9_bf7c_ffcfacf45b50.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/8c0bccdc_e462_4de3_b984_0ffe47aa1072.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/53/18/b8369eca_81ba_4880_9cfd_9b14dde1958c.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/10/358/99c2d0f1_5304_4e78_acce_da55618bd604.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/10/358/cc163058_3484_4546_995b_7eb28e1e5f27.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/10/358/e175926b_db58_4577_876a_411ebaa131fc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/10/358/4efc1ed2_464e_46b2_a46e_3d666583d70e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/10/358/0f8be1a0_d7a1_4090_8705_59c282c80226.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/10/358/907af1c0_0fe5_4276_888d_7aab0fe53717.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-B-M-226",
                               "Franchisee_Name": "FIRST STEP",
                               "Operating_Status": "A",
                               "Address1": "P-14, Ground Floor,",
                               "Address2": "Garia Park, Opp. Baroda avenue kali temple",
                               "Place": "Garia Region ,Opp. Baroda avenue kali temple",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee226@kidzee.com",
                               "Mobile_No": "9830276357",
                               "Phone_No1": "9830276357",
                               "Phone_No2": "",
                               "Latitude": "22.469281",
                               "Longitude": "88.378408"
                            },
                            {
                               "Franchisee_Code": "E-B-S-396",
                               "Franchisee_Name": "Kidzee- Sakher Bazar- Kolkata-Learning Ladder",
                               "Operating_Status": "A",
                               "Address1": "230/4, Santosh Roy Road,",
                               "Address2": "",
                               "Place": "Sakher Bazar, Near Barisha Club",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee396@kidzee.com",
                               "Mobile_No": "9830814414",
                               "Phone_No1": "9830814414",
                               "Phone_No2": "",
                               "Latitude": "22.481114",
                               "Longitude": "88.313158"
                            },
                            {
                               "Franchisee_Code": "E-B-S-635",
                               "Franchisee_Name": "Kidzee, Bosepukur, kasba",
                               "Operating_Status": "A",
                               "Address1": "37/1,",
                               "Address2": "Dharmatala Road,",
                               "Place": "Bosepukur, Kasba ,Opp Bosepukur Sitala Mandir,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee635@kidzee.com",
                               "Mobile_No": "9903091803",
                               "Phone_No1": "9831699998",
                               "Phone_No2": "",
                               "Latitude": "22.519658",
                               "Longitude": "88.388271"
                            },
                            {
                               "Franchisee_Code": "E-B-U-731",
                               "Franchisee_Name": "Kidzee- Survey Park- Crazy Kidss",
                               "Operating_Status": "A",
                               "Address1": "B-232",
                               "Address2": "Survey Park, Near Ajay Nagar",
                               "Place": "Santoshpur ",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee731@kidzee.com",
                               "Mobile_No": "9073352313",
                               "Phone_No1": "9073353199",
                               "Phone_No2": "",
                               "Latitude": "22.491873673140013",
                               "Longitude": "88.3934478350792"
                            },
                            {
                               "Franchisee_Code": "E-B-S-338",
                               "Franchisee_Name": "KIDZEE_KARAYA-FIRST FRIENDS",
                               "Operating_Status": "A",
                               "Address1": "Eden Terrace, 10-Tarak Dutta Road,",
                               "Address2": "Near-Karaya Police Station,",
                               "Place": "Beck Bagan, Karaya Police Station",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee338@kidzee.com",
                               "Mobile_No": "9831275774",
                               "Phone_No1": "30539333",
                               "Phone_No2": "",
                               "Latitude": "22.538563",
                               "Longitude": "88.361571"
                            },
                            {
                               "Franchisee_Code": "E-B-S-253",
                               "Franchisee_Name": "Kidzee-Tender Hearts",
                               "Operating_Status": "A",
                               "Address1": "26H/14,",
                               "Address2": "Radha Madhab Dutta Garden Lane,",
                               "Place": "Beleghata, Subhash Sarovar ,Near Subhash Sarabor",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee253@kidzee.com",
                               "Mobile_No": "9830031844",
                               "Phone_No1": "9123-790416",
                               "Phone_No2": "",
                               "Latitude": "22.567023",
                               "Longitude": "88.404208"
                            },
                            {
                               "Franchisee_Code": "E-B-S-450",
                               "Franchisee_Name": "Kankurgachi",
                               "Operating_Status": "A",
                               "Address1": "P-90, CIT Road,",
                               "Address2": "Scheme- 6, MS,",
                               "Place": "Kankugrachi,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee450@kidzee.com",
                               "Mobile_No": "9830012311",
                               "Phone_No1": "9830012311",
                               "Phone_No2": "",
                               "Latitude": "22.583115",
                               "Longitude": "88.391494"
                            },
                            {
                               "Franchisee_Code": "E-U-M-444",
                               "Franchisee_Name": "KIDZEE_PANIHATI / TODDLERS HOME",
                               "Operating_Status": "A",
                               "Address1": "1/1/A, Thakur Nitya Gopal Road,",
                               "Address2": "Thakur Nitya Gopal Road,",
                               "Place": "Khardah, Near-Panihati Post Office,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee444@kidzee.com",
                               "Mobile_No": "9674459410",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "22.701901655485763",
                               "Longitude": "88.35143707276848"
                            },
                            {
                               "Franchisee_Code": "E-A-M-1122",
                               "Franchisee_Name": "Kidzee- Deshapriya Park-Kolkata- One Step Up",
                               "Operating_Status": "A",
                               "Address1": "17,Park Side Road,",
                               "Address2": "Near-Rashbehari-Lansdowne Crossing,",
                               "Place": "Deshapriya Park, Opposite-D.K.S.Club,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1122@kidzee.com",
                               "Mobile_No": "9748005306",
                               "Phone_No1": "9748005306",
                               "Phone_No2": "",
                               "Latitude": "22.518057",
                               "Longitude": "88.351138"
                            },
                            {
                               "Franchisee_Code": "E-C-U-1105",
                               "Franchisee_Name": "Kidzee- Sakuntala Park",
                               "Operating_Status": "A",
                               "Address1": "H-2/54, Sakuntala Park,",
                               "Address2": "Near-Sakuntala Police Station,",
                               "Place": "Sakuntala Park ,Near  Sarsuna Police Station,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1105@kidzee.com",
                               "Mobile_No": "9830421188",
                               "Phone_No1": "9051224864",
                               "Phone_No2": "",
                               "Latitude": "22.483435",
                               "Longitude": "88.288081"
                            },
                            {
                               "Franchisee_Code": "E-A-M-1399",
                               "Franchisee_Name": "KIDZEE-BATANAGAR-KOLKATA-TODDLERS HUT-1",
                               "Operating_Status": "A",
                               "Address1": "F-3-24/A, New Daulatpur Road,",
                               "Address2": "South 24 Paraganas,",
                               "Place": "Batanagar, Opposite to Maheshtala Police Station,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1399@kidzee.com",
                               "Mobile_No": "9830105050",
                               "Phone_No1": "9830105050",
                               "Phone_No2": "",
                               "Latitude": "22.513269",
                               "Longitude": "88.222991"
                            },
                            {
                               "Franchisee_Code": "E-A-M-1370",
                               "Franchisee_Name": "Kidzee-Purba Jadavpur- Kolkata- Edu-Step",
                               "Operating_Status": "A",
                               "Address1": "2163-Nayabad Avenue,",
                               "Address2": "P.S Purba Jhadavpur",
                               "Place": "Purbasha, Nayabad ,Near-1-B, Bus Stand,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1370@kidzee.com",
                               "Mobile_No": "9331422227",
                               "Phone_No1": "03324320037",
                               "Phone_No2": "",
                               "Latitude": "22.479488554976193",
                               "Longitude": "88.40467076057439"
                            },
                            {
                               "Franchisee_Code": "E-U-S-556",
                               "Franchisee_Name": "Kidzee- Balia (Near Garia Stn)- Garia- Morning Glory",
                               "Operating_Status": "A",
                               "Address1": "Garia Station, Balia, P.O. Garia,",
                               "Address2": "Near-Chotto Battala,(Besite-Rabindra Market),",
                               "Place": "Balia ,Near Garia Station",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee556@kidzee.com",
                               "Mobile_No": "9883228565",
                               "Phone_No1": "9883228575",
                               "Phone_No2": "",
                               "Latitude": "22.466241",
                               "Longitude": "88.404947"
                            },
                            {
                               "Franchisee_Code": "E-G-K-1133",
                               "Franchisee_Name": "Kidzee- Arka Phatak- WB- Little Buds",
                               "Operating_Status": "A",
                               "Address1": "C1/81, Dakshinee Housing Estate,",
                               "Address2": "",
                               "Place": "Akra Phatak, Near-Ravindra Nagar Police Station,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1133@kidzee.com",
                               "Mobile_No": "9432085320",
                               "Phone_No1": "033-24913870",
                               "Phone_No2": "",
                               "Latitude": "22.536746",
                               "Longitude": "88.258894"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2479",
                               "Franchisee_Name": "Kidzee New Town Kolkata",
                               "Operating_Status": "A",
                               "Address1": "CE1/A112,",
                               "Address2": "Action Area-1,",
                               "Place": "New Town. Behind Axix Mall,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2479@kidzee.com",
                               "Mobile_No": "8334825544",
                               "Phone_No1": "7980151867",
                               "Phone_No2": "",
                               "Latitude": "22.579769",
                               "Longitude": "88.462189"
                            },
                            {
                               "Franchisee_Code": "E-S-C-2178",
                               "Franchisee_Name": "Kidzee Jheel Road Kolkata",
                               "Operating_Status": "A",
                               "Address1": "15/54C,Jheel Road",
                               "Address2": "Bank Plot",
                               "Place": "Jheel Road ,Behind Sahid Nagar Axis Bank",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2178@kidzee.com",
                               "Mobile_No": "9831098089",
                               "Phone_No1": "9831098089",
                               "Phone_No2": "",
                               "Latitude": "22.501613",
                               "Longitude": "88.376042"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2422",
                               "Franchisee_Name": "KIDZEE  Thakurpukur",
                               "Operating_Status": "A",
                               "Address1": "15/10, Diamond park,",
                               "Address2": "P.O. Joka, P.S.",
                               "Place": "Thakurpukur, Near Corporation Office Diamond Park,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2422@kidzee.com",
                               "Mobile_No": "9433075959",
                               "Phone_No1": "9830041988",
                               "Phone_No2": "",
                               "Latitude": "22.453132",
                               "Longitude": "88.304549"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2113",
                               "Franchisee_Name": "Kidzee Khardha Kolkata",
                               "Operating_Status": "A",
                               "Address1": "Kalyannagar Bartala,",
                               "Address2": "P.O- Pansila, P.S.Khardha North 24 Parganas,",
                               "Place": "Khardha, Kalyannagar Bartala, Near Rahara Auto Sta",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2113@kidzee.com",
                               "Mobile_No": "9231665008",
                               "Phone_No1": "9231665008",
                               "Phone_No2": "",
                               "Latitude": "22.572646",
                               "Longitude": "88.363895"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2643",
                               "Franchisee_Name": "Kidzee Haridebpur",
                               "Operating_Status": "A",
                               "Address1": "211, Sodepur, 1st Lane,",
                               "Address2": "P.O- Haridebpur,",
                               "Place": "Near-Haridebpur Kolkata,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2643@kidzee.com",
                               "Mobile_No": "9804121213",
                               "Phone_No1": "9804121213",
                               "Phone_No2": "",
                               "Latitude": "22.482235",
                               "Longitude": "88.333799"
                            },
                            {
                               "Franchisee_Code": "E-S-C-2220",
                               "Franchisee_Name": "Kidzee Paharpur Road Kolkatta",
                               "Operating_Status": "A",
                               "Address1": "N-34, Paharpur Road,",
                               "Address2": "",
                               "Place": "Gardenreach, Opposite UCO Bank,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2220@kidzee.com",
                               "Mobile_No": "7439739589",
                               "Phone_No1": "033-24891072",
                               "Phone_No2": "",
                               "Latitude": "22.52436953056981",
                               "Longitude": "88.29677696113583"
                            },
                            {
                               "Franchisee_Code": "E-S-C-1520",
                               "Franchisee_Name": "Kidzee-Chinnar Park-Kolkota",
                               "Operating_Status": "A",
                               "Address1": "9,Block- B, Napara, Chinar Park",
                               "Address2": "Atghara, P.S- New Town",
                               "Place": "Atghara - Kolkata ,Behind Arsenal Restaurant",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1520@kidzee.com",
                               "Mobile_No": "8336875155",
                               "Phone_No1": "9674749222",
                               "Phone_No2": "",
                               "Latitude": "22.572646",
                               "Longitude": "88.363895"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2450",
                               "Franchisee_Name": "Kidzee Baguihati Kolkata",
                               "Operating_Status": "A",
                               "Address1": "D 5/1, Hatiara Road,",
                               "Address2": "P.O. Jyangra,",
                               "Place": "Baguihati, Opposite to Kanchan Medical,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2450@kidzee.com",
                               "Mobile_No": "9836315521",
                               "Phone_No1": "9836315521",
                               "Phone_No2": "",
                               "Latitude": "22.611047",
                               "Longitude": "88.438161"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2174",
                               "Franchisee_Name": "Kidzee HINDMOTOR Uttarpara Kotrang",
                               "Operating_Status": "A",
                               "Address1": "252/1, Uttarpara Kotrang",
                               "Address2": "K G.T.ROAD",
                               "Place": "Hindmotor Uttarpara Kotrang ,Near Relife Hospital",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2174@kidzee.com",
                               "Mobile_No": "9831126122",
                               "Phone_No1": "9831126122",
                               "Phone_No2": "",
                               "Latitude": "22.684721",
                               "Longitude": "88.354302"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3039",
                               "Franchisee_Name": "Kidzee Baghajatin",
                               "Operating_Status": "A",
                               "Address1": "Neelkanth Enterprise  Kolkata- L 1/9, Vidyasagar Colony,",
                               "Address2": "P.S. Netaji Nagar,",
                               "Place": "Baghajatin, Military Road,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3039@kidzee.com",
                               "Mobile_No": "8334003344",
                               "Phone_No1": "070-44059210",
                               "Phone_No2": "",
                               "Latitude": "22.482555",
                               "Longitude": "88.374573"
                            },
                            {
                               "Franchisee_Code": "E-R-S-3071",
                               "Franchisee_Name": "Kidzee DHAKURIA",
                               "Operating_Status": "A",
                               "Address1": "32/1E (Opposite aarambagh),",
                               "Address2": "Gariahat Road -South Selimpur,",
                               "Place": "DHAKURIA ,Opposite Aarambagh",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3071@kidzee.com",
                               "Mobile_No": "9903179858",
                               "Phone_No1": "7980091607",
                               "Phone_No2": "",
                               "Latitude": "22.5055362",
                               "Longitude": "88.3686526"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3239",
                               "Franchisee_Name": "Kidzee Rajarhat",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Block-6, Sapphire Garden,",
                               "Address2": "Police Station,",
                               "Place": "Rajarhat, Kajiyalpara, Opposite Lotus Hospital",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3239@kidzee.com",
                               "Mobile_No": "9830140248",
                               "Phone_No1": "9830140248",
                               "Phone_No2": "",
                               "Latitude": "22.635799",
                               "Longitude": "88.479402",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d97b9b60_981a_47a9_816f_12c6daac89ea.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/10584ee2_a262_44d8_ba59_c71f1f414714.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/dbfafa08_186f_4f18_847c_c4e974214213.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/530fdf05_bfb9_4cce_90af_e2d7794960e5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4d194bf3_f23c_45d6_8b3b_2314ff37d0c7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/a7a50994_3f40_482c_8e35_c0fa97f9c093.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4f78268b_c26a_4a51_bf24_51aaa48dfe39.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1f416454_f2ae_4c46_93c8_cf4cbe82d560.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1175415c_2e59_433b_8ca8_eac9b388350a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4a5720d6_a9c7_452e_9153_f3471b1a8815.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d0c2a8a5_edd0_4465_b942_54dba745c6a3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1320690/10486/32649fb9_1a54_4233_b9e8_2a1e910aaaef.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3332",
                               "Franchisee_Name": "Kidzee Ashokenagar",
                               "Operating_Status": "A",
                               "Address1": "Golbazaar  Little Genius,",
                               "Address2": "North 24 Parganas,",
                               "Place": "Ashokenagar, Near-4 Number Agradut Sangha,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3332@kidzee.com",
                               "Mobile_No": "8373012132",
                               "Phone_No1": "8373012132",
                               "Phone_No2": "",
                               "Latitude": "22.8363980719477",
                               "Longitude": "88.6258345842361"
                            },
                            {
                               "Franchisee_Code": "E-U-C-805",
                               "Franchisee_Name": "KIDZEE-KAMALGAZI Narendrapur- Kolkata- Rising Star",
                               "Operating_Status": "A",
                               "Address1": "Block A, Plot No-5, Nivedita, Pratapgarh,",
                               "Address2": "Garia, P.S: Sonarpur, P.O :Narendrapur",
                               "Place": "Pratapgarh, Behind-B D Memorial School,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee805@kidzee.com",
                               "Mobile_No": "9007042652",
                               "Phone_No1": "03365004937",
                               "Phone_No2": "",
                               "Latitude": "22.449527",
                               "Longitude": "88.395541"
                            },
                            {
                               "Franchisee_Code": "E-S-C-1661",
                               "Franchisee_Name": "Kidzee-Harinavi",
                               "Operating_Status": "A",
                               "Address1": "The Wave Riders Abhishek Bhavan,",
                               "Address2": "39, N.S.Road,",
                               "Place": "Harinavi ,Near Bharat gas",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1661@kidzee.comm",
                               "Mobile_No": "9830399943",
                               "Phone_No1": "9831-294401",
                               "Phone_No2": "",
                               "Latitude": "22.4125193",
                               "Longitude": "88.4185685",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/768d591d_98cd_4925_bf0d_83c75a646be5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/00d33ad6_b9e5_4f63_8bb0_d897621a8779.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/1f928bad_aad6_4297_a913_88fd5f19d471.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/4afc599c_eac8_4260_b0c5_ee4b38877b49.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/9195928a_8c8b_43b5_a82d_03914913c15c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/109f039c_8581_4b8c_92ba_36d52824aada.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/ef8ab925_fa92_4f1f_8150_28561f7ae4ba.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/3156d7de_f37c_413f_b334_b462e70a166a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/2bfcf67d_3ef8_4735_8a78_a6e9aea4edda.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/43d23f69_f5fd_4c5a_a629_b723ffe365d8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/aa70d6ac_ecc2_48b8_8dc7_539b64173de3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/3340dd4d_4b14_4443_a907_06a0ccc5db07.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/634fc4a1_9c3b_49ea_afcd_5d70c4c0cb23.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/f1d3c398_ec40_4c44_94f3_1fcf42fdb2b9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/2f748d01_86c6_45da_9194_b5dc0c276ff8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/07347400_f4eb_4e39_8118_2d0105fbfc6a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/d954b7c2_8a7e_4632_8189_60b9bb71cabd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/bdcc7a15_9fee_4e1f_8881_0c0378c9970a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/6433e2c3_2831_44dd_bdb2_5e1597015cb2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/893f2dba_ffab_4604_b927_4a70bc49b7a5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/7270701e_b0f9_4810_957d_d7235ac39c6f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/7fff43e0_2422_46bf_941c_4561b3db6a3f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/512898/10550/890727ed_d8a2_4a8e_99f1_fb00fcf87419.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-S-C-1733",
                               "Franchisee_Name": "Kidzee-The Play House, Lake Town",
                               "Operating_Status": "A",
                               "Address1": "772/1, Lake Town,",
                               "Address2": "Block-A,",
                               "Place": "Lake Town, Behind-Jaya Cinema Hall,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1733@kidzee.com",
                               "Mobile_No": "9830842469",
                               "Phone_No1": "9674566148",
                               "Phone_No2": "",
                               "Latitude": "22.605166",
                               "Longitude": "88.402188"
                            },
                            {
                               "Franchisee_Code": "E-S-C-1775",
                               "Franchisee_Name": "Kidzee-Baranagar- New Trayee Construction",
                               "Operating_Status": "A",
                               "Address1": "2/1A/1 A.K. Mukherjee Road,",
                               "Address2": "Tobin Road,",
                               "Place": "Baranagar, Near State General Hospital,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1775@kidzee.com",
                               "Mobile_No": "9883141771",
                               "Phone_No1": "9883-141771",
                               "Phone_No2": "",
                               "Latitude": "22.63885",
                               "Longitude": "88.381732"
                            },
                            {
                               "Franchisee_Code": "E-R-C-1852",
                               "Franchisee_Name": "Kidzee Boral",
                               "Operating_Status": "A",
                               "Address1": "139/2, Boral Main Road,",
                               "Address2": "P.S. Bansdroni, P.O-Garia,",
                               "Place": "Boral, Usha Palli, Near- Kalyan Parisad Play Groun",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1852@kidzee.com",
                               "Mobile_No": "9051508089",
                               "Phone_No1": "9051508089",
                               "Phone_No2": "",
                               "Latitude": "22.456694",
                               "Longitude": "88.376400"
                            },
                            {
                               "Franchisee_Code": "E-R-C-1925",
                               "Franchisee_Name": "Kidzee Kestopur",
                               "Operating_Status": "A",
                               "Address1": "No. AF-13, krishnapur,",
                               "Address2": "Kestopur Police Station – Baguahati,",
                               "Place": "Kestopur ,Near- Hanapara, Sitala Mandir,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1925@kidzee.com",
                               "Mobile_No": "9674188129",
                               "Phone_No1": "9830-228151",
                               "Phone_No2": "",
                               "Latitude": "22.600605",
                               "Longitude": "88.432042",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/3515c479_e8e2_47af_9bb2_81576113329a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/d3f1486d_4e2c_498f_87ad_40692fdc49bd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/089a1ac3_9248_4c8e_9300_f179c3da19dd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/a41ac30e_81dd_4f46_b018_4f9d4031a572.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/399b9256_9992_4589_823d_d4c700e37479.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/ebd2698a_6d43_40f8_8666_32c6116dd9bb.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/eeb044cc_e488_49dc_a346_3124038f2da6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/93df8cda_f0a2_45f6_8ba4_df2527391b84.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/6f4ebed4_aaea_4104_afb1_d0e3f911e610.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/9953527c_9061_46de_a93d_92846283fa68.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/a6459b5a_681e_42f4_ae35_e96935a57b7d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/d348a13d_2647_436d_b830_903dd9dea818.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/0f18bb23_d595_4f00_98c3_acb05ac9960a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/13020c52_1146_4c22_9088_c925929d642f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/27d5a253_9c5e_44ba_b373_9cd58a38c762.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/c5354fc5_1eca_41fc_b911_f47edc820f19.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/d834587e_87ed_4ed6_883a_82a29e0526d1.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/e18ba221_48aa_41d7_b7b8_13457423aab9.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/94c97a40_6793_4a2e_a509_c074886ca4fd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/cc657ba3_fc54_4ed1_94a5_bc199e5974a8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/f1405f5f_258f_49d1_b8b7_df6bdba89bb3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/9783a385_35d6_4551_93e4_9540a39244f0.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/44bd634e_813f_4c4d_b072_9b7ba5adbe5e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/c843bd7e_4c32_4d54_ae3d_4d4998c14e38.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/8ea9fdbc_863b_48bd_a036_0ef9c62a98b4.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/9e53e15f_e513_446c_9350_60f4898a2f29.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/b7586910_9a4e_4b12_b58d_bb0b2c914839.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/943dcb24_803b_41f8_81bd_d99a0c37823e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/06089113_1be3_4630_9079_855aa89852cd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/5e8a6613_fb1d_47f6_a3cd_ab8999e79308.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/71ad8a64_6183_4e80_988b_ec3a0265c9f4.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/03da8d65_c076_49cb_8dde_88af9d789e9e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/149eedf5_5b11_422f_98f4_79b72c8d0b2e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/af973336_f8e8_4659_9b73_ed52802378dd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/30789718_3ad4_4921_a9cc_64147966a79e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/40054e03_18d5_49f1_8d08_95ea23b8c849.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/df58cb25_c934_4fab_971c_a28142b36de8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/32fe5efe_7942_41dd_b5e5_3eb876fc9670.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/dcef321a_2adb_49df_a576_414b665cbfec.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/c286d41c_d27f_4099_b8d4_37841ad27d44.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/5c6d3cf0_6781_42de_bc65_a7f39b04904e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/7cd4db06_3c11_45e4_996c_08a12a57d3b3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/da7b8bb8_45c3_4019_864b_f52554d89b11.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/d2d1e118_0a8d_4765_8c90_b065d1e2cbfd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/a28a4296_da97_4e8e_87cb_b6295029e51e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/75ffe421_7ef4_41c2_833d_2500a72a7e08.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/5ce4126a_ad3e_4f6b_a146_f57c0ae2923e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/c75db37a_b5f0_4d8d_8590_15fb1e3e14a6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/a68c7fed_000e_4f64_9ef7_efd8ba7a4755.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/738985f8_bac4_4130_b505_e99270f699d3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/7dc49ee7_b970_4a23_8e34_16ce6751118a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/f4238e62_50bf_4757_bcc8_dbb23710b7a2.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/86c5f8ca_5d7c_4609_9b7e_1e89852b0425.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/4395beba_1717_489f_9f29_2ce6583f6a51.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/0a0271f1_dec8_4b39_823c_0de39a479d49.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/414a6fad_691b_4a9e_9df0_7ddf47cd7cad.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/e3360685_f1d6_45b7_9efb_235f6497a12f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/2c59c96d_20af_41b1_ba89_a4fad6d3cdec.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/8a01d0e5_d06f_4d78_85c0_fd7fbcda4c35.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/69034e5d_a289_4ac1_954e_49fd9336e4ec.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/aa8a9cb0_a46d_4738_be6e_04f3d4f022e5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/1756401b_12b6_4e29_950a_ae27f9c7b6e9.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/80b696ab_2c01_437c_8d12_93dc2f201ec2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/3ff7cbc0_142b_422f_a26f_0bc95910049d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/8b30f42c_6a34_4a2c_8e23_bfa596a19e59.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/638f5821_c82e_4b0c_a69e_899d5ab276e6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/39018432_d110_4b12_a958_1bf435f3100d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/8a3fdb81_7db3_438b_a2f4_84c5f1d7d34f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/3c8f2d55_ea71_4ff5_aac7_26db347ae83b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/a826587a_5a41_438c_a9d0_bfc6557e6584.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/3ba81b08_55a8_49c5_ba2a_f42a3771d001.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/eaa23567_fc67_4ffc_81cb_6391a3a55b6c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/b6463679_caa5_42e4_b02c_6ccf4fcb6412.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/64291ea8_e536_4fd4_9c37_88cb4778b9bc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/445ce69a_2116_4e3e_8e75_4b9b13e7c6f6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/872080be_594b_4fe5_94bc_b5ae892d1d97.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/3d98de5b_43cc_4f06_a1fb_8c2d4c6f0281.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/e6b2cf94_c156_4bc2_abe9_f06ad01c076f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/8eb3aa08_3d61_4352_ae2a_12cc081a1a21.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/04da30c9_4485_4bc4_aad3_43ddf50e7af6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/f75b1f22_41db_4485_b5a0_65961f7f52ad.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/5bcd1ee4_36a2_4adc_ab63_67cccf18fc19.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/5748bd21_5091_4c8f_9e0f_e622b1510c4c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/584367/10841/7f15d110_b9e4_4330_b739_2597b398e025.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-2873",
                               "Franchisee_Name": "Kidzee NEW BARRACKPORE",
                               "Operating_Status": "A",
                               "Address1": "Chandana Construction -79, Sahara Ghosh Para,",
                               "Address2": "Michael Nagar,",
                               "Place": "New Barrackpore, Behind Hyundai Showroom,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2873@kidzee.com",
                               "Mobile_No": "7980579705",
                               "Phone_No1": "9831093394",
                               "Phone_No2": "",
                               "Latitude": "22.690846",
                               "Longitude": "88.440044"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2955",
                               "Franchisee_Name": "Kidzee Charaktala",
                               "Operating_Status": "A",
                               "Address1": "3/2/2A, Charaktala Branch Road,",
                               "Address2": "P.S.Behala,",
                               "Place": "Behaala ,Near Charaktala,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2955@kidzee.com",
                               "Mobile_No": "9830465126",
                               "Phone_No1": "9830465126",
                               "Phone_No2": "",
                               "Latitude": "22.49814",
                               "Longitude": "88.310838"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3001",
                               "Franchisee_Name": "Kidzee Kasba Kolkata",
                               "Operating_Status": "A",
                               "Address1": "119/1,",
                               "Address2": "Rajdanga,",
                               "Place": "Nabapally, Near Frankross Pharmacy",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3001@kidzee.com",
                               "Mobile_No": "7044647377",
                               "Phone_No1": "7044647378",
                               "Phone_No2": "",
                               "Latitude": "22.515078",
                               "Longitude": "88.388446"
                            },
                            {
                               "Franchisee_Code": "E-B-U-864",
                               "Franchisee_Name": "KIDZEE- PANASREE PALLY- KOLKATA- West Bengal-  GENIUS ACADEMY",
                               "Operating_Status": "A",
                               "Address1": "52-Parnashree Pally,",
                               "Address2": "Thana, Behala,",
                               "Place": "Parnashree Pally ,Beside- Hotel Sea Castle, Opposi",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee864@kidzee.com",
                               "Mobile_No": "9051031319",
                               "Phone_No1": "3332999444",
                               "Phone_No2": "",
                               "Latitude": "22.5070369",
                               "Longitude": "88.3021198"
                            },
                            {
                               "Franchisee_Code": "E-B-S-880",
                               "Franchisee_Name": "KIDZEE- Belgharia- KOLKATA-BLOOMING FLOWERS",
                               "Operating_Status": "A",
                               "Address1": "97/4, B.T. Road,",
                               "Address2": "Near (A Bus Stand",
                               "Place": "Kolkata ,Near Dunlop Bridge",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee880@kidzee.com",
                               "Mobile_No": "8276044881",
                               "Phone_No1": "8981055519",
                               "Phone_No2": "",
                               "Latitude": "22.572646",
                               "Longitude": "88.363895"
                            },
                            {
                               "Franchisee_Code": "E-U-C-904",
                               "Franchisee_Name": "Kidzee- Sonarpur- Kolkata-Little Steps",
                               "Operating_Status": "A",
                               "Address1": "Kidzee Sonarpur, Park Road",
                               "Address2": "Sahebpara, Sonarpur, Opp. Bandhan Bank & HDFC Bank",
                               "Place": "Sonarpur ,Sahebpara opposite Bandhan Bank and Axis Bank HDFC Bank",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee904@kidzee.com",
                               "Mobile_No": "9903449866",
                               "Phone_No1": "8777576664",
                               "Phone_No2": "",
                               "Latitude": "22.440874",
                               "Longitude": "88.424636"
                            },
                            {
                               "Franchisee_Code": "E-R-S-2063",
                               "Franchisee_Name": "KIDZEE NAKTALA WB",
                               "Operating_Status": "A",
                               "Address1": "356/14,",
                               "Address2": "N.S.C. Bose Road,",
                               "Place": "Near- Naktala Petrol Pump,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2063@kidzee.com",
                               "Mobile_No": "9830276357",
                               "Phone_No1": "03364501163",
                               "Phone_No2": "",
                               "Latitude": "22.471669",
                               "Longitude": "88.369204"
                            },
                            {
                               "Franchisee_Code": "E-B-U-931",
                               "Franchisee_Name": "KIDZEE-BAGUIATI ROAD-KOLKATA- Silver Star",
                               "Operating_Status": "A",
                               "Address1": "44, Baguiati Road,",
                               "Address2": "Second Lane,",
                               "Place": "Baguiati Road, Near- Aam Bagan Gas Godown,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee931@kidzee.com",
                               "Mobile_No": "9830792256",
                               "Phone_No1": "9748432222",
                               "Phone_No2": "",
                               "Latitude": "22.529873",
                               "Longitude": "88.387769"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2015",
                               "Franchisee_Name": "Kidzee Dumdum Kolkata",
                               "Operating_Status": "A",
                               "Address1": "No-11, Santosh Park Nalta,",
                               "Address2": "Bakultala Road,",
                               "Place": "Dumdum Airport, Near- Bakultala,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2015@kidzee.com",
                               "Mobile_No": "9830323336",
                               "Phone_No1": "9830323336",
                               "Phone_No2": "",
                               "Latitude": "22.519961",
                               "Longitude": "88.380993"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2058",
                               "Franchisee_Name": "Kidzee Birati Kolkatta",
                               "Operating_Status": "A",
                               "Address1": "902, M.B,Road, Mahajatinagar, Opposite UBI Bank,",
                               "Address2": "",
                               "Place": "Birati ,Opp PNB Bank,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2058@kidzee.com",
                               "Mobile_No": "8240677925",
                               "Phone_No1": "033-25140198",
                               "Phone_No2": "",
                               "Latitude": "22.662978",
                               "Longitude": "88.43814"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2221",
                               "Franchisee_Name": "Kidzee Sunflowers Dakghar",
                               "Operating_Status": "A",
                               "Address1": "Biren Roy Road West",
                               "Address2": "Dakhghar Near BSNL telecom office",
                               "Place": "Maheshtala, Near Telephone Exchange",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2221@kidzee.com",
                               "Mobile_No": "9830421188",
                               "Phone_No1": "9830421188",
                               "Phone_No2": "",
                               "Latitude": "22.49814",
                               "Longitude": "88.310838"
                            },
                            {
                               "Franchisee_Code": "E-A-M-1250",
                               "Franchisee_Name": "Kidzee- Guha Road- Kolkata- Siksha",
                               "Operating_Status": "A",
                               "Address1": "90, R.N.Guha Road,",
                               "Address2": "Old Housing No.563,",
                               "Place": "Ward No.9, Near Nagerbazar,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1250@kidzee.com",
                               "Mobile_No": "9836516201",
                               "Phone_No1": "8017508365",
                               "Phone_No2": "",
                               "Latitude": "22.629942",
                               "Longitude": "88.421217"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2368",
                               "Franchisee_Name": "Kidzee Baruipur WB",
                               "Operating_Status": "A",
                               "Address1": "Kachari Bazar Mouza Salehpur,",
                               "Address2": "Kulpi Road Village Padmapukur",
                               "Place": "Baruipur, Behind Reliance Digital showroom",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2368@kidzee.com",
                               "Mobile_No": "7003114020",
                               "Phone_No1": "7003114020",
                               "Phone_No2": "",
                               "Latitude": "22.37397016878189",
                               "Longitude": "88.42445001327519"
                            },
                            {
                               "Franchisee_Code": "E-R-C-2544",
                               "Franchisee_Name": "Kidzee Paul Enterprise Dumdum",
                               "Operating_Status": "A",
                               "Address1": "Paul Enterprise,",
                               "Address2": "3A/12, B.K. Paul Lane,",
                               "Place": "Near-Dumdum Metro,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2544@kidzee.com",
                               "Mobile_No": "9831080688",
                               "Phone_No1": "9831080688",
                               "Phone_No2": "",
                               "Latitude": "22.6203",
                               "Longitude": "88.397123"
                            },
                            {
                               "Franchisee_Code": "E-R-S-2672",
                               "Franchisee_Name": "Kidzee Tagore Park Kolkata",
                               "Operating_Status": "A",
                               "Address1": "C - 14A. Ground Floor,",
                               "Address2": "1664/1/1 Laskharhat ,",
                               "Place": "Tagore Park, Near Tagore Park Sporting Club,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2672@kidzee.com",
                               "Mobile_No": "9163338840",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "22.520655",
                               "Longitude": "88.397125"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3739",
                               "Franchisee_Name": "Kidzee Kalitala",
                               "Operating_Status": "A",
                               "Address1": "229, Kalitala Main Road,",
                               "Address2": "PS-",
                               "Place": "Garfa ,Purbachal North, Kalitala Kali Mandir,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3739@kidzee.com",
                               "Mobile_No": "9748662078",
                               "Phone_No1": "9748662078",
                               "Phone_No2": "",
                               "Latitude": "22.5058032000421",
                               "Longitude": "88.3790552616119"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3767",
                               "Franchisee_Name": "Kidzee Santoshpur Trikonpark",
                               "Operating_Status": "A",
                               "Address1": "KMC Premises No 25 South Road",
                               "Address2": "Postal No 47",
                               "Place": "Santoshpur Trikonpark ,Near Shukanta Setu",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3767@kidzee.com",
                               "Mobile_No": "9073351251",
                               "Phone_No1": "3340-209797",
                               "Phone_No2": "",
                               "Latitude": "22.491374315263236",
                               "Longitude": "88.37859040336616",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/f3c528d1_2f39_4423_a19f_5a489f777657.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/50cbf6f5_a295_4dfc_a3e5_05c7a23bdf4e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/228b2599_086a_4273_a654_dd1e028fb14c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/501d10d2_bc9b_4ed2_8ef6_45fa0f82b59f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/d229131a_e656_487e_b960_7ac1154a47f3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/d03525c1_637f_4e05_8245_c23425ea7523.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/6170bba9_4ca4_47d0_9f79_6e9d491c7290.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/6f50664f_a4eb_4fb3_a941_2dec4db8768b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/cdcbb29c_4adc_40fe_9573_0e42a8f41621.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/642e7fe9_3d99_403b_8e4e_4ec541c2dac0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/fe5ac2a9_d1a7_4966_bbfa_dcccc92deb04.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/103a7694_1aa0_403e_909e_e7dd6fe46aae.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/7ed23afa_9216_44d0_accf_ee2e03f74d25.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/c22541d7_8529_41b2_b9d0_c76ebf6d3d5b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1653145/10362/f96823d2_27e1_4d7d_9559_85ed724819af.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3239",
                               "Franchisee_Name": "Kidzee Rajarhat",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Block-6, Sapphire Garden,",
                               "Address2": "Police Station,",
                               "Place": "Rajarhat, Kajiyalpara, Opposite Lotus Hospital",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3239@kidzee.com",
                               "Mobile_No": "9830140248",
                               "Phone_No1": "9830140248",
                               "Phone_No2": "",
                               "Latitude": "22.635509",
                               "Longitude": "88.479430",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d97b9b60_981a_47a9_816f_12c6daac89ea.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/10584ee2_a262_44d8_ba59_c71f1f414714.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/dbfafa08_186f_4f18_847c_c4e974214213.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/530fdf05_bfb9_4cce_90af_e2d7794960e5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4d194bf3_f23c_45d6_8b3b_2314ff37d0c7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/a7a50994_3f40_482c_8e35_c0fa97f9c093.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4f78268b_c26a_4a51_bf24_51aaa48dfe39.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1f416454_f2ae_4c46_93c8_cf4cbe82d560.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1175415c_2e59_433b_8ca8_eac9b388350a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4a5720d6_a9c7_452e_9153_f3471b1a8815.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d0c2a8a5_edd0_4465_b942_54dba745c6a3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1320690/10486/32649fb9_1a54_4233_b9e8_2a1e910aaaef.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3239",
                               "Franchisee_Name": "Kidzee Rajarhat",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Block-6, Sapphire Garden,",
                               "Address2": "Police Station,",
                               "Place": "Rajarhat, Kajiyalpara, Opposite Lotus Hospital",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3239@kidzee.com",
                               "Mobile_No": "9830140248",
                               "Phone_No1": "9830140248",
                               "Phone_No2": "",
                               "Latitude": "22.635509",
                               "Longitude": "88.479430",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d97b9b60_981a_47a9_816f_12c6daac89ea.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/10584ee2_a262_44d8_ba59_c71f1f414714.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/dbfafa08_186f_4f18_847c_c4e974214213.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/530fdf05_bfb9_4cce_90af_e2d7794960e5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4d194bf3_f23c_45d6_8b3b_2314ff37d0c7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/a7a50994_3f40_482c_8e35_c0fa97f9c093.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4f78268b_c26a_4a51_bf24_51aaa48dfe39.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1f416454_f2ae_4c46_93c8_cf4cbe82d560.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1175415c_2e59_433b_8ca8_eac9b388350a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4a5720d6_a9c7_452e_9153_f3471b1a8815.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d0c2a8a5_edd0_4465_b942_54dba745c6a3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1320690/10486/32649fb9_1a54_4233_b9e8_2a1e910aaaef.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3239",
                               "Franchisee_Name": "Kidzee Rajarhat",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Block-6, Sapphire Garden,",
                               "Address2": "Police Station,",
                               "Place": "Rajarhat, Kajiyalpara, Opposite Lotus Hospital",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3239@kidzee.com",
                               "Mobile_No": "9830140248",
                               "Phone_No1": "9830140248",
                               "Phone_No2": "",
                               "Latitude": "22.572646",
                               "Longitude": "88.363895",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d97b9b60_981a_47a9_816f_12c6daac89ea.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/10584ee2_a262_44d8_ba59_c71f1f414714.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/dbfafa08_186f_4f18_847c_c4e974214213.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/530fdf05_bfb9_4cce_90af_e2d7794960e5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4d194bf3_f23c_45d6_8b3b_2314ff37d0c7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/a7a50994_3f40_482c_8e35_c0fa97f9c093.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4f78268b_c26a_4a51_bf24_51aaa48dfe39.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1f416454_f2ae_4c46_93c8_cf4cbe82d560.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1175415c_2e59_433b_8ca8_eac9b388350a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4a5720d6_a9c7_452e_9153_f3471b1a8815.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d0c2a8a5_edd0_4465_b942_54dba745c6a3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1320690/10486/32649fb9_1a54_4233_b9e8_2a1e910aaaef.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3239",
                               "Franchisee_Name": "Kidzee Rajarhat",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Block-6, Sapphire Garden,",
                               "Address2": "Police Station,",
                               "Place": "Rajarhat, Kajiyalpara, Opposite Lotus Hospital",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3239@kidzee.com",
                               "Mobile_No": "9830140248",
                               "Phone_No1": "9830140248",
                               "Phone_No2": "",
                               "Latitude": "22.635509",
                               "Longitude": "88.479430",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d97b9b60_981a_47a9_816f_12c6daac89ea.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/10584ee2_a262_44d8_ba59_c71f1f414714.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/dbfafa08_186f_4f18_847c_c4e974214213.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/530fdf05_bfb9_4cce_90af_e2d7794960e5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4d194bf3_f23c_45d6_8b3b_2314ff37d0c7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/a7a50994_3f40_482c_8e35_c0fa97f9c093.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4f78268b_c26a_4a51_bf24_51aaa48dfe39.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1f416454_f2ae_4c46_93c8_cf4cbe82d560.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1175415c_2e59_433b_8ca8_eac9b388350a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4a5720d6_a9c7_452e_9153_f3471b1a8815.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d0c2a8a5_edd0_4465_b942_54dba745c6a3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1320690/10486/32649fb9_1a54_4233_b9e8_2a1e910aaaef.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3239",
                               "Franchisee_Name": "Kidzee Rajarhat",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Block-6, Sapphire Garden,",
                               "Address2": "Police Station,",
                               "Place": "Rajarhat, Kajiyalpara, Opposite Lotus Hospital",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3239@kidzee.com",
                               "Mobile_No": "9830140248",
                               "Phone_No1": "9830140248",
                               "Phone_No2": "",
                               "Latitude": "22.635509",
                               "Longitude": "88.479430",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d97b9b60_981a_47a9_816f_12c6daac89ea.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/10584ee2_a262_44d8_ba59_c71f1f414714.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/dbfafa08_186f_4f18_847c_c4e974214213.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/530fdf05_bfb9_4cce_90af_e2d7794960e5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4d194bf3_f23c_45d6_8b3b_2314ff37d0c7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/a7a50994_3f40_482c_8e35_c0fa97f9c093.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4f78268b_c26a_4a51_bf24_51aaa48dfe39.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1f416454_f2ae_4c46_93c8_cf4cbe82d560.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1175415c_2e59_433b_8ca8_eac9b388350a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4a5720d6_a9c7_452e_9153_f3471b1a8815.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d0c2a8a5_edd0_4465_b942_54dba745c6a3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1320690/10486/32649fb9_1a54_4233_b9e8_2a1e910aaaef.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3239",
                               "Franchisee_Name": "Kidzee Rajarhat",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, Block-6, Sapphire Garden,",
                               "Address2": "Police Station,",
                               "Place": "Rajarhat, Kajiyalpara, Opposite Lotus Hospital",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3239@kidzee.com",
                               "Mobile_No": "9830140248",
                               "Phone_No1": "9830140248",
                               "Phone_No2": "",
                               "Latitude": "22.636318",
                               "Longitude": "88.479086",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d97b9b60_981a_47a9_816f_12c6daac89ea.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/10584ee2_a262_44d8_ba59_c71f1f414714.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/dbfafa08_186f_4f18_847c_c4e974214213.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/530fdf05_bfb9_4cce_90af_e2d7794960e5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4d194bf3_f23c_45d6_8b3b_2314ff37d0c7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/a7a50994_3f40_482c_8e35_c0fa97f9c093.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4f78268b_c26a_4a51_bf24_51aaa48dfe39.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1f416454_f2ae_4c46_93c8_cf4cbe82d560.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/1175415c_2e59_433b_8ca8_eac9b388350a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/4a5720d6_a9c7_452e_9153_f3471b1a8815.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1320690/10486/d0c2a8a5_edd0_4465_b942_54dba745c6a3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1320690/10486/32649fb9_1a54_4233_b9e8_2a1e910aaaef.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3886",
                               "Franchisee_Name": "Kidzee Mukundapur",
                               "Operating_Status": "A",
                               "Address1": "1794 SATABDI PARK,",
                               "Address2": "BLOCK-A GROUND FLOOR",
                               "Place": "Mukundapur ,Near Daspara Khal Bridge",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3886@kidzee.com",
                               "Mobile_No": "8100049244",
                               "Phone_No1": "8100049244",
                               "Phone_No2": "",
                               "Latitude": "22.490231",
                               "Longitude": "88.410880"
                            },
                            {
                               "Franchisee_Code": "COESS-4178",
                               "Franchisee_Name": "Kolkata",
                               "Operating_Status": "A",
                               "Address1": "Ground and Entire, 1st Floor, Located at 40-D, Palm Avenue,",
                               "Address2": "",
                               "Place": "Ballygunge, Palm Avenue,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4178@kidzee.com",
                               "Mobile_No": "9073672119",
                               "Phone_No1": "9073672119",
                               "Phone_No2": "",
                               "Latitude": "22.532904",
                               "Longitude": "88.371873"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4235",
                               "Franchisee_Name": "Kidzee Madhurda",
                               "Operating_Status": "A",
                               "Address1": "1642, Madurdaha,",
                               "Address2": "kallikapur,",
                               "Place": "Madurdaha, Near-Axis Bank,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4235@kidzee.com",
                               "Mobile_No": "9073301154",
                               "Phone_No1": "9147100702",
                               "Phone_No2": "",
                               "Latitude": "22.505004",
                               "Longitude": "88.407030",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2165590/10806/8383ae64_e361_42ed_a42b_0975b9a1c1bf.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2165590/10804/a738525e_3b32_40d6_8652_b4142e8593f8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2165590/10804/7e890065_5236_4eda_bde1_98804bc42672.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2165590/10805/110b4acd_4350_4414_841b_42d35c254a6b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2165590/10807/002f7df4_bbc7_498b_b571_52ffcbe9fe2a.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-S-4545",
                               "Franchisee_Name": "Kidzee Dover Lane",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor, House No-24/8,",
                               "Address2": "Dover Lane, Garcha First Lane,",
                               "Place": "Ballygunge, Near-Gariahat,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4545@kidzee.com",
                               "Mobile_No": "9874888172",
                               "Phone_No1": "9874888172",
                               "Phone_No2": "",
                               "Latitude": "22.5243420",
                               "Longitude": "88.3615060"
                            },
                            {
                               "Franchisee_Code": "E-R-S-4645",
                               "Franchisee_Name": "Kidzee Curiocity",
                               "Operating_Status": "A",
                               "Address1": "Ground Floor-Unit-1&2,",
                               "Address2": "Curiocity, landmark, Nearby Ecospace,",
                               "Place": "New Town - Chakpachuria, Near-Ecospace",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4645@kidzee.com",
                               "Mobile_No": "9830188509",
                               "Phone_No1": "8335886363",
                               "Phone_No2": "",
                               "Latitude": "27.858056",
                               "Longitude": "92.908056",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2472587/10946/05c65876_453f_4fee_a8a2_85303849b4a6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2472587/10946/2f053408_bb6c_4cca_a247_d846bb937824.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-4443",
                               "Franchisee_Name": "KIDZEE THAKURPUKUR",
                               "Operating_Status": "A",
                               "Address1": "A3/21",
                               "Address2": "Srijani, MG Road, Near Cancer Hospital",
                               "Place": "Thakurpukur, Srijani ,Near Thakurpukur Cancer Hospital",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4443@kidzee.com",
                               "Mobile_No": "7595821333",
                               "Phone_No1": "7595821333",
                               "Phone_No2": "",
                               "Latitude": "22.457482",
                               "Longitude": "88.321723"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4688",
                               "Franchisee_Name": "Kidzee Contai",
                               "Operating_Status": "A",
                               "Address1": "Ward No 20",
                               "Address2": "Dharmadasbarh, po +ps contai",
                               "Place": "Contai ,Near Central Bus Stand",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4688@kidzee.com",
                               "Mobile_No": "8777601679",
                               "Phone_No1": "7430-927355",
                               "Phone_No2": "",
                               "Latitude": "21.771809",
                               "Longitude": "87.725729",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/a3d9b83c_cba4_4c66_9ad8_57c6c3ea7982.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/d37f7c65_e156_487b_8933_6309c62e70c6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/fe7c4e0e_9801_4319_bb76_7d3254ffd39e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/85823acb_2d80_4f38_b358_ee1969cf2a82.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/f7af4fca_c54f_4a07_b5c1_ff7baa5e4365.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/f9ee929f_7e11_4643_8b25_0c81174a06f7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/2e07d1df_4a01_4c16_a114_3b06be2e5b2b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/a611604c_9423_4dfa_8be3_e2824b89787c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/85b62349_f3cd_4073_a3f3_a4b09ed24ea5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/b8f180c6_49b1_42d0_bf37_bd11852836ba.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/13baf1cb_45fc_4f3f_8b4d_90bc9e3ab092.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/506d9c6c_1eee_4f42_8278_b15ab02e96fe.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/82441352_063c_4365_bad0_184bc0e4e5e2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/409fd86e_7ce8_4b8c_b10f_2cc304c0f77f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/d6bd2ec0_c027_4704_a56c_6561f61985a7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/79890832_191a_49ac_94c3_3c09ad9646b2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/bbcc58ff_2edb_44e5_97b1_8472a6c4b640.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/6d27a994_c436_490e_94b0_97873ab0c77b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/b47f1d57_eed9_45d1_ba7b_e6ba8ed06e8e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/f5d0516c_b093_4251_b048_d23b4d877ef6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/6093082d_79e2_4080_a24b_40ad3ae7a337.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/260b2318_202a_47b3_a1af_b6e58852c9b6.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/6808be4e_744f_4717_b73e_3ea5869ef125.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/86387ac2_eda6_413c_84c8_ff47b2d21435.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/311818f7_5de1_463c_894b_14ac0a8d6e1a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2511423/10999/5483a421_9455_4aed_b445_deff37944989.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3882",
                               "Franchisee_Name": "Kidzee Bangaon",
                               "Operating_Status": "A",
                               "Address1": "Protapgarh",
                               "Address2": "opp State General Hospital",
                               "Place": "Bangaon ,opposit to State General Hospital",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3882@kidzee.com",
                               "Mobile_No": "9674321388",
                               "Phone_No1": "9674321388",
                               "Phone_No2": "",
                               "Latitude": "23.049068",
                               "Longitude": "88.822599"
                            },
                            {
                               "Franchisee_Code": "E-R-S-4911",
                               "Franchisee_Name": "Kidzee Karigari Bhavan",
                               "Operating_Status": "A",
                               "Address1": "AA IIIB, Street No-770, Plot No- 810,",
                               "Address2": "Behind- Rosedale,",
                               "Place": "Opposite Of Uniworld City, New Town,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4911@kidzee.com",
                               "Mobile_No": "8910915474",
                               "Phone_No1": "8910915474",
                               "Phone_No2": "",
                               "Latitude": "22.569000",
                               "Longitude": "88.491907"
                            },
                            {
                               "Franchisee_Code": "E-R-S-4838",
                               "Franchisee_Name": "Kidzee Newtown",
                               "Operating_Status": "A",
                               "Address1": "Plot No.AB 100, Action Area 1, Ground Floor",
                               "Address2": "Street Number 74",
                               "Place": "Newtown ,Near Newtown Bus Stand Street No. 74",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4838@kidzee.com",
                               "Mobile_No": "9748418960",
                               "Phone_No1": "9748418960",
                               "Phone_No2": "",
                               "Latitude": "22.5875353",
                               "Longitude": "88.4542181"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4842",
                               "Franchisee_Name": "Kidzee Tarakeswar",
                               "Operating_Status": "A",
                               "Address1": "Vill: Guria (Opp Mondal Paper)",
                               "Address2": "Padmapukur Bhimpur Road",
                               "Place": "Po & PS Tarakeswar ,Opposite Mondal Paper",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4842@kidzee.com",
                               "Mobile_No": "9932870876",
                               "Phone_No1": "9932870876",
                               "Phone_No2": "",
                               "Latitude": "22.8774683",
                               "Longitude": "88.0098385"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4859",
                               "Franchisee_Name": "DIWAN ACADEMIC INTELLIGENCE PRIVATE LIMITED",
                               "Operating_Status": "A",
                               "Address1": "P-177 CIT Scheme Road",
                               "Address2": "Scheme VII-M",
                               "Place": "Maniktolla ,Near Ultadanga",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4859@kidzee.com",
                               "Mobile_No": "9339994136",
                               "Phone_No1": "9339994136",
                               "Phone_No2": "",
                               "Latitude": "22.590248",
                               "Longitude": "88.392514"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5052",
                               "Franchisee_Name": "Kidzee Patulia",
                               "Operating_Status": "A",
                               "Address1": "Hazrabari, Baisyapara,",
                               "Address2": "Khardha,",
                               "Place": "Patulia, Opp Patulia Panchayat Office,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5052@kidzee.com",
                               "Mobile_No": "6289467791",
                               "Phone_No1": "9968282766",
                               "Phone_No2": "",
                               "Latitude": "22.736528",
                               "Longitude": "88.387597"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5311",
                               "Franchisee_Name": "Kidzee Akankhamore",
                               "Operating_Status": "A",
                               "Address1": "09/628, Action Area -1 ",
                               "Address2": "New Town ",
                               "Place": "Akankhamore ,Near Highland Woods",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5311@kidzee.com",
                               "Mobile_No": "8296415480",
                               "Phone_No1": "9449263237",
                               "Phone_No2": "",
                               "Latitude": "22.619162",
                               "Longitude": "88.46910"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5108",
                               "Franchisee_Name": "Kidzee Parnashree Palli",
                               "Operating_Status": "A",
                               "Address1": "P-105, Sagar manna Road",
                               "Address2": "Parnashree Palli",
                               "Place": "Parnashree, Behind Parnashree Palli Thana",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5108@kidzee.com",
                               "Mobile_No": "7605840600",
                               "Phone_No1": "7605840600",
                               "Phone_No2": "",
                               "Latitude": "22.3035",
                               "Longitude": "88.1832"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4868",
                               "Franchisee_Name": "Kidzee Bolpur",
                               "Operating_Status": "A",
                               "Address1": "28/1 ,Mission Compound, Jayguru",
                               "Address2": "Birbhum",
                               "Place": "Bolpur ,Mission compound",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4868@kidzee.com",
                               "Mobile_No": "7584897769",
                               "Phone_No1": "8116977465",
                               "Phone_No2": "",
                               "Latitude": "23.66611",
                               "Longitude": "87.69711"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4895",
                               "Franchisee_Name": "Kidzee Bishnupur",
                               "Operating_Status": "A",
                               "Address1": "Arihant Garden,",
                               "Address2": "SRCM Road, Chptto Chandpur, Bishnupur,",
                               "Place": "Rajarhat, Near Arihant Garden,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4895@kidzee.com",
                               "Mobile_No": "7439330044",
                               "Phone_No1": "7439330044",
                               "Phone_No2": "",
                               "Latitude": "22.62334",
                               "Longitude": "88.49286"
                            },
                            {
                               "Franchisee_Code": "E-R-S-4940",
                               "Franchisee_Name": "Kidzee Shapoorji Pallonji",
                               "Operating_Status": "A",
                               "Address1": "Patharghata",
                               "Address2": "Near Shapoorji, Rajarhat. Newtown",
                               "Place": "New Town ,Shapoorji C block gate",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4940@kidzee.com",
                               "Mobile_No": "8777576290",
                               "Phone_No1": "8777576290",
                               "Phone_No2": "",
                               "Latitude": "22.56826",
                               "Longitude": "88.50866"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4963",
                               "Franchisee_Name": "Kidzee Girish Park",
                               "Operating_Status": "A",
                               "Address1": "15 Balaram Dey Street",
                               "Address2": "Mahila Parishad",
                               "Place": "Girish Park ,Mahila Parishad",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4963@kidzee.com",
                               "Mobile_No": "9831669996",
                               "Phone_No1": "9831669996",
                               "Phone_No2": "",
                               "Latitude": "22.58735",
                               "Longitude": "88.36003"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5012",
                               "Franchisee_Name": "Kidzee Kaikhali",
                               "Operating_Status": "A",
                               "Address1": "4/1,Kaikhali Chiriamore, Near-Rajarhat,",
                               "Address2": "Gopalpur, P.S.Dumdum Airport",
                               "Place": "Kaikhali, Near-Chiriyamore,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5012@kidzee.com",
                               "Mobile_No": "7439240424",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "22.63435",
                               "Longitude": "88.43931"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5175",
                               "Franchisee_Name": "Kidzee Salt Lake Logical Wizard",
                               "Operating_Status": "A",
                               "Address1": "AD-16,",
                               "Address2": "Sector-1,",
                               "Place": "Salt - Lake, Near AD Block",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5175@kidzee.com",
                               "Mobile_No": "9831171319",
                               "Phone_No1": "9831171319",
                               "Phone_No2": "",
                               "Latitude": "22.59887",
                               "Longitude": "88.40579"
                            },
                            {
                               "Franchisee_Code": "E-R-S-4444",
                               "Franchisee_Name": "Kidzee H B Town",
                               "Operating_Status": "A",
                               "Address1": "HB Town, Road No-5,",
                               "Address2": "Holding No-23, Ward No-31,",
                               "Place": "Sodepur, H B Town,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4444@kidzee.com",
                               "Mobile_No": "6291670877",
                               "Phone_No1": "9073035652.",
                               "Phone_No2": "",
                               "Latitude": "22.69517",
                               "Longitude": "88.39271"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4016",
                               "Franchisee_Name": "KIDZEE MALL ROAD",
                               "Operating_Status": "A",
                               "Address1": "R. N-13/5/D/.Ground Floor,",
                               "Address2": "Off East Mall Road,",
                               "Place": "Raghunathpur, Behind- Space Town,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4016@kidzee.com",
                               "Mobile_No": "9748761657",
                               "Phone_No1": "033111111",
                               "Phone_No2": "",
                               "Latitude": "22.62821",
                               "Longitude": "88.42899"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3988",
                               "Franchisee_Name": "Kidzee  Parnoshree",
                               "Operating_Status": "A",
                               "Address1": "17/2 Parui Para Road,",
                               "Address2": "2 No. Basudebpur, Near Bakultala,Po.Sarsuna",
                               "Place": "PS Parnoshree ,Near Bakultala",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3988@kidzee.com",
                               "Mobile_No": "7004609491",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "26.11485",
                               "Longitude": "85.35870"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5207",
                               "Franchisee_Name": "Kidzee Madahyamgram",
                               "Operating_Status": "A",
                               "Address1": "SRINAGAR , 3 no Gate , Madhyamgram ",
                               "Address2": "North 24 parganas",
                               "Place": "Madhyamgram ,Near 3 No Gate",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5207@kidzee.com",
                               "Mobile_No": "9875527051",
                               "Phone_No1": "7399782244",
                               "Phone_No2": "",
                               "Latitude": "22.69357",
                               "Longitude": "88.45395"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4153",
                               "Franchisee_Name": "Kidzee James Long Sarani",
                               "Operating_Status": "A",
                               "Address1": "26/1, Ground Floor,",
                               "Address2": "S N Chatterjee Road,",
                               "Place": "James Long Sarani, Near-Xclusive Showroom,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4153@kidzee.com",
                               "Mobile_No": "9830654789",
                               "Phone_No1": "033111111",
                               "Phone_No2": "",
                               "Latitude": "22.504870",
                               "Longitude": "88.322490"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5409",
                               "Franchisee_Name": "Kidzee Salt -Lake",
                               "Operating_Status": "A",
                               "Address1": "FD 250",
                               "Address2": "NEAR TANK NO 11 ,Sector -3 , PS .Salt LAKE",
                               "Place": "Salt -Lake",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4838@kidzee.com",
                               "Mobile_No": "8335056544",
                               "Phone_No1": "9748418960",
                               "Phone_No2": "",
                               "Latitude": "22.581069",
                               "Longitude": "88.412823"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5556",
                               "Franchisee_Name": "Kidzee Sealdah",
                               "Operating_Status": "A",
                               "Address1": "2/1, SERPENTINE LANE",
                               "Address2": "PS-MUCHIPARA",
                               "Place": "SEALDAH",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5556@kidzee.com",
                               "Mobile_No": "7439330963",
                               "Phone_No1": "7439330963",
                               "Phone_No2": "",
                               "Latitude": "22.565236",
                               "Longitude": "88.364599"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4257",
                               "Franchisee_Name": "Kidzee Subhas Udyan",
                               "Operating_Status": "A",
                               "Address1": "68-B, Ground Floor,",
                               "Address2": "Hazi Md, Mohasin Road,",
                               "Place": "Budge Budge, Inactive",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4257@kidzee.com",
                               "Mobile_No": "7003623172",
                               "Phone_No1": "7003623172",
                               "Phone_No2": "",
                               "Latitude": "22.4778° N",
                               "Longitude": "88.1827° E"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4427",
                               "Franchisee_Name": "Kidzee Gopalpur",
                               "Operating_Status": "A",
                               "Address1": "Kalipark United Club,",
                               "Address2": "Rajarhat,",
                               "Place": "Gopalpur, Near Kalipark United Club,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4427@kidzee.com",
                               "Mobile_No": "9957565331",
                               "Phone_No1": "9957565331",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5487",
                               "Franchisee_Name": "KIDZEE SHYAMBAZAR",
                               "Operating_Status": "A",
                               "Address1": "43, Ramakanta Bose Street",
                               "Address2": ".P.O -Shyambazar , P.S - Shyampukur",
                               "Place": "SHYAMBAZAR ",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5487@kidzee.com",
                               "Mobile_No": "7595924092",
                               "Phone_No1": "9830013113",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5622",
                               "Franchisee_Name": "Kidzee Dum Dum Park",
                               "Operating_Status": "A",
                               "Address1": "D-95 BANGUR AVENUE,",
                               "Address2": "Block D,",
                               "Place": "Dum Dum Park",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5622@kidzee.com",
                               "Mobile_No": "7999719229",
                               "Phone_No1": "7999719229",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "A-E-A-1056",
                               "Franchisee_Name": "Ankurum Patharghata",
                               "Operating_Status": "A",
                               "Address1": "New Town,",
                               "Address2": "Rajarhat -Dist,",
                               "Place": "Pathargahata,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "satyasaran1984@gmail.com",
                               "Mobile_No": "7890377371",
                               "Phone_No1": "7890377371",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5631",
                               "Franchisee_Name": "Kidzee Atghara",
                               "Operating_Status": "A",
                               "Address1": "Ranapara Dist",
                               "Address2": "south 24 paraganas Dag No 208",
                               "Place": "Atghara",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5631@kidzee.com",
                               "Mobile_No": "8918760917",
                               "Phone_No1": "8918760917",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "A-E-A-1017",
                               "Franchisee_Name": "ANKURUM S D Education",
                               "Operating_Status": "A",
                               "Address1": "74 Nutanchati",
                               "Address2": "Near P C Chandra Jewellers",
                               "Place": "Bankura. ,Near P.C. Chandra Jewellers",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4915@kidzee.com",
                               "Mobile_No": "9474929590",
                               "Phone_No1": "9474929590",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-4196",
                               "Franchisee_Name": "Kidzee Baduria",
                               "Operating_Status": "A",
                               "Address1": "Safirabad,",
                               "Address2": "Basirhat Dist, North 24 Paraganas,",
                               "Place": "Baduria, Near- Milan Sangha Club,",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4196@kidzee.com",
                               "Mobile_No": "9433731015",
                               "Phone_No1": "9433731015",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5731",
                               "Franchisee_Name": "Kidzee Action Area",
                               "Operating_Status": "A",
                               "Address1": "AAIIB/1406",
                               "Address2": "Premises No 53/620",
                               "Place": "Action Area",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5731@kidzee.com",
                               "Mobile_No": "7980341905",
                               "Phone_No1": "9836122583",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5370",
                               "Franchisee_Name": "Kidzee Uluberia",
                               "Operating_Status": "A",
                               "Address1": "Bazar Para, near Jeevandeep Nurshng home , ",
                               "Address2": "Dist Howrah, Near Sajakrishna Apartments",
                               "Place": "Uluberia",
                               "City_Id": "583",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5370@kidzee.com",
                               "Mobile_No": "8240801599",
                               "Phone_No1": "8657446295",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Konnagar / Konnagore",
                         "Franchisee": {
                            "Franchisee_Code": "E-S-C-2016",
                            "Franchisee_Name": "kidzee Konnagar Hooghly",
                            "Operating_Status": "A",
                            "Address1": "71/104 S.C.Chatterjee Road, Plot No-61,JL No-7",
                            "Address2": "Kalitala",
                            "Place": "Konnagar, Near- Sakuntala Kali Mandir,",
                            "City_Id": "591",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2016@kidzee.com",
                            "Mobile_No": "9748418882",
                            "Phone_No1": "9007684533",
                            "Phone_No2": "",
                            "Latitude": "22.700473",
                            "Longitude": "88.319072"
                         }
                      },
                      {
                         "City_Name": "Malda",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2287",
                               "Franchisee_Name": "Kidzee English Bazar Malda WB",
                               "Operating_Status": "A",
                               "Address1": "182/17 Vivekananda Pally,",
                               "Address2": "P.O Malda, P.S. English Bazar",
                               "Place": "English Bazar, Vivekananda Pally",
                               "City_Id": "637",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2287@kidzee.com",
                               "Mobile_No": "8250619178",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "25.008252765151973",
                               "Longitude": "88.14000708067329",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/743768/11084/e9b1fd85_3e0b_4865_93a0_fa42f6fd3e17.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/743768/11084/985d27a9_a33c_4f29_b9dd_6db24600477d.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-3268",
                               "Franchisee_Name": "Kidzee Gour Road, Malda",
                               "Operating_Status": "A",
                               "Address1": "Plot No.1629, Goaltuli Lane,",
                               "Address2": "Mokdumpur,",
                               "Place": "Gour Road, Malda, Gour Road Bazar",
                               "City_Id": "637",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3268@kidzee.com",
                               "Mobile_No": "9593200469",
                               "Phone_No1": "9593200469",
                               "Phone_No2": "",
                               "Latitude": "24.992504",
                               "Longitude": "88.143309"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5205",
                               "Franchisee_Name": "Kidzee Chanchal",
                               "Operating_Status": "A",
                               "Address1": "village Chanchal Durgabari more ",
                               "Address2": "P.S& P.O - Chanchal",
                               "Place": "Chanchal ,Opposite of Wbsedcl,Near Central Bank of",
                               "City_Id": "637",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5205@kidzee.com",
                               "Mobile_No": "9083856835",
                               "Phone_No1": "9083856835",
                               "Phone_No2": "",
                               "Latitude": "25.39102",
                               "Longitude": "88.00636"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5678",
                               "Franchisee_Name": "Kidzee Gazole",
                               "Operating_Status": "A",
                               "Address1": "Tulshidanga, nayabajar,",
                               "Address2": "Malda",
                               "Place": "Gazole",
                               "City_Id": "637",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5678@kidzee.com",
                               "Mobile_No": "9804293083",
                               "Phone_No1": "9804293083",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Midnapore",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-C-639",
                               "Franchisee_Name": "KIDZEE-MIDNAPUR-WB -World mart- World Mart",
                               "Operating_Status": "A",
                               "Address1": "Parul Smriti Bhavan,",
                               "Address2": "Post Office Midnapur",
                               "Place": "Dist- Paschim Midnapur, Near-Jugnitala Chawk,",
                               "City_Id": "667",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee639@kidzee.com",
                               "Mobile_No": "9434005478",
                               "Phone_No1": "322271723",
                               "Phone_No2": "",
                               "Latitude": "22.409031995810366",
                               "Longitude": "87.31573429447747"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3599",
                               "Franchisee_Name": "Kidzee Mecheda",
                               "Operating_Status": "A",
                               "Address1": "Mouza Santipur",
                               "Address2": "ADSR Kolaghat Dist-Purab ",
                               "Place": "Mecheda ,Beside of Regal Guest House",
                               "City_Id": "667",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3599@kidzee.com",
                               "Mobile_No": "9932368052",
                               "Phone_No1": "03228231007",
                               "Phone_No2": "",
                               "Latitude": "22.401323844947626",
                               "Longitude": "87.87001222372055"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4415",
                               "Franchisee_Name": "Kidzee Ghatal",
                               "Operating_Status": "A",
                               "Address1": "Ward No 3,",
                               "Address2": "Krishnanagar,",
                               "Place": "Ghatal ,Near Ghatal Bus Stand,",
                               "City_Id": "667",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4415@kidzee.com",
                               "Mobile_No": "9382812566",
                               "Phone_No1": "9126785045",
                               "Phone_No2": "",
                               "Latitude": "22.6615534",
                               "Longitude": "87.7298128",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/9a38a563_f2a7_4500_bf37_0573d9bac11b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/68623473_6bb2_4223_af65_481843bf78aa.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/1412dd06_4547_4162_9618_d9c542aa387b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/1e05f292_e41c_4ca9_ad0d_918da868831c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/b6b06200_c338_40b9_87d7_ffa64360d07b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/13763a88_4db3_468c_b22e_102175510fb0.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/4eb1e92c_b555_46bd_8982_63d1987091e2.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/0edd0a5c_dcbe_41eb_8df6_8850c26b648d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/89607434_fb4d_41b2_b387_525d247f83fb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/40c623a0_3d0a_413d_8f02_6c4aacd92613.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/da7d9ba3_156a_4215_83e5_d8b280f585bd.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/802c9ef7_46dd_4c85_85bd_2772c69812fc.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/e08b57c7_0a07_4070_aa92_dee692b28d5b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/20a584fb_1651_4218_a097_8331a6de235c.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/eb953381_6c06_4d3b_91a1_171dbb35355d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/7485f4ee_2b82_4d23_bf84_c287dd160a9e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/d322bdc4_2e84_4bf3_a739_6ceda4af0887.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2219790/10934/eec38f5c_ab1a_48cc_8414_01b9427e9db9.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-5260",
                               "Franchisee_Name": "Kidzee Kolaghat",
                               "Operating_Status": "A",
                               "Address1": "Behind Jain Mandir ,Mouza-Barbarisha",
                               "Address2": "Kolaghat Natun Bazar , Dist purba,",
                               "Place": "Kolaghat",
                               "City_Id": "667",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5260@kidzee.com",
                               "Mobile_No": "9434016869",
                               "Phone_No1": "9932368052",
                               "Phone_No2": "",
                               "Latitude": "22.26481",
                               "Longitude": "87.52248"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5327",
                               "Franchisee_Name": "Kidzee Dharma",
                               "Operating_Status": "A",
                               "Address1": "West Midnapore",
                               "Address2": "Colonelgola,",
                               "Place": "Dharma",
                               "City_Id": "667",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5327@kidzee.com",
                               "Mobile_No": "9836473564",
                               "Phone_No1": "9836473564",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5304",
                               "Franchisee_Name": "Kidzee  Satbankura Chandrakona Road",
                               "Operating_Status": "A",
                               "Address1": "Vill-Durlabhganj:P.O SATBANKURA : P.S- Garhbeta :Garhbeta -3",
                               "Address2": "Subdivision-Medimipur Sadar , Paschim Medinipur",
                               "Place": "Satbankura Chandrakona Road ,Garhbeta",
                               "City_Id": "667",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5304@kidzee.com",
                               "Mobile_No": "7797356626",
                               "Phone_No1": "7797356626",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5716",
                               "Franchisee_Name": "Kidzee Midnapore",
                               "Operating_Status": "A",
                               "Address1": "Mitra compound ,Near Station Road Durga mondop Post Office",
                               "Address2": "Midnapur Dist Paschim Mednipur ",
                               "Place": "Midnapore",
                               "City_Id": "667",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5716@kidzee.com",
                               "Mobile_No": "7872262787",
                               "Phone_No1": "7872262787",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Mogra / Magra / Mugra"
                      },
                      {
                         "City_Name": "Murshidabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-4729",
                               "Franchisee_Name": "Kidzee Raghunathganj",
                               "Operating_Status": "A",
                               "Address1": "Deuli Miapur",
                               "Address2": "Miapur",
                               "Place": "Raghunathganj ,Near Miyapur vegetable market",
                               "City_Id": "687",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4729@kidzee.com",
                               "Mobile_No": "9733779491",
                               "Phone_No1": "9733779491",
                               "Phone_No2": "",
                               "Latitude": "24.46005",
                               "Longitude": "88.04411"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4987",
                               "Franchisee_Name": "Kidzee Domkal",
                               "Operating_Status": "A",
                               "Address1": "Maniknagar",
                               "Address2": "",
                               "Place": "Domkal ,Near MAB INSTITUTE OF JURIDICAL SCIENCE (L",
                               "City_Id": "687",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4987@kidzee.com",
                               "Mobile_No": "9647190202",
                               "Phone_No1": "8670753290",
                               "Phone_No2": "",
                               "Latitude": "24.139267",
                               "Longitude": "88.556097"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5598",
                               "Franchisee_Name": "Kidzee Kandi",
                               "Operating_Status": "A",
                               "Address1": "Kandi Ward no 17",
                               "Address2": "Beside Kandi police station,",
                               "Place": "Kandi",
                               "City_Id": "687",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5598@kidzee.com",
                               "Mobile_No": "9832134082",
                               "Phone_No1": "9832134082",
                               "Phone_No2": "",
                               "Latitude": "23.9625370",
                               "Longitude": "88.0384110"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5595",
                               "Franchisee_Name": "Kidzee Islampur",
                               "Operating_Status": "A",
                               "Address1": "Nazrulpalli,",
                               "Address2": "Village & P.O. & PS – Islampur,",
                               "Place": "Islampur",
                               "City_Id": "687",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5595@kidzee.com",
                               "Mobile_No": "7551889900",
                               "Phone_No1": "9836342192",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5394",
                               "Franchisee_Name": "Kidzee JIAGANJ",
                               "Operating_Status": "A",
                               "Address1": "GROUND FLOOR, MOUZA-GULZARBAG",
                               "Address2": "VILL-THAKURGRAM P.O-JIAGANJ",
                               "Place": "JIAGANJ",
                               "City_Id": "687",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "admin.baharampur@mountlitera.com",
                               "Mobile_No": "9933004920",
                               "Phone_No1": "9933004920",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5723",
                               "Franchisee_Name": "Kidzee Salar",
                               "Operating_Status": "A",
                               "Address1": "Salar New Town,",
                               "Address2": "Near Block Office,",
                               "Place": "Salar",
                               "City_Id": "687",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5723@kidzee.com",
                               "Mobile_No": "9831877568",
                               "Phone_No1": "9831877568",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5570",
                               "Franchisee_Name": "Kidzee Beldanga",
                               "Operating_Status": "A",
                               "Address1": "Yusuf Mantion,  Mouza Barua",
                               "Address2": "Uttarpara, Murshidabad",
                               "Place": "Beldanga",
                               "City_Id": "687",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5570@kidzee.com",
                               "Mobile_No": "9836182353",
                               "Phone_No1": "9836182353",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Naihati",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2785",
                            "Franchisee_Name": "Kidzee  Blossom Naihati",
                            "Operating_Status": "A",
                            "Address1": "11, Baroda Road, Near-Katayani Girls High school,",
                            "Address2": "P.O & P.S- Naihati, Dist: 24 pgs North,",
                            "Place": "Naihati, Near Katayani Girls High School,",
                            "City_Id": "704",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2785@kidzee.com",
                            "Mobile_No": "9830238072",
                            "Phone_No1": "9830238072",
                            "Phone_No2": "",
                            "Latitude": "22.88263",
                            "Longitude": "88.434416"
                         }
                      },
                      {
                         "City_Name": "Podrah / Podra / Podara"
                      },
                      {
                         "City_Name": "Raiganj",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-M-422",
                               "Franchisee_Name": "KIDZEE-RAIGANJ",
                               "Operating_Status": "A",
                               "Address1": "Netaji Pally,",
                               "Address2": "",
                               "Place": "Mohanbati",
                               "City_Id": "816",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee422@kidzee.com",
                               "Mobile_No": "9434130605",
                               "Phone_No1": "03523-249357",
                               "Phone_No2": "",
                               "Latitude": "25.61853",
                               "Longitude": "88.125584"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5026",
                               "Franchisee_Name": "Kidzee Uttar Dinajpur",
                               "Operating_Status": "A",
                               "Address1": "College Para",
                               "Address2": "Opp Childrens Park",
                               "Place": "Uttar Dinajpur",
                               "City_Id": "816",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5026@kidzee.com",
                               "Mobile_No": "7001936051",
                               "Phone_No1": "7001936051",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Ranaghat",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4732",
                            "Franchisee_Name": "Kidzee Ranaghat",
                            "Operating_Status": "A",
                            "Address1": "51/1 Silverjubili Road",
                            "Address2": "Dakshin Para, Nadia",
                            "Place": "Ranaghat ,Near PWD Office",
                            "City_Id": "831",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4732@kidzee.com",
                            "Mobile_No": "9332601292",
                            "Phone_No1": "9333004309",
                            "Phone_No2": "",
                            "Latitude": "23.1758480",
                            "Longitude": "88.5592490"
                         }
                      },
                      {
                         "City_Name": "Rishra"
                      },
                      {
                         "City_Name": "Serampore",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-A-M-1332",
                               "Franchisee_Name": "Kidzee - Srerampur - Hoogly",
                               "Operating_Status": "A",
                               "Address1": "8 Raja K.L,",
                               "Address2": "Goswami Street,",
                               "Place": "Srerampur, Hoogly, On Raja K L Goswami Street,",
                               "City_Id": "884",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1332@kidzee.com",
                               "Mobile_No": "9831198411",
                               "Phone_No1": "9831198411",
                               "Phone_No2": "",
                               "Latitude": "22.759759557738175",
                               "Longitude": "88.3406400383758",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1013/10991/947ce94e_361b_4ade_8917_ff9674109eb6.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-R-C-4345",
                               "Franchisee_Name": "Kidzee Mahesh",
                               "Operating_Status": "A",
                               "Address1": "2A Sadgope Para Lane",
                               "Address2": "P.O - Mahesh",
                               "Place": "P. S Serampore, Hoogly",
                               "City_Id": "884",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4345@kidzee.com",
                               "Mobile_No": "0326522222",
                               "Phone_No1": "9830332401",
                               "Phone_No2": "",
                               "Latitude": "22.7413181046221",
                               "Longitude": "88.35313629032134"
                            }
                         ]
                      },
                      {
                         "City_Name": "Siliguri",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-A-M-1226",
                               "Franchisee_Name": "Kidzee- Pradhan Nagar- Siliguri- Aditya Nanda Academy",
                               "Operating_Status": "A",
                               "Address1": "Kamal Kunj,",
                               "Address2": "Ground Floor,",
                               "Place": "Pradhan Nagar, Opp Shivakoti Printing Press,",
                               "City_Id": "906",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1226@kidzee.com",
                               "Mobile_No": "9002000808",
                               "Phone_No1": "03532514468",
                               "Phone_No2": "",
                               "Latitude": "26.72711444717304",
                               "Longitude": "88.41856725379375",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/945/10680/03993652_0a11_4078_bf72_81389b53b1ba.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-A-M-227",
                               "Franchisee_Name": "Kidzee- South Bapupara Siliguri- WB- S G Foundation",
                               "Operating_Status": "A",
                               "Address1": "Srima Sarani,",
                               "Address2": "South Babupara, Siliguri,",
                               "Place": "South Babupara ,Opposite krishna kunj bhavan",
                               "City_Id": "906",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee227@kidzee.com",
                               "Mobile_No": "8779329048",
                               "Phone_No1": "0810-1011839",
                               "Phone_No2": "",
                               "Latitude": "26.6989",
                               "Longitude": "88.425441"
                            },
                            {
                               "Franchisee_Code": "E-R-C-3443",
                               "Franchisee_Name": "Kidzee Bhaktinagar",
                               "Operating_Status": "A",
                               "Address1": "Near-Agrani Sangha Club,",
                               "Address2": "Ward No-35, Netaji Subhash Bose Road,",
                               "Place": "Bhaktinagar, New Jalpaiguri, Near Agrani Sangha Cl",
                               "City_Id": "906",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3443@kidzee.com",
                               "Mobile_No": "7602855034",
                               "Phone_No1": "7602855034",
                               "Phone_No2": "",
                               "Latitude": "26.6907",
                               "Longitude": "88.4385"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4617",
                               "Franchisee_Name": "Kidzee Shivmandir Siliguri",
                               "Operating_Status": "A",
                               "Address1": "Shivmandir, R S Plot No.77,Bagdogra..",
                               "Address2": "PO Matigara,Darjeeling",
                               "Place": "Phansidewa More ,Shivmandir, ",
                               "City_Id": "906",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4617@kidzee.com",
                               "Mobile_No": "9593224245",
                               "Phone_No1": "9593224245",
                               "Phone_No2": "",
                               "Latitude": "26.71303115198181",
                               "Longitude": "88.36752465518336",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2496441/11067/721fb138_0820_4db2_a70f_a0cd7a9ebf37.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2496441/11067/1b5df260_0b20_4216_ab05_f8dbb152a28f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2496441/11067/f9ee7634_ee08_4fca_b32f_07853c158fe7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2496441/11067/29b4de51_b6a0_4404_9506_2bb7a285a929.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2496441/11067/1c0a6c44_dd3e_4e40_8312_342a94f52c95.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2496441/11067/2c9718b8_db01_4b98_84b4_75f4ddbbc3ee.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2496441/11067/c316cbc2_550b_4811_9c6a_48635135ceb1.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2496441/11067/ba8debca_6333_4d3c_84f2_07cc3d16180f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2496441/11067/ba559a0d_1896_4e06_9d37_a08e2c24927d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2496441/11067/35ddc36b_c0c8_4d6b_ad59_056affde8c68.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "E-R-C-5798",
                               "Franchisee_Name": "Kidzee Salugara",
                               "Operating_Status": "A",
                               "Address1": "Samadhan Kendra, Debi Choudhurani road, Ruma Bhawan,",
                               "Address2": "Near Rose Valley Apartment.",
                               "Place": "Salugara",
                               "City_Id": "906",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5798@kidzee.com",
                               "Mobile_No": "9036769432",
                               "Phone_No1": "9036769432",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-4414",
                               "Franchisee_Name": "Kidzee Kalamjote",
                               "Operating_Status": "A",
                               "Address1": "Medical More,",
                               "Address2": "",
                               "Place": "Kalamjote ,Behind Shantiniketan,",
                               "City_Id": "906",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4414@kidzee.com",
                               "Mobile_No": "9474569853",
                               "Phone_No1": "9474569853",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "A-E-A-1060",
                               "Franchisee_Name": "Ankurum Khaprail",
                               "Operating_Status": "A",
                               "Address1": "Sainikpuri , Khaprail",
                               "Address2": "Dist -Darjelling,P.0 Chamta,",
                               "Place": "Matigara ,Khaprail Sainikpuri Block B P.0 New Chamta",
                               "City_Id": "906",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "anitagiriroy@gmail.com  ",
                               "Mobile_No": "9635143286",
                               "Phone_No1": "9635143286",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-5529",
                               "Franchisee_Name": "Kidzee Pradhan Nagar",
                               "Operating_Status": "A",
                               "Address1": "Pradhan Nagar ward no 2 (SMC)",
                               "Address2": "PO & PS Pradhan Nagar , Dist Darjeeling",
                               "Place": "Pradhan Nagar",
                               "City_Id": "906",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5529@kidzee.com",
                               "Mobile_No": "8972089602",
                               "Phone_No1": "8972089602",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "E-R-C-4244",
                               "Franchisee_Name": "Kidzee Netaji Colony",
                               "Operating_Status": "A",
                               "Address1": "Ramkrishna Sarani, Netaji Colony,",
                               "Address2": "Ward No.37, Siliguri Municipal Corporation,",
                               "Place": "Netaji Colony, Near-Jagat Bandhu Ashram,",
                               "City_Id": "906",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4244@kidzee.com",
                               "Mobile_No": "9064583408",
                               "Phone_No1": "9735014645",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Sodepur"
                      },
                      {
                         "City_Name": "Sheoraphuli / Seoraphuli"
                      },
                      {
                         "City_Name": "Uttarpara",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-U-C-594",
                               "Franchisee_Name": "KIDZEE - Uttarpara-Hooghly-West Bengal, Fresh Fragrance",
                               "Operating_Status": "A",
                               "Address1": "258,",
                               "Address2": "",
                               "Place": "G T Road, Near-Kheya Ghat,",
                               "City_Id": "989",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee594@kidzee.com",
                               "Mobile_No": "6291499719",
                               "Phone_No1": "9903739914",
                               "Phone_No2": "",
                               "Latitude": "22.665982",
                               "Longitude": "88.3508"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5243",
                               "Franchisee_Name": "Kidzee Makhla",
                               "Operating_Status": "A",
                               "Address1": "125, MANICKTALA, MAKHLA",
                               "Address2": "( NEAR MANORAJAN BHAVAN)",
                               "Place": "HOOGHLY ,NEAR MANORAJAN BHAVAN",
                               "City_Id": "989",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5243@kidzee.com",
                               "Mobile_No": "9632161926",
                               "Phone_No1": "9632161926",
                               "Phone_No2": "",
                               "Latitude": "22.669808",
                               "Longitude": "88.333225"
                            }
                         ]
                      },
                      {
                         "City_Name": "Falakata",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-1872",
                            "Franchisee_Name": "Kidzee-Falakata",
                            "Operating_Status": "A",
                            "Address1": "Vill. Subhashpally,",
                            "Address2": "Dak Banglow Road, Falakata,",
                            "Place": "Dist-Jalpaiguri, Subhashpally,",
                            "City_Id": "1120",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1872@kidzee.com",
                            "Mobile_No": "9641221339",
                            "Phone_No1": "",
                            "Phone_No2": "",
                            "Latitude": "26.517486",
                            "Longitude": "89.203919"
                         }
                      },
                      {
                         "City_Name": "Chakdaha",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4828",
                            "Franchisee_Name": "Kidzee Chakdaha",
                            "Operating_Status": "A",
                            "Address1": "Chhatimtala",
                            "Address2": "Nadia",
                            "Place": "Chakdah ,Behind Chowrasta Power House",
                            "City_Id": "1129",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4828@kidzee.com",
                            "Mobile_No": "9064880703",
                            "Phone_No1": "9064880703",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Shantipur",
                         "Franchisee": {
                            "Franchisee_Code": "E-L-C-1860",
                            "Franchisee_Name": "Aegis Foundation",
                            "Operating_Status": "A",
                            "Address1": "Dakshinpara,",
                            "Address2": "Post: Babla Gobindopur, P.S. Santipur,",
                            "Place": "Dist Nadia ,Vill: Babla Dakshinpara, Near-B.Ed Col",
                            "City_Id": "1154",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1860@kidzee.com",
                            "Mobile_No": "8250771075",
                            "Phone_No1": "8250771075 ",
                            "Phone_No2": "",
                            "Latitude": "23.26454",
                            "Longitude": "88.438097"
                         }
                      },
                      {
                         "City_Name": "Batanagar",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5712",
                            "Franchisee_Name": "Kidzee Asansol",
                            "Operating_Status": "A",
                            "Address1": "Ward No:55,",
                            "Address2": "Paschim Burdwan,",
                            "Place": "Asansol",
                            "City_Id": "1156",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5712@kidzee.com",
                            "Mobile_No": "9735155148",
                            "Phone_No1": "9735155148",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Rajpur sonarpur"
                      },
                      {
                         "City_Name": "Baruipur"
                      },
                      {
                         "City_Name": "Madhyamgram",
                         "Franchisee": {
                            "Franchisee_Code": "E-S-C-1604",
                            "Franchisee_Name": "Kidzee-Madhyamgram",
                            "Operating_Status": "A",
                            "Address1": "H/O. Mr. Ashray Tilak Paul Chawdhary, Vill-Subhashpally,",
                            "Address2": "P.O.Madhyamgram Bazar, 372, Madhyam Main Road, Dist - Paragans (N)",
                            "Place": "North 24 Parganas ,Near Madhyam Gram Bazar",
                            "City_Id": "1159",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1604@kidzee.com",
                            "Mobile_No": "8240677925",
                            "Phone_No1": "033-25380762",
                            "Phone_No2": "",
                            "Latitude": "22.833967",
                            "Longitude": "88.634419"
                         }
                      },
                      {
                         "City_Name": "Duttapukur / Nebadhai duttapukur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2857",
                            "Franchisee_Name": "Kidzee Duttapukur",
                            "Operating_Status": "A",
                            "Address1": "Shivalaya,",
                            "Address2": "P.O. Adi kashimpur, P.S.Duttapukur, Dist.24 prg (North)",
                            "Place": "Near Duttapukur, Police Station",
                            "City_Id": "1160",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2857@kidzee.com",
                            "Mobile_No": "9830467127",
                            "Phone_No1": "9830427127 ",
                            "Phone_No2": "",
                            "Latitude": "22.771136",
                            "Longitude": "88.539613"
                         }
                      },
                      {
                         "City_Name": "Kharagpur"
                      },
                      {
                         "City_Name": "Krishnanagar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2607",
                               "Franchisee_Name": "KIDZEE KRISHNANAGAR",
                               "Operating_Status": "A",
                               "Address1": "284/D,M.G.Road. Opp- Judge Bunglow,",
                               "Address2": "P.O- Krishnanagar, P.S- Kotwali, Dist- Nadia,",
                               "Place": "Krishnanagar, Opposite To Judge Bungalow,",
                               "City_Id": "1194",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2607@kidzee.com",
                               "Mobile_No": "8001069816",
                               "Phone_No1": "8001069816",
                               "Phone_No2": "",
                               "Latitude": "23.400874",
                               "Longitude": "88.501396",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2607/218/10cf39da_6227_4775_b30e_9b27f69fecd8.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "E-R-C-4975",
                               "Franchisee_Name": "Kidzee Bethuadahari",
                               "Operating_Status": "A",
                               "Address1": "Kanthalberia,P.O. & P.S. Nakashipara,",
                               "Address2": "Patuli Ghat Road,",
                               "Place": "Bethuadahari, Near-Kathalbereya Power House,",
                               "City_Id": "1194",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4975@kidzee.com",
                               "Mobile_No": "8444889629",
                               "Phone_No1": "8444889629",
                               "Phone_No2": "",
                               "Latitude": "23.600192",
                               "Longitude": "88.371926"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5058",
                               "Franchisee_Name": "Kidzee Kapileshwar",
                               "Operating_Status": "A",
                               "Address1": "Ward No-14 ,Barajaguli",
                               "Address2": "PS Haringhata,Dist. Nadia",
                               "Place": "Kapileswar ,Near Old Post Office",
                               "City_Id": "1194",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5058@kidzee.com",
                               "Mobile_No": "9933516496",
                               "Phone_No1": "7003279043",
                               "Phone_No2": "",
                               "Latitude": "22.55.56.5",
                               "Longitude": "88.32.39.0"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4635",
                               "Franchisee_Name": "Kidzee Kanthalpota",
                               "Operating_Status": "A",
                               "Address1": "Sukanta Sarani,",
                               "Address2": "P.S.Kotwali, Dist. Nadia,",
                               "Place": "Kanthalpota, Opposite of Indane Gas office",
                               "City_Id": "1194",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2607@kidzee.com",
                               "Mobile_No": "8373806930",
                               "Phone_No1": "8373806930",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Kaliaganj"
                      },
                      {
                         "City_Name": "Bakreshwar"
                      },
                      {
                         "City_Name": "Amtala"
                      },
                      {
                         "City_Name": "Halisahar / Kumarhata",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4489",
                            "Franchisee_Name": "Kidzee Halisahar",
                            "Operating_Status": "A",
                            "Address1": "Maniraipara,Halisahar Station Road,",
                            "Address2": "PO Nabanagar, PS Bizpur,",
                            "Place": "Halisahar ,On Moniraipara,",
                            "City_Id": "1229",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4489@kidzee.com",
                            "Mobile_No": "9163232504",
                            "Phone_No1": "8961149421",
                            "Phone_No2": "",
                            "Latitude": "22.92505",
                            "Longitude": "88.43295"
                         }
                      },
                      {
                         "City_Name": "Chak kashipur"
                      },
                      {
                         "City_Name": "Barrackpore",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2424",
                            "Franchisee_Name": "KIDZEE BARRACKPORE",
                            "Operating_Status": "A",
                            "Address1": "37/1, S.N.Banerjee Road, P.S.Titagarh,",
                            "Address2": "District :North 24 Parganas,",
                            "Place": "Barrackpore ,Opposite to Mio Amore Cake Shop,",
                            "City_Id": "1231",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2424@kidzee.com",
                            "Mobile_No": "9051416843",
                            "Phone_No1": "03325297070",
                            "Phone_No2": "",
                            "Latitude": "22.7607406",
                            "Longitude": "88.368701"
                         }
                      },
                      {
                         "City_Name": "Bhatpara",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3298",
                            "Franchisee_Name": "Kidzee Jagaddal",
                            "Operating_Status": "A",
                            "Address1": "Athpur,",
                            "Address2": "Bhatpara,",
                            "Place": "Jagaddal, Near-Athpur,",
                            "City_Id": "1232",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3298@kidzee.com",
                            "Mobile_No": "9831090133",
                            "Phone_No1": "9831090133",
                            "Phone_No2": "",
                            "Latitude": "22.846046",
                            "Longitude": "88.382881"
                         }
                      },
                      {
                         "City_Name": "Baidyabati"
                      },
                      {
                         "City_Name": "Bally"
                      },
                      {
                         "City_Name": "Bansberia"
                      },
                      {
                         "City_Name": "Bhadrakali"
                      },
                      {
                         "City_Name": "Bhadreswar"
                      },
                      {
                         "City_Name": "Dankuni",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2315",
                            "Franchisee_Name": "KIDZEE CHILD CARE DANKUNI HOOGHLY",
                            "Operating_Status": "A",
                            "Address1": "Kashmira Apartment, North Subhash Pally,",
                            "Address2": "Durgapur Express way, P.O+P.S Dankuni",
                            "Place": "Durgapur Express way,",
                            "City_Id": "1241",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2315@kidzee.com",
                            "Mobile_No": "9681119724",
                            "Phone_No1": "9681119724",
                            "Phone_No2": "",
                            "Latitude": "22.680835",
                            "Longitude": "88.292931"
                         }
                      },
                      {
                         "City_Name": "Hindmotor"
                      },
                      {
                         "City_Name": "Makhla"
                      },
                      {
                         "City_Name": "Raghunathpur"
                      },
                      {
                         "City_Name": "Hooghly",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-4300",
                               "Franchisee_Name": "Kidzee Mankundu",
                               "Operating_Status": "A",
                               "Address1": "Mankundu Station Road,",
                               "Address2": "Chandannagar Dist.",
                               "Place": "Mankundu, Near-Gaurerdhar, Durga Mandir,",
                               "City_Id": "1253",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4300@kidzee.com",
                               "Mobile_No": "8777742873",
                               "Phone_No1": "8777742873",
                               "Phone_No2": "",
                               "Latitude": "22.8447",
                               "Longitude": "88.3568"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5713",
                               "Franchisee_Name": "Kidzee Khanakul",
                               "Operating_Status": "A",
                               "Address1": "Ramnagar Bus Stand, ",
                               "Address2": "P.O.+PS -Khanakul, Dist-Hooghly,",
                               "Place": "Khanakul",
                               "City_Id": "1253",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5713@kidzee.com",
                               "Mobile_No": "9831669612",
                               "Phone_No1": "9831669612",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Andul"
                      },
                      {
                         "City_Name": "Banupur"
                      },
                      {
                         "City_Name": "Baranagar / Barahanagar"
                      },
                      {
                         "City_Name": "Mecheda"
                      },
                      {
                         "City_Name": "Purbasthali"
                      },
                      {
                         "City_Name": "Islampur"
                      },
                      {
                         "City_Name": "Habra",
                         "Franchisee": {
                            "Franchisee_Code": "E-S-C-2059",
                            "Franchisee_Name": "Kidzee Habra West Bengal",
                            "Operating_Status": "A",
                            "Address1": "100/2, Jirat Road,",
                            "Address2": "City-Habra,",
                            "Place": "North 24 Parganas, Near-1, Number Railway Gate,",
                            "City_Id": "1335",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2059@kidzee.com",
                            "Mobile_No": "8373012132",
                            "Phone_No1": "8373012132",
                            "Phone_No2": "",
                            "Latitude": "22.61681",
                            "Longitude": "88.402895"
                         }
                      },
                      {
                         "City_Name": "Islampur, Uttar binajpur"
                      },
                      {
                         "City_Name": "Doharia",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5138",
                            "Franchisee_Name": "KIDZEE DOLTALLA",
                            "Operating_Status": "A",
                            "Address1": "Doltalla Najrul Sarani Block-A,",
                            "Address2": "PS-MadhyamGram, Dist-North 24 Paraganas,",
                            "Place": "Doharia, Doltala, Near-Srijon Complex,",
                            "City_Id": "1412",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5138@kidzee.com",
                            "Mobile_No": "9674132249",
                            "Phone_No1": "9674132249",
                            "Phone_No2": "",
                            "Latitude": "22.680048",
                            "Longitude": "88.462386"
                         }
                      },
                      {
                         "City_Name": "Balurghat",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5745",
                            "Franchisee_Name": "Kidzee Balurghat",
                            "Operating_Status": "A",
                            "Address1": "Mangalpur (Hili More), Beside of Bharat Petroleum Petrol Pump,",
                            "Address2": "Dist Dakkshin Dinajpur",
                            "Place": "Dinajpur",
                            "City_Id": "1473",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5745@kidzee.com",
                            "Mobile_No": "9547470305",
                            "Phone_No1": "9547470305",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Barjora"
                      },
                      {
                         "City_Name": "Purulia",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2806",
                            "Franchisee_Name": "Kidzee Purulia",
                            "Operating_Status": "A",
                            "Address1": "Raghabpur More, Tulsi Apartment, Ground Floor,",
                            "Address2": "PO + DT:",
                            "Place": "Purulia, Near-Reliance Trend,",
                            "City_Id": "1509",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2806@kidzee.com",
                            "Mobile_No": "8370971460",
                            "Phone_No1": "8370971460",
                            "Phone_No2": "",
                            "Latitude": "23.332078",
                            "Longitude": "86.365208"
                         }
                      },
                      {
                         "City_Name": "Shyamnagar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-2786",
                               "Franchisee_Name": "Kidzee Shyamnagar West Bengal",
                               "Operating_Status": "A",
                               "Address1": "190, West Ghosh Para Road,",
                               "Address2": "Near-United Bank of India, P O Shyam Nagar,",
                               "Place": "Garulia Branch, Noapara, Shyamnagar, Near-PNB Bank",
                               "City_Id": "1532",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2786@kidzee.com",
                               "Mobile_No": "7003914464",
                               "Phone_No1": "9830269315",
                               "Phone_No2": "",
                               "Latitude": "22.819708",
                               "Longitude": "88.373919"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4864",
                               "Franchisee_Name": "Kidzee Keutia Kankinara",
                               "Operating_Status": "A",
                               "Address1": "Kundu Bagan",
                               "Address2": "ground floor",
                               "Place": "Kakinara ,Near Kalyan Snagha Club",
                               "City_Id": "1532",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4864@kidzee.com",
                               "Mobile_No": "8583981718",
                               "Phone_No1": "8583981718",
                               "Phone_No2": "",
                               "Latitude": "22.84833951246484",
                               "Longitude": "88.41968560786798"
                            }
                         ]
                      },
                      {
                         "City_Name": "Arambagh",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-2858",
                            "Franchisee_Name": "KIDZEE AARAMBAGH  HOOGHLY",
                            "Operating_Status": "A",
                            "Address1": "Pashchim, Krishnapur,",
                            "Address2": "Milan Mela,",
                            "Place": "Aarambagh, Hooghly, Court Para, Near-SDO Office",
                            "City_Id": "1533",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2858@kidzee.com",
                            "Mobile_No": "9735996199",
                            "Phone_No1": "9735996199",
                            "Phone_No2": "",
                            "Latitude": "22.8840443376713",
                            "Longitude": "87.7830737829208",
                            "Images": {
                               "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1144459/10798/4b1b8ab5_8617_4408_b328_d652278b068e.jpg"
                            }
                         }
                      },
                      {
                         "City_Name": "Jaigaon"
                      },
                      {
                         "City_Name": "Majdia"
                      },
                      {
                         "City_Name": "Singur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5623",
                            "Franchisee_Name": "Kidzee Jalaghata",
                            "Operating_Status": "A",
                            "Address1": "Ground Floor",
                            "Address2": "Dist Hooghly",
                            "Place": "Jalaghata",
                            "City_Id": "1578",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5623@kidzee.com",
                            "Mobile_No": "7003327242",
                            "Phone_No1": "7003327242",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Bongaon"
                      },
                      {
                         "City_Name": "Belghoria",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5077",
                            "Franchisee_Name": "KIDZEE BELGHARIA",
                            "Operating_Status": "A",
                            "Address1": "15/3 M B Road",
                            "Address2": "Ground Floor",
                            "Place": "Belghoria ,Near MB Road Govt Quarter",
                            "City_Id": "1598",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5077@kidzee.com",
                            "Mobile_No": "9830686389",
                            "Phone_No1": "9831823253",
                            "Phone_No2": "",
                            "Latitude": "22.66215",
                            "Longitude": "88.39467"
                         }
                      },
                      {
                         "City_Name": "Katwa",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3343",
                            "Franchisee_Name": "Kidzee Katwa  Fun & Learn Enterprise",
                            "Operating_Status": "A",
                            "Address1": "Bhutnath Tala,",
                            "Address2": "PO:",
                            "Place": "Katwa, Burdwan, Sri Ram Bhawan, Bhutnath Tala,",
                            "City_Id": "1605",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "kidzee3343@kidzee.com",
                            "Mobile_No": "8961653336",
                            "Phone_No1": "3453-256065",
                            "Phone_No2": "",
                            "Latitude": "23.643836891084057",
                            "Longitude": "88.13226533862303"
                         }
                      },
                      {
                         "City_Name": "New jalpaiguri"
                      },
                      {
                         "City_Name": "Dhubulia"
                      },
                      {
                         "City_Name": "Rampurhat",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5398",
                            "Franchisee_Name": "Kidzee Rampurhat",
                            "Operating_Status": "A",
                            "Address1": "Near-Masjid,",
                            "Address2": "Ward No-9.P.O & P.S- Rampurhat,",
                            "Place": "Brahmoni Gram,",
                            "City_Id": "1659",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5398@kidzee.com",
                            "Mobile_No": "9832829680",
                            "Phone_No1": "9832829680",
                            "Phone_No2": "",
                            "Latitude": "27.178496",
                            "Longitude": "87.795103"
                         }
                      },
                      {
                         "City_Name": "KAKDWIP"
                      },
                      {
                         "City_Name": "DHUPGURI"
                      },
                      {
                         "City_Name": "Bishnupur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4204",
                            "Franchisee_Name": "Kidzee Bishnupur",
                            "Operating_Status": "A",
                            "Address1": "House of Late Ashamukul Roy,",
                            "Address2": "Bishnupur,",
                            "Place": "Sal Bagan, Near-Durga Mandir,",
                            "City_Id": "1833",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4204@kidzee.com",
                            "Mobile_No": "9434580333",
                            "Phone_No1": "8900187070 ",
                            "Phone_No2": "",
                            "Latitude": "23.063253",
                            "Longitude": "87.316515",
                            "Images": [
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10725/227445a9_61db_4d84_a3e4_8560ae3cda0e.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/8ee4a458_39ab_4fb3_9002_d8ae301eef44.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/e5256842_ac47_4fef_b4dd_ed36e5968f1b.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/1a037d0b_50e7_471c_8640_8c855d887c91.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/fb4f6698_74eb_494b_a3ae_549a7224fd6a.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/6781354a_1ff2_4ed6_9140_969016c6ec57.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/76ad578d_a73a_41c7_afde_9c5ce5645ee1.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/14aecc10_e7eb_473c_b3e7_698b11152381.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/9aa89e25_0d2a_4527_b59f_9dbfa489ff4d.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/caf81ace_fb69_4417_a352_7d1e55e5c298.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/5fdd4c2f_86c1_42a6_aaad_7b22c571be2b.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/0c028821_6bee_4658_befa_9c207df377fb.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/7f68852a_8076_4050_ba93_12f727adfd27.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/17e759f9_0b1a_44ed_9981_352adecb7401.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/e24b814b_81db_4438_8161_f71fcf1eac69.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/1899c53d_8f87_4698_913f_8fac34fd0c1c.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10726/8293c02a_182b_4df5_84de_60c4eb49a28b.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10765/ba8a732c_8a16_459d_ac92_e9398423bed9.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10765/1ac027fd_4730_45ee_9677_b048dbe54c8a.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10765/0babcdb4_306b_440c_8325_3cfaa4478150.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10765/128048d4_55db_4202_a43e_939defea5615.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10765/359bb285_e79c_413f_9e0b_09c181405f4e.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10765/5ae97751_6fb5_4070_8175_40792612f34e.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10766/e0d5735d_fb7c_47b3_8a61_b3737f82a5e0.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10766/bf06b02c_c212_426f_8bb4_c0d82dc09214.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10766/4c2cd52a_972a_4ce0_91f1_ecf4bdf49c87.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10766/6b77e5a6_c794_404f_981d_2d273c19efaf.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10766/5a1b0067_8718_4d2b_a780_f77e38588f85.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10778/bef86485_bc33_4b9a_a860_b75758e6a8c1.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10778/db6f1f90_fcb8_4477_9392_92972ccc907c.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10778/a9c65970_0612_461c_b171_108af2879199.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10778/075a5ef6_ae2c_4d67_91e9_cf146079b1c4.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10778/c4515678_bd98_4c32_ad5d_8ef12b418102.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10778/b34c2aa4_5f72_41f0_addd_b09c93b25a68.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10778/cb2b877a_2779_49f2_a620_a179760ea142.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10778/d6e338a9_f932_4fff_9181_c4fe264c94e7.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10779/340b210e_eff5_4cf9_915e_d686bfc421a8.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/2098269/10779/653f2fc3_febe_4d07_a725_6ad5e7c8dd98.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Tamluk",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4209",
                            "Franchisee_Name": "Kidzee Tamluk",
                            "Operating_Status": "A",
                            "Address1": "Vill. D.C. Sankarara,",
                            "Address2": "P.O. & P.S. Tamluk,",
                            "Place": "Purba Midnapur,Tamluk , Nimtolamore Tamluk Kidzee,",
                            "City_Id": "1836",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4209@kidzee.com",
                            "Mobile_No": "9614266281",
                            "Phone_No1": "9614266281",
                            "Phone_No2": "",
                            "Latitude": "21.9373° N",
                            "Longitude": "87.7763° E"
                         }
                      },
                      {
                         "City_Name": "Memari",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-3851",
                            "Franchisee_Name": "Kidzee Memari",
                            "Operating_Status": "A",
                            "Address1": "460/410, Memari Hospital Road,",
                            "Address2": "Ward No. 08, Memari ",
                            "Place": "Near Memai Hospital",
                            "City_Id": "1848",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee3851@kidzee.com",
                            "Mobile_No": "8001568702",
                            "Phone_No1": "8250099571",
                            "Phone_No2": "",
                            "Latitude": "23.1763845",
                            "Longitude": "88.1015282",
                            "Images": [
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1912780/10712/34f447a0_1177_4700_9ba4_cdef6162cda1.jpg"
                               },
                               {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1912780/10712/5eef2a19_d995_438f_8a4b_9543f3a7d8a6.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Sonpur",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5624",
                            "Franchisee_Name": "Kidzee Ghasiara",
                            "Operating_Status": "A",
                            "Address1": "Biswas Bhawan,Madhyapara, Ghasiara,(Near Sharma's Kathgola), ",
                            "Address2": " PO+PS-Sonarpur",
                            "Place": "Ghasiara",
                            "City_Id": "1867",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5624@kidzee.com",
                            "Mobile_No": "9831445550",
                            "Phone_No1": "9831445550",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Basirhat",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "E-R-C-5188",
                               "Franchisee_Name": "Kidzee  Kholapota",
                               "Operating_Status": "A",
                               "Address1": "Subhadeep Enterprises,",
                               "Address2": "South Mathurapur,",
                               "Place": "Kholapota, Beside Subhadeep Enterprise,",
                               "City_Id": "1868",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5188@kidzee.com",
                               "Mobile_No": "9830023883",
                               "Phone_No1": "9732818542",
                               "Phone_No2": "",
                               "Latitude": "22.68232",
                               "Longitude": "88.80729"
                            },
                            {
                               "Franchisee_Code": "E-R-C-4519",
                               "Franchisee_Name": "Kidzee Basirhat",
                               "Operating_Status": "A",
                               "Address1": "Vill + PO: Pifa,",
                               "Address2": "PS -Basirhat,",
                               "Place": "Dist-Nort 24 Parganas, Near Mamata Kanan,",
                               "City_Id": "1868",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4519@kidzee.com",
                               "Mobile_No": "6292110120",
                               "Phone_No1": "9647-430165",
                               "Phone_No2": "",
                               "Latitude": "22.60223",
                               "Longitude": "88.84258"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5554",
                               "Franchisee_Name": "Kidzee Sadik Nagar",
                               "Operating_Status": "A",
                               "Address1": "Vill-Gangati, Post-Chaita, PS. Matia",
                               "Address2": "Amtona Road, North 24 Paragana",
                               "Place": "Sadik Nagar",
                               "City_Id": "1868",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "mountedutech@gmail.com",
                               "Mobile_No": "8617320046",
                               "Phone_No1": "8617320046",
                               "Phone_No2": "",
                               "Latitude": "22.654056",
                               "Longitude": "88.749973"
                            },
                            {
                               "Franchisee_Code": "E-R-C-5266",
                               "Franchisee_Name": "Kidzee Basirhat Town",
                               "Operating_Status": "A",
                               "Address1": "Taki road",
                               "Address2": "Chto Jirakpur,",
                               "Place": "Near Kishalya, Sangha",
                               "City_Id": "1868",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5266@kidzee.com",
                               "Mobile_No": "9647430165",
                               "Phone_No1": "9647430165",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "A-E-A-1074",
                               "Franchisee_Name": "Ankurum Minakhan",
                               "Operating_Status": "A",
                               "Address1": "Vill-Madari,Block-Minakhan",
                               "Address2": "Minakhan,daag No-254,JL No-26",
                               "Place": "Minakhan,24 North Paragana",
                               "City_Id": "1868",
                               "State_Id": "37",
                               "Country_Id": "1",
                               "Email_Id": "iamshaheb.1@gmail.com",
                               "Mobile_No": "9647430165",
                               "Phone_No1": "9647430165",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Panskura",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5261",
                            "Franchisee_Name": "Kidzee Panskura",
                            "Operating_Status": "A",
                            "Address1": "Behind Panskura Bag Centre, PS Panskura Dist",
                            "Address2": "Near Axis Bank , Purba Medinapur",
                            "Place": "Bahargram",
                            "City_Id": "1869",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5261@kidzee.com",
                            "Mobile_No": "9932368052",
                            "Phone_No1": "9932368052",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Pifa"
                      },
                      {
                         "City_Name": "Palta",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4567",
                            "Franchisee_Name": "Kidzee Palta",
                            "Operating_Status": "A",
                            "Address1": "Babanpur,",
                            "Address2": "24  PGS (N),",
                            "Place": "Palta, Near-Indane Gas Godown,",
                            "City_Id": "1871",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4567@kidzee.com",
                            "Mobile_No": "7003163602",
                            "Phone_No1": "7003163601",
                            "Phone_No2": "",
                            "Latitude": "22.78596",
                            "Longitude": "88.38544"
                         }
                      },
                      {
                         "City_Name": "Ichapore"
                      },
                      {
                         "City_Name": "Gobordanga",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4472",
                            "Franchisee_Name": "Kidzee Gobordanga",
                            "Operating_Status": "A",
                            "Address1": "Bazar Road,",
                            "Address2": "Near-Gobordanga Station,",
                            "Place": "Garhpara, Near Garpara Bidhan Smriti Sangha Club,",
                            "City_Id": "1884",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4472@kidzee.com",
                            "Mobile_No": "8373012132",
                            "Phone_No1": "8373012132",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "SURI",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4802",
                            "Franchisee_Name": "Kidzee Suri",
                            "Operating_Status": "A",
                            "Address1": "Dangal Para",
                            "Address2": "Near- Suri Bus Stand, Birbhum,",
                            "Place": "Birbhum, Behind - Studio Coral, Suri",
                            "City_Id": "1905",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4802@kidzee.com",
                            "Mobile_No": "9434249966",
                            "Phone_No1": "9434249966",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Nalhati",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4599",
                            "Franchisee_Name": "Kidzee Khaspara",
                            "Operating_Status": "A",
                            "Address1": "Birbhum,",
                            "Address2": "",
                            "Place": "Khaspara, Near-Maruti Bhawan,",
                            "City_Id": "1910",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4599@kidzee.com",
                            "Mobile_No": "9007593277",
                            "Phone_No1": "9007593277",
                            "Phone_No2": "",
                            "Latitude": "24.2897003",
                            "Longitude": "87.8413324"
                         }
                      },
                      {
                         "City_Name": "Bolpur"
                      },
                      {
                         "City_Name": "Contai"
                      },
                      {
                         "City_Name": "PAIKAR",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-4969",
                            "Franchisee_Name": "Kidzee Paikar",
                            "Operating_Status": "A",
                            "Address1": "Kabiguru IPS",
                            "Address2": "Vill +PO + PS - Paikar, Birbhum",
                            "Place": "Paikar ,Opp :Kabiguru College of Education",
                            "City_Id": "1924",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4969@kidzee.com",
                            "Mobile_No": "9734413424",
                            "Phone_No1": "9734413424",
                            "Phone_No2": "",
                            "Latitude": "24.431621",
                            "Longitude": "87.911632"
                         }
                      },
                      {
                         "City_Name": "Bethuadahari"
                      },
                      {
                         "City_Name": "NABADWIP",
                         "Franchisee": {
                            "Franchisee_Code": "A-E-A-1067",
                            "Franchisee_Name": "Ankurum Aganeswari Para",
                            "Operating_Status": "A",
                            "Address1": "Dwarik Babu Road ",
                            "Address2": "ward no- 3 , Dist Nadia",
                            "Place": "Aganeswari Para",
                            "City_Id": "1950",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "IDEASINTERIOR2@GMAIL.COM",
                            "Mobile_No": "9332035951",
                            "Phone_No1": "9332035951",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "SOUTH 24 PARGANAS"
                      },
                      {
                         "City_Name": "DIAMOND HARBOUR"
                      },
                      {
                         "City_Name": "HASNABAD"
                      },
                      {
                         "City_Name": "TAKI",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5212",
                            "Franchisee_Name": "Kidzee Taki Road",
                            "Operating_Status": "A",
                            "Address1": "Taki College Road (Bakultala), Near Indian Bank",
                            "Address2": "P.O Taki , P.s Hasnabad, Dist North 24 Parganas, Opp Police Fari",
                            "Place": "Taki ,Near Indian Bank",
                            "City_Id": "1955",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5212@kidzee.com",
                            "Mobile_No": "9932583216",
                            "Phone_No1": "9749508544",
                            "Phone_No2": "",
                            "Latitude": "22.5871387",
                            "Longitude": "88.9276152"
                         }
                      },
                      {
                         "City_Name": "CHANDPARA",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5079",
                            "Franchisee_Name": "Kidzee chandpara",
                            "Operating_Status": "A",
                            "Address1": "Chhekathi,",
                            "Address2": "Gaighata, 24 Parganas North,",
                            "Place": "Chandpara,",
                            "City_Id": "1956",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5079@kidzee.com",
                            "Mobile_No": "9830965963",
                            "Phone_No1": "9830965963",
                            "Phone_No2": "",
                            "Latitude": "22.05753",
                            "Longitude": "78.93818"
                         }
                      },
                      {
                         "City_Name": "Baduria",
                         "Franchisee": {
                            "Franchisee_Code": "E-R-C-5350",
                            "Franchisee_Name": "Kidzee Baduria Diamond Garden",
                            "Operating_Status": "A",
                            "Address1": "North 24 Parganas,",
                            "Address2": "Baduria Muncipality Bus Terminus,",
                            "Place": "Baduria Diamond Garden",
                            "City_Id": "1967",
                            "State_Id": "37",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5350@kidzee.com",
                            "Mobile_No": "7001694686",
                            "Phone_No1": "7001694686",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Kakinara"
                      }
                   ]
                },
                {
                   "State_Name": "Chandigarh (ut)",
                   "City": [
                      {
                         "City_Name": "Manimajra"
                      },
                      {
                         "City_Name": "Chandigarh"
                      },
                      {
                         "City_Name": "Baikanthapur "
                      }
                   ]
                },
                {
                   "State_Name": "Dadra and Nagar Haveli (ut)",
                   "City": [
                      {
                         "City_Name": "Silvassa",
                         "Franchisee": {
                            "Franchisee_Code": "W-C-C-1031",
                            "Franchisee_Name": "Kidzee-Silvassa",
                            "Operating_Status": "A",
                            "Address1": "50, Shushi Mangal,",
                            "Address2": ",",
                            "Place": "Vrindavan Park Society, Silvassa,",
                            "City_Id": "913",
                            "State_Id": "40",
                            "Country_Id": "1",
                            "Email_Id": "kidzee1031@kidzee.com",
                            "Mobile_No": "9737024400",
                            "Phone_No1": "9924308310",
                            "Phone_No2": "",
                            "Latitude": "20.275915",
                            "Longitude": "73.008045"
                         }
                      },
                      {
                         "City_Name": "Dadra"
                      },
                      {
                         "City_Name": "Nagar haveli"
                      }
                   ]
                },
                {
                   "State_Name": "Daman and Diu (ut)",
                   "City": [
                      {
                         "City_Name": "Daman"
                      },
                      {
                         "City_Name": "Daman"
                      }
                   ]
                },
                {
                   "State_Name": "Lakshadweep (ut)"
                },
                {
                   "State_Name": "Mizoram"
                },
                {
                   "State_Name": "Nepal",
                   "City": [
                      {
                         "City_Name": "Kathmandu"
                      },
                      {
                         "City_Name": "Biratnagar (morang dist)"
                      }
                   ]
                },
                {
                   "State_Name": "Telangana",
                   "City": [
                      {
                         "City_Name": "Hyderabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-A-M-1405",
                               "Franchisee_Name": "kidzee O.U colony Manikonda",
                               "Operating_Status": "A",
                               "Address1": "8-1-284/OU/234 & 235,",
                               "Address2": " OU Colony, Shaikpet,",
                               "Place": "Manikonda ,Beside-Shivalayam Temple,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1405@kidzee.com",
                               "Mobile_No": "9985800888",
                               "Phone_No1": "9985800888",
                               "Phone_No2": "",
                               "Latitude": "17.409614",
                               "Longitude": "78.393493"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1382",
                               "Franchisee_Name": "Kidzee - Mehdipatnam ",
                               "Operating_Status": "A",
                               "Address1": "12-2-800/436, Padmanabha Nagar Colony,",
                               "Address2": "Beside-Saibaba Temple,",
                               "Place": "Mehdipatnam,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1382@kidzee.com",
                               "Mobile_No": "9353214603",
                               "Phone_No1": "9010000768",
                               "Phone_No2": "",
                               "Latitude": "17.391636",
                               "Longitude": "78.440065"
                            },
                            {
                               "Franchisee_Code": "S-B-S-591",
                               "Franchisee_Name": "KIDZEE Kukatpally",
                               "Operating_Status": "A",
                               "Address1": "H.No-2-22-198/B, Plot No-132,",
                               "Address2": "KPHB,",
                               "Place": "Kukatpally, Jayanagar, Beside-HMT Sathavahana Naga",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee591@kidzee.com",
                               "Mobile_No": "9849468797",
                               "Phone_No1": "9849468797",
                               "Phone_No2": "",
                               "Latitude": "17.4970424573489",
                               "Longitude": "78.3907556533813"
                            },
                            {
                               "Franchisee_Code": "S-B-S-617",
                               "Franchisee_Name": "KIDZEE-MALAYSIAN TOWNSHIP- KUKATPALLY",
                               "Operating_Status": "A",
                               "Address1": "#MIG-2-224, KPHB,",
                               "Address2": "9th, Phase, Kukatpally,",
                               "Place": "Malaysian Township, Near-9th phase Green Park, Bac",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee617@kidzee.com",
                               "Mobile_No": "9290660051",
                               "Phone_No1": "9290660051",
                               "Phone_No2": "",
                               "Latitude": "17.485684",
                               "Longitude": "78.382806"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1314",
                               "Franchisee_Name": "Kidzee-Balaji Nagar",
                               "Operating_Status": "A",
                               "Address1": "# HIG 76, Balaji Nagar,",
                               "Address2": "",
                               "Place": "Kukatpally, Near to Kukatpally Metro Station,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1314@kidzee.com",
                               "Mobile_No": "9949252128",
                               "Phone_No1": "9949252128",
                               "Phone_No2": "",
                               "Latitude": "17.482138",
                               "Longitude": "78.408568"
                            },
                            {
                               "Franchisee_Code": "S-B-U-410",
                               "Franchisee_Name": "Kidze-Akshara-CLC",
                               "Operating_Status": "A",
                               "Address1": "PM HOUSE, 5-9-211/2, Next to Little Flower High School,",
                               "Address2": "Chirag Ali Lane,",
                               "Place": "Abids, Beside Little Flower School",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee410@kidzee.com",
                               "Mobile_No": "9849752022",
                               "Phone_No1": "9849752022",
                               "Phone_No2": "",
                               "Latitude": "17.392076",
                               "Longitude": "78.47555"
                            },
                            {
                               "Franchisee_Code": "S-B-S-567",
                               "Franchisee_Name": "Kidzee- Uppal",
                               "Operating_Status": "A",
                               "Address1": "Address- Plot No.66",
                               "Address2": "Opposite Srinivasa Heights, West Balaji Hill Colony,Adarsh Nagar,",
                               "Place": "Uppal",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee567@kidzee.com",
                               "Mobile_No": "9948106127",
                               "Phone_No1": "9652976593",
                               "Phone_No2": "",
                               "Latitude": "17.461519806084585",
                               "Longitude": "78.48897896099083"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1255",
                               "Franchisee_Name": "Kidzee - Miyapur",
                               "Operating_Status": "A",
                               "Address1": "Plot No.89&90,",
                               "Address2": "Vinayak Nagar, Old-",
                               "Place": "Hafeezpet ,Besides Hafeezpet Flyover,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1255@kidzee.com",
                               "Mobile_No": "9908900121",
                               "Phone_No1": "9908900121",
                               "Phone_No2": "",
                               "Latitude": "17.484877918355792",
                               "Longitude": "78.35780538618565"
                            },
                            {
                               "Franchisee_Code": "S-A-M-1310",
                               "Franchisee_Name": "Kidzee-Padmarao Nagar",
                               "Operating_Status": "A",
                               "Address1": "6-1-118/10 CRPF Colony,",
                               "Address2": "",
                               "Place": "Padmarao Nagar",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1310@kidzee.com",
                               "Mobile_No": "8019545573",
                               "Phone_No1": "4032212009",
                               "Phone_No2": "",
                               "Latitude": "11.09344",
                               "Longitude": "77.335314"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2338",
                               "Franchisee_Name": "Kidzee Pragathi Nagar, Kukatpally",
                               "Operating_Status": "A",
                               "Address1": "Plot No 4, Dollar Homes, Pragathi Nagar,",
                               "Address2": "Pragathi Nagar",
                               "Place": "Pragathi Nagar,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2338@kidzee.com",
                               "Mobile_No": "9676307664",
                               "Phone_No1": "9676307664",
                               "Phone_No2": "",
                               "Latitude": "17.526217235845312",
                               "Longitude": "78.39651275353242",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/826935/10750/aff2e38a_90ac_42ab_8760_f19e71fd5d5b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/826935/10750/a9fb323b_df59_4cc8_ac1a_b2be9659365b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/826935/10750/ed519049_a706_4cf1_966a_fe69ad2d9f28.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-2592",
                               "Franchisee_Name": "Kidzee Mansoorabad Hyderabad",
                               "Operating_Status": "A",
                               "Address1": "3-12-92/506, Road- # 3, 9th Cross,",
                               "Address2": "Rock Town Colony,",
                               "Place": "Mansoorabad, Beside- Reliance Petrol bunk Lane,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2592@kidzee.com",
                               "Mobile_No": "9392219416",
                               "Phone_No1": "040-24224646",
                               "Phone_No2": "",
                               "Latitude": "17.36032203090395",
                               "Longitude": "78.5621860899658"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2512",
                               "Franchisee_Name": "Kidzee Miyapur Hyderabad",
                               "Operating_Status": "A",
                               "Address1": "Plot No-35, JP Nagar,",
                               "Address2": "",
                               "Place": "Miyapur, JP Nagar Lake,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2512@kidzee.com",
                               "Mobile_No": "9000420082",
                               "Phone_No1": "8074284825",
                               "Phone_No2": "",
                               "Latitude": "17.507113",
                               "Longitude": "78.357911"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2339",
                               "Franchisee_Name": "Kidzee Habsiguda  Hyderabad",
                               "Operating_Status": "A",
                               "Address1": "House No 1-4-54/2,",
                               "Address2": "Opp. More Super market, ",
                               "Place": "Habsiguda, near more market",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2339@kidzee.com",
                               "Mobile_No": "9948106127",
                               "Phone_No1": "7207850643 ",
                               "Phone_No2": "",
                               "Latitude": "17.407825",
                               "Longitude": "78.543941"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2411",
                               "Franchisee_Name": "Kidzee Begumpet",
                               "Operating_Status": "A",
                               "Address1": "HNo. 1-8-495,",
                               "Address2": "Pattigadda, Begumpet,",
                               "Place": "Prakashnagar, Near Sai Sagar Apartment,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2411@kidzee.com",
                               "Mobile_No": "7093824984",
                               "Phone_No1": "9553481561",
                               "Phone_No2": "",
                               "Latitude": "17.440289",
                               "Longitude": "78.472086"
                            },
                            {
                               "Franchisee_Code": "S-U-S-800",
                               "Franchisee_Name": "Kidzee- L. B Nagar- Hyderabad",
                               "Operating_Status": "A",
                               "Address1": "H.No.11- 13- 186/14/2, R.R.",
                               "Address2": "Road No.2,",
                               "Place": "Green Hills Colony-LB Nagar, Beside Karachi Bakery",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee800@kidzee.com",
                               "Mobile_No": "8019077722",
                               "Phone_No1": "8019077722",
                               "Phone_No2": "",
                               "Latitude": "17.361705",
                               "Longitude": "78.548225"
                            },
                            {
                               "Franchisee_Code": "S-S-S-1879",
                               "Franchisee_Name": "Kidzee Azampura Hyderabad",
                               "Operating_Status": "A",
                               "Address1": "H No.16-7-335/ A,",
                               "Address2": "Chaderghat,",
                               "Place": "Azampura, Near Thumbay Hospitial,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1879@kidzee.com",
                               "Mobile_No": "9000652517",
                               "Phone_No1": "9000652517",
                               "Phone_No2": "",
                               "Latitude": "17.3764900295998",
                               "Longitude": "78.4916388988495"
                            },
                            {
                               "Franchisee_Code": "S-L-C-1908",
                               "Franchisee_Name": "Kidzee Uppal Hyderabad",
                               "Operating_Status": "A",
                               "Address1": "House No.19/75/3/1,",
                               "Address2": "Opp. To Community Hall, Kalyanipuri,",
                               "Place": "Uppal, Beside Metro Stadium Road, Opp. To East Kal",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1908@kidzee.com",
                               "Mobile_No": "9553862279",
                               "Phone_No1": "9553862279",
                               "Phone_No2": "",
                               "Latitude": "17.413981",
                               "Longitude": "78.558277"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2826",
                               "Franchisee_Name": "Kidzee Kalyan Nagar Hyderabad",
                               "Operating_Status": "A",
                               "Address1": "H-No.8-3-168/20/27, Lakshmi Nagar,",
                               "Address2": "Lane #4, Near-Central Bank,",
                               "Place": "Kalyan Nagar,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2826@kidzee.com",
                               "Mobile_No": "9848984777",
                               "Phone_No1": "9347006344",
                               "Phone_No2": "",
                               "Latitude": "17.448007",
                               "Longitude": "78.4322"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2890",
                               "Franchisee_Name": "Kidzee  Neredmet Secunderabad",
                               "Operating_Status": "A",
                               "Address1": "Plot # 12, H.No-27-16/23/1,",
                               "Address2": "Sri Krishna Nagar, Road # 3, Behind Krupa Complex,",
                               "Place": "Safilguda Neredmet, Near- Safilguda Lake",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2890@kidzee.com",
                               "Mobile_No": "9392897979",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "17.468374",
                               "Longitude": "78.533946",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10758/398bcdab_f903_456f_b164_7535083710e8.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10758/2bff71ae_52c8_4fef_bbf2_440e6f672928.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10758/b3eaa00e_ac64_42aa_b50b_a2694ec6deac.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10758/7cdc82d2_094e_4c5c_aad4_6bc2f9be616f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10758/50a4ded8_3372_4de2_abce_b10a40b26792.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10758/41bcbe3d_97a1_47c6_b380_c68c0e4eb1c9.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10758/10383763_f590_46a1_87af_104ed58dd1eb.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10758/70f9894a_74c6_47db_bebb_e417ad05b927.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10758/7b0045ea_691b_4a7b_82a6_60b8af6fb670.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/42bdd2e2_a9d2_4d25_a9d1_adf151eb2158.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/06e5954c_d3a4_48ef_84ce_af4bde179c48.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/320c3766_90cc_44f0_be70_0741c4814dcd.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/503a2a79_ee8e_46dd_a28a_f491047953ed.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/64167791_1896_4227_831e_03c0c62d7e4e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/dbc99a1c_3cba_4acc_9563_8165a8a7e9d3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/b699ab98_908e_4b8d_a524_5b6afdc7b040.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/b68ab6f2_c6d1_493b_a80c_f3cfde4721d3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/a3187d0d_e963_4021_bdc7_5f349ce2900a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/54fc5ba6_5492_47f2_ac03_8738d9893d5c.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/b2cd818e_494d_4ffe_8779_4622d848100d.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/612e8e0f_6857_44ee_82ec_8cd016306bee.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/f81047f3_40e3_4796_ac3f_2322d74f0d3a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/a370c55f_6faf_426f_b98b_b951cac5ed08.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/1873710b_efd7_493e_a69d_ad40ea5e470b.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/9e662173_3b47_4b14_92d3_24c3bfac0135.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/a9d7c5f1_ae7d_4ed4_ac7c_0768c9c7500e.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10759/01998942_f345_4804_bd06_6d8dd95703b6.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/f3d958ba_d27e_4b0d_8a3a_2522f0592c7a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/6fa10eb8_d40d_4e05_b18f_ef7f2e353171.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/e4ec577b_1c50_4c46_a281_1025df6094f3.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/37714737_73fa_4aac_a99a_d0988edacb23.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/5ae6ee5a_83fd_46b7_8736_61f0d607be1f.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/55e127d6_442e_47e0_98d4_1561f5838988.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/84c650b9_1a1b_45c8_918d_9afef70b90dc.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/f8d76132_0294_47e8_ba53_3d1a06711b7a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/b5afecb3_4ffa_4d9b_8dc1_c4e69763d858.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/fbf948d1_4485_4d61_bc7f_87d7484f6fae.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/4ba211ea_71af_4fdd_9cde_f729d3f6e021.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/98112967_1c37_4bbd_814b_22f0c136846a.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/f1ac57db_67fa_454d_8c76_c10ed8b4fdc5.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1010118/10760/5b0e4c83_7214_4cd4_b504_734229c925a6.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-2904",
                               "Franchisee_Name": "Kidzee Harini Educational Academy Hyderabad",
                               "Operating_Status": "A",
                               "Address1": "Plot No-23, CBI Colony,",
                               "Address2": "Phase-4,",
                               "Place": "Near Vansthalipuram Water Tank",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2904@kidzee.com",
                               "Mobile_No": "9347844333",
                               "Phone_No1": "9347844333",
                               "Phone_No2": "",
                               "Latitude": "17.332173",
                               "Longitude": "78.564904",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2904/133/8affb30f_e154_458f_bca0_f3afaba2743e.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2904/133/d4f8d570_5b41_423f_b1bd_99d1acfe0be4.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2904/133/5d494ba4_d73c_4089_a89b_790a67e75d36.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2904/133/1bcaa307_e9ea_4af6_83fa_8c32d6afc188.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2904/133/a4e363cf_1aed_4762_9447_d6aa1417f38f.JPG"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-2950",
                               "Franchisee_Name": "KIDZEE HYDERSHAKOTE   HYDERABAD",
                               "Operating_Status": "A",
                               "Address1": "Plot # -33/P, Padmsree Hills,",
                               "Address2": "Bandlaguda Jagir Village, ",
                               "Place": "Hydershakote,Beside- KK Convention Hall",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2950@kidzee.com",
                               "Mobile_No": "9490710000",
                               "Phone_No1": "9849776833",
                               "Phone_No2": "",
                               "Latitude": "17.385044",
                               "Longitude": "78.486671",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2950/69/1eb21ac0_fe73_4aad_ad91_335badebedaf.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2950/69/006288e8_47d3_4c3c_84c2_5ca33063a64f.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2950/69/baf6baed_0ef9_47b0_9e14_b981c3a08b31.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2950/70/82255d95_c444_4ed3_8080_a072440e805d.JPG"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-B-U-923",
                               "Franchisee_Name": "Kidzee- Hitech City",
                               "Operating_Status": "A",
                               "Address1": "Plot No.41, Ayyappa Co-Operative Society,",
                               "Address2": "Madhapur Village, Sirimalle Enclave,",
                               "Place": "Madhapur, Ayyapa Socity,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee923@kidzee.com",
                               "Mobile_No": "8106778826",
                               "Phone_No1": "9704838123",
                               "Phone_No2": "",
                               "Latitude": "17.385044",
                               "Longitude": "78.486671"
                            },
                            {
                               "Franchisee_Code": "S-A-S-1078",
                               "Franchisee_Name": "Kidzee-Banjara Hills Road",
                               "Operating_Status": "A",
                               "Address1": "Door No.8-2-601/P/31/A,",
                               "Address2": "Panchavati Colony,",
                               "Place": "Banjara Hill, Road No-10,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1078@kidzee.com",
                               "Mobile_No": "9704178919",
                               "Phone_No1": "9248747090",
                               "Phone_No2": "",
                               "Latitude": "17.413828",
                               "Longitude": "78.439758"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2023",
                               "Franchisee_Name": "Kidzee  ECIL Post Hyderabad",
                               "Operating_Status": "A",
                               "Address1": "Plot No-114, Anupuram colony,",
                               "Address2": "Dr. A S-Rao Nagar,",
                               "Place": "ECIL,Backside Of Radhika Theater,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2023@kidzee.com",
                               "Mobile_No": "9966944144",
                               "Phone_No1": "9966944144",
                               "Phone_No2": "",
                               "Latitude": "17.475808",
                               "Longitude": "78.560192"
                            },
                            {
                               "Franchisee_Code": "S-R-S-2686",
                               "Franchisee_Name": "BHEL Lingampally",
                               "Operating_Status": "A",
                               "Address1": "MIG-647,",
                               "Address2": "Employees Co-Op Housing Society Ltd, MIG Colony, Nallagandla Village,",
                               "Place": "BHEL-Serilingampally, Near-Bicha Reddy Sweets,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2686@kidzee.com",
                               "Mobile_No": "8096900505",
                               "Phone_No1": "8096900505",
                               "Phone_No2": "",
                               "Latitude": "17.483811",
                               "Longitude": "78.297401",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/921458/10743/1b8d8434_6f5a_4135_873e_5d0cff2be768.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-R-S-3770",
                               "Franchisee_Name": "Kidzee Nizampet Village",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 17",
                               "Address2": "Adarsh Avenue, Nizampet , Pratap Reddy Layout, Kolan Narayana reddy colony",
                               "Place": "Nizampet Village ,near narayana reddy colony",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3770@kidzee.com",
                               "Mobile_No": "9160039222",
                               "Phone_No1": "9160039222",
                               "Phone_No2": "",
                               "Latitude": "17.517153",
                               "Longitude": "78.377546"
                            },
                            {
                               "Franchisee_Code": "S-R-C-3833",
                               "Franchisee_Name": "Kidzee Chandanagar",
                               "Operating_Status": "A",
                               "Address1": "House No. 57,",
                               "Address2": "Suraksha Hills Colony",
                               "Place": "Chandanagar ,suraksha hills",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3833@kidzee.com",
                               "Mobile_No": "8465072489",
                               "Phone_No1": "9849078989",
                               "Phone_No2": "",
                               "Latitude": "17.5058757",
                               "Longitude": "78.32072959999999"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4105",
                               "Franchisee_Name": "Kidzee Old Alwal",
                               "Operating_Status": "A",
                               "Address1": "House no.1-5-1027/12,Plot no.12",
                               "Address2": "Bhoopathy Rao Nagar,",
                               "Place": "Old Alwal, Secunderabad",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4105@kidzee.com",
                               "Mobile_No": "8897186850",
                               "Phone_No1": "040-40164569",
                               "Phone_No2": "",
                               "Latitude": "17.50907388695886",
                               "Longitude": "78.49658082012941",
                               "Images": [
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1988262/10698/be2b0641_83ff_492e_9dd6_62f117585023.jpg"
                                  },
                                  {
                                     "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/1988262/10698/e9f3f209_5af0_49e2_b871_14ab0e8aa930.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1988262/10696/c62ad900_32cd_4cce_8be9_560d7c55272b.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1988262/10696/e9642917_df8b_4a08_9dcb_794b7396bba8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1988262/10696/4a74a00b_e10d_4a70_8d53_900121dff0d7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1988262/10696/26e78380_0532_449d_a0f6_9a4f3cc9f2e8.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1988262/10698/26d7495b_2d81_435e_aed7_1cafff2b4db9.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-S-4177",
                               "Franchisee_Name": "Kidzee - Qutubullahpur",
                               "Operating_Status": "A",
                               "Address1": "Plot no.66 Venkateshwara Enclave",
                               "Address2": "Jeedimetla Village, Suchithra Junction",
                               "Place": "Qutubullahpur",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4177@kidzee.com",
                               "Mobile_No": "9866161929",
                               "Phone_No1": "9866161929",
                               "Phone_No2": "",
                               "Latitude": "17.508397",
                               "Longitude": "78.461481"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4232",
                               "Franchisee_Name": "Kidzee Gachibowli",
                               "Operating_Status": "A",
                               "Address1": "Plot No - 95,",
                               "Address2": "Janardhan Hills Colony,",
                               "Place": "Gachi Bowli, Beside South India Shopping Mall,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4232@kidzee.com",
                               "Mobile_No": "9849405325",
                               "Phone_No1": "8897806788",
                               "Phone_No2": "",
                               "Latitude": "17.436142",
                               "Longitude": "78.369593"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4436",
                               "Franchisee_Name": "Kidzee Symphony Homes Road, Beeramguda",
                               "Operating_Status": "A",
                               "Address1": "Plot No-127, Venkatamma Hanumanth Reddy Nest,",
                               "Address2": "Safeway Greenfields, Iskabavi Village, Ameenpur Mandal,",
                               "Place": "Symphony Homes Road, Beeramguda ,Near Green Meadow",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4436@kidzee.com",
                               "Mobile_No": "9030059599",
                               "Phone_No1": "9030059599",
                               "Phone_No2": "",
                               "Latitude": "17.530408791867167",
                               "Longitude": "78.29261242460632"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4070",
                               "Franchisee_Name": "Kidzee Financial District ,Near Q City",
                               "Operating_Status": "A",
                               "Address1": "H.No.23, Mani Nagar,",
                               "Address2": "Adjacent to Q City, Near Wipro Circle,",
                               "Place": "Financial District, Near- Q City,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4070@kidzee.com",
                               "Mobile_No": "9121015437",
                               "Phone_No1": "7673918822",
                               "Phone_No2": "",
                               "Latitude": "17.427475",
                               "Longitude": "78.3328530"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4621",
                               "Franchisee_Name": "Kidzee Kompally",
                               "Operating_Status": "A",
                               "Address1": "Plot No-10,",
                               "Address2": "OAK Tree Enclave,",
                               "Place": "Kompally, Behind Croma Store",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4621@kidzee.com",
                               "Mobile_No": "9866161929",
                               "Phone_No1": "9866-161929",
                               "Phone_No2": "",
                               "Latitude": "17.532162° N",
                               "Longitude": "78.486663° E"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4585",
                               "Franchisee_Name": "Kidzee Gajulramaram",
                               "Operating_Status": "A",
                               "Address1": "H.No-2-66/253, Plot No- 253, Road No- 3,",
                               "Address2": "Malla Reddy Nagar, Opp- Bharat Petrol Bunk,",
                               "Place": "Gajularamaram, Near-Ramky Towers,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4585@kidzee.com",
                               "Mobile_No": "6305022013",
                               "Phone_No1": "630-5022013",
                               "Phone_No2": "",
                               "Latitude": "17.523026",
                               "Longitude": "78.418369"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3604",
                               "Franchisee_Name": "Kidzee Gopal Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 816, Gopal Nagar Hydernagar Village Kukatpally",
                               "Address2": "Beside Prajay Constructions, Water Works Road,",
                               "Place": "Kukatpally ,Road 35",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3604@kidzee.com",
                               "Mobile_No": "9000772904",
                               "Phone_No1": "9000772902",
                               "Phone_No2": "",
                               "Latitude": "17.481069477191085",
                               "Longitude": "78.37596515765472"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4738",
                               "Franchisee_Name": "KIDZEE MEDCHAL",
                               "Operating_Status": "A",
                               "Address1": "Plot No- 233, Raghvendra Nagar,",
                               "Address2": "Near- Balaji Nagar, Athvelly Village, Medchal Mandal,",
                               "Place": "Medchal,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4738@kidzee.com",
                               "Mobile_No": "9441141234",
                               "Phone_No1": "7993-484444",
                               "Phone_No2": "",
                               "Latitude": "17.642882",
                               "Longitude": "78.4913138",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2512813/10957/9250d8ed_0cf0_4883_a3ce_98b5955e13ce.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-R-S-4253",
                               "Franchisee_Name": "Kidzee Godimalkapur",
                               "Operating_Status": "A",
                               "Address1": "13-6-460,..",
                               "Address2": "MAHESH NAGAR,SHIYBAGH,",
                               "Place": "GUDIMALKAPUR ,mahesh nagar",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4253@kidzee.com",
                               "Mobile_No": "9959925260",
                               "Phone_No1": "9912-942121",
                               "Phone_No2": "",
                               "Latitude": "17.381840283289346",
                               "Longitude": "78.43734071412086"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4825",
                               "Franchisee_Name": "Kidzee Mallampet",
                               "Operating_Status": "A",
                               "Address1": "Plot No- 62P,63P, Mallampet, Qutbulapur Mandal,",
                               "Address2": "Near-Bachupally,",
                               "Place": "Mallampet, Near Gram Panchyat Office,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4825@kidzee.com",
                               "Mobile_No": "9701055042",
                               "Phone_No1": "040-66446614",
                               "Phone_No2": "",
                               "Latitude": "17.554126",
                               "Longitude": "78.364330"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4106",
                               "Franchisee_Name": "Kidzee L B Nagar",
                               "Operating_Status": "A",
                               "Address1": "Plot no. 83, Venture-2, SBH Colony,..",
                               "Address2": "Saroornagar Mandal ,Ranga Reddy Distarict",
                               "Place": "LB Nagar ,Backside lane D MART",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4106@kidzee.com",
                               "Mobile_No": "9059226909",
                               "Phone_No1": "9059226909",
                               "Phone_No2": "",
                               "Latitude": "17.3457° N",
                               "Longitude": "78.5522° E"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4208",
                               "Franchisee_Name": "Kidzee Huda Colony,  ChandaNagar",
                               "Operating_Status": "A",
                               "Address1": "H.No- 6-94/19,",
                               "Address2": "Huda Main Road, Near-Canara Bank,",
                               "Place": "Huda Colony, Chandanagar,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4208@kidzee.com",
                               "Mobile_No": "8985988444",
                               "Phone_No1": "8985988444",
                               "Phone_No2": "",
                               "Latitude": "17.49252",
                               "Longitude": "78.32763",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2154745/11052/efcca749_791a_46d4_bf5b_b67201d4c7ff.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-R-C-4035",
                               "Franchisee_Name": "Kidzee Shamshabad",
                               "Operating_Status": "A",
                               "Address1": "Plot No -21D Building (Ground Floor+2),",
                               "Address2": "Adarsha Nagar,",
                               "Place": "Shamshabad, OPP to Sri Chaitanya Techno School,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4035@kidzee.com",
                               "Mobile_No": "8886888624",
                               "Phone_No1": "8886-886624",
                               "Phone_No2": "",
                               "Latitude": "17.27169495272298",
                               "Longitude": "78.38447694274636"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4584",
                               "Franchisee_Name": "Kidzee Nagaram",
                               "Operating_Status": "A",
                               "Address1": "H.No. 8-130/3/9, plot no. 160&161 part,",
                               "Address2": "Shilpa nagar colony, Keesara Mandal, Medchal, Malkagiri Dist",
                               "Place": "Nagaram ,OPP: Water Tank, Shilpa Nagar, Near paramount Avenue",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4584@kidzee.com",
                               "Mobile_No": "9666733366",
                               "Phone_No1": "9666733366",
                               "Phone_No2": "",
                               "Latitude": "17.4816432",
                               "Longitude": "78.6032704"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4676",
                               "Franchisee_Name": "Kidzee Phase 15 KPHB",
                               "Operating_Status": "A",
                               "Address1": "Plot No-8, LIG, Phase-15,",
                               "Address2": "KPHB Colony, Kukatpally,",
                               "Place": "Phase-15, KPHB Colony, Near Rainbow Vistas",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4676@kidzee.com",
                               "Mobile_No": "9290660051",
                               "Phone_No1": "9290660051",
                               "Phone_No2": "",
                               "Latitude": "17.46806",
                               "Longitude": "78.39381"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4686",
                               "Franchisee_Name": "Kidzee Tolichowki",
                               "Operating_Status": "A",
                               "Address1": "H.No: 8-1-364/17, Ground Floor,",
                               "Address2": "Deluxe colony,",
                               "Place": "Tolichowki, Near VIP School",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4686@kidzee.com",
                               "Mobile_No": "9515809174",
                               "Phone_No1": "9515809174",
                               "Phone_No2": "",
                               "Latitude": "17.40961",
                               "Longitude": "78.41418"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4747",
                               "Franchisee_Name": "Kidzee Chengicherla",
                               "Operating_Status": "A",
                               "Address1": "Plot No-1, Survey No-133,140, Anushakthi Nagar, Chengicherla Village,",
                               "Address2": "Medipally Mandal, Medchal Malkajgiri,",
                               "Place": "Chengicherla Charlapally, Near Indian Oil",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4747@kidzee.com",
                               "Mobile_No": "6309552976",
                               "Phone_No1": "9059390996",
                               "Phone_No2": "",
                               "Latitude": "17.44569",
                               "Longitude": "78.60489"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4763",
                               "Franchisee_Name": "Kidzee Manikonda",
                               "Operating_Status": "A",
                               "Address1": "Plot No. 432, Survey no. 5&6,  Manikonda Khalsa Village,",
                               "Address2": "Sree Ramnagar colony,",
                               "Place": "Secretariat Colony, Manikonda ,Near Venkateshwara swamy Temple",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4763@kidzee.com",
                               "Mobile_No": "9985800888",
                               "Phone_No1": "9985800888",
                               "Phone_No2": "",
                               "Latitude": "17.40070",
                               "Longitude": "78.37555"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4948",
                               "Franchisee_Name": "Kidzee Bachupally",
                               "Operating_Status": "A",
                               "Address1": "H.No- 8-5/461/P,",
                               "Address2": "Sai Anurag Colony,",
                               "Place": "Bachupalli, Ameenpur,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4948@kidzee.com",
                               "Mobile_No": "9849501110",
                               "Phone_No1": "9849501110",
                               "Phone_No2": "",
                               "Latitude": "17.52754",
                               "Longitude": "78.35207"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3602",
                               "Franchisee_Name": "Kidzee Moosapet",
                               "Operating_Status": "A",
                               "Address1": "Good shed road",
                               "Address2": "Ground Floor,Near Pinnacle Institute of Hotel",
                               "Place": "Moosapet ,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3602@kidzee.com",
                               "Mobile_No": "8885691142",
                               "Phone_No1": "8885691142",
                               "Phone_No2": "",
                               "Latitude": "17.46848",
                               "Longitude": "78.42595"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5148",
                               "Franchisee_Name": "Kidzee Balapur",
                               "Operating_Status": "A",
                               "Address1": "H.No- 6-179, Ground Floor, SLNS Colony,",
                               "Address2": "Meerpet, Balapur Mandal, Rangareddy Distt.",
                               "Place": "Balapur X Road",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5148@kidzee.com",
                               "Mobile_No": "6303983302",
                               "Phone_No1": "9652255664",
                               "Phone_No2": "",
                               "Latitude": "17.31958",
                               "Longitude": "78.51898"
                            },
                            {
                               "Franchisee_Code": "S-R-S-5290",
                               "Franchisee_Name": "Kidzee Kondapur",
                               "Operating_Status": "A",
                               "Address1": "112/C, Sri Ram nagar colony, kondapur",
                               "Address2": "Near Botanical Garden,",
                               "Place": "Koondapur ,Sri Ram nagar colony",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5290@kidzee.com",
                               "Mobile_No": "7799862625",
                               "Phone_No1": "7799862625",
                               "Phone_No2": "",
                               "Latitude": "17.45810",
                               "Longitude": "78.35251"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4448",
                               "Franchisee_Name": "Kidzee- Gurramgudda",
                               "Operating_Status": "A",
                               "Address1": "House No-3-23,",
                               "Address2": "RR District,",
                               "Place": "Gurramguda,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4448@kidzee.com",
                               "Mobile_No": "9949969672",
                               "Phone_No1": "9949969672",
                               "Phone_No2": "",
                               "Latitude": "17.29445",
                               "Longitude": "78.56652"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4288",
                               "Franchisee_Name": "Kidzee Hayathnagar",
                               "Operating_Status": "A",
                               "Address1": "H.No-4-9-712/1, Plot No- 69,",
                               "Address2": "Lecturers Colony,",
                               "Place": "Hayathnagar, Near Community Park,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4288@kidzee.com",
                               "Mobile_No": "9701442876",
                               "Phone_No1": "9701442876",
                               "Phone_No2": "",
                               "Latitude": "17.33239",
                               "Longitude": "78.59466"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5679",
                               "Franchisee_Name": "Kidzee Ammenpur",
                               "Operating_Status": "A",
                               "Address1": "H. No 5-13-9/R-17/N/P/A/1",
                               "Address2": "Sai ram enclave phase 2",
                               "Place": "Ammenpur",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5679@kidzee.com",
                               "Mobile_No": "9908974569",
                               "Phone_No1": "9908974569",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-C-5770",
                               "Franchisee_Name": "Kidzee Kuntloor",
                               "Operating_Status": "A",
                               "Address1": "Pedda amaberpet to Kuntloor road",
                               "Address2": "Kanakadurganagar colony,Hayath nagar, Ranga Reddy Dist.",
                               "Place": "Kuntloor",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5770@kidzee.com",
                               "Mobile_No": "9951688898",
                               "Phone_No1": "9951688898",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-C-5448",
                               "Franchisee_Name": "Kidzee Sri Hari Nagar,",
                               "Operating_Status": "A",
                               "Address1": "12-4-104/A/1, Plot No-55",
                               "Address2": "Pragathi Nagar ,Moosapet Village, Kukatpally Circle,Balanagar Mandal,Ranga Reddy Districit",
                               "Place": "Sri Hari Nagar,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5448@kidzee.com",
                               "Mobile_No": "9347096316",
                               "Phone_No1": "9347096316",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-C-5120",
                               "Franchisee_Name": "Kidzee Rangareddy",
                               "Operating_Status": "A",
                               "Address1": "5-4-54/2 Bhavani colony,",
                               "Address2": "Rajendranagar, Near hanuman temple",
                               "Place": "Rangareddy ,Bhavani Colony",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5120@kidzee.com",
                               "Mobile_No": "9550000081",
                               "Phone_No1": "9550000081",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-C-5479",
                               "Franchisee_Name": "Kidzee Boduppal,",
                               "Operating_Status": "A",
                               "Address1": "3-100/B, Saraswathi Nagar Colony, ",
                               "Address2": "Medipally Manadal, Medchal Malkajiri Dist,",
                               "Place": "Boduppal,",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5479@kidzee.com",
                               "Mobile_No": "9866571275",
                               "Phone_No1": "9866571275",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "S-R-C-5694",
                               "Franchisee_Name": "Kidzee Ameenpur",
                               "Operating_Status": "A",
                               "Address1": "No 5-278/215/A/1 Opp Shivadhara Residency Apartments ",
                               "Address2": "Road No 5 Green Villas Next to Fusion International School,Mandal Sangareddy Dist",
                               "Place": "Ameenpur",
                               "City_Id": "7",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5694@kidzee.com",
                               "Mobile_No": "8885047464",
                               "Phone_No1": "8885047464",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Nallagonda",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-2575",
                            "Franchisee_Name": "Kidzee Nalgonda",
                            "Operating_Status": "A",
                            "Address1": "H.No 6-2-970,",
                            "Address2": "Chennakesa Nilayam,",
                            "Place": "VT Colony,",
                            "City_Id": "708",
                            "State_Id": "46",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2575@kidzee.com",
                            "Mobile_No": "9701186017",
                            "Phone_No1": "9701186017 ",
                            "Phone_No2": "",
                            "Latitude": "17.069916",
                            "Longitude": "79.261325"
                         }
                      },
                      {
                         "City_Name": "Secunderabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-B-M-403",
                               "Franchisee_Name": "KIDZEE-SHILPKAAR-WE MOULD KIDS",
                               "Operating_Status": "A",
                               "Address1": "158.Vayupuri 3rd Cross Road,",
                               "Address2": "Sainikpuri Post,",
                               "Place": "Sainikpuri, Near Vayupuri-East Park",
                               "City_Id": "882",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee403@kidzee.com",
                               "Mobile_No": "9396674413",
                               "Phone_No1": "7032824413",
                               "Phone_No2": "",
                               "Latitude": "17.43993",
                               "Longitude": "78.498274"
                            },
                            {
                               "Franchisee_Code": "S-R-S-3029",
                               "Franchisee_Name": "Kidzee - DK Road, Ameerpet, Hyderabad",
                               "Operating_Status": "A",
                               "Address1": "7-1-59/9/A/3, Dharam Karam Road,",
                               "Address2": "Ameerpet,",
                               "Place": "Dharamkaram Road,",
                               "City_Id": "882",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3029@kidzee.com",
                               "Mobile_No": "7842795643",
                               "Phone_No1": "9949252128",
                               "Phone_No2": "",
                               "Latitude": "17.436235",
                               "Longitude": "78.450427",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3029/184/a14eae46_a535_4124_a6be_94a7dc419c42.JPG"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3029/184/8214418b_4fe0_441f_9e56_17cbd668491e.JPG"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-B-S-1076",
                               "Franchisee_Name": "Kidzee - Kompally, Secundrabad",
                               "Operating_Status": "A",
                               "Address1": "2-132/1, Spring Field Colony,",
                               "Address2": "Jeedimetla,(Village),",
                               "Place": "Kompally, Near- Metro Suchitra,",
                               "City_Id": "882",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1076@kidzee.com",
                               "Mobile_No": "9849796404",
                               "Phone_No1": "9849-796404",
                               "Phone_No2": "",
                               "Latitude": "17.510385869365997",
                               "Longitude": "78.48266903670117"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4102",
                               "Franchisee_Name": "Kidzee Old Bowenpally",
                               "Operating_Status": "A",
                               "Address1": "Plot No-50, 8-7-105/50, Balaji Enclave, Road Number-1,",
                               "Address2": "Mallikarjuna Nagar,",
                               "Place": "Old Bowenpally,",
                               "City_Id": "882",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4102@kidzee.com",
                               "Mobile_No": "9246332722",
                               "Phone_No1": "9246332722",
                               "Phone_No2": "",
                               "Latitude": "17.479458758932484",
                               "Longitude": "78.48157793283462"
                            },
                            {
                               "Franchisee_Code": "S-R-S-4597",
                               "Franchisee_Name": "Kidzee Tarnaka",
                               "Operating_Status": "A",
                               "Address1": "H. No. 12-13-806",
                               "Address2": "Street no. 2, Kimtee Colony",
                               "Place": "Tarnaka ,Kimtee Colony",
                               "City_Id": "882",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4597@kidzee.com",
                               "Mobile_No": "6309884611",
                               "Phone_No1": "6309884611",
                               "Phone_No2": "",
                               "Latitude": "17.429781277713612",
                               "Longitude": "78.53885849073791",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461385/10950/0d6e5e66_76a7_4ebe_97ca_cac84be9987f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2461385/10950/f2efff8a_e81b_4463_918c_f5aad4161a15.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "S-R-C-5395",
                               "Franchisee_Name": "Kidzee Kapra",
                               "Operating_Status": "A",
                               "Address1": "plot no 244,panchsheel enclave ",
                               "Address2": "phase 2,kapra,yarpal PO",
                               "Place": "medchal-malakagiri district ",
                               "City_Id": "882",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "mvk1577@gmail.com",
                               "Mobile_No": "8500089568",
                               "Phone_No1": "9040-855585",
                               "Phone_No2": "",
                               "Latitude": "17.505339816925062",
                               "Longitude": "78.55823278427124"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5194",
                               "Franchisee_Name": "Kidzee Yapral",
                               "Operating_Status": "A",
                               "Address1": "C-5194 C-314, Greenwood Residency,",
                               "Address2": "Behind Patel's Bright View Apts, Kowkoor Dargah Road,",
                               "Place": "Kowkoor Dargah Road,  Yapral",
                               "City_Id": "882",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5194@kidzee.com",
                               "Mobile_No": "9063009443",
                               "Phone_No1": "9419917533",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Hanumakonda / Hanmakonda / Hanamkonda"
                      },
                      {
                         "City_Name": "Nizamabad"
                      },
                      {
                         "City_Name": "Jagtial",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-2528",
                               "Franchisee_Name": "Kidzee Jagtial",
                               "Operating_Status": "A",
                               "Address1": "HNo-1-4-169,",
                               "Address2": "Krishna Nagar,",
                               "Place": "Jagtial, OPP- MD Shankar Hospitial,",
                               "City_Id": "1409",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2528@kidzee.com",
                               "Mobile_No": "8008436363",
                               "Phone_No1": "9246-936363",
                               "Phone_No2": "",
                               "Latitude": "18.79042104375998",
                               "Longitude": "78.91818796512223",
                               "Images": {
                                  "Image_URL": "https://www.ekidzee.com/images/PhotoGallery/ImageGallery/839756/10749/6f5f9934_b92d_438d_8056_74f1f3ade8fb.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "S-R-C-4207",
                               "Franchisee_Name": "Kidzee Metpally",
                               "Operating_Status": "A",
                               "Address1": "H.No.1-8-283,",
                               "Address2": "Adarsa Nagar,",
                               "Place": "Metpally, Center Vaccated,",
                               "City_Id": "1409",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4207@kidzee.com",
                               "Mobile_No": "9959967912",
                               "Phone_No1": "9959967912",
                               "Phone_No2": "",
                               "Latitude": "18.84566",
                               "Longitude": "78.62532"
                            }
                         ]
                      },
                      {
                         "City_Name": "Karimnagar",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-4497",
                               "Franchisee_Name": "Kidzee Korutla",
                               "Operating_Status": "A",
                               "Address1": "H-No-4-2-141/2, IB Road,",
                               "Address2": "Distt- Jagtial,",
                               "Place": "Korutla, Near Post Office,",
                               "City_Id": "1410",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4497@kidzee.com",
                               "Mobile_No": "9440555810",
                               "Phone_No1": "9440143703",
                               "Phone_No2": "",
                               "Latitude": "18.82298",
                               "Longitude": "78.70736"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5294",
                               "Franchisee_Name": "Kidzee Karimnagar AADHYA KRUTHI EDUCATIONAL SOCIETY",
                               "Operating_Status": "A",
                               "Address1": "H.No. 4-46/55/5D/N, Vidhyaranyapuri,",
                               "Address2": "Theegalaguttapalli,",
                               "Place": "Vidhyaranyapuri",
                               "City_Id": "1410",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5294@kidzee.com",
                               "Mobile_No": "8099717332",
                               "Phone_No1": "8099717332",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Hayathnagar"
                      },
                      {
                         "City_Name": "Manikonda"
                      },
                      {
                         "City_Name": "Secunderabad",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-S-2800",
                            "Franchisee_Name": "Kidzee Trimulgiri  Secunderabad",
                            "Operating_Status": "A",
                            "Address1": "Lavanya Educational Trust,  Plot-# 83/B , Surya Enclave,",
                            "Address2": "Secunderabad,",
                            "Place": "Trimulgiri, All Saints Road,",
                            "City_Id": "1625",
                            "State_Id": "46",
                            "Country_Id": "1",
                            "Email_Id": "kidzee2800@kidzee.com",
                            "Mobile_No": "9000588911",
                            "Phone_No1": "9000588911",
                            "Phone_No2": "",
                            "Latitude": "17.47236",
                            "Longitude": "78.503962",
                            "Images": [
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2800/124/f2ed61bb_8fe1_4b43_9411_64a4446ced11.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2800/124/328939b1_f303_40ff_9b04_65871aa08ba7.jpg"
                               },
                               {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/2800/124/4d0b3052_3c7f_4269_b9a2_f16d4b9f377d.jpg"
                               }
                            ]
                         }
                      },
                      {
                         "City_Name": "Bhoopalapally"
                      },
                      {
                         "City_Name": "Khammam",
                         "Franchisee": {
                            "Franchisee_Code": "S-R-C-5654",
                            "Franchisee_Name": "Kidzee Khammam",
                            "Operating_Status": "A",
                            "Address1": "Mytri Nagar Road No 1 ",
                            "Address2": "Opp. New Mamatha College Gate",
                            "Place": "Golla Godem Road",
                            "City_Id": "1834",
                            "State_Id": "46",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee5654@kidzee.com",
                            "Mobile_No": "9705362299",
                            "Phone_No1": "9291563462",
                            "Phone_No2": "",
                            "Latitude": "",
                            "Longitude": ""
                         }
                      },
                      {
                         "City_Name": "Sangareddy",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-4238",
                               "Franchisee_Name": "Kidzee Sangareddy",
                               "Operating_Status": "A",
                               "Address1": "H.No. 5-1-15/2, First Floor,",
                               "Address2": "Kalvakunta Village,",
                               "Place": "Shanthi Nagar, 2nd Lane Shanti Nagar,",
                               "City_Id": "1845",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4238@kidzee.com",
                               "Mobile_No": "7989824292",
                               "Phone_No1": "8008576669",
                               "Phone_No2": "",
                               "Latitude": "17.611808025210856",
                               "Longitude": "78.08494091033936"
                            },
                            {
                               "Franchisee_Code": "S-R-C-4320",
                               "Franchisee_Name": "Kidzee Tellapur",
                               "Operating_Status": "A",
                               "Address1": "H.No- 6-84,",
                               "Address2": "RC Puram Mandal,",
                               "Place": "Tellapur, Beside-Aliens Apartment,",
                               "City_Id": "1845",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4320@kidzee.com",
                               "Mobile_No": "9492305493",
                               "Phone_No1": "9492305493",
                               "Phone_No2": "",
                               "Latitude": "17.46267",
                               "Longitude": "78.28742"
                            }
                         ]
                      },
                      {
                         "City_Name": "Mahabubnagar"
                      },
                      {
                         "City_Name": "Suryapet",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "S-R-C-4511",
                               "Franchisee_Name": "Kidzee Kodad",
                               "Operating_Status": "A",
                               "Address1": "Revenue Number Of Land Survey Number-151/1&152/1,",
                               "Address2": "Kodad Mandal,",
                               "Place": "Kodad, Near ABCD School,",
                               "City_Id": "1864",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4511@kidzee.com",
                               "Mobile_No": "9989598611",
                               "Phone_No1": "9848464606",
                               "Phone_No2": "",
                               "Latitude": "16.9970679",
                               "Longitude": "79.9754279"
                            },
                            {
                               "Franchisee_Code": "S-R-C-5155",
                               "Franchisee_Name": "Kidzee Suryapet",
                               "Operating_Status": "A",
                               "Address1": "H.No.1-6-93, Vidya Nagar",
                               "Address2": "Bhumireddy Children's Hospital Lane",
                               "Place": "Suryapet Town and District",
                               "City_Id": "1864",
                               "State_Id": "46",
                               "Country_Id": "1",
                               "Email_Id": "kidzee5155@kidzee.com",
                               "Mobile_No": "9490074040",
                               "Phone_No1": "9059718791",
                               "Phone_No2": "",
                               "Latitude": "14.75132",
                               "Longitude": "74.23893"
                            }
                         ]
                      },
                      {
                         "City_Name": "Jagtiyal"
                      },
                      {
                         "City_Name": "Gadwal"
                      },
                      {
                         "City_Name": "Adilabad"
                      }
                   ]
                },
                {
                   "State_Name": "Ladakh",
                   "City": {
                      "City_Name": "Leh",
                      "Franchisee": {
                         "Franchisee_Code": "N-R-C-5545",
                         "Franchisee_Name": "Kidzee Sheynam",
                         "Operating_Status": "A",
                         "Address1": "Plot Near Hotel Padma Ghergher,",
                         "Address2": "Ground Floor",
                         "Place": "Sheynam",
                         "City_Id": "1977",
                         "State_Id": "122",
                         "Country_Id": "1",
                         "Email_Id": "Kidzee5545@kidzee.com",
                         "Mobile_No": "7006534802",
                         "Phone_No1": "7006534802",
                         "Phone_No2": "",
                         "Latitude": "",
                         "Longitude": ""
                      }
                   }
                },
                {
                   "State_Name": "NCR",
                   "City": [
                      {
                         "City_Name": "Faridabad",
                         "Franchisee": {
                            "Franchisee_Code": "N-R-S-4916",
                            "Franchisee_Name": "Kidzee Sector 21/ D Faridabad",
                            "Operating_Status": "A",
                            "Address1": "Nursery School Site No-1,",
                            "Address2": "Near-House No-1167-A,",
                            "Place": "Sector-21/ D, Near-Labour Chowk,",
                            "City_Id": "315",
                            "State_Id": "16",
                            "Country_Id": "1",
                            "Email_Id": "Kidzee4916@kidzee.com",
                            "Mobile_No": "9873995242",
                            "Phone_No1": "9873995242",
                            "Phone_No2": "",
                            "Latitude": "77.2869460",
                            "Longitude": "28.4170560"
                         }
                      },
                      {
                         "City_Name": "Ghaziabad",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-B-S-565",
                               "Franchisee_Name": "Kidzee- Indirapuram Ghaziabad, U.P",
                               "Operating_Status": "A",
                               "Address1": "Plot No.4,",
                               "Address2": "",
                               "Place": "Indirapuram-1, Shakti Khand -2,",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee565@kidzee.com",
                               "Mobile_No": "9868554882",
                               "Phone_No1": "9868554882",
                               "Phone_No2": "",
                               "Latitude": "28.646861",
                               "Longitude": "77.37073",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/391/10429/d461a75d_1fa8_485f_8cdc_ab81efb65e02.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/391/10429/8784e29e_e76d_4a06_b785_f8bbbd8185bb.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/391/10429/e545e90b_10aa_451d_b091_8c8bcf886ce8.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-S-C-1614",
                               "Franchisee_Name": "Kidzee-Pilkhuwa-Gaziabad",
                               "Operating_Status": "A",
                               "Address1": "Near Vineet Textiles, Arya Nagar,",
                               "Address2": "Chandi Mandir",
                               "Place": "Pilkhuwa ,Near Chandi Temple",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1614@kidzee.com",
                               "Mobile_No": "7017672234",
                               "Phone_No1": "1222321346",
                               "Phone_No2": "",
                               "Latitude": "28.669156",
                               "Longitude": "77.453758"
                            },
                            {
                               "Franchisee_Code": "N-B-S-474",
                               "Franchisee_Name": "Kidzee-Vaishali",
                               "Operating_Status": "A",
                               "Address1": "A- 301,",
                               "Address2": "Opposite- Punjab National Bank,",
                               "Place": "Vaishali, Sector-3",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee474@kidzee.com",
                               "Mobile_No": "9718990010",
                               "Phone_No1": "9811109319",
                               "Phone_No2": "",
                               "Latitude": "28.6416320552894",
                               "Longitude": "77.3356014490128"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3260",
                               "Franchisee_Name": "Kidzee Vasundara Ghaziabad",
                               "Operating_Status": "A",
                               "Address1": "Plot No 1466.",
                               "Address2": "Sector 5, Near Indian Bank,Vasundhara",
                               "Place": "Sector-5 ,Near Indian Bank VASUNDHARA",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee3260@kidzee.com",
                               "Mobile_No": "9643437878",
                               "Phone_No1": "9643437878",
                               "Phone_No2": "",
                               "Latitude": "28.659513",
                               "Longitude": "77.063047"
                            },
                            {
                               "Franchisee_Code": "N-B-S-796",
                               "Franchisee_Name": "Kidzee- Raj Nagar -Ghaziabad",
                               "Operating_Status": "A",
                               "Address1": "K-41,",
                               "Address2": "Sector -23,",
                               "Place": "Raj Nagar, Near- Sculpture Park,",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee796@kidzee.com",
                               "Mobile_No": "7042120876",
                               "Phone_No1": "1204138755",
                               "Phone_No2": "",
                               "Latitude": "28.693828",
                               "Longitude": "77.452792"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3380",
                               "Franchisee_Name": "Kidzee  Indrapuram",
                               "Operating_Status": "A",
                               "Address1": "125-Gyan Khand-1,",
                               "Address2": "",
                               "Place": "Indrapuram, Gyankhand-1,",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3380@kidzee.com",
                               "Mobile_No": "9999496891",
                               "Phone_No1": "9999496891",
                               "Phone_No2": "",
                               "Latitude": "28.643754",
                               "Longitude": "77.352901",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/f631e185_1633_4b8b_ba2e_352991a075fa.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/0e7e22c0_882e_40e3_aa9e_d2a6e309260f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/466ffc51_9bde_4ea2_bea1_ffa2de2adc86.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/348fb2fe_2bda_4cb0_8bda_5b5dd0c1ed5e.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/a8618839_ec57_4de0_93e4_51fda55c0ed3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/62b1eabf_759c_452f_9767_55c5a9a84f04.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/66b3e4c4_22a3_4a2e_8b97_29f6000f4d9a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/d15ffce2_f28c_44bd_b52d_dde558ae10f7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/0ce8bef7_ced9_4414_90a0_69c7f6a6cf8f.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/68e533c3_dcfb_4571_9f22_918e7c7c9dec.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/3380/95/5a5dc3c5_afa0_45ca_9e25_36c4191ce131.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-C-2809",
                               "Franchisee_Name": "Kidzee Ghaziabad",
                               "Operating_Status": "A",
                               "Address1": "Radha Krishna Public School,",
                               "Address2": "",
                               "Place": "Lal Kuan, Krishna Vihar Colony,",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2809@kidzee.com",
                               "Mobile_No": "9910098898",
                               "Phone_No1": "9810743885",
                               "Phone_No2": "",
                               "Latitude": "28.632926",
                               "Longitude": "77.474674"
                            },
                            {
                               "Franchisee_Code": "N-R-S-2032",
                               "Franchisee_Name": "Kidzee Ghaziabad UP",
                               "Operating_Status": "A",
                               "Address1": "H.No 99,Tower-No-6,",
                               "Address2": "",
                               "Place": "Crossing Republic,",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2032@kidzee.com",
                               "Mobile_No": "9312922444",
                               "Phone_No1": "9312922444",
                               "Phone_No2": "",
                               "Latitude": "28.629409",
                               "Longitude": "77.432905"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3421",
                               "Franchisee_Name": "Kidzee Shastri Nagar",
                               "Operating_Status": "A",
                               "Address1": "SJ-1,",
                               "Address2": "Block-J,",
                               "Place": "Shastri Nagar -1,",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3421@kidzee.com",
                               "Mobile_No": "9818001187",
                               "Phone_No1": "8882428755",
                               "Phone_No2": "",
                               "Latitude": "28.667282",
                               "Longitude": "77.456359"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4954",
                               "Franchisee_Name": "Kidzee Sector 5 Ghaziabad",
                               "Operating_Status": "A",
                               "Address1": "973, Ground Floor,",
                               "Address2": "Sector-5, NH 24,",
                               "Place": "Wave City, Near-A.S.Royal Farmhouse,",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee4954@kidzee.com",
                               "Mobile_No": "9015292050",
                               "Phone_No1": "9015292050",
                               "Phone_No2": "",
                               "Latitude": "77.4915000",
                               "Longitude": "28.6625860"
                            },
                            {
                               "Franchisee_Code": "N-R-S-4312",
                               "Franchisee_Name": "Rudra Education Care",
                               "Operating_Status": "A",
                               "Address1": "Khasra No.839,",
                               "Address2": "opposit-Charms Castle, Main Road,",
                               "Place": "Raj Nagar Ext. Near-Bank Of India,",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4312@kidzee.com",
                               "Mobile_No": "7835980470",
                               "Phone_No1": "7982398982",
                               "Phone_No2": "",
                               "Latitude": "28.638837",
                               "Longitude": "77.472375"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5465",
                               "Franchisee_Name": "Kidzee Chiranjeev vihar",
                               "Operating_Status": "A",
                               "Address1": "House No-265/1 ,",
                               "Address2": "Sector 1,",
                               "Place": "Chiranjeev vihar",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5465@kidzee.com",
                               "Mobile_No": "9557777456",
                               "Phone_No1": "9557777456",
                               "Phone_No2": "",
                               "Latitude": "28.6689576",
                               "Longitude": "77.4737598"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5415",
                               "Franchisee_Name": "Kidzee Raj Nagar extention",
                               "Operating_Status": "A",
                               "Address1": "Block A,Staircase b , UG , 2,",
                               "Address2": "Milan earth society ,",
                               "Place": "Raj Nagar extention",
                               "City_Id": "362",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5415@kidzee.com",
                               "Mobile_No": "9899673310",
                               "Phone_No1": "9899673310",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Greater noida",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-R-S-4803",
                               "Franchisee_Name": "Kidzee Greater Noida",
                               "Operating_Status": "A",
                               "Address1": "NS - 86, Eta-1,",
                               "Address2": "Gulmohar Estate,",
                               "Place": "Gautam Budh Nagar,",
                               "City_Id": "391",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4803@kidzee.com",
                               "Mobile_No": "9625077143",
                               "Phone_No1": "9625077143",
                               "Phone_No2": "",
                               "Latitude": "28.047820",
                               "Longitude": "78.837830"
                            },
                            {
                               "Franchisee_Code": "N-R-S-4397",
                               "Franchisee_Name": "Kidzee Greater Noida West",
                               "Operating_Status": "A",
                               "Address1": "NS-05, Opposit Supertec Eco Village-1,",
                               "Address2": "Sector-2,",
                               "Place": "Greater Noida (West), OPP To Supertech ECO Village",
                               "City_Id": "391",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4397@kidzee.com",
                               "Mobile_No": "9953104744",
                               "Phone_No1": "9953104744",
                               "Phone_No2": "",
                               "Latitude": "28.582385",
                               "Longitude": "77.443407"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3898",
                               "Franchisee_Name": "Kidzee Gaur City",
                               "Operating_Status": "A",
                               "Address1": "B 80 & 81, First Avenue",
                               "Address2": " Noida Extension,Gautaum Budh Nagar",
                               "Place": "Gaur City,GAUR CITY FIRST AVENUE",
                               "City_Id": "391",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3898@kidzee.com",
                               "Mobile_No": "9015566686",
                               "Phone_No1": "9953104744",
                               "Phone_No2": "",
                               "Latitude": "28.612903",
                               "Longitude": "77.424831"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5594",
                               "Franchisee_Name": "Kidzee Omicron-2",
                               "Operating_Status": "A",
                               "Address1": "Villa No.-126",
                               "Address2": "Block-B",
                               "Place": "Omicron-2",
                               "City_Id": "391",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5594@kidzee.com",
                               "Mobile_No": "9999791101",
                               "Phone_No1": "9999791101",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5040",
                               "Franchisee_Name": "KIDZEE GAUTAMBUDDHANAGAR",
                               "Operating_Status": "A",
                               "Address1": "C/O, Rajendra Singh Ameen,",
                               "Address2": "Near- Nagarpalika,",
                               "Place": "Dadri, Gautam Buddh Nagar,",
                               "City_Id": "391",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5040@kidzee.com",
                               "Mobile_No": "9910048692",
                               "Phone_No1": "9910048692",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-S-5779",
                               "Franchisee_Name": "Kidzee Gautam Buddha Nagar",
                               "Operating_Status": "A",
                               "Address1": "NS - 03, SEC - 10",
                               "Address2": "GREATER NOIDA",
                               "Place": "GAUTAM BUDDHA NAGAR",
                               "City_Id": "391",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5779@kidzee.com",
                               "Mobile_No": "9999044303",
                               "Phone_No1": "9999044303",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-5702",
                               "Franchisee_Name": "Kidzee P3 Greater Noida",
                               "Operating_Status": "A",
                               "Address1": "NS 47",
                               "Address2": "Opposite B 69",
                               "Place": "P 3",
                               "City_Id": "391",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5702@kidzee.com",
                               "Mobile_No": "9899673310",
                               "Phone_No1": "9899673310",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Gurgaon",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-S-S-1581",
                               "Franchisee_Name": "Kidzee-Little Scholars-SuryaVihar-Gurgaon",
                               "Operating_Status": "A",
                               "Address1": "House No-34/6/2/2, Dhanwapur Road,",
                               "Address2": "Block A, Surya Vihar, Sector -4 Gurgaon",
                               "Place": "Surya Vihar,",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "kidzee1581@kidzee.com",
                               "Mobile_No": "9891313734",
                               "Phone_No1": "9891313734",
                               "Phone_No2": "",
                               "Latitude": "28.4671312599706",
                               "Longitude": "77.020275592804",
                               "Images": {
                                  "Image_URL": "http://www.kidzee.net/images/PhotoGallery/1581/298/7f14fb30_1696_4706_8499_16a6489723f8.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-S-2635",
                               "Franchisee_Name": "Kidzee Gurgoan",
                               "Operating_Status": "A",
                               "Address1": "Moti Vihar,",
                               "Address2": "Village Silokhra,",
                               "Place": "Gurgaon, Central Avenue Moti Vihar,",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2635@kidzee.com",
                               "Mobile_No": "7840000039",
                               "Phone_No1": "0124-2381239",
                               "Phone_No2": "",
                               "Latitude": "28.457282373498312",
                               "Longitude": "77.06274037717255"
                            },
                            {
                               "Franchisee_Code": "N-R-C-2167",
                               "Franchisee_Name": "Kidzee  Manesar Haryana",
                               "Operating_Status": "A",
                               "Address1": "Village Naharpur (Kasan)",
                               "Address2": "on SH -15a, Nawada Naharpur Road, opposite logistics company",
                               "Place": "Manesar, MANESAR",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2167@kidzee.com",
                               "Mobile_No": "9718148683",
                               "Phone_No1": "0124-6511755",
                               "Phone_No2": "",
                               "Latitude": "28.22376",
                               "Longitude": "76.56148"
                            },
                            {
                               "Franchisee_Code": "N-R-S-4907",
                               "Franchisee_Name": "Kidzee Gurgaon Sec 49",
                               "Operating_Status": "A",
                               "Address1": "Shree Narsingh Education Consulting Pvt Ltd",
                               "Address2": "Plot No. NS-02, H Block South City 2",
                               "Place": "Gurgaon Sec-49 , Next to Ram Rahim Ashram",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4907@kidzee.com",
                               "Mobile_No": "9810193751",
                               "Phone_No1": "9810193751",
                               "Phone_No2": "",
                               "Latitude": "28.422946",
                               "Longitude": "77.057576"
                            },
                            {
                               "Franchisee_Code": "N-R-C-3568",
                               "Franchisee_Name": "Kidzee  Sector 93, Gurgaon",
                               "Operating_Status": "A",
                               "Address1": "VPO-Village Hayat Pur,",
                               "Address2": "Distt. Gurgaon,  Hayat Pur,",
                               "Place": "Sector-93, Gurgaon,",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3568@kidzee.com",
                               "Mobile_No": "9821213064",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "28.4123",
                               "Longitude": "76.9344"
                            },
                            {
                               "Franchisee_Code": "N-R-C-5163",
                               "Franchisee_Name": "Kidzee Mohmmadpur",
                               "Operating_Status": "A",
                               "Address1": "Village, Mohmmadpur Jharsa,",
                               "Address2": "",
                               "Place": "Gurgaon, Village Mohammadpur,",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5163@kidzee.com",
                               "Mobile_No": "8826004188",
                               "Phone_No1": "9810634188",
                               "Phone_No2": "",
                               "Latitude": "28.422548",
                               "Longitude": "76.984718"
                            },
                            {
                               "Franchisee_Code": "N-R-C-4090",
                               "Franchisee_Name": "Kidzee Bhangrola",
                               "Operating_Status": "A",
                               "Address1": "Sector- 87,",
                               "Address2": "Near Bus Stand",
                               "Place": "Near Bus stand Kankrola . Post Office Bhangrola",
                               "City_Id": "395",
                               "State_Id": "11",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4090@kidzee.com",
                               "Mobile_No": "7290000243",
                               "Phone_No1": "8376067721",
                               "Phone_No2": "",
                               "Latitude": "28.393388",
                               "Longitude": "76.915801",
                               "Images": {
                                  "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1965647/11077/7971054e_d48c_4159_a134_854ded7d88a0.jpg"
                               }
                            },
                            {
                               "Franchisee_Code": "N-R-C-3567",
                               "Franchisee_Name": "Kidzee  Kherki Daula",
                               "Operating_Status": "A",
                               "Address1": "H.No-162, Kherki Daula,",
                               "Address2": "Gurgaon Teh - Manesar,",
                               "Place": "Kherki Daula,",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3567@kidzee.com",
                               "Mobile_No": "9999284280",
                               "Phone_No1": "",
                               "Phone_No2": "",
                               "Latitude": "28.400719",
                               "Longitude": "76.984241"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5140",
                               "Franchisee_Name": "Kidzee Gurgaon Sec 46",
                               "Operating_Status": "A",
                               "Address1": "House No-1511,",
                               "Address2": "Ground Floor,",
                               "Place": "Gurgaon, Sector-46,",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5140@kidzee.com",
                               "Mobile_No": "9050050304",
                               "Phone_No1": "8570805115",
                               "Phone_No2": "",
                               "Latitude": "28.436281",
                               "Longitude": "77.09509"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5105",
                               "Franchisee_Name": "Kidzee Sec -1 IMT Manesar",
                               "Operating_Status": "A",
                               "Address1": "H. No. 450,",
                               "Address2": "Ground Floor",
                               "Place": "Sec-1 IMT Manesar",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5105@kidzee.com",
                               "Mobile_No": "7838513307",
                               "Phone_No1": "7838513307",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-S-5300",
                               "Franchisee_Name": "Kidzee Westerlies",
                               "Operating_Status": "A",
                               "Address1": "NS-01, Dharampura",
                               "Address2": "The Westerlies,",
                               "Place": "Sector 108",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5300@kidzee.com",
                               "Mobile_No": "9811850006",
                               "Phone_No1": "9811850006",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-S-5555",
                               "Franchisee_Name": "Kidzee  Gurugram Sector 56",
                               "Operating_Status": "A",
                               "Address1": "House No. - 204, Basement Plus Ground Floor",
                               "Address2": "Sector - 56",
                               "Place": "Gurugram Sector 56",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5555@kidzee.com",
                               "Mobile_No": "8588900168",
                               "Phone_No1": "8588900168",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-C-4335",
                               "Franchisee_Name": "Kidzee Maruti Kunj",
                               "Operating_Status": "A",
                               "Address1": "H-No-50 B Block,",
                               "Address2": "Bhondsi,",
                               "Place": "Maruti Kunj, Maruti Kunj Gali No.1,",
                               "City_Id": "395",
                               "State_Id": "16",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4335@kidzee.com",
                               "Mobile_No": "9560830573",
                               "Phone_No1": "9560830573",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      },
                      {
                         "City_Name": "Noida",
                         "Franchisee": [
                            {
                               "Franchisee_Code": "N-S-S-2209",
                               "Franchisee_Name": "Kidzee THE DILIGENT WORLD EDUCATIONAL AND TECHNICAL INSTITUTE DELHI",
                               "Operating_Status": "A",
                               "Address1": "Nursery School Site,",
                               "Address2": "I -110, Sector-27,",
                               "Place": "Near- Kailash Hospital,",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2209@kidzee.com",
                               "Mobile_No": "9811130621",
                               "Phone_No1": "01204264076",
                               "Phone_No2": "",
                               "Latitude": "28.575083",
                               "Longitude": "77.328135"
                            },
                            {
                               "Franchisee_Code": "N-R-S-2883",
                               "Franchisee_Name": "Kidzee sector 100 Noida",
                               "Operating_Status": "A",
                               "Address1": "Village Hazipur,",
                               "Address2": "",
                               "Place": "Gautam Buddha Nagar, Sector-100, Near- Goldy Gym",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2883@kidzee.com",
                               "Mobile_No": "9717995235",
                               "Phone_No1": "8287420333",
                               "Phone_No2": "",
                               "Latitude": "28.53935",
                               "Longitude": "77.367614"
                            },
                            {
                               "Franchisee_Code": "N-R-S-2055",
                               "Franchisee_Name": "Kidzee Noida",
                               "Operating_Status": "A",
                               "Address1": "Plot No-GT-93,",
                               "Address2": "",
                               "Place": "Sector 93, Opp ATS Greens,",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "kidzee2055@kidzee.com",
                               "Mobile_No": "8860772555",
                               "Phone_No1": "8860772555",
                               "Phone_No2": "",
                               "Latitude": "28.523428",
                               "Longitude": "77.38548"
                            },
                            {
                               "Franchisee_Code": "N-R-S-3576",
                               "Franchisee_Name": "Kidzee Sector 116",
                               "Operating_Status": "A",
                               "Address1": "Residential Plot No. 04",
                               "Address2": "Block - SD Gautam Budh Nagar",
                               "Place": "Sector 116, NOIDA SEC 116 NEAR SETHI MAX",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3576@kidzee.com",
                               "Mobile_No": "9821775568",
                               "Phone_No1": "9871716109",
                               "Phone_No2": "",
                               "Latitude": "28.569232",
                               "Longitude": "77.395424",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1618141/10462/cbaefe8c_a401_4ca3_a2e8_52075dc236d5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1618141/10462/edc9dfcc_cb1a_435d_9068_5866b55bccc7.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1618141/10463/7fb680ba_a105_4007_b4a1_a1374b5c5589.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1618141/10463/e3bbdb41_9ed9_4a7c_9ee6_c85a4d6dc20d.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1618141/10462/b4fb0f0a_8686_4fc7_bf3b_6eaa03c533ff.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.kidzee.net/images/PhotoGallery/ImageGallery/1618141/10463/efbc9efb_5be5_4745_b288_6c0a80f21a56.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-S-4804",
                               "Franchisee_Name": "Kidzee Sector 143 B",
                               "Operating_Status": "A",
                               "Address1": "JH-06",
                               "Address2": "Sec 143 B ,Near Logix Bloom Greens, Noida Expressway",
                               "Place": "Sec 143 B ,NEAR LOGIX BLOSSOM GREENS",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4804@kidzee.com",
                               "Mobile_No": "8700822312",
                               "Phone_No1": "8800332061",
                               "Phone_No2": "",
                               "Latitude": "28.4972405019008",
                               "Longitude": "77.42498383432394",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2531965/11027/28151315_6b18_498d_af39_db4ae2d12a98.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2531965/11028/69f7fd1c_100a_4d75_a3b1_8e301a1023f3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/2531965/11028/ae3a4610_155e_4542_8136_2116ff9dbb91.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-S-3848",
                               "Franchisee_Name": "Kidzee G B Nagar Noida",
                               "Operating_Status": "A",
                               "Address1": "SD-131 ground floor,",
                               "Address2": "Sector 45 Noida",
                               "Place": "G B Nagar ,NOIDA SEC 45 SADARPUR",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee3848@kidzee.com",
                               "Mobile_No": "9717496054",
                               "Phone_No1": "9717496054",
                               "Phone_No2": "",
                               "Latitude": "28.550129",
                               "Longitude": "77.353392",
                               "Images": [
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1838925/11020/a75e66f9_5518_41c1_ad8f_02d9cf949a6a.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1838925/11020/6b2f7ba2_c9f2_49cb_b046_ab8800881899.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1838925/11020/fa492881_864d_470e_b470_5e710b3894b5.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1838925/11020/e95401d4_c03a_4cb2_8655_cbe74c843eea.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1838925/11020/91f9c592_c1e8_421d_9852_f8097e9078d3.jpg"
                                  },
                                  {
                                     "Image_URL": "http://www.ekidzee.com/images/PhotoGallery/ImageGallery/1838925/11020/defa2d00_19bd_4735_b672_84ec6a165c02.jpg"
                                  }
                               ]
                            },
                            {
                               "Franchisee_Code": "N-R-S-4562",
                               "Franchisee_Name": "Kidzee Sector 141",
                               "Operating_Status": "A",
                               "Address1": "SH-20 & 21,",
                               "Address2": ", Opp. Logix Blossom County",
                               "Place": "gate number 1,Sector 141",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee4562@kidzee.com",
                               "Mobile_No": "8383809197",
                               "Phone_No1": "8383809197",
                               "Phone_No2": "",
                               "Latitude": "28.527324",
                               "Longitude": "77.389097"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5087",
                               "Franchisee_Name": "Kidzee Sector 70 Noida",
                               "Operating_Status": "A",
                               "Address1": "House No. 16",
                               "Address2": "Ground Floor",
                               "Place": "Sector 70, Noida",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5087@kidzee.com",
                               "Mobile_No": "8800782888",
                               "Phone_No1": "8920207357",
                               "Phone_No2": "",
                               "Latitude": "28.561444",
                               "Longitude": "77.34971"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5390",
                               "Franchisee_Name": "Kidzee Noida Sector 112",
                               "Operating_Status": "A",
                               "Address1": "Sk 61 ,",
                               "Address2": "Near to Sector 78",
                               "Place": "Noida , Sector 112",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5390@kidzee.com",
                               "Mobile_No": "9910928346",
                               "Phone_No1": "0120-3673490",
                               "Phone_No2": "",
                               "Latitude": "77.410201",
                               "Longitude": "28.562060"
                            },
                            {
                               "Franchisee_Code": "N-R-S-5808",
                               "Franchisee_Name": "Kidzee Noida sector 12",
                               "Operating_Status": "A",
                               "Address1": "House No.- I-21, ",
                               "Address2": "Gautam Buddha Nagar",
                               "Place": "Noida sector 12",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5808@kidzee.com",
                               "Mobile_No": "9212093177",
                               "Phone_No1": "9212093177",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            },
                            {
                               "Franchisee_Code": "N-R-S-5769",
                               "Franchisee_Name": "Kidzee Noida sector-122",
                               "Operating_Status": "A",
                               "Address1": "PKB-77, Parthla Khanjarpur",
                               "Address2": "Parthla Khanjarpur,Gautam buddha Nagar",
                               "Place": "Noida sector-122",
                               "City_Id": "743",
                               "State_Id": "35",
                               "Country_Id": "1",
                               "Email_Id": "Kidzee5769@kidzee.com",
                               "Mobile_No": "8130901743",
                               "Phone_No1": "8130901743",
                               "Phone_No2": "",
                               "Latitude": "",
                               "Longitude": ""
                            }
                         ]
                      }
                   ]
                }
             ]
          },
          {
             "Country_Name": "Nepal",
             "State": [
                {
                   "State_Name": "Morang District",
                   "City": {
                      "City_Name": "Biratnagar"
                   }
                },
                {
                   "State_Name": "Sunsari District",
                   "City": [
                      {
                         "City_Name": "Inaruwa"
                      },
                      {
                         "City_Name": "Dharan"
                      }
                   ]
                },
                {
                   "State_Name": "Bhojpur District",
                   "City": {
                      "City_Name": "Bhojpur"
                   }
                },
                {
                   "State_Name": "Dhankuta District",
                   "City": {
                      "City_Name": "Dhankuta"
                   }
                },
                {
                   "State_Name": "Terhathum District",
                   "City": {
                      "City_Name": "Myanglung"
                   }
                },
                {
                   "State_Name": "Sankhuwasabha District",
                   "City": {
                      "City_Name": "Khandbari"
                   }
                },
                {
                   "State_Name": "Jhapa District",
                   "City": {
                      "City_Name": "Chandragadi"
                   }
                },
                {
                   "State_Name": "Ilam District",
                   "City": {
                      "City_Name": "Ilam"
                   }
                },
                {
                   "State_Name": "Panchthar District",
                   "City": {
                      "City_Name": "Phidim"
                   }
                },
                {
                   "State_Name": "Taplejung District",
                   "City": {
                      "City_Name": "Phungling"
                   }
                },
                {
                   "State_Name": "Saptari District",
                   "City": {
                      "City_Name": "Rajbiraj"
                   }
                },
                {
                   "State_Name": "Siraha District",
                   "City": {
                      "City_Name": "Siraha"
                   }
                },
                {
                   "State_Name": "Khotang District",
                   "City": {
                      "City_Name": "Diktel"
                   }
                },
                {
                   "State_Name": "Okhaldhunga District",
                   "City": {
                      "City_Name": "Okhaldhunga"
                   }
                },
                {
                   "State_Name": "Solukhumbu District",
                   "City": {
                      "City_Name": "Salleri"
                   }
                },
                {
                   "State_Name": "Dhanusa District",
                   "City": {
                      "City_Name": "Janakpur"
                   }
                },
                {
                   "State_Name": "Mahottari District",
                   "City": {
                      "City_Name": "Jaleswar"
                   }
                },
                {
                   "State_Name": "Sarlahi District",
                   "City": {
                      "City_Name": "Malangwa"
                   }
                },
                {
                   "State_Name": "Sindhuli District",
                   "City": {
                      "City_Name": "Kamalamai"
                   }
                },
                {
                   "State_Name": "Ramechhap District",
                   "City": {
                      "City_Name": "Manthali"
                   }
                },
                {
                   "State_Name": "Dolakha District",
                   "City": {
                      "City_Name": "Charikot"
                   }
                },
                {
                   "State_Name": "Bhaktapur District",
                   "City": {
                      "City_Name": "Bhaktapur"
                   }
                },
                {
                   "State_Name": "Dhading District",
                   "City": {
                      "City_Name": "Dhading Besi"
                   }
                },
                {
                   "State_Name": "Kathmandu District",
                   "City": {
                      "City_Name": "Kathmandu",
                      "Franchisee": {
                         "Franchisee_Code": "E-R-S-3157",
                         "Franchisee_Name": "Kidsg dhapasi ",
                         "Operating_Status": "A",
                         "Address1": "Anamika Aryal, Sakar Rana , ",
                         "Address2": "House No 29, Dhapasi VDC-9 .",
                         "Place": "50 Meter North from Basundhara Police Post",
                         "City_Id": "1760",
                         "State_Id": "70",
                         "Country_Id": "2",
                         "Email_Id": "bhandarisaroj@gmail.com",
                         "Mobile_No": "9849137922",
                         "Phone_No1": "0977-9801055590",
                         "Phone_No2": "",
                         "Latitude": "",
                         "Longitude": ""
                      }
                   }
                },
                {
                   "State_Name": "Kavrepalanchok District",
                   "City": {
                      "City_Name": "Dhulikhel"
                   }
                },
                {
                   "State_Name": "Lalitpur District",
                   "City": {
                      "City_Name": "Lalitpur"
                   }
                },
                {
                   "State_Name": "Nuwakot District",
                   "City": {
                      "City_Name": "Bidur"
                   }
                },
                {
                   "State_Name": "Rasuwa District",
                   "City": {
                      "City_Name": "Dhunche"
                   }
                },
                {
                   "State_Name": "Sindhupalchok District",
                   "City": {
                      "City_Name": "Chautara"
                   }
                },
                {
                   "State_Name": "Bara District",
                   "City": {
                      "City_Name": "Kalaiya"
                   }
                },
                {
                   "State_Name": "Parsa District",
                   "City": {
                      "City_Name": "Birgunj"
                   }
                },
                {
                   "State_Name": "Rautahat District",
                   "City": {
                      "City_Name": "Gaur"
                   }
                },
                {
                   "State_Name": "Chitwan District",
                   "City": {
                      "City_Name": "Bharatpur"
                   }
                },
                {
                   "State_Name": "Makwanpur District",
                   "City": {
                      "City_Name": "Hetauda"
                   }
                },
                {
                   "State_Name": "Gorkha District",
                   "City": {
                      "City_Name": "Gorkha"
                   }
                },
                {
                   "State_Name": "Kaski District",
                   "City": {
                      "City_Name": "Pokhara"
                   }
                },
                {
                   "State_Name": "Lamjung District",
                   "City": {
                      "City_Name": "Besisahar"
                   }
                },
                {
                   "State_Name": "Syangja District",
                   "City": {
                      "City_Name": "Syangja"
                   }
                },
                {
                   "State_Name": "Tanahu District",
                   "City": {
                      "City_Name": "Byas"
                   }
                },
                {
                   "State_Name": "Manang District",
                   "City": {
                      "City_Name": "Chame"
                   }
                },
                {
                   "State_Name": "Kapilvastu District",
                   "City": {
                      "City_Name": "Kapilvastu"
                   }
                },
                {
                   "State_Name": "Nawalparasi District",
                   "City": {
                      "City_Name": "Parasi"
                   }
                },
                {
                   "State_Name": "Rupandehi District",
                   "City": {
                      "City_Name": "Siddharthanagar"
                   }
                },
                {
                   "State_Name": "Arghakhanchi District",
                   "City": {
                      "City_Name": "Sandhikharka"
                   }
                },
                {
                   "State_Name": "Gulmi District",
                   "City": {
                      "City_Name": "Tamghas"
                   }
                },
                {
                   "State_Name": "Palpa District",
                   "City": {
                      "City_Name": "Tansen"
                   }
                },
                {
                   "State_Name": "Baglung District",
                   "City": {
                      "City_Name": "Baglung"
                   }
                },
                {
                   "State_Name": "Myagdi District",
                   "City": {
                      "City_Name": "Beni"
                   }
                },
                {
                   "State_Name": "Parbat District",
                   "City": {
                      "City_Name": "Kusma"
                   }
                },
                {
                   "State_Name": "Mustang District",
                   "City": {
                      "City_Name": "Jomsom"
                   }
                },
                {
                   "State_Name": "Dang Deukhuri District",
                   "City": {
                      "City_Name": "Tribhuwannagar"
                   }
                },
                {
                   "State_Name": "Pyuthan District",
                   "City": {
                      "City_Name": "Pyuthan Khalanga"
                   }
                },
                {
                   "State_Name": "Rolpa District",
                   "City": {
                      "City_Name": "Liwang"
                   }
                },
                {
                   "State_Name": "Rukum District",
                   "City": {
                      "City_Name": "Musikot"
                   }
                },
                {
                   "State_Name": "Salyan District",
                   "City": {
                      "City_Name": "Salyan Khalanga"
                   }
                },
                {
                   "State_Name": "Dolpa District",
                   "City": {
                      "City_Name": "Dunai"
                   }
                },
                {
                   "State_Name": "Humla District",
                   "City": {
                      "City_Name": "Simikot"
                   }
                },
                {
                   "State_Name": "Jumla District",
                   "City": {
                      "City_Name": "Jumla Khalanga"
                   }
                },
                {
                   "State_Name": "Kalikot District",
                   "City": {
                      "City_Name": "Manma"
                   }
                },
                {
                   "State_Name": "Mugu District",
                   "City": {
                      "City_Name": "Gamgadhi"
                   }
                },
                {
                   "State_Name": "Banke District",
                   "City": {
                      "City_Name": "Nepalganj"
                   }
                },
                {
                   "State_Name": "Bardiya District",
                   "City": {
                      "City_Name": "Gulariya"
                   }
                },
                {
                   "State_Name": "Surkhet District",
                   "City": {
                      "City_Name": "Birendranagar"
                   }
                },
                {
                   "State_Name": "Dailekh District",
                   "City": {
                      "City_Name": "Narayan"
                   }
                },
                {
                   "State_Name": "Jajarkot District",
                   "City": {
                      "City_Name": "Khalanga"
                   }
                },
                {
                   "State_Name": "Kailali District",
                   "City": {
                      "City_Name": "Dhangadhi"
                   }
                },
                {
                   "State_Name": "Achham District",
                   "City": {
                      "City_Name": "Mangalsen"
                   }
                },
                {
                   "State_Name": "Doti District",
                   "City": {
                      "City_Name": "Dipayal"
                   }
                },
                {
                   "State_Name": "Bajhang District",
                   "City": {
                      "City_Name": "Chainpur"
                   }
                },
                {
                   "State_Name": "Bajura District",
                   "City": {
                      "City_Name": "Martadi"
                   }
                },
                {
                   "State_Name": "Kanchanpur District",
                   "City": {
                      "City_Name": "Bhim Datta"
                   }
                },
                {
                   "State_Name": "Dadeldhura District",
                   "City": {
                      "City_Name": "Dadeldhura"
                   }
                },
                {
                   "State_Name": "Baitadi District",
                   "City": {
                      "City_Name": "Gothalapani"
                   }
                },
                {
                   "State_Name": "Darchula District",
                   "City": {
                      "City_Name": "Darchula"
                   }
                }
             ]
          }
       ]
    }
 }
  public placeholder  = '';
 public cityListData:any=[ 
    {'name':'Kolkata'},
    {'name':'Ranchi'},
    {'name':'Patna'},
    {'name':'Raipur'},
    {'name':'Jamshedpur'},
    {'name':'Mumbai'},
    {'name':'Pune'},
    {'name':'Ahmedabad'},
    {'name':'Surat'},
    {'name':'Delhi'},
    {'name':'Noida'},
    {'name':'Faridabad'},
    {'name':'Moradabad'},
    {'name':'Allahabad'},
    {'name':'Kanpur'},
    {'name':'Rajasthan'},
    {'name':'Bangaluru'},
    {'name':'Hyderabad'},
    {'name':'Chennai'},
    {'name':'Salem'},
    {'name':'Mysore'}
  ]
  showCity: any;
  constructor(private fb: FormBuilder, private _servie: CommonService, private _activeRoute: ActivatedRoute, private router: Router,
    private projectService: ProjectSeoService,
    private apiService: ApicallService) {
    this.admissionForm = fb.group({
      fname: ['', Validators.required],
      // lname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      pinCode: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]],
      state: ['', Validators.required],
      city: ['', Validators.required],
      otp: ['', Validators.required],
      budget: ['', Validators.required],
      // altmobile:['']

    })
  }
  ngOnInit(): void {
    this._activeRoute.queryParams.subscribe((params: Params) => {
      this.utm_source=params["utm_source"]!=null?params["utm_source"]:this.utm_source;
      this.utm_medium=params["utm_medium"]!=null?params["utm_medium"]:this.utm_medium;
      this.utm_compaign=params["utm_campaign"]!=null?params["utm_campaign"]:this.utm_compaign;
      this.gclid=params["gclid"]!=null?params["gclid"]:this.gclid;
      this.utm_term=params["utm_term"]!=null?params["utm_term"]:this.utm_term;
      this.utm_content=params["utm_content"]!=null?params["utm_content"]:this.utm_source;
      this.utm_ad=params["utm_ad"]!=null?params["utm_ad"]:this.utm_ad;
    });
    this.selectCountry_State_cityList();
  }

  get f() {
    return this.admissionForm.controls;
  }
  selectCountry_State_cityList() { 
    let state_data = this.state_list_data.root.subroot[0].State;
    this.stateList = state_data
    console.log('state list',this.stateList);   
    // this._servie.getState_countryList().subscribe(
    //   res => {
    //     this.countryList = res.root.subroot
    //  this.stateList = this.countryList.State
    //   })
  }
  selectState(selectVal: any) {
    let state = selectVal.target.value
    let filterCity = this.stateList.filter((x: any) => {
      return x.State_Name == state
    })
    if(!Array.isArray(filterCity[0].City))
    {

      this.cityList=[filterCity[0].City];
    }
    else
    {
      this.cityList = filterCity[0].City;

    }
  }

  selectCity(selectVal: any) {
    let city = selectVal.target.value
    let filterFranchisee = this.cityList.filter((x: any) => {
      return x.City_Name == city
    })
    this.franchiseeList = filterFranchisee[0].Franchisee
  }
  validationForm() {
    this.submitted = true;
    if (this.admissionForm.invalid) {
      if(!this.admissionForm.controls["city"].value){
        this.selectedcity=true;
      }else{
        this.selectedcity=false;
      }
      return;
      
    } else {
      this.submitForm()
    }
  }


  submitForm() {

    if ((this.admissionForm.get('otp')?.value).length == 4) {
      if (this.randomOtp == this.admissionForm.get('otp')?.value) {
        let obj = {
          "City": (this.admissionForm.get('city')?.value),
          "Country": "India",
          "Email": this.admissionForm.get('email')?.value,
          "FirstName":"",
          "HaveSpace": "",
          "LastName": this.admissionForm.get('fname')?.value,
          "Location": "",
          "Mobile": this.admissionForm.get('mobile')?.value,
          "PinCode": this.admissionForm.get('pinCode')?.value,
          "Product": "259262000015975052",
          "ProjectId": "3",
          "SoonStartsIn": "",
          "Source": "gclid",
          "gclid": "gclid",
          "State":this.admissionForm.get('state')?.value,
          "Type": "F",
          "WillingToInvest":this.admissionForm.get('budget')?.value,
          "utm_compaign": this.utm_compaign,
          "utm_medium": this.utm_medium,
          "utm_source": this.utm_source,
          "utm_ad": this.utm_ad,
          "utm_Content": this.utm_content,
          "utm_Term": this.utm_term,
        }
        this._servie.saveDataPartner(obj).subscribe(
          res => {
            this.otp_ValidMsg = false;
            this.otp_inValidMsg = false;
            this.router.navigate(['/partnerThankyou'])
            this.admissionForm.reset();
            this.submitted = false
          }
        )
        this.otp_ValidMsg = true;
        this.otp_inValidMsg = false;

      } else {
        alert('Please Enter Valid OTP')
        this.otp_inValidMsg = true;
        this.otp_ValidMsg = false;
      }
    }
  }

  getMobileNO() {
    if ((this.admissionForm.get('mobile')?.value).length == 10) {
      this.sendMobNO();
    }
  }

  sendMobNO() {
    this.randomOtp = Math.floor(1000 + Math.random() * 9000);
    let mobNo = {
      "MobileNo": this.admissionForm.get('mobile')?.value,
      "smsText": `To validate your ZICA interest use One Time Password ${this.randomOtp}. Think Education. Think Zee Learn.`,
      "sResponse": ""
    }
    this._servie.getOtp(mobNo).subscribe(
      res => {
        this.otpInput = true;
      }
    )
  }

  verifyOTP() {
    if ((this.admissionForm.get('otp')?.value).length == 4) {
      if (this.randomOtp == this.admissionForm.get('otp')?.value) {
        this.otp_ValidMsg = true;
        this.otp_inValidMsg = false;
      } else {
        this.otp_inValidMsg = true;
        this.otp_ValidMsg = false;
      }
    }
  }
  clickMenu(value:any){
    document.getElementById("menuClose")?.click();
    }
    onlyNumberKey(evt:any) {
      let ASCIICode = (evt.which) ? evt.which : evt.keyCode
      if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
          return false;
      return true;
  }

  searchCity(){
    let val = this.admissionForm.get('city')?.value
    this.showCity = this.cityListData.filter((el:any)=>{
        return  el.toLowerCase().includes(val.toLowerCase());
    })
// let val = this.admissionForm.get('city')?.value
//     return this.cityListData.filter((el:any) => el.toLowerCase().includes(val.toLowerCase()));
//   }
  }

  customFilter = function(cityListData: any[], query: string): any[] {
    return cityListData.filter((x:any) => x.name.toLowerCase().startsWith(query.toLowerCase()));
  };
}
