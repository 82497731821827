import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-ux',
  templateUrl: './ui-ux.component.html',
  styleUrls: ['./ui-ux.component.css', '../../../../../src/app/components/commonCss/program.css']
})
export class UiUxComponent {

}
