<section>
    <div class="box1">
      <div class="bitCard_content">
        <app-sub-menu></app-sub-menu>
         </div>
        <div class="row">
            <div class="col-md-6">
                <div class="text1">Course Detail</div>
                <div class="text2">Digital <br>Photography
                </div>
                <div class="text_para">
                    <ul>
                        <li>Learn about camera types, accessories, care for equipment, and mastering exposure.</li>
                        <li>Understand various lens types, characteristics, and filters.</li>
                        <li>Dive into features, styles, composition, lighting, and motion photography.</li>
                        <li>Master photo editing tools, tones, contrast, and advanced techniques.</li>
                        <li>Recreate images, blend art with photography, and delve into color management and printing.</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="img_box">
                    <div class="imgSubBox d-flex">
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/digitalPhoto1.png" class="aniImg1" alt="Digital Photography">
                        </div>
                        <div class="imgContent1">
                            <img src="../../../../assets/img/zica/course/digitalPhoto2.png" class="aniImg2" alt="Digital Photography">
                        </div>
                    </div>
                    <div class="imgContent2">
                        <img src="../../../../assets/img/zica/course/digitalPhoto3.png" class="aniImg3" alt="Digital Photography">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="box2">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-md-12">
                <div class="left_box">
                    <div class="text2">Program In Digital Photography<span class="color_text"> (3 Months)</span>
                    </div>
                    <div class="sub_text mt-4 text-white">Learn from <span
                            class="sub_text_color">The Professionals</span></div>
                    <div class="text_para">
                        <p>
                          Technology breakthroughs have caused the price of digital cameras and digital editing software to drop dramatically, allowing ordinary photographers to create extraordinary pictures inexpensively. With a multitude of in-camera features and computer programs that help photographers fine-tune their work, digital photography can inspire creativity in a different way than film-based photography.
                        </p>
                        <div class="sub_text mt-3 text-white">Become a professional artist</div>
                        <div class="text_para">
                            <p>A student will learn about detailed digital photography, range and exposures, lenses and optics, techniques and styles, composition and learning how to see, lighting techniques, depth of field and aperture control, learning Photoshop and Light-room to edit the photographs, blending art and photography, Color Management and Printing. This program is useful for anyone who wants to learn it as the hobby or want to make a career.</p>
                        </div>
                        <div class="sub_text mt-3 text-white">Be Industry Ready</div>
                        <div class="text_para">
                            <p>Upon completing the Digital Photography course, students will be industry-ready professionals. They'll possess a deep understanding of photography history, digital camera operation, lens technology, and advanced techniques. Proficiency in digital photo editing, color management, and printing ensures they can produce high-quality work. With hands-on experience and creativity honed through the course, students are well-prepared to excel in the competitive field of digital photography and meet industry standards.</p>
                        </div>
                        <div class="sub_text mt-4 text-white">Program Highlights</div>
                            <div class="text_para">
                            <ul>
                              <li>Increase visualization and technical skills</li>
                              <li>Learn from master photographers</li>
                              <li>Learn master photo editing tools, tones, contrast, and advanced techniques.</li>
                            </ul>  
                            </div>
                            <div class="accordion mt-2" id="accordionExample">
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                         Module Outline

                                    </button>
                                  </h2>
                                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <ul>
                                          <li>History</li>
                                          <li>Digital Camera</li>
                                          <li>Camera Lenses</li>
                                        </ul>
                                      </div>
                                      <div class="col-md-6">
                                        <li>Photography Techniques</li>
                                        <li>Digital Photo Editing</li>
                                        <li>Post-Photography Session</li>
                                      </div>
                                    </div> 
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="sub_text_color sub_text mt-4">Career Avenues</div>
                              <div class="row mt-3">
                                <div class="col-6">
                                    <ul>
                                      <li>Portrait Photographer</li>
                                      <li>Wedding Photographer</li>
                                      <li>Commercial Photographer</li>
                                      <li>Fashion Photographer</li>
                                      <li>Photojournalist</li>
                                      <li>Food Photographer</li>
                                      </ul>
                                </div>
                                <div class="col-6">
                                    <ul>
                                      <li>Travel Photographer</li>
                                      <li>Wildlife Photographer</li>
                                      <li>Architectural Photographer</li>
                                      <li>Sports Photographer</li>
                                      <li>Event Photographer</li>
                                      <li>Product Photographer</li>
                                      </ul>
                                </div>
                              </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="list_box">
                    <div class="list">
                    <div class="letf_text sub_text_color">Level</div>
                    <div class="right_text">Intermediate</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Duration</div>
                    <div class="right_text">3 Months</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Eligibility</div>
                    <div class="right_text">10th Pass</div>
                </div>
                <div class="list">
                    <div class="letf_text sub_text_color">Avg. Package</div>
                    <div class="right_text">12LPA</div>
                </div>
                </div>
            </div> -->
        </div>
    </div>

    <div class="box3">
        <div class="text2 text-center">Our <span class="color_text">Latest Blogs</span></div>
        <div class="text_para text-center">
            <p>ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country. ZICA
                Training Academy Is Equipped With High-End Animation Tools, 2D Animation Facilities, 3D Software,
                Visual FX Labs And Imaging Technologies.</p>
        </div>
        <app-blogs></app-blogs>
        </div>
        <div class="box4 mt-5">
            <div class="row">
                <div class="col-md-6">
                    <div class="text2">Frequently Asked<br><span class="color_text">Questions</span>
                    </div>
                    <div class="text_para">
                     <img src="../../../assets/img/zica/faq.png" width="300px" height="300px" alt="Digital Photography">
                    </div>
                </div>
                <div class="col-md-6">
                    <app-faqs></app-faqs>
                </div>
            </div>
        </div>
        
        <div class="box5">
            <div class="row ">
                <div class="col-lg-6 col-6 text-white mobileRes">
                   
                 <div class="text2"><span class="color_text"> Get In Touch</span><br>
                      With Us </div>
                  <div class="text_para">
                      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua</p> -->
                  </div>
                </div>
                <div class="col-lg-6 col-6 text-white mobileRes">
               
                  <div class="girl_img">
                      <img src="../../../assets/img/zica/Enquiry_pic.png" width="100%" alt="Digital Photography">
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 desktopRes">
                    <div class="text2"><span class="color_text"> Get In Touch</span><br>
                        With Us </div>
                    <div class="text_para">
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua</p> -->
                    </div>
                    <div class="girl_img">
                        <img src="../../../assets/img/zica/Enquiry_pic.png" alt="Digital Photography">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="franchise_form_content">
                        <!-- <app-franchise-form></app-franchise-form> -->
                        <app-amission-course></app-amission-course>
                    </div>
                </div>
            </div>
        </div>
</section>