<section>
  <div class="main_body">
    <div class="box1" *ngIf="blogMainDiv">
      <div class="row">
        <div class="col-md-6">
          <div class="left_box">
            <div class="text2">Read
              <span class="color_text">ZICA <br>Blogs</span> Here
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="img_box">
            <img src="../../.../../../../assets/img/zica/allBlogImg.png" width="100%" alt="Blogs">
          </div>
        </div>
      </div>
    </div>
    <div class="box2" *ngIf="allBlogs">
      <app-all-blog></app-all-blog>
    </div>
    <div class="box3 mt-5" *ngIf="!allBlogs">
      <div class="text2 text-center">Our Suggested <span class="color_text">Blogs</span></div>
      <div class="text_para text-center">
          <p class="text-center">ZICA’s Placement Cell Provides Placement Assistance To All ZICA Students Across The Country.</p>
      </div>
      <app-blog-details></app-blog-details>
    </div>
  </div>
</section>