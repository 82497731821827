<div class="preSchoolbgImg_content">
    <div class="">
        <img src="../../../assets/img/bg_boy.png" alt="Holistic Learning,Expert Educators,Innovative Learning,Smooth Transition,Parental Involvement,Safe and
        Secure" class="preSchoolbgImg responsive" align="center">
        <div class="title_right">
            <div class="text-left">
                <div>Why Choose</div>
                <div>Kidzee Preschool?</div>
            </div>
        </div>
        <div class="paragraph_content1 mt-2">
            <p>Kidzee Preschool is your trusted partner in providing exceptional early education for your child.
                Here's why Kidzee stands out as the preferred choice for parents seeking the best for their
                little ones</p>
        </div>
        <div class="">
            <img src="../../../assets/img/preeSchoolGroup.png" alt="" class="smallGroupImg responsive" align="center">
        </div>
    </div>
</div>
<div class="mobileView">
    <div class="">
        <img src="../../../assets/img/Mask.png" alt=""  class="maskImg responsive" align="center">
        <div class="title_right">
            <div class="text-left">
                <div>Why Choose</div>
                <div>Kidzee Preschool?</div>
            </div>
        </div>
        <div class="paragraph_content1 mt-2">
            <p>Kidzee Preschool is your trusted partner in providing exceptional early education for your child.
                Here's why Kidzee stands out as the preferred choice for parents seeking the best for their
                little ones</p>
        </div>
        <div class="">
            <img src="../../../assets/img/preeSchoolGroup.png" alt="" class="subImg responsive" align="center">
        </div>
    </div>
</div>